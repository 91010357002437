export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string | number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  Json: any;
};

/** Account info */
export type gqlAccount = {
  readonly __typename?: 'Account';
  readonly accountOrganization?: Maybe<ReadonlyArray<gqlOrganizationInfo>>;
  readonly accountOrganizationDivisionsAccess: ReadonlyArray<gqlOrganization>;
  readonly accountOrganizationDivisionsAccessCount?: Maybe<Scalars['Float']>;
  /** Account status object */
  readonly accountStatus?: Maybe<gqlAccountStatus>;
  readonly accountStatusId: Scalars['Int'];
  readonly accountSystemRoles: ReadonlyArray<gqlSystemRole>;
  readonly accountUrlTypes?: Maybe<ReadonlyArray<gqlAccountUrlType>>;
  readonly artists: ReadonlyArray<gqlArtist>;
  /** Account billing info */
  readonly billingInfo?: Maybe<gqlAccountBillingInfo>;
  /** Country object */
  readonly country?: Maybe<gqlCountry>;
  readonly countryId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  /** Divisions that user belongs to */
  readonly divisions?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly email: Scalars['String'];
  /** Holds the generated token value, till it gets expired or the email update verification done successfully. */
  readonly emailResetToken?: Maybe<Scalars['String']>;
  /** Holds the value to reset the original account email till it gets verified */
  readonly emailToReset?: Maybe<Scalars['String']>;
  /** Returns organization features */
  readonly features?: Maybe<ReadonlyArray<gqlFeature>>;
  readonly firstName: Scalars['String'];
  readonly former?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  readonly ipcInfo: gqlIpcInfo;
  /** Checks if user is in role Independent Project Creator */
  readonly isAccountIPC: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  /** Checks if user is in role Casting Director */
  readonly isCCD: Scalars['Boolean'];
  /** Checks if user is in role Independent Project Creator */
  readonly isIPC: Scalars['Boolean'];
  /** Checks if the current/switched user is PC or not */
  readonly isInPCContext: Scalars['Boolean'];
  /** Language Info object */
  readonly language?: Maybe<gqlLanguageInfo>;
  readonly languageLocaleId?: Maybe<Scalars['Int']>;
  readonly lastArtistId?: Maybe<Scalars['Int']>;
  readonly lastLogin?: Maybe<Scalars['DateTime']>;
  readonly lastName: Scalars['String'];
  /** Legacy email */
  readonly legacyEmail?: Maybe<Scalars['String']>;
  /** Legacy instance */
  readonly legacyInstance?: Maybe<Scalars['String']>;
  /** Legacy login */
  readonly legacyLogin?: Maybe<Scalars['String']>;
  readonly legacyProfileStatus?: Maybe<gqlProfileStatus>;
  readonly linkedAccountDivisionCount?: Maybe<Scalars['Float']>;
  readonly linkedAccountOrganization?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly linkedOrganization?: Maybe<Scalars['Float']>;
  readonly linkedToAccountId?: Maybe<Scalars['Int']>;
  readonly loginAttemptCount?: Maybe<Scalars['Int']>;
  readonly loginAttemptFailedAt?: Maybe<Scalars['DateTime']>;
  readonly notificationSubscriptions: ReadonlyArray<gqlNotificationTypeNotificationSubscription>;
  readonly organizationDivisionsCount?: Maybe<Scalars['Float']>;
  /** Organizations that user belongs to */
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly passwordHash?: Maybe<Scalars['String']>;
  readonly phone: Scalars['String'];
  readonly preferences: gqlAccountPreferences;
  /** Roster invitation token to hold the invitation token till logged in user will not create the profile using it */
  readonly rosterInvitationToken?: Maybe<Scalars['String']>;
  readonly subscription?: Maybe<gqlActiveSubscriptionModel>;
  /** system roles */
  readonly systemRoles?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly systemRolesIds?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly termsOfUse: Scalars['Boolean'];
  /** Time zone object */
  readonly timeZone?: Maybe<gqlTimeZone>;
  /** Updated at Date */
  readonly updated?: Maybe<Scalars['DateTime']>;
};


/** Account info */
export type gqlAccountAccountOrganizationArgs = {
  systemRoleCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


/** Account info */
export type gqlAccountOrganizationsArgs = {
  systemRoleCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type gqlAccountActiveOnboardedPaypal = {
  readonly __typename?: 'AccountActiveOnboardedPaypal';
  readonly accountId: Scalars['Float'];
  readonly artistId: Scalars['Float'];
  readonly legacyInstance: Scalars['String'];
  readonly paypalCode: Scalars['String'];
};

/** Account billing info */
export type gqlAccountBillingInfo = {
  readonly __typename?: 'AccountBillingInfo';
  readonly cardExpirationMonth?: Maybe<Scalars['Int']>;
  readonly cardExpirationYear?: Maybe<Scalars['Int']>;
  readonly cardType?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastFour?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type gqlAccountContext = {
  readonly __typename?: 'AccountContext';
  readonly artistId?: Maybe<Scalars['Float']>;
  readonly organizationId?: Maybe<Scalars['Float']>;
  readonly role?: Maybe<gqlContextRole>;
};

/** Creates a user account based on given input values */
export type gqlAccountCreateInput = {
  readonly countryId: Scalars['ID'];
  /** Closest casting market for PC user */
  readonly dmaId?: InputMaybe<Scalars['Int']>;
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly languageLocaleId?: InputMaybe<Scalars['Int']>;
  readonly lastName: Scalars['String'];
  /** Marketing option subscription option */
  readonly marketingOptIn?: InputMaybe<Scalars['Boolean']>;
  readonly password: Scalars['String'];
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly professionalBio?: InputMaybe<Scalars['String']>;
  readonly profileAccessToken?: InputMaybe<Scalars['String']>;
  readonly rosterInvitationToken?: InputMaybe<Scalars['String']>;
  readonly systemRoleCode?: InputMaybe<gqlSystemRoleEnum>;
  readonly termsOfUse: Scalars['Boolean'];
  readonly timeZoneId: Scalars['Int'];
  readonly utmCampaign?: InputMaybe<Scalars['String']>;
  readonly utmMedium?: InputMaybe<Scalars['String']>;
  readonly utmSource?: InputMaybe<Scalars['String']>;
};

export type gqlAccountItem = {
  readonly __typename?: 'AccountItem';
  readonly accountStatus?: Maybe<gqlAccountStatus>;
  readonly accountSystemRole?: Maybe<ReadonlyArray<gqlSystemRole>>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly email: Scalars['String'];
  readonly emailToReset?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly id: Scalars['Float'];
  readonly lastName: Scalars['String'];
  readonly lastSuccessfulLogin?: Maybe<Scalars['DateTime']>;
  readonly legacyEmail?: Maybe<Scalars['String']>;
  readonly legacyInstance?: Maybe<Scalars['String']>;
  readonly legacyLogin?: Maybe<Scalars['String']>;
  readonly legacyProfileStatus?: Maybe<gqlProfileStatus>;
  readonly organizationIds?: Maybe<ReadonlyArray<gqlAccountOrganizationItem>>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly updated?: Maybe<Scalars['DateTime']>;
};

export type gqlAccountItemPagedList = {
  readonly __typename?: 'AccountItemPagedList';
  readonly data: ReadonlyArray<gqlAccountItem>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export enum gqlAccountOnboardingStatusEnum {
  BAU_ACTIVE = 'BAU_ACTIVE',
  BAU_UNCLAIMED = 'BAU_UNCLAIMED',
  BAU_UNVERIFIED = 'BAU_UNVERIFIED',
  BAU_VERIFIED = 'BAU_VERIFIED'
}

export type gqlAccountOrganizationItem = {
  readonly __typename?: 'AccountOrganizationItem';
  readonly admin?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly organizationId: Scalars['Float'];
  readonly organizationParentId?: Maybe<Scalars['Float']>;
};

/** Get Onboarded Active Paypal Accounts Input */
export type gqlAccountPaypalLocationInput = {
  /** Paypal locations */
  readonly locations: ReadonlyArray<gqlPaypalLocation>;
};

export type gqlAccountPreferences = {
  readonly __typename?: 'AccountPreferences';
  readonly projectListFilters?: Maybe<gqlAccountProjectFiltersPreferences>;
  readonly schedule?: Maybe<gqlAccountSchedulePreferences>;
};

export type gqlAccountProjectFiltersInput = {
  readonly castingOrganizationIds?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly dmaIds?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly hasRequests?: InputMaybe<Scalars['Boolean']>;
  readonly hasSelects?: InputMaybe<Scalars['Boolean']>;
  readonly hasSubmissions?: InputMaybe<Scalars['Boolean']>;
  readonly isKidsRolesOnly?: InputMaybe<Scalars['Boolean']>;
  readonly projectName?: InputMaybe<Scalars['String']>;
  readonly projectTypeIds?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly projectUnionIds?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly projectViewStatus?: InputMaybe<gqlProjectViewFilterType>;
  readonly roleName?: InputMaybe<Scalars['String']>;
  readonly roleTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly submissionDueDate?: InputMaybe<Scalars['String']>;
};

export type gqlAccountProjectFiltersPreferences = {
  readonly __typename?: 'AccountProjectFiltersPreferences';
  /** Casting Organisation Search Settings */
  readonly castingOrganizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly hasRequests?: Maybe<Scalars['Boolean']>;
  readonly hasSelects?: Maybe<Scalars['Boolean']>;
  readonly hasSubmissions?: Maybe<Scalars['Boolean']>;
  readonly isKidsRolesOnly?: Maybe<Scalars['Boolean']>;
  readonly projectName?: Maybe<Scalars['String']>;
  /** Region Search Settings */
  readonly projectRegion?: Maybe<ReadonlyArray<gqlDma>>;
  /** Project Types Search Settings */
  readonly projectTypes?: Maybe<ReadonlyArray<gqlProjectType>>;
  /** Project Union Search Settings */
  readonly projectUnion?: Maybe<ReadonlyArray<gqlTalentUnion>>;
  readonly projectViewStatus?: Maybe<gqlProjectViewFilterType>;
  readonly roleName?: Maybe<Scalars['String']>;
  /** Role Types Search Settings */
  readonly roleTypes?: Maybe<ReadonlyArray<gqlRoleType>>;
  readonly submissionDueDate?: Maybe<Scalars['String']>;
};

/** Account Recovery Type by EMAILANDPHONE|PROJECT|STAGE */
export enum gqlAccountRecoveryType {
  EmailAndPhone = 'EmailAndPhone',
  Phone = 'Phone',
  Project = 'Project',
  StageName = 'StageName'
}

export type gqlAccountSchedulePreferences = {
  readonly __typename?: 'AccountSchedulePreferences';
  readonly showUnionStatus: Scalars['Boolean'];
  readonly thumbnailType: gqlThumbnailType;
};

export type gqlAccountSchedulePreferencesInput = {
  readonly showUnionStatus: Scalars['Boolean'];
  readonly thumbnailType: gqlThumbnailType;
};

/** Account status object */
export type gqlAccountStatus = {
  readonly __typename?: 'AccountStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlAccountStatusEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  INCOMPLETE = 'INCOMPLETE',
  JOINED = 'JOINED',
  LOCKED = 'LOCKED',
  UNCLAIMED = 'UNCLAIMED',
  UNVERIFIED = 'UNVERIFIED'
}

export type gqlAccountUrlType = {
  readonly __typename?: 'AccountUrlType';
  readonly accountId: Scalars['Float'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
  readonly urlType: gqlUrlType;
  readonly urlTypeId: Scalars['Float'];
};

export type gqlAccountsSystemRoleCodes = {
  readonly __typename?: 'AccountsSystemRoleCodes';
  readonly accountId: Scalars['Float'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly systemRoleCodes: ReadonlyArray<Scalars['String']>;
};

/** Subscription */
export type gqlActiveSubscriptionModel = {
  readonly __typename?: 'ActiveSubscriptionModel';
  readonly id: Scalars['ID'];
  readonly nextBillingDate?: Maybe<Scalars['DateTime']>;
  readonly plans?: Maybe<ReadonlyArray<gqlPlanModel>>;
};

export type gqlActivityLog = {
  readonly __typename?: 'ActivityLog';
  readonly account?: Maybe<gqlActivityLogAccount>;
  readonly auditionGroup?: Maybe<gqlActivityLogAuditionGroup>;
  readonly auditionSession?: Maybe<gqlActivityLogAuditionSession>;
  readonly calendarSlot?: Maybe<gqlActivityLogCalendarSlot>;
  readonly id: Scalars['ID'];
  readonly note?: Maybe<gqlActivityLogNote>;
  readonly operation: gqlActivityLogOperation;
  readonly organization?: Maybe<gqlActivityLogOrganization>;
  readonly presentation?: Maybe<gqlActivityLogPresentation>;
  readonly presentationFolder?: Maybe<gqlActivityLogPresentationFolder>;
  readonly presentationGroup?: Maybe<gqlActivityLogPresentationGroup>;
  readonly project?: Maybe<gqlActivityLogProject>;
  readonly request?: Maybe<gqlActivityLogRequest>;
  readonly role?: Maybe<gqlActivityLogRole>;
  readonly schedule?: Maybe<gqlActivityLogSchedule>;
  readonly submission?: Maybe<gqlActivityLogSubmission>;
  readonly timestamp: Scalars['DateTime'];
  readonly type: gqlActivityLogType;
};

export type gqlActivityLogAccount = {
  readonly __typename?: 'ActivityLogAccount';
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly isCCD: Scalars['Boolean'];
  readonly lastName: Scalars['String'];
};

export type gqlActivityLogAuditionGroup = {
  readonly __typename?: 'ActivityLogAuditionGroup';
  readonly id: Scalars['Int'];
  readonly mediaByType?: Maybe<gqlMediaByType>;
  readonly medias?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
};

export type gqlActivityLogAuditionSession = {
  readonly __typename?: 'ActivityLogAuditionSession';
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
};

export type gqlActivityLogCalendarSlot = {
  readonly __typename?: 'ActivityLogCalendarSlot';
  readonly id: Scalars['Int'];
  readonly slotEndTime: Scalars['DateTime'];
  readonly slotStartTime: Scalars['DateTime'];
};

export type gqlActivityLogNote = {
  readonly __typename?: 'ActivityLogNote';
  readonly comment: Scalars['String'];
  readonly noteModifier?: Maybe<gqlWfBasicSettingModel>;
};

export enum gqlActivityLogOperation {
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE'
}

export type gqlActivityLogOrganization = {
  readonly __typename?: 'ActivityLogOrganization';
  readonly abbreviation?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
};

export type gqlActivityLogPagedList = {
  readonly __typename?: 'ActivityLogPagedList';
  readonly data: ReadonlyArray<gqlActivityLog>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type gqlActivityLogPresentation = {
  readonly __typename?: 'ActivityLogPresentation';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlActivityLogPresentationFolder = {
  readonly __typename?: 'ActivityLogPresentationFolder';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlActivityLogPresentationGroup = {
  readonly __typename?: 'ActivityLogPresentationGroup';
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
};

export type gqlActivityLogProject = {
  readonly __typename?: 'ActivityLogProject';
  readonly id: Scalars['Int'];
  readonly note: Scalars['String'];
};

export type gqlActivityLogRequest = {
  readonly __typename?: 'ActivityLogRequest';
  readonly additionalMedia?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly answer?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly masterRequestId?: Maybe<Scalars['String']>;
  readonly mediaByType?: Maybe<gqlMediaByType>;
  readonly medias?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly note?: Maybe<Scalars['String']>;
  readonly question?: Maybe<Scalars['String']>;
  readonly status: Scalars['String'];
  readonly talentInstructions?: Maybe<Scalars['String']>;
  readonly type: gqlRequestType;
};

export type gqlActivityLogRole = {
  readonly __typename?: 'ActivityLogRole';
  readonly id: Scalars['Int'];
  readonly internalName: Scalars['String'];
  readonly name: Scalars['String'];
};

export type gqlActivityLogSchedule = {
  readonly __typename?: 'ActivityLogSchedule';
  readonly id: Scalars['Int'];
  readonly scheduleDate?: Maybe<Scalars['DateTime']>;
  readonly scheduleType?: Maybe<Scalars['String']>;
};

export type gqlActivityLogSubmission = {
  readonly __typename?: 'ActivityLogSubmission';
  readonly id: Scalars['Int'];
  readonly media?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly mediaByType?: Maybe<gqlMediaByType>;
  readonly newRole?: Maybe<gqlWfRoleShort>;
  readonly note?: Maybe<Scalars['String']>;
  readonly oldRole?: Maybe<gqlWfRoleShort>;
  readonly requestMedia?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly requestMediaByType?: Maybe<gqlMediaByType>;
  readonly selectionTagCode?: Maybe<Scalars['String']>;
  readonly selectionTagId?: Maybe<Scalars['Float']>;
  readonly selectionTagName?: Maybe<Scalars['String']>;
  readonly statusCode?: Maybe<Scalars['String']>;
  readonly statusId?: Maybe<Scalars['Float']>;
  readonly statusName?: Maybe<Scalars['String']>;
};

export enum gqlActivityLogType {
  FAST_CAPTURE_NOTE_ADDED = 'FAST_CAPTURE_NOTE_ADDED',
  REQUEST_ASKQUESTION = 'REQUEST_ASKQUESTION',
  REQUEST_AUDITION = 'REQUEST_AUDITION',
  REQUEST_CALLBACK = 'REQUEST_CALLBACK',
  REQUEST_MEDIA = 'REQUEST_MEDIA',
  SUBMISSION_ADDED_TO_AUDITION_GROUP = 'SUBMISSION_ADDED_TO_AUDITION_GROUP',
  SUBMISSION_ADDED_TO_PRESENTATION_GROUP = 'SUBMISSION_ADDED_TO_PRESENTATION_GROUP',
  SUBMISSION_CREATED = 'SUBMISSION_CREATED',
  SUBMISSION_MOVED_TO_ROLE = 'SUBMISSION_MOVED_TO_ROLE',
  SUBMISSION_NOTE_ADDED = 'SUBMISSION_NOTE_ADDED',
  SUBMISSION_SELECTIONTAG_ASSIGNED = 'SUBMISSION_SELECTIONTAG_ASSIGNED',
  SUBMISSION_SELECTIONTAG_UPDATED = 'SUBMISSION_SELECTIONTAG_UPDATED',
  SUBMISSION_STATUS_UPDATED = 'SUBMISSION_STATUS_UPDATED',
  SUBMISSION_UPDATED = 'SUBMISSION_UPDATED'
}

export type gqlAddArtistFreeCreditsInput = {
  readonly artistId: Scalars['Int'];
  readonly credits: Scalars['Int'];
  readonly itemCode: gqlBillingItemCode;
};

export type gqlAddBillingInfoInput = {
  readonly backupPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  readonly primaryPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  readonly threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  readonly tokenId: Scalars['String'];
};

export type gqlAddCastingListProfileToRoleInput = {
  readonly listId?: InputMaybe<Scalars['ID']>;
  readonly listProfileIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly roleId: Scalars['ID'];
  readonly tileSchemaValueId: Scalars['ID'];
};

/** Add Note Input Type */
export type gqlAddNoteInput = {
  /** Text of the note */
  readonly comment: Scalars['String'];
  /** Entity ID that note will be assigned to */
  readonly entityId: Scalars['BigInt'];
  /** Entity Type CODE */
  readonly entityTypeCode: Scalars['String'];
  /** Note Modifier CODE */
  readonly noteModifierCode?: InputMaybe<Scalars['String']>;
  /** Note Type CODE */
  readonly noteTypeCode?: InputMaybe<Scalars['String']>;
  /** Organization Id that will take assignment from the context if not supplied explicitly */
  readonly organizationId?: InputMaybe<Scalars['Int']>;
};

export type gqlAddNoteToCastingListProfileResult = {
  readonly __typename?: 'AddNoteToCastingListProfileResult';
  readonly error?: Maybe<gqlAddNoteToProfileError>;
  readonly success: Scalars['Boolean'];
};

export type gqlAddNoteToProfileError = gqlValidationError;

/** Add or Delete List Entities Input */
export type gqlAddOrDeleteListEntitiesInput = {
  /** List entities ids */
  readonly entitiesIds: ReadonlyArray<Scalars['BigInt']>;
  /** List Id */
  readonly listId: Scalars['Float'];
};

export type gqlAddProfileError = gqlValidationError;

/** Add Profile List Input */
export type gqlAddProfileListInput = {
  /** List Name */
  readonly listName: Scalars['String'];
  /** List of profile ids */
  readonly profileIds: ReadonlyArray<Scalars['Int']>;
};

export type gqlAddProfileNoteInput = {
  readonly listProfileId: Scalars['ID'];
  readonly note: Scalars['String'];
};

export type gqlAddProfileSubmissionsToCastingListInput = {
  readonly listIds: ReadonlyArray<Scalars['ID']>;
  readonly projectId: Scalars['Int'];
  readonly submissionIds: ReadonlyArray<Scalars['String']>;
};

export type gqlAddProfileToAuditionGroupAddInInput = {
  readonly auditionGroupId: Scalars['Int'];
  readonly email: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly roleId: Scalars['Int'];
  readonly stageName: Scalars['String'];
};

export type gqlAddProfileToAuditionGroupInput = {
  readonly auditionGroupId: Scalars['Int'];
  readonly profileId: Scalars['Int'];
  readonly roleId: Scalars['Int'];
  readonly tileSchemeValueCode: gqlTileSchemeValueEnum;
};

export type gqlAddProfileToCastingListInput = {
  readonly listId: Scalars['ID'];
  readonly profileIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlAddProfileToCastingListResult = {
  readonly __typename?: 'AddProfileToCastingListResult';
  readonly error?: Maybe<gqlAddProfileError>;
  readonly success: Scalars['Boolean'];
};

/** Adds a Profile to Project. */
export type gqlAddProfileToProjectInput = {
  readonly profileId: Scalars['ID'];
  readonly projectId: Scalars['ID'];
  readonly roleId: Scalars['ID'];
  /** Submission Status CODE */
  readonly submissionStatusCode: Scalars['String'];
  /** Field is DEPRECATED!!! */
  readonly submissionStatusId?: InputMaybe<Scalars['ID']>;
  /** Tile Schema Value CODE */
  readonly tileSchemeValueCode: Scalars['String'];
  /** Field is DEPRECATED!!! */
  readonly tileSchemeValueId?: InputMaybe<Scalars['ID']>;
};

export type gqlAddProfileToRoleError = gqlMissingDataError | gqlNotFoundError | gqlValidationError;

export type gqlAddProfileToRoleResult = {
  readonly __typename?: 'AddProfileToRoleResult';
  readonly error?: Maybe<gqlAddProfileToRoleError>;
  /** Failed Profile Ids */
  readonly failedIds?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly success: Scalars['Boolean'];
};

export type gqlAddProfileToTimeSlotAddInInput = {
  readonly email: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly roleId: Scalars['Int'];
  readonly scheduleId: Scalars['Int'];
  readonly slotId: Scalars['Int'];
  readonly stageName: Scalars['String'];
};

export type gqlAddProfileToTimeSlotInput = {
  readonly profileId: Scalars['Int'];
  readonly roleId: Scalars['Int'];
  readonly scheduleId: Scalars['Int'];
  readonly slotId: Scalars['Int'];
  readonly tileSchemeValue: gqlTileSchemeValueEnum;
};

/** Add Representative List Input */
export type gqlAddRepresentativeListInput = {
  /** List Name */
  readonly listName: Scalars['String'];
  readonly publicationList?: InputMaybe<Scalars['Boolean']>;
  /** List of repIds */
  readonly representativeIds: ReadonlyArray<Scalars['Float']>;
};

/** Input object for assigning submissions to the timeframe */
export type gqlAddSubmissionsToTimeframeInput = {
  /** List of role Ids */
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Schedule Id */
  readonly scheduleId?: InputMaybe<Scalars['Int']>;
  readonly searchInput?: InputMaybe<gqlSubmissionSearchInput>;
  /** Calendar Slot Id */
  readonly slotId?: InputMaybe<Scalars['Int']>;
  /** Slot's start time */
  readonly startTime?: InputMaybe<Scalars['DateTime']>;
  /** List of submission Ids */
  readonly submissionIds: ReadonlyArray<Scalars['BigInt']>;
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Timeframe Id */
  readonly timeframeId?: InputMaybe<Scalars['Int']>;
};

/** AddTimeframe input type */
export type gqlAddTimeframeInput = {
  /** End time */
  readonly endTime: Scalars['DateTime'];
  readonly isBreak?: InputMaybe<Scalars['Boolean']>;
  /** Timeframe Name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Input type for Role Talents */
  readonly roleTalents: ReadonlyArray<gqlRoleTalent>;
  /** Schedule Id */
  readonly scheduleId: Scalars['ID'];
  /** Slot Durations */
  readonly slotDurations?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  /** Start time */
  readonly startTime: Scalars['DateTime'];
};

/** Address object type */
export type gqlAddress = {
  readonly __typename?: 'Address';
  /** City */
  readonly city: Scalars['String'];
  readonly country?: Maybe<gqlCountry>;
  /** Address Id */
  readonly id: Scalars['Int'];
  /** Line #1 of address */
  readonly line1: Scalars['String'];
  /** Line #2 of address */
  readonly line2: Scalars['String'];
  /** Line #3 of address */
  readonly line3: Scalars['String'];
  /** Note */
  readonly note: Scalars['String'];
  /** Other detail */
  readonly otherDetail: Scalars['String'];
  /** Postal code */
  readonly postalCode: Scalars['String'];
  readonly state?: Maybe<gqlState>;
  /** Division state ID */
  readonly stateId: Scalars['Int'];
};

/** Address Input Type */
export type gqlAddressInput = {
  readonly addressId?: InputMaybe<Scalars['Int']>;
  /** Address Line 1 */
  readonly addressLine1: Scalars['String'];
  /** Address Line 2 */
  readonly addressLine2?: InputMaybe<Scalars['String']>;
  /** Address Line 3 */
  readonly addressLine3?: InputMaybe<Scalars['String']>;
  /** Address Type CODE */
  readonly addressTypeCode: Scalars['String'];
  /** City */
  readonly city: Scalars['String'];
  /** Country Id */
  readonly countryId?: InputMaybe<Scalars['Int']>;
  /** Division City Id */
  readonly divisionCityId?: InputMaybe<Scalars['Int']>;
  /** Note */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Other detail */
  readonly otherDetail?: InputMaybe<Scalars['String']>;
  /** Postal Code */
  readonly postalCode?: InputMaybe<Scalars['String']>;
  /** State / Province CODE */
  readonly stateCode?: InputMaybe<Scalars['String']>;
  /** State Id */
  readonly stateId?: InputMaybe<Scalars['Int']>;
};

export enum gqlAddressTypeEnum {
  AUDITION = 'AUDITION',
  CALLBACK = 'CALLBACK',
  COMPANY = 'COMPANY'
}

export type gqlAgeRange = {
  readonly __typename?: 'AgeRange';
  readonly ageMax?: Maybe<Scalars['Int']>;
  readonly ageMin?: Maybe<Scalars['Int']>;
  readonly inMonths?: Maybe<Scalars['Boolean']>;
};

/** Input type for AgeRangeInput */
export type gqlAgeRangeInput = {
  /** The minimum age a talent can play */
  readonly ageMax?: InputMaybe<Scalars['Int']>;
  /** The maximum age a talent can play */
  readonly ageMin?: InputMaybe<Scalars['Int']>;
  /** Ages set in months. Default value: true */
  readonly inMonths?: InputMaybe<Scalars['Boolean']>;
};

export type gqlAgencyCallListDivisionGroup = {
  readonly __typename?: 'AgencyCallListDivisionGroup';
  readonly id: Scalars['Int'];
  readonly organizationName: Scalars['String'];
  readonly phone?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly submissions: ReadonlyArray<gqlAgencyCallListSubmission>;
};

export type gqlAgencyCallListProjectInfo = {
  readonly __typename?: 'AgencyCallListProjectInfo';
  readonly adAgency?: Maybe<Scalars['String']>;
  readonly castingDirector?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly producer?: Maybe<Scalars['String']>;
  readonly productionCompany?: Maybe<Scalars['String']>;
};

export type gqlAgencyCallListReportCreateInput = {
  readonly scheduleId: Scalars['Int'];
};

export type gqlAgencyCallListReportModel = {
  readonly __typename?: 'AgencyCallListReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlAgencyCallListReportPayloadModel>;
  readonly reportType: gqlReportTypeEnum;
  readonly scheduleId: Scalars['Int'];
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Agency Call List Report Payload */
export type gqlAgencyCallListReportPayloadModel = {
  readonly __typename?: 'AgencyCallListReportPayloadModel';
  readonly divisionLogo?: Maybe<gqlReportMediaModel>;
  readonly divisionName?: Maybe<Scalars['String']>;
  readonly personalSubmissions: ReadonlyArray<gqlAgencyCallListSubmission>;
  readonly projectInfo: gqlAgencyCallListProjectInfo;
  readonly reppedSubmissions: ReadonlyArray<gqlAgencyCallListDivisionGroup>;
  readonly scheduleInfo: gqlAgencyCallListScheduleInfo;
};

export type gqlAgencyCallListScheduleInfo = {
  readonly __typename?: 'AgencyCallListScheduleInfo';
  readonly scheduleDate: Scalars['DateTime'];
  readonly scheduleName: Scalars['String'];
  readonly scheduleType: Scalars['String'];
};

export type gqlAgencyCallListSubmission = {
  readonly __typename?: 'AgencyCallListSubmission';
  readonly cover?: Maybe<gqlReportMediaModel>;
  readonly deletedProfile: Scalars['Boolean'];
  readonly id: Scalars['BigInt'];
  readonly isPersonal: Scalars['Boolean'];
  readonly note?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly roleName: Scalars['String'];
  readonly scheduleResponseStatus?: Maybe<Scalars['String']>;
  readonly slotStartDate: Scalars['DateTime'];
  readonly stageName?: Maybe<Scalars['String']>;
  readonly talentUnions?: Maybe<ReadonlyArray<Scalars['String']>>;
};

/** Creates or update Rep organization contact, address, publishing categories, dma and workingLocation on given input values */
export type gqlAgentOrganizationInput = {
  /** Representation age categories */
  readonly ageCategories?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  /** DMA id */
  readonly dmaId: Scalars['Float'];
  /** Organization input */
  readonly organizationInput: gqlOrganizationInput;
  /** Primary working organization */
  readonly primaryWorkingLocation: ReadonlyArray<Scalars['Float']>;
  /** Publishing categories */
  readonly publishingCategories: ReadonlyArray<Scalars['Float']>;
};

/** All Talent Submission Count Model */
export type gqlAllTalentSubmissionCountModel = {
  readonly __typename?: 'AllTalentSubmissionCountModel';
  readonly auditionsCount: Scalars['Int'];
  readonly callbacksCount: Scalars['Int'];
  readonly mediaRequestCount: Scalars['Int'];
  readonly profile?: Maybe<gqlAllTalentSubmissionProfileModel>;
  readonly submissionsCount: Scalars['Int'];
};

/** All Talent Submission Report */
export type gqlAllTalentSubmissionModel = {
  readonly __typename?: 'AllTalentSubmissionModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly dateFrom: Scalars['DateTime'];
  readonly dateTo: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly includeFormerClient?: Maybe<Scalars['Boolean']>;
  readonly organizationId: Scalars['Int'];
  readonly payload?: Maybe<gqlAllTalentSubmissionPayloadModel>;
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** All Talent Submission Report Payload */
export type gqlAllTalentSubmissionPayloadModel = {
  readonly __typename?: 'AllTalentSubmissionPayloadModel';
  readonly castingCompanyInfo?: Maybe<gqlCompanyInfoModel>;
  readonly data: ReadonlyArray<gqlAllTalentSubmissionCountModel>;
};

/** All Talent Submission Profile Model */
export type gqlAllTalentSubmissionProfileModel = {
  readonly __typename?: 'AllTalentSubmissionProfileModel';
  readonly isFormerClient: Scalars['Boolean'];
  readonly profileId: Scalars['Int'];
  readonly stageName?: Maybe<Scalars['String']>;
};

/** All Talent Submission Report Input Arguments */
export type gqlAllTalentSubmissionReportCreateInput = {
  readonly dateFrom: Scalars['DateTime'];
  readonly dateTo: Scalars['DateTime'];
  readonly includeFormerClient: Scalars['Boolean'];
};

/** Input type for PC project approve */
export type gqlApprovePcProjectInput = {
  readonly projectId?: InputMaybe<Scalars['Int']>;
  /** Send notifications to talent (FFY) */
  readonly sendNotifications?: InputMaybe<Scalars['Boolean']>;
};

export type gqlArchiveMediaInput = {
  readonly mediaIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlArchiveMediaResponse = {
  readonly __typename?: 'ArchiveMediaResponse';
  readonly media: ReadonlyArray<gqlMedia>;
  readonly success: Scalars['Boolean'];
};

/** Artist info */
export type gqlArtist = {
  readonly __typename?: 'Artist';
  readonly account?: Maybe<gqlAccount>;
  readonly activeSubscription?: Maybe<gqlSubscription>;
  /** Artist Id */
  readonly artistId: Scalars['Int'];
  readonly artistSubscriptions?: Maybe<ReadonlyArray<gqlSubscriptionModel>>;
  /** Created Date */
  readonly created: Scalars['DateTime'];
  /** First Name */
  readonly firstName: Scalars['String'];
  readonly hasAugeoBenefits?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Last Name */
  readonly lastName: Scalars['String'];
  readonly mediaCounts?: Maybe<ReadonlyArray<gqlMediaCount>>;
  readonly mediaEntitlement: gqlArtistMediaEntitlement;
  readonly mediaUtilization: gqlArtistMediaUtilization;
  /** True if artist is minor */
  readonly minor: Scalars['Boolean'];
  readonly notificationPhone?: Maybe<Scalars['String']>;
  readonly previousSubscription?: Maybe<gqlSubscription>;
  readonly profiles?: Maybe<ReadonlyArray<gqlProfile>>;
  readonly storageLimitBytes?: Maybe<Scalars['Int']>;
  readonly subscriptionMediaUsage?: Maybe<gqlArtistSubscriptionMediaEntitlement>;
};


/** Artist info */
export type gqlArtistMediaCountsArgs = {
  isLocked: Scalars['Boolean'];
};


/** Artist info */
export type gqlArtistProfilesArgs = {
  oldest?: InputMaybe<Scalars['Boolean']>;
};

export type gqlArtistBillingFeatureModel = {
  readonly __typename?: 'ArtistBillingFeatureModel';
  readonly artistId: Scalars['Float'];
  readonly credit: Scalars['Int'];
  readonly itemCode: Scalars['String'];
};

/** Input type for Artist Credits */
export type gqlArtistCreditsInput = {
  /** Credit type id */
  readonly creditTypeId: Scalars['Int'];
  /** The director of the Project */
  readonly director: Scalars['String'];
  /** Display order descending */
  readonly displayOrder?: InputMaybe<Scalars['Int']>;
  /** Highlighted */
  readonly highlighted?: InputMaybe<Scalars['Boolean']>;
  /** Profile ids */
  readonly profileIds: ReadonlyArray<Scalars['ID']>;
  /** Role name */
  readonly role: Scalars['String'];
  /** Credit title */
  readonly title: Scalars['String'];
  /** Year */
  readonly year?: InputMaybe<Scalars['Int']>;
};

/** Artist Media */
export type gqlArtistMedia = {
  readonly __typename?: 'ArtistMedia';
  /** Profile media details - is primary, order */
  readonly artistMediaProfile?: Maybe<ReadonlyArray<gqlArtistMediaProfile>>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** The media object */
  readonly media: gqlMedia;
  /** Media Id */
  readonly mediaId: Scalars['ID'];
};

/** An overview of the media entitlement for an artist, broken down by media type. */
export type gqlArtistMediaEntitlement = {
  readonly __typename?: 'ArtistMediaEntitlement';
  readonly artist: gqlArtist;
  readonly artistId: Scalars['ID'];
  readonly audioUpload: Scalars['Int'];
  readonly photoUpload: Scalars['Int'];
  readonly videoUpload: Scalars['Int'];
};

/** Basic profile media */
export type gqlArtistMediaProfile = {
  readonly __typename?: 'ArtistMediaProfile';
  /** Checks if profile have granted access to roster */
  readonly accessGranted: Scalars['Boolean'];
  readonly accountId?: Maybe<Scalars['Float']>;
  readonly ageInYears?: Maybe<Scalars['Boolean']>;
  /** Profile Artist ID */
  readonly artistId?: Maybe<Scalars['Float']>;
  /** Profile audio assets */
  readonly audios?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  readonly billingFeatures?: Maybe<ReadonlyArray<gqlArtistBillingFeatureModel>>;
  /** @deprecated Use 'billingFeatures' instead. ID will now return 0. */
  readonly billingItems?: Maybe<ReadonlyArray<gqlBillingItemModel>>;
  /** Blurb */
  readonly blurb?: Maybe<Scalars['String']>;
  /** Bust Object */
  readonly bust?: Maybe<gqlBust>;
  /** Chest size Object */
  readonly chestSize?: Maybe<gqlChestSize>;
  /** Coat Object */
  readonly coat?: Maybe<gqlCoat>;
  /** Conflicts for the profile */
  readonly conflicts: ReadonlyArray<gqlProfileConflict>;
  /** Dynamic field. Based on profile status and representative type */
  readonly contactInfo?: Maybe<gqlProfileContactInfo>;
  readonly contactedByRep?: Maybe<ReadonlyArray<gqlProfileContactedByRep>>;
  readonly cover?: Maybe<gqlMedia>;
  /** Created at Date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Profile Credits */
  readonly credits: ReadonlyArray<gqlCredit>;
  /** Profile Credits Count */
  readonly creditsCount: Scalars['Int'];
  /** Cup size Object */
  readonly cupSize?: Maybe<gqlCupSize>;
  readonly customSlug?: Maybe<gqlCustomSlug>;
  /** Date of birth */
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** stores/holds the id of the person who has deactivated the profile */
  readonly deactivatedBy?: Maybe<Scalars['Float']>;
  /** Default submission note */
  readonly defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Document type(s) */
  readonly documentTypes?: Maybe<ReadonlyArray<gqlDocument>>;
  /** Profile document assets */
  readonly documents?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Dress size Object */
  readonly dressSize?: Maybe<gqlClothesSize>;
  /** Ethnic Appearance(s) */
  readonly ethnicAppearances?: Maybe<ReadonlyArray<gqlProfileEthnicAppearance>>;
  /** Eye Color Object */
  readonly eyeColor?: Maybe<gqlEyeColor>;
  /** Facial Hair Type(s) */
  readonly facialHair?: Maybe<ReadonlyArray<gqlFacialHair>>;
  /** Gender Appearance(s) */
  readonly genderAppearances?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  /** Glove size Object */
  readonly gloveSize?: Maybe<gqlGlove>;
  /** GUID */
  readonly guid?: Maybe<Scalars['String']>;
  /** Hair Color Object */
  readonly hairColor?: Maybe<gqlHairColor>;
  /** Hair Style Object */
  readonly hairStyle?: Maybe<gqlHairStyle>;
  /** Profile has audio reel */
  readonly hasAudioReel?: Maybe<Scalars['Boolean']>;
  readonly hasAuditionHistory: Scalars['Boolean'];
  /** has Representation */
  readonly hasRepresentation?: Maybe<Scalars['Boolean']>;
  /** Profile has resume */
  readonly hasResume?: Maybe<Scalars['Boolean']>;
  /** Profile has video reel */
  readonly hasVideoReel?: Maybe<Scalars['Boolean']>;
  /** Hat size Object */
  readonly hatSize?: Maybe<gqlHatSize>;
  /** Height Object */
  readonly height?: Maybe<gqlHeight>;
  /**
   * Deprecated
   * @deprecated This field is redundant as profile media visibility depends only on existence in profile_media table.
   */
  readonly hidden: Scalars['Boolean'];
  /** Hide credit year */
  readonly hideCreditYear: Scalars['Boolean'];
  /** Hip size Object */
  readonly hipSize?: Maybe<gqlHip>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inseam size Object */
  readonly inseam?: Maybe<gqlInseam>;
  readonly isActive: Scalars['Boolean'];
  /**
   * Returns true if profile was deactivated by a Rep user, returns false if
   * profile was deactivated by a Talent, returns null when there is no information
   * about the deactivating account
   */
  readonly isDeactivatedByReps?: Maybe<Scalars['Boolean']>;
  readonly isDropped: Scalars['Boolean'];
  readonly isInactive: Scalars['Boolean'];
  /** If Profile is overscaled */
  readonly isOverscaled: Scalars['Boolean'];
  /** Identify the personal or represented profile. */
  readonly isPersonal: Scalars['Boolean'];
  readonly isPlayableAgeLockedToTalent: Scalars['Boolean'];
  /** Is media primary (primary photo, video reel or audio reel) */
  readonly isPrimary: Scalars['Boolean'];
  /** Identify represented profile */
  readonly isRepresented: Scalars['Boolean'];
  /** Is talent seeking representation */
  readonly isSeekingRepresentation?: Maybe<Scalars['Boolean']>;
  /** Last note for the profile */
  readonly lastNote?: Maybe<gqlNote>;
  /** Luminate profile Data */
  readonly luminateProfile?: Maybe<gqlLuminateProfile>;
  /** Manager Email */
  readonly managerEmail?: Maybe<Scalars['String']>;
  /** All profile media */
  readonly mediaAssets?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Profile's primary photo */
  readonly mediaSummary: ReadonlyArray<gqlMediaCount>;
  /** Is minor */
  readonly minor?: Maybe<Scalars['Boolean']>;
  /** Multiple Birth Object */
  readonly multipleBirth?: Maybe<gqlMultipleBirth>;
  /** Neck size Object */
  readonly neck?: Maybe<gqlNeck>;
  /** Note */
  readonly note?: Maybe<Scalars['String']>;
  /** Note to Representative */
  readonly noteToRepresentative?: Maybe<Scalars['String']>;
  /** Notes for the profile */
  readonly notes: ReadonlyArray<gqlNote>;
  /** Order of display for the media */
  readonly order?: Maybe<Scalars['Int']>;
  readonly organization?: Maybe<gqlOrganization>;
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  /** Other experiences */
  readonly otherExperiences?: Maybe<Scalars['String']>;
  /** Overscale Note */
  readonly overscaleNote?: Maybe<Scalars['String']>;
  /** Parent Name */
  readonly parent?: Maybe<Scalars['String']>;
  /** Profile phone */
  readonly phone?: Maybe<Scalars['String']>;
  /** Profile photo assets */
  readonly photos?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Note about piercing */
  readonly piercingNote?: Maybe<Scalars['String']>;
  /** The age a talent can play */
  readonly playableAge?: Maybe<Scalars['Float']>;
  /** The minimum age a talent can play */
  readonly playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  readonly playableAgeMin?: Maybe<Scalars['Float']>;
  /**
   * Date of pregnancy
   * @deprecated This field is deprecated and will be deleted once the compatible changes at the front end will be added everyewhere for pregnancyDue reference. Use pregnancyDue instead
   */
  readonly pregnancy?: Maybe<Scalars['DateTime']>;
  /** Date of pregnancy */
  readonly pregnancyDue?: Maybe<Scalars['DateTime']>;
  /** Profession */
  readonly profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  readonly profileAccessToken?: Maybe<Scalars['String']>;
  /** ProfileAccessToken created date */
  readonly profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile Account System Role */
  readonly profileAccountRole?: Maybe<Scalars['String']>;
  /** Get profile bookouts */
  readonly profileBookOuts: ReadonlyArray<gqlProfileBookOuts>;
  /** Gender Identities */
  readonly profileGenderIdentities?: Maybe<ReadonlyArray<gqlProfileGenderIdentity>>;
  /** Primary ID */
  readonly profileId: Scalars['ID'];
  /** The Profile List ids where current profile participated */
  readonly profileLists: ReadonlyArray<Scalars['Int']>;
  readonly profileMainDivision?: Maybe<gqlOrganization>;
  readonly profileMainOrganization?: Maybe<gqlOrganization>;
  /** media assets */
  readonly profileMedia?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Profile Pants */
  readonly profilePants: ReadonlyArray<gqlPants>;
  /** Profile pets */
  readonly profilePets: ReadonlyArray<gqlProfilePet>;
  /** Profile piercings */
  readonly profilePiercings: ReadonlyArray<gqlPiercing>;
  /** Profile shoe sizes */
  readonly profileShoeSizes: ReadonlyArray<gqlProfileShoeSize>;
  /** Profile general size */
  readonly profileSizes: ReadonlyArray<gqlProfileSize>;
  /** Profile Status Object */
  readonly profileStatus?: Maybe<gqlProfileStatus>;
  readonly profileStatusId?: Maybe<Scalars['Int']>;
  /** Profile TshirtSize */
  readonly profileTshirtSizes: ReadonlyArray<gqlTShirtSize>;
  /** Profile Type */
  readonly profileType?: Maybe<gqlProfileType>;
  /** Profile vehicles */
  readonly profileVehicles: ReadonlyArray<gqlProfileVehicle>;
  /** profileVisibility Object */
  readonly profileVisiblities?: Maybe<ReadonlyArray<gqlProfileVisibility>>;
  /** Wardrobes */
  readonly profileWardrobes?: Maybe<ReadonlyArray<gqlProfileWardrobe>>;
  /** Project Type */
  readonly projectType?: Maybe<Scalars['String']>;
  /** Prop Type(s) */
  readonly props?: Maybe<ReadonlyArray<gqlProfileProp>>;
  /** Job Persuing Type(s) */
  readonly pursuing?: Maybe<ReadonlyArray<gqlPursuing>>;
  /** User's resume document */
  readonly resume?: Maybe<gqlProfileMedia>;
  /** Roster hidden */
  readonly rosterHidden?: Maybe<Scalars['Boolean']>;
  /** Search hidden */
  readonly searchHidden?: Maybe<Scalars['Boolean']>;
  /** SeekingRepresentation Object */
  readonly seekingRepresentations?: Maybe<ReadonlyArray<gqlSeekingRepresentation>>;
  readonly selectionType: gqlProfileSelectionType;
  /** Skills and their Levels */
  readonly skills?: Maybe<ReadonlyArray<gqlProfileSkill>>;
  /** Sleeve Object */
  readonly sleeve?: Maybe<gqlSleeve>;
  /** Sport equipments */
  readonly sportEquipments?: Maybe<ReadonlyArray<gqlProfileSportEquipment>>;
  /** Stage Name */
  readonly stageName: Scalars['String'];
  /** Returns submission */
  readonly submission?: Maybe<gqlSubmission>;
  /** Submission request's media assets */
  readonly submissionRequestMedia: ReadonlyArray<gqlSubmissionMedia>;
  /** talent's contacts regardless representative status */
  readonly talentContactInfo?: Maybe<gqlProfileContactInfo>;
  /** Profile email */
  readonly talentEmail?: Maybe<Scalars['String']>;
  readonly talentFolders?: Maybe<ReadonlyArray<gqlPresentationFolderTalentPresence>>;
  /** Talent unions */
  readonly talentUnions?: Maybe<ReadonlyArray<gqlTalentProfileUnion>>;
  /** Profile tattoo */
  readonly tattoo?: Maybe<gqlTattoo>;
  /** Tattoo Note */
  readonly tattooNote?: Maybe<Scalars['String']>;
  /** Underbust size Object */
  readonly underbust?: Maybe<gqlUnderBust>;
  /** Unique Attribute Type(s) */
  readonly uniqueAttributes?: Maybe<ReadonlyArray<gqlUniqueAttribute>>;
  /** Updated at Date */
  readonly updated?: Maybe<Scalars['DateTime']>;
  /** Profile video assets */
  readonly videos?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Waist Object */
  readonly waist?: Maybe<gqlWaist>;
  /** Weight Object */
  readonly weight?: Maybe<gqlWeight>;
  /** Willingness Objects */
  readonly willingness: ReadonlyArray<gqlWillingness>;
  /** Work permit expiration date */
  readonly workPermitExp?: Maybe<Scalars['DateTime']>;
  /** Working Locations */
  readonly workingLocations?: Maybe<ReadonlyArray<gqlProfileWorkingLocation>>;
};


/** Basic profile media */
export type gqlArtistMediaProfileHasAuditionHistoryArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};


/** Basic profile media */
export type gqlArtistMediaProfilePhotosArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};


/** Basic profile media */
export type gqlArtistMediaProfileSelectionTypeArgs = {
  roleId: Scalars['Float'];
};


/** Basic profile media */
export type gqlArtistMediaProfileSubmissionArgs = {
  roleId: Scalars['Float'];
};


/** Basic profile media */
export type gqlArtistMediaProfileSubmissionRequestMediaArgs = {
  roleId: Scalars['Float'];
};


/** Basic profile media */
export type gqlArtistMediaProfileTalentFoldersArgs = {
  folderId: Scalars['Int'];
};

/** An overview of the media utilization for an artist, broken down by media type. */
export type gqlArtistMediaUtilization = {
  readonly __typename?: 'ArtistMediaUtilization';
  readonly artist: gqlArtist;
  readonly artistId: Scalars['ID'];
  readonly audioUpload: Scalars['Int'];
  readonly countByMediaTypes: ReadonlyArray<gqlArtistMediaUtilizationMediaTypeCount>;
  readonly countByPurchaseStatus: ReadonlyArray<gqlArtistMediaUtilizationPurchaseStatusCount>;
  readonly photoUpload: Scalars['Int'];
  readonly videoUpload: Scalars['Int'];
};

export enum gqlArtistMediaUtilizationMediaType {
  AUDIO = 'AUDIO',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

/** A count of media utilization by media type. */
export type gqlArtistMediaUtilizationMediaTypeCount = {
  readonly __typename?: 'ArtistMediaUtilizationMediaTypeCount';
  readonly count: Scalars['Int'];
  readonly countByPurchaseStatus: ReadonlyArray<gqlArtistMediaUtilizationPurchaseStatusCount>;
  readonly media: ReadonlyArray<gqlMedia>;
  readonly mediaType: gqlArtistMediaUtilizationMediaType;
};

/** A count of media utilization by purchase type. */
export type gqlArtistMediaUtilizationPurchaseStatusCount = {
  readonly __typename?: 'ArtistMediaUtilizationPurchaseStatusCount';
  readonly count: Scalars['Int'];
  readonly media: ReadonlyArray<gqlMedia>;
  readonly purchaseStatus: gqlArtistMediaUtilizationPurchaseType;
};

export enum gqlArtistMediaUtilizationPurchaseType {
  FREE_CREDIT = 'FREE_CREDIT',
  NOT_PURCHASED = 'NOT_PURCHASED',
  PURCHASED = 'PURCHASED'
}

/** Artist Media Utilization */
export type gqlArtistStorageUtilization = {
  readonly __typename?: 'ArtistStorageUtilization';
  /** The storage limit in bytes for the artist. Returns null if the artist has no storage limit. */
  readonly artistStorageLimitBytes?: Maybe<Scalars['BigInt']>;
  /** The remaining storage in bytes for the artist. Returns null if the artist has no storage limit. */
  readonly artistStorageRemainingBytes?: Maybe<Scalars['BigInt']>;
  readonly id: Scalars['ID'];
  /** Each media type is broken down with its storage utilization */
  readonly mediaStorages: ReadonlyArray<gqlMediaStorage>;
  /** Total size of all media types used in bytes */
  readonly totalSizeBytes: Scalars['BigInt'];
};

/** Subscription Input */
export type gqlArtistSubscriptionInput = {
  /** Artist id */
  readonly artistId: Scalars['Int'];
};

export type gqlArtistSubscriptionMediaEntitlement = {
  readonly __typename?: 'ArtistSubscriptionMediaEntitlement';
  readonly available: ReadonlyArray<gqlSubscriptionMediaEntitlement>;
  readonly entitle: ReadonlyArray<gqlSubscriptionMediaEntitlement>;
};

/** Input for Updating Artist */
export type gqlArtistUpdateInput = {
  /** Account Id */
  readonly accountId?: InputMaybe<Scalars['Int']>;
  /** Artist Id */
  readonly artistId: Scalars['Int'];
  /** First Name */
  readonly firstName?: InputMaybe<Scalars['String']>;
  /** Last Name */
  readonly lastName?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  readonly organizationId?: InputMaybe<Scalars['Int']>;
  /** Profile access token granted by Rep */
  readonly profileAccessToken?: InputMaybe<Scalars['String']>;
};

/** 'Ask Question Request' Type */
export type gqlAskQuestionRequest = {
  readonly __typename?: 'AskQuestionRequest';
  readonly createdAt: Scalars['DateTime'];
  readonly draftSubmissions?: Maybe<gqlWfSubmissionPagedList>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dueDateTimeZone?: Maybe<gqlWfTimeZone>;
  readonly id: Scalars['ID'];
  readonly includeSides: Scalars['Boolean'];
  readonly isDraft?: Maybe<Scalars['Boolean']>;
  readonly messageForRepresentatives?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly projectId: Scalars['Int'];
  readonly question: Scalars['String'];
  readonly responses: gqlAskQuestionResponsePagedList;
  readonly shouldResend: Scalars['Boolean'];
  readonly submissionIds: ReadonlyArray<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};


/** 'Ask Question Request' Type */
export type gqlAskQuestionRequestDraftSubmissionsArgs = {
  page?: InputMaybe<gqlPageInput>;
};


/** 'Ask Question Request' Type */
export type gqlAskQuestionRequestResponsesArgs = {
  page: gqlPageInput;
};

export type gqlAskQuestionRequestInput = {
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly dueDateTimeZoneId?: InputMaybe<Scalars['Int']>;
  readonly excludeSubmissionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly includeSides: Scalars['Boolean'];
  readonly isDraft: Scalars['Boolean'];
  readonly messageForRepresentatives?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly projectId: Scalars['Int'];
  readonly question: Scalars['String'];
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly submissionIds: ReadonlyArray<Scalars['Int']>;
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum gqlAskQuestionRequestStatus {
  ANSWERED = 'ANSWERED',
  CANCELLED = 'CANCELLED',
  NOT_SENT = 'NOT_SENT',
  PENDING = 'PENDING'
}

export type gqlAskQuestionResponse = {
  readonly __typename?: 'AskQuestionResponse';
  readonly answer?: Maybe<Scalars['String']>;
  readonly cancelledAt?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly repliedAt?: Maybe<Scalars['DateTime']>;
  readonly sentAt?: Maybe<Scalars['DateTime']>;
  readonly status: gqlAskQuestionRequestStatus;
  readonly submission: gqlWfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  readonly submissionId: Scalars['Float'];
};

export type gqlAskQuestionResponseInput = {
  readonly id: Scalars['ID'];
  readonly message: Scalars['String'];
};

export type gqlAskQuestionResponsePagedList = {
  readonly __typename?: 'AskQuestionResponsePagedList';
  readonly data: ReadonlyArray<gqlAskQuestionResponse>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** 'Ask Question Sub-Request' Type */
export type gqlAskQuestionSubRequest = {
  readonly __typename?: 'AskQuestionSubRequest';
  /** Cancellation note */
  readonly cancellationNote?: Maybe<Scalars['String']>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dueDateTimeZone?: Maybe<gqlWfTimeZone>;
  /** Forwarded note */
  readonly forwardNote?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly includeSides: Scalars['Boolean'];
  readonly messageForRepresentatives?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<gqlWfProfile>;
  readonly project: gqlWfProject;
  readonly question: Scalars['String'];
  /** The date when response was sent */
  readonly repliedAt?: Maybe<Scalars['DateTime']>;
  /** Reps Worksheet Status */
  readonly repsWorksheetStatus: Scalars['String'];
  /** The answer to the question */
  readonly response?: Maybe<Scalars['String']>;
  readonly role: gqlWfRole;
  /** The date when request was sent */
  readonly sentAt: Scalars['DateTime'];
  readonly status: gqlAskQuestionRequestStatus;
  readonly submission: gqlWfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  readonly submissionId: Scalars['Int'];
};

export type gqlAttachMediaToProfileInput = {
  /** Is media primary? (Primary photo, video reel or audio reel) */
  readonly isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media Id */
  readonly mediaId: Scalars['Int'];
  /** Media Moderation Status Code can be found in Tackle box */
  readonly mediaModerationStatusCode?: InputMaybe<Scalars['String']>;
  /** Media order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
  readonly paid?: InputMaybe<Scalars['Boolean']>;
  /** Profile Id */
  readonly profileId: Scalars['Int'];
  readonly tag?: InputMaybe<Scalars['String']>;
};

export type gqlAttachMultipleMediaToProfileInput = {
  /** Media Ids to attach */
  readonly mediaIds: ReadonlyArray<Scalars['Int']>;
  /** Media Moderation Status Code can be found in Tackle box */
  readonly mediaModerationStatusCode?: InputMaybe<Scalars['String']>;
  /** Profile Id */
  readonly profileId: Scalars['Int'];
};

export type gqlAttachmentDetails = {
  readonly __typename?: 'AttachmentDetails';
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
};

/** Audition address */
export type gqlAuditionAddress = {
  readonly __typename?: 'AuditionAddress';
  /** Address Line 1 */
  readonly addressLine1?: Maybe<Scalars['String']>;
  /** Address Line 2 */
  readonly addressLine2?: Maybe<Scalars['String']>;
  /** City */
  readonly city?: Maybe<Scalars['String']>;
  /** Country */
  readonly country?: Maybe<ReadonlyArray<gqlCountry>>;
  /** Address ID */
  readonly id: Scalars['ID'];
  /** Location notes */
  readonly locationNotes?: Maybe<Scalars['String']>;
  /** ZIP/Postal Code */
  readonly postalCode?: Maybe<Scalars['String']>;
  /** State / Province */
  readonly state?: Maybe<ReadonlyArray<gqlState>>;
};

export type gqlAuditionGroup = {
  readonly __typename?: 'AuditionGroup';
  readonly archived?: Maybe<Scalars['Boolean']>;
  /** Gets audition session */
  readonly auditionSession?: Maybe<gqlAuditionSession>;
  /** Id of audition session to which the audition session group is assigned */
  readonly auditionSessionId: Scalars['Int'];
  readonly cover?: Maybe<gqlMedia>;
  /** Created date of audition group */
  readonly created: Scalars['DateTime'];
  /** Gets Account object whos created the audition group */
  readonly createdBy?: Maybe<gqlAccount>;
  /** Id of audition session group */
  readonly id: Scalars['Int'];
  /** Media Count */
  readonly mediaCount?: Maybe<ReadonlyArray<gqlMediaCount>>;
  /** Gets related to group media */
  readonly medias?: Maybe<ReadonlyArray<gqlAuditionGroupMedia>>;
  /** Name of audition session group */
  readonly name?: Maybe<Scalars['String']>;
  /** Group order */
  readonly order?: Maybe<Scalars['Int']>;
  /** Submissions Count */
  readonly submissionCount?: Maybe<Scalars['BigInt']>;
  /** Gets related to group submissions */
  readonly submissions?: Maybe<ReadonlyArray<gqlSubmission>>;
  /** Updated date of audition group */
  readonly updated?: Maybe<Scalars['DateTime']>;
};


export type gqlAuditionGroupSubmissionsArgs = {
  pageSize?: InputMaybe<Scalars['Float']>;
};

/** Audition Session Group input type */
export type gqlAuditionGroupBulkUpdateInput = {
  /** Archive status */
  readonly archived: Scalars['Boolean'];
};

/** Audition Session Group input type */
export type gqlAuditionGroupInput = {
  /** Id of audition session to which the audition session group will be assigned */
  readonly auditionSessionId?: InputMaybe<Scalars['Int']>;
  /** ID of the creator */
  readonly createdBy?: InputMaybe<Scalars['Int']>;
  /** Name for audition session group */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Note for the group */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
  /** Thumbnail. Id of existing media */
  readonly thumbnailMediaId?: InputMaybe<Scalars['Int']>;
};

/** Represent relation between Audition Group and Media */
export type gqlAuditionGroupMedia = {
  readonly __typename?: 'AuditionGroupMedia';
  readonly id: Scalars['Int'];
  /** Related media object */
  readonly media: gqlMedia;
  /** Id of media */
  readonly mediaId: Scalars['Int'];
  /** Name of relation */
  readonly name: Scalars['String'];
  /** Order of appearance */
  readonly order: Scalars['Int'];
};

/** Audition Group Media input type */
export type gqlAuditionGroupMediaInput = {
  /** Id of audition group to which media will be added */
  readonly auditionGroupId: Scalars['Int'];
  /** List of Media items */
  readonly media: ReadonlyArray<gqlAuditionGroupMediaItemInput>;
};

/** Media input type for Audition Group */
export type gqlAuditionGroupMediaItemInput = {
  /** Id of existing Media */
  readonly mediaId: Scalars['Int'];
  /** Name for the media */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
};

export type gqlAuditionGroupMediaOrderUpdateInput = {
  readonly auditionGroupId: Scalars['Int'];
  readonly groupMediaId: Scalars['Int'];
  readonly moveAfterGroupMediaId?: InputMaybe<Scalars['Int']>;
};

/** Paginated result with [AuditionGroup] objects */
export type gqlAuditionGroupPaginatedResult = {
  readonly __typename?: 'AuditionGroupPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of AuditionGroup objects */
  readonly objects: ReadonlyArray<gqlAuditionGroup>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Audition Group Talent */
export type gqlAuditionGroupTalent = {
  readonly __typename?: 'AuditionGroupTalent';
  /** Audition Group ids */
  readonly auditionGroupIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** Talent profile Id */
  readonly profileId?: Maybe<Scalars['Int']>;
};

export type gqlAuditionGrouping = {
  readonly __typename?: 'AuditionGrouping';
  readonly details?: Maybe<gqlAuditionGroupDetails>;
  readonly entityId: Scalars['Float'];
  readonly itemCount: Scalars['Float'];
};

export type gqlAuditionHistory = {
  readonly __typename?: 'AuditionHistory';
  readonly auditionGroups?: Maybe<ReadonlyArray<gqlAuditionHistorySessionGroupItem>>;
  readonly date: Scalars['DateTime'];
  readonly projectName: Scalars['String'];
  readonly roleName: Scalars['String'];
  readonly snapshotMedia?: Maybe<gqlMedia>;
  readonly submissionStatus: gqlSubmissionStatus;
};

/** Paginated result with [AuditionHistory] objects */
export type gqlAuditionHistoryPaginatedResult = {
  readonly __typename?: 'AuditionHistoryPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of AuditionHistory objects */
  readonly objects: ReadonlyArray<gqlAuditionHistory>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlAuditionHistorySessionGroupItem = {
  readonly __typename?: 'AuditionHistorySessionGroupItem';
  readonly auditionSession: gqlAuditionHistorySessionItem;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlAuditionHistorySessionItem = {
  readonly __typename?: 'AuditionHistorySessionItem';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlAuditionPagedListSearch = {
  readonly __typename?: 'AuditionPagedListSearch';
  /** Pagination Next Page Key */
  readonly after?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly data: ReadonlyArray<gqlMediaSubRequest>;
  /** Artist does not pay for YOUR_AUDITIONS. */
  readonly isBasic: Scalars['Boolean'];
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Audition Presentation input type */
export type gqlAuditionPresentationInput = {
  /** List of linked audition sessions. Required for Live Presentation */
  readonly auditionSessionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly isLive?: InputMaybe<Scalars['Boolean']>;
  /** Name for audition presentation */
  readonly name: Scalars['String'];
  /** Project ID */
  readonly projectId: Scalars['Int'];
};

/** Audition session */
export type gqlAuditionSession = {
  readonly __typename?: 'AuditionSession';
  /** Created date of audition session */
  readonly created: Scalars['DateTime'];
  /** Returns Account object who`s created the audition session */
  readonly createdBy?: Maybe<gqlAccount>;
  /** Indicates that the audition session should be hidden */
  readonly hidden?: Maybe<Scalars['Boolean']>;
  /** ID of audition session */
  readonly id: Scalars['Int'];
  /** Name of audition session */
  readonly name?: Maybe<Scalars['String']>;
  /** Note of audition session */
  readonly note?: Maybe<Scalars['String']>;
  /** Order of appearence of audition session */
  readonly order: Scalars['Int'];
  /** Project id */
  readonly projectId?: Maybe<Scalars['Int']>;
  /** Returns Forms associated to Media Request */
  readonly requestForms?: Maybe<gqlRequestForms>;
  /** Gets AuditionSource object */
  readonly source?: Maybe<gqlAuditionSource>;
  /** Submissions Count */
  readonly submissionCount?: Maybe<Scalars['BigInt']>;
  /** Virtual Audition Details */
  readonly virtualAudition?: Maybe<gqlScheduleVirtualAudition>;
};

export type gqlAuditionSessionCreateInput = {
  /** ID of the creator */
  readonly createdBy?: InputMaybe<Scalars['Int']>;
  /** Indicates that the audition session should be hidden */
  readonly hidden?: InputMaybe<Scalars['Boolean']>;
  /** Name of audition session */
  readonly name: Scalars['String'];
  /** ID of the project to which will be assigned audition session */
  readonly projectId: Scalars['Int'];
  /** Source of audition session */
  readonly sourceCode: Scalars['String'];
};

export type gqlAuditionSessionFilterInput = {
  readonly searchKey?: InputMaybe<Scalars['String']>;
};

/** Paginated result with [AuditionSession] objects */
export type gqlAuditionSessionPaginatedResult = {
  readonly __typename?: 'AuditionSessionPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of AuditionSession objects */
  readonly objects: ReadonlyArray<gqlAuditionSession>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Sort condition for audition session */
export type gqlAuditionSessionSortInstructionInput = {
  /** Sort by */
  readonly sortBy?: InputMaybe<Scalars['String']>;
  /** Sort by */
  readonly sortOrder?: InputMaybe<Scalars['String']>;
};

export type gqlAuditionSessionUpdateInput = {
  /** Indicates that the audition session should be hidden */
  readonly hidden?: InputMaybe<Scalars['Boolean']>;
  /** ID of existing audition submission */
  readonly id: Scalars['Int'];
  /** Name of audition session */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Note of audition session */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
  /** Source of audition session */
  readonly sourceCode?: InputMaybe<Scalars['String']>;
};

/** AuditionSource object */
export type gqlAuditionSource = {
  readonly __typename?: 'AuditionSource';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Audition Submission Snapshot input type */
export type gqlAuditionSubmissionSnapshotInput = {
  /** Snapshot Id */
  readonly snapshotId: Scalars['Int'];
  /** Submission Ids */
  readonly submissionId: Scalars['BigInt'];
};

/** Audition Talent Pool */
export type gqlAuditionTalentPool = {
  readonly __typename?: 'AuditionTalentPool';
  /** ID of audition session */
  readonly auditionSessionId?: Maybe<Scalars['Int']>;
  /** Slot End Time */
  readonly endTime?: Maybe<Scalars['DateTime']>;
  /** ID of audition session */
  readonly id?: Maybe<Scalars['ID']>;
  /** Schedule Date */
  readonly scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Slot Start Time */
  readonly startTime?: Maybe<Scalars['DateTime']>;
  /** List of Submissions */
  readonly submissions?: Maybe<ReadonlyArray<gqlSubmission>>;
};

/** Auditiontype object */
export type gqlAuditionType = {
  readonly __typename?: 'AuditionType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlAuditionsFilterInput = {
  /** Filters by casting orgs */
  readonly orgs?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Filters by profiles */
  readonly profiles?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Filters by projects */
  readonly projects?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export enum gqlAuditionsGroupBy {
  castingCompanyName = 'castingCompanyName',
  projectName = 'projectName',
  talentName = 'talentName'
}

export type gqlAuditionsGroups = {
  readonly __typename?: 'AuditionsGroups';
  /** Pagination Next Page Key */
  readonly after?: Maybe<ReadonlyArray<gqlGroupLastKey>>;
  readonly groups: ReadonlyArray<gqlAuditionGrouping>;
  /** Artist does not pay for YOUR_AUDITIONS. */
  readonly isBasic: Scalars['Boolean'];
  readonly totalCount: Scalars['Float'];
};

/** Organization info */
export type gqlAuditionsOrganization = {
  readonly __typename?: 'AuditionsOrganization';
  /** Organization ID */
  readonly id: Scalars['Int'];
  /** Logo Media ID */
  readonly logoMediaId?: Maybe<Scalars['Int']>;
  /** Organization name */
  readonly name: Scalars['String'];
};

export type gqlAuditionsSearchSortOptionsInput = {
  /** Field name by which should be sorted */
  readonly orderColumn: gqlAuditionsSortColumn;
  /** Sort order value(asc or desc). Default value: asc */
  readonly orderDirection?: InputMaybe<gqlSortOrder>;
};

export enum gqlAuditionsSortColumn {
  castingCompanyName = 'castingCompanyName',
  projectName = 'projectName',
  talentName = 'talentName',
  updatedAt = 'updatedAt'
}

export type gqlAuthToken = {
  readonly __typename?: 'AuthToken';
  readonly access: Scalars['String'];
  readonly refresh: Scalars['String'];
};

export type gqlAuthTokenItem = {
  readonly __typename?: 'AuthTokenItem';
  readonly access?: Maybe<Scalars['String']>;
  readonly refresh?: Maybe<Scalars['String']>;
};

/** Context object for base email context */
export type gqlBaseEmailContext = {
  readonly __typename?: 'BaseEmailContext';
  readonly buttonText: Scalars['String'];
  readonly buttonURL: Scalars['String'];
  readonly heading: Scalars['String'];
  readonly receiverName: Scalars['String'];
  readonly roleCode: ReadonlyArray<Scalars['String']>;
};

export type gqlBaseEmailContextNotificationContextAndRecipient = {
  readonly __typename?: 'BaseEmailContextNotificationContextAndRecipient';
  readonly context: gqlBaseEmailContext;
  readonly to?: Maybe<Scalars['String']>;
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toOrgId?: Maybe<Scalars['Int']>;
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

/** Base object */
export type gqlBasicSetting = {
  readonly __typename?: 'BasicSetting';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** BAU Account Object */
export type gqlBauAccount = {
  readonly __typename?: 'BauAccount';
  readonly accountStatusId: Scalars['ID'];
  readonly countryId?: Maybe<Scalars['Float']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly hasPreMappedOrg?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly isLegacyEmailUnique?: Maybe<Scalars['Boolean']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly legacyAccountID?: Maybe<Scalars['Float']>;
  readonly legacyClaim?: Maybe<Scalars['Float']>;
  readonly legacyEmail?: Maybe<Scalars['String']>;
  readonly legacyInstance?: Maybe<Scalars['String']>;
  readonly legacyLogin?: Maybe<Scalars['String']>;
  readonly legacyPersonID?: Maybe<Scalars['Float']>;
  readonly onboardingStatus?: Maybe<Scalars['String']>;
  readonly onboardingStatusId: Scalars['ID'];
  readonly paypalAccountCode?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly primaryAccountId?: Maybe<Scalars['Float']>;
  readonly recurlyAccountCode?: Maybe<Scalars['String']>;
  readonly roles?: Maybe<ReadonlyArray<gqlSystemRole>>;
  readonly status?: Maybe<Scalars['String']>;
  readonly timeZoneId?: Maybe<Scalars['Float']>;
  readonly vip?: Maybe<Scalars['Boolean']>;
};

/** Creates or update a user account based on given input values */
export type gqlBauAccountInput = {
  readonly audience: Scalars['String'];
  readonly countryId: Scalars['ID'];
  /** Closest casting market for PC user */
  readonly dmaId?: InputMaybe<Scalars['ID']>;
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly password: Scalars['String'];
  readonly phone: Scalars['String'];
  readonly termsOfUse: Scalars['Boolean'];
  readonly timeZoneId: Scalars['Float'];
};

/** BAU Artist Object */
export type gqlBauArtist = {
  readonly __typename?: 'BauArtist';
  readonly firstName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly profiles?: Maybe<ReadonlyArray<gqlBauProfile>>;
};

/** Update or Create B21 Profile from BAU */
export type gqlBauArtistInput = {
  /** Artist first name */
  readonly firstName: Scalars['String'];
  /** Is artist minor */
  readonly isMinor: Scalars['Boolean'];
  /** Artist last name */
  readonly lastName: Scalars['String'];
  /** Personal Profile Info */
  readonly personalProfile: gqlBauPersonalProfileInput;
  /** Primary Artist ID */
  readonly primaryArtistId?: InputMaybe<Scalars['Int']>;
  /** Array of Bau Profiles */
  readonly profiles: ReadonlyArray<gqlBauProfileInput>;
};

/** Organization Object */
export type gqlBauCountry = {
  readonly __typename?: 'BauCountry';
  /** Country code */
  readonly code?: Maybe<Scalars['String']>;
  /** Continent id */
  readonly continentId?: Maybe<Scalars['Int']>;
  /** Has dma */
  readonly hasDma?: Maybe<Scalars['Boolean']>;
  /** Has states */
  readonly hasState?: Maybe<Scalars['Boolean']>;
  /** Country id */
  readonly id: Scalars['Int'];
  /** Country name */
  readonly name?: Maybe<Scalars['String']>;
  /** Supporting country id */
  readonly supportingCountryId?: Maybe<Scalars['Int']>;
};

export type gqlBauHeight = {
  readonly __typename?: 'BauHeight';
  readonly cm: Scalars['Int'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly imperial: Scalars['String'];
  readonly inch: Scalars['Float'];
};

export type gqlBauLoginByLegacyDataInput = {
  /** Recovery Type EMAILANDPHONE|PROJECT|STAGE */
  readonly accountRecoveryType: gqlAccountRecoveryType;
  readonly email: Scalars['String'];
  /** project name for validation */
  readonly instance?: InputMaybe<Scalars['String']>;
  /** additional account id */
  readonly migratedAccountID?: InputMaybe<Scalars['Float']>;
  readonly phoneEnding: Scalars['String'];
  /** project name for validation */
  readonly projectName?: InputMaybe<Scalars['String']>;
  /** stage name for validation */
  readonly stageName?: InputMaybe<Scalars['String']>;
  readonly username: Scalars['String'];
};

/** Creates a user account based on given input values */
export type gqlBauLoginInput = {
  readonly instance: Scalars['String'];
  readonly migratedAccountID: Scalars['Float'];
  readonly password: Scalars['String'];
  readonly username: Scalars['String'];
};

export type gqlBauMedia = {
  readonly __typename?: 'BauMedia';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly filename?: Maybe<Scalars['String']>;
  readonly guid: Scalars['String'];
  readonly height?: Maybe<Scalars['Int']>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly length?: Maybe<Scalars['Float']>;
  readonly mediaFileKey?: Maybe<Scalars['String']>;
  readonly mediaId: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly updated?: Maybe<Scalars['DateTime']>;
  readonly width?: Maybe<Scalars['Int']>;
};

export type gqlBauPcAccountUpdateInput = {
  readonly companyName?: InputMaybe<Scalars['String']>;
  readonly facebookLink?: InputMaybe<Scalars['String']>;
  readonly imdbLink?: InputMaybe<Scalars['String']>;
  readonly instagramLink?: InputMaybe<Scalars['String']>;
  readonly institutionName?: InputMaybe<Scalars['String']>;
  readonly portfolio?: InputMaybe<Scalars['String']>;
  readonly professionalBio?: InputMaybe<Scalars['String']>;
  readonly twitterLink?: InputMaybe<Scalars['String']>;
  readonly vimeoLink?: InputMaybe<Scalars['String']>;
  readonly youtubeLink?: InputMaybe<Scalars['String']>;
};

/** Update or Create B21 Profile from BAU */
export type gqlBauPersonalProfileInput = {
  /** Artist ID */
  readonly artistId?: InputMaybe<Scalars['Int']>;
  /** Profile max playable age */
  readonly maxPlayableAge: Scalars['Int'];
  /** Profile min playable age */
  readonly minPlayableAge: Scalars['Int'];
  /** Profile from REP|UNREP|NEW */
  readonly personalProfileFrom: gqlProfileFromEnum;
  /** Profile primary appearance ID */
  readonly primaryAppearanceId: Scalars['Int'];
  /** Profile primary location ID */
  readonly primaryLocationId: Scalars['Int'];
  /** Profile ID */
  readonly profileId: Scalars['Int'];
};

/** BAU Profile Object */
export type gqlBauProfile = {
  readonly __typename?: 'BauProfile';
  /** Profile Artist ID */
  readonly artistId?: Maybe<Scalars['Int']>;
  /** Profile  date of birth */
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** Profile Division ID */
  readonly divisionId?: Maybe<Scalars['Int']>;
  /** Profile Division Name */
  readonly divisionName?: Maybe<Scalars['String']>;
  /** Profile height */
  readonly height?: Maybe<gqlBauHeight>;
  /** Profile ID */
  readonly id: Scalars['Int'];
  /** Profile is Personal? */
  readonly isPersonal?: Maybe<Scalars['Boolean']>;
  /** Legacy Profile ID */
  readonly legacyProfileId?: Maybe<Scalars['String']>;
  /** Profile Medias */
  readonly medias?: Maybe<ReadonlyArray<gqlBauMedia>>;
  /** Profile Playable Max Age */
  readonly playableMaxAge?: Maybe<Scalars['Int']>;
  /** Profile Playable Min Age */
  readonly playableMinAge?: Maybe<Scalars['Int']>;
  /** Profile Primary Working Location ID */
  readonly primaryWorkingLocationId?: Maybe<Scalars['Int']>;
  /** Profile Primary Working Location Name */
  readonly primaryWorkingLocationName?: Maybe<Scalars['String']>;
  /** Profile Stage Name */
  readonly stageName?: Maybe<Scalars['String']>;
  /** Profile Status Object */
  readonly status?: Maybe<gqlProfileStatus>;
  /** Profile talent union name */
  readonly talentUnions?: Maybe<ReadonlyArray<gqlBauTalentUnion>>;
  /** Profile weight */
  readonly weight?: Maybe<gqlBauWeight>;
};

/** Update or Create B21 Profile from BAU */
export type gqlBauProfileInput = {
  /** Artist ID */
  readonly artistId?: InputMaybe<Scalars['Int']>;
  /** Profile division (organization) ID */
  readonly divisionId?: InputMaybe<Scalars['Int']>;
  /** Profile max playable age */
  readonly maxPlayableAge: Scalars['Int'];
  /** Profile min playable age */
  readonly minPlayableAge: Scalars['Int'];
  /** Profile primary appearance ID */
  readonly primaryAppearanceId: Scalars['Int'];
  /** Profile primary location ID */
  readonly primaryLocationId: Scalars['Int'];
  /** Profile ID */
  readonly profileId: Scalars['Int'];
};

/** Update or Create B21 Profile from BAU */
export type gqlBauProfileUpdateInput = {
  /** Array of Bau Artists */
  readonly artists: ReadonlyArray<gqlBauArtistInput>;
};

/** Talent Union object */
export type gqlBauTalentUnion = {
  readonly __typename?: 'BauTalentUnion';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlBauWeight = {
  readonly __typename?: 'BauWeight';
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly kg: Scalars['Float'];
  readonly lbs: Scalars['Int'];
};

export enum gqlBillingCode {
  cb_annual = 'cb_annual',
  cb_m2m = 'cb_m2m',
  cb_semiannual = 'cb_semiannual',
  cbmh_annual = 'cbmh_annual',
  cbmh_m2m = 'cbmh_m2m',
  cbmh_semiannual = 'cbmh_semiannual',
  cbmhpu_annual = 'cbmhpu_annual',
  cbmhpu_m2m = 'cbmhpu_m2m',
  cbmhpu_semiannual = 'cbmhpu_semiannual',
  cbpu_annual = 'cbpu_annual',
  cbpu_m2m = 'cbpu_m2m',
  cbpu_semiannual = 'cbpu_semiannual',
  concierge_annual = 'concierge_annual',
  concierge_m2m = 'concierge_m2m',
  concierge_semiannual = 'concierge_semiannual',
  essential_annual = 'essential_annual',
  essential_comped = 'essential_comped',
  essential_m2m = 'essential_m2m',
  la_cb_annual = 'la_cb_annual',
  la_cb_m2m = 'la_cb_m2m',
  la_cb_semiannual = 'la_cb_semiannual',
  la_cbmh_annual = 'la_cbmh_annual',
  la_cbmh_m2m = 'la_cbmh_m2m',
  la_cbmh_semiannual = 'la_cbmh_semiannual',
  la_cbmu_annual = 'la_cbmu_annual',
  la_cbmu_m2m = 'la_cbmu_m2m',
  la_cbmu_semiannual = 'la_cbmu_semiannual',
  la_mh_annual = 'la_mh_annual',
  la_mh_m2m = 'la_mh_m2m',
  la_mh_semiannual = 'la_mh_semiannual',
  mh_annual = 'mh_annual',
  mh_m2m = 'mh_m2m',
  mh_semiannual = 'mh_semiannual',
  mhpu_annual = 'mhpu_annual',
  mhpu_m2m = 'mhpu_m2m',
  mhpu_semiannual = 'mhpu_semiannual',
  premium_annual = 'premium_annual',
  premium_comped = 'premium_comped',
  premium_m2m = 'premium_m2m',
  pu_annual = 'pu_annual',
  pu_m2m = 'pu_m2m',
  pu_semiannual = 'pu_semiannual',
  registration = 'registration',
  ultimate_annual = 'ultimate_annual',
  ultimate_comped = 'ultimate_comped',
  ultimate_m2m = 'ultimate_m2m'
}

/** Billing information */
export type gqlBillingInfo = {
  readonly __typename?: 'BillingInfo';
  readonly cardType?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly isBackupPaymentMethod?: Maybe<Scalars['Boolean']>;
  readonly isPrimaryPaymentMethod?: Maybe<Scalars['Boolean']>;
  readonly lastFour?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly month?: Maybe<Scalars['Int']>;
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
};

export enum gqlBillingItemCode {
  AUDIO_UPLOAD = 'AUDIO_UPLOAD',
  BENEFITS = 'BENEFITS',
  BENEFITS_PLUS = 'BENEFITS_PLUS',
  CUSTOM_URL_ACCESS = 'CUSTOM_URL_ACCESS',
  MEDIA_UPLOAD = 'MEDIA_UPLOAD',
  MOBILE_REELS_ACCESS = 'MOBILE_REELS_ACCESS',
  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  ROLE_TRACKER_ACCESS = 'ROLE_TRACKER_ACCESS',
  STORAGE_LIMIT_MB = 'STORAGE_LIMIT_MB',
  SUBMISSION = 'SUBMISSION',
  SUBMISSION_TRENDS = 'SUBMISSION_TRENDS',
  SYSTEM_ACCESS = 'SYSTEM_ACCESS',
  TALENT_SCOUT_ACCESS = 'TALENT_SCOUT_ACCESS',
  VIDEO_UPLOAD = 'VIDEO_UPLOAD',
  YOUR_AUDITIONS = 'YOUR_AUDITIONS',
  registration = 'registration'
}

/** Billing Item */
export type gqlBillingItemModel = {
  readonly __typename?: 'BillingItemModel';
  readonly availableCredit?: Maybe<Scalars['Int']>;
  readonly code: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly priceInCents?: Maybe<Scalars['Int']>;
  readonly qty?: Maybe<Scalars['Int']>;
};

/** Billing Plan */
export type gqlBillingPlan = {
  readonly __typename?: 'BillingPlan';
  readonly billingCode: Scalars['String'];
  readonly code: Scalars['String'];
  readonly currency: gqlCurrency;
  readonly id: Scalars['Int'];
  readonly isBasePlan: Scalars['Boolean'];
  readonly isComped: Scalars['Boolean'];
  readonly level: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
  readonly term: gqlBillingPlanTerm;
  readonly trialLengthDays: Scalars['Int'];
};

/** Billing Plan Term */
export type gqlBillingPlanTerm = {
  readonly __typename?: 'BillingPlanTerm';
  readonly code: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlBillingPlansInput = {
  readonly billingPath?: InputMaybe<Scalars['String']>;
};

/** Custom URL Slugs cannot contain this blocked keyword */
export type gqlBlockedKeyword = {
  readonly __typename?: 'BlockedKeyword';
  /** Reason why this keyword is blocked - a subset of CustomUrlSlugExpiredReason */
  readonly blockedReason: gqlCustomUrlSlugExpiredReason;
  /** Optional note for why this keyword is blocked */
  readonly blockedReasonNote?: Maybe<Scalars['String']>;
  /** The account that created this blocked keyword */
  readonly createdByAccount: gqlAccount;
  /** Date when the keyword was created */
  readonly createdDate: Scalars['DateTime'];
  /** Keyword Id */
  readonly id: Scalars['ID'];
  /** Keyword that custom slugs may not use */
  readonly keyword: Scalars['String'];
};

/** Paginated result with [BlockedKeyword] objects */
export type gqlBlockedKeywordPaginatedResult = {
  readonly __typename?: 'BlockedKeywordPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of BlockedKeyword objects */
  readonly objects: ReadonlyArray<gqlBlockedKeyword>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlBookingCallListDivisionSubmissionsModel = {
  readonly __typename?: 'BookingCallListDivisionSubmissionsModel';
  readonly id?: Maybe<Scalars['Float']>;
  readonly orgName?: Maybe<Scalars['String']>;
  readonly orgParentName?: Maybe<Scalars['String']>;
  readonly orgPhone?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly submissions?: Maybe<ReadonlyArray<gqlBookingCallListPersonalProfilesModel>>;
};

export type gqlBookingCallListPersonalProfilesModel = {
  readonly __typename?: 'BookingCallListPersonalProfilesModel';
  readonly cover?: Maybe<gqlReportMediaModel>;
  readonly deletedProfile: Scalars['Boolean'];
  readonly id?: Maybe<Scalars['Int']>;
  readonly isPersonal?: Maybe<Scalars['Boolean']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly roleName: Scalars['String'];
  readonly roleRateSummary?: Maybe<Scalars['String']>;
  readonly snapshotMedia?: Maybe<gqlReportMediaModel>;
  readonly stageName?: Maybe<Scalars['String']>;
  readonly talentUnion?: Maybe<ReadonlyArray<gqlTalentUnionModel>>;
};

/** Booking Call List Report Payload */
export type gqlBookingCallListReportPayloadModel = {
  readonly __typename?: 'BookingCallListReportPayloadModel';
  readonly castingCompanyLogo?: Maybe<gqlReportMediaModel>;
  readonly divisionSubmissions: ReadonlyArray<gqlBookingCallListDivisionSubmissionsModel>;
  readonly personalSubmissions: ReadonlyArray<gqlBookingCallListPersonalProfilesModel>;
  readonly projectInfo: gqlProjectReportInfoModel;
};

export type gqlBookingCallListsReportModel = {
  readonly __typename?: 'BookingCallListsReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlBookingCallListReportPayloadModel>;
  readonly projectId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gqlBust = {
  readonly __typename?: 'Bust';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

export type gqlCdWorksheetFilterInput = {
  readonly hasComments?: InputMaybe<Scalars['Boolean']>;
  readonly hasPicks?: InputMaybe<Scalars['Boolean']>;
  readonly hasRepresentation?: InputMaybe<Scalars['Boolean']>;
  readonly otherRequest?: InputMaybe<gqlSubmissionRequestSearchInput>;
  readonly presentationIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly projectId: Scalars['Int'];
  readonly representativeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly representativeListIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly scheduleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly scheduleRequest?: InputMaybe<gqlSubmissionScheduleRequestSearchInput>;
  readonly sessionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly stageName?: InputMaybe<Scalars['String']>;
  readonly statusCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Calendar Type object */
export type gqlCalendarType = {
  readonly __typename?: 'CalendarType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlCalendarTypeEnum {
  AUDITION = 'AUDITION',
  CALLBACK = 'CALLBACK'
}

/** Says whether cancel was successful */
export type gqlCancelBenefits = {
  readonly __typename?: 'CancelBenefits';
  readonly success: Scalars['Boolean'];
};

export type gqlCancelBenefitsInput = {
  readonly cancelReason?: InputMaybe<Scalars['String']>;
  readonly zip: Scalars['String'];
};

export type gqlCancelRequestInput = {
  readonly note?: InputMaybe<Scalars['String']>;
  readonly submissionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

/** Cancel Schedule Input */
export type gqlCancelScheduleInput = {
  /** Schedule cancellation message */
  readonly cancellationMessage?: InputMaybe<Scalars['String']>;
  /** Schedule ID */
  readonly scheduleId: Scalars['ID'];
};

/** Search Casting Billboard Input */
export type gqlCastingBillboardSearchInput = {
  readonly autofill?: InputMaybe<Scalars['Boolean']>;
  readonly ethnicAppearanceIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly ethnicAppearances?: InputMaybe<ReadonlyArray<gqlEthnicAppearanceEnum>>;
  readonly excludeSexualSituations?: InputMaybe<Scalars['Boolean']>;
  readonly genderAppearanceIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly genderAppearances?: InputMaybe<ReadonlyArray<gqlGenderAppearanceEnum>>;
  /** Keywords */
  readonly keywords?: InputMaybe<Scalars['String']>;
  readonly payPrefences?: InputMaybe<ReadonlyArray<gqlPayPreferencesEnum>>;
  readonly payPreferenceIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Playable Age Range */
  readonly playableAge?: InputMaybe<gqlAgeRangeInput>;
  readonly profileUnionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileUnions?: InputMaybe<ReadonlyArray<gqlTalentUnionEnum>>;
  readonly projectId?: InputMaybe<Scalars['Int']>;
  readonly projectTypeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly roleId?: InputMaybe<Scalars['Int']>;
  /** Search by Project/Role Names and Descriptions */
  readonly roleProjectKeywordSearch?: InputMaybe<gqlRoleProjectKeywordSearchEnum>;
  readonly roleTargetIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly roleTargets?: InputMaybe<ReadonlyArray<gqlRoleTargetsEnum>>;
  readonly showRolesNoLongerAcceptingSubmissions?: InputMaybe<Scalars['Boolean']>;
  readonly workingLocations?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type gqlCastingListFilterInput = {
  readonly name?: InputMaybe<Scalars['String']>;
};

export type gqlCastingListProfile = {
  readonly __typename?: 'CastingListProfile';
  readonly audios?: Maybe<ReadonlyArray<gqlCastingListProfileMedia>>;
  readonly credits?: Maybe<ReadonlyArray<gqlCredit>>;
  readonly id: Scalars['ID'];
  readonly listId: Scalars['ID'];
  readonly note: Scalars['String'];
  readonly photos?: Maybe<ReadonlyArray<gqlCastingListProfileMedia>>;
  readonly profile: gqlProfile;
  readonly videos?: Maybe<ReadonlyArray<gqlCastingListProfileMedia>>;
};

export type gqlCastingListProfileMedia = {
  readonly __typename?: 'CastingListProfileMedia';
  readonly isPrimary: Scalars['Boolean'];
  readonly media: gqlMedia;
};

export type gqlCastingListProfilePagedList = {
  readonly __typename?: 'CastingListProfilePagedList';
  readonly data: ReadonlyArray<gqlCastingListProfile>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type gqlCdMediaResponseCounts = {
  readonly __typename?: 'CdMediaResponseCounts';
  readonly confirmed: Scalars['Int'];
  readonly declined: Scalars['Int'];
  readonly notSent: Scalars['Int'];
  readonly receivedResponse: Scalars['Int'];
  readonly sentPending: Scalars['Int'];
};

export type gqlChangeAuditionGroupTalentOrderInput = {
  /** Submission ID behind which the item should be moved. If not passed will be moved to the beginning */
  readonly behindSubmissionId?: InputMaybe<Scalars['BigInt']>;
  readonly groupId: Scalars['Int'];
  readonly submissionId: Scalars['BigInt'];
};

export type gqlChangeAuditionSessionTalentRoleNameInput = {
  readonly entityId: Scalars['BigInt'];
  readonly entityTypeCode: gqlEntityTypeEnum;
  readonly roleId: Scalars['Int'];
  readonly roleName: Scalars['String'];
};

/** Create Chat Message Input */
export type gqlChatMessageCreateInput = {
  readonly messageBody: Scalars['String'];
  readonly topicKey: Scalars['String'];
};

/** Chat Message */
export type gqlChatMessageModel = {
  readonly __typename?: 'ChatMessageModel';
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly messageBody: Scalars['String'];
  readonly messageType: gqlChatMessageType;
  readonly ownMessage: Scalars['Boolean'];
  readonly senderAccount?: Maybe<gqlAccount>;
  readonly senderAccountId?: Maybe<Scalars['Int']>;
  readonly senderOrgId?: Maybe<Scalars['Int']>;
  readonly senderOrganization?: Maybe<gqlOrganization>;
  readonly topicKey: Scalars['String'];
};

export enum gqlChatMessageType {
  System = 'System',
  User = 'User'
}

export type gqlChatSenderAccount = {
  readonly __typename?: 'ChatSenderAccount';
  readonly firstName?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export type gqlChatTopic = {
  readonly __typename?: 'ChatTopic';
  readonly lastUserMessage?: Maybe<gqlChatMessageModel>;
  readonly topicKey: Scalars['String'];
  readonly unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type gqlChestSize = {
  readonly __typename?: 'ChestSize';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

/** Dress size object */
export type gqlClothesSize = {
  readonly __typename?: 'ClothesSize';
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['Int'];
  /** Size for AU */
  readonly sizeAU: Scalars['String'];
  /** Size for EU */
  readonly sizeEU: Scalars['String'];
  /** Numeric size */
  readonly sizeNumeric: Scalars['Int'];
  /** Size for UK */
  readonly sizeUK: Scalars['String'];
  /** Size for US */
  readonly sizeUS: Scalars['String'];
};

/** Coat object */
export type gqlCoat = {
  readonly __typename?: 'Coat';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Creates a user account based on given input values */
export type gqlCollaboratorAccountCreateInput = {
  readonly countryId: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly invitationId: Scalars['String'];
  readonly languageLocaleId?: InputMaybe<Scalars['Int']>;
  readonly lastName: Scalars['String'];
  readonly password: Scalars['String'];
  readonly termsOfUse: Scalars['Boolean'];
  readonly timeZoneId: Scalars['Int'];
};

export type gqlCollaboratorDetails = {
  readonly __typename?: 'CollaboratorDetails';
  readonly collaboratorId?: Maybe<Scalars['Float']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

/** Creates an invite for a user to Collaborate on a project */
export type gqlCollaboratorInviteCreateInput = {
  readonly email: Scalars['String'];
  readonly projectId: Scalars['ID'];
};

export type gqlCollaboratorInviteFromOrgDetails = {
  readonly __typename?: 'CollaboratorInviteFromOrgDetails';
  readonly orgId?: Maybe<Scalars['ID']>;
  /** The name of the organization that the invite is from */
  readonly orgName?: Maybe<Scalars['String']>;
};

export type gqlCollaboratorInviteProjectDetails = {
  readonly __typename?: 'CollaboratorInviteProjectDetails';
  readonly internalName?: Maybe<Scalars['String']>;
  readonly projectId?: Maybe<Scalars['Float']>;
  readonly projectName?: Maybe<Scalars['String']>;
};

export enum gqlCollaboratorInviteStatusCode {
  INVITED = 'INVITED',
  JOINED = 'JOINED',
  REVOKED = 'REVOKED'
}

/** Revokes Access For User from Organization */
export type gqlCollaboratorRevokeAccessFromOrgInput = {
  readonly email: Scalars['String'];
};

/** Revokes Access For User from Project */
export type gqlCollaboratorRevokeAccessFromProjectInput = {
  readonly inviteId: Scalars['ID'];
  readonly projectId: Scalars['ID'];
};

export type gqlCollaboratorsInOrganization = {
  readonly __typename?: 'CollaboratorsInOrganization';
  readonly collaboratorDetails?: Maybe<gqlCollaboratorDetails>;
  readonly collaboratorInviteeOrgDetails?: Maybe<gqlCollaboratorInviteFromOrgDetails>;
  readonly collaboratorsProjectDetails?: Maybe<ReadonlyArray<gqlCollaboratorInviteProjectDetails>>;
  readonly email?: Maybe<Scalars['String']>;
};


export type gqlCollaboratorsInOrganizationCollaboratorsProjectDetailsArgs = {
  pageProjects?: InputMaybe<gqlPageInput>;
};

/** Paginated result with [CollaboratorsInOrganization] objects */
export type gqlCollaboratorsInOrganizationPaginatedResult = {
  readonly __typename?: 'CollaboratorsInOrganizationPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of CollaboratorsInOrganization objects */
  readonly objects: ReadonlyArray<gqlCollaboratorsInOrganization>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlCollaboratorsInvite = {
  readonly __typename?: 'CollaboratorsInvite';
  readonly collaborator?: Maybe<gqlCollaboratorDetails>;
  readonly collaboratorInviteFromOrgDetails?: Maybe<gqlCollaboratorInviteFromOrgDetails>;
  readonly collaboratorInviteProjectDetails?: Maybe<gqlCollaboratorInviteProjectDetails>;
  readonly email: Scalars['String'];
  readonly inviteId: Scalars['String'];
  readonly inviteStatus: gqlCollaboratorInviteStatusCode;
};

/** Paginated result with [CollaboratorsInvite] objects */
export type gqlCollaboratorsInvitePaginatedResult = {
  readonly __typename?: 'CollaboratorsInvitePaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of CollaboratorsInvite objects */
  readonly objects: ReadonlyArray<gqlCollaboratorsInvite>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Company Info Model */
export type gqlCompanyInfoModel = {
  readonly __typename?: 'CompanyInfoModel';
  readonly logo?: Maybe<gqlReportMediaModel>;
  readonly name?: Maybe<Scalars['String']>;
  readonly parentName?: Maybe<Scalars['String']>;
};

/** Compensation object */
export type gqlCompensation = {
  readonly __typename?: 'Compensation';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlCompensationEnum {
  COPY = 'COPY',
  CREDIT = 'CREDIT',
  MEAL = 'MEAL',
  OTHER = 'OTHER'
}

export type gqlCompleteArtistMediaCreationInput = {
  readonly artistId: Scalars['Int'];
  readonly media: ReadonlyArray<gqlCompleteMediaCreationInputItem>;
};

export type gqlCompleteMediaCreationInputItem = {
  readonly duration?: InputMaybe<Scalars['String']>;
  readonly height?: InputMaybe<Scalars['String']>;
  readonly mediaId: Scalars['Int'];
  readonly size?: InputMaybe<Scalars['Int']>;
  readonly width?: InputMaybe<Scalars['String']>;
};

/** Conflict object */
export type gqlConflict = {
  readonly __typename?: 'Conflict';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlConflict>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** array of parent objects */
  readonly parentObject: ReadonlyArray<gqlConflict>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlConflictEnum {
  ACNE_PRODUCTS = 'ACNE_PRODUCTS',
  ADHESIVE_BANDAGES = 'ADHESIVE_BANDAGES',
  ADULT_INCONTINENCE = 'ADULT_INCONTINENCE',
  ADULT_NUTRITION = 'ADULT_NUTRITION',
  AERONAUTICAL_COMPANIES = 'AERONAUTICAL_COMPANIES',
  AESTHETIC_FACIAL_INJECTABLES = 'AESTHETIC_FACIAL_INJECTABLES',
  AGRICULTURAL_CHEMICAL = 'AGRICULTURAL_CHEMICAL',
  AGRICULTURAL_FUNGICIDES = 'AGRICULTURAL_FUNGICIDES',
  AGRICULTURAL_PRODUCTS = 'AGRICULTURAL_PRODUCTS',
  AGRICULTURE = 'AGRICULTURE',
  AIRLINES = 'AIRLINES',
  AIR_CARE = 'AIR_CARE',
  AIR_FILTERS = 'AIR_FILTERS',
  AIR_FRESHENERS = 'AIR_FRESHENERS',
  AIR_TRAVEL = 'AIR_TRAVEL',
  ALCOHOL = 'ALCOHOL',
  ALCOHOL_TOBACCO = 'ALCOHOL_TOBACCO',
  ALLERGY_MEDICATION = 'ALLERGY_MEDICATION',
  AMAZON = 'AMAZON',
  ANALPHYLAXIS = 'ANALPHYLAXIS',
  ANESTHESIA = 'ANESTHESIA',
  ANTACIDS = 'ANTACIDS',
  ANTIAGING = 'ANTIAGING',
  ANTICOAGULANTS = 'ANTICOAGULANTS',
  ANTIDEPRESSENT = 'ANTIDEPRESSENT',
  ANTIFUNGAL = 'ANTIFUNGAL',
  ANTIPERSPERANT_DEODORANT = 'ANTIPERSPERANT_DEODORANT',
  ANTIPLATELET = 'ANTIPLATELET',
  ANTIVIRALS = 'ANTIVIRALS',
  APPAREL = 'APPAREL',
  APPLIANCES = 'APPLIANCES',
  ARTHRITIS_MEDICATIONS = 'ARTHRITIS_MEDICATIONS',
  ASTHMA = 'ASTHMA',
  ATHLETIC_BRANDS_SPORTSWEAR = 'ATHLETIC_BRANDS_SPORTSWEAR',
  ATRIAL_FIBRILLATION = 'ATRIAL_FIBRILLATION',
  AUDIO_EQUIPMENT = 'AUDIO_EQUIPMENT',
  AUTOMOBILE_DEALERSHIPS = 'AUTOMOBILE_DEALERSHIPS',
  AUTOMOBILE_SERVICES = 'AUTOMOBILE_SERVICES',
  AUTOMOTIVE = 'AUTOMOTIVE',
  AUTO_INSURANCE = 'AUTO_INSURANCE',
  AUTO_PARTS = 'AUTO_PARTS',
  AUTO_RACING = 'AUTO_RACING',
  BABY_DIAPERS = 'BABY_DIAPERS',
  BABY_FORMULA = 'BABY_FORMULA',
  BABY_PRODUCTS = 'BABY_PRODUCTS',
  BACKGROUND_CHECKING = 'BACKGROUND_CHECKING',
  BAKING_PRODUCTS = 'BAKING_PRODUCTS',
  BANKING = 'BANKING',
  BANKS = 'BANKS',
  BATTERIES = 'BATTERIES',
  BEAUTY = 'BEAUTY',
  BEDS__MATTRESSES_AND_PILLOWS = 'BEDS__MATTRESSES_AND_PILLOWS',
  BEERS = 'BEERS',
  BIG_BOX_STORES = 'BIG_BOX_STORES',
  BLADES_AND_RAZORS = 'BLADES_AND_RAZORS',
  BODY_LOTION = 'BODY_LOTION',
  BONE_DENSITY = 'BONE_DENSITY',
  BOTTLED_WATER = 'BOTTLED_WATER',
  BOWLING = 'BOWLING',
  BREAD = 'BREAD',
  BREAKFAST_CEREAL = 'BREAKFAST_CEREAL',
  BROKERAGE = 'BROKERAGE',
  BROKERAGE_SERVICES = 'BROKERAGE_SERVICES',
  CABLE_SERVICE_PROVIDERS = 'CABLE_SERVICE_PROVIDERS',
  CALLING_CARDS = 'CALLING_CARDS',
  CAMERA_COMPANIES = 'CAMERA_COMPANIES',
  CANCER = 'CANCER',
  CANDY = 'CANDY',
  CANNED_FOODS = 'CANNED_FOODS',
  CARBONATED_BEVERAGES = 'CARBONATED_BEVERAGES',
  CARDIOVASCULAR = 'CARDIOVASCULAR',
  CASH_ADVANCE = 'CASH_ADVANCE',
  CASINOS = 'CASINOS',
  CASUAL_APPAREL = 'CASUAL_APPAREL',
  CASUAL_DINING = 'CASUAL_DINING',
  CAT_FOOD = 'CAT_FOOD',
  CHAIN_RESTAURANTS = 'CHAIN_RESTAURANTS',
  CHECK_CASHING = 'CHECK_CASHING',
  CHEESE = 'CHEESE',
  CHEWING_GUMS = 'CHEWING_GUMS',
  CHILDREN = 'CHILDREN',
  CHILDRENS_HOSPITALS = 'CHILDRENS_HOSPITALS',
  CHIPS_SEMICONDUCTOR_MANUFACTURERS = 'CHIPS_SEMICONDUCTOR_MANUFACTURERS',
  CHIPS_SNACKS = 'CHIPS_SNACKS',
  CHOCOLATE = 'CHOCOLATE',
  CHOLESTEROL = 'CHOLESTEROL',
  CHRISTMAS = 'CHRISTMAS',
  CHROHNS_DISEASE = 'CHROHNS_DISEASE',
  CHRONIC_BRONCHITIS = 'CHRONIC_BRONCHITIS',
  CIGARETTES = 'CIGARETTES',
  CLEANING_PRODUCTS = 'CLEANING_PRODUCTS',
  CLINICS = 'CLINICS',
  CLOTHING = 'CLOTHING',
  COFFEE = 'COFFEE',
  COFFEE_MAKERS = 'COFFEE_MAKERS',
  COLA = 'COLA',
  COLD_FLU_MEDICATIONS = 'COLD_FLU_MEDICATIONS',
  COLLEGES = 'COLLEGES',
  COMPRESSION_WEAR = 'COMPRESSION_WEAR',
  COMPUTER_HARDWARE = 'COMPUTER_HARDWARE',
  CONCRETE = 'CONCRETE',
  CONDIMENTS = 'CONDIMENTS',
  CONFECTIONARY = 'CONFECTIONARY',
  CONSUMER_ELECTRONICS = 'CONSUMER_ELECTRONICS',
  CONSUMER_TECHNOLOGY = 'CONSUMER_TECHNOLOGY',
  CONTRACEPTION = 'CONTRACEPTION',
  CONVENVIENCE_STORES = 'CONVENVIENCE_STORES',
  COOKIES = 'COOKIES',
  COOKING_SPRAYS = 'COOKING_SPRAYS',
  CORPORATE_BANKING = 'CORPORATE_BANKING',
  COSMETICS = 'COSMETICS',
  COSMETIC_INJECTABLES = 'COSMETIC_INJECTABLES',
  COUGH_COLD_REMEDIES = 'COUGH_COLD_REMEDIES',
  COUGH_DROPS = 'COUGH_DROPS',
  CRACKERS = 'CRACKERS',
  CRAFT_STORES = 'CRAFT_STORES',
  CREDIT_DEBIT_CARDS = 'CREDIT_DEBIT_CARDS',
  CREDIT_UNIONS = 'CREDIT_UNIONS',
  CRUISE_LINES = 'CRUISE_LINES',
  CURLING_IRONS = 'CURLING_IRONS',
  DAIRY = 'DAIRY',
  DATING_SITES = 'DATING_SITES',
  DEALERSHIPS = 'DEALERSHIPS',
  DELIVERY_SERVICES = 'DELIVERY_SERVICES',
  DELI_MEATS = 'DELI_MEATS',
  DEMENTIA_PRODUCTS = 'DEMENTIA_PRODUCTS',
  DENTAL = 'DENTAL',
  DENTAL_PRODUCTS = 'DENTAL_PRODUCTS',
  DENTAL_SERVICES = 'DENTAL_SERVICES',
  DEPARTMENT_STORES = 'DEPARTMENT_STORES',
  DERMATOLOGY = 'DERMATOLOGY',
  DESSERTS = 'DESSERTS',
  DETERGENT = 'DETERGENT',
  DIABETES_MEDICATION = 'DIABETES_MEDICATION',
  DIABETIC_PERIPHERAL_NEUROPATHY_DRUGS = 'DIABETIC_PERIPHERAL_NEUROPATHY_DRUGS',
  DICED_TOMATOES = 'DICED_TOMATOES',
  DIGITAL_TV_SERVICE_PROVIDERS = 'DIGITAL_TV_SERVICE_PROVIDERS',
  DINEIN_RESTAURANTS = 'DINEIN_RESTAURANTS',
  DISCOUNT_RETAIL = 'DISCOUNT_RETAIL',
  DISCOUNT_SHOE_RETAILERS = 'DISCOUNT_SHOE_RETAILERS',
  DOG_FOOD = 'DOG_FOOD',
  DOG_TREATS = 'DOG_TREATS',
  DOLLAR_STORES = 'DOLLAR_STORES',
  DRUG_STORES = 'DRUG_STORES',
  ECIGARETTES = 'ECIGARETTES',
  ECOMMERCE = 'ECOMMERCE',
  EDUCATION = 'EDUCATION',
  EDUCATIONAL_INSTITUTIONS = 'EDUCATIONAL_INSTITUTIONS',
  EGGS = 'EGGS',
  ELDERLY_CARE = 'ELDERLY_CARE',
  ELECTRICAL_COMPANY = 'ELECTRICAL_COMPANY',
  ELECTRONICS = 'ELECTRONICS',
  ELECTRONICS_RETAIL_STORES = 'ELECTRONICS_RETAIL_STORES',
  EMAIL_MARKETING = 'EMAIL_MARKETING',
  EMPHYSEMA = 'EMPHYSEMA',
  ENERGY_COMPANIES = 'ENERGY_COMPANIES',
  ENERGY_DRINKS = 'ENERGY_DRINKS',
  ENERGY_PROVIDERS = 'ENERGY_PROVIDERS',
  ENTERPRISE_SOFTWARE = 'ENTERPRISE_SOFTWARE',
  ENTERTAINMENT = 'ENTERTAINMENT',
  EPINEPHRINE = 'EPINEPHRINE',
  ERECTILE_DYSFUNCTION = 'ERECTILE_DYSFUNCTION',
  EXERCISE_EQUIPMENT = 'EXERCISE_EQUIPMENT',
  EYECARE = 'EYECARE',
  EYEGLASSES = 'EYEGLASSES',
  EYEWEAR = 'EYEWEAR',
  FABRIC = 'FABRIC',
  FABRIC_SOFTENERS = 'FABRIC_SOFTENERS',
  FANTASY_SPORTS = 'FANTASY_SPORTS',
  FASHION = 'FASHION',
  FAST_FOOD_RESTAURANTS = 'FAST_FOOD_RESTAURANTS',
  FEDEX = 'FEDEX',
  FEMININE_HYGIENE_PRODUCTS = 'FEMININE_HYGIENE_PRODUCTS',
  FIBER_SUPPLEMENTS = 'FIBER_SUPPLEMENTS',
  FIBROMYALGIA = 'FIBROMYALGIA',
  FINANCIAL = 'FINANCIAL',
  FINANCIAL_INSTITUTIONS = 'FINANCIAL_INSTITUTIONS',
  FINANCIAL_SERVICE_GROUPS = 'FINANCIAL_SERVICE_GROUPS',
  FITNESS = 'FITNESS',
  FITNESS_CENTERS_GYMS = 'FITNESS_CENTERS_GYMS',
  FITNESS_TRACKERS = 'FITNESS_TRACKERS',
  FLOORING_COMPANIES = 'FLOORING_COMPANIES',
  FOOD = 'FOOD',
  FOOTWEAR = 'FOOTWEAR',
  FRAGRANCE = 'FRAGRANCE',
  FREIGHT_COMPANIES = 'FREIGHT_COMPANIES',
  FROZEN_DESSERT = 'FROZEN_DESSERT',
  FROZEN_FOODS = 'FROZEN_FOODS',
  FROZEN_MEALS = 'FROZEN_MEALS',
  FROZEN_PIZZA = 'FROZEN_PIZZA',
  FRUIT_DRIED = 'FRUIT_DRIED',
  FRUIT_FRESH = 'FRUIT_FRESH',
  FRUIT_JUICE = 'FRUIT_JUICE',
  FURNITURE = 'FURNITURE',
  GAMING = 'GAMING',
  GAMING_RETAILERS = 'GAMING_RETAILERS',
  GASTREOENTEROLOGY = 'GASTREOENTEROLOGY',
  GAS_COMPANY = 'GAS_COMPANY',
  GAS_STATIONS = 'GAS_STATIONS',
  GLAUCOMA = 'GLAUCOMA',
  GLUCOSE_MONITORS = 'GLUCOSE_MONITORS',
  GOLF_EQUIPMENT = 'GOLF_EQUIPMENT',
  GROCERY_STORES = 'GROCERY_STORES',
  HAIR = 'HAIR',
  HAIR_CARE = 'HAIR_CARE',
  HAIR_COLOR = 'HAIR_COLOR',
  HAIR_GROWTH = 'HAIR_GROWTH',
  HAIR_PRODUCTS = 'HAIR_PRODUCTS',
  HAIR_REGROWTH = 'HAIR_REGROWTH',
  HAIR_REMOVAL = 'HAIR_REMOVAL',
  HARDWARE = 'HARDWARE',
  HEALTHCARE = 'HEALTHCARE',
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
  HEARTBURN = 'HEARTBURN',
  HEMATOLOGY = 'HEMATOLOGY',
  HIGH_SPEED_INTERNET_SERVICE_PROVIDERS = 'HIGH_SPEED_INTERNET_SERVICE_PROVIDERS',
  HIV_AIDS_PHAMACEUTICALS = 'HIV_AIDS_PHAMACEUTICALS',
  HMOS = 'HMOS',
  HOLIDAYS = 'HOLIDAYS',
  HOME = 'HOME',
  HOME_COOLING = 'HOME_COOLING',
  HOME_FURNISHINGS = 'HOME_FURNISHINGS',
  HOME_HEATING = 'HOME_HEATING',
  HOME_IMPROVEMENT = 'HOME_IMPROVEMENT',
  HOME_IMPROVEMENT_STORES = 'HOME_IMPROVEMENT_STORES',
  HOME_INSURANCE = 'HOME_INSURANCE',
  HOME_SECURITY_AUTOMATION = 'HOME_SECURITY_AUTOMATION',
  HOSPITALS = 'HOSPITALS',
  HOTEL_HOSPITALITY = 'HOTEL_HOSPITALITY',
  HOT_BEVERAGES = 'HOT_BEVERAGES',
  HOUSEHOLD = 'HOUSEHOLD',
  HOUSEHOLD_PAPER_GOODS = 'HOUSEHOLD_PAPER_GOODS',
  HVAC = 'HVAC',
  HYGIENE = 'HYGIENE',
  ICE_CREAM = 'ICE_CREAM',
  INCONTIENCE = 'INCONTIENCE',
  INSURANCE = 'INSURANCE',
  INTERNATIONAL_CAMPAIGNS = 'INTERNATIONAL_CAMPAIGNS',
  INTERNET_SERVICE_PROVIDERS = 'INTERNET_SERVICE_PROVIDERS',
  INTIMATES = 'INTIMATES',
  JEWELRY = 'JEWELRY',
  KIDS_APPAREL = 'KIDS_APPAREL',
  KITCHEN_APPLIANCES = 'KITCHEN_APPLIANCES',
  LANGUAGE_INSTITUTES = 'LANGUAGE_INSTITUTES',
  LAPTOPS = 'LAPTOPS',
  LAWN_GARDEN = 'LAWN_GARDEN',
  LAXATIVES = 'LAXATIVES',
  LEGACY_CONFLICT = 'LEGACY_CONFLICT',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  LIGHTBULBS = 'LIGHTBULBS',
  LIQUOR = 'LIQUOR',
  LIVER_DISEASE = 'LIVER_DISEASE',
  LOCAL_CASINOS = 'LOCAL_CASINOS',
  LOCAL_FAST_FOOD = 'LOCAL_FAST_FOOD',
  LOCAL_GAMING = 'LOCAL_GAMING',
  LOCAL_HOSPITALS = 'LOCAL_HOSPITALS',
  MAKEUP = 'MAKEUP',
  MALT_BEVERAGES = 'MALT_BEVERAGES',
  MAYONNAISE_BRANDS = 'MAYONNAISE_BRANDS',
  MEAL_KIT_DELIVERY_SERVICES = 'MEAL_KIT_DELIVERY_SERVICES',
  MEAT = 'MEAT',
  MEDICAL_CENTERS = 'MEDICAL_CENTERS',
  MEDICAL_GRADE_SILICONE = 'MEDICAL_GRADE_SILICONE',
  MEDICARE = 'MEDICARE',
  MEDICATION = 'MEDICATION',
  MENOPAUSE = 'MENOPAUSE',
  MILK_ALTERNATIVES = 'MILK_ALTERNATIVES',
  MINTS = 'MINTS',
  MISCELLANEOUS = 'MISCELLANEOUS',
  MLB = 'MLB',
  MOBILE_CELL_PHONES = 'MOBILE_CELL_PHONES',
  MOBILITY = 'MOBILITY',
  MOISTURIZER = 'MOISTURIZER',
  MORTGAGE_SERVICES = 'MORTGAGE_SERVICES',
  MOTOR_OIL = 'MOTOR_OIL',
  MOUTHWASH = 'MOUTHWASH',
  MUTUAL_FUNDS = 'MUTUAL_FUNDS',
  NBA = 'NBA',
  NEUROPATHY = 'NEUROPATHY',
  NFL = 'NFL',
  NHL = 'NHL',
  NONALCOHOLIC_MALT_BEVERAGES = 'NONALCOHOLIC_MALT_BEVERAGES',
  NUT_BUTTERS = 'NUT_BUTTERS',
  OFFICE_SUPPLY_RETAILERS = 'OFFICE_SUPPLY_RETAILERS',
  ONCOLOGY = 'ONCOLOGY',
  ONLINE_AUTO_SALES = 'ONLINE_AUTO_SALES',
  ONLINE_TRAVEL_SITES = 'ONLINE_TRAVEL_SITES',
  ORAL_CARE = 'ORAL_CARE',
  ORAL_DENTURE_PRODUCTS = 'ORAL_DENTURE_PRODUCTS',
  ORAL_HYGIENE = 'ORAL_HYGIENE',
  OVER_THE_COUNTER = 'OVER_THE_COUNTER',
  PAIN_RELIEF = 'PAIN_RELIEF',
  PAPER_TOWEL = 'PAPER_TOWEL',
  PASTA = 'PASTA',
  PERFUMES = 'PERFUMES',
  PERSONAL_BANKING = 'PERSONAL_BANKING',
  PETROLEUM = 'PETROLEUM',
  PETS = 'PETS',
  PET_PRODUCTS = 'PET_PRODUCTS',
  PET_STORES = 'PET_STORES',
  PHARMACEUTICAL = 'PHARMACEUTICAL',
  PHARMACIES = 'PHARMACIES',
  PHONE_CHARGERS = 'PHONE_CHARGERS',
  PHONE_SERVICE_PROVIDERS = 'PHONE_SERVICE_PROVIDERS',
  PLUMBING = 'PLUMBING',
  PPOS = 'PPOS',
  PREPACKAGED_LUNCH = 'PREPACKAGED_LUNCH',
  PROFESSIONAL_SPORT_LEAGUES = 'PROFESSIONAL_SPORT_LEAGUES',
  PSORIASIS = 'PSORIASIS',
  QUICK_SERVICE_RESTAURANTS = 'QUICK_SERVICE_RESTAURANTS',
  QUICK_SERVICE_RESTAURANTS_BURGER = 'QUICK_SERVICE_RESTAURANTS_BURGER',
  QUICK_SERVICE_RESTAURANTS_CHICKEN = 'QUICK_SERVICE_RESTAURANTS_CHICKEN',
  QUICK_SERVICE_RESTAURANTS_PIZZA = 'QUICK_SERVICE_RESTAURANTS_PIZZA',
  QUICK_SERVICE_RESTAURANTS_SANDWICH = 'QUICK_SERVICE_RESTAURANTS_SANDWICH',
  QUICK_SERVICE_RESTAURANTS_SEAFOOD = 'QUICK_SERVICE_RESTAURANTS_SEAFOOD',
  REAL_ESTATE = 'REAL_ESTATE',
  RECOMMERCE_RESALE = 'RECOMMERCE_RESALE',
  REGIONAL_CASINOS = 'REGIONAL_CASINOS',
  REGIONAL_FAST_FOOD = 'REGIONAL_FAST_FOOD',
  REGIONAL_GAMING = 'REGIONAL_GAMING',
  REGIONAL_HOSPITALS = 'REGIONAL_HOSPITALS',
  REGIONAL_LOTTERIES = 'REGIONAL_LOTTERIES',
  RENTAL_CARS = 'RENTAL_CARS',
  RESORTS = 'RESORTS',
  RESTAURANTS = 'RESTAURANTS',
  RETAIL = 'RETAIL',
  RETAIL_CHAINS = 'RETAIL_CHAINS',
  RETAIL_ECOMMERCE = 'RETAIL_ECOMMERCE',
  RETIREMENT_SERVICES = 'RETIREMENT_SERVICES',
  RHEUMATOID_ARTHRITIS = 'RHEUMATOID_ARTHRITIS',
  RHEUMATOLOGY = 'RHEUMATOLOGY',
  RX = 'RX',
  SALTY_SNACKS = 'SALTY_SNACKS',
  SATELLITE_TV_SERVICE_PROVIDERS = 'SATELLITE_TV_SERVICE_PROVIDERS',
  SAUCES = 'SAUCES',
  SAUSAGE = 'SAUSAGE',
  SEAFOOD = 'SEAFOOD',
  SHAMPOO_CONDITIONER = 'SHAMPOO_CONDITIONER',
  SHIPPING = 'SHIPPING',
  SHOES = 'SHOES',
  SKIN_CARE = 'SKIN_CARE',
  SMARTHOME = 'SMARTHOME',
  SNACKS = 'SNACKS',
  SNACK_BARS = 'SNACK_BARS',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  SOCIAL_SECURITY = 'SOCIAL_SECURITY',
  SOFTWARE = 'SOFTWARE',
  SOUPS = 'SOUPS',
  SPIRITS = 'SPIRITS',
  SPORTING_GOODS = 'SPORTING_GOODS',
  SPORTS = 'SPORTS',
  SPORTSWEAR = 'SPORTSWEAR',
  SPORTS_DRINKS = 'SPORTS_DRINKS',
  SPORTS_MEDIA = 'SPORTS_MEDIA',
  STATE_LOTTERIES = 'STATE_LOTTERIES',
  STREAMING_TV_PROVIDERS = 'STREAMING_TV_PROVIDERS',
  SUGAR_BASED = 'SUGAR_BASED',
  SUNSCREEN = 'SUNSCREEN',
  SUPERMARKETS = 'SUPERMARKETS',
  SUPPLEMENTAL_HEALTH_INSURANCE = 'SUPPLEMENTAL_HEALTH_INSURANCE',
  SUPPLEMENTS = 'SUPPLEMENTS',
  SWEET_SNACKS = 'SWEET_SNACKS',
  TABLETS = 'TABLETS',
  TABLET_READERS = 'TABLET_READERS',
  TANNING_PRODUCTS = 'TANNING_PRODUCTS',
  TAX_PREPARATION = 'TAX_PREPARATION',
  TEA = 'TEA',
  TECHNOLOGY = 'TECHNOLOGY',
  TECHNOLOGY_COMPANIES = 'TECHNOLOGY_COMPANIES',
  TEETH_WHITENING = 'TEETH_WHITENING',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  TESTOSTERONE = 'TESTOSTERONE',
  THEME_PARKS = 'THEME_PARKS',
  THRIFT_STORES = 'THRIFT_STORES',
  TIRE_COMPANIES = 'TIRE_COMPANIES',
  TIRE_RETAILERS = 'TIRE_RETAILERS',
  TNF_BLOCKERS = 'TNF_BLOCKERS',
  TOBACCO = 'TOBACCO',
  TOILET_PAPER = 'TOILET_PAPER',
  TOOTHPASTES = 'TOOTHPASTES',
  TOURISM = 'TOURISM',
  TOYS = 'TOYS',
  TRADE_SCHOOLS = 'TRADE_SCHOOLS',
  TRAVEL = 'TRAVEL',
  TRAVEL_PRODUCTS = 'TRAVEL_PRODUCTS',
  TRAVEL_SERVICES = 'TRAVEL_SERVICES',
  TRUCKS = 'TRUCKS',
  UNDERWEAR = 'UNDERWEAR',
  UNIVERSITIES = 'UNIVERSITIES',
  USPS = 'USPS',
  UTILITIES = 'UTILITIES',
  VACATION = 'VACATION',
  VACATION_DESTINATIONS = 'VACATION_DESTINATIONS',
  VACCINES = 'VACCINES',
  VACUUM_CLEANERS = 'VACUUM_CLEANERS',
  VIDEO_GAMES = 'VIDEO_GAMES',
  VISION = 'VISION',
  VITAMINS = 'VITAMINS',
  WATCHES = 'WATCHES',
  WATER = 'WATER',
  WATER_FILTERS = 'WATER_FILTERS',
  WEBSITES = 'WEBSITES',
  WEED_KILLERS = 'WEED_KILLERS',
  WEIGHT_LOSS_DIET = 'WEIGHT_LOSS_DIET',
  WINE = 'WINE',
  WIRELESS_PHONE_SERVICE_PROVIDERS = 'WIRELESS_PHONE_SERVICE_PROVIDERS',
  YOGURT = 'YOGURT'
}

/** Contact information */
export type gqlContact = {
  readonly __typename?: 'Contact';
  readonly contactId: Scalars['Float'];
  /** Email */
  readonly email?: Maybe<Scalars['String']>;
  readonly enabled: Scalars['Boolean'];
  /** Contact ID */
  readonly id: Scalars['ID'];
  /** Defines organization main contact */
  readonly mainContact: Scalars['Boolean'];
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Phone */
  readonly phone: Scalars['String'];
};

/** Input type for contact */
export type gqlContactInput = {
  readonly contactId?: InputMaybe<Scalars['Int']>;
  /** Email */
  readonly email?: InputMaybe<Scalars['String']>;
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  /** Defines organization main contact */
  readonly mainContact: Scalars['Boolean'];
  /** Name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Phone */
  readonly phone: Scalars['String'];
};

export type gqlContactSubTalentInput = {
  readonly includeProjectRole?: InputMaybe<Scalars['Boolean']>;
  readonly message: Scalars['String'];
  readonly roleId: Scalars['Int'];
  readonly subject?: InputMaybe<Scalars['String']>;
  readonly submissionStatusType: gqlRepsSubmissionTypeEnum;
  readonly talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};

export type gqlContextRole = {
  readonly __typename?: 'ContextRole';
  readonly code: gqlSystemRoleCode;
  readonly id: Scalars['Int'];
};

/** Continent object */
export type gqlContinent = {
  readonly __typename?: 'Continent';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Contract Status Object */
export type gqlContractStatus = {
  readonly __typename?: 'ContractStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlContractStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}

export type gqlCopyAllProfileToCastingListInput = {
  readonly fromListId: Scalars['ID'];
  readonly includeNotes?: Scalars['Boolean'];
  readonly toListIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlCopyPresentationInput = {
  /** Name of new presentation */
  readonly name: Scalars['String'];
  /** Presentation Id to copy */
  readonly presentationId: Scalars['Int'];
  /** Project ID */
  readonly projectId: Scalars['Int'];
};

export type gqlCopyProfileToCastingListError = gqlValidationError;

export type gqlCopyProfileToCastingListInput = {
  readonly includeNotes?: Scalars['Boolean'];
  readonly listProfileIds: ReadonlyArray<Scalars['ID']>;
  readonly toListIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlCopyProfileToCastingListResult = {
  readonly __typename?: 'CopyProfileToCastingListResult';
  readonly error?: Maybe<gqlCopyProfileToCastingListError>;
  readonly success: Scalars['Boolean'];
};

/** Project Union */
export type gqlCountry = {
  readonly __typename?: 'Country';
  /** code */
  readonly code: Scalars['String'];
  /** Continent */
  readonly continent: gqlContinent;
  /** Currency */
  readonly currency?: Maybe<gqlCurrency>;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  readonly regions?: Maybe<ReadonlyArray<gqlRegion>>;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
  /** Supporting country id */
  readonly supportingCountry?: Maybe<gqlCountry>;
  /** Unit System */
  readonly unitSystem: gqlUnitSystem;
};

export enum gqlCountryEnum {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CS = 'CS',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
  _00 = '_00'
}

export type gqlCountryItem = {
  readonly __typename?: 'CountryItem';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enbaled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlCoupon = {
  readonly __typename?: 'Coupon';
  readonly code: Scalars['String'];
  readonly description: Scalars['String'];
  readonly isRedeemed: Scalars['Boolean'];
  readonly state: Scalars['String'];
};

/** Creates a user account with Google id token and refresh token */
export type gqlCreateAccountWithGoogleInput = {
  readonly authCode: Scalars['String'];
  readonly countryId: Scalars['ID'];
  /** Closest casting market for PC user */
  readonly dmaId?: InputMaybe<Scalars['Int']>;
  readonly isMobileAppClient?: InputMaybe<Scalars['Boolean']>;
  readonly languageLocaleId?: InputMaybe<Scalars['Int']>;
  /** Marketing option subscription option */
  readonly marketingOptIn?: InputMaybe<Scalars['Boolean']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
  readonly professionalBio?: InputMaybe<Scalars['String']>;
  readonly profileAccessToken?: InputMaybe<Scalars['String']>;
  readonly rosterInvitationToken?: InputMaybe<Scalars['String']>;
  readonly systemRoleCode?: InputMaybe<gqlSystemRoleEnum>;
  readonly termsOfUse: Scalars['Boolean'];
  readonly timeZoneId: Scalars['Int'];
  readonly utmCampaign?: InputMaybe<Scalars['String']>;
  readonly utmMedium?: InputMaybe<Scalars['String']>;
  readonly utmSource?: InputMaybe<Scalars['String']>;
};

export type gqlCreateArtistMediaInput = {
  readonly artistId: Scalars['Int'];
  readonly media: ReadonlyArray<gqlCreateMediaInputItem>;
};

export type gqlCreateArtistMediaResponse = gqlICreateMediaResponse & {
  readonly __typename?: 'CreateArtistMediaResponse';
  readonly artist: gqlArtist;
  readonly media: ReadonlyArray<gqlMedia>;
};

export type gqlCreateCastingListResult = {
  readonly __typename?: 'CreateCastingListResult';
  readonly error?: Maybe<gqlMutateListError>;
  readonly success?: Maybe<Scalars['Int']>;
};

/** Create Comped Subscription Input */
export type gqlCreateCompedSubscriptionInput = {
  /** Account id */
  readonly accountId: Scalars['Int'];
  /** Artist id */
  readonly artistId: Scalars['Int'];
  /** Billing code */
  readonly billingCode: Scalars['String'];
  /** Subscription ends at */
  readonly endsAt: Scalars['DateTime'];
};

/** Create Coupon Redemption Input */
export type gqlCreateCouponRedemptionInput = {
  readonly couponId: Scalars['String'];
};

export type gqlCreateFormResult = {
  readonly __typename?: 'CreateFormResult';
  readonly error?: Maybe<gqlMutateFormError>;
  /** The ID of the created form */
  readonly formId?: Maybe<Scalars['Int']>;
};

export type gqlCreateMediaInputItem = {
  readonly filename: Scalars['String'];
};

/** Input type for Organization */
export type gqlCreateOrganizationInput = {
  readonly abbreviation?: InputMaybe<Scalars['String']>;
  readonly addresses?: InputMaybe<ReadonlyArray<gqlAddressInput>>;
  readonly contacts?: InputMaybe<ReadonlyArray<gqlContactInput>>;
  readonly creatorId?: InputMaybe<Scalars['ID']>;
  readonly diversityDisclaimer?: InputMaybe<Scalars['String']>;
  /** Devisions could have multiple locations/dma's */
  readonly dmaIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly featureId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly features?: InputMaybe<ReadonlyArray<gqlOrganizationFeatureInput>>;
  readonly logoMediaId?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly organizationParentId?: InputMaybe<Scalars['ID']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly profileListId?: InputMaybe<Scalars['ID']>;
  readonly projectCastingAssistant?: InputMaybe<Scalars['String']>;
  readonly projectCastingAssociate?: InputMaybe<Scalars['String']>;
  readonly projectCastingDirector?: InputMaybe<Scalars['String']>;
  readonly representationAgeCategoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly representationCategoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly representationTypeId?: InputMaybe<Scalars['ID']>;
  readonly scheduleAddressNote?: InputMaybe<Scalars['String']>;
  readonly systemRoleId?: InputMaybe<Scalars['ID']>;
  readonly tileSchemeId?: InputMaybe<Scalars['ID']>;
  readonly websiteUrl?: InputMaybe<Scalars['String']>;
};

/** Input type for PC Project */
export type gqlCreatePcProjectInput = {
  readonly adAgency?: InputMaybe<Scalars['String']>;
  readonly additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  readonly attachmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Project sides visibility CODE */
  readonly attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  readonly auditionCity?: InputMaybe<Scalars['String']>;
  readonly auditionDates?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly auditionTypeId?: InputMaybe<Scalars['ID']>;
  readonly canTalentSubmitSelfTape?: InputMaybe<Scalars['Boolean']>;
  readonly castingAssistant?: InputMaybe<Scalars['String']>;
  readonly castingAssociate?: InputMaybe<Scalars['String']>;
  readonly castingDirector?: InputMaybe<Scalars['String']>;
  readonly conflictIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly conflictNote?: InputMaybe<Scalars['String']>;
  readonly contactEmail?: InputMaybe<Scalars['String']>;
  readonly contactInfoPermissions: Scalars['Boolean'];
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  readonly contractStatusCode?: InputMaybe<Scalars['String']>;
  readonly determinedLocationInput?: InputMaybe<gqlPcProjectDeterminedAddressInput>;
  readonly director?: InputMaybe<Scalars['String']>;
  readonly executiveProducer?: InputMaybe<Scalars['String']>;
  readonly fieldRestrictionSettings?: InputMaybe<ReadonlyArray<gqlProjectFieldRestrictionInput>>;
  readonly hidden?: InputMaybe<Scalars['Boolean']>;
  readonly hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  readonly internalName?: InputMaybe<Scalars['String']>;
  /**
   * If null/undefined/false than PC project audition address not specified, if
   * true than the isSpecificLocation should be not null/undefined
   */
  readonly isAuditionInPerson?: InputMaybe<Scalars['Boolean']>;
  /**
   * Can not be null/undefined while isAuditionInPerson is true, if this field is
   * true than API will use specificLocationInput, if false than API use
   * determinedLocationInput
   */
  readonly isSpecificLocation?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly network?: InputMaybe<Scalars['String']>;
  readonly newSeriesName?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly organizationId?: InputMaybe<Scalars['Int']>;
  readonly photographer?: InputMaybe<Scalars['String']>;
  readonly producer?: InputMaybe<Scalars['String']>;
  readonly productionCompany?: InputMaybe<Scalars['String']>;
  readonly projectOrganization?: InputMaybe<ReadonlyArray<gqlProjectOrganizationInput>>;
  readonly projectParentId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  readonly projectSeriesId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  readonly projectStatusCode?: InputMaybe<Scalars['String']>;
  readonly projectStatusId?: InputMaybe<Scalars['ID']>;
  readonly projectTypeId: Scalars['ID'];
  readonly projectUnionId?: InputMaybe<Scalars['ID']>;
  readonly rate?: InputMaybe<Scalars['String']>;
  /** PC project release location codes */
  readonly releaseLocations?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requestBrief?: InputMaybe<Scalars['Boolean']>;
  readonly requestBriefInstruction?: InputMaybe<Scalars['String']>;
  readonly roleDefaults?: InputMaybe<gqlRoleDefaultsInput>;
  /** Uploaded script Id */
  readonly scriptFileId?: InputMaybe<Scalars['Int']>;
  readonly specificLocationInput?: InputMaybe<gqlPcProjectSpecificAddressInput>;
  readonly studio?: InputMaybe<Scalars['String']>;
  readonly submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  readonly synopsis?: InputMaybe<Scalars['String']>;
  readonly tileSchemeId?: InputMaybe<Scalars['ID']>;
  readonly unionContractId?: InputMaybe<Scalars['String']>;
  readonly usage?: InputMaybe<Scalars['String']>;
  readonly workCity?: InputMaybe<Scalars['String']>;
  readonly workDates?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly writer?: InputMaybe<Scalars['String']>;
};

/** Create profile conflict input */
export type gqlCreateProfileConflictInput = {
  readonly conflictId: Scalars['Int'];
  readonly expiration?: InputMaybe<Scalars['DateTime']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly profileId: Scalars['Int'];
};

/** Input type for creating profile submission */
export type gqlCreateProfileSubmissionInput = {
  /** ID of the creator */
  readonly createdBy?: InputMaybe<Scalars['Int']>;
  /** Profile working location id */
  readonly dmaId?: InputMaybe<Scalars['Int']>;
  /** Represents that profile is overscale or not */
  readonly isOverscale?: InputMaybe<Scalars['Boolean']>;
  /** Note for submission */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Over scale note */
  readonly overscaleNote?: InputMaybe<Scalars['String']>;
  /** Array of profile ids */
  readonly profileIds: ReadonlyArray<Scalars['Int']>;
  /** Request media(s) */
  readonly requestMedia?: InputMaybe<ReadonlyArray<gqlSubmissionMediaInput>>;
  /** Role Id */
  readonly roleId: Scalars['Int'];
  /** Submission profile media(s) */
  readonly submissionProfileMedia?: InputMaybe<ReadonlyArray<gqlSubmissionMediaInput>>;
  /** Submission status code */
  readonly submissionStatusCode: Scalars['String'];
};

export type gqlCreateProfileSubmissionOrGetInput = {
  readonly profileId: Scalars['Int'];
  readonly roleId: Scalars['Int'];
};

/** Input type for Project */
export type gqlCreateProjectInput = {
  readonly adAgency?: InputMaybe<Scalars['String']>;
  readonly additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  readonly attachmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Project sides visibility CODE */
  readonly attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  readonly auditionCity?: InputMaybe<Scalars['String']>;
  readonly auditionDates?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly auditionTypeId?: InputMaybe<Scalars['ID']>;
  readonly castingAssistant?: InputMaybe<Scalars['String']>;
  readonly castingAssociate?: InputMaybe<Scalars['String']>;
  readonly castingDirector?: InputMaybe<Scalars['String']>;
  readonly conflictIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly conflictNote?: InputMaybe<Scalars['String']>;
  readonly contactEmail?: InputMaybe<Scalars['String']>;
  readonly contactInfoPermissions: Scalars['Boolean'];
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  readonly contractStatusCode?: InputMaybe<Scalars['String']>;
  readonly director?: InputMaybe<Scalars['String']>;
  readonly executiveProducer?: InputMaybe<Scalars['String']>;
  readonly fieldRestrictionSettings?: InputMaybe<ReadonlyArray<gqlProjectFieldRestrictionInput>>;
  readonly hidden?: InputMaybe<Scalars['Boolean']>;
  readonly hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  readonly internalName?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly network?: InputMaybe<Scalars['String']>;
  readonly newSeriesName?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly organizationId?: InputMaybe<Scalars['Int']>;
  readonly photographer?: InputMaybe<Scalars['String']>;
  readonly producer?: InputMaybe<Scalars['String']>;
  readonly productionCompany?: InputMaybe<Scalars['String']>;
  readonly projectOrganization?: InputMaybe<ReadonlyArray<gqlProjectOrganizationInput>>;
  readonly projectParentId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  readonly projectSeriesId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  readonly projectStatusCode?: InputMaybe<Scalars['String']>;
  readonly projectStatusId?: InputMaybe<Scalars['ID']>;
  readonly projectTypeId: Scalars['ID'];
  readonly projectUnionId?: InputMaybe<Scalars['ID']>;
  readonly rate?: InputMaybe<Scalars['String']>;
  readonly requestBrief?: InputMaybe<Scalars['Boolean']>;
  readonly requestBriefInstruction?: InputMaybe<Scalars['String']>;
  readonly roleDefaults?: InputMaybe<gqlRoleDefaultsInput>;
  /** Uploaded script Id */
  readonly scriptFileId?: InputMaybe<Scalars['Int']>;
  readonly studio?: InputMaybe<Scalars['String']>;
  readonly submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  readonly synopsis?: InputMaybe<Scalars['String']>;
  readonly tileSchemeId?: InputMaybe<Scalars['ID']>;
  readonly unionContractId?: InputMaybe<Scalars['String']>;
  readonly usage?: InputMaybe<Scalars['String']>;
  readonly workCity?: InputMaybe<Scalars['String']>;
  readonly workDates?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly writer?: InputMaybe<Scalars['String']>;
};

export type gqlCreateProjectSeriesInput = {
  readonly seriesName: Scalars['String'];
};

export type gqlCreateSavedSearchInput = {
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notificationType?: InputMaybe<Scalars['String']>;
  readonly searchArgs: gqlCastingBillboardSearchInput;
};

/** Schedule Input */
export type gqlCreateScheduleInput = {
  /** Address Id */
  readonly addressId?: InputMaybe<Scalars['Int']>;
  /** Is location hidden */
  readonly isLocationHidden?: InputMaybe<Scalars['Boolean']>;
  /** Is Virtual Audition */
  readonly isVirtualAudition?: InputMaybe<Scalars['Boolean']>;
  /** Schedule Name */
  readonly name: Scalars['String'];
  /** Note */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Project Id */
  readonly projectId: Scalars['ID'];
  /** Schedule Date */
  readonly scheduleDate: Scalars['String'];
  /** Schedule Type Code */
  readonly scheduleTypeCode: Scalars['String'];
  /** Time Zone CODE */
  readonly timeZoneCode?: InputMaybe<Scalars['String']>;
  /** Time Zone Id */
  readonly timeZoneId?: InputMaybe<Scalars['ID']>;
};

/** Create Subscription Input */
export type gqlCreateSubscriptionInput = {
  /** Account code */
  readonly accountCode: Scalars['String'];
  /** Artist id */
  readonly artistId: Scalars['Float'];
  /** Billing code */
  readonly billingCode: gqlBillingCode;
  /** Country id */
  readonly countryId: Scalars['Float'];
  /** Coupon code */
  readonly coupon?: InputMaybe<Scalars['String']>;
  /** Next billing date */
  readonly nextBillDate: Scalars['DateTime'];
};

export type gqlCreateTalentScoutHistoryInput = {
  readonly message: Scalars['String'];
  readonly profileId: Scalars['Int'];
};

/** Credit Attribute */
export type gqlCredit = {
  readonly __typename?: 'Credit';
  /** Created at Date */
  readonly created: Scalars['DateTime'];
  readonly creditMedia: ReadonlyArray<gqlMedia>;
  /** Credit Sort ordering */
  readonly creditOrder: Scalars['Int'];
  /** Credit Type */
  readonly creditType?: Maybe<gqlCreditType>;
  /** The director of the Project */
  readonly director?: Maybe<Scalars['String']>;
  readonly guid: Scalars['String'];
  /** Highlighted */
  readonly highlighted: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Profile Credit Type Id */
  readonly profileCreditTypeId: Scalars['Float'];
  /** Profile ID */
  readonly profileId: Scalars['ID'];
  /** Role title */
  readonly role?: Maybe<Scalars['String']>;
  /** Credit Type Sort ordering */
  readonly sortOrder: Scalars['Int'];
  /** Title */
  readonly title?: Maybe<Scalars['String']>;
  /** Updated at Date */
  readonly updated: Scalars['DateTime'];
  /** Year */
  readonly year?: Maybe<Scalars['Int']>;
};

export enum gqlCreditCardTypeEnum {
  COMMERCIAL = 'COMMERCIAL',
  CORPORATE = 'CORPORATE',
  DANCE = 'DANCE',
  DOCUMENTARY = 'DOCUMENTARY',
  EDUCATION = 'EDUCATION',
  FILM = 'FILM',
  HOSTING = 'HOSTING',
  INDUSTRIAL = 'INDUSTRIAL',
  INFOMERCIAL = 'INFOMERCIAL',
  MODELING = 'MODELING',
  MUSIC = 'MUSIC',
  MUSICAL_THEATER = 'MUSICAL_THEATER',
  MUSIC_VIDEO = 'MUSIC_VIDEO',
  ONLINE_CONTENT = 'ONLINE_CONTENT',
  OPERA = 'OPERA',
  PRINT = 'PRINT',
  RADIO = 'RADIO',
  REALITY_TV = 'REALITY_TV',
  SHORT_FILM = 'SHORT_FILM',
  STAND_UP_COMEDY = 'STAND_UP_COMEDY',
  TELEVISION = 'TELEVISION',
  THEATER = 'THEATER',
  TRAINING = 'TRAINING',
  VIDEO_GAME = 'VIDEO_GAME',
  VOICE_OVER = 'VOICE_OVER',
  WEB_SERIES = 'WEB_SERIES'
}

/** Input type for Credit */
export type gqlCreditInput = {
  /** Credit type id */
  readonly creditTypeId: Scalars['Int'];
  /** The director of the Project */
  readonly director: Scalars['String'];
  /** Display order descending */
  readonly displayOrder?: InputMaybe<Scalars['Int']>;
  /** Highlighted */
  readonly highlighted?: InputMaybe<Scalars['Boolean']>;
  /** Profile id */
  readonly profileId: Scalars['Int'];
  /** Role name */
  readonly role: Scalars['String'];
  /** Credit title */
  readonly title: Scalars['String'];
  /** Year */
  readonly year?: InputMaybe<Scalars['Int']>;
};

/** Input type for Credit Order */
export type gqlCreditOrderInput = {
  /** Credit order */
  readonly creditOrder: Scalars['Int'];
  /** Credit id */
  readonly id: Scalars['Int'];
};

/** CreditType object */
export type gqlCreditType = {
  readonly __typename?: 'CreditType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for Credit */
export type gqlCreditUpdateInput = {
  /** Credit type id */
  readonly creditTypeId?: InputMaybe<Scalars['Int']>;
  /** The director of the Project */
  readonly director?: InputMaybe<Scalars['String']>;
  /** Display Credit Type order descending */
  readonly displayOrder?: InputMaybe<Scalars['Int']>;
  /** Highlighted */
  readonly highlighted?: InputMaybe<Scalars['Boolean']>;
  /** Credit id */
  readonly id: Scalars['Int'];
  /** Role name */
  readonly role?: InputMaybe<Scalars['String']>;
  /** Credit title */
  readonly title?: InputMaybe<Scalars['String']>;
  /** Year */
  readonly year?: InputMaybe<Scalars['Int']>;
};

/** Cup sizes object */
export type gqlCupSize = {
  readonly __typename?: 'CupSize';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Currency object */
export type gqlCurrency = {
  readonly __typename?: 'Currency';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
  readonly symbol: Scalars['String'];
};

export enum gqlCurrencyEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW'
}

export type gqlCustomMediaInput = {
  /** Is Primary Media */
  readonly isPrimary?: Scalars['Boolean'];
  /** Media Id */
  readonly mediaId: Scalars['ID'];
};

export type gqlCustomProfileInput = {
  /** Audio Customisation */
  readonly audios?: InputMaybe<ReadonlyArray<gqlCustomMediaInput>>;
  /** Custom Credits */
  readonly credits?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Photos Customisation */
  readonly photos?: InputMaybe<ReadonlyArray<gqlCustomMediaInput>>;
  /** Video Customisation */
  readonly videos?: InputMaybe<ReadonlyArray<gqlCustomMediaInput>>;
};

/** Custom URL Slug */
export type gqlCustomSlug = {
  readonly __typename?: 'CustomSlug';
  /** The account that created this slug */
  readonly createdBy: gqlAccount;
  /** Date when the slug was created and assigned to this user */
  readonly createdDate: Scalars['DateTime'];
  /** The date after which the user may change their slug again */
  readonly creationCooldownDate?: Maybe<Scalars['DateTime']>;
  /** The account that expired this slug */
  readonly expiredBy?: Maybe<gqlAccount>;
  /** Date when the slug is or will be expired */
  readonly expiredDate?: Maybe<Scalars['DateTime']>;
  /** Reason why the slug was expired */
  readonly expiredReason?: Maybe<gqlCustomUrlSlugExpiredReason>;
  /** Slug Id */
  readonly id: Scalars['ID'];
  /** The profile that this slug is assigned to */
  readonly profile?: Maybe<gqlProfile>;
  /** Slug Name */
  readonly slugName: Scalars['String'];
};

export enum gqlCustomSlugBlockedReasonEnum {
  PROHIBITED = 'PROHIBITED',
  RESERVED = 'RESERVED',
  SUPPORT = 'SUPPORT'
}

export enum gqlCustomSlugExpiredReasonEnum {
  DISCARDED = 'DISCARDED',
  DOWNGRADED = 'DOWNGRADED',
  PROHIBITED = 'PROHIBITED',
  RESERVED = 'RESERVED',
  SUPPORT = 'SUPPORT'
}

/** Custom Slug expired reason */
export type gqlCustomUrlSlugExpiredReason = {
  readonly __typename?: 'CustomUrlSlugExpiredReason';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlCustomizeTalentInFolderInput = {
  readonly folderId: Scalars['Int'];
  /** Represents that profile is overscale or not */
  readonly isOverscale?: InputMaybe<Scalars['Boolean']>;
  /** profile customized media(s) */
  readonly media?: InputMaybe<ReadonlyArray<gqlPresentationFolderTalentCustomizedMediaInput>>;
  /** Note for submission */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Over scale note */
  readonly overscaleNote?: InputMaybe<Scalars['String']>;
  readonly profileId: Scalars['Int'];
};

/** Input type for DateRangeInput */
export type gqlDateRangeInput = {
  /** The maximum date */
  readonly dateMax?: InputMaybe<Scalars['DateTime']>;
  /** The minimum date */
  readonly dateMin?: InputMaybe<Scalars['DateTime']>;
};

/** Profile Fit For You Values */
export type gqlDefaultFitForYouArtist = {
  readonly __typename?: 'DefaultFitForYouArtist';
  /** Is age displayed in years> */
  readonly ageInYears: Scalars['Boolean'];
  readonly ethnicAppearances?: Maybe<ReadonlyArray<gqlEthnicAppearance>>;
  readonly genderAppearances?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  readonly payPreferences?: Maybe<ReadonlyArray<gqlPaidType>>;
  /** Maximum Playable Age */
  readonly playableAgeMax?: Maybe<Scalars['Int']>;
  /** Mimimumm Playable Age */
  readonly playableAgeMin?: Maybe<Scalars['Int']>;
  readonly projectTypes?: Maybe<ReadonlyArray<gqlProjectType>>;
  readonly roleTargets?: Maybe<ReadonlyArray<gqlRoleTarget>>;
  readonly talentUnions?: Maybe<ReadonlyArray<gqlTalentUnion>>;
  readonly workingLocations: ReadonlyArray<gqlDma>;
};

export type gqlDeleteCastingListResult = {
  readonly __typename?: 'DeleteCastingListResult';
  readonly error?: Maybe<gqlMutateListError>;
  readonly success: Scalars['Boolean'];
};

export type gqlDeleteMediaInput = {
  readonly mediaIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlDeleteMediaResponse = {
  readonly __typename?: 'DeleteMediaResponse';
  readonly media: ReadonlyArray<gqlMedia>;
  readonly success: Scalars['Boolean'];
};

export type gqlDeleteMediaV2Input = {
  readonly mediaIds: ReadonlyArray<Scalars['ID']>;
};

/** DeleteMediaV2Response */
export type gqlDeleteMediaV2Response = {
  readonly __typename?: 'DeleteMediaV2Response';
  readonly success: Scalars['Boolean'];
};

export type gqlDeletePresentationProfilePickPublickInput = {
  readonly pickId: Scalars['String'];
  readonly presentationGuid: Scalars['String'];
  readonly profileId: Scalars['Int'];
  readonly userName: Scalars['String'];
};

export type gqlDeleteProjectSeriesInput = {
  readonly projectSeriesId: ReadonlyArray<Scalars['ID']>;
};

/** Holds the Input for Deleting Submission from a Slot */
export type gqlDeleteSubmissionFromSlotInput = {
  /** Message for Cancellation */
  readonly cancellationMessage?: InputMaybe<Scalars['String']>;
  /** Slot Id */
  readonly slotId: Scalars['ID'];
  /** Submission Id */
  readonly submissionId: Scalars['ID'];
};

export type gqlDeleteSubmissionsFromSlotInput = {
  /** Message for Cancellation */
  readonly cancellationMessage?: InputMaybe<Scalars['String']>;
  readonly slotSubmissions: ReadonlyArray<gqlSlotSubmissionsInput>;
};

/** DeleteTimeframe input type */
export type gqlDeleteTimeframeInput = {
  /** Timeframe cancelation note */
  readonly cancelationNote?: InputMaybe<Scalars['String']>;
  /** Schedule Id */
  readonly scheduleId: Scalars['Float'];
  /** Timeframe Id */
  readonly timeframeId: Scalars['Float'];
};

export enum gqlDeviceTokenType {
  APN = 'APN'
}

export enum gqlDisplayOnlyTalentWithPicksEnum {
  All = 'All',
  CommentsOnly = 'CommentsOnly',
  PicksOnly = 'PicksOnly'
}

export enum gqlDisplayRoleOptionsEnum {
  BasicRoleInformation = 'BasicRoleInformation',
  FullRoleDetails = 'FullRoleDetails'
}

export enum gqlDisplayTalentInfoOptionsEnum {
  KidsAges = 'KidsAges',
  PicksAndFeedback = 'PicksAndFeedback',
  ProjectNotes = 'ProjectNotes',
  TalentContactInfo = 'TalentContactInfo',
  TalentRepresentationInfo = 'TalentRepresentationInfo',
  UnionStatuses = 'UnionStatuses'
}

export enum gqlDisplayTalentPerPageOptionsEnum {
  OnePerPage = 'OnePerPage',
  TwoPerPage = 'TwoPerPage'
}

export enum gqlDisplayTalentPhotoOptionsEnum {
  AuditionSnapshots = 'AuditionSnapshots',
  Headshots = 'Headshots',
  NoPhoto = 'NoPhoto'
}

/** DMA for Profile */
export type gqlDma = {
  readonly __typename?: 'Dma';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** child objects */
  readonly region?: Maybe<gqlRegion>;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlDmaEnum {
  ADELAIDE = 'ADELAIDE',
  ALBUQUERQUE = 'ALBUQUERQUE',
  ANCHORAGE = 'ANCHORAGE',
  ATLANTA = 'ATLANTA',
  AUCKLAND = 'AUCKLAND',
  AUSTIN = 'AUSTIN',
  BALTIMORE = 'BALTIMORE',
  BARCELONA = 'BARCELONA',
  BELFAST = 'BELFAST',
  BIRMINGHAM = 'BIRMINGHAM',
  BOGOTA = 'BOGOTA',
  BOISE = 'BOISE',
  BOSTON = 'BOSTON',
  BRISBANE = 'BRISBANE',
  BUENOS_AIRES = 'BUENOS_AIRES',
  BUFFALO = 'BUFFALO',
  CALGARY = 'CALGARY',
  CANBERRA = 'CANBERRA',
  CAPE_TOWN = 'CAPE_TOWN',
  CARACAS = 'CARACAS',
  CARDIFF = 'CARDIFF',
  CHARLESTON = 'CHARLESTON',
  CHARLOTTE = 'CHARLOTTE',
  CHICAGO = 'CHICAGO',
  CHRISTCHURCH = 'CHRISTCHURCH',
  CINCINNATI = 'CINCINNATI',
  CLEVELAND = 'CLEVELAND',
  DALLAS = 'DALLAS',
  DARWIN = 'DARWIN',
  DENVER = 'DENVER',
  DETROIT = 'DETROIT',
  DUBLIN = 'DUBLIN',
  EDINBURGH = 'EDINBURGH',
  GLASGOW = 'GLASGOW',
  GUADALAJARA = 'GUADALAJARA',
  HALIFAX = 'HALIFAX',
  HOBART = 'HOBART',
  HONOLULU = 'HONOLULU',
  HOUSTON = 'HOUSTON',
  INDIANAPOLIS = 'INDIANAPOLIS',
  JACKSONVILLE = 'JACKSONVILLE',
  JOHANNESBURG = 'JOHANNESBURG',
  KANSAS_CITY_MO = 'KANSAS_CITY_MO',
  LAS_VEGAS = 'LAS_VEGAS',
  LEEDS = 'LEEDS',
  LIMA = 'LIMA',
  LIVERPOOL = 'LIVERPOOL',
  LONDON = 'LONDON',
  LOS_ANGELES = 'LOS_ANGELES',
  LOUISVILLE = 'LOUISVILLE',
  MADRID = 'MADRID',
  MANCHESTER = 'MANCHESTER',
  MELBOURNE = 'MELBOURNE',
  MEMPHIS = 'MEMPHIS',
  MEXICO_CITY = 'MEXICO_CITY',
  MIAMI = 'MIAMI',
  MILWAUKEE = 'MILWAUKEE',
  MINNEAPOLIS = 'MINNEAPOLIS',
  MONTERREY = 'MONTERREY',
  MONTREAL = 'MONTREAL',
  NASHVILLE = 'NASHVILLE',
  NEW_ORLEANS = 'NEW_ORLEANS',
  NEW_YORK_CITY = 'NEW_YORK_CITY',
  OKLAHOMA_CITY = 'OKLAHOMA_CITY',
  ORLANDO = 'ORLANDO',
  OTTAWA = 'OTTAWA',
  PARIS = 'PARIS',
  PERTH = 'PERTH',
  PHILADELPHIA = 'PHILADELPHIA',
  PHOENIX = 'PHOENIX',
  PITTSBURGH = 'PITTSBURGH',
  PORTLAND = 'PORTLAND',
  PROVIDENCE = 'PROVIDENCE',
  QUEBEC_CITY = 'QUEBEC_CITY',
  REGINA = 'REGINA',
  RENO = 'RENO',
  RICHMOND = 'RICHMOND',
  RIO_DE_JANEIRO = 'RIO_DE_JANEIRO',
  SACRAMENTO = 'SACRAMENTO',
  SALT_LAKE_CITY = 'SALT_LAKE_CITY',
  SANTIAGO = 'SANTIAGO',
  SAN_ANTONIO = 'SAN_ANTONIO',
  SAN_DIEGO = 'SAN_DIEGO',
  SAN_FRANCISCO = 'SAN_FRANCISCO',
  SAN_JUAN = 'SAN_JUAN',
  SAO_PAULO = 'SAO_PAULO',
  SEATTLE = 'SEATTLE',
  SPOKANE = 'SPOKANE',
  ST_LOUIS = 'ST_LOUIS',
  SYDNEY = 'SYDNEY',
  TAMPA = 'TAMPA',
  TORONTO = 'TORONTO',
  VANCOUVER = 'VANCOUVER',
  WASHINGTON_DC = 'WASHINGTON_DC',
  WELLINGTON = 'WELLINGTON',
  WINNIPEG = 'WINNIPEG'
}

export type gqlDmaItem = {
  readonly __typename?: 'DmaItem';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enbaled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  readonly region?: Maybe<gqlRegionItem>;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** General size object */
export type gqlDocument = {
  readonly __typename?: 'Document';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlDocument>;
  /** code */
  readonly code: Scalars['String'];
  /** country */
  readonly country?: Maybe<gqlCountry>;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlDocument>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlEditCastingListResult = {
  readonly __typename?: 'EditCastingListResult';
  readonly error?: Maybe<gqlEditListError>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type gqlEditListError = gqlMissingDataError | gqlNotFoundError | gqlValidationError;

export type gqlEditProfileCastingListResult = {
  readonly __typename?: 'EditProfileCastingListResult';
  readonly error?: Maybe<gqlEditProfileError>;
  readonly success: Scalars['Boolean'];
};

export type gqlEditProfileError = gqlMissingDataError | gqlNotFoundError | gqlValidationError;

export type gqlEditProfileInput = {
  /** List Profile Id */
  readonly listProfileId: Scalars['ID'];
  /** Profile Customisations to Save */
  readonly profilesCustomisation?: InputMaybe<gqlCustomProfileInput>;
};

export type gqlEditProjectSeriesInput = {
  readonly projectSeriesId: Scalars['ID'];
  readonly projectSeriesName: Scalars['String'];
};

export type gqlEmailHistory = {
  readonly __typename?: 'EmailHistory';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly emailType: gqlEmailHistoryTypeEnum;
  readonly messageToReps?: Maybe<Scalars['String']>;
  readonly messageToTalents?: Maybe<Scalars['String']>;
  readonly publicationSettings: ReadonlyArray<gqlPublicationSetting>;
  readonly roles: ReadonlyArray<gqlRole>;
};

/** Paginated result with [EmailHistory] objects */
export type gqlEmailHistoryPaginatedResult = {
  readonly __typename?: 'EmailHistoryPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of EmailHistory objects */
  readonly objects: ReadonlyArray<gqlEmailHistory>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Email History type */
export enum gqlEmailHistoryTypeEnum {
  Publication_AdditionalSubmissions = 'Publication_AdditionalSubmissions',
  Publication_Approved = 'Publication_Approved',
  Publication_Published = 'Publication_Published',
  Publication_RepsEmailed = 'Publication_RepsEmailed',
  Publication_Widened = 'Publication_Widened'
}

/** Creates a end user account based on given input values */
export type gqlEndUserAccountInput = {
  readonly companyAdmin: Scalars['Boolean'];
  readonly countryId: Scalars['Int'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly organizationIds: ReadonlyArray<Scalars['Float']>;
  readonly phone: Scalars['String'];
  readonly roleId: Scalars['Float'];
  readonly timeZoneId: Scalars['Float'];
};

/** Says whether enrollment as successful */
export type gqlEnrollBenefits = {
  readonly __typename?: 'EnrollBenefits';
  readonly success: Scalars['Boolean'];
};

export type gqlEnrollBenefitsInput = {
  readonly acceptedTerms?: InputMaybe<Scalars['Boolean']>;
  readonly address: ReadonlyArray<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly email?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly phone: Scalars['String'];
  readonly state: Scalars['String'];
  readonly work?: InputMaybe<Scalars['String']>;
  readonly zip: Scalars['String'];
};

/** Input type for entity type subscription */
export type gqlEntitySubscriptionInput = {
  /** Entity Type CODE */
  readonly entityTypeCode: Scalars['String'];
  /** Entity value CODE. e.g if entityTypeCode is ROLE_TARGET then entityValueCode must be PRINCIPAL Or BACKGROUND */
  readonly entityValueCode: Scalars['String'];
};

/** Entity Type object */
export type gqlEntityType = {
  readonly __typename?: 'EntityType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlEntityTypeEnum {
  ACCOUNT = 'ACCOUNT',
  ADDRESS = 'ADDRESS',
  ARTIST = 'ARTIST',
  AUDITION_SESSION = 'AUDITION_SESSION',
  CALENDAR = 'CALENDAR',
  CALENDAR_EVENT = 'CALENDAR_EVENT',
  CALENDAR_SLOT = 'CALENDAR_SLOT',
  MEDIA = 'MEDIA',
  MEDIA_TYPE = 'MEDIA_TYPE',
  NOTIFICATION_NATURE = 'NOTIFICATION_NATURE',
  ORGANIZATION = 'ORGANIZATION',
  PAID_TYPE = 'PAID_TYPE',
  PROFILE = 'PROFILE',
  PROJECT = 'PROJECT',
  PROJECT_TYPE = 'PROJECT_TYPE',
  ROLE = 'ROLE',
  ROLE_TARGET = 'ROLE_TARGET',
  SUBMISSION = 'SUBMISSION',
  TALENT_UNION = 'TALENT_UNION'
}

/** EthnicAppearance object */
export type gqlEthnicAppearance = {
  readonly __typename?: 'EthnicAppearance';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlEthnicAppearanceEnum {
  ABORIGINAL_TORRES_STRAIT_ISLANDER = 'ABORIGINAL_TORRES_STRAIT_ISLANDER',
  AFRICAN_AMERICAN_BLACK = 'AFRICAN_AMERICAN_BLACK',
  AMERICAN_INDIAN = 'AMERICAN_INDIAN',
  CAUCASIAN_WHITE = 'CAUCASIAN_WHITE',
  EASTERN_EUROPEAN = 'EASTERN_EUROPEAN',
  EAST_ASIAN = 'EAST_ASIAN',
  EAST_INDIAN_SOUTH_ASIAN = 'EAST_INDIAN_SOUTH_ASIAN',
  ETHNICALLY_AMBIGUOUS = 'ETHNICALLY_AMBIGUOUS',
  LATINX = 'LATINX',
  MAORI = 'MAORI',
  MEDITERRANEAN = 'MEDITERRANEAN',
  MIDDLE_EASTERN = 'MIDDLE_EASTERN',
  PACIFIC_ISLANDER = 'PACIFIC_ISLANDER',
  SOUTHEAST_ASIAN = 'SOUTHEAST_ASIAN'
}

export type gqlExpireCompedSubscriptionInput = {
  /** Subscription id */
  readonly subscriptionId: Scalars['Int'];
};

export enum gqlExternalAuthenticationProvider {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  PROTUNES = 'PROTUNES',
  TWITTER = 'TWITTER'
}

/** Eye color object */
export type gqlEyeColor = {
  readonly __typename?: 'EyeColor';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** FFY Context input */
export type gqlFfyContextInput = {
  readonly artistIds: ReadonlyArray<Scalars['Int']>;
  readonly roleIds: ReadonlyArray<Scalars['Int']>;
};

export type gqlFacet = {
  readonly __typename?: 'Facet';
  readonly buckets: ReadonlyArray<gqlFacetBucket>;
  readonly name: Scalars['String'];
};

export type gqlFacetBucket = {
  readonly __typename?: 'FacetBucket';
  readonly docCount: Scalars['Int'];
  readonly facets?: Maybe<ReadonlyArray<gqlFacet>>;
  readonly key: Scalars['ID'];
};

/** Facial Hair object */
export type gqlFacialHair = {
  readonly __typename?: 'FacialHair';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlFeature = {
  readonly __typename?: 'Feature';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlFeatureEnum {
  ACCESS_LIST_AT_ORG = 'ACCESS_LIST_AT_ORG',
  ALLOW_CASTING_TO_HIDE_ROLE_RATES = 'ALLOW_CASTING_TO_HIDE_ROLE_RATES',
  AUDIO_VIDEO_UPLOAD = 'AUDIO_VIDEO_UPLOAD',
  AUTOMATICALLY_SHARE_CREATED_PROFILES_WITH_TALENT = 'AUTOMATICALLY_SHARE_CREATED_PROFILES_WITH_TALENT',
  CAN_ACCESS_TALENT_SCOUT = 'CAN_ACCESS_TALENT_SCOUT',
  CASTING_BILLBOARD_SUBMISSION = 'CASTING_BILLBOARD_SUBMISSION',
  CREATEPROFILE = 'CREATEPROFILE',
  CREATEPROJECT = 'CREATEPROJECT',
  GATE_MEDIA_REQUEST = 'GATE_MEDIA_REQUEST',
  GETPROFILE = 'GETPROFILE',
  GETPROJECT = 'GETPROJECT',
  LOCK_AGE_RANGE_FOR_TALENT = 'LOCK_AGE_RANGE_FOR_TALENT',
  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  ROLE_TRACKER_OPT_OUT = 'ROLE_TRACKER_OPT_OUT',
  SET_DEFAULTS_TO_BACKGROUND_FOR_EXTRAS_CASTING = 'SET_DEFAULTS_TO_BACKGROUND_FOR_EXTRAS_CASTING',
  SYSTEM_ACCESS = 'SYSTEM_ACCESS',
  UPDATEPROFILE = 'UPDATEPROFILE',
  UPDATEPROJECT = 'UPDATEPROJECT'
}

export type gqlFeatureInput = {
  readonly enabled: Scalars['Boolean'];
  readonly featureId: Scalars['Float'];
};

export type gqlFilterInput = {
  readonly keyType: gqlKeyType;
  readonly keys: ReadonlyArray<Scalars['ID']>;
};

export enum gqlFilterOperator {
  AND = 'AND',
  OR = 'OR'
}

export type gqlFinalizeMediaUploadInput = {
  /** The media items that are to be uploaded. */
  readonly media: ReadonlyArray<gqlFinalizeUploadMediaInput>;
};

export type gqlFinalizeMediaUploadResponse = {
  readonly __typename?: 'FinalizeMediaUploadResponse';
  readonly media?: Maybe<ReadonlyArray<gqlMedia>>;
  readonly storageUtilization?: Maybe<gqlArtistStorageUtilization>;
};

export type gqlFinalizeUploadMediaInput = {
  /** The duration of the new file in seconds. This is required for audio and video uploads. */
  readonly fileDuration?: InputMaybe<Scalars['Int']>;
  /** The height of the new file in pixels. This is required for image and video uploads. */
  readonly fileHeight?: InputMaybe<Scalars['Int']>;
  /** The width of the new file in pixels. */
  readonly fileWidth?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the new file. */
  readonly guid: Scalars['String'];
  readonly transformation?: InputMaybe<gqlMediaTransformationInput>;
};

export type gqlFormAssociatedProject = {
  readonly __typename?: 'FormAssociatedProject';
  readonly projectId: Scalars['Int'];
  readonly projectName: Scalars['String'];
};

/** Form Bulk Response Form Response */
export type gqlFormBulkResponseFormResponse = {
  readonly __typename?: 'FormBulkResponseFormResponse';
  readonly formVersionId?: Maybe<Scalars['Int']>;
  readonly responseId: Scalars['String'];
  readonly responseSchema: Scalars['JSON'];
};

/** Form Bulk Response Profile Details */
export type gqlFormBulkResponseProfileDetails = {
  readonly __typename?: 'FormBulkResponseProfileDetails';
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly phone?: Maybe<Scalars['String']>;
  readonly stageName: Scalars['String'];
  readonly submissionPhotoUrl?: Maybe<Scalars['String']>;
};

/** Form Bulk Response Rep Details */
export type gqlFormBulkResponseRepDetails = {
  readonly __typename?: 'FormBulkResponseRepDetails';
  readonly company?: Maybe<Scalars['String']>;
  readonly division?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

export type gqlFormDetails = {
  readonly __typename?: 'FormDetails';
  readonly description?: Maybe<Scalars['String']>;
  readonly formId: Scalars['Float'];
  readonly title?: Maybe<Scalars['String']>;
};

export type gqlFormGqlModel = {
  readonly __typename?: 'FormGqlModel';
  readonly associatedProjects?: Maybe<ReadonlyArray<gqlFormAssociatedProject>>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly createdBy?: Maybe<gqlAccount>;
  readonly description?: Maybe<Scalars['String']>;
  readonly formId: Scalars['Int'];
  /** The schema of the form */
  readonly formSchema?: Maybe<Scalars['JSON']>;
  readonly orgId?: Maybe<Scalars['Float']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};


export type gqlFormGqlModelAssociatedProjectsArgs = {
  pageProjects?: InputMaybe<gqlPageInput>;
};

export type gqlFormGqlModelPagedList = {
  readonly __typename?: 'FormGqlModelPagedList';
  readonly data: ReadonlyArray<gqlFormGqlModel>;
  readonly page: Scalars['Float'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Form details */
export type gqlFormInfo = {
  readonly __typename?: 'FormInfo';
  /** ID of form */
  readonly formId: Scalars['Float'];
  /** Title of form */
  readonly title?: Maybe<Scalars['String']>;
};

export type gqlFormResponseGqlModel = {
  readonly __typename?: 'FormResponseGqlModel';
  readonly description?: Maybe<Scalars['String']>;
  readonly formId?: Maybe<Scalars['Int']>;
  readonly formResponseId?: Maybe<Scalars['BigInt']>;
  /** The schema of the form response */
  readonly formResponseSchema?: Maybe<Scalars['JSON']>;
  /** The schema of the form */
  readonly formSchema?: Maybe<Scalars['JSON']>;
  readonly profile?: Maybe<gqlProfileGqlModel>;
  readonly title?: Maybe<Scalars['String']>;
};

/** value used to sort forms by name, created at, updated at, or creator name */
export enum gqlFormSortBy {
  CreatedAt = 'CreatedAt',
  CreatorName = 'CreatorName',
  FormTitle = 'FormTitle',
  UpdatedAt = 'UpdatedAt'
}

/** Sort condition for forms */
export type gqlFormSortConditionInput = {
  /** Sort by */
  readonly sortBy?: InputMaybe<gqlFormSortBy>;
  /** Sort by */
  readonly sortOrder?: InputMaybe<gqlFormSortOrder>;
};

/** value used to sort forms in ascending or descending order */
export enum gqlFormSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type gqlFormsFilterArgs = {
  readonly formName?: InputMaybe<Scalars['String']>;
};

/** Forms Report */
export type gqlFormsReportModel = {
  readonly __typename?: 'FormsReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly formId: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly mediaRequestId: Scalars['String'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlFormsReportPayload>;
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Forms Report Payload */
export type gqlFormsReportPayload = {
  readonly __typename?: 'FormsReportPayload';
  readonly data: ReadonlyArray<gqlRpcFormBulkResponseItem>;
  readonly formSchema: Scalars['JSON'];
  readonly mediaRequestName: Scalars['String'];
  readonly projectName: Scalars['String'];
};

export type gqlFreeCreditItemModel = {
  readonly __typename?: 'FreeCreditItemModel';
  readonly artistId: Scalars['Float'];
  readonly created: Scalars['DateTime'];
  readonly createdBy: Scalars['Float'];
  readonly credits: Scalars['Float'];
  readonly itemCode: gqlBillingItemCode;
  readonly itemId: Scalars['Float'];
  readonly updated?: Maybe<Scalars['DateTime']>;
};

export type gqlGqlFormBulkResponseFormResponse = {
  readonly __typename?: 'GQLFormBulkResponseFormResponse';
  readonly formVersionId?: Maybe<Scalars['Int']>;
  readonly responseId: Scalars['String'];
  /** The schema of the form */
  readonly responseSchema?: Maybe<Scalars['JSON']>;
};

export type gqlGqlFormBulkResponseProfileDetails = {
  readonly __typename?: 'GQLFormBulkResponseProfileDetails';
  readonly email: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly phone: Scalars['String'];
  readonly stageName: Scalars['String'];
  readonly submissionPhotoUrl: Scalars['String'];
};

export type gqlGqlFormBulkResponseRepDetails = {
  readonly __typename?: 'GQLFormBulkResponseRepDetails';
  readonly company: Scalars['String'];
  readonly division: Scalars['String'];
  readonly email: Scalars['String'];
  readonly phone: Scalars['String'];
};

export type gqlGqlFormBulkResponseRoleDetails = {
  readonly __typename?: 'GQLFormBulkResponseRoleDetails';
  readonly id: Scalars['Int'];
  readonly internalName: Scalars['String'];
  readonly name: Scalars['String'];
};

export type gqlGqlRpcFormBulkResponse = {
  readonly __typename?: 'GQLRpcFormBulkResponse';
  readonly data: ReadonlyArray<gqlGqlRpcFormBulkResponseItem>;
  /** The schema of the form */
  readonly formSchema?: Maybe<Scalars['JSON']>;
  readonly mediaRequestName: Scalars['String'];
  readonly projectName: Scalars['String'];
};

export type gqlGqlRpcFormBulkResponseItem = {
  readonly __typename?: 'GQLRpcFormBulkResponseItem';
  readonly formResponseDetails: gqlGqlFormBulkResponseFormResponse;
  readonly profileDetails: gqlGqlFormBulkResponseProfileDetails;
  readonly repDetails?: Maybe<gqlGqlFormBulkResponseRepDetails>;
  readonly roleDetails?: Maybe<gqlGqlFormBulkResponseRoleDetails>;
};

/** Gender Appearance object */
export type gqlGenderAppearance = {
  readonly __typename?: 'GenderAppearance';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlGenderAppearanceEnum {
  MAN = 'MAN',
  NON_BINARY_PERSON = 'NON_BINARY_PERSON',
  TRANS_MAN = 'TRANS_MAN',
  TRANS_WOMAN = 'TRANS_WOMAN',
  WOMAN = 'WOMAN'
}

/** Gender Identitie object */
export type gqlGenderIdentity = {
  readonly __typename?: 'GenderIdentity';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlGenderIdentityEnum {
  NON_BINARY = 'NON_BINARY',
  OTHER = 'OTHER',
  TRANS = 'TRANS',
  UNDEFINED = 'UNDEFINED'
}

/** Input type for Gender picker */
export type gqlGenderInput = {
  /**
   * Gender Appearance Ids. NOTE: Ok for now. TODO: Should simply be integer, not
   * array. Function update_profile_gender_appearance has _gender_appearance_id integer[]
   */
  readonly genderAppearanceIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Gender Identity Ids */
  readonly genderIdentityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Gender Other Identity Note */
  readonly genderOtherIdentityNote?: InputMaybe<Scalars['String']>;
};

/** General size object */
export type gqlGeneralSize = {
  readonly __typename?: 'GeneralSize';
  readonly category: gqlBasicSetting;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Size gender ID */
  readonly sizeGenderId: Scalars['ID'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlGetBillingInfoInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type gqlGetCastingListProfileArgs = {
  readonly listId: Scalars['ID'];
  readonly pageNumber?: Scalars['Int'];
  readonly pageSize?: Scalars['Int'];
};

export type gqlGetCastingListProfileResult = {
  readonly __typename?: 'GetCastingListProfileResult';
  readonly error?: Maybe<gqlGetCastingListProfileResultError>;
  readonly success?: Maybe<gqlCastingListProfilePagedList>;
};

export type gqlGetCastingListProfileResultError = gqlValidationError;

export type gqlGetChatMessagesByTopicModel = {
  readonly __typename?: 'GetChatMessagesByTopicModel';
  readonly lastViewedMessageId?: Maybe<Scalars['String']>;
  readonly messages: ReadonlyArray<gqlChatMessageModel>;
  readonly participantOrganizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** Collaborator Email */
export type gqlGetCollaboratorByEmailInput = {
  readonly email: Scalars['String'];
};

/** Creates an invite for a user to Collaborate on a project */
export type gqlGetCollaboratorInviteInput = {
  readonly inviteId: Scalars['ID'];
};

/** Get or create an attendee for the Virtual Audition Session associated to a Schedule */
export type gqlGetOrCreateVirtualAuditionAttendeeInput = {
  /** Schedule Id */
  readonly scheduleId: Scalars['Int'];
};

/** Get Organization Representative Lists Input */
export type gqlGetOrganizationRepresentativeListsInput = {
  /** Reps list sort order */
  readonly sortOrder?: InputMaybe<gqlListSortOrderEnum>;
};

export type gqlGetProjectSeriesByIdInput = {
  readonly projectSeriesId: ReadonlyArray<Scalars['ID']>;
};

export type gqlGetProjectSeriesByNameInput = {
  readonly projectSeriesName?: InputMaybe<Scalars['String']>;
};

/** Input type for fetching submissions by role and profile */
export type gqlGetSubmissionByRoleProfileInput = {
  /** Profile ID */
  readonly profileId: Scalars['Int'];
  /** Role ID */
  readonly roleId: Scalars['Int'];
};

export type gqlGlove = {
  readonly __typename?: 'Glove';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

export type gqlGroupLastKey = {
  readonly __typename?: 'GroupLastKey';
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
};

export type gqlGroupLastKeyInput = {
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
};

/** HairColor object */
export type gqlHairColor = {
  readonly __typename?: 'HairColor';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** HairStyle object */
export type gqlHairStyle = {
  readonly __typename?: 'HairStyle';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Is this artist actively enrolled in this benefit? */
export type gqlHasActiveEnrolledBenefit = {
  readonly __typename?: 'HasActiveEnrolledBenefit';
  readonly artistId: Scalars['Float'];
  readonly hasActiveEnrolledBenefits: Scalars['Boolean'];
};

export type gqlHasActiveEnrolledBenefitInput = {
  readonly artistIds: ReadonlyArray<Scalars['Int']>;
  readonly benefitsProvider: Scalars['String'];
};

/** General hat size object */
export type gqlHatSize = {
  readonly __typename?: 'HatSize';
  /** cm */
  readonly cm: Scalars['Float'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** inch */
  readonly inch: Scalars['String'];
  /** size */
  readonly size: Scalars['String'];
};

export type gqlHeight = {
  readonly __typename?: 'Height';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

export type gqlHip = {
  readonly __typename?: 'Hip';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

export type gqlICreateMediaResponse = {
  readonly media: ReadonlyArray<gqlMedia>;
};

export type gqlIError = {
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

export type gqlIpcInfo = {
  readonly __typename?: 'IPCInfo';
  readonly ipcProfessionalBio?: Maybe<Scalars['String']>;
};

/** ID range input */
export type gqlIdRangeInput = {
  /** The minimum ID value */
  readonly from?: InputMaybe<Scalars['Int']>;
  /** The maximum ID value */
  readonly to?: InputMaybe<Scalars['Int']>;
};

export type gqlInitiateMediaBulkDownloadInput = {
  /** Media ids to be downloaded */
  readonly ids: ReadonlyArray<Scalars['ID']>;
};

export type gqlInitiateMediaBulkDownloadResponse = {
  readonly __typename?: 'InitiateMediaBulkDownloadResponse';
  /** Whether the operation was successful */
  readonly success: Scalars['Boolean'];
};

export type gqlInitiateMediaUploadInput = {
  /** The ID of the artist associated with the upload. This field is required for certain users. */
  readonly artistId?: InputMaybe<Scalars['ID']>;
  /** Indicates whether the new media should be created as a draft, which will result in automatic archiving. */
  readonly draft?: InputMaybe<Scalars['Boolean']>;
  /** The media items that are to be uploaded. */
  readonly media: ReadonlyArray<gqlInitiateUploadMediaInput>;
};

export type gqlInitiateMediaUploadResponse = {
  readonly __typename?: 'InitiateMediaUploadResponse';
  readonly media?: Maybe<ReadonlyArray<gqlMedia>>;
  readonly storageUtilization?: Maybe<gqlArtistStorageUtilization>;
};

export type gqlInitiateUploadMediaInput = {
  /** The duration of the new file in seconds. This is required for audio and video uploads. */
  readonly fileDuration?: InputMaybe<Scalars['Int']>;
  /** The height of the new file in pixels. This is required for image and video uploads. */
  readonly fileHeight?: InputMaybe<Scalars['Int']>;
  /** The name of the new file. */
  readonly fileName: Scalars['String'];
  /** The size of the new file in bytes. */
  readonly fileSize: Scalars['Int'];
  /** The extension of the new file. */
  readonly fileType: gqlMediaFileTypeExtension;
  /** The width of the new file in pixels. */
  readonly fileWidth?: InputMaybe<Scalars['Int']>;
};

export type gqlInseam = {
  readonly __typename?: 'Inseam';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

/** Creates a internal user account based on given input values */
export type gqlInternalAccountCreateInput = {
  readonly email: Scalars['String'];
  readonly env?: InputMaybe<Scalars['String']>;
  readonly roleId: ReadonlyArray<Scalars['ID']>;
};

/** Context object for internal welcome */
export type gqlInternalWelcomeContext = {
  readonly __typename?: 'InternalWelcomeContext';
  readonly env: Scalars['String'];
};

export type gqlInternalWelcomeContextNotificationContextAndRecipient = {
  readonly __typename?: 'InternalWelcomeContextNotificationContextAndRecipient';
  readonly context: gqlInternalWelcomeContext;
  readonly to?: Maybe<Scalars['String']>;
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toOrgId?: Maybe<Scalars['Int']>;
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

/** Date interval */
export enum gqlIntervalEnum {
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week'
}

export enum gqlItemEnum {
  AUDIO_UPLOAD = 'AUDIO_UPLOAD',
  BENEFITS = 'BENEFITS',
  BENEFITS_PLUS = 'BENEFITS_PLUS',
  CUSTOM_URL_ACCESS = 'CUSTOM_URL_ACCESS',
  MOBILE_REELS_ACCESS = 'MOBILE_REELS_ACCESS',
  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  ROLE_TRACKER_ACCESS = 'ROLE_TRACKER_ACCESS',
  STORAGE_LIMIT_MB = 'STORAGE_LIMIT_MB',
  SUBMISSION = 'SUBMISSION',
  SUBMISSION_TRENDS = 'SUBMISSION_TRENDS',
  SYSTEM_ACCESS = 'SYSTEM_ACCESS',
  TALENT_SCOUT_ACCESS = 'TALENT_SCOUT_ACCESS',
  VIDEO_UPLOAD = 'VIDEO_UPLOAD',
  YOUR_AUDITIONS = 'YOUR_AUDITIONS'
}

/** Type of the identifier to use for dictionary lookup. */
export enum gqlKeyType {
  code = 'code',
  id = 'id'
}

export enum gqlLanguageEnum {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH'
}

export type gqlLanguageInfo = {
  readonly __typename?: 'LanguageInfo';
  readonly baseLocale: Scalars['String'];
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['Int'];
  readonly locale: Scalars['String'];
  readonly name: Scalars['String'];
};

/** Legacy Account By Profile */
export type gqlLegacyAccount = {
  readonly __typename?: 'LegacyAccount';
  /** Account Id */
  readonly accountId: Scalars['Float'];
  /** Legacy login */
  readonly legacyLogin: Scalars['String'];
};

export type gqlListGqlModel = {
  readonly __typename?: 'ListGqlModel';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly project: gqlProject;
  /** Project ID */
  readonly projectId: Scalars['Int'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gqlListGqlModelPagedList = {
  readonly __typename?: 'ListGqlModelPagedList';
  readonly data: ReadonlyArray<gqlListGqlModel>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Sort order for list of lists */
export enum gqlListSortOrderEnum {
  ALPHABETICAL = 'ALPHABETICAL',
  LAST_MODIFIED = 'LAST_MODIFIED'
}

/** Roster type object */
export type gqlListType = {
  readonly __typename?: 'ListType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Luminate company */
export type gqlLuminateCompanyModel = {
  readonly __typename?: 'LuminateCompanyModel';
  readonly contacts?: Maybe<ReadonlyArray<gqlLuminateContactModel>>;
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

/** Luminate contact */
export type gqlLuminateContactModel = {
  readonly __typename?: 'LuminateContactModel';
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

/** Luminate profile Data */
export type gqlLuminateProfile = {
  readonly __typename?: 'LuminateProfile';
  /** Luminate profile agencies */
  readonly agencies?: Maybe<ReadonlyArray<gqlLuminateCompanyModel>>;
  readonly countryOfOrigin?: Maybe<Scalars['String']>;
  /** Luminate profile credits */
  readonly credits?: Maybe<ReadonlyArray<gqlCredit>>;
  readonly dateOfBirth?: Maybe<Scalars['String']>;
  /** Luminate profile is deceased */
  readonly isDeceased?: Maybe<Scalars['Boolean']>;
  /** Luminate profile management companies */
  readonly managements?: Maybe<ReadonlyArray<gqlLuminateCompanyModel>>;
  readonly pronounce?: Maybe<Scalars['String']>;
  readonly screenScore?: Maybe<Scalars['String']>;
};

export type gqlMedia = {
  readonly __typename?: 'Media';
  readonly accountId: Scalars['Float'];
  readonly appearOnMediaRequests: Scalars['Boolean'];
  readonly appearOnSubmissions: Scalars['Boolean'];
  readonly appearancesOnProfiles: Scalars['Int'];
  readonly archivableOn?: Maybe<Scalars['DateTime']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Returns the Organization of the media create, if one exists */
  readonly createdByOrg?: Maybe<gqlOrganization>;
  /** Formatted size with units */
  readonly displaySize?: Maybe<gqlMediaDisplaySize>;
  readonly fileKey?: Maybe<Scalars['String']>;
  readonly fileType: gqlMediaFileType;
  readonly filename?: Maybe<Scalars['String']>;
  readonly guid: Scalars['String'];
  readonly height?: Maybe<Scalars['Int']>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly length?: Maybe<Scalars['Float']>;
  readonly mediaId: Scalars['Int'];
  readonly mediaParentId?: Maybe<Scalars['Float']>;
  readonly mediaStatus: gqlMediaStatus;
  readonly mediaStorageStatus: gqlMediaStorageStatus;
  readonly mediaType?: Maybe<gqlMediaType>;
  readonly moderationStatus: gqlMediaModerationStatus;
  readonly name?: Maybe<Scalars['String']>;
  readonly profiles?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  readonly purchaseStatus?: Maybe<gqlMediaPurchaseStatus>;
  readonly purpose: gqlMediaPurpose;
  /** @deprecated use url field instead */
  readonly resizedPaths: ReadonlyArray<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly thumbnail?: Maybe<gqlMedia>;
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly transformation?: Maybe<gqlMediaTransformation>;
  readonly transformations: ReadonlyArray<gqlMedia>;
  readonly updated?: Maybe<Scalars['DateTime']>;
  /** @deprecated use url field instead */
  readonly uploadPath?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['Int']>;
};

export type gqlMediaByType = {
  readonly __typename?: 'MediaByType';
  readonly AUDIO: ReadonlyArray<Scalars['Int']>;
  readonly DOCUMENT: ReadonlyArray<Scalars['Int']>;
  readonly PHOTO: ReadonlyArray<Scalars['Int']>;
  readonly VIDEO: ReadonlyArray<Scalars['Int']>;
};

/** Media files to change */
export type gqlMediaChangeInput = {
  /** Media sub request ID to change */
  readonly id: Scalars['ID'];
  /** List of the media IDs to change */
  readonly media: ReadonlyArray<Scalars['Int']>;
  readonly note?: InputMaybe<Scalars['String']>;
};

/** Media Count */
export type gqlMediaCount = {
  readonly __typename?: 'MediaCount';
  /** Count of the media */
  readonly count: Scalars['Int'];
  /** Media Type */
  readonly type: gqlMediaType;
};

/** MediaDisplaySize */
export type gqlMediaDisplaySize = {
  readonly __typename?: 'MediaDisplaySize';
  readonly size: Scalars['Float'];
  readonly unit: Scalars['String'];
};

/** Media File Types */
export type gqlMediaFileType = {
  readonly __typename?: 'MediaFileType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** MediaType */
  readonly mediaType: gqlMediaType;
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Describes the file type extension for media files. */
export enum gqlMediaFileTypeExtension {
  AAC = 'AAC',
  AIFF = 'AIFF',
  AMR = 'AMR',
  AVI = 'AVI',
  DOC = 'DOC',
  DOCX = 'DOCX',
  FLAC = 'FLAC',
  FLV = 'FLV',
  FMP4 = 'FMP4',
  GIF = 'GIF',
  HEIC = 'HEIC',
  JFIF = 'JFIF',
  JPE = 'JPE',
  JPEG = 'JPEG',
  JPG = 'JPG',
  M4A = 'M4A',
  M4V = 'M4V',
  MKV = 'MKV',
  MOV = 'MOV',
  MP3 = 'MP3',
  MP4 = 'MP4',
  MPEG = 'MPEG',
  MPG = 'MPG',
  MXF = 'MXF',
  ODT = 'ODT',
  OGA = 'OGA',
  OGG = 'OGG',
  OGV = 'OGV',
  PDF = 'PDF',
  PNG = 'PNG',
  RTF = 'RTF',
  TIF = 'TIF',
  TS = 'TS',
  WAV = 'WAV',
  WEBM = 'WEBM',
  WMA = 'WMA',
  WMV = 'WMV'
}

export type gqlMediaInitUploadInput = {
  readonly count: Scalars['Int'];
  /** Entity CODE to which media should be assigned */
  readonly entity?: InputMaybe<Scalars['String']>;
  /** Entity Id to which media should be assigned */
  readonly entityId?: InputMaybe<Scalars['Int']>;
};

/** Media Input Object */
export type gqlMediaInput = {
  /** Media ID */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

/** Media Invoice Model */
export type gqlMediaInvoice = {
  readonly __typename?: 'MediaInvoice';
  readonly coupon?: Maybe<gqlCoupon>;
  readonly creditsApplied: Scalars['Float'];
  readonly currency: gqlCurrency;
  readonly discount: Scalars['Float'];
  readonly email: Scalars['String'];
  readonly invoiceId?: Maybe<Scalars['String']>;
  readonly items: ReadonlyArray<gqlMediaPurchase>;
  readonly paymentDetails?: Maybe<gqlPaymentDetails>;
  readonly total: Scalars['Float'];
};

export type gqlMediaItem = {
  readonly __typename?: 'MediaItem';
  /** Media ID */
  readonly mediaId: Scalars['Int'];
  /** name */
  readonly name?: Maybe<Scalars['String']>;
  /** Upload Path */
  readonly uploadPath?: Maybe<Scalars['String']>;
};

/** MediaMediaType */
export type gqlMediaMediaType = {
  readonly __typename?: 'MediaMediaType';
  readonly mediaId: Scalars['Int'];
  readonly mediaType?: Maybe<gqlMediaType>;
  readonly mediaTypeId: Scalars['Int'];
};

export type gqlMediaMetadataInput = {
  readonly filename: Scalars['String'];
  /** The extension of uploaded file */
  readonly format: Scalars['String'];
  readonly guid: Scalars['ID'];
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly length?: InputMaybe<Scalars['Int']>;
  readonly size: Scalars['Int'];
  readonly transformation?: InputMaybe<gqlMediaTransformationInput>;
  readonly width?: InputMaybe<Scalars['Int']>;
};

/** MediaModerationStatus object */
export type gqlMediaModerationStatus = {
  readonly __typename?: 'MediaModerationStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlMediaModerationStatusEnum {
  PENDING = 'PENDING',
  READY = 'READY',
  REQUIRED = 'REQUIRED'
}

/** Media Purchase Model */
export type gqlMediaPurchase = {
  readonly __typename?: 'MediaPurchase';
  readonly currency: gqlCurrency;
  readonly media: gqlMedia;
  readonly price: Scalars['Float'];
};

export enum gqlMediaPurchaseStatus {
  FREE_CREDIT = 'FREE_CREDIT',
  FREE_CREDIT_READY = 'FREE_CREDIT_READY',
  NOT_PURCHASED = 'NOT_PURCHASED',
  PURCHASED = 'PURCHASED',
  UNLIMITED = 'UNLIMITED'
}

/** MediaPurpose object */
export type gqlMediaPurpose = {
  readonly __typename?: 'MediaPurpose';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** 'Media Request' Type */
export type gqlMediaRequest = {
  readonly __typename?: 'MediaRequest';
  readonly allowAdditionalMedia?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['DateTime'];
  readonly draftSubmissions?: Maybe<gqlWfSubmissionPagedList>;
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dueDateTimeZone?: Maybe<gqlWfTimeZone>;
  readonly formDetails?: Maybe<ReadonlyArray<gqlFormDetails>>;
  readonly id: Scalars['ID'];
  readonly includeSides: Scalars['Boolean'];
  readonly isDraft?: Maybe<Scalars['Boolean']>;
  readonly maxNumberOfMediaFiles: Scalars['Int'];
  readonly mediaTypes: ReadonlyArray<gqlRequestMediaType>;
  readonly messageForRepresentatives?: Maybe<Scalars['String']>;
  readonly messageForRepresentativesUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly name: Scalars['String'];
  readonly projectId: Scalars['Int'];
  readonly responses: gqlMediaResponsePagedList;
  readonly shouldResend: Scalars['Boolean'];
  readonly submissionIds: ReadonlyArray<Scalars['Int']>;
  readonly talentInstructions?: Maybe<Scalars['String']>;
  readonly talentInstructionsUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};


/** 'Media Request' Type */
export type gqlMediaRequestDraftSubmissionsArgs = {
  page?: InputMaybe<gqlPageInput>;
};


/** 'Media Request' Type */
export type gqlMediaRequestResponsesArgs = {
  page: gqlPageInput;
  statuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum gqlMediaRequestFilterType {
  WITHOUT_MEDIA_REQUEST = 'WITHOUT_MEDIA_REQUEST',
  WITH_MEDIA_REQUEST = 'WITH_MEDIA_REQUEST'
}

export type gqlMediaRequestInput = {
  readonly allowAdditionalMedia?: InputMaybe<Scalars['Boolean']>;
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly dueDateTimeZoneId?: InputMaybe<Scalars['Int']>;
  readonly excludeSubmissionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly formIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly includeSides: Scalars['Boolean'];
  readonly isDraft: Scalars['Boolean'];
  readonly maxNumberOfMediaFiles: Scalars['Int'];
  readonly mediaTypes: ReadonlyArray<gqlRequestMediaType>;
  readonly messageForRepresentatives?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly projectId: Scalars['Int'];
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly submissionIds: ReadonlyArray<Scalars['Int']>;
  readonly talentInstructions?: InputMaybe<Scalars['String']>;
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum gqlMediaRequestStatus {
  ADDITIONAL_MEDIA_ADDED = 'ADDITIONAL_MEDIA_ADDED',
  CANCELLED = 'CANCELLED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  MEDIA_ADDED = 'MEDIA_ADDED',
  NOT_SENT = 'NOT_SENT',
  PENDING = 'PENDING'
}

export type gqlMediaResponse = {
  readonly __typename?: 'MediaResponse';
  readonly cancelledAt?: Maybe<Scalars['DateTime']>;
  readonly chatTopic?: Maybe<gqlWfChatTopic>;
  readonly chatTopicKey: Scalars['String'];
  readonly formDetails?: Maybe<ReadonlyArray<gqlMediaResponseFormDetails>>;
  readonly id: Scalars['ID'];
  readonly mediaList?: Maybe<ReadonlyArray<gqlMediaResponseItem>>;
  /** @deprecated please use mediaList */
  readonly medias?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly note?: Maybe<Scalars['String']>;
  readonly repliedAt?: Maybe<Scalars['DateTime']>;
  readonly sentAt?: Maybe<Scalars['DateTime']>;
  readonly status: gqlMediaRequestStatus;
  readonly submission: gqlWfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  readonly submissionId: Scalars['Float'];
};

export type gqlMediaResponseCounts = {
  readonly __typename?: 'MediaResponseCounts';
  readonly count: Scalars['Float'];
  readonly status: gqlMediaRequestStatus;
};

export type gqlMediaResponseFormDetails = {
  readonly __typename?: 'MediaResponseFormDetails';
  readonly formId: Scalars['Int'];
  readonly formResponseId?: Maybe<Scalars['Int']>;
  readonly formTitle: Scalars['String'];
  readonly requestId?: Maybe<Scalars['String']>;
};

export type gqlMediaResponseInput = {
  readonly audios?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly documents?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly id: Scalars['ID'];
  readonly mediaAlias?: InputMaybe<ReadonlyArray<gqlMediaInput>>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly photos?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly response?: InputMaybe<gqlMediaResponseStatus>;
  readonly videos?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type gqlMediaResponseItem = {
  readonly __typename?: 'MediaResponseItem';
  readonly alias?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly isAdditional?: Maybe<Scalars['Boolean']>;
  readonly isForwardedToCD?: Maybe<Scalars['Boolean']>;
  readonly media: gqlWfMedia;
  readonly timestamp: Scalars['DateTime'];
  readonly type: Scalars['String'];
};

export type gqlMediaResponsePagedList = {
  readonly __typename?: 'MediaResponsePagedList';
  readonly data: ReadonlyArray<gqlMediaResponse>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export enum gqlMediaResponseStatus {
  ADDITIONAL_MEDIA_ADDED = 'ADDITIONAL_MEDIA_ADDED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  MEDIA_ADDED = 'MEDIA_ADDED'
}

/** MediaStatus object */
export type gqlMediaStatus = {
  readonly __typename?: 'MediaStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlMediaStorage = {
  readonly __typename?: 'MediaStorage';
  /** Type of Media (i.e. Audio) */
  readonly mediaType: gqlMediaType;
  /** Total size of media type used in bytes */
  readonly storageSizeBytes: Scalars['BigInt'];
};

export enum gqlMediaStorageEnum {
  ARCHIVED = 'ARCHIVED',
  MIGRATION_CLEANED = 'MIGRATION_CLEANED',
  MIGRATION_DATA = 'MIGRATION_DATA',
  MIGRATION_FILE_MISSING = 'MIGRATION_FILE_MISSING',
  MIGRATION_MOVED = 'MIGRATION_MOVED',
  MIGRATION_PROCESSED = 'MIGRATION_PROCESSED',
  MIGRATION_READY = 'MIGRATION_READY',
  READY = 'READY'
}

/** Media Storage Status object */
export type gqlMediaStorageStatus = {
  readonly __typename?: 'MediaStorageStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlMediaStorageStatusEnum {
  BAU_MIGRATED = 'BAU_MIGRATED',
  CLD_CLEANED = 'CLD_CLEANED',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
  UPLOADED = 'UPLOADED',
  UPLOADING = 'UPLOADING',
  UPLOAD_FAILED = 'UPLOAD_FAILED'
}

/** 'Media Sub-Request' Type for (Reps|Talent) */
export type gqlMediaSubRequest = {
  readonly __typename?: 'MediaSubRequest';
  readonly allowAdditionalMedia?: Maybe<Scalars['Boolean']>;
  /** Cancellation note */
  readonly cancellationNote?: Maybe<Scalars['String']>;
  /** The date when request was cancelled */
  readonly cancelledAt?: Maybe<Scalars['DateTime']>;
  readonly chatTopic?: Maybe<gqlWfChatTopic>;
  readonly chatTopicKey: Scalars['String'];
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  readonly dueDateTimeZone?: Maybe<gqlWfTimeZone>;
  readonly formDetails?: Maybe<ReadonlyArray<gqlMediaResponseFormDetails>>;
  /** Forwarded Note */
  readonly forwardNote?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly includeSides: Scalars['Boolean'];
  readonly isGated: Scalars['Boolean'];
  readonly maxNumberOfMediaFiles: Scalars['Int'];
  readonly mediaList?: Maybe<ReadonlyArray<gqlMediaResponseItem>>;
  readonly mediaTypes: ReadonlyArray<gqlRequestMediaType>;
  /**
   * The media files sent in response
   * @deprecated please use mediaList
   */
  readonly medias?: Maybe<ReadonlyArray<gqlWfMedia>>;
  readonly messageForRepresentatives?: Maybe<Scalars['String']>;
  readonly messageForRepresentativesUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The note sent in response */
  readonly note?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<gqlWfProfile>;
  readonly project: gqlWfProject;
  /** The date when response was sent */
  readonly repliedAt?: Maybe<Scalars['DateTime']>;
  /** Reps Worksheet Status */
  readonly repsWorksheetStatus: Scalars['String'];
  readonly requestedChangesMedia?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly requestedChangesNote?: Maybe<Scalars['String']>;
  readonly role: gqlWfRole;
  /** The date when request was sent */
  readonly sentAt?: Maybe<Scalars['DateTime']>;
  readonly status: gqlMediaRequestStatus;
  readonly submission: gqlWfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  readonly submissionId: Scalars['Int'];
  readonly talentInstructions: Scalars['String'];
  readonly talentInstructionsUpdatedAt?: Maybe<Scalars['DateTime']>;
  readonly updatedMedia?: Maybe<ReadonlyArray<Scalars['Int']>>;
};

export type gqlMediaToArchive = {
  readonly __typename?: 'MediaToArchive';
  readonly filePath: Scalars['String'];
  readonly format: Scalars['String'];
  readonly guid: Scalars['String'];
  readonly storageStatus: gqlMediaStorageStatusEnum;
  readonly type: gqlMediaTypeEnum;
};

export type gqlMediaTransformation = {
  readonly __typename?: 'MediaTransformation';
  readonly height?: Maybe<Scalars['Float']>;
  readonly rotate?: Maybe<Scalars['Float']>;
  readonly startOffset?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly xAxis?: Maybe<Scalars['Float']>;
  readonly yAxis?: Maybe<Scalars['Float']>;
};

export type gqlMediaTransformationInput = {
  readonly height?: InputMaybe<Scalars['Float']>;
  readonly rotate?: InputMaybe<Scalars['Float']>;
  readonly startOffset?: InputMaybe<Scalars['String']>;
  readonly width?: InputMaybe<Scalars['Float']>;
  readonly xAxis?: InputMaybe<Scalars['Float']>;
  readonly yAxis?: InputMaybe<Scalars['Float']>;
};

/** MediaType object */
export type gqlMediaType = {
  readonly __typename?: 'MediaType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly isRequestable: Scalars['Boolean'];
  readonly mediaVersionTypes: ReadonlyArray<gqlMediaVersionType>;
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlMediaTypeCodeEnum {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

export enum gqlMediaTypeEnum {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

/** Media Version Types */
export type gqlMediaVersionType = {
  readonly __typename?: 'MediaVersionType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly landscapeHeight: Scalars['Float'];
  readonly landscapeWidth: Scalars['Float'];
  /** name */
  readonly name: Scalars['String'];
  readonly portraitHeight: Scalars['Float'];
  readonly portraitWidth: Scalars['Float'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlMicroServices {
  BACKLOT = 'BACKLOT',
  FAST_CAPTURE = 'FAST_CAPTURE'
}

export type gqlMissingDataError = gqlIError & {
  readonly __typename?: 'MissingDataError';
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

export type gqlMoveAllProfileToCastingListInput = {
  readonly fromListId: Scalars['ID'];
  readonly includeNotes?: InputMaybe<Scalars['Boolean']>;
  readonly toListId: Scalars['ID'];
};

export type gqlMoveProfileToCastingListError = gqlValidationError;

export type gqlMoveProfileToCastingListInput = {
  readonly ids: ReadonlyArray<Scalars['ID']>;
  readonly includeNotes?: InputMaybe<Scalars['Boolean']>;
  readonly toListId: Scalars['ID'];
};

export type gqlMoveProfileToCastingListResult = {
  readonly __typename?: 'MoveProfileToCastingListResult';
  readonly error?: Maybe<gqlMoveProfileToCastingListError>;
  readonly success: Scalars['Boolean'];
};

export type gqlMoveSubmissionsFromSlotsInput = {
  readonly from: ReadonlyArray<gqlSlotSubmissionsInput>;
  readonly newSlotId: Scalars['Int'];
};

/** MultipleBirth object */
export type gqlMultipleBirth = {
  readonly __typename?: 'MultipleBirth';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlMutateFormError = gqlValidationErrorForm;

export type gqlMutateListError = gqlValidationError;

export type gqlMutation = {
  readonly __typename?: 'Mutation';
  /** Denotes if the project has been accepted successfully along with it(s) associated role(s) */
  readonly acceptProjectModification: Scalars['Boolean'];
  /** Adds Add-in profile to the timeslot. Returns added submission */
  readonly addAddInProfileToScheduleTimeSlot: gqlSubmission;
  readonly addAddress: Scalars['Float'];
  /** Adding submissions for all profiles by search input filters. Returns count of created submissions. */
  readonly addAllProfilesSubmissions: Scalars['Float'];
  /** Adds all profiles to list based on provided filters. */
  readonly addAllProfilesToList: Scalars['Float'];
  readonly addArtistFreeCredits: Scalars['Boolean'];
  /** Adds new audition session. Returns id. */
  readonly addAuditionSession?: Maybe<Scalars['Int']>;
  /** Adds new audition session group */
  readonly addAuditionSessionGroup?: Maybe<Scalars['Int']>;
  readonly addBillingInfo: gqlBillingInfo;
  /** Block a keyword from being used in a custom slug */
  readonly addBlockedKeyword: gqlBlockedKeyword;
  readonly addCastingListProfilesToRole: gqlAddProfileToRoleResult;
  readonly addCommentToPresentationSubmissionPublic: gqlPresentationSubmissionComment;
  /** Adds comment for the submission */
  readonly addCommentToSubmission: gqlPresentationSubmissionComment;
  readonly addNewSlug: gqlCustomSlug;
  readonly addNote: gqlNote;
  readonly addOrganizationAccount: gqlAccount;
  readonly addOrganizationLogo: gqlMedia;
  /** Adds pick for the profile in the package */
  readonly addPickToPackageProfile: Scalars['Int'];
  readonly addPickToPackageProfilePublic: Scalars['String'];
  readonly addPickToPresentationSubmissionPublic: Scalars['String'];
  /** Adds pick for the submission */
  readonly addPickToSubmission: Scalars['Int'];
  readonly addProfileBookout: Scalars['Boolean'];
  /** Creating profile conflict */
  readonly addProfileConflict: Scalars['Boolean'];
  /** Adds a list of profiles. */
  readonly addProfileList: gqlProfileList;
  /** Adds profiles (ids) to organization profile list */
  readonly addProfileListEntities: Scalars['Float'];
  readonly addProfilePet: gqlProfilePet;
  readonly addProfileProp: gqlProfileProp;
  readonly addProfileSkill: Scalars['Boolean'];
  readonly addProfileSportsEquipment: gqlProfileSportEquipment;
  /** Adding profile submission */
  readonly addProfileSubmission: ReadonlyArray<gqlSubmission>;
  readonly addProfileSubmissionOrGet: gqlSubmissionCreated;
  readonly addProfileToAuditionGroup: Scalars['Boolean'];
  readonly addProfileToAuditionGroupAddIn: Scalars['Boolean'];
  readonly addProfileToCastingList: gqlAddProfileToCastingListResult;
  /**
   * Adds a profile to a Project
   * @deprecated That mutation is deprecated. Please use addSubmission for the same
   */
  readonly addProfileToProject: gqlSubmission;
  /** Adds profile to the timeslot. Returns ID of added submission */
  readonly addProfileToScheduleTimeSlot: gqlSubmission;
  readonly addProfileVehicle: gqlProfileVehicle;
  readonly addProfileWardrobe: gqlProfileWardrobe;
  readonly addProjectNote?: Maybe<Scalars['Float']>;
  /** Adds a list of representatives. */
  readonly addRepresentativeList: Scalars['Float'];
  /** Add break to schedule */
  readonly addScheduleBreak: Scalars['Int'];
  /** Quick add submission (FastCapture support) */
  readonly addSubmissionQuick: Scalars['Int'];
  /** Add submission snapshot */
  readonly addSubmissionSnapshot: Scalars['Int'];
  /** Mutation for adding submission to the Presentation Group */
  readonly addSubmissionToPresentationGroup: ReadonlyArray<gqlPresentationGroupSubmission>;
  /** Add Submissions to Audition Group */
  readonly addSubmissionsToAuditionGroup: Scalars['Int'];
  readonly addSubmissionsToAuditionTalentPool: Scalars['Boolean'];
  readonly addSubmissionsToExistingSession: Scalars['Int'];
  /** Assign submissions to the calendar slot. Returns added submission ids */
  readonly addSubmissionsToSlot: ReadonlyArray<Scalars['BigInt']>;
  readonly addSubmittedProfilesToCastingLists: gqlAddProfileToCastingListResult;
  readonly addTalentToMediaRequest: gqlMediaRequest;
  /** Add the talent(s) to Presentation Folder */
  readonly addTalentToPresentationFolder: Scalars['Boolean'];
  readonly addTalentToQuestionRequest: gqlAskQuestionRequest;
  /** Add Timeframe to a Schedule */
  readonly addTimeframeToSchedule: Scalars['Float'];
  readonly approvePCProject: Scalars['Boolean'];
  readonly archiveMedia: gqlArchiveMediaResponse;
  /** @deprecated use archiveMediaAssetV2 instead */
  readonly archiveMediaAsset: Scalars['Boolean'];
  readonly archiveMediaAssetV2: Scalars['Boolean'];
  /** Archive notification */
  readonly archiveNotifications: Scalars['Boolean'];
  readonly archiveProjects: Scalars['String'];
  readonly archiveRole: Scalars['String'];
  readonly attachCreditVideo: ReadonlyArray<gqlMedia>;
  readonly attachMediaToArtist: gqlMedia;
  /** Attaches uploaded media to the Audition Group */
  readonly attachMediaToAuditionGroup: Scalars['Boolean'];
  /** Mutation for attaching media to the presentation group */
  readonly attachMediaToPresentationGroup: Scalars['Boolean'];
  readonly attachMediaToProfile: gqlMedia;
  readonly attachMediaToProfileSkill: gqlMedia;
  readonly attachMediasToProfile: Scalars['Boolean'];
  readonly attachMultipleMediaToProfile: ReadonlyArray<gqlProfileMedia>;
  /** Attaches uploaded media to the Audition Group in defined order */
  readonly attachOrderedMediaToAuditionGroup: Scalars['Boolean'];
  /** Attach Zendesk ticket number to a project */
  readonly attachProjectSupportTicketNumber: Scalars['Boolean'];
  /** Returns a session bearer token. */
  readonly bauLogin: Scalars['String'];
  /** BAU login by providing legacy data. Returns onboarding token. */
  readonly bauLoginByLegacyData: Scalars['Json'];
  readonly cancelBenefits: gqlCancelBenefits;
  readonly cancelRequest: Scalars['Boolean'];
  /** Cancel Schedule */
  readonly cancelSchedule: Scalars['Boolean'];
  readonly cancelSubscription: Scalars['Boolean'];
  readonly changeAuditionSessionTalentRoleName: Scalars['Boolean'];
  /** Changes order of the role. Returns either the order was changed or not */
  readonly changeRoleOrder: Scalars['Boolean'];
  /** Changes Submission order in Audition Group */
  readonly changeSubmissionOrderInAuditionGroup: Scalars['Boolean'];
  readonly checkEmailOtp: Scalars['Boolean'];
  readonly checkOtpCode: Scalars['Boolean'];
  /** Claims Rep/CD account multiple times */
  readonly claimEnterpriseAccount: Scalars['Json'];
  readonly cloneMedia: gqlMedia;
  readonly closeDivision?: Maybe<Scalars['Float']>;
  readonly completeArtistMediaCreation: gqlCreateArtistMediaResponse;
  readonly contactSubTalent: Scalars['Boolean'];
  readonly copyAllProfileToCastingList: gqlCopyProfileToCastingListResult;
  /** Copy the selected groups to the specific session */
  readonly copyAuditionGroupsToAuditionSession?: Maybe<Scalars['Int']>;
  readonly copyPresentation: Scalars['Int'];
  readonly copyProfileData: Scalars['Float'];
  readonly copyProfileFromExisting: gqlProfile;
  readonly copyProfileToCastingList: gqlCopyProfileToCastingListResult;
  readonly copyProfileUnderSameArtist: Scalars['Boolean'];
  /**
   * Copies submission data to another role
   * @deprecated Use moveSubmissionsToRole
   */
  readonly copySubmissionToRole: gqlSubmission;
  readonly createAccount: gqlAuthToken;
  readonly createAccountWithGoogle: gqlAuthToken;
  readonly createAgencyCallListReport: Scalars['String'];
  readonly createAllTalentSubmissionReport: Scalars['String'];
  readonly createArtistCredits: ReadonlyArray<gqlCredit>;
  readonly createArtistMedia: gqlCreateArtistMediaResponse;
  /** Creates 'Ask Question' request type */
  readonly createAskQuestionRequest: gqlAskQuestionRequest;
  /** Mutation for creating Audition Presentation */
  readonly createAuditionPresentation: Scalars['Int'];
  /** Creates new pair of tokens */
  readonly createAuthContextByAccountId: gqlAuthToken;
  readonly createBookingCallListReport: Scalars['String'];
  readonly createChatMessage: gqlChatMessageModel;
  readonly createChatTopicFromSubRequest: Scalars['Boolean'];
  readonly createCollaboratorAccount: gqlAuthToken;
  readonly createCollaboratorInvite: Scalars['ID'];
  readonly createCompedSubscription: Scalars['Boolean'];
  readonly createCouponRedemption: Scalars['Boolean'];
  readonly createCredit: gqlCredit;
  readonly createEndUserAccount: gqlAccount;
  readonly createForm: gqlCreateFormResult;
  readonly createFormResponseReport: Scalars['String'];
  readonly createInternalAccount: Scalars['Float'];
  readonly createList: gqlCreateCastingListResult;
  /** Creates 'Media' request type */
  readonly createMediaRequest: gqlMediaRequest;
  readonly createOrganization: gqlOrganization;
  readonly createPCProject: gqlProject;
  readonly createPCRole: gqlRole;
  /** Mutation for creating Presentation Folder */
  readonly createPresentationFolder: Scalars['Int'];
  /** Mutation for creating Presentation Group in a Presentation Folder */
  readonly createPresentationGroup: Scalars['Int'];
  readonly createPresentationLogReport: Scalars['String'];
  readonly createPresentationLogReportPublic: Scalars['String'];
  readonly createPresentationPickReport: Scalars['String'];
  readonly createPresentationSizeSheetsReport: Scalars['String'];
  readonly createPresentationSizeSheetsReportPublic: Scalars['String'];
  readonly createProfile: gqlProfile;
  readonly createProject: gqlProject;
  readonly createProjectOverviewCDReport: Scalars['String'];
  readonly createProjectOverviewReport: Scalars['String'];
  /** Project Series ID */
  readonly createProjectSeries: Scalars['Float'];
  readonly createProjectSubmissionReport: Scalars['String'];
  /** Mutation for creating Rep Package Presentation */
  readonly createRepPackagePresentation: gqlPresentation;
  /** Sends request to media-request for all selected request statuses */
  readonly createRequest: Scalars['Boolean'];
  readonly createRole: gqlRole;
  /** Creates 'Roster Invitation Request' type */
  readonly createRosterInvitationRequest: gqlRosterInvitationRequest;
  readonly createSavedSearch: gqlSavedSearch;
  /** Creates a schedule */
  readonly createSchedule: gqlScheduleDetail;
  readonly createScheduleReport: Scalars['String'];
  readonly createScheduleSizeSheetsReport: Scalars['String'];
  readonly createSessionAndAddSubmissions: Scalars['Int'];
  /**
   * Mutation for creating Share Selects Presentation
   * @deprecated use createShareTalentPresentation instead
   */
  readonly createShareSelectPresentation: gqlPresentation;
  /** Mutation for creating Share Selects Presentation */
  readonly createShareTalentPresentation: gqlPresentation;
  /** @deprecated Use createCompedSubscription instead */
  readonly createSubscription: Scalars['Boolean'];
  readonly createTalentScoutHistory: gqlTalentScoutModel;
  readonly createTalentSubmissionReport: Scalars['String'];
  readonly customizeTalentInFolder: gqlPresentationFolderTalentCustomization;
  /** Delete account */
  readonly deleteAccount: Scalars['Boolean'];
  /** Delete submission */
  readonly deleteAllSubmissions: Scalars['Float'];
  /** Returns true if deleted successfully. */
  readonly deleteAuditionSession: Scalars['Boolean'];
  /** Unblock a keyword from being used in a custom slug */
  readonly deleteBlockedKeyword: gqlSuccessResponse;
  /** Delete comment for the submission */
  readonly deleteCommentToPresentationSubmissionPublic: Scalars['Boolean'];
  readonly deleteCredit: Scalars['Boolean'];
  readonly deleteCreditMedia: Scalars['Boolean'];
  readonly deleteList: gqlDeleteCastingListResult;
  readonly deleteMedia: gqlDeleteMediaResponse;
  /** @deprecated use deleteMediaV2 instead */
  readonly deleteMediaAsset: Scalars['Boolean'];
  /** Deletes Audition Group Media */
  readonly deleteMediaFromAuditionGroup: Scalars['Int'];
  /** Mutation for deleting media from the presentation group */
  readonly deleteMediaFromPresentationGroup: Scalars['Boolean'];
  readonly deleteMediaV2: gqlDeleteMediaV2Response;
  readonly deleteNote: Scalars['Boolean'];
  readonly deleteOrganzationAddress: Scalars['Boolean'];
  readonly deletePick: Scalars['Boolean'];
  readonly deletePickFromPresentationProfilePublic: Scalars['Boolean'];
  readonly deletePickFromPresentationSubmissionPublic: Scalars['Boolean'];
  /** Removes pick from the submission */
  readonly deletePickFromSubmission: Scalars['Boolean'];
  /** Mutation for deleting Presentation */
  readonly deletePresentation?: Maybe<Scalars['Boolean']>;
  /** Archives the Presentation Folder */
  readonly deletePresentationFolder: Scalars['Boolean'];
  /** Deletes the talent from Presentation Folder */
  readonly deletePresentationFolderTalent: Scalars['Boolean'];
  /** Mutation for deleting Presentation Group */
  readonly deletePresentationGroup: Scalars['Boolean'];
  readonly deleteProfile: Scalars['Boolean'];
  /** Creating profile conflict */
  readonly deleteProfileConflict: Scalars['Boolean'];
  /** Deletes the profile list by listId. */
  readonly deleteProfileList: Scalars['Float'];
  /** Removes profiles (ids) from organization profile list */
  readonly deleteProfileListEntities: Scalars['Float'];
  readonly deleteProfileMedia: Scalars['Boolean'];
  readonly deleteProfilePet: Scalars['Boolean'];
  readonly deleteProfileProp: Scalars['Boolean'];
  readonly deleteProfileSkill: Scalars['Boolean'];
  readonly deleteProfileSportsEquipment: Scalars['Boolean'];
  readonly deleteProfileVehicle: Scalars['Boolean'];
  readonly deleteProfileWardrobe: Scalars['Boolean'];
  /** Count of items deleted */
  readonly deleteProjectSeries: Scalars['Float'];
  /** Deletes the representative list by ListId. */
  readonly deleteRepresentativeList: Scalars['Float'];
  readonly deleteRequest: Scalars['Boolean'];
  readonly deleteRole: Scalars['Boolean'];
  readonly deleteSavedSearch: Scalars['Int'];
  /** Delete submission */
  readonly deleteSubmission: Scalars['Boolean'];
  /** Delete comment for the submission */
  readonly deleteSubmissionComment: Scalars['Boolean'];
  /** Mutation for deleting submission from the Presentation Group */
  readonly deleteSubmissionFromPresentationGroup: Scalars['Boolean'];
  /**
   * Remove submission from calendar slot
   * @deprecated use deleteSubmissionsFromSlot
   */
  readonly deleteSubmissionFromSlot: Scalars['Boolean'];
  /** Delete submission */
  readonly deleteSubmissionsBulk: Scalars['Boolean'];
  /** Delete Submissions from Audition Group */
  readonly deleteSubmissionsFromAuditionGroup: Scalars['Int'];
  /** Remove submissions from calendar slots */
  readonly deleteSubmissionsFromSlot: Scalars['Boolean'];
  readonly deleteSubscription: Scalars['Boolean'];
  /** Delete Timeframe from a Schedule */
  readonly deleteTimeframeFromSchedule: Scalars['Boolean'];
  readonly editCastingList: gqlEditCastingListResult;
  readonly editCastingListProfiles: gqlEditProfileCastingListResult;
  readonly editMedia: gqlMedia;
  readonly editMediaThumbnail: gqlMedia;
  /** Count of Modified Series */
  readonly editProjectSeries: Scalars['Float'];
  readonly enrollBenefits: gqlEnrollBenefits;
  readonly expireCompedSubscription: Scalars['Boolean'];
  readonly expireSlug: gqlSuccessResponse;
  readonly finalizeMediaUpload: ReadonlyArray<gqlMedia>;
  readonly finalizeMediaUploadV2: gqlFinalizeMediaUploadResponse;
  readonly forwardRequests: ReadonlyArray<gqlRepWorksheetItem>;
  /** Generates signature for uploading media */
  readonly getMediaUploadSignature: Scalars['String'];
  /** Get or create an attendee for the virtual audition */
  readonly getOrCreateVirtualAuditionAttendee?: Maybe<gqlVirtualAuditionAttendee>;
  /** Returns a session bearer token for internal user. */
  readonly googleLoginInternal: gqlAuthToken;
  readonly grantTalentAccess: Scalars['Boolean'];
  readonly hideProfileInRoster: gqlProfile;
  readonly hideProfileInRosterAndSearch: gqlProfile;
  /** Mutation for import Audition Group to the Presentation Folder */
  readonly importPresentationFolderFromAuditionGroup?: Maybe<gqlPresentationFolder>;
  /** Mutation for import Audition Session data to the Presentation */
  readonly importPresentationFromAudition: Scalars['Int'];
  readonly initMediaUpload: ReadonlyArray<gqlMedia>;
  /** Initiates presentation's media downloading */
  readonly initPresentationMediaToDownloadPublic: Scalars['String'];
  /** Initiates media bulk download */
  readonly initiateMediaBulkDownload: gqlInitiateMediaBulkDownloadResponse;
  readonly initiateMediaUploadV2: gqlInitiateMediaUploadResponse;
  /** Returns a session bearer token. */
  readonly login: gqlAuthToken;
  /** Sign in user on behalf of internal service */
  readonly loginDelegatedUser: gqlAuthToken;
  /** Creates new pair of tokens for CSR user */
  readonly loginImpersonatedAccount: gqlAuthToken;
  /** Returns a session bearer token. */
  readonly loginWithAuthProvider: gqlAuthToken;
  /** Deletes refresh token */
  readonly logout: Scalars['Boolean'];
  readonly markAllAsRead: Scalars['Boolean'];
  readonly markChatMessageAsReadFromId: Scalars['Boolean'];
  /** Marks notifications as viewed */
  readonly markNotifications: Scalars['Boolean'];
  readonly mergeOrganization: Scalars['Boolean'];
  readonly mergeUserAccounts: Scalars['Boolean'];
  readonly moveAllProfileToCastingList: gqlMoveProfileToCastingListResult;
  readonly moveProfileToCastingList: gqlMoveProfileToCastingListResult;
  /** Moves Submissions to the new time slot */
  readonly moveSubmissionsFromSlotBulk: Scalars['Boolean'];
  /** Move submissions to another role */
  readonly moveSubmissionsToRole: ReadonlyArray<gqlSubmission>;
  /** Prepare account for onboarding. */
  readonly prepareAccountForOnboarding: Scalars['Boolean'];
  readonly publishRoles: Scalars['Boolean'];
  readonly purchaseMedia: gqlMediaInvoice;
  readonly purchaseSubscription: gqlSubscriptionInvoice;
  readonly reAddUserToOrganization?: Maybe<Scalars['Float']>;
  readonly reassignProjectToOrganization?: Maybe<Scalars['Boolean']>;
  readonly reassociateChildOrgtoParent?: Maybe<Scalars['Boolean']>;
  /** Creates new pair of tokens */
  readonly refreshToken: gqlAuthToken;
  readonly registerAccountDevice: Scalars['Boolean'];
  /** Denotes if the project has been rejected successfully along with it(s) associated role(s) */
  readonly rejectProjectModification: Scalars['Boolean'];
  readonly removeActiveCouponRedemption: Scalars['Boolean'];
  readonly removeBillingInfo: Scalars['Boolean'];
  readonly removeOrganizationLogo: gqlOrganization;
  readonly removeProfileBookout: Scalars['Boolean'];
  readonly removeProfileFromCastingList: gqlRemoveProfileFromCastingListResult;
  readonly removeProfileResume: Scalars['Boolean'];
  readonly removeUserFromOrganization?: Maybe<Scalars['Float']>;
  readonly renewSubscription: gqlSubscriptionRenewal;
  readonly reopenDivision: Scalars['Boolean'];
  readonly reorderList: gqlReorderResult;
  readonly reorderProfile: gqlReorderResult;
  /** Reply to 'Ask Question' request */
  readonly replyToAskQuestionRequest: gqlAskQuestionSubRequest;
  /** Reply to 'Media' request */
  readonly replyToMediaRequest: gqlMediaSubRequest;
  /** Reply to 'Schedule Invite' request */
  readonly replyToScheduleInviteRequest: gqlScheduleInviteSubRequest;
  /** Request changes on 'Media' request */
  readonly requestMediaChanges: gqlMediaSubRequest;
  /** Request for additional submissions for provided roles. Returns either request was sent or not. */
  readonly requestSubmissionsForRoles: Scalars['Boolean'];
  readonly resendCreatePasswordLink: Scalars['Boolean'];
  readonly resendEmailVerification?: Maybe<Scalars['String']>;
  /** Resent Verification email */
  readonly resentVerificationEmail: Scalars['Json'];
  /** Creates a temporary, single-use email otp code */
  readonly resetEmail: Scalars['String'];
  /** Creates a temporary, single-use password reset token. */
  readonly resetPassword: Scalars['String'];
  /** Reset password using B21 email, BAU email, BAU username. */
  readonly resetPasswordBauB21: Scalars['Json'];
  /** Creates a temporary, single-use password reset token by legacy login and legacy instance. */
  readonly resetPasswordByLegacyLogin: Scalars['String'];
  /** Deletes the current observer url and generates a new one */
  readonly resetSessionObserverUrl: gqlResetSessionObserverUrlOutput;
  readonly restoreArchivedMedia: gqlRestoreArchivedMediaResponse;
  readonly restoreDeletedMedia: gqlRestoreDeletedMediaResponse;
  /** Restore notification */
  readonly restoreNotifications: Scalars['Boolean'];
  readonly restoreProjects: Scalars['String'];
  readonly restoreRole: Scalars['String'];
  readonly resumeSubscription: Scalars['Boolean'];
  readonly revokeCollaboratorAccessFromOrg: Scalars['Boolean'];
  readonly revokeCollaboratorAccessFromProject: Scalars['Boolean'];
  /** Saves 'Ask Question' request type as Draft */
  readonly saveDraftAskQuestionRequest: gqlAskQuestionRequest;
  /** Saves 'Media' request type as Draft */
  readonly saveDraftMediaRequest: gqlMediaRequest;
  readonly saveFormResponse: gqlSaveFormResponseResult;
  /** Add submissions for all profiles supplied. Returns count of created submissions. */
  readonly selectProfilesFromRoster: Scalars['Float'];
  readonly sendAccountEmailVerificationCode: Scalars['String'];
  readonly sendDirectEmailToPickedPackageTalent: Scalars['Boolean'];
  readonly sendDirectEmailToPresentationFolderTalent: Scalars['Boolean'];
  readonly sendDirectMessageToTalents: Scalars['Boolean'];
  /** Email presentation link */
  readonly sendPresentationLinkEmail: Scalars['Boolean'];
  /** Sends notification to all representatives */
  readonly sendProjectUpdateMessageToReps: Scalars['Boolean'];
  /** Send 'Schedule Invite' request */
  readonly sendScheduleInviteRequest: Scalars['Boolean'];
  readonly sendVerificationCode: Scalars['String'];
  /** Sends Voice Over 'Media' request */
  readonly sendVoiceOverMediaRequest: ReadonlyArray<gqlMediaSubRequest>;
  readonly setAccountProjectListFilterPreferences: Scalars['Boolean'];
  readonly setAccountSchedulePreferences: Scalars['Boolean'];
  readonly setCastingListProfileNote: gqlAddNoteToCastingListProfileResult;
  readonly setProfileActive: gqlProfile;
  readonly setProfileResume: gqlMedia;
  /** Denotes if the update went successfully */
  readonly setProjectAsViewed: Scalars['Boolean'];
  readonly setProjectStatus: Scalars['Boolean'];
  readonly setRoleRepStatus: Scalars['Boolean'];
  /** Casting Director can set reply status */
  readonly setSheduleInviteRequestStatus: gqlScheduleInviteSubRequest;
  readonly setSubscriptionPaymentMethod: Scalars['Boolean'];
  readonly shiftScheduleTimeframe?: Maybe<gqlScheduleTimeframeShiftedResult>;
  readonly submitPCProjectForApproval: Scalars['Boolean'];
  /** Submits all selected submissions by REPS for the role */
  readonly submitSelectedSubmissionForRole: Scalars['Boolean'];
  /** Submits all selected submissions by REPS for the role */
  readonly submitSelectedSubmissionForRoleV1: Scalars['Int'];
  /** Creates new pair of tokens */
  readonly switchAuthContext: gqlAuthToken;
  readonly unHideProfileInRosterAndSearch: gqlProfile;
  readonly unlockMedia: gqlUnlockMediaResponse;
  readonly updateAccount: gqlAccount;
  readonly updateAccountBillingInfo: Scalars['Boolean'];
  readonly updateAccountName: gqlAccount;
  readonly updateAccountPhone: gqlAccount;
  /** Returns a session bearer token. */
  readonly updateAccountPreMappedOrganizations: gqlUpdateLegacyResult;
  readonly updateAccountRosterInvitationToken: gqlAccount;
  /** Mutation for updating address */
  readonly updateAddress: Scalars['Float'];
  /** Update organization for Agent rep User */
  readonly updateAgentOrganization: gqlUpdateOrganizationResult;
  readonly updateArtist: Scalars['Boolean'];
  /** Updates 'Ask Question' request type */
  readonly updateAskQuestionRequest: gqlAskQuestionRequest;
  /** Update AuditionGroupMedia */
  readonly updateAuditionGroupMedia: Scalars['Int'];
  /** Mutation for updating audition group sort order */
  readonly updateAuditionGroupMediaOrder: Scalars['Boolean'];
  /** Set thumbnail media to the audition group */
  readonly updateAuditionGroupThumbnail: gqlAuditionGroup;
  /** Updates existing audition session. Returns true if updated successfully. */
  readonly updateAuditionSession: Scalars['Boolean'];
  /** Updates new audition session group */
  readonly updateAuditionSessionGroup?: Maybe<Scalars['Boolean']>;
  /** Updates audition session groups */
  readonly updateAuditionSessionGroupsBulk?: Maybe<Scalars['Float']>;
  /** Update existing account */
  readonly updateBauAccount: Scalars['Json'];
  /** Resent Verification email */
  readonly updateBauEmailAddress: Scalars['Json'];
  readonly updateBillingInfo: gqlBillingInfo;
  /** Update organization for casting director user */
  readonly updateCastingDirectorOrganization: gqlUpdateOrganizationResult;
  readonly updateCredit: gqlCredit;
  readonly updateCreditOrder: Scalars['Boolean'];
  readonly updateEmailWithToken: gqlAuthToken;
  readonly updateForm: gqlFormGqlModel;
  /** Update existing account */
  readonly updateLegacyProfile: gqlUpdateLegacyResult;
  readonly updateMediaAsset: gqlMedia;
  /** Updates 'Media' request type */
  readonly updateMediaRequest: gqlMediaRequest;
  readonly updateMediaRequestMessageForRepresentatives: gqlMediaRequest;
  readonly updateMediaRequestTalentInstructions: gqlMediaRequest;
  readonly updateNote: Scalars['Boolean'];
  readonly updateNotificationEntityTypeSubscription: Scalars['Boolean'];
  readonly updateNotificationNotificationTypeSubscription: Scalars['Boolean'];
  readonly updateOrganization: gqlOrganization;
  readonly updateOrganizationAccount?: Maybe<Scalars['Boolean']>;
  readonly updateOrganizationFeatures: ReadonlyArray<gqlFeature>;
  readonly updatePCAccount?: Maybe<Scalars['Float']>;
  /** Returns a session bearer token. */
  readonly updatePCOrganizations: gqlUpdateLegacyResult;
  readonly updatePCProject: Scalars['Boolean'];
  readonly updatePassword: Scalars['String'];
  readonly updatePasswordWithToken: Scalars['Boolean'];
  /** Mutation for updating Presentation */
  readonly updatePresentation?: Maybe<Scalars['Int']>;
  /** Updates presentation folder */
  readonly updatePresentationFolder: Scalars['Boolean'];
  /** Updates presentation folder sort order */
  readonly updatePresentationFolderOrder: Scalars['Boolean'];
  /** Mutation for updating Presentation Group */
  readonly updatePresentationGroup: gqlPresentationGroup;
  /** Mutation for updating presentation group sort order */
  readonly updatePresentationGroupMediaOrder: Scalars['Boolean'];
  /** Mutation for updating presentation group sort order */
  readonly updatePresentationGroupOrder: Scalars['Boolean'];
  /** Set thumbnail media to the presentation group */
  readonly updatePresentationGroupThumbnail: gqlPresentationGroup;
  readonly updateProfile: gqlProfile;
  readonly updateProfileArtist: Scalars['Int'];
  readonly updateProfileBookout: Scalars['Boolean'];
  /** Creating profile conflict */
  readonly updateProfileConflict: Scalars['Boolean'];
  readonly updateProfileCreditTypeSortOrder: Scalars['Boolean'];
  /** Updates the name of a Profile List. */
  readonly updateProfileListName: Scalars['Float'];
  /** Update single profile media */
  readonly updateProfileMedia: Scalars['Boolean'];
  /** Update profile media list */
  readonly updateProfileMediaList: Scalars['Boolean'];
  readonly updateProfileMediaVisibility: Scalars['Boolean'];
  readonly updateProfileMoveToArtist: gqlProfile;
  readonly updateProfilePet: gqlProfilePet;
  readonly updateProfileProp: gqlProfileProp;
  readonly updateProfileSkill: Scalars['Boolean'];
  readonly updateProfileSportsEquipment: gqlProfileSportEquipment;
  readonly updateProfileVehicle: gqlProfileVehicle;
  readonly updateProfileVisibilities: Scalars['Boolean'];
  readonly updateProfileWardrobe: gqlProfileWardrobe;
  readonly updateProject: gqlProject;
  /** Returns a session bearer token. */
  readonly updateProjectCreatorAccount: gqlAuthTokenItem;
  /** Updates a list of representatives. */
  readonly updateRepresentativeList: Scalars['Float'];
  /** Updates the name of a Reps List. */
  readonly updateRepresentativeListName: Scalars['Float'];
  readonly updateRequestDueDate: gqlRequestUnion;
  readonly updateRole?: Maybe<gqlRole>;
  readonly updateRoleOpenCall?: Maybe<gqlRole>;
  readonly updateRoleStatus: Scalars['Boolean'];
  readonly updateRolesWardrobeBulk: Scalars['Boolean'];
  readonly updateSavedSearch: gqlSavedSearch;
  /** Update break */
  readonly updateScheduleBreak: Scalars['Boolean'];
  /** Update schedule */
  readonly updateScheduleWithAddress: Scalars['Boolean'];
  /** Update submission */
  readonly updateSubmission: gqlSubmission;
  /** Updates submission Selction Tag and Gets Related Submissions for the artist */
  readonly updateSubmissionSelection: gqlSubmissionSelectionResult;
  /** Update submission status */
  readonly updateSubmissionStatus: Scalars['Boolean'];
  readonly updateSubmissionsInAuditionTalentPool: Scalars['Boolean'];
  /** Update status of a bunch submissions */
  readonly updateSubmissionsStatus: ReadonlyArray<gqlSubmission>;
  /** Update account email and resend email verification link. Returns new emailResetToken. */
  readonly updateVerificationEmail: Scalars['String'];
  readonly upgradePCOrgToCD?: Maybe<Scalars['Boolean']>;
  readonly verifyAccountEmail: gqlAuthToken;
  /** Verify BAU account from email link */
  readonly verifyBauAccount: Scalars['String'];
  readonly widenPublicationRoles: Scalars['Boolean'];
};


export type gqlMutationAcceptProjectModificationArgs = {
  id: Scalars['ID'];
};


export type gqlMutationAddAddInProfileToScheduleTimeSlotArgs = {
  input: gqlAddProfileToTimeSlotAddInInput;
};


export type gqlMutationAddAddressArgs = {
  addToOrganization?: InputMaybe<Scalars['Boolean']>;
  input: gqlAddressInput;
};


export type gqlMutationAddAllProfilesSubmissionsArgs = {
  roleId: Scalars['Int'];
  searchInput: gqlProfileSearchInput;
};


export type gqlMutationAddAllProfilesToListArgs = {
  input: gqlProfileSearchInput;
  listId: Scalars['Float'];
};


export type gqlMutationAddArtistFreeCreditsArgs = {
  input: gqlAddArtistFreeCreditsInput;
};


export type gqlMutationAddAuditionSessionArgs = {
  inputModel: gqlAuditionSessionCreateInput;
};


export type gqlMutationAddAuditionSessionGroupArgs = {
  inputModel: gqlAuditionGroupInput;
};


export type gqlMutationAddBillingInfoArgs = {
  input: gqlAddBillingInfoInput;
};


export type gqlMutationAddBlockedKeywordArgs = {
  blockedReason?: InputMaybe<gqlCustomSlugBlockedReasonEnum>;
  blockedReasonCode?: InputMaybe<Scalars['String']>;
  blockedReasonNote?: InputMaybe<Scalars['String']>;
  keyword: Scalars['String'];
};


export type gqlMutationAddCastingListProfilesToRoleArgs = {
  input: gqlAddCastingListProfileToRoleInput;
};


export type gqlMutationAddCommentToPresentationSubmissionPublicArgs = {
  comment: Scalars['String'];
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};


export type gqlMutationAddCommentToSubmissionArgs = {
  comment: Scalars['String'];
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
};


export type gqlMutationAddNewSlugArgs = {
  profileId: Scalars['Int'];
  slugName: Scalars['String'];
};


export type gqlMutationAddNoteArgs = {
  input: gqlAddNoteInput;
};


export type gqlMutationAddOrganizationAccountArgs = {
  companyAdmin: Scalars['Boolean'];
  organizationId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type gqlMutationAddOrganizationLogoArgs = {
  mediaId: Scalars['Int'];
  organizationId: Scalars['Int'];
};


export type gqlMutationAddPickToPackageProfileArgs = {
  packageId: Scalars['Float'];
  profileId: Scalars['Float'];
};


export type gqlMutationAddPickToPackageProfilePublicArgs = {
  packageId: Scalars['Float'];
  profileId: Scalars['Float'];
  userName: Scalars['String'];
};


export type gqlMutationAddPickToPresentationSubmissionPublicArgs = {
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};


export type gqlMutationAddPickToSubmissionArgs = {
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
};


export type gqlMutationAddProfileBookoutArgs = {
  artistId?: InputMaybe<Scalars['Float']>;
  input: gqlProfileBookOutInput;
};


export type gqlMutationAddProfileConflictArgs = {
  input: gqlCreateProfileConflictInput;
};


export type gqlMutationAddProfileListArgs = {
  input: gqlAddProfileListInput;
};


export type gqlMutationAddProfileListEntitiesArgs = {
  input: gqlAddOrDeleteListEntitiesInput;
};


export type gqlMutationAddProfilePetArgs = {
  input: gqlProfilePetInput;
  profileId: Scalars['Float'];
};


export type gqlMutationAddProfilePropArgs = {
  profileId: Scalars['Float'];
  propId: Scalars['Float'];
};


export type gqlMutationAddProfileSkillArgs = {
  profileId: Scalars['Float'];
  skillIds: ReadonlyArray<Scalars['Float']>;
};


export type gqlMutationAddProfileSportsEquipmentArgs = {
  profileId: Scalars['Float'];
  sportEquipmentId: Scalars['Float'];
};


export type gqlMutationAddProfileSubmissionArgs = {
  input: gqlCreateProfileSubmissionInput;
  syncIndexing?: InputMaybe<Scalars['Boolean']>;
};


export type gqlMutationAddProfileSubmissionOrGetArgs = {
  input: gqlCreateProfileSubmissionOrGetInput;
};


export type gqlMutationAddProfileToAuditionGroupArgs = {
  input: gqlAddProfileToAuditionGroupInput;
};


export type gqlMutationAddProfileToAuditionGroupAddInArgs = {
  input: gqlAddProfileToAuditionGroupAddInInput;
};


export type gqlMutationAddProfileToCastingListArgs = {
  input: gqlAddProfileToCastingListInput;
};


export type gqlMutationAddProfileToProjectArgs = {
  input: gqlAddProfileToProjectInput;
};


export type gqlMutationAddProfileToScheduleTimeSlotArgs = {
  input: gqlAddProfileToTimeSlotInput;
};


export type gqlMutationAddProfileVehicleArgs = {
  input: gqlProfileVehicleInput;
  profileId: Scalars['Float'];
};


export type gqlMutationAddProfileWardrobeArgs = {
  profileId: Scalars['Float'];
  wardrobeId: Scalars['Float'];
};


export type gqlMutationAddProjectNoteArgs = {
  input: gqlProjectNoteInput;
};


export type gqlMutationAddRepresentativeListArgs = {
  input: gqlAddRepresentativeListInput;
};


export type gqlMutationAddScheduleBreakArgs = {
  endTime: Scalars['DateTime'];
  scheduleId: Scalars['Int'];
  startTime: Scalars['DateTime'];
};


export type gqlMutationAddSubmissionQuickArgs = {
  input: gqlSubmissionQuickAddInput;
};


export type gqlMutationAddSubmissionSnapshotArgs = {
  snapshotId: Scalars['Int'];
  startOffset?: InputMaybe<Scalars['String']>;
  submissionsId: Scalars['BigInt'];
};


export type gqlMutationAddSubmissionToPresentationGroupArgs = {
  groupId: Scalars['Int'];
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  searchInput?: InputMaybe<gqlSubmissionSearchInput>;
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
  tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlMutationAddSubmissionsToAuditionGroupArgs = {
  createdBy?: InputMaybe<Scalars['Int']>;
  groupId: Scalars['Int'];
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  searchInput?: InputMaybe<gqlSubmissionSearchInput>;
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
  tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlMutationAddSubmissionsToAuditionTalentPoolArgs = {
  createdBy?: InputMaybe<Scalars['Int']>;
  sessionId: Scalars['Int'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlMutationAddSubmissionsToExistingSessionArgs = {
  sessionId: Scalars['Int'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlMutationAddSubmissionsToSlotArgs = {
  input: gqlAddSubmissionsToTimeframeInput;
};


export type gqlMutationAddSubmittedProfilesToCastingListsArgs = {
  input: gqlAddProfileSubmissionsToCastingListInput;
};


export type gqlMutationAddTalentToMediaRequestArgs = {
  id: Scalars['ID'];
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  searchInput?: InputMaybe<gqlWfSubmissionSearchInput>;
  submissionIds: ReadonlyArray<Scalars['Int']>;
  tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlMutationAddTalentToPresentationFolderArgs = {
  presentationFolderId: Scalars['Int'];
  profileId: ReadonlyArray<Scalars['Int']>;
};


export type gqlMutationAddTalentToQuestionRequestArgs = {
  id: Scalars['ID'];
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  searchInput?: InputMaybe<gqlWfSubmissionSearchInput>;
  submissionIds: ReadonlyArray<Scalars['Int']>;
  tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlMutationAddTimeframeToScheduleArgs = {
  input: gqlAddTimeframeInput;
};


export type gqlMutationApprovePcProjectArgs = {
  input: gqlApprovePcProjectInput;
};


export type gqlMutationArchiveMediaArgs = {
  input: gqlArchiveMediaInput;
};


export type gqlMutationArchiveMediaAssetArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  artistId?: InputMaybe<Scalars['Float']>;
  mediaId?: InputMaybe<Scalars['Float']>;
  mediaIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlMutationArchiveMediaAssetV2Args = {
  accountId?: InputMaybe<Scalars['Float']>;
  artistId?: InputMaybe<Scalars['Float']>;
  mediaIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlMutationArchiveNotificationsArgs = {
  ids: ReadonlyArray<Scalars['String']>;
};


export type gqlMutationArchiveProjectsArgs = {
  projectIds: ReadonlyArray<Scalars['Float']>;
};


export type gqlMutationArchiveRoleArgs = {
  roleIds: ReadonlyArray<Scalars['String']>;
};


export type gqlMutationAttachCreditVideoArgs = {
  attachToProfile?: InputMaybe<Scalars['Boolean']>;
  checkPayment?: InputMaybe<Scalars['Boolean']>;
  creditId: Scalars['Float'];
  mediaIds: ReadonlyArray<Scalars['Float']>;
};


export type gqlMutationAttachMediaToArtistArgs = {
  artistId?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
};


export type gqlMutationAttachMediaToAuditionGroupArgs = {
  inputModel: gqlAuditionGroupMediaInput;
};


export type gqlMutationAttachMediaToPresentationGroupArgs = {
  groupId: Scalars['Int'];
  mediaIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlMutationAttachMediaToProfileArgs = {
  input: gqlAttachMediaToProfileInput;
};


export type gqlMutationAttachMediaToProfileSkillArgs = {
  checkPayment?: InputMaybe<Scalars['Boolean']>;
  mediaId: Scalars['Int'];
  profileId: Scalars['Int'];
  profileSkillId: Scalars['BigInt'];
};


export type gqlMutationAttachMediasToProfileArgs = {
  mediaIds: ReadonlyArray<Scalars['Int']>;
  profileId: Scalars['Int'];
};


export type gqlMutationAttachMultipleMediaToProfileArgs = {
  input: gqlAttachMultipleMediaToProfileInput;
};


export type gqlMutationAttachOrderedMediaToAuditionGroupArgs = {
  inputModel: gqlAuditionGroupMediaInput;
};


export type gqlMutationAttachProjectSupportTicketNumberArgs = {
  input: gqlProjectAttachSupportTicketNumber;
};


export type gqlMutationBauLoginArgs = {
  input: gqlBauLoginInput;
};


export type gqlMutationBauLoginByLegacyDataArgs = {
  input: gqlBauLoginByLegacyDataInput;
};


export type gqlMutationCancelBenefitsArgs = {
  input: gqlCancelBenefitsInput;
};


export type gqlMutationCancelRequestArgs = {
  id: Scalars['ID'];
  input: gqlCancelRequestInput;
};


export type gqlMutationCancelScheduleArgs = {
  input: gqlCancelScheduleInput;
};


export type gqlMutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
};


export type gqlMutationChangeAuditionSessionTalentRoleNameArgs = {
  input: gqlChangeAuditionSessionTalentRoleNameInput;
};


export type gqlMutationChangeRoleOrderArgs = {
  moveBehind?: InputMaybe<Scalars['Boolean']>;
  positionalRoleId: Scalars['Int'];
  projectId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type gqlMutationChangeSubmissionOrderInAuditionGroupArgs = {
  input: gqlChangeAuditionGroupTalentOrderInput;
};


export type gqlMutationCheckEmailOtpArgs = {
  otp: Scalars['String'];
  verificationId: Scalars['String'];
};


export type gqlMutationCheckOtpCodeArgs = {
  otpCode: Scalars['String'];
  verificationId: Scalars['String'];
};


export type gqlMutationClaimEnterpriseAccountArgs = {
  input: gqlBauAccountInput;
};


export type gqlMutationCloneMediaArgs = {
  id: Scalars['Int'];
  input: gqlMediaTransformationInput;
};


export type gqlMutationCloseDivisionArgs = {
  divisionId: Scalars['Float'];
};


export type gqlMutationCompleteArtistMediaCreationArgs = {
  input: gqlCompleteArtistMediaCreationInput;
};


export type gqlMutationContactSubTalentArgs = {
  input: gqlContactSubTalentInput;
};


export type gqlMutationCopyAllProfileToCastingListArgs = {
  input: gqlCopyAllProfileToCastingListInput;
};


export type gqlMutationCopyAuditionGroupsToAuditionSessionArgs = {
  auditionGroups: ReadonlyArray<Scalars['Int']>;
  auditionSession: Scalars['Int'];
};


export type gqlMutationCopyPresentationArgs = {
  input: gqlCopyPresentationInput;
};


export type gqlMutationCopyProfileDataArgs = {
  fromProfileId: Scalars['Float'];
  toProfileId: Scalars['Float'];
};


export type gqlMutationCopyProfileFromExistingArgs = {
  invitationToken?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  profileId: Scalars['Float'];
};


export type gqlMutationCopyProfileToCastingListArgs = {
  input: gqlCopyProfileToCastingListInput;
};


export type gqlMutationCopyProfileUnderSameArtistArgs = {
  fromProfileId: Scalars['Float'];
  toProfileId: Scalars['Float'];
};


export type gqlMutationCopySubmissionToRoleArgs = {
  roleId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
  submissionStatusCode: gqlSubmissionStatusCodeEnum;
  tileSchemaValueCode: Scalars['String'];
};


export type gqlMutationCreateAccountArgs = {
  audience?: InputMaybe<Scalars['String']>;
  input: gqlAccountCreateInput;
  isEmailVerificationRequired?: InputMaybe<Scalars['Boolean']>;
};


export type gqlMutationCreateAccountWithGoogleArgs = {
  input: gqlCreateAccountWithGoogleInput;
};


export type gqlMutationCreateAgencyCallListReportArgs = {
  input: gqlAgencyCallListReportCreateInput;
};


export type gqlMutationCreateAllTalentSubmissionReportArgs = {
  input: gqlAllTalentSubmissionReportCreateInput;
};


export type gqlMutationCreateArtistCreditsArgs = {
  credit: gqlArtistCreditsInput;
};


export type gqlMutationCreateArtistMediaArgs = {
  input: gqlCreateArtistMediaInput;
};


export type gqlMutationCreateAskQuestionRequestArgs = {
  input: gqlAskQuestionRequestInput;
};


export type gqlMutationCreateAuditionPresentationArgs = {
  input: gqlAuditionPresentationInput;
};


export type gqlMutationCreateAuthContextByAccountIdArgs = {
  accountId: Scalars['Int'];
  organizationId?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<gqlMicroServices>;
};


export type gqlMutationCreateBookingCallListReportArgs = {
  input: gqlProjectReportCreateInput;
};


export type gqlMutationCreateChatMessageArgs = {
  messageInput: gqlChatMessageCreateInput;
};


export type gqlMutationCreateChatTopicFromSubRequestArgs = {
  subRequestId: Scalars['String'];
};


export type gqlMutationCreateCollaboratorAccountArgs = {
  input: gqlCollaboratorAccountCreateInput;
};


export type gqlMutationCreateCollaboratorInviteArgs = {
  input: gqlCollaboratorInviteCreateInput;
};


export type gqlMutationCreateCompedSubscriptionArgs = {
  input: gqlCreateCompedSubscriptionInput;
};


export type gqlMutationCreateCouponRedemptionArgs = {
  input: gqlCreateCouponRedemptionInput;
};


export type gqlMutationCreateCreditArgs = {
  credit: gqlCreditInput;
};


export type gqlMutationCreateEndUserAccountArgs = {
  input: gqlEndUserAccountInput;
};


export type gqlMutationCreateFormArgs = {
  description: Scalars['String'];
  formSchema: Scalars['JSON'];
  title: Scalars['String'];
};


export type gqlMutationCreateFormResponseReportArgs = {
  formId: Scalars['Float'];
  mediaRequestId: Scalars['String'];
  presentationKey?: InputMaybe<Scalars['String']>;
  sessionName?: InputMaybe<Scalars['String']>;
};


export type gqlMutationCreateInternalAccountArgs = {
  input: gqlInternalAccountCreateInput;
};


export type gqlMutationCreateListArgs = {
  name: Scalars['String'];
  projectId: Scalars['Int'];
};


export type gqlMutationCreateMediaRequestArgs = {
  input: gqlMediaRequestInput;
};


export type gqlMutationCreateOrganizationArgs = {
  input: gqlCreateOrganizationInput;
};


export type gqlMutationCreatePcProjectArgs = {
  input: gqlCreatePcProjectInput;
};


export type gqlMutationCreatePcRoleArgs = {
  input: gqlRoleCreateInput;
};


export type gqlMutationCreatePresentationFolderArgs = {
  input: gqlPresentationFolderInput;
};


export type gqlMutationCreatePresentationGroupArgs = {
  folderId: Scalars['Int'];
};


export type gqlMutationCreatePresentationLogReportArgs = {
  input: gqlPresentationReportCreateInput;
};


export type gqlMutationCreatePresentationLogReportPublicArgs = {
  input: gqlPresentationReportCreatePublicInput;
};


export type gqlMutationCreatePresentationPickReportArgs = {
  input: gqlPresentationReportCreateInput;
};


export type gqlMutationCreatePresentationSizeSheetsReportArgs = {
  input: gqlPresentationReportCreateInput;
};


export type gqlMutationCreatePresentationSizeSheetsReportPublicArgs = {
  input: gqlPresentationReportCreatePublicInput;
};


export type gqlMutationCreateProfileArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  input: gqlProfileCreateInput;
};


export type gqlMutationCreateProjectArgs = {
  input: gqlCreateProjectInput;
};


export type gqlMutationCreateProjectOverviewCdReportArgs = {
  input: gqlProjectReportCdCreateInput;
};


export type gqlMutationCreateProjectOverviewReportArgs = {
  input: gqlProjectReportCreateInput;
};


export type gqlMutationCreateProjectSeriesArgs = {
  input: gqlCreateProjectSeriesInput;
};


export type gqlMutationCreateProjectSubmissionReportArgs = {
  input: gqlProjectSubmissionCreateInput;
};


export type gqlMutationCreateRepPackagePresentationArgs = {
  name: Scalars['String'];
};


export type gqlMutationCreateRequestArgs = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  sendTo: ReadonlyArray<gqlRequestSendTo>;
};


export type gqlMutationCreateRoleArgs = {
  input: gqlRoleCreateInput;
};


export type gqlMutationCreateRosterInvitationRequestArgs = {
  input: gqlRosterInvitationRequestInput;
};


export type gqlMutationCreateSavedSearchArgs = {
  input: gqlCreateSavedSearchInput;
};


export type gqlMutationCreateScheduleArgs = {
  input: gqlCreateScheduleInput;
};


export type gqlMutationCreateScheduleReportArgs = {
  input: gqlScheduleReportCreateInput;
};


export type gqlMutationCreateScheduleSizeSheetsReportArgs = {
  input: gqlScheduleSizeSheetsReportCreateInput;
};


export type gqlMutationCreateSessionAndAddSubmissionsArgs = {
  sessionName: Scalars['String'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlMutationCreateShareSelectPresentationArgs = {
  input: gqlShareSelectPresentationInput;
};


export type gqlMutationCreateShareTalentPresentationArgs = {
  input: gqlShareTalentPresentationInput;
};


export type gqlMutationCreateSubscriptionArgs = {
  input: gqlCreateSubscriptionInput;
};


export type gqlMutationCreateTalentScoutHistoryArgs = {
  input: gqlCreateTalentScoutHistoryInput;
};


export type gqlMutationCreateTalentSubmissionReportArgs = {
  input: gqlTalentSubmissionReportCreateInput;
};


export type gqlMutationCustomizeTalentInFolderArgs = {
  input: gqlCustomizeTalentInFolderInput;
};


export type gqlMutationDeleteAccountArgs = {
  accountId: Scalars['Float'];
};


export type gqlMutationDeleteAllSubmissionsArgs = {
  projectId: Scalars['Float'];
  roleId: Scalars['Float'];
};


export type gqlMutationDeleteAuditionSessionArgs = {
  id: Scalars['Int'];
};


export type gqlMutationDeleteBlockedKeywordArgs = {
  keyword: Scalars['String'];
};


export type gqlMutationDeleteCommentToPresentationSubmissionPublicArgs = {
  commentId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};


export type gqlMutationDeleteCreditArgs = {
  creditId: Scalars['Float'];
};


export type gqlMutationDeleteCreditMediaArgs = {
  creditId: Scalars['Float'];
  mediaId: Scalars['Float'];
};


export type gqlMutationDeleteListArgs = {
  listId: ReadonlyArray<Scalars['ID']>;
};


export type gqlMutationDeleteMediaArgs = {
  input: gqlDeleteMediaInput;
};


export type gqlMutationDeleteMediaAssetArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  mediaId: Scalars['Float'];
};


export type gqlMutationDeleteMediaFromAuditionGroupArgs = {
  groupId: Scalars['Int'];
  mediaIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlMutationDeleteMediaFromPresentationGroupArgs = {
  groupId: Scalars['Int'];
  mediaIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlMutationDeleteMediaV2Args = {
  input: gqlDeleteMediaV2Input;
};


export type gqlMutationDeleteNoteArgs = {
  noteId: Scalars['Int'];
};


export type gqlMutationDeleteOrganzationAddressArgs = {
  addressId: Scalars['Int'];
  organizationId: Scalars['Int'];
};


export type gqlMutationDeletePickArgs = {
  pickId: Scalars['Int'];
};


export type gqlMutationDeletePickFromPresentationProfilePublicArgs = {
  input: gqlDeletePresentationProfilePickPublickInput;
};


export type gqlMutationDeletePickFromPresentationSubmissionPublicArgs = {
  guid: Scalars['String'];
  pickId: Scalars['String'];
  submissionId: Scalars['BigInt'];
};


export type gqlMutationDeletePickFromSubmissionArgs = {
  pickId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};


export type gqlMutationDeletePresentationArgs = {
  presentationId: Scalars['Int'];
};


export type gqlMutationDeletePresentationFolderArgs = {
  presentationFolderId: Scalars['Int'];
};


export type gqlMutationDeletePresentationFolderTalentArgs = {
  presentationFolderId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type gqlMutationDeletePresentationGroupArgs = {
  groupId: Scalars['Int'];
};


export type gqlMutationDeleteProfileArgs = {
  profileId: Scalars['Int'];
};


export type gqlMutationDeleteProfileConflictArgs = {
  profileConflictId: Scalars['Float'];
};


export type gqlMutationDeleteProfileListArgs = {
  listId: Scalars['Float'];
};


export type gqlMutationDeleteProfileListEntitiesArgs = {
  input: gqlAddOrDeleteListEntitiesInput;
};


export type gqlMutationDeleteProfileMediaArgs = {
  mediaId: Scalars['Float'];
  profileId: Scalars['Float'];
};


export type gqlMutationDeleteProfilePetArgs = {
  profileId: Scalars['Float'];
  profilePetId: Scalars['Float'];
};


export type gqlMutationDeleteProfilePropArgs = {
  profileId: Scalars['Float'];
  profilePropId: Scalars['Float'];
};


export type gqlMutationDeleteProfileSkillArgs = {
  profileSkillId: Scalars['BigInt'];
};


export type gqlMutationDeleteProfileSportsEquipmentArgs = {
  profileId: Scalars['Float'];
  profileSportEquipmentId: Scalars['Float'];
};


export type gqlMutationDeleteProfileVehicleArgs = {
  profileId: Scalars['Float'];
  profileVehicleId: Scalars['Float'];
};


export type gqlMutationDeleteProfileWardrobeArgs = {
  profileId: Scalars['Float'];
  profileWardrobeId: Scalars['Float'];
};


export type gqlMutationDeleteProjectSeriesArgs = {
  input: gqlDeleteProjectSeriesInput;
};


export type gqlMutationDeleteRepresentativeListArgs = {
  listId: Scalars['Float'];
};


export type gqlMutationDeleteRequestArgs = {
  id: Scalars['ID'];
};


export type gqlMutationDeleteRoleArgs = {
  roleId: Scalars['Float'];
};


export type gqlMutationDeleteSavedSearchArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlMutationDeleteSubmissionArgs = {
  id: Scalars['BigInt'];
};


export type gqlMutationDeleteSubmissionCommentArgs = {
  commentId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};


export type gqlMutationDeleteSubmissionFromPresentationGroupArgs = {
  groupId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};


export type gqlMutationDeleteSubmissionFromSlotArgs = {
  input: gqlDeleteSubmissionFromSlotInput;
};


export type gqlMutationDeleteSubmissionsBulkArgs = {
  ids: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlMutationDeleteSubmissionsFromAuditionGroupArgs = {
  groupId: Scalars['Int'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlMutationDeleteSubmissionsFromSlotArgs = {
  input: gqlDeleteSubmissionsFromSlotInput;
};


export type gqlMutationDeleteSubscriptionArgs = {
  transaction: Scalars['String'];
};


export type gqlMutationDeleteTimeframeFromScheduleArgs = {
  input: gqlDeleteTimeframeInput;
};


export type gqlMutationEditCastingListArgs = {
  listId: Scalars['ID'];
  name: Scalars['String'];
};


export type gqlMutationEditCastingListProfilesArgs = {
  input: gqlEditProfileInput;
};


export type gqlMutationEditMediaArgs = {
  id: Scalars['Int'];
  input: gqlMediaTransformationInput;
};


export type gqlMutationEditMediaThumbnailArgs = {
  id: Scalars['Int'];
  input: gqlMediaTransformationInput;
};


export type gqlMutationEditProjectSeriesArgs = {
  input: gqlEditProjectSeriesInput;
};


export type gqlMutationEnrollBenefitsArgs = {
  input: gqlEnrollBenefitsInput;
};


export type gqlMutationExpireCompedSubscriptionArgs = {
  input: gqlExpireCompedSubscriptionInput;
};


export type gqlMutationExpireSlugArgs = {
  expiredReason?: InputMaybe<gqlCustomSlugExpiredReasonEnum>;
  expiredReasonCode?: InputMaybe<Scalars['String']>;
  profileId: Scalars['Int'];
};


export type gqlMutationFinalizeMediaUploadArgs = {
  input: ReadonlyArray<gqlMediaMetadataInput>;
};


export type gqlMutationFinalizeMediaUploadV2Args = {
  input: gqlFinalizeMediaUploadInput;
};


export type gqlMutationForwardRequestsArgs = {
  ccManagerEmail?: InputMaybe<Scalars['Boolean']>;
  ids: ReadonlyArray<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};


export type gqlMutationGetMediaUploadSignatureArgs = {
  params: Scalars['JSONObject'];
};


export type gqlMutationGetOrCreateVirtualAuditionAttendeeArgs = {
  input: gqlGetOrCreateVirtualAuditionAttendeeInput;
};


export type gqlMutationGoogleLoginInternalArgs = {
  googleAuthToken: Scalars['String'];
};


export type gqlMutationGrantTalentAccessArgs = {
  note: Scalars['String'];
  organizationId: Scalars['Float'];
  profileId: Scalars['Float'];
};


export type gqlMutationHideProfileInRosterArgs = {
  id: Scalars['ID'];
};


export type gqlMutationHideProfileInRosterAndSearchArgs = {
  id: Scalars['ID'];
};


export type gqlMutationImportPresentationFolderFromAuditionGroupArgs = {
  auditionGroupIds: ReadonlyArray<Scalars['Int']>;
  presentationFolderId?: InputMaybe<Scalars['Int']>;
  presentationFolderName?: InputMaybe<Scalars['String']>;
  presentationId?: InputMaybe<Scalars['Int']>;
  presentationName?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
};


export type gqlMutationImportPresentationFromAuditionArgs = {
  auditionIds: ReadonlyArray<Scalars['Int']>;
  isLive: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  presentationId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};


export type gqlMutationInitMediaUploadArgs = {
  input: gqlMediaInitUploadInput;
};


export type gqlMutationInitPresentationMediaToDownloadPublicArgs = {
  input: gqlPresentationMediaDownloadInput;
};


export type gqlMutationInitiateMediaBulkDownloadArgs = {
  input: gqlInitiateMediaBulkDownloadInput;
};


export type gqlMutationInitiateMediaUploadV2Args = {
  input: gqlInitiateMediaUploadInput;
};


export type gqlMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type gqlMutationLoginDelegatedUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  service: gqlMicroServices;
};


export type gqlMutationLoginImpersonatedAccountArgs = {
  accountId: Scalars['Float'];
};


export type gqlMutationLoginWithAuthProviderArgs = {
  authCode: Scalars['String'];
  authProvider: gqlExternalAuthenticationProvider;
  isMobileAppClient?: InputMaybe<Scalars['Boolean']>;
};


export type gqlMutationLogoutArgs = {
  refreshToken: Scalars['String'];
};


export type gqlMutationMarkAllAsReadArgs = {
  topicKey: Scalars['String'];
};


export type gqlMutationMarkChatMessageAsReadFromIdArgs = {
  messageId: Scalars['String'];
};


export type gqlMutationMarkNotificationsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
  viewed: Scalars['Boolean'];
};


export type gqlMutationMergeOrganizationArgs = {
  fromOrganizationId: Scalars['Float'];
  toOrganizationId: Scalars['Float'];
};


export type gqlMutationMergeUserAccountsArgs = {
  fromAccountId: Scalars['Float'];
  toAccountId: Scalars['Float'];
};


export type gqlMutationMoveAllProfileToCastingListArgs = {
  input: gqlMoveAllProfileToCastingListInput;
};


export type gqlMutationMoveProfileToCastingListArgs = {
  input: gqlMoveProfileToCastingListInput;
};


export type gqlMutationMoveSubmissionsFromSlotBulkArgs = {
  input: gqlMoveSubmissionsFromSlotsInput;
};


export type gqlMutationMoveSubmissionsToRoleArgs = {
  roleId: Scalars['Int'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
  submissionStatusCode?: InputMaybe<gqlSubmissionStatusCodeEnum>;
  tileSchemaValueCode: Scalars['String'];
};


export type gqlMutationPrepareAccountForOnboardingArgs = {
  accountId: Scalars['Float'];
  paypalCode?: InputMaybe<Scalars['String']>;
};


export type gqlMutationPublishRolesArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
  roleIds: ReadonlyArray<Scalars['ID']>;
};


export type gqlMutationPurchaseMediaArgs = {
  input: gqlPurchaseMediaInput;
};


export type gqlMutationPurchaseSubscriptionArgs = {
  input: gqlPurchaseSubscriptionInput;
};


export type gqlMutationReAddUserToOrganizationArgs = {
  accountId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type gqlMutationReassignProjectToOrganizationArgs = {
  fromOrganizationId: Scalars['Float'];
  toOrganizationId: Scalars['Float'];
};


export type gqlMutationReassociateChildOrgtoParentArgs = {
  childOrgId: Scalars['Float'];
  parentOrgId: Scalars['Float'];
};


export type gqlMutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type gqlMutationRegisterAccountDeviceArgs = {
  input: gqlRegisterDeviceInput;
};


export type gqlMutationRejectProjectModificationArgs = {
  id: Scalars['ID'];
};


export type gqlMutationRemoveBillingInfoArgs = {
  input: gqlRemoveBillingInfoInput;
};


export type gqlMutationRemoveOrganizationLogoArgs = {
  organizationId: Scalars['Float'];
};


export type gqlMutationRemoveProfileBookoutArgs = {
  input: gqlProfileBookOutInput;
};


export type gqlMutationRemoveProfileFromCastingListArgs = {
  input: gqlRemoveProfileFromCastingListInput;
};


export type gqlMutationRemoveProfileResumeArgs = {
  profileId: Scalars['Int'];
};


export type gqlMutationRemoveUserFromOrganizationArgs = {
  accountId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type gqlMutationRenewSubscriptionArgs = {
  input: gqlRenewSubscriptionInput;
};


export type gqlMutationReopenDivisionArgs = {
  divisionId: Scalars['Float'];
};


export type gqlMutationReorderListArgs = {
  moveBehind: Scalars['Boolean'];
  movingId: Scalars['ID'];
  refId: Scalars['ID'];
};


export type gqlMutationReorderProfileArgs = {
  moveBehind: Scalars['Boolean'];
  movingId: Scalars['ID'];
  refId: Scalars['ID'];
};


export type gqlMutationReplyToAskQuestionRequestArgs = {
  input: gqlAskQuestionResponseInput;
};


export type gqlMutationReplyToMediaRequestArgs = {
  input: gqlMediaResponseInput;
};


export type gqlMutationReplyToScheduleInviteRequestArgs = {
  input: gqlScheduleInviteResponseInput;
};


export type gqlMutationRequestMediaChangesArgs = {
  input: gqlMediaChangeInput;
};


export type gqlMutationRequestSubmissionsForRolesArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
  roleIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlMutationResendCreatePasswordLinkArgs = {
  token: Scalars['String'];
};


export type gqlMutationResendEmailVerificationArgs = {
  audience: Scalars['String'];
  token: Scalars['String'];
};


export type gqlMutationResentVerificationEmailArgs = {
  audience: Scalars['String'];
};


export type gqlMutationResetEmailArgs = {
  audience: Scalars['String'];
  updateInfo: gqlUpdateAccountInput;
};


export type gqlMutationResetPasswordArgs = {
  audience?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};


export type gqlMutationResetPasswordBauB21Args = {
  legacyInstance?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
};


export type gqlMutationResetPasswordByLegacyLoginArgs = {
  legacyInstance?: InputMaybe<Scalars['String']>;
  legacyLogin: Scalars['String'];
};


export type gqlMutationResetSessionObserverUrlArgs = {
  input: gqlResetSessionObserverUrlInput;
};


export type gqlMutationRestoreArchivedMediaArgs = {
  input: gqlRestoreArchivedMediaInput;
};


export type gqlMutationRestoreDeletedMediaArgs = {
  input: gqlRestoreDeletedMediaInput;
};


export type gqlMutationRestoreNotificationsArgs = {
  ids: ReadonlyArray<Scalars['String']>;
};


export type gqlMutationRestoreProjectsArgs = {
  projectIds: ReadonlyArray<Scalars['Float']>;
};


export type gqlMutationRestoreRoleArgs = {
  roleIds: ReadonlyArray<Scalars['String']>;
};


export type gqlMutationResumeSubscriptionArgs = {
  input: gqlResumeSubscriptionInput;
};


export type gqlMutationRevokeCollaboratorAccessFromOrgArgs = {
  input: gqlCollaboratorRevokeAccessFromOrgInput;
};


export type gqlMutationRevokeCollaboratorAccessFromProjectArgs = {
  input: gqlCollaboratorRevokeAccessFromProjectInput;
};


export type gqlMutationSaveDraftAskQuestionRequestArgs = {
  input: gqlSaveDraftAskQuestionRequestArgs;
};


export type gqlMutationSaveDraftMediaRequestArgs = {
  input: gqlSaveDraftMediaRequestArgs;
};


export type gqlMutationSaveFormResponseArgs = {
  formId: Scalars['Int'];
  requestId: Scalars['String'];
  responseSchema: Scalars['JSON'];
};


export type gqlMutationSelectProfilesFromRosterArgs = {
  profileIds: ReadonlyArray<Scalars['Int']>;
  roleId: Scalars['Int'];
};


export type gqlMutationSendDirectEmailToPickedPackageTalentArgs = {
  message: Scalars['String'];
  packageFolderId: Scalars['Int'];
  subject?: InputMaybe<Scalars['String']>;
  talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};


export type gqlMutationSendDirectEmailToPresentationFolderTalentArgs = {
  message: Scalars['String'];
  presentationFolderId: Scalars['Int'];
  subject?: InputMaybe<Scalars['String']>;
  talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};


export type gqlMutationSendDirectMessageToTalentsArgs = {
  input: gqlSendDirectMessageToTalentsInput;
};


export type gqlMutationSendPresentationLinkEmailArgs = {
  input: gqlPresentationLinkEmailInput;
};


export type gqlMutationSendProjectUpdateMessageToRepsArgs = {
  input: gqlProjectNotificationToRepsInput;
};


export type gqlMutationSendScheduleInviteRequestArgs = {
  input: gqlScheduleInviteRequestInput;
};


export type gqlMutationSendVerificationCodeArgs = {
  language?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};


export type gqlMutationSendVoiceOverMediaRequestArgs = {
  input: gqlSendVoiceOverMediaRequestInput;
};


export type gqlMutationSetAccountProjectListFilterPreferencesArgs = {
  preferences: gqlAccountProjectFiltersInput;
};


export type gqlMutationSetAccountSchedulePreferencesArgs = {
  preferences: gqlAccountSchedulePreferencesInput;
};


export type gqlMutationSetCastingListProfileNoteArgs = {
  input: gqlAddProfileNoteInput;
};


export type gqlMutationSetProfileActiveArgs = {
  id: Scalars['ID'];
};


export type gqlMutationSetProfileResumeArgs = {
  mediaId: Scalars['Int'];
  profileId: Scalars['Int'];
};


export type gqlMutationSetProjectAsViewedArgs = {
  id: Scalars['ID'];
};


export type gqlMutationSetProjectStatusArgs = {
  projectId: Scalars['ID'];
  projectStatusCodeToUpdate: Scalars['String'];
};


export type gqlMutationSetRoleRepStatusArgs = {
  newStatus: gqlRepRoleStatusEnum;
  roleId: Scalars['Float'];
};


export type gqlMutationSetSheduleInviteRequestStatusArgs = {
  input: gqlScheduleInviteSetStatusInput;
};


export type gqlMutationSetSubscriptionPaymentMethodArgs = {
  input: gqlSetSubscriptionPaymentMethodInput;
};


export type gqlMutationShiftScheduleTimeframeArgs = {
  input: gqlShiftTimeframeInput;
};


export type gqlMutationSubmitPcProjectForApprovalArgs = {
  input: gqlUpdatePcProjectInput;
};


export type gqlMutationSubmitSelectedSubmissionForRoleArgs = {
  mediaRequestStatuses?: InputMaybe<ReadonlyArray<gqlMediaRequestStatus>>;
  roleId: Scalars['Int'];
};


export type gqlMutationSubmitSelectedSubmissionForRoleV1Args = {
  mediaRequestStatuses?: InputMaybe<ReadonlyArray<gqlMediaRequestStatus>>;
  roleId: Scalars['Int'];
};


export type gqlMutationSwitchAuthContextArgs = {
  app?: InputMaybe<Scalars['String']>;
  artistId?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  refreshToken: Scalars['String'];
  role?: InputMaybe<gqlSystemRoleCode>;
};


export type gqlMutationUnHideProfileInRosterAndSearchArgs = {
  id: Scalars['ID'];
};


export type gqlMutationUnlockMediaArgs = {
  input: gqlUnlockMediaInput;
};


export type gqlMutationUpdateAccountArgs = {
  accountId: Scalars['Float'];
  input: gqlUpdateAccountInput;
};


export type gqlMutationUpdateAccountBillingInfoArgs = {
  threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type gqlMutationUpdateAccountNameArgs = {
  updateInfo: gqlUpdateAccountInput;
};


export type gqlMutationUpdateAccountPhoneArgs = {
  updateInfo: gqlUpdateAccountInput;
};


export type gqlMutationUpdateAccountRosterInvitationTokenArgs = {
  updateInfo: gqlUpdateAccountInput;
};


export type gqlMutationUpdateAddressArgs = {
  addToOrganization?: InputMaybe<Scalars['Boolean']>;
  addressId: Scalars['Float'];
  input: gqlAddressInput;
};


export type gqlMutationUpdateAgentOrganizationArgs = {
  input: gqlAgentOrganizationInput;
};


export type gqlMutationUpdateArtistArgs = {
  input: gqlArtistUpdateInput;
};


export type gqlMutationUpdateAskQuestionRequestArgs = {
  id: Scalars['ID'];
  input: gqlAskQuestionRequestInput;
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlMutationUpdateAuditionGroupMediaArgs = {
  inputModel: gqlAuditionGroupMediaInput;
};


export type gqlMutationUpdateAuditionGroupMediaOrderArgs = {
  input: gqlAuditionGroupMediaOrderUpdateInput;
};


export type gqlMutationUpdateAuditionGroupThumbnailArgs = {
  groupId: Scalars['Int'];
  mediaId: Scalars['Int'];
  startOffset?: InputMaybe<Scalars['String']>;
};


export type gqlMutationUpdateAuditionSessionArgs = {
  inputModel: gqlAuditionSessionUpdateInput;
};


export type gqlMutationUpdateAuditionSessionGroupArgs = {
  auditionGroupId: Scalars['Int'];
  inputModel: gqlAuditionGroupInput;
};


export type gqlMutationUpdateAuditionSessionGroupsBulkArgs = {
  auditionGroupIds: ReadonlyArray<Scalars['Int']>;
  inputModel: gqlAuditionGroupBulkUpdateInput;
};


export type gqlMutationUpdateBauAccountArgs = {
  input: gqlBauAccountInput;
};


export type gqlMutationUpdateBauEmailAddressArgs = {
  audience: Scalars['String'];
  email: Scalars['String'];
};


export type gqlMutationUpdateBillingInfoArgs = {
  input: gqlUpdateBillingInfoInput;
};


export type gqlMutationUpdateCastingDirectorOrganizationArgs = {
  input: gqlOrganizationInput;
};


export type gqlMutationUpdateCreditArgs = {
  credit: gqlCreditUpdateInput;
};


export type gqlMutationUpdateCreditOrderArgs = {
  input: ReadonlyArray<gqlCreditOrderInput>;
};


export type gqlMutationUpdateEmailWithTokenArgs = {
  token: Scalars['String'];
};


export type gqlMutationUpdateFormArgs = {
  description?: InputMaybe<Scalars['String']>;
  formId: Scalars['Int'];
  formSchema?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
};


export type gqlMutationUpdateLegacyProfileArgs = {
  input: gqlBauProfileUpdateInput;
};


export type gqlMutationUpdateMediaAssetArgs = {
  input: gqlUpdateMediaInput;
};


export type gqlMutationUpdateMediaRequestArgs = {
  id: Scalars['ID'];
  input: gqlMediaRequestInput;
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlMutationUpdateMediaRequestMessageForRepresentativesArgs = {
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
};


export type gqlMutationUpdateMediaRequestTalentInstructionsArgs = {
  id: Scalars['ID'];
  instructions: Scalars['String'];
};


export type gqlMutationUpdateNoteArgs = {
  input: gqlUpdateNoteInput;
};


export type gqlMutationUpdateNotificationEntityTypeSubscriptionArgs = {
  input: gqlNotificationSubscriptionInput;
};


export type gqlMutationUpdateNotificationNotificationTypeSubscriptionArgs = {
  input: gqlNotificationSubscriptionInput;
};


export type gqlMutationUpdateOrganizationArgs = {
  input: gqlUpdateOrganizationInput;
};


export type gqlMutationUpdateOrganizationAccountArgs = {
  accountId: Scalars['Float'];
  accountsRoots: ReadonlyArray<gqlOrganizationAccountInput>;
};


export type gqlMutationUpdateOrganizationFeaturesArgs = {
  features: ReadonlyArray<gqlOrganizationFeatureInput>;
};


export type gqlMutationUpdatePcAccountArgs = {
  input: gqlPcAccountUpdateInput;
};


export type gqlMutationUpdatePcOrganizationsArgs = {
  input: gqlBauPcAccountUpdateInput;
};


export type gqlMutationUpdatePcProjectArgs = {
  input: gqlUpdatePcProjectInput;
};


export type gqlMutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type gqlMutationUpdatePasswordWithTokenArgs = {
  isEmailNotificationRequired: Scalars['Boolean'];
  languageLocaleId?: InputMaybe<Scalars['Int']>;
  newPassword: Scalars['String'];
  timeZoneCode?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type gqlMutationUpdatePresentationArgs = {
  input: gqlPresentationUpdateInput;
};


export type gqlMutationUpdatePresentationFolderArgs = {
  input: gqlPresentationFolderUpdateInput;
};


export type gqlMutationUpdatePresentationFolderOrderArgs = {
  behindFolderId?: InputMaybe<Scalars['Int']>;
  folderId: Scalars['Int'];
};


export type gqlMutationUpdatePresentationGroupArgs = {
  input: gqlPresentationGroupUpdateInput;
};


export type gqlMutationUpdatePresentationGroupMediaOrderArgs = {
  groupMediaId: Scalars['Int'];
  moveAfterGroupMediaId?: InputMaybe<Scalars['Int']>;
};


export type gqlMutationUpdatePresentationGroupOrderArgs = {
  groupId: Scalars['Int'];
  moveAfterGroupMediaId?: InputMaybe<Scalars['Int']>;
};


export type gqlMutationUpdatePresentationGroupThumbnailArgs = {
  groupId: Scalars['Int'];
  mediaId: Scalars['Int'];
  startOffset?: InputMaybe<Scalars['String']>;
};


export type gqlMutationUpdateProfileArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  input: gqlProfileUpdateInput;
};


export type gqlMutationUpdateProfileArtistArgs = {
  artistId: Scalars['Int'];
  profileAccessToken: Scalars['String'];
};


export type gqlMutationUpdateProfileBookoutArgs = {
  input: gqlProfileBookOutInput;
};


export type gqlMutationUpdateProfileConflictArgs = {
  input: gqlUpdateProfileConflictInput;
};


export type gqlMutationUpdateProfileCreditTypeSortOrderArgs = {
  firstProfileCreditTypeId: Scalars['Float'];
  secondProfileCreditTypeId: Scalars['Float'];
};


export type gqlMutationUpdateProfileListNameArgs = {
  input: gqlUpdateListNameInput;
};


export type gqlMutationUpdateProfileMediaArgs = {
  profileMedia: gqlProfileMediaInput;
};


export type gqlMutationUpdateProfileMediaListArgs = {
  profileMediaList: ReadonlyArray<gqlProfileMediaInput>;
};


export type gqlMutationUpdateProfileMediaVisibilityArgs = {
  mediaId: Scalars['Int'];
  profileIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlMutationUpdateProfileMoveToArtistArgs = {
  input: gqlUpdateProfileMoveToArtistInput;
};


export type gqlMutationUpdateProfilePetArgs = {
  input: gqlProfilePetInput;
  profileId: Scalars['Float'];
  profilePetId: Scalars['Float'];
};


export type gqlMutationUpdateProfilePropArgs = {
  profileId: Scalars['Float'];
  profilePropId: Scalars['Float'];
  propId: Scalars['Float'];
};


export type gqlMutationUpdateProfileSkillArgs = {
  input: gqlProfileSkillInput;
};


export type gqlMutationUpdateProfileSportsEquipmentArgs = {
  profileId: Scalars['Float'];
  profileSportEquipmentId: Scalars['Float'];
  sportEquipmentId: Scalars['Float'];
};


export type gqlMutationUpdateProfileVehicleArgs = {
  input: gqlUpdateProfileVehicleInput;
  profileId: Scalars['Float'];
  profileVehicleId: Scalars['Float'];
};


export type gqlMutationUpdateProfileVisibilitiesArgs = {
  profileId: Scalars['Float'];
  profileVisibilityCodes: ReadonlyArray<Scalars['String']>;
};


export type gqlMutationUpdateProfileWardrobeArgs = {
  profileId: Scalars['Float'];
  profileWardrobeId: Scalars['Float'];
  wardrobeId: Scalars['Float'];
};


export type gqlMutationUpdateProjectArgs = {
  input: gqlUpdateProjectInput;
};


export type gqlMutationUpdateRepresentativeListArgs = {
  input: gqlUpdateRepresentativeListInput;
};


export type gqlMutationUpdateRepresentativeListNameArgs = {
  input: gqlUpdateListNameInput;
};


export type gqlMutationUpdateRequestDueDateArgs = {
  id: Scalars['ID'];
  input: gqlUpdateDueDateInput;
};


export type gqlMutationUpdateRoleArgs = {
  input: gqlRoleUpdateInput;
};


export type gqlMutationUpdateRoleOpenCallArgs = {
  isOpenCall: Scalars['Boolean'];
  roleId: Scalars['Int'];
};


export type gqlMutationUpdateRoleStatusArgs = {
  roleIds: ReadonlyArray<Scalars['Int']>;
  roleStatusCode: gqlRoleStatusCodes;
};


export type gqlMutationUpdateRolesWardrobeBulkArgs = {
  input: ReadonlyArray<gqlRoleUpdateWardrobeInput>;
};


export type gqlMutationUpdateSavedSearchArgs = {
  input: gqlUpdateSavedSearchInput;
};


export type gqlMutationUpdateScheduleBreakArgs = {
  breakId: Scalars['Int'];
  endTime: Scalars['DateTime'];
  scheduleId: Scalars['Int'];
  startTime: Scalars['DateTime'];
};


export type gqlMutationUpdateScheduleWithAddressArgs = {
  addressInput?: InputMaybe<gqlAddressInput>;
  scheduleInput: gqlUpdateScheduleInput;
};


export type gqlMutationUpdateSubmissionArgs = {
  input: gqlSubmissionUpdateInput;
  syncIndexing?: InputMaybe<Scalars['Boolean']>;
};


export type gqlMutationUpdateSubmissionSelectionArgs = {
  input: gqlSubmissionUpdateSelectionInput;
};


export type gqlMutationUpdateSubmissionStatusArgs = {
  input: gqlUpdateSubmissionStatusInput;
};


export type gqlMutationUpdateSubmissionsInAuditionTalentPoolArgs = {
  sessionId: Scalars['Int'];
  submissionSnapshotIds: ReadonlyArray<gqlAuditionSubmissionSnapshotInput>;
};


export type gqlMutationUpdateSubmissionsStatusArgs = {
  statusCode: Scalars['String'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlMutationUpdateVerificationEmailArgs = {
  audience: Scalars['String'];
  email: Scalars['String'];
};


export type gqlMutationUpgradePcOrgToCdArgs = {
  organizationId: Scalars['Float'];
};


export type gqlMutationVerifyBauAccountArgs = {
  token: Scalars['String'];
};


export type gqlMutationWidenPublicationRolesArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
  roleIds: ReadonlyArray<Scalars['Int']>;
  rolePublicationSettings: ReadonlyArray<gqlRolePublicationSettingInput>;
};

export type gqlNeck = {
  readonly __typename?: 'Neck';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

export type gqlNotFoundError = gqlIError & {
  readonly __typename?: 'NotFoundError';
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

/** Note object type */
export type gqlNote = {
  readonly __typename?: 'Note';
  /** Get Account object for the Note */
  readonly account: gqlAccount;
  /** Account id who created the note */
  readonly accountId: Scalars['Int'];
  /** Comment */
  readonly comment: Scalars['String'];
  /** Created Date */
  readonly created: Scalars['DateTime'];
  readonly entityId: Scalars['BigInt'];
  readonly entityType: gqlEntityType;
  /** Note Id */
  readonly id: Scalars['Int'];
  readonly noteModifier?: Maybe<gqlNoteModifier>;
  readonly noteType?: Maybe<gqlNoteType>;
  /** Project status at the moment when note was added */
  readonly projectStatus?: Maybe<gqlProjectStatus>;
};

/** Note Modifier object */
export type gqlNoteModifier = {
  readonly __typename?: 'NoteModifier';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  readonly entityType: gqlEntityType;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlNoteModifierEnum {
  BAD_ARTIST = 'BAD_ARTIST',
  BAD_PROFILE = 'BAD_PROFILE',
  GOOD_ARTIST = 'GOOD_ARTIST',
  GOOD_PROFILE = 'GOOD_PROFILE',
  VERY_BAD_ARTIST = 'VERY_BAD_ARTIST',
  VERY_BAD_PROFILE = 'VERY_BAD_PROFILE',
  VERY_GOOD_ARTIST = 'VERY_GOOD_ARTIST',
  VERY_GOOD_PROFILE = 'VERY_GOOD_PROFILE'
}

/** Paginated result with [Note] objects */
export type gqlNotePaginatedResult = {
  readonly __typename?: 'NotePaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of Note objects */
  readonly objects: ReadonlyArray<gqlNote>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Note Type object */
export type gqlNoteType = {
  readonly __typename?: 'NoteType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlNoteTypeEnum {
  ADD_IN = 'ADD_IN',
  ADMIN_NOTE = 'ADMIN_NOTE',
  AGENT_NOTE = 'AGENT_NOTE',
  CD_TALENT_NOTE = 'CD_TALENT_NOTE',
  FASTCAPTURE_NOTE = 'FASTCAPTURE_NOTE',
  PERMANENT_NOTE = 'PERMANENT_NOTE',
  PERMANENT_SUBMISSION_NOTE = 'PERMANENT_SUBMISSION_NOTE',
  SUBMISSION_NOTE = 'SUBMISSION_NOTE'
}

export type gqlNotification = {
  readonly __typename?: 'Notification';
  readonly archivedAt?: Maybe<Scalars['DateTime']>;
  readonly context?: Maybe<gqlNotificationContext>;
  readonly from?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly message: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly timestamp: Scalars['DateTime'];
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toArtistId?: Maybe<Scalars['Int']>;
  readonly toProfileId: Scalars['Int'];
  readonly type: Scalars['String'];
  readonly viewed?: Maybe<Scalars['Boolean']>;
};

export type gqlNotificationContext = {
  readonly __typename?: 'NotificationContext';
  readonly auditionDateUTC?: Maybe<Scalars['DateTime']>;
  readonly requestId?: Maybe<Scalars['ID']>;
  readonly requestType?: Maybe<gqlRequestType>;
};

export type gqlNotificationEntityDetails = gqlPaidType | gqlProjectType | gqlRoleTarget | gqlTalentUnion;

/** Notification Entity Subscription object type */
export type gqlNotificationEntitySubscription = {
  readonly __typename?: 'NotificationEntitySubscription';
  /** Subscription Creator Id */
  readonly accountId: Scalars['Int'];
  /** Artist Id */
  readonly artistId: Scalars['Int'];
  /** Created Date */
  readonly created: Scalars['DateTime'];
  readonly entity?: Maybe<gqlNotificationEntityDetails>;
  /** Entity Id */
  readonly entityId?: Maybe<Scalars['BigInt']>;
  readonly entityType?: Maybe<gqlEntityType>;
  /** Entity Type Id */
  readonly entityTypeId?: Maybe<Scalars['Int']>;
  /** Notification Id */
  readonly id?: Maybe<Scalars['ID']>;
  /** Notification Id */
  readonly notificationId?: Maybe<Scalars['Int']>;
  readonly notificationNature?: Maybe<gqlNotificationNature>;
  readonly notificationType?: Maybe<gqlNotificationType>;
  /** Organization Id */
  readonly organizationId?: Maybe<Scalars['Int']>;
  /** @deprecated Use "entity" field instead */
  readonly projectType?: Maybe<gqlProjectType>;
  /** @deprecated Use "entity" field instead */
  readonly rolePaidType?: Maybe<gqlPaidType>;
  /** @deprecated Use "entity" field instead */
  readonly roleTarget?: Maybe<gqlRoleTarget>;
};

/** Notification Nature object */
export type gqlNotificationNature = {
  readonly __typename?: 'NotificationNature';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlNotificationNatureEnum {
  ALERT = 'ALERT',
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  TEXT = 'TEXT'
}

/** Order By options, default set to timestamp DESC */
export type gqlNotificationOrderByInput = {
  /** Timestamp Sort field */
  readonly timestamp: gqlSortOrder;
};

export type gqlNotificationPagedList = {
  readonly __typename?: 'NotificationPagedList';
  readonly data: ReadonlyArray<gqlNotification>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Add/Update Notification Subscription Input Type */
export type gqlNotificationSubscriptionInput = {
  /** Account Id that notification subscription will be assigned to */
  readonly accountId?: InputMaybe<Scalars['Int']>;
  /** Artist Id that notification subscription will be assigned to */
  readonly artistId?: InputMaybe<Scalars['Int']>;
  /** Entity Type Subscription Array */
  readonly entitySubscription?: InputMaybe<ReadonlyArray<gqlEntitySubscriptionInput>>;
  /** Notification Subscription ID which should be updated */
  readonly notificationId?: InputMaybe<Scalars['ID']>;
  readonly notificationPhone?: InputMaybe<Scalars['ID']>;
  /** Notification Type Subscription Array */
  readonly notificationTypeSubscription?: InputMaybe<ReadonlyArray<gqlNotificationTypeSubscriptionInput>>;
  /** Organization Id that notification subscription will be assigned to */
  readonly organizationId?: InputMaybe<Scalars['Int']>;
};

/** Notification Type object */
export type gqlNotificationType = {
  readonly __typename?: 'NotificationType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlNotificationTypeEnum {
  CHAT = 'CHAT',
  MARKETING = 'MARKETING',
  PROJECT = 'PROJECT',
  PROJECT_APPROVAL = 'PROJECT_APPROVAL',
  REQUEST = 'REQUEST'
}

/** Notification Notitifcation Type Subscription object type */
export type gqlNotificationTypeNotificationSubscription = {
  readonly __typename?: 'NotificationTypeNotificationSubscription';
  /** Subscription Creator Id */
  readonly accountId: Scalars['Int'];
  /** Artist Id */
  readonly artistId: Scalars['Int'];
  /** Created Date */
  readonly created: Scalars['DateTime'];
  readonly entity?: Maybe<gqlNotificationEntityDetails>;
  readonly entityType?: Maybe<gqlEntityType>;
  /** Notification Id */
  readonly notificationId?: Maybe<Scalars['Int']>;
  readonly notificationNature?: Maybe<gqlNotificationNature>;
  /** Notification Nature Id */
  readonly notificationNatureId?: Maybe<Scalars['Int']>;
  readonly notificationType?: Maybe<gqlNotificationType>;
  /** Notification Type Id */
  readonly notificationTypeId?: Maybe<Scalars['Int']>;
  /** Organization Id */
  readonly organizationId?: Maybe<Scalars['Int']>;
  /** @deprecated Use "entity" field instead */
  readonly projectType?: Maybe<gqlProjectType>;
  /** @deprecated Use "entity" field instead */
  readonly rolePaidType?: Maybe<gqlPaidType>;
  /** @deprecated Use "entity" field instead */
  readonly roleTarget?: Maybe<gqlRoleTarget>;
};

/** Input type for notification type subscription */
export type gqlNotificationTypeSubscriptionInput = {
  /** Notification Nature CODE */
  readonly notificationNatureCode: Scalars['String'];
  /** Notification Type CODE */
  readonly notificationTypeCode: Scalars['String'];
  /** Subscription status */
  readonly subscriptionStatus: Scalars['Boolean'];
};

/** Account status object */
export type gqlOnboardingStatus = {
  readonly __typename?: 'OnboardingStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for ordered items like Working Location or Ethnic Appearance */
export type gqlOrderedItemInput = {
  readonly id: Scalars['ID'];
  readonly order: Scalars['Int'];
};

/** Organization object */
export type gqlOrganization = {
  readonly __typename?: 'Organization';
  /** Abbreviation */
  readonly abbreviation?: Maybe<Scalars['String']>;
  readonly accountCount?: Maybe<Scalars['Float']>;
  /** Organization accounts */
  readonly accounts: ReadonlyArray<gqlAccount>;
  /** Linked organizations */
  readonly addresses?: Maybe<ReadonlyArray<gqlAddress>>;
  readonly contacts?: Maybe<ReadonlyArray<gqlContact>>;
  readonly country: ReadonlyArray<gqlCountry>;
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Creator ID */
  readonly creatorId?: Maybe<Scalars['Int']>;
  /** Diversity disclaimer */
  readonly diversityDisclaimer?: Maybe<Scalars['String']>;
  readonly divisions: ReadonlyArray<gqlOrganization>;
  readonly dmas?: Maybe<ReadonlyArray<gqlDma>>;
  readonly features?: Maybe<ReadonlyArray<gqlFeature>>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  /** Tells whether it is organization or division */
  readonly isOrganization?: Maybe<Scalars['Boolean']>;
  /** Last updated/modified datetime */
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  readonly linkedAccount: Scalars['Float'];
  readonly linkedAccountDivision: ReadonlyArray<gqlOrganization>;
  readonly linkedDivisionId?: Maybe<Scalars['Float']>;
  readonly linkedDivisions: ReadonlyArray<gqlOrganization>;
  /** Linked organizations */
  readonly linkedOrganizations: ReadonlyArray<gqlOrganization>;
  readonly logo?: Maybe<gqlMedia>;
  /** Logo media ID */
  readonly logoMediaId?: Maybe<Scalars['Int']>;
  /** Organization name */
  readonly name?: Maybe<Scalars['String']>;
  /** Note */
  readonly note?: Maybe<Scalars['String']>;
  /** Parent organization ID */
  readonly organizationParentId?: Maybe<Scalars['Int']>;
  readonly organizationPhone?: Maybe<ReadonlyArray<gqlPhone>>;
  readonly organizationStatus: ReadonlyArray<gqlOrganizationStatus>;
  /** Parent Organization name */
  readonly parentName?: Maybe<Scalars['String']>;
  readonly parentOrganization?: Maybe<gqlOrganization>;
  /** Organization phone list */
  readonly phone: ReadonlyArray<Scalars['String']>;
  readonly profileCount?: Maybe<Scalars['Float']>;
  /** Profile list ID */
  readonly profileListId?: Maybe<Scalars['Int']>;
  /** Organization profiles */
  readonly profiles: ReadonlyArray<gqlProfile>;
  /** Project casting assistant */
  readonly projectCastingAssistant?: Maybe<Scalars['String']>;
  /** Project casting associate */
  readonly projectCastingAssociate?: Maybe<Scalars['String']>;
  /** Project casting director */
  readonly projectCastingDirector?: Maybe<Scalars['String']>;
  readonly representationAgeCategory?: Maybe<ReadonlyArray<gqlRepresentationAgeCategory>>;
  readonly representationCategory?: Maybe<ReadonlyArray<gqlRepresentationCategory>>;
  readonly representationCategoryList?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly representationProfile: ReadonlyArray<gqlProfile>;
  readonly representationType: ReadonlyArray<gqlRepresentationType>;
  /** Representation type ID */
  readonly representationTypeId?: Maybe<Scalars['Int']>;
  readonly representationTypes: ReadonlyArray<gqlRepresentationType>;
  /** Schedule address note */
  readonly scheduleAddressNote?: Maybe<Scalars['String']>;
  readonly searchDivisions: ReadonlyArray<gqlOrganization>;
  /** Organization status */
  readonly status?: Maybe<Scalars['Float']>;
  readonly systemRoleCode?: Maybe<Scalars['String']>;
  /** System role ID */
  readonly sytemRoleId?: Maybe<Scalars['Int']>;
  readonly tags: ReadonlyArray<gqlTag>;
  /** Tile scheme ID */
  readonly tileSchemeId?: Maybe<Scalars['Int']>;
  /** Website url */
  readonly websiteUrl?: Maybe<Scalars['String']>;
};


/** Organization object */
export type gqlOrganizationAccountsArgs = {
  statusIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

/** Object that represent realtion between organization and account */
export type gqlOrganizationAccount = {
  readonly __typename?: 'OrganizationAccount';
  readonly accountId: Scalars['Float'];
  readonly isAdmin: Scalars['Boolean'];
  readonly organizationId: Scalars['Float'];
  readonly organizationStatus: Scalars['Float'];
};

export type gqlOrganizationAccountInput = {
  readonly enabled: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  readonly organizationId: Scalars['Int'];
};

export type gqlOrganizationDictionaryItem = {
  readonly __typename?: 'OrganizationDictionaryItem';
  /** Organization abbreviation */
  readonly abbreviation?: Maybe<Scalars['String']>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  /** Organization name */
  readonly name?: Maybe<Scalars['String']>;
};

export type gqlOrganizationDictionaryItemPagedList = {
  readonly __typename?: 'OrganizationDictionaryItemPagedList';
  readonly data: ReadonlyArray<gqlOrganizationDictionaryItem>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Input type for Organization Feature */
export type gqlOrganizationFeatureInput = {
  readonly enabled: Scalars['Boolean'];
  readonly featureId: Scalars['Int'];
};

export type gqlOrganizationFilter = {
  readonly __typename?: 'OrganizationFilter';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlOrganizationInfo = {
  readonly __typename?: 'OrganizationInfo';
  readonly category: ReadonlyArray<gqlRepresentationCategory>;
  readonly categoryName: ReadonlyArray<Scalars['String']>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

/** Creates or update organization contact or address on given input values */
export type gqlOrganizationInput = {
  /** Address Id */
  readonly addressId?: InputMaybe<Scalars['Float']>;
  /** City */
  readonly city: Scalars['String'];
  /** Company Address Line 1 */
  readonly companyAddressLine1: Scalars['String'];
  /** Company Address Line 2 */
  readonly companyAddressLine2?: InputMaybe<Scalars['String']>;
  /** Company Phone Number */
  readonly companyPhoneNumber?: InputMaybe<Scalars['String']>;
  /** Contact Id */
  readonly contactId?: InputMaybe<Scalars['Float']>;
  /** Country Id */
  readonly countryId: Scalars['Float'];
  /** is update org info */
  readonly isUpdateOrgInfo: Scalars['Boolean'];
  /** Organization Id */
  readonly organizationId: Scalars['Float'];
  /** Organization Parent Id */
  readonly parentOrganizationId?: InputMaybe<Scalars['Float']>;
  /** Primary Contact Email */
  readonly primaryContactEmail: Scalars['String'];
  /** Primary Contact Name */
  readonly primaryContactName: Scalars['String'];
  /** Primary Contact Phone Number */
  readonly primaryContactPhoneNumber: Scalars['String'];
  /** State Id */
  readonly stateId?: InputMaybe<Scalars['Float']>;
  /** Zip */
  readonly zip: Scalars['String'];
};

export type gqlOrganizationItem = {
  readonly __typename?: 'OrganizationItem';
  /** abbreviation */
  readonly abbreviation?: Maybe<Scalars['String']>;
  /** Account Count */
  readonly accountCount?: Maybe<Scalars['Int']>;
  readonly accounts?: Maybe<ReadonlyArray<gqlAccountItem>>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly divisions?: Maybe<ReadonlyArray<gqlOrganizationItem>>;
  readonly dmas?: Maybe<ReadonlyArray<gqlDmaItem>>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  readonly logo?: Maybe<gqlMediaItem>;
  /** Organization name */
  readonly name?: Maybe<Scalars['String']>;
  /** Parent organization ID */
  readonly organizationParentId?: Maybe<Scalars['Int']>;
  readonly organizationStatus: gqlOrganizationStatus;
  /** Profile Count */
  readonly profileCount?: Maybe<Scalars['Int']>;
  readonly representationCategory?: Maybe<ReadonlyArray<gqlRepresentationCategory>>;
  readonly representationType?: Maybe<gqlRepresentationType>;
  readonly updated?: Maybe<Scalars['DateTime']>;
};

export type gqlOrganizationItemPagedList = {
  readonly __typename?: 'OrganizationItemPagedList';
  readonly data: ReadonlyArray<gqlOrganizationItem>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type gqlOrganizationLogo = {
  readonly __typename?: 'OrganizationLogo';
  readonly fileKey: Scalars['String'];
  readonly id: Scalars['Float'];
};

export type gqlOrganizationProjectFilterInput = {
  /** Project name filter */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Project status codes */
  readonly projectStatusCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Project type codes */
  readonly projectTypeCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Project series filter */
  readonly seriesIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type gqlOrganizationProjectGroup = {
  readonly __typename?: 'OrganizationProjectGroup';
  /** Organization project group details */
  readonly details: gqlOrganizationProjectGroupDetails;
  readonly itemCount: Scalars['Int'];
};

export type gqlOrganizationProjectGroupDetails = gqlProjectSeries | gqlProjectStatus | gqlProjectType;

/** Paginated result with [OrganizationProjectGroup] objects */
export type gqlOrganizationProjectGroupPaginatedResult = {
  readonly __typename?: 'OrganizationProjectGroupPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of OrganizationProjectGroup objects */
  readonly objects: ReadonlyArray<gqlOrganizationProjectGroup>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Organization search input */
export type gqlOrganizationSearchInput = {
  /** Organization abbreviation */
  readonly abbreviation?: InputMaybe<Scalars['String']>;
  /** List of DMA Id */
  readonly dmas?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Defines whether search should use 'add' or 'or' clause with input parameters */
  readonly multiMatch?: InputMaybe<Scalars['Boolean']>;
  /** Organization name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  readonly organizationId?: InputMaybe<Scalars['Int']>;
  /** Organization parent Id */
  readonly organizationParentId?: InputMaybe<Scalars['Int']>;
  /** Organization parent Ids */
  readonly organizationParentIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Organization Status Ids */
  readonly organizationStatusIds?: InputMaybe<Scalars['Int']>;
  readonly parent?: InputMaybe<Scalars['Boolean']>;
  /** List of Representation Age Category Id */
  readonly representationAgeCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** List of Representation Category Id */
  readonly representationCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** List of Representation Category Id */
  readonly representationCategoryList?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Organization Type Id */
  readonly representationTypeId?: InputMaybe<Scalars['Int']>;
  /** List of Representation Type Id */
  readonly representationTypeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** System Role Id for account/user */
  readonly systemRoleId?: InputMaybe<Scalars['Int']>;
};

/** Organization search result */
export type gqlOrganizationSearchResult = {
  readonly __typename?: 'OrganizationSearchResult';
  /** List of organization */
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  /** Total records count */
  readonly totalCount: Scalars['Int'];
};

/** Organization Status */
export type gqlOrganizationStatus = {
  readonly __typename?: 'OrganizationStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlOrganizationStatusEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  JOINED = 'JOINED',
  UNCLAIMED = 'UNCLAIMED'
}

/** Input for get orgs by representation type + piece of/full name of org */
export type gqlOrganizationsByRepTypeAndNameInput = {
  /**
   * Org name or part of the Org name. Could be null in case if first N(page cfg)
   * records need to be presented in order to sort by name asc
   */
  readonly orgName?: InputMaybe<Scalars['String']>;
  /** Codes of sought org representation type */
  readonly orgRepTypeCodes: ReadonlyArray<Scalars['String']>;
  /** Pagination config */
  readonly page: gqlPageInput;
  /** Optional: ids of orgs used for search by org rep type+org name */
  readonly targetOrganizationIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

/** Object that represent organization data retreived by org representation type and partial/full name match */
export type gqlOrganizationsByRepTypeAndNameResult = {
  readonly __typename?: 'OrganizationsByRepTypeAndNameResult';
  readonly orgId: Scalars['Float'];
  /** Organization object */
  readonly organization: gqlOrganization;
};

/** Paginated result with [OrganizationsByRepTypeAndNameResult] objects */
export type gqlOrganizationsByRepTypeAndNameResultPaginatedResult = {
  readonly __typename?: 'OrganizationsByRepTypeAndNameResultPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of OrganizationsByRepTypeAndNameResult objects */
  readonly objects: ReadonlyArray<gqlOrganizationsByRepTypeAndNameResult>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Object that represent Reps divisions/agencies and count of their submissions to particular role */
export type gqlOrganizationsByRoleSubmissionsAndName = {
  readonly __typename?: 'OrganizationsByRoleSubmissionsAndName';
  readonly orgId: Scalars['Int'];
  /** Organization object */
  readonly organization: gqlOrganization;
  readonly submissionsCount: Scalars['BigInt'];
};

/** Paginated result with [OrganizationsByRoleSubmissionsAndName] objects */
export type gqlOrganizationsByRoleSubmissionsAndNamePaginatedResult = {
  readonly __typename?: 'OrganizationsByRoleSubmissionsAndNamePaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of OrganizationsByRoleSubmissionsAndName objects */
  readonly objects: ReadonlyArray<gqlOrganizationsByRoleSubmissionsAndName>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlPcAccountUpdateInput = {
  readonly accountId: Scalars['ID'];
  readonly companyName?: InputMaybe<Scalars['String']>;
  readonly facebookLink?: InputMaybe<Scalars['String']>;
  readonly imdbLink?: InputMaybe<Scalars['String']>;
  readonly instagramLink?: InputMaybe<Scalars['String']>;
  readonly institutionName?: InputMaybe<Scalars['String']>;
  readonly portfolio?: InputMaybe<Scalars['String']>;
  readonly professionalBio?: InputMaybe<Scalars['String']>;
  readonly twitterLink?: InputMaybe<Scalars['String']>;
  readonly vimeoLink?: InputMaybe<Scalars['String']>;
  readonly youtubeLink?: InputMaybe<Scalars['String']>;
};

/** Determined location Address Input for creating PC project */
export type gqlPcProjectDeterminedAddressInput = {
  /** City */
  readonly city: Scalars['String'];
  /** Country CODE */
  readonly countryCode: Scalars['String'];
  /** Location notes */
  readonly locationNotes?: InputMaybe<Scalars['String']>;
};

/** Specific location Address Input for creating IPC project */
export type gqlPcProjectSpecificAddressInput = {
  /** Address Line 1 */
  readonly addressLine1: Scalars['String'];
  /** Address Line 2 */
  readonly addressLine2?: InputMaybe<Scalars['String']>;
  /** City */
  readonly city: Scalars['String'];
  /** Country CODE */
  readonly countryCode: Scalars['String'];
  /** Location notes */
  readonly locationNotes?: InputMaybe<Scalars['String']>;
  /** ZIP/Postal Code */
  readonly postalCode: Scalars['String'];
  /** State / Province Id */
  readonly stateId: Scalars['Int'];
};

/** Page Input object */
export type gqlPageInput = {
  /** Page number */
  readonly pageNumber: Scalars['Int'];
  /** Page size */
  readonly pageSize: Scalars['Int'];
};

/** Paginated array of Custom URL Slug with relevant blocked keyword */
export type gqlPaginatedProhibitedCustomSlug = {
  readonly __typename?: 'PaginatedProhibitedCustomSlug';
  readonly lastKey?: Maybe<Scalars['String']>;
  readonly objects: ReadonlyArray<gqlProhibitedCustomSlug>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type gqlPaginatedSearchArtistMediaResult = {
  readonly __typename?: 'PaginatedSearchArtistMediaResult';
  readonly artist: gqlArtist;
  readonly objects: ReadonlyArray<gqlMedia>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** PaidType object */
export type gqlPaidType = {
  readonly __typename?: 'PaidType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlPaidTypeEnum {
  NO = 'NO',
  PAY_IS_DEFERRED = 'PAY_IS_DEFERRED',
  UNLISTED = 'UNLISTED',
  YES = 'YES'
}

/** Measurement object */
export type gqlPants = {
  readonly __typename?: 'Pants';
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Gender */
  readonly gender: gqlBasicSetting;
  /** Primary ID */
  readonly id: Scalars['Int'];
  /** Size for AU */
  readonly sizeAU: Scalars['String'];
  /** Size for EU */
  readonly sizeEU: Scalars['String'];
  /** Numeric size */
  readonly sizeNumeric: Scalars['Int'];
  /** Size for UK */
  readonly sizeUK: Scalars['String'];
  /** Size for US */
  readonly sizeUS: Scalars['String'];
};

/** Partial BAU account details */
export type gqlPartialBauAccountDetails = {
  readonly __typename?: 'PartialBauAccountDetails';
  readonly accountStatusCode: Scalars['String'];
  readonly partialEmail: Scalars['String'];
  readonly partialPhones: ReadonlyArray<Scalars['String']>;
  readonly projectNames?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly roles: ReadonlyArray<gqlSystemRole>;
  readonly stageName?: Maybe<Scalars['String']>;
};

export type gqlPartialBauAccountDetailsInput = {
  readonly legacyInstance?: InputMaybe<Scalars['String']>;
  readonly legacyLogin: Scalars['String'];
  readonly migratedAccountID?: InputMaybe<Scalars['Float']>;
};

export enum gqlPayPreferencesEnum {
  NO = 'NO',
  PAY_IS_DEFERRED = 'PAY_IS_DEFERRED',
  UNLISTED = 'UNLISTED',
  YES = 'YES'
}

/** Payment Details */
export type gqlPaymentDetails = {
  readonly __typename?: 'PaymentDetails';
  readonly cardType?: Maybe<Scalars['String']>;
  readonly lastFour?: Maybe<Scalars['String']>;
  readonly paymentType?: Maybe<Scalars['String']>;
};

export enum gqlPaypalLocation {
  AU = 'AU',
  EU = 'EU'
}

export type gqlPermissionsModel = {
  readonly __typename?: 'PermissionsModel';
  readonly action: Scalars['String'];
  readonly subject: Scalars['String'];
};

/** Pet object */
export type gqlPet = {
  readonly __typename?: 'Pet';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlPet>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlPet>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlPetEnum {
  BIRD = 'BIRD',
  CAT = 'CAT',
  CHICKEN = 'CHICKEN',
  DOG = 'DOG',
  DOG_AKITA = 'DOG_AKITA',
  DOG_BEAGLE = 'DOG_BEAGLE',
  DOG_BORDER_COLLIE = 'DOG_BORDER_COLLIE',
  DOG_BOXER = 'DOG_BOXER',
  DOG_BULLDOG = 'DOG_BULLDOG',
  DOG_CAVALIER_KING_CHARLES_SPANIEL = 'DOG_CAVALIER_KING_CHARLES_SPANIEL',
  DOG_CHIHUAHUA = 'DOG_CHIHUAHUA',
  DOG_DACHSHUND = 'DOG_DACHSHUND',
  DOG_ENGLISH_COCKER_SPANIEL = 'DOG_ENGLISH_COCKER_SPANIEL',
  DOG_ENGLISH_SETTER = 'DOG_ENGLISH_SETTER',
  DOG_FRENCH_BULLDOG = 'DOG_FRENCH_BULLDOG',
  DOG_GERMAN_SHEPHERD = 'DOG_GERMAN_SHEPHERD',
  DOG_GERMAN_SPITZ = 'DOG_GERMAN_SPITZ',
  DOG_GOLDEN_RETRIEVER = 'DOG_GOLDEN_RETRIEVER',
  DOG_JACK_RUSSELL_TERRIER = 'DOG_JACK_RUSSELL_TERRIER',
  DOG_LABRADOR = 'DOG_LABRADOR',
  DOG_MALTESE = 'DOG_MALTESE',
  DOG_MINIATURE_SCHNAUZER = 'DOG_MINIATURE_SCHNAUZER',
  DOG_OTHER = 'DOG_OTHER',
  DOG_PIT_BULL = 'DOG_PIT_BULL',
  DOG_POMERANIAN = 'DOG_POMERANIAN',
  DOG_POODLE = 'DOG_POODLE',
  DOG_PUG = 'DOG_PUG',
  DOG_ROTTWEILER = 'DOG_ROTTWEILER',
  DOG_SHIH_TZU = 'DOG_SHIH_TZU',
  DOG_SIBERIAN_HUSKY = 'DOG_SIBERIAN_HUSKY',
  DOG_UNKNOWN_MIXED = 'DOG_UNKNOWN_MIXED',
  DOG_YORKSHIRE_TERRIER = 'DOG_YORKSHIRE_TERRIER',
  DUCK = 'DUCK',
  FISH = 'FISH',
  GOAT = 'GOAT',
  GOOSE = 'GOOSE',
  HAMSTER = 'HAMSTER',
  HORSE = 'HORSE',
  IGUANA = 'IGUANA',
  LIZARD = 'LIZARD',
  MOUSE = 'MOUSE',
  PIG = 'PIG',
  RABBIT = 'RABBIT',
  REPTILE = 'REPTILE',
  RODENTS = 'RODENTS',
  SNAKE = 'SNAKE',
  SPIDER = 'SPIDER',
  TURTLE = 'TURTLE'
}

/** PetSize object */
export type gqlPetSize = {
  readonly __typename?: 'PetSize';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlPhone = {
  readonly __typename?: 'Phone';
  readonly entityId?: Maybe<Scalars['BigInt']>;
  readonly entityTypeId?: Maybe<Scalars['Int']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly phoneTypeId?: Maybe<Scalars['Int']>;
};

/** Piercing object */
export type gqlPiercing = {
  readonly __typename?: 'Piercing';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for search by piercing attributes */
export type gqlPiercingInput = {
  /** Piercing id list */
  readonly piercingId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Piercing description */
  readonly piercingNote?: InputMaybe<Scalars['String']>;
};

export type gqlPlanFeatureModel = {
  readonly __typename?: 'PlanFeatureModel';
  readonly code: Scalars['String'];
  readonly credit: Scalars['Int'];
  readonly currency?: Maybe<gqlCurrency>;
  readonly isPermanent: Scalars['Boolean'];
  readonly itemCode: Scalars['String'];
  readonly itemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly planId: Scalars['ID'];
  readonly price?: Maybe<Scalars['Float']>;
};

/** Subscription Plan */
export type gqlPlanModel = {
  readonly __typename?: 'PlanModel';
  readonly billingCode?: Maybe<Scalars['String']>;
  readonly billingProviderId?: Maybe<Scalars['ID']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly currency?: Maybe<gqlCurrency>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly isBasePlan?: Maybe<Scalars['Boolean']>;
  readonly isComped?: Maybe<Scalars['Boolean']>;
  readonly level?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly planCurrencyCode?: Maybe<Scalars['String']>;
  readonly planStatusId?: Maybe<Scalars['ID']>;
  readonly planTerm?: Maybe<gqlPlanTermModel>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly sortOrder?: Maybe<Scalars['Int']>;
  readonly updated?: Maybe<Scalars['DateTime']>;
};

export enum gqlPlanTermEnum {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  SEMIANNUAL = 'SEMIANNUAL'
}

export type gqlPlanTermModel = {
  readonly __typename?: 'PlanTermModel';
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type gqlPreCancelInput = {
  /** The URL to redirect the user to after the retention process is completed */
  readonly callbackUrl: Scalars['String'];
  /** The URL to redirect the user to after the retention process is canceled, default to callbackUrl */
  readonly cancelUrl?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the retention process was successful, default to callbackUrl */
  readonly saveUrl?: InputMaybe<Scalars['String']>;
  /** The ID of the subscription to be retained */
  readonly subscriptionId: Scalars['Int'];
};

/** Presentation */
export type gqlPresentation = {
  readonly __typename?: 'Presentation';
  /** Creation Date */
  readonly createdAt: Scalars['DateTime'];
  /** Account of creator */
  readonly createdBy?: Maybe<gqlAccount>;
  /** Creator Account ID */
  readonly createdById: Scalars['Int'];
  /** Number of Folders in Presentation */
  readonly foldersCount: Scalars['Int'];
  /** GUID */
  readonly guid: Scalars['ID'];
  /** ID of presentation */
  readonly id: Scalars['Int'];
  readonly isLive: Scalars['Boolean'];
  /** Account of updater */
  readonly linkExpired?: Maybe<Scalars['Boolean']>;
  /** Link expiration date */
  readonly linkExpiresAt?: Maybe<Scalars['DateTime']>;
  /** Name of presentation */
  readonly name: Scalars['String'];
  readonly organization: gqlOrganization;
  readonly passwordSet: Scalars['Boolean'];
  /** presentation type */
  readonly project?: Maybe<gqlProject>;
  /** Project ID of presentation */
  readonly projectId?: Maybe<Scalars['Int']>;
  readonly settings?: Maybe<gqlPresentationSettings>;
  /** presentation type */
  readonly type?: Maybe<gqlPresentationType>;
  /** Update Date */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** Account of updater */
  readonly updatedBy?: Maybe<gqlAccount>;
  /** Updated Account ID */
  readonly updatedById?: Maybe<Scalars['Int']>;
};

export type gqlPresentationFilterData = {
  readonly __typename?: 'PresentationFilterData';
  readonly commentersAccountIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly folders?: Maybe<ReadonlyArray<gqlPresentationFilterFolder>>;
  readonly picksUser?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly roles?: Maybe<ReadonlyArray<gqlPresentationFilterRole>>;
};

export type gqlPresentationFilterFolder = {
  readonly __typename?: 'PresentationFilterFolder';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type gqlPresentationFilterInput = {
  /** Users who made comments on presentation */
  readonly commentersAccountIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Users who made pick on presenatation */
  readonly pickUsers?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly presenetationFolderIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly presentationGuid?: InputMaybe<Scalars['String']>;
  readonly presentationId?: InputMaybe<Scalars['Int']>;
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly roleNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly stageName?: InputMaybe<Scalars['String']>;
};

export type gqlPresentationFilterRole = {
  readonly __typename?: 'PresentationFilterRole';
  readonly id?: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
};

/** Presentation Folder */
export type gqlPresentationFolder = {
  readonly __typename?: 'PresentationFolder';
  /** Gets audition session */
  readonly auditionSession?: Maybe<gqlAuditionSession>;
  /** Date when was created */
  readonly createdAt: Scalars['DateTime'];
  /** Groups Count */
  readonly groupCount: Scalars['Int'];
  /** ID of presentation folder */
  readonly id: Scalars['Int'];
  /** Name of presentation folder */
  readonly name: Scalars['String'];
  /** Order of appearance */
  readonly order: Scalars['Int'];
  /** ID of presentation */
  readonly presentationId: Scalars['Int'];
  /** Submissions Count */
  readonly submissionCount: Scalars['Int'];
  /** Talents Count */
  readonly talentCount: Scalars['Int'];
  /** Date when was updated */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Folder input type */
export type gqlPresentationFolderInput = {
  /** Name for presentation folder */
  readonly name: Scalars['String'];
  /** Presentation ID */
  readonly presentationId: Scalars['Int'];
};

export type gqlPresentationFolderPublic = {
  readonly __typename?: 'PresentationFolderPublic';
  /** Gets audition session */
  readonly auditionSession?: Maybe<gqlAuditionSession>;
  /** Date when was created */
  readonly createdAt: Scalars['DateTime'];
  /** Groups Count */
  readonly groupCount: Scalars['Int'];
  readonly groups: ReadonlyArray<gqlPresentationGroupPublic>;
  readonly groupsByFilter: ReadonlyArray<gqlPresentationGroupPublic>;
  /** ID of presentation folder */
  readonly id: Scalars['Int'];
  /** Name of presentation folder */
  readonly name: Scalars['String'];
  /** Order of appearance */
  readonly order: Scalars['Int'];
  readonly presentation: gqlPresentationPublic;
  /** ID of presentation */
  readonly presentationId: Scalars['Int'];
  /** Submissions Count */
  readonly submissionCount: Scalars['Int'];
  /** Talents Count */
  readonly talentCount: Scalars['Int'];
  /** Folder talents of the package presentation */
  readonly talents: ReadonlyArray<gqlPresentationTalentPublic>;
  readonly talentsWithPicksCount: Scalars['Int'];
  /** Date when was updated */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};


export type gqlPresentationFolderPublicGroupsArgs = {
  hasPicks?: InputMaybe<Scalars['Boolean']>;
};


export type gqlPresentationFolderPublicGroupsByFilterArgs = {
  input: gqlPresentationFilterInput;
};


export type gqlPresentationFolderPublicTalentsArgs = {
  pageInput?: InputMaybe<gqlPageInput>;
};

/** Presentation Folder Talent */
export type gqlPresentationFolderTalent = {
  readonly __typename?: 'PresentationFolderTalent';
  /** Total count of comments for the talent */
  readonly commentCount: Scalars['Int'];
  /** Retrieves cover for profile in the package folder. Based on customization and profile cover */
  readonly cover?: Maybe<gqlMedia>;
  /** ID of the presentation folder */
  readonly folderId: Scalars['Int'];
  /** ID of the presentation group */
  readonly groupId: Scalars['Int'];
  readonly id: Scalars['ID'];
  /** Total count of picks for the talent */
  readonly pickCount: Scalars['Int'];
  readonly pickedBy?: Maybe<ReadonlyArray<gqlProfilePick>>;
  readonly profile?: Maybe<gqlProfile>;
  /** Retrieves profile customizations in the package folder */
  readonly profileCustomization?: Maybe<gqlPresentationFolderTalentCustomization>;
};

export type gqlPresentationFolderTalentCustomization = {
  readonly __typename?: 'PresentationFolderTalentCustomization';
  readonly id: Scalars['Int'];
  readonly isOverscale?: Maybe<Scalars['Boolean']>;
  /** Retrieves profile's customized media */
  readonly media?: Maybe<ReadonlyArray<gqlPresentationFolderTalentMediaCustomization>>;
  readonly note?: Maybe<Scalars['String']>;
  readonly overscaleNote?: Maybe<Scalars['String']>;
};

export type gqlPresentationFolderTalentCustomizedMediaInput = {
  /** Is media primary? (Primary photo, video reel or audio reel) */
  readonly isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media Id */
  readonly mediaId: Scalars['ID'];
  /** Customize media file name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Media order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
};

export type gqlPresentationFolderTalentMediaCustomization = {
  readonly __typename?: 'PresentationFolderTalentMediaCustomization';
  readonly id: Scalars['ID'];
  readonly isPrimary: Scalars['Boolean'];
  readonly media: gqlMedia;
  readonly mediaId: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
};

/** Presentation Folder Talent Presence */
export type gqlPresentationFolderTalentPresence = {
  readonly __typename?: 'PresentationFolderTalentPresence';
  /** Presentation Folder Id */
  readonly folderId: Scalars['Float'];
  /** Presentation Folder Name */
  readonly folderName: Scalars['String'];
};

/** Presentation Folder Update input type */
export type gqlPresentationFolderUpdateInput = {
  /** Name for presentation folder */
  readonly name: Scalars['String'];
  /** Presentation Folder ID */
  readonly presentationFolderId: Scalars['Int'];
};

/** Presentation Group */
export type gqlPresentationGroup = {
  readonly __typename?: 'PresentationGroup';
  readonly cover?: Maybe<gqlMedia>;
  /** Date when created */
  readonly createdAt: Scalars['DateTime'];
  /** ID of presentation group */
  readonly id: Scalars['Int'];
  readonly media?: Maybe<ReadonlyArray<gqlPresentationGroupMedia>>;
  readonly mediaCount?: Maybe<ReadonlyArray<gqlMediaCount>>;
  /** Name of presentation group */
  readonly name?: Maybe<Scalars['String']>;
  /** Order of appearance */
  readonly order: Scalars['Int'];
  readonly presentation: gqlPresentation;
  readonly presentationFolder: gqlPresentationFolder;
  /** Stage names of added talents */
  readonly stageNames?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly submissionIds: ReadonlyArray<Scalars['BigInt']>;
  /** Count of added talents */
  readonly talentCount?: Maybe<Scalars['Int']>;
  /** Date when updated */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};


/** Presentation Group */
export type gqlPresentationGroupMediaArgs = {
  mediaType?: InputMaybe<Scalars['String']>;
};

/** Represent relation between Presentation Group and Media */
export type gqlPresentationGroupMedia = {
  readonly __typename?: 'PresentationGroupMedia';
  readonly id: Scalars['Int'];
  /** Media object */
  readonly media: gqlMedia;
  /** Id of media */
  readonly mediaId: Scalars['Int'];
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Order of appearance */
  readonly order: Scalars['Int'];
};

export type gqlPresentationGroupPublic = {
  readonly __typename?: 'PresentationGroupPublic';
  readonly cover?: Maybe<gqlMedia>;
  /** Date when created */
  readonly createdAt: Scalars['DateTime'];
  /** ID of presentation group */
  readonly id: Scalars['Int'];
  readonly media?: Maybe<ReadonlyArray<gqlPresentationGroupMedia>>;
  readonly mediaCount?: Maybe<ReadonlyArray<gqlMediaCount>>;
  /** Name of presentation group */
  readonly name?: Maybe<Scalars['String']>;
  /** Order of appearance */
  readonly order: Scalars['Int'];
  readonly presentation?: Maybe<gqlPresentationPublic>;
  readonly presentationFolder?: Maybe<gqlPresentationFolderPublic>;
  /** Stage names of added talents */
  readonly stageNames?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly submissionIds?: Maybe<ReadonlyArray<Scalars['BigInt']>>;
  readonly submissions: ReadonlyArray<gqlPresentationSubmissionPublic>;
  /** Count of added talents */
  readonly talentCount?: Maybe<Scalars['Int']>;
  readonly talents: ReadonlyArray<gqlPresentationTalentPublic>;
  /** Date when updated */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};


export type gqlPresentationGroupPublicMediaArgs = {
  mediaType?: InputMaybe<Scalars['String']>;
};


export type gqlPresentationGroupPublicSubmissionsArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
};


export type gqlPresentationGroupPublicTalentsArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** Presentation Group Submission */
export type gqlPresentationGroupSubmission = {
  readonly __typename?: 'PresentationGroupSubmission';
  /** Total count of comments for the submission */
  readonly commentCount: Scalars['Int'];
  /** Submission cover */
  readonly cover?: Maybe<gqlMedia>;
  /** ID of the presentation group */
  readonly groupId: Scalars['Int'];
  /** ID of connection between presentation group and submission */
  readonly id: Scalars['Int'];
  /** Total count of picks for the submission */
  readonly pickCount: Scalars['Int'];
  /** Custom presentation group talent role name. */
  readonly roleName?: Maybe<Scalars['String']>;
  readonly submission: gqlSubmission;
};

/** Paginated result with [PresentationGroupSubmission] objects */
export type gqlPresentationGroupSubmissionPaginatedResult = {
  readonly __typename?: 'PresentationGroupSubmissionPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of PresentationGroupSubmission objects */
  readonly objects: ReadonlyArray<gqlPresentationGroupSubmission>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Presentation Group Update input type */
export type gqlPresentationGroupUpdateInput = {
  /** Name for presentation group */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Presentation Group ID */
  readonly presentationGroupId: Scalars['Int'];
};

/** Send presentation link email input */
export type gqlPresentationLinkEmailInput = {
  /** Email copy */
  readonly cc?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Message */
  readonly message: Scalars['String'];
  /** Presentation ID */
  readonly presentationId: Scalars['Int'];
  /** Reply to */
  readonly replyTo: Scalars['String'];
  /** Subject */
  readonly subject: Scalars['String'];
  /** Email Recipients */
  readonly to: ReadonlyArray<Scalars['String']>;
};

/** Presentation Log Report Folder Model */
export type gqlPresentationLogFolderModel = {
  readonly __typename?: 'PresentationLogFolderModel';
  readonly id: Scalars['ID'];
  readonly items?: Maybe<ReadonlyArray<gqlPresentationLogGroupModel>>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Presentation Log Report Payload Group Model */
export type gqlPresentationLogGroupModel = {
  readonly __typename?: 'PresentationLogGroupModel';
  readonly id: Scalars['ID'];
  readonly items?: Maybe<ReadonlyArray<gqlPresentationReportTalentModel>>;
  readonly name?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
};

/** Presentation Log Report Presentation Model */
export type gqlPresentationLogPresentationModel = {
  readonly __typename?: 'PresentationLogPresentationModel';
  readonly id: Scalars['ID'];
  readonly items?: Maybe<ReadonlyArray<gqlPresentationLogFolderModel>>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Presentation Log Report Model */
export type gqlPresentationLogReportModel = {
  readonly __typename?: 'PresentationLogReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly displayTalentInfoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>>;
  readonly displayTalentPhotoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlPresentationLogReportPayloadModel>;
  readonly presentationFolderIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly presentationId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Log Report Payload Model */
export type gqlPresentationLogReportPayloadModel = {
  readonly __typename?: 'PresentationLogReportPayloadModel';
  readonly castingCompanyLogo?: Maybe<gqlReportMediaModel>;
  readonly presentation?: Maybe<gqlPresentationLogPresentationModel>;
  readonly projectName: Scalars['String'];
};

/** Presentation Download Archive Input Type */
export type gqlPresentationMediaDownloadInput = {
  /** GUID of Presentation */
  readonly guid: Scalars['String'];
  /** Presenatation Folder Id */
  readonly presentationFolderId: Scalars['Int'];
  /** Group Id of Presentation */
  readonly presentationGroupId?: InputMaybe<Scalars['Int']>;
  /** Email Id of Unauthenticated User */
  readonly unauthenticatedUserEmail?: InputMaybe<Scalars['String']>;
};

export type gqlPresentationOrganizationPublic = {
  readonly __typename?: 'PresentationOrganizationPublic';
  readonly id: Scalars['Int'];
  readonly logo?: Maybe<gqlMedia>;
  readonly name?: Maybe<Scalars['String']>;
  readonly parentName?: Maybe<Scalars['String']>;
};

/** Presentation Pick Report Presentation Model */
export type gqlPresentationPickPresentationModel = {
  readonly __typename?: 'PresentationPickPresentationModel';
  readonly id: Scalars['ID'];
  readonly items?: Maybe<ReadonlyArray<gqlPresentationPickRoleModel>>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Presentation Pick Report Model */
export type gqlPresentationPickReportModel = {
  readonly __typename?: 'PresentationPickReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlPresentationPickReportPayloadModel>;
  readonly presentationFolderIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly presentationId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Pick Report Payload Model */
export type gqlPresentationPickReportPayloadModel = {
  readonly __typename?: 'PresentationPickReportPayloadModel';
  readonly castingCompanyLogo?: Maybe<gqlReportMediaModel>;
  readonly presentation?: Maybe<gqlPresentationPickPresentationModel>;
  readonly projectName: Scalars['String'];
};

/** Presentation Pick Report Role Model */
export type gqlPresentationPickRoleModel = {
  readonly __typename?: 'PresentationPickRoleModel';
  readonly id: Scalars['ID'];
  readonly items?: Maybe<ReadonlyArray<gqlPresentationReportTalentModel>>;
  readonly name?: Maybe<Scalars['String']>;
};

export type gqlPresentationProjectPublic = {
  readonly __typename?: 'PresentationProjectPublic';
  readonly id: Scalars['Int'];
  readonly internalName?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type gqlPresentationPublic = {
  readonly __typename?: 'PresentationPublic';
  /** Creation Date */
  readonly createdAt: Scalars['DateTime'];
  /** Account of creator */
  readonly createdBy?: Maybe<gqlAccount>;
  /** Creator Account ID */
  readonly createdById: Scalars['Int'];
  readonly folder?: Maybe<ReadonlyArray<gqlPresentationFolderPublic>>;
  readonly folders: ReadonlyArray<gqlPresentationFolder>;
  /** Number of Folders in Presentation */
  readonly foldersCount: Scalars['Int'];
  /** GUID */
  readonly guid: Scalars['ID'];
  /** ID of presentation */
  readonly id: Scalars['Int'];
  readonly isLive: Scalars['Boolean'];
  /** Account of updater */
  readonly linkExpired?: Maybe<Scalars['Boolean']>;
  /** Link expiration date */
  readonly linkExpiresAt?: Maybe<Scalars['DateTime']>;
  /** Name of presentation */
  readonly name: Scalars['String'];
  readonly organization?: Maybe<gqlPresentationOrganizationPublic>;
  readonly passwordSet: Scalars['Boolean'];
  readonly project?: Maybe<gqlPresentationProjectPublic>;
  /** Project ID of presentation */
  readonly projectId?: Maybe<Scalars['Int']>;
  readonly settings?: Maybe<gqlPresentationSettings>;
  /** presentation type */
  readonly type?: Maybe<gqlPresentationType>;
  /** Update Date */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
  /** Account of updater */
  readonly updatedBy?: Maybe<gqlAccount>;
  /** Updated Account ID */
  readonly updatedById?: Maybe<Scalars['Int']>;
};


export type gqlPresentationPublicFolderArgs = {
  folderId: ReadonlyArray<Scalars['Int']>;
};

/** Presentation Report Create Input Arguments */
export type gqlPresentationReportCreateInput = {
  readonly clientNamesFilter?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly displayOptions?: InputMaybe<gqlPresentationReportDisplayOptionsModel>;
  readonly presentationFolderIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly presentationId: Scalars['Int'];
};

export type gqlPresentationReportCreatePublicInput = {
  readonly presentationFolderIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly presentationKey: Scalars['ID'];
  readonly publicUserName: Scalars['String'];
};

/** Presentation Report Display Options Model */
export type gqlPresentationReportDisplayOptionsModel = {
  readonly displayOnlyTalentWithPicks?: InputMaybe<ReadonlyArray<gqlDisplayOnlyTalentWithPicksEnum>>;
  readonly displayTalentInfoOptions?: InputMaybe<ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>>;
  readonly displayTalentPerPageOptions?: InputMaybe<gqlDisplayTalentPerPageOptionsEnum>;
  readonly displayTalentPhotoOptions?: InputMaybe<ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>>;
};

/** Presentation Report Talent Model */
export type gqlPresentationReportTalentModel = {
  readonly __typename?: 'PresentationReportTalentModel';
  readonly agencyContacts?: Maybe<gqlReportContactInfoModel>;
  readonly cover?: Maybe<gqlReportMediaModel>;
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly genderAppearance?: Maybe<ReadonlyArray<gqlSubFieldsGql>>;
  readonly headshotMedia?: Maybe<gqlReportMediaModel>;
  readonly id: Scalars['Int'];
  readonly isPersonal?: Maybe<Scalars['Boolean']>;
  readonly isRepresented?: Maybe<Scalars['Boolean']>;
  readonly minor?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly orgAbbreviation?: Maybe<Scalars['String']>;
  readonly orgLogo?: Maybe<gqlOrganizationLogo>;
  readonly orgName?: Maybe<Scalars['String']>;
  readonly orgParentName?: Maybe<Scalars['String']>;
  readonly orgPhone?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly roleName?: Maybe<Scalars['String']>;
  readonly sizeSheets?: Maybe<gqlPresentationSizeSheetsTalentModel>;
  readonly snapshotMedia?: Maybe<gqlReportMediaModel>;
  readonly stageName?: Maybe<Scalars['String']>;
  readonly talentEmail?: Maybe<Scalars['String']>;
  readonly talentNote?: Maybe<gqlPresentationReportTalentNoteModel>;
  readonly talentUnions?: Maybe<ReadonlyArray<Scalars['String']>>;
};

/** Presentation Report Talent Note Comment */
export type gqlPresentationReportTalentNoteCommentModel = {
  readonly __typename?: 'PresentationReportTalentNoteCommentModel';
  readonly author: Scalars['String'];
  readonly comment: Scalars['String'];
  readonly id: Scalars['String'];
  readonly submissionId: Scalars['BigInt'];
};

/** Presentation Report Talent Note */
export type gqlPresentationReportTalentNoteModel = {
  readonly __typename?: 'PresentationReportTalentNoteModel';
  readonly comments?: Maybe<ReadonlyArray<gqlPresentationReportTalentNoteCommentModel>>;
  readonly commentsCount?: Maybe<Scalars['Float']>;
  readonly pickedBy?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly picksCount?: Maybe<Scalars['Float']>;
};

export type gqlPresentationSettings = {
  readonly __typename?: 'PresentationSettings';
  readonly allowDownloadMedia: Scalars['Boolean'];
  readonly allowExportFormResponse: Scalars['Boolean'];
  readonly leaveFeedback?: Maybe<Scalars['Boolean']>;
  readonly seeOthersFeedback?: Maybe<Scalars['Boolean']>;
};

export type gqlPresentationSettingsInput = {
  readonly allowDownloadMedia?: InputMaybe<Scalars['Boolean']>;
  readonly allowExportFormResponse?: InputMaybe<Scalars['Boolean']>;
  readonly leaveFeedback?: InputMaybe<Scalars['Boolean']>;
  readonly seeOthersFeedback?: InputMaybe<Scalars['Boolean']>;
};

export type gqlPresentationSizeSheetsMetricsModel = {
  readonly __typename?: 'PresentationSizeSheetsMetricsModel';
  readonly cm?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly inch?: Maybe<Scalars['String']>;
  readonly inchRounded?: Maybe<Scalars['String']>;
};

export type gqlPresentationSizeSheetsProfileSizeModel = {
  readonly __typename?: 'PresentationSizeSheetsProfileSizeModel';
  readonly category?: Maybe<gqlSubFieldsGql>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly sizeModifier?: Maybe<gqlSubFieldsGql>;
};

/** Presentation Size Sheets Report Model */
export type gqlPresentationSizeSheetsReportModel = {
  readonly __typename?: 'PresentationSizeSheetsReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly displayOnlyTalentWithPicks?: Maybe<ReadonlyArray<gqlDisplayOnlyTalentWithPicksEnum>>;
  readonly displayTalentInfoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>>;
  readonly displayTalentPerPageOptions?: Maybe<gqlDisplayTalentPerPageOptionsEnum>;
  readonly displayTalentPhotoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlPresentationLogReportPayloadModel>;
  readonly presentationFolderIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly presentationId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gqlPresentationSizeSheetsSizeMetricsModel = {
  readonly __typename?: 'PresentationSizeSheetsSizeMetricsModel';
  readonly category?: Maybe<gqlSubFieldsGql>;
  readonly gender?: Maybe<gqlSubFieldsGql>;
  readonly id: Scalars['Int'];
  readonly shoeModifier?: Maybe<gqlSubFieldsGql>;
  readonly sizeAU?: Maybe<Scalars['String']>;
  readonly sizeEU?: Maybe<Scalars['String']>;
  readonly sizeUK?: Maybe<Scalars['String']>;
  readonly sizeUS?: Maybe<Scalars['String']>;
};

/** Presentation Report Talent Model Size Sheets */
export type gqlPresentationSizeSheetsTalentModel = {
  readonly __typename?: 'PresentationSizeSheetsTalentModel';
  readonly bust?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly chestSize?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly coat?: Maybe<Scalars['String']>;
  readonly cupSize?: Maybe<Scalars['String']>;
  readonly dressSize?: Maybe<gqlPresentationSizeSheetsSizeMetricsModel>;
  readonly gloveSize?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly hatSize?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly height?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly hipSize?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly inseam?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly neck?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly profilePants?: Maybe<ReadonlyArray<gqlPresentationSizeSheetsSizeMetricsModel>>;
  readonly profileShoeSizes?: Maybe<ReadonlyArray<gqlPresentationSizeSheetsSizeMetricsModel>>;
  readonly profileSizes?: Maybe<ReadonlyArray<gqlPresentationSizeSheetsProfileSizeModel>>;
  readonly profileTshirtSizes?: Maybe<ReadonlyArray<gqlPresentationSizeSheetsProfileSizeModel>>;
  readonly sleeve?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly underbust?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly waist?: Maybe<gqlPresentationSizeSheetsMetricsModel>;
  readonly weight?: Maybe<gqlPresentationSizeSheetsWeightMetricsModel>;
};

export type gqlPresentationSizeSheetsWeightMetricsModel = {
  readonly __typename?: 'PresentationSizeSheetsWeightMetricsModel';
  readonly id: Scalars['Int'];
  readonly kg?: Maybe<Scalars['Float']>;
  readonly lbs?: Maybe<Scalars['Float']>;
};

export type gqlPresentationSubmissionComment = {
  readonly __typename?: 'PresentationSubmissionComment';
  readonly comment: Scalars['String'];
  /** Author's Account ID is exists */
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly date: Scalars['DateTime'];
  /** Presentation Group Talent Comment ID */
  readonly id: Scalars['Int'];
  readonly submissionId: Scalars['BigInt'];
  readonly userName: Scalars['String'];
};

/** Paginated result with [PresentationSubmissionComment] objects */
export type gqlPresentationSubmissionCommentPaginatedResult = {
  readonly __typename?: 'PresentationSubmissionCommentPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of PresentationSubmissionComment objects */
  readonly objects: ReadonlyArray<gqlPresentationSubmissionComment>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlPresentationSubmissionFeedback = {
  readonly __typename?: 'PresentationSubmissionFeedback';
  readonly commentsCount: Scalars['Int'];
  readonly pickedBy: ReadonlyArray<gqlSubmissionPick>;
};

export type gqlPresentationSubmissionPublic = {
  readonly __typename?: 'PresentationSubmissionPublic';
  /** Audition Snapshot Media Assets */
  readonly auditionSnapshot?: Maybe<gqlMedia>;
  readonly commentCount?: Maybe<Scalars['Int']>;
  readonly contactInfo?: Maybe<gqlProfileContactInfo>;
  readonly cover?: Maybe<gqlMedia>;
  /** Primary ID */
  readonly id: Scalars['BigInt'];
  /** Media */
  readonly media?: Maybe<ReadonlyArray<gqlSubmissionMedia>>;
  readonly mediaCount?: Maybe<ReadonlyArray<gqlMediaCount>>;
  /** Note */
  readonly note?: Maybe<Scalars['String']>;
  readonly pickCount?: Maybe<Scalars['Int']>;
  readonly pickedBy?: Maybe<ReadonlyArray<gqlTalentPickPublic>>;
  readonly profile?: Maybe<gqlPublicProfile>;
  readonly profileId?: Maybe<Scalars['Int']>;
  /** Profile Working Location ID */
  readonly profileWorkingLocationId?: Maybe<Scalars['Int']>;
  readonly projectId?: Maybe<Scalars['Int']>;
  /** Requested Media */
  readonly requestedMedia?: Maybe<ReadonlyArray<gqlSubmissionMedia>>;
  readonly roleId?: Maybe<Scalars['Int']>;
  readonly roleName?: Maybe<Scalars['String']>;
  /** Customized Profile Working Location */
  readonly workingLocation?: Maybe<gqlProfileWorkingLocation>;
};

export type gqlPresentationTalentPublic = {
  readonly __typename?: 'PresentationTalentPublic';
  readonly commentCount?: Maybe<Scalars['Int']>;
  readonly cover?: Maybe<gqlMedia>;
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly groupId?: Maybe<Scalars['Int']>;
  readonly guid?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly minor?: Maybe<Scalars['Boolean']>;
  readonly pickCount?: Maybe<Scalars['Int']>;
  readonly pickedBy?: Maybe<ReadonlyArray<gqlTalentPickPublic>>;
  readonly projectGuid?: Maybe<Scalars['String']>;
  readonly roleGuid?: Maybe<Scalars['String']>;
  readonly roleName?: Maybe<Scalars['String']>;
  readonly stageName?: Maybe<Scalars['String']>;
  readonly submissionId?: Maybe<Scalars['BigInt']>;
  readonly talentUnions?: Maybe<ReadonlyArray<gqlTalentUnion>>;
};

/** Paginated result with [PresentationTalentPublic] objects */
export type gqlPresentationTalentPublicPaginatedResult = {
  readonly __typename?: 'PresentationTalentPublicPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of PresentationTalentPublic objects */
  readonly objects: ReadonlyArray<gqlPresentationTalentPublic>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** PresentationType object */
export type gqlPresentationType = {
  readonly __typename?: 'PresentationType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlPresentationTypeEnum {
  AUDITION_PRESENTATION = 'AUDITION_PRESENTATION',
  CASTING_PACKAGE = 'CASTING_PACKAGE',
  REP_PACKAGE = 'REP_PACKAGE',
  SEND_MEDIA_TO_CASTIT = 'SEND_MEDIA_TO_CASTIT',
  SHARED_SELECTS = 'SHARED_SELECTS'
}

/** Presentation Update input type */
export type gqlPresentationUpdateInput = {
  /** Date of link expiration */
  readonly linkExpiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Name for presentation */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Password */
  readonly password?: InputMaybe<Scalars['String']>;
  /** Presentation ID */
  readonly presentationId: Scalars['Int'];
  readonly settings?: InputMaybe<gqlPresentationSettingsInput>;
};

export type gqlPreviewSubscriptionInput = {
  readonly billingPath?: InputMaybe<Scalars['String']>;
  readonly couponCode?: InputMaybe<Scalars['String']>;
  readonly subscriptionBillingCode: Scalars['String'];
};

export type gqlPreviewSubscriptionRenewalInput = {
  readonly billingInfoId: Scalars['String'];
  readonly subscriptionId: Scalars['Int'];
};

/** Talent Profile - May be associated with Account */
export type gqlProfile = {
  readonly __typename?: 'Profile';
  /** Checks if profile have granted access to roster */
  readonly accessGranted: Scalars['Boolean'];
  readonly accountId?: Maybe<Scalars['Float']>;
  readonly ageInYears?: Maybe<Scalars['Boolean']>;
  /** Profile Artist ID */
  readonly artistId?: Maybe<Scalars['Float']>;
  /** Profile audio assets */
  readonly audios?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  readonly billingFeatures?: Maybe<ReadonlyArray<gqlArtistBillingFeatureModel>>;
  /** @deprecated Use 'billingFeatures' instead. ID will now return 0. */
  readonly billingItems?: Maybe<ReadonlyArray<gqlBillingItemModel>>;
  /** Blurb */
  readonly blurb?: Maybe<Scalars['String']>;
  /** Bust Object */
  readonly bust?: Maybe<gqlBust>;
  /** Chest size Object */
  readonly chestSize?: Maybe<gqlChestSize>;
  /** Coat Object */
  readonly coat?: Maybe<gqlCoat>;
  /** Conflicts for the profile */
  readonly conflicts: ReadonlyArray<gqlProfileConflict>;
  /** Dynamic field. Based on profile status and representative type */
  readonly contactInfo?: Maybe<gqlProfileContactInfo>;
  readonly contactedByRep?: Maybe<ReadonlyArray<gqlProfileContactedByRep>>;
  readonly cover?: Maybe<gqlMedia>;
  /** Created at Date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Profile Credits */
  readonly credits: ReadonlyArray<gqlCredit>;
  /** Profile Credits Count */
  readonly creditsCount: Scalars['Int'];
  /** Cup size Object */
  readonly cupSize?: Maybe<gqlCupSize>;
  readonly customSlug?: Maybe<gqlCustomSlug>;
  /** Date of birth */
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** stores/holds the id of the person who has deactivated the profile */
  readonly deactivatedBy?: Maybe<Scalars['Float']>;
  /** Default submission note */
  readonly defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Document type(s) */
  readonly documentTypes?: Maybe<ReadonlyArray<gqlDocument>>;
  /** Profile document assets */
  readonly documents?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Dress size Object */
  readonly dressSize?: Maybe<gqlClothesSize>;
  /** Ethnic Appearance(s) */
  readonly ethnicAppearances?: Maybe<ReadonlyArray<gqlProfileEthnicAppearance>>;
  /** Eye Color Object */
  readonly eyeColor?: Maybe<gqlEyeColor>;
  /** Facial Hair Type(s) */
  readonly facialHair?: Maybe<ReadonlyArray<gqlFacialHair>>;
  /** Gender Appearance(s) */
  readonly genderAppearances?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  /** Glove size Object */
  readonly gloveSize?: Maybe<gqlGlove>;
  /** GUID */
  readonly guid?: Maybe<Scalars['String']>;
  /** Hair Color Object */
  readonly hairColor?: Maybe<gqlHairColor>;
  /** Hair Style Object */
  readonly hairStyle?: Maybe<gqlHairStyle>;
  /** Profile has audio reel */
  readonly hasAudioReel?: Maybe<Scalars['Boolean']>;
  readonly hasAuditionHistory: Scalars['Boolean'];
  /** has Representation */
  readonly hasRepresentation?: Maybe<Scalars['Boolean']>;
  /** Profile has resume */
  readonly hasResume?: Maybe<Scalars['Boolean']>;
  /** Profile has video reel */
  readonly hasVideoReel?: Maybe<Scalars['Boolean']>;
  /** Hat size Object */
  readonly hatSize?: Maybe<gqlHatSize>;
  /** Height Object */
  readonly height?: Maybe<gqlHeight>;
  /** Hide credit year */
  readonly hideCreditYear: Scalars['Boolean'];
  /** Hip size Object */
  readonly hipSize?: Maybe<gqlHip>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inseam size Object */
  readonly inseam?: Maybe<gqlInseam>;
  readonly isActive: Scalars['Boolean'];
  /**
   * Returns true if profile was deactivated by a Rep user, returns false if
   * profile was deactivated by a Talent, returns null when there is no information
   * about the deactivating account
   */
  readonly isDeactivatedByReps?: Maybe<Scalars['Boolean']>;
  readonly isDropped: Scalars['Boolean'];
  readonly isInCastingList: Scalars['Boolean'];
  readonly isInactive: Scalars['Boolean'];
  /** If Profile is overscaled */
  readonly isOverscaled: Scalars['Boolean'];
  /** Identify the personal or represented profile. */
  readonly isPersonal: Scalars['Boolean'];
  readonly isPlayableAgeLockedToTalent: Scalars['Boolean'];
  /** Identify represented profile */
  readonly isRepresented: Scalars['Boolean'];
  /** Is talent seeking representation */
  readonly isSeekingRepresentation?: Maybe<Scalars['Boolean']>;
  /** Last note for the profile */
  readonly lastNote?: Maybe<gqlNote>;
  /** Luminate profile Data */
  readonly luminateProfile?: Maybe<gqlLuminateProfile>;
  /** Manager Email */
  readonly managerEmail?: Maybe<Scalars['String']>;
  /** All profile media */
  readonly mediaAssets?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Profile's primary photo */
  readonly mediaSummary: ReadonlyArray<gqlMediaCount>;
  /** Is minor */
  readonly minor?: Maybe<Scalars['Boolean']>;
  /** Multiple Birth Object */
  readonly multipleBirth?: Maybe<gqlMultipleBirth>;
  /** Neck size Object */
  readonly neck?: Maybe<gqlNeck>;
  /** Note */
  readonly note?: Maybe<Scalars['String']>;
  /** Note to Representative */
  readonly noteToRepresentative?: Maybe<Scalars['String']>;
  /** Notes for the profile */
  readonly notes: ReadonlyArray<gqlNote>;
  readonly organization?: Maybe<gqlOrganization>;
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  /** Other experiences */
  readonly otherExperiences?: Maybe<Scalars['String']>;
  /** Overscale Note */
  readonly overscaleNote?: Maybe<Scalars['String']>;
  /** Parent Name */
  readonly parent?: Maybe<Scalars['String']>;
  /** Profile phone */
  readonly phone?: Maybe<Scalars['String']>;
  /** Profile photo assets */
  readonly photos?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Note about piercing */
  readonly piercingNote?: Maybe<Scalars['String']>;
  /** The age a talent can play */
  readonly playableAge?: Maybe<Scalars['Float']>;
  /** The minimum age a talent can play */
  readonly playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  readonly playableAgeMin?: Maybe<Scalars['Float']>;
  /**
   * Date of pregnancy
   * @deprecated This field is deprecated and will be deleted once the compatible changes at the front end will be added everyewhere for pregnancyDue reference. Use pregnancyDue instead
   */
  readonly pregnancy?: Maybe<Scalars['DateTime']>;
  /** Date of pregnancy */
  readonly pregnancyDue?: Maybe<Scalars['DateTime']>;
  /** Profession */
  readonly profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  readonly profileAccessToken?: Maybe<Scalars['String']>;
  /** ProfileAccessToken created date */
  readonly profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile Account System Role */
  readonly profileAccountRole?: Maybe<Scalars['String']>;
  /** Get profile bookouts */
  readonly profileBookOuts: ReadonlyArray<gqlProfileBookOuts>;
  /** Gender Identities */
  readonly profileGenderIdentities?: Maybe<ReadonlyArray<gqlProfileGenderIdentity>>;
  /** Primary ID */
  readonly profileId: Scalars['ID'];
  /** The Profile List ids where current profile participated */
  readonly profileLists: ReadonlyArray<Scalars['Int']>;
  readonly profileMainDivision?: Maybe<gqlOrganization>;
  readonly profileMainOrganization?: Maybe<gqlOrganization>;
  /** media assets */
  readonly profileMedia?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Profile Pants */
  readonly profilePants: ReadonlyArray<gqlPants>;
  /** Profile pets */
  readonly profilePets: ReadonlyArray<gqlProfilePet>;
  /** Profile piercings */
  readonly profilePiercings: ReadonlyArray<gqlPiercing>;
  /** Profile shoe sizes */
  readonly profileShoeSizes: ReadonlyArray<gqlProfileShoeSize>;
  /** Profile general size */
  readonly profileSizes: ReadonlyArray<gqlProfileSize>;
  /** Profile Status Object */
  readonly profileStatus?: Maybe<gqlProfileStatus>;
  readonly profileStatusId?: Maybe<Scalars['Int']>;
  /** Profile TshirtSize */
  readonly profileTshirtSizes: ReadonlyArray<gqlTShirtSize>;
  /** Profile Type */
  readonly profileType?: Maybe<gqlProfileType>;
  /** Profile vehicles */
  readonly profileVehicles: ReadonlyArray<gqlProfileVehicle>;
  /** profileVisibility Object */
  readonly profileVisiblities?: Maybe<ReadonlyArray<gqlProfileVisibility>>;
  /** Wardrobes */
  readonly profileWardrobes?: Maybe<ReadonlyArray<gqlProfileWardrobe>>;
  /** Project Type */
  readonly projectType?: Maybe<Scalars['String']>;
  /** Prop Type(s) */
  readonly props?: Maybe<ReadonlyArray<gqlProfileProp>>;
  /** Job Persuing Type(s) */
  readonly pursuing?: Maybe<ReadonlyArray<gqlPursuing>>;
  /** User's resume document */
  readonly resume?: Maybe<gqlProfileMedia>;
  /** Roster hidden */
  readonly rosterHidden?: Maybe<Scalars['Boolean']>;
  /** Search hidden */
  readonly searchHidden?: Maybe<Scalars['Boolean']>;
  /** SeekingRepresentation Object */
  readonly seekingRepresentations?: Maybe<ReadonlyArray<gqlSeekingRepresentation>>;
  readonly selectionType: gqlProfileSelectionType;
  /** Skills and their Levels */
  readonly skills?: Maybe<ReadonlyArray<gqlProfileSkill>>;
  /** Sleeve Object */
  readonly sleeve?: Maybe<gqlSleeve>;
  /** Sport equipments */
  readonly sportEquipments?: Maybe<ReadonlyArray<gqlProfileSportEquipment>>;
  /** Stage Name */
  readonly stageName: Scalars['String'];
  /** Returns submission */
  readonly submission?: Maybe<gqlSubmission>;
  /** Submission request's media assets */
  readonly submissionRequestMedia: ReadonlyArray<gqlSubmissionMedia>;
  /** talent's contacts regardless representative status */
  readonly talentContactInfo?: Maybe<gqlProfileContactInfo>;
  /** Profile email */
  readonly talentEmail?: Maybe<Scalars['String']>;
  readonly talentFolders?: Maybe<ReadonlyArray<gqlPresentationFolderTalentPresence>>;
  /** Talent unions */
  readonly talentUnions?: Maybe<ReadonlyArray<gqlTalentProfileUnion>>;
  /** Profile tattoo */
  readonly tattoo?: Maybe<gqlTattoo>;
  /** Tattoo Note */
  readonly tattooNote?: Maybe<Scalars['String']>;
  /** Underbust size Object */
  readonly underbust?: Maybe<gqlUnderBust>;
  /** Unique Attribute Type(s) */
  readonly uniqueAttributes?: Maybe<ReadonlyArray<gqlUniqueAttribute>>;
  /** Updated at Date */
  readonly updated?: Maybe<Scalars['DateTime']>;
  /** Profile video assets */
  readonly videos?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Waist Object */
  readonly waist?: Maybe<gqlWaist>;
  /** Weight Object */
  readonly weight?: Maybe<gqlWeight>;
  /** Willingness Objects */
  readonly willingness: ReadonlyArray<gqlWillingness>;
  /** Work permit expiration date */
  readonly workPermitExp?: Maybe<Scalars['DateTime']>;
  /** Working Locations */
  readonly workingLocations?: Maybe<ReadonlyArray<gqlProfileWorkingLocation>>;
};


/** Talent Profile - May be associated with Account */
export type gqlProfileHasAuditionHistoryArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};


/** Talent Profile - May be associated with Account */
export type gqlProfileIsInCastingListArgs = {
  listId: Scalars['ID'];
};


/** Talent Profile - May be associated with Account */
export type gqlProfilePhotosArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};


/** Talent Profile - May be associated with Account */
export type gqlProfileSelectionTypeArgs = {
  roleId: Scalars['Float'];
};


/** Talent Profile - May be associated with Account */
export type gqlProfileSubmissionArgs = {
  roleId: Scalars['Float'];
};


/** Talent Profile - May be associated with Account */
export type gqlProfileSubmissionRequestMediaArgs = {
  roleId: Scalars['Float'];
};


/** Talent Profile - May be associated with Account */
export type gqlProfileTalentFoldersArgs = {
  folderId: Scalars['Int'];
};

/** Profile Access Token */
export type gqlProfileAccessToken = {
  readonly __typename?: 'ProfileAccessToken';
  /** Artist Id */
  readonly artistId: Scalars['Float'];
  /** Organization Id */
  readonly organizationId: Scalars['Float'];
  /** ProfileAccessToken created date */
  readonly profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile Id */
  readonly profileId: Scalars['Float'];
};

/** Input type for Profile Book Out dates */
export type gqlProfileBookOutInput = {
  /** End Date */
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  /** Profile Book Out Id */
  readonly profileBookoutId?: InputMaybe<Scalars['Float']>;
  /** Profile Id */
  readonly profileId?: InputMaybe<Scalars['Float']>;
  /** Start Date */
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
};

export type gqlProfileBookOuts = {
  readonly __typename?: 'ProfileBookOuts';
  /** End Date */
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** Profile Id */
  readonly id?: Maybe<Scalars['ID']>;
  /** Profile Book Out Id */
  readonly profileBookoutId?: Maybe<Scalars['Int']>;
  /** Profile Id */
  readonly profileId?: Maybe<Scalars['Int']>;
  /** Start Date */
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type gqlProfileConflict = {
  readonly __typename?: 'ProfileConflict';
  readonly code?: Maybe<Scalars['String']>;
  readonly conflictId?: Maybe<Scalars['Float']>;
  readonly expiration?: Maybe<Scalars['DateTime']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly profileConflictId?: Maybe<Scalars['Float']>;
  readonly profileId?: Maybe<Scalars['Float']>;
};

export type gqlProfileContactInfo = {
  readonly __typename?: 'ProfileContactInfo';
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

export type gqlProfileContactedByRep = {
  readonly __typename?: 'ProfileContactedByRep';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly profileId?: Maybe<Scalars['Float']>;
};

/** Input type for Create profile */
export type gqlProfileCreateInput = {
  /** for linking Profile to account not from current context */
  readonly accountId?: InputMaybe<Scalars['Float']>;
  /** for linking Profile to an existing artist */
  readonly artistId?: InputMaybe<Scalars['Float']>;
  /** Date of birth */
  readonly dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** First Name */
  readonly firstName?: InputMaybe<Scalars['String']>;
  /** Invitation token from Roster Invitation Request */
  readonly invitationToken?: InputMaybe<Scalars['String']>;
  /** Last Name */
  readonly lastName?: InputMaybe<Scalars['String']>;
  /** is minor */
  readonly minor?: InputMaybe<Scalars['Boolean']>;
  /** minor date of birth */
  readonly minorDateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** Note */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  readonly organizationId?: InputMaybe<Scalars['Float']>;
  /** Profile access token granted by Rep */
  readonly profileAccessToken?: InputMaybe<Scalars['String']>;
  /** profile status code */
  readonly profileStatusCode?: InputMaybe<Scalars['String']>;
  /** profileStatusId */
  readonly profileStatusId?: InputMaybe<Scalars['Int']>;
  /** Profile Type code */
  readonly profileTypeCode?: InputMaybe<Scalars['String']>;
  /** Send Email */
  readonly sendEmail?: InputMaybe<Scalars['Boolean']>;
  /** Stage Name */
  readonly stageName: Scalars['String'];
  /** Talent Email */
  readonly talentEmail?: InputMaybe<Scalars['String']>;
};

/** Context object for Profile Direct Message */
export type gqlProfileDirectMessageContextAndProfile = {
  readonly __typename?: 'ProfileDirectMessageContextAndProfile';
  readonly organizationDivision: Scalars['String'];
  readonly organizationLogo: Scalars['String'];
  readonly project?: Maybe<gqlProfileDirectMessageProjectContext>;
  readonly role?: Maybe<gqlProfileDirectMessageRoleContext>;
};

/** Profile Direct Message Context input */
export type gqlProfileDirectMessageContextInput = {
  readonly roleId?: InputMaybe<Scalars['Int']>;
  readonly senderOrgId: Scalars['Int'];
};

/** SubContext object for Project in Profile Direct Message context */
export type gqlProfileDirectMessageProjectContext = {
  readonly __typename?: 'ProfileDirectMessageProjectContext';
  readonly castingCompanyName: Scalars['String'];
  readonly name: Scalars['String'];
};

/** SubContext object for Role in Profile Direct Message context */
export type gqlProfileDirectMessageRoleContext = {
  readonly __typename?: 'ProfileDirectMessageRoleContext';
  readonly name: Scalars['String'];
};

/** Profile Gender Identity */
export type gqlProfileEthnicAppearance = {
  readonly __typename?: 'ProfileEthnicAppearance';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Order of appearance */
  readonly order?: Maybe<Scalars['Float']>;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlProfileFilter = {
  readonly __typename?: 'ProfileFilter';
  readonly id: Scalars['Int'];
  readonly stageName: Scalars['String'];
};

/** All possible way for creating personal profile */
export enum gqlProfileFromEnum {
  NEW = 'NEW',
  NONE = 'NONE',
  REP = 'REP',
  UNREP = 'UNREP'
}

/** Profile Gender Identity */
export type gqlProfileGenderIdentity = {
  readonly __typename?: 'ProfileGenderIdentity';
  /** Profile Gender Identity */
  readonly genderIdentity: gqlGenderIdentity;
  /** Profile Gender Other Identity Note */
  readonly genderOtherIdentityNote?: Maybe<Scalars['String']>;
};

export type gqlProfileGqlModel = {
  readonly __typename?: 'ProfileGqlModel';
  readonly stageName?: Maybe<Scalars['String']>;
  readonly submissionPhotoUrl?: Maybe<Scalars['String']>;
};

/** Profile Ids by Account */
export type gqlProfileIdsByAccount = {
  readonly __typename?: 'ProfileIdsByAccount';
  /** AccountId */
  readonly accountId?: Maybe<Scalars['Float']>;
  /** Profile ID */
  readonly profileId?: Maybe<Scalars['Float']>;
};

/** Update or Create B21 Profile from BAU */
export type gqlProfileInput = {
  /** Artist ID */
  readonly artistId?: InputMaybe<Scalars['Int']>;
  /** Profile max playable age */
  readonly maxPlayableAge: Scalars['Int'];
  /** Profile min playable age */
  readonly minPlayableAge: Scalars['Int'];
  /** Profile primary appearance ID */
  readonly primaryAppearanceId: Scalars['Int'];
  /** Profile primary location ID */
  readonly primaryLocationId: Scalars['Int'];
  /** Profile ID */
  readonly profileId: Scalars['Int'];
};

/** Profile List Details */
export type gqlProfileList = {
  readonly __typename?: 'ProfileList';
  /** List creation date */
  readonly created: Scalars['DateTime'];
  /** List's Creator Account */
  readonly createdBy?: Maybe<gqlAccount>;
  /** List ID */
  readonly id: Scalars['ID'];
  /** List Type Object */
  readonly listType?: Maybe<gqlListType>;
  /** Account of last list editor */
  readonly modifiedBy?: Maybe<gqlAccount>;
  /** List Name */
  readonly name: Scalars['String'];
  /** Organization of represe */
  readonly organization?: Maybe<gqlOrganization>;
  /** Organization ID */
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly profiles: gqlProfileSearch;
  /** Total count of profiles */
  readonly totalProfiles: Scalars['Float'];
  /** Last list updated date */
  readonly updated?: Maybe<Scalars['DateTime']>;
};


/** Profile List Details */
export type gqlProfileListProfilesArgs = {
  page: gqlPageInput;
  profileSearchInput?: InputMaybe<gqlProfileSearchInput>;
};


/** Profile List Details */
export type gqlProfileListTotalProfilesArgs = {
  profileStatusToSearch?: InputMaybe<gqlProfileStatusSearchEnum>;
};

/** Paginated result with [ProfileList] objects */
export type gqlProfileListPaginatedResult = {
  readonly __typename?: 'ProfileListPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of ProfileList objects */
  readonly objects: ReadonlyArray<gqlProfileList>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Profile Media */
export type gqlProfileMedia = {
  readonly __typename?: 'ProfileMedia';
  readonly created: Scalars['DateTime'];
  /**
   * Deprecated
   * @deprecated This field is redundant as profile media visibility depends only on existence in profile_media table.
   */
  readonly hidden: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isPrimary: Scalars['Boolean'];
  readonly media: gqlMedia;
  readonly order: Scalars['Float'];
  /** @deprecated See the B21-2790: As for the photosCount field, it shouldn't be there at all. Profile photos count should be returned in a different place. */
  readonly photosCount?: Maybe<Scalars['Int']>;
  readonly profile: gqlProfile;
  readonly profileMediaId: Scalars['Float'];
};

/** Profile Media */
export type gqlProfileMediaInput = {
  /** Is media hidden on profile? */
  readonly isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Is media primary? (Primary photo, video reel or audio reel) */
  readonly isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
  /** Profile Media Id */
  readonly profileMediaId: Scalars['ID'];
};

/** Profile pet */
export type gqlProfilePet = {
  readonly __typename?: 'ProfilePet';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlPet>;
  /** code */
  readonly code: Scalars['String'];
  /** Count */
  readonly count?: Maybe<Scalars['Float']>;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlPet>;
  /** Pet Size */
  readonly petSize?: Maybe<gqlPetSize>;
  /** Profile pet relation id */
  readonly profilePetId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for Profile Pet */
export type gqlProfilePetInput = {
  /** Pet count */
  readonly count: Scalars['Int'];
  /** Pet Id */
  readonly petId: Scalars['Int'];
  /** Pet Size Id */
  readonly petSizeId?: InputMaybe<Scalars['Int']>;
};

export type gqlProfilePick = {
  readonly __typename?: 'ProfilePick';
  /** ID Pick */
  readonly id: Scalars['Int'];
  readonly userName: Scalars['String'];
};

/** Profile prop */
export type gqlProfileProp = {
  readonly __typename?: 'ProfileProp';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlProp>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlProp>;
  /** Profile prop relation id */
  readonly profilePropId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Profile search object */
export type gqlProfileSearch = {
  readonly __typename?: 'ProfileSearch';
  readonly facets?: Maybe<ReadonlyArray<gqlFacet>>;
  /** Search profile list */
  readonly profiles?: Maybe<ReadonlyArray<gqlProfile>>;
  /** Total number of profiles */
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Search input */
export type gqlProfileSearchInput = {
  /** blurb */
  readonly blurb?: InputMaybe<Scalars['ID']>;
  /** Range of IDs for bust size */
  readonly bustId?: InputMaybe<gqlIdRangeInput>;
  /** Range of IDs for chest size */
  readonly chestSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Range for Coat Ids */
  readonly coatId?: InputMaybe<gqlIdRangeInput>;
  /** Range of IDs for cup size */
  readonly cupSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Document Ids */
  readonly documentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Range of IDs for dress size */
  readonly dressId?: InputMaybe<gqlIdRangeInput>;
  /** Enables search by luminate profiles, false by default */
  readonly enableLuminate?: InputMaybe<Scalars['Boolean']>;
  /** Ethninc Appearance Ids */
  readonly ethnicAppearanceIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly ethnicAppearances?: InputMaybe<ReadonlyArray<gqlEthnicAppearanceEnum>>;
  readonly excludeBookOutDates?: InputMaybe<gqlDateRangeInput>;
  readonly excludeConflictIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Representative Profile List Ids - search profile/s if it SHOULD NOT be included into Reps Profile List */
  readonly excludeProfileListIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Work Unions Ids which should be excluded from the search */
  readonly excludeTalentUnionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Work Unions Ids which should be excluded from the search */
  readonly excludeTalentUnions?: InputMaybe<ReadonlyArray<gqlTalentUnionEnum>>;
  /** Eye Color Id */
  readonly eyeColorId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Facial Hair Ids */
  readonly facialHairIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Gender Appearance Ids */
  readonly genderAppearanceIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly genderAppearances?: InputMaybe<ReadonlyArray<gqlGenderAppearanceEnum>>;
  /** Gender Identity Codes */
  readonly genderIdentities?: InputMaybe<ReadonlyArray<gqlGenderIdentityEnum>>;
  /** Gender Identity Ids */
  readonly genderIdentityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Range for Glove Size Ids */
  readonly gloveSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Hair Color Id */
  readonly hairColorId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Hair Style Id */
  readonly hairStyleId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** If set TRUE, will return only Profiles with Profile Pictures */
  readonly hasCover?: InputMaybe<Scalars['Boolean']>;
  /** Has Representation */
  readonly hasRepresentation?: InputMaybe<Scalars['Boolean']>;
  /** Range of Hat Size Ids */
  readonly hatSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Height Range by Ids */
  readonly heightRange?: InputMaybe<gqlIdRangeInput>;
  /** Range of IDs for hip size */
  readonly hipSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Range for Inseam size Ids */
  readonly inseamId?: InputMaybe<gqlIdRangeInput>;
  /** New profiles */
  readonly isNew?: InputMaybe<Scalars['Boolean']>;
  /** Search by minor's age */
  readonly minorAge?: InputMaybe<gqlAgeRangeInput>;
  /** Multiple Birth Id */
  readonly multipleBirthId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Range for Neck Ids */
  readonly neckId?: InputMaybe<gqlIdRangeInput>;
  /** Organization ID */
  readonly organizationId?: InputMaybe<Scalars['Float']>;
  /** Other Experiences */
  readonly otherExperiences?: InputMaybe<Scalars['String']>;
  /** Range of IDs for pants size */
  readonly pantsId?: InputMaybe<gqlIdRangeInput>;
  /** Pet Ids */
  readonly petIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Pet Size Id */
  readonly petSizeId?: InputMaybe<Scalars['Int']>;
  /** Piercing search by list of id and/or description */
  readonly piercing?: InputMaybe<gqlPiercingInput>;
  /** Playable Age Range */
  readonly playableAge?: InputMaybe<gqlAgeRangeInput>;
  /** Date Range */
  readonly pregnancyDueRange?: InputMaybe<gqlDateRangeInput>;
  /** Profession */
  readonly profession?: InputMaybe<Scalars['String']>;
  /** Profile Ids to search in */
  readonly profileIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Representative Profile List Ids - search profile/s if it included into Reps Profile List */
  readonly profileListIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Profile Status Id */
  readonly profileStatusId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search By Profile Statuses */
  readonly profileStatusToSearch?: InputMaybe<gqlProfileStatusSearchEnum>;
  /** Search by profile types */
  readonly profileTypeCodes?: InputMaybe<ReadonlyArray<gqlProfileTypeEnum>>;
  /** Search by profile type */
  readonly profileTypeId?: InputMaybe<Scalars['Int']>;
  /** Prop Id */
  readonly propIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Pursuing Ids */
  readonly pursuingIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Relationship To Minor Id */
  readonly relationshipToMinorId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly representationType?: InputMaybe<ReadonlyArray<gqlProfileSearchRepresentationType>>;
  /** Representative Ids */
  readonly representativeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** List Ids */
  readonly representativeListIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Representation Ids */
  readonly seekingRepresentations?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Shoe Modifier ID */
  readonly shoeModifierId?: InputMaybe<Scalars['ID']>;
  /** Range of Shoe Size IDs */
  readonly shoeSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Range of General Size IDs */
  readonly sizeId?: InputMaybe<gqlIdRangeInput>;
  /** Size Modifier ID */
  readonly sizeModifierId?: InputMaybe<Scalars['ID']>;
  readonly skillOperator?: InputMaybe<gqlFilterOperator>;
  /** Search by skill ids and their level ids */
  readonly skills?: InputMaybe<ReadonlyArray<gqlSkillSearchInput>>;
  /** Range for Sleeve Ids */
  readonly sleeveId?: InputMaybe<gqlIdRangeInput>;
  /** Search by Sport Equipment IDs */
  readonly sportEquipmentIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Stage name */
  readonly stageName?: InputMaybe<Scalars['String']>;
  /** Work Unions Ids */
  readonly talentUnionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly talentUnions?: InputMaybe<ReadonlyArray<gqlTalentUnionEnum>>;
  /** Tattoo search by list of id and/or description */
  readonly tattoo?: InputMaybe<gqlTattooInput>;
  /** Range for t-shirt Size Ids */
  readonly tshirtSizeId?: InputMaybe<gqlIdRangeInput>;
  /** Range of IDs for underbust size */
  readonly underbustId?: InputMaybe<gqlIdRangeInput>;
  /** Unique Attribute Ids */
  readonly uniqueAttributeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search by vehicle object */
  readonly vehicle?: InputMaybe<gqlVehicleSearchInput>;
  /** Range for Waist Ids */
  readonly waistId?: InputMaybe<gqlIdRangeInput>;
  /** Wardrobe Ids */
  readonly wardrobeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Weight Range by IDs */
  readonly weightRange?: InputMaybe<gqlIdRangeInput>;
  /** Willingness Ids */
  readonly willingnessIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Work permit expiration Range */
  readonly workPermitExpRange?: InputMaybe<gqlDateRangeInput>;
  /** Working Location Ids */
  readonly workingLocationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

/** Profile search representation type */
export enum gqlProfileSearchRepresentationType {
  Luminate = 'Luminate',
  Represented = 'Represented',
  Unrepresented = 'Unrepresented'
}

/** Project by org id search result */
export type gqlProfileSearchResultByOrgId = {
  readonly __typename?: 'ProfileSearchResultByOrgId';
  /** Profile Id */
  readonly id?: Maybe<Scalars['Int']>;
  /** Profile stage name */
  readonly stageName?: Maybe<Scalars['String']>;
};

/** Paginated result with [ProfileSearchResultByOrgId] objects */
export type gqlProfileSearchResultByOrgIdPaginatedResult = {
  readonly __typename?: 'ProfileSearchResultByOrgIdPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of ProfileSearchResultByOrgId objects */
  readonly objects: ReadonlyArray<gqlProfileSearchResultByOrgId>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Profile sort field options */
export enum gqlProfileSearchSortField {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Random = 'Random',
  Relevance = 'Relevance'
}

export type gqlProfileSearchSortOption = {
  readonly fieldName: gqlProfileSearchSortField;
  /** Default value: ASC */
  readonly sortOrder?: InputMaybe<gqlSortOrderEnum>;
};

export enum gqlProfileSelectionType {
  NONE = 'NONE',
  SELECTED = 'SELECTED',
  SELECTED_FOR_OTHER_ROLES = 'SELECTED_FOR_OTHER_ROLES',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_FOR_OTHER_ROLES = 'SUBMITTED_FOR_OTHER_ROLES'
}

/** Profile Shoe Size */
export type gqlProfileShoeSize = {
  readonly __typename?: 'ProfileShoeSize';
  /** Gender */
  readonly category: gqlBasicSetting;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Shoe Modifier */
  readonly shoeModifier?: Maybe<gqlShoeModifier>;
  /** ShoeSize for AU */
  readonly shoeSizeAU: Scalars['String'];
  /** ShoeSize for EU */
  readonly shoeSizeEU: Scalars['String'];
  /** ShoeSize for UK */
  readonly shoeSizeUK: Scalars['String'];
  /** ShoeSize for US */
  readonly shoeSizeUS: Scalars['String'];
  /** short order */
  readonly sortOrder: Scalars['Float'];
};

/** Input type for Profile Shoe Size */
export type gqlProfileShoeSizeInput = {
  /** Shoe Modifier Id */
  readonly shoeModifierId?: InputMaybe<Scalars['Int']>;
  /** Shoe Size Id */
  readonly shoeSizeId?: InputMaybe<Scalars['Int']>;
};

/** Profile Size */
export type gqlProfileSize = {
  readonly __typename?: 'ProfileSize';
  readonly category: gqlBasicSetting;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Size gender ID */
  readonly sizeGenderId: Scalars['ID'];
  /** Size Modifier */
  readonly sizeModifier?: Maybe<gqlSizeModifier>;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for Profile Size */
export type gqlProfileSizeInput = {
  /** Size Id */
  readonly sizeId?: InputMaybe<Scalars['Int']>;
  /** Size Modifier Id */
  readonly sizeModifierId?: InputMaybe<Scalars['Int']>;
};

/** Skill and it`s level */
export type gqlProfileSkill = {
  readonly __typename?: 'ProfileSkill';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlSkill>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Skill level */
  readonly level?: Maybe<gqlSkillLevel>;
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlSkill>;
  /** Profile media id */
  readonly profileMediaId?: Maybe<Scalars['Float']>;
  /** Profile skill id */
  readonly profileSkillId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type to update profile skill */
export type gqlProfileSkillInput = {
  /** Note */
  readonly note?: InputMaybe<Scalars['ID']>;
  /** Profile Media Id */
  readonly profileMediaId?: InputMaybe<Scalars['ID']>;
  /** Profile Skill Id */
  readonly profileSkillId: Scalars['BigInt'];
  /** Skill Level Id */
  readonly skillLevelId?: InputMaybe<Scalars['ID']>;
};

/** Profile sport equipment */
export type gqlProfileSportEquipment = {
  readonly __typename?: 'ProfileSportEquipment';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlSportEquipment>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlSportEquipment>;
  /** Profile sport equipment relation id */
  readonly profileSportEquipmentId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** ProfileStatus object */
export type gqlProfileStatus = {
  readonly __typename?: 'ProfileStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlProfileStatusEnum {
  ACTIVE = 'ACTIVE',
  ADD_IN = 'ADD_IN',
  CREATE_PROFILE_STEP_1 = 'CREATE_PROFILE_STEP_1',
  CREATE_PROFILE_STEP_2 = 'CREATE_PROFILE_STEP_2',
  CREATE_PROFILE_STEP_3 = 'CREATE_PROFILE_STEP_3',
  CREATE_PROFILE_STEP_4 = 'CREATE_PROFILE_STEP_4',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
  MIGRATED_INCOMPLETE = 'MIGRATED_INCOMPLETE',
  SELECT_MEMBERSHIP = 'SELECT_MEMBERSHIP',
  UNCLAIMED = 'UNCLAIMED',
  VERIFY_AGE = 'VERIFY_AGE'
}

export enum gqlProfileStatusSearchEnum {
  CURRENT = 'CURRENT',
  FORMER = 'FORMER',
  FORMER_AND_CURRENT = 'FORMER_AND_CURRENT',
  ROSTER_AND_SEARCH_HIDDEN = 'ROSTER_AND_SEARCH_HIDDEN',
  ROSTER_HIDDEN = 'ROSTER_HIDDEN'
}

/** ProfileType object */
export type gqlProfileType = {
  readonly __typename?: 'ProfileType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlProfileTypeEnum {
  B21 = 'B21',
  FASTCAST = 'FASTCAST',
  LUMINATE = 'LUMINATE'
}

export type gqlProfileUnionFilter = {
  readonly __typename?: 'ProfileUnionFilter';
  readonly excludeTalentUnions: ReadonlyArray<gqlTalentUnionEnum>;
  readonly roleId: Scalars['Float'];
  readonly talentUnions: ReadonlyArray<gqlTalentUnionEnum>;
};

/** Input type for update profile */
export type gqlProfileUpdateInput = {
  readonly ageInYears?: InputMaybe<Scalars['Boolean']>;
  /** artist id */
  readonly artistId?: InputMaybe<Scalars['ID']>;
  /** blurb */
  readonly blurb?: InputMaybe<Scalars['ID']>;
  /** bustId */
  readonly bustId?: InputMaybe<Scalars['ID']>;
  /** chestSizeId */
  readonly chestSizeId?: InputMaybe<Scalars['ID']>;
  /** coatId */
  readonly coatId?: InputMaybe<Scalars['ID']>;
  /** consentDate */
  readonly consentDate?: InputMaybe<Scalars['DateTime']>;
  /** cupSizeId */
  readonly cupSizeId?: InputMaybe<Scalars['ID']>;
  /** Date of birth */
  readonly dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** managerEmail */
  readonly defaultSubmissionNote?: InputMaybe<Scalars['String']>;
  /** documentIds */
  readonly documentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** dressId */
  readonly dressId?: InputMaybe<Scalars['ID']>;
  /** Ethnic Appearance Ids */
  readonly ethnicAppearance?: InputMaybe<ReadonlyArray<gqlOrderedItemInput>>;
  /** eyeColorId */
  readonly eyeColorId?: InputMaybe<Scalars['ID']>;
  /** facialHairStyleIds */
  readonly facialHairStyleIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** First Name */
  readonly firstName?: InputMaybe<Scalars['String']>;
  /** gender */
  readonly gender?: InputMaybe<gqlGenderInput>;
  /** gloveSizeId */
  readonly gloveSizeId?: InputMaybe<Scalars['ID']>;
  /** hairColorId */
  readonly hairColorId?: InputMaybe<Scalars['ID']>;
  /** hairStyleId */
  readonly hairStyleId?: InputMaybe<Scalars['ID']>;
  /** is Representation */
  readonly hasRepresentation?: InputMaybe<Scalars['Boolean']>;
  /** hatSizeId */
  readonly hatSizeId?: InputMaybe<Scalars['ID']>;
  /** heightId */
  readonly heightId?: InputMaybe<Scalars['ID']>;
  /** Hide credit year */
  readonly hideCreditYear?: InputMaybe<Scalars['Boolean']>;
  /** hipSizeId */
  readonly hipSizeId?: InputMaybe<Scalars['ID']>;
  /** Profile id */
  readonly id: Scalars['ID'];
  /** inseamId */
  readonly inseamId?: InputMaybe<Scalars['ID']>;
  /** If Profile is overscaled */
  readonly isOverscaled?: InputMaybe<Scalars['Boolean']>;
  /** is Seeking Representation */
  readonly isSeekingRepresentation?: InputMaybe<Scalars['Boolean']>;
  /** Last Name */
  readonly lastName?: InputMaybe<Scalars['String']>;
  /** managerEmail */
  readonly managerEmail?: InputMaybe<Scalars['String']>;
  /** is minor */
  readonly minor?: InputMaybe<Scalars['Boolean']>;
  /** Minor date of birth */
  readonly minorDateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** multipleBirthId */
  readonly multipleBirthId?: InputMaybe<Scalars['ID']>;
  /** neckId */
  readonly neckId?: InputMaybe<Scalars['ID']>;
  /** Note to reps */
  readonly noteToRepresentative?: InputMaybe<Scalars['String']>;
  /** otherExperiences */
  readonly otherExperiences?: InputMaybe<Scalars['String']>;
  /** Overscale Note */
  readonly overscaleNote?: InputMaybe<Scalars['String']>;
  /** Pants IDs */
  readonly pantsIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** phone */
  readonly phone?: InputMaybe<Scalars['String']>;
  /** piercingIds */
  readonly piercingIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** piercing note */
  readonly piercingNote?: InputMaybe<Scalars['String']>;
  /** playableAge at */
  readonly playableAge?: InputMaybe<Scalars['Int']>;
  /** playableAge */
  readonly playableAgeRange?: InputMaybe<gqlAgeRangeInput>;
  /** pregnancyDueDate */
  readonly pregnancyDue?: InputMaybe<Scalars['DateTime']>;
  /** profession */
  readonly profession?: InputMaybe<Scalars['String']>;
  /** Profile access token granted by Rep */
  readonly profileAccessToken?: InputMaybe<Scalars['String']>;
  /** profile status code */
  readonly profileStatusCode?: InputMaybe<Scalars['String']>;
  /** pursuing ids */
  readonly pursuingIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** _relationship_note */
  readonly relationshipNote?: InputMaybe<Scalars['String']>;
  /** relationshipToMinorId */
  readonly relationshipToMinorId?: InputMaybe<Scalars['ID']>;
  /** rosterIds */
  readonly rosterIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Representation Ids */
  readonly seekingRepresentations?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Profile Shoe Size */
  readonly shoeSize?: InputMaybe<ReadonlyArray<gqlProfileShoeSizeInput>>;
  /** Profile Size */
  readonly size?: InputMaybe<ReadonlyArray<gqlProfileSizeInput>>;
  /** Skills and Skills Levels */
  readonly skills?: InputMaybe<ReadonlyArray<gqlSkillInput>>;
  /** sleeveId */
  readonly sleeveId?: InputMaybe<Scalars['ID']>;
  /** Stage Name */
  readonly stageName?: InputMaybe<Scalars['String']>;
  /** talentEmail */
  readonly talentEmail?: InputMaybe<Scalars['String']>;
  /** Talent Unions */
  readonly talentUnions?: InputMaybe<ReadonlyArray<gqlTalentUnionInput>>;
  /** tattooId */
  readonly tattooId?: InputMaybe<Scalars['ID']>;
  /** tattooNote */
  readonly tattooNote?: InputMaybe<Scalars['String']>;
  /** tshirt size ids */
  readonly tshirtSizeIds?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  /** underbustId */
  readonly underbustId?: InputMaybe<Scalars['ID']>;
  /** unique atribute ids */
  readonly uniqueAttributeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** waistId */
  readonly waistId?: InputMaybe<Scalars['ID']>;
  /** wardrobe ids */
  readonly wardrobeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** weightId */
  readonly weightId?: InputMaybe<Scalars['ID']>;
  /** willingness ids */
  readonly willingnessIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Work permit expiration date */
  readonly workPermitExp?: InputMaybe<Scalars['DateTime']>;
  /** Working Locations */
  readonly workingLocations?: InputMaybe<ReadonlyArray<gqlOrderedItemInput>>;
};

/** Profile vehicle */
export type gqlProfileVehicle = {
  readonly __typename?: 'ProfileVehicle';
  /** Profile vehicle relation id */
  readonly profileVehicleId?: Maybe<Scalars['Float']>;
  /** Vehicle Name */
  readonly vehicleName?: Maybe<Scalars['String']>;
  /** Vehicle type make */
  readonly vehicleTypeMake?: Maybe<gqlVehicleTypeMake>;
  /** Vehicle Year */
  readonly vehicleYear?: Maybe<Scalars['Float']>;
};

/** Input type for Profile Vehicle */
export type gqlProfileVehicleInput = {
  /** Vehicle name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Vehicle type make id */
  readonly vehicleTypeMakeId: Scalars['Float'];
  /** Vehicle year */
  readonly year?: InputMaybe<Scalars['Float']>;
};

/** Profile Visibility object */
export type gqlProfileVisibility = {
  readonly __typename?: 'ProfileVisibility';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Profile wardrobe */
export type gqlProfileWardrobe = {
  readonly __typename?: 'ProfileWardrobe';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlWardrobe>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlWardrobe>;
  /** Profile wardrobe relation id */
  readonly profileWardrobeId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Profile working location */
export type gqlProfileWorkingLocation = {
  readonly __typename?: 'ProfileWorkingLocation';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Profile working location id */
  readonly profileWorkingLocationId: Scalars['Float'];
  /** child objects */
  readonly region?: Maybe<gqlRegion>;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Custom URL Slug with relevant blocked keyword */
export type gqlProhibitedCustomSlug = {
  readonly __typename?: 'ProhibitedCustomSlug';
  /** The relevant blocked keyword */
  readonly blockedKeyword: Scalars['String'];
  /** The relevant blocked keyword id */
  readonly blockedKeywordId: Scalars['ID'];
  /** The reason for the blocked keyword */
  readonly blockedReasonNote?: Maybe<Scalars['String']>;
  /** Date when the slug was created and assigned to this user */
  readonly createdDate: Scalars['DateTime'];
  /** Date when the slug is or will be expired */
  readonly expiredDate?: Maybe<Scalars['DateTime']>;
  /** Slug Id */
  readonly id: Scalars['ID'];
  /** Profile Id that owns the slug */
  readonly profileId: Scalars['ID'];
  /** Slug Name */
  readonly slugName: Scalars['String'];
};

/** Project object */
export type gqlProject = {
  readonly __typename?: 'Project';
  /** Account name */
  readonly accountName?: Maybe<Scalars['String']>;
  /** Project accounts */
  readonly accounts?: Maybe<ReadonlyArray<gqlAccount>>;
  /** AD agency */
  readonly adAgency?: Maybe<Scalars['String']>;
  /** Additional provisions */
  readonly additionalProvisions?: Maybe<Scalars['String']>;
  /** Project Attachment Security Id - show attachments to reps only - otherwise it will be also viewable on casting billboard */
  readonly attachmentDisplayTypeId?: Maybe<Scalars['ID']>;
  /** Project audition address */
  readonly auditionAddress?: Maybe<gqlAuditionAddress>;
  /** Audition type */
  readonly auditionType?: Maybe<gqlAuditionType>;
  readonly canTalentSubmitSelfTape?: Maybe<Scalars['Boolean']>;
  /** Casting assistant */
  readonly castingAssistant?: Maybe<Scalars['String']>;
  /** Casting associate */
  readonly castingAssociate?: Maybe<Scalars['String']>;
  /** Casting company name */
  readonly castingCompany?: Maybe<Scalars['String']>;
  /** Casting Company Name */
  readonly castingCompanyName?: Maybe<Scalars['String']>;
  /** Casting director */
  readonly castingDirector?: Maybe<Scalars['String']>;
  /** Conflict note */
  readonly conflictNote?: Maybe<Scalars['String']>;
  /** Contact email */
  readonly contactEmail?: Maybe<Scalars['String']>;
  /** Contact info permissions */
  readonly contactInfoPermissions?: Maybe<Scalars['Boolean']>;
  /** Contact phone */
  readonly contactPhone?: Maybe<Scalars['String']>;
  /** Created date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Created By Account Id */
  readonly createdBy?: Maybe<Scalars['Float']>;
  /** Is project created by a PC user */
  readonly createdByPC: Scalars['Boolean'];
  /** Support User(Creator) Account ID */
  readonly createdByTalentSystemUser?: Maybe<Scalars['Float']>;
  /**
   * Do not use this field
   * @deprecated Use 'created' field instead
   */
  readonly creationDate?: Maybe<Scalars['DateTime']>;
  /** Director */
  readonly director?: Maybe<Scalars['String']>;
  /** Displayed Project Union */
  readonly displayedProjectUnion: Scalars['String'];
  /** Executive producer */
  readonly executiveProducer?: Maybe<Scalars['String']>;
  readonly fieldRestrictions?: Maybe<ReadonlyArray<gqlProjectFieldRestriction>>;
  /** Project public GUID */
  readonly guid?: Maybe<Scalars['String']>;
  /** Is hidden */
  readonly hidden?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Project internal name */
  readonly internalName?: Maybe<Scalars['String']>;
  readonly isAuditionInPerson?: Maybe<Scalars['Boolean']>;
  readonly isSpecificLocation?: Maybe<Scalars['Boolean']>;
  readonly lastModifiedAt: Scalars['DateTime'];
  /** Last Role Published Date */
  readonly lastRolePublishedDate?: Maybe<Scalars['DateTime']>;
  /** Legacy instance ID */
  readonly legacyInstance?: Maybe<Scalars['String']>;
  /** Legacy instance URL */
  readonly legacyInstanceUrl?: Maybe<Scalars['String']>;
  /** Legacy project ID */
  readonly legacyProjectId?: Maybe<Scalars['ID']>;
  /** Project asset */
  readonly media?: Maybe<ReadonlyArray<gqlMedia>>;
  /** Project name */
  readonly name?: Maybe<Scalars['String']>;
  /** Network */
  readonly network?: Maybe<Scalars['String']>;
  /** Project Notes */
  readonly notes?: Maybe<Scalars['String']>;
  /** Project organizations */
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  /** Parent Project- eg series */
  readonly parent?: Maybe<gqlProject>;
  readonly pendingRequestCount?: Maybe<Scalars['Int']>;
  /** Photographer */
  readonly photographer?: Maybe<Scalars['String']>;
  /** Producer */
  readonly producer?: Maybe<Scalars['String']>;
  /** Production company */
  readonly productionCompany?: Maybe<Scalars['String']>;
  /** Project Conflicts */
  readonly projectConflicts?: Maybe<ReadonlyArray<gqlConflict>>;
  /** Project Id */
  readonly projectId?: Maybe<Scalars['Float']>;
  /** Project internal name */
  readonly projectInternalName?: Maybe<Scalars['String']>;
  /** Project name */
  readonly projectName?: Maybe<Scalars['String']>;
  /** Parent ID */
  readonly projectParentId?: Maybe<Scalars['ID']>;
  readonly projectSeries?: Maybe<gqlSeriesDetails>;
  /** Project type */
  readonly projectStatus?: Maybe<gqlProjectStatus>;
  /** Project type */
  readonly projectType?: Maybe<gqlProjectType>;
  /** Project Unions */
  readonly projectUnion?: Maybe<ReadonlyArray<gqlProjectUnionDetails>>;
  /** Project rate */
  readonly rate?: Maybe<Scalars['String']>;
  /** Release Locations DMA */
  readonly releaseLocations?: Maybe<ReadonlyArray<gqlDma>>;
  /** Request brief */
  readonly requestBrief?: Maybe<Scalars['Boolean']>;
  /** Request brief instruction */
  readonly requestBriefInstruction?: Maybe<Scalars['String']>;
  readonly roleDefaults?: Maybe<gqlRoleDefaults>;
  /** Project roles */
  readonly roles?: Maybe<ReadonlyArray<gqlRole>>;
  /** Series Name */
  readonly seriesName?: Maybe<Scalars['String']>;
  /** Project side visibility */
  readonly sideVisibility?: Maybe<gqlProjectSidesVisibility>;
  /** Studio */
  readonly studio?: Maybe<Scalars['String']>;
  /** Submissions due */
  readonly submissionsDueBy?: Maybe<Scalars['DateTime']>;
  /** Zendesk support ticket id */
  readonly supportTicketNumber?: Maybe<Scalars['String']>;
  /** Synopsis */
  readonly synopsis?: Maybe<Scalars['String']>;
  /** Tile scheme ID */
  readonly tileSchemeId?: Maybe<Scalars['ID']>;
  /** Total records count */
  readonly totalCount?: Maybe<Scalars['Float']>;
  /** Updated date */
  readonly updated?: Maybe<Scalars['DateTime']>;
  /** Usage */
  readonly usage?: Maybe<Scalars['String']>;
  /** Writer */
  readonly writer?: Maybe<Scalars['String']>;
};


/** Project object */
export type gqlProjectRolesArgs = {
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  roleStatusFilter?: InputMaybe<gqlRoleStatusFilter>;
};

export type gqlProjectAttachSupportTicketNumber = {
  readonly projectId: Scalars['Int'];
  readonly supportTicketNumber: Scalars['String'];
};

/** Filters for searching suitable projects from backlot */
export type gqlProjectBacklotSearchInput = {
  /** Country Ids */
  readonly countryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  readonly organizationId?: InputMaybe<Scalars['Int']>;
  /** Project Creator Mail */
  readonly projectCreatorEmail?: InputMaybe<Scalars['String']>;
  /** Project Id */
  readonly projectId?: InputMaybe<Scalars['Int']>;
  /** Project Status Code */
  readonly projectStatusCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Search result for backlot projects */
export type gqlProjectBacklotSearchResult = {
  readonly __typename?: 'ProjectBacklotSearchResult';
  /** Casting Company Id */
  readonly castingCompanyId?: Maybe<Scalars['Int']>;
  /** Casting Company Name */
  readonly castingCompanyName?: Maybe<Scalars['String']>;
  /** Contact Email */
  readonly contactEmail?: Maybe<Scalars['String']>;
  /** Countries */
  readonly country?: Maybe<gqlCountry>;
  /** Project Created Date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Project Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Project Id */
  readonly projectId?: Maybe<Scalars['Int']>;
  /** Project Status */
  readonly projectStatus?: Maybe<gqlProjectStatus>;
  /** Project Status Id */
  readonly projectStatusId?: Maybe<Scalars['Int']>;
  /** Project Type */
  readonly projectType?: Maybe<gqlProjectType>;
  /** Project Type Id */
  readonly projectTypeId?: Maybe<Scalars['Int']>;
  /** Project Update Date */
  readonly updated?: Maybe<Scalars['DateTime']>;
};

/** Paginated result with [ProjectBacklotSearchResult] objects */
export type gqlProjectBacklotSearchResultPaginatedResult = {
  readonly __typename?: 'ProjectBacklotSearchResultPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of ProjectBacklotSearchResult objects */
  readonly objects: ReadonlyArray<gqlProjectBacklotSearchResult>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlProjectBase = {
  readonly __typename?: 'ProjectBase';
  /** Audition type */
  readonly auditionType?: Maybe<gqlAuditionType>;
  /** Casting Company Name */
  readonly castingCompanyName?: Maybe<Scalars['String']>;
  /** Created date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Project internal name */
  readonly internalName?: Maybe<Scalars['String']>;
  readonly lastModifiedAt: Scalars['DateTime'];
  /** Project name */
  readonly name?: Maybe<Scalars['String']>;
  readonly projectHasList: Scalars['Boolean'];
  /** Project type */
  readonly projectStatus?: Maybe<gqlProjectStatus>;
  /** Project type */
  readonly projectType?: Maybe<gqlProjectType>;
  /** Series Name */
  readonly seriesName?: Maybe<Scalars['String']>;
  /** Zendesk support ticket id */
  readonly supportTicketNumber?: Maybe<Scalars['String']>;
};

/** Paginated result with [ProjectBase] objects */
export type gqlProjectBasePaginatedResult = {
  readonly __typename?: 'ProjectBasePaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of ProjectBase objects */
  readonly objects: ReadonlyArray<gqlProjectBase>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlProjectFieldRestriction = {
  readonly __typename?: 'ProjectFieldRestriction';
  readonly fields: ReadonlyArray<gqlProjectFieldRestrictionFieldOption>;
  readonly restrictedFor: gqlProjectFieldRestrictionOption;
};

export enum gqlProjectFieldRestrictionFieldOption {
  adAgency = 'adAgency',
  director = 'director',
  executiveProducer = 'executiveProducer',
  network = 'network',
  photographer = 'photographer',
  producer = 'producer',
  productionCompany = 'productionCompany',
  studio = 'studio',
  writer = 'writer'
}

export type gqlProjectFieldRestrictionInput = {
  readonly fields: ReadonlyArray<gqlProjectFieldRestrictionFieldOption>;
  readonly restrictedFor: gqlProjectFieldRestrictionOption;
};

export enum gqlProjectFieldRestrictionOption {
  AGENT = 'AGENT',
  CastingBillboard = 'CastingBillboard'
}

export type gqlProjectFilter = {
  readonly __typename?: 'ProjectFilter';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

/** Filters for searching suitable projects */
export type gqlProjectFilterInput = {
  /** Which casting organization's project should it be? */
  readonly castingOrganizationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Which organization division should be considered? */
  readonly dmaIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Has requests attached? */
  readonly hasRequests?: InputMaybe<Scalars['Boolean']>;
  /** Has selects attached? */
  readonly hasSelects?: InputMaybe<Scalars['Boolean']>;
  /** Has submissions attached? */
  readonly hasSubmissions?: InputMaybe<Scalars['Boolean']>;
  /** Should the project contain only kids roles? */
  readonly isKidsRolesOnly?: InputMaybe<Scalars['Boolean']>;
  /** Is project viewed */
  readonly isViewed?: InputMaybe<Scalars['Boolean']>;
  /** Which organization this project belongs? */
  readonly organizationId?: InputMaybe<Scalars['Float']>;
  /** Search in the name */
  readonly projectName?: InputMaybe<Scalars['String']>;
  /** What project types (by ID) should project have? */
  readonly projectTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Which project unions should be considered? */
  readonly projectUnionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Search in role name */
  readonly roleName?: InputMaybe<Scalars['String']>;
  /** Search by roleTypes array using codes */
  readonly roleTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** What submission date should it be? */
  readonly submissionDueDate?: InputMaybe<gqlSubmissionDueDateSearchEnum>;
};

/** Project group type enum */
export enum gqlProjectGroupType {
  Series = 'Series',
  Status = 'Status',
  Type = 'Type'
}

export type gqlProjectLocation = {
  readonly __typename?: 'ProjectLocation';
  readonly id: Scalars['ID'];
  readonly projectId: Scalars['Int'];
  readonly projectLocations: ReadonlyArray<Scalars['String']>;
};

/** Add Note Input Type */
export type gqlProjectNoteInput = {
  /** Text of the note */
  readonly comment: Scalars['String'];
  /** Project ID */
  readonly projectId?: InputMaybe<Scalars['BigInt']>;
};

export type gqlProjectNotificationToRepsInput = {
  readonly message: Scalars['String'];
  readonly projectId: Scalars['Int'];
};

/** Add organization as project attribute */
export type gqlProjectOrganizationInput = {
  readonly accessTypeId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
};

/** Project Overview CD Report */
export type gqlProjectOverviewCdReportModel = {
  readonly __typename?: 'ProjectOverviewCDReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlProjectOverviewCdReportPayloadModel>;
  readonly projectId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Project Overview CD Report Payload */
export type gqlProjectOverviewCdReportPayloadModel = {
  readonly __typename?: 'ProjectOverviewCDReportPayloadModel';
  readonly castingCompanyLogo?: Maybe<gqlReportMediaModel>;
  readonly displayRoleOption: gqlDisplayRoleOptionsEnum;
  readonly projectInfo: gqlProjectReportInfoModel;
  readonly projectRoles: ReadonlyArray<gqlProjectReportCdRoleModel>;
};

/** Project Overview Report */
export type gqlProjectOverviewReportModel = {
  readonly __typename?: 'ProjectOverviewReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlProjectOverviewReportPayloadModel>;
  readonly projectId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Project Overview Report Payload */
export type gqlProjectOverviewReportPayloadModel = {
  readonly __typename?: 'ProjectOverviewReportPayloadModel';
  readonly divisionLogo?: Maybe<gqlReportMediaModel>;
  readonly divisionName?: Maybe<Scalars['String']>;
  readonly projectInfo: gqlProjectReportInfoModel;
  readonly projectRoles: ReadonlyArray<gqlProjectReportRoleModel>;
};

/** Paginated result with [Project] objects */
export type gqlProjectPaginatedResult = {
  readonly __typename?: 'ProjectPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of Project objects */
  readonly objects: ReadonlyArray<gqlProject>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Project Report Attachment */
export type gqlProjectReportAttachmentModel = {
  readonly __typename?: 'ProjectReportAttachmentModel';
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
};

/** Project Report Input Arguments */
export type gqlProjectReportCdCreateInput = {
  readonly displayRoleOption: gqlDisplayRoleOptionsEnum;
  readonly projectId: Scalars['Int'];
  readonly roleTypes: ReadonlyArray<Scalars['String']>;
};

/** Project Report Role */
export type gqlProjectReportCdRoleModel = {
  readonly __typename?: 'ProjectReportCDRoleModel';
  readonly ageRange: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly displayedRoleEthnicAppearance?: Maybe<Scalars['String']>;
  readonly displayedRoleGenderAppearance: Scalars['String'];
  readonly displayedRoleUnion?: Maybe<Scalars['String']>;
  readonly ethnicAppearance?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly gender: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly paidType?: Maybe<Scalars['String']>;
  readonly photos?: Maybe<ReadonlyArray<gqlReportMediaModel>>;
  readonly rate?: Maybe<Scalars['String']>;
  readonly rateNote?: Maybe<Scalars['String']>;
  readonly ratePerType?: Maybe<Scalars['String']>;
  readonly rateSummary?: Maybe<Scalars['String']>;
  readonly requestMediaInstruction?: Maybe<Scalars['String']>;
  readonly requestMediaTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly requestSubmissionsFrom?: Maybe<ReadonlyArray<gqlReportPublicationSettings>>;
  readonly roleDateWithLocation?: Maybe<ReadonlyArray<gqlReportRoleDateWithLocation>>;
  readonly roleEthnicityNationality?: Maybe<Scalars['String']>;
  readonly roleSkills?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly roleStatus: Scalars['String'];
  readonly roleType: Scalars['String'];
  readonly sexualSituationDescription?: Maybe<Scalars['String']>;
  readonly sexualSituations?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sides?: Maybe<ReadonlyArray<gqlReportMediaObjectModel>>;
  readonly sidesVisibility?: Maybe<Scalars['String']>;
  readonly spot?: Maybe<Scalars['String']>;
  readonly submissionInstruction?: Maybe<Scalars['String']>;
  readonly wardrobe?: Maybe<Scalars['String']>;
  readonly workRequirement?: Maybe<Scalars['String']>;
};

/** Project Report Input Arguments */
export type gqlProjectReportCreateInput = {
  readonly projectId: Scalars['Int'];
};

/** Project Info */
export type gqlProjectReportInfoModel = {
  readonly __typename?: 'ProjectReportInfoModel';
  readonly adAgency?: Maybe<Scalars['String']>;
  readonly additionalProvisions?: Maybe<Scalars['String']>;
  readonly attachments: ReadonlyArray<gqlProjectReportAttachmentModel>;
  readonly castingAssistant?: Maybe<Scalars['String']>;
  readonly castingAssociate?: Maybe<Scalars['String']>;
  readonly castingCompany?: Maybe<Scalars['String']>;
  readonly castingDirector?: Maybe<Scalars['String']>;
  readonly conflictsNameList: ReadonlyArray<Scalars['String']>;
  readonly conflictsNote?: Maybe<Scalars['String']>;
  readonly director?: Maybe<Scalars['String']>;
  readonly displayedProjectUnion?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly executiveProducer?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly network?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly photographer?: Maybe<Scalars['String']>;
  readonly producer?: Maybe<Scalars['String']>;
  readonly productionCompany?: Maybe<Scalars['String']>;
  readonly projectNotes?: Maybe<Scalars['String']>;
  readonly projectSynopsis?: Maybe<Scalars['String']>;
  readonly projectType: Scalars['String'];
  readonly rate?: Maybe<Scalars['String']>;
  readonly studio?: Maybe<Scalars['String']>;
  readonly usageRun?: Maybe<Scalars['String']>;
  readonly writer?: Maybe<Scalars['String']>;
};

/** Project Report Role */
export type gqlProjectReportRoleModel = {
  readonly __typename?: 'ProjectReportRoleModel';
  readonly ageRange: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly displayedRoleUnion?: Maybe<Scalars['String']>;
  readonly ethnicAppearance: ReadonlyArray<Scalars['String']>;
  readonly gender: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly paidType?: Maybe<Scalars['String']>;
  readonly rate?: Maybe<Scalars['String']>;
  readonly rateNote?: Maybe<Scalars['String']>;
  readonly ratePer?: Maybe<Scalars['String']>;
  readonly rateSummary?: Maybe<Scalars['String']>;
  readonly roleType: Scalars['String'];
  readonly sexualSituations: ReadonlyArray<Scalars['String']>;
  readonly submissions: ReadonlyArray<Scalars['String']>;
};

export type gqlProjectRequestsInfo = {
  readonly __typename?: 'ProjectRequestsInfo';
  /** # of Requested talent in Project with worksheer request status of New, Rescheduled, Review */
  readonly newProjectRequestsCount?: Maybe<Scalars['Float']>;
  /** Total Project Requests regardless of status */
  readonly totalProjectRequestsCount: Scalars['Float'];
};

/** Project Series */
export type gqlProjectSeries = {
  readonly __typename?: 'ProjectSeries';
  /** Project Series ID */
  readonly id: Scalars['ID'];
  /** Org ID */
  readonly organizationId?: Maybe<Scalars['ID']>;
  /** Series Name */
  readonly seriesName: Scalars['String'];
};

/** Paginated result with [ProjectSeries] objects */
export type gqlProjectSeriesPaginatedResult = {
  readonly __typename?: 'ProjectSeriesPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of ProjectSeries objects */
  readonly objects: ReadonlyArray<gqlProjectSeries>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Project Sides Visibility object */
export type gqlProjectSidesVisibility = {
  readonly __typename?: 'ProjectSidesVisibility';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Project Status Object */
export type gqlProjectStatus = {
  readonly __typename?: 'ProjectStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlProjectStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  ARCHIVE_IN_PROGRESS = 'ARCHIVE_IN_PROGRESS',
  DRAFT = 'DRAFT',
  EDIT_TO_ACTIVE_PROJECT = 'EDIT_TO_ACTIVE_PROJECT',
  MODIFICATIONS_COMPLETE = 'MODIFICATIONS_COMPLETE',
  MODIFICATIONS_REQUESTED = 'MODIFICATIONS_REQUESTED',
  NEW_PROJECT_PENDING = 'NEW_PROJECT_PENDING',
  NOT_YET_PUBLISHED = 'NOT_YET_PUBLISHED',
  REJECTED = 'REJECTED',
  RESTORE_IN_PROGRESS = 'RESTORE_IN_PROGRESS',
  SUBMISSIONS_CLOSED = 'SUBMISSIONS_CLOSED'
}

/** Project status filter options */
export enum gqlProjectStatusFilterEnum {
  Archived = 'Archived',
  Current = 'Current'
}

/** Project Report Input Arguments */
export type gqlProjectSubmissionCreateInput = {
  readonly projectId: Scalars['Int'];
  readonly roleIds: ReadonlyArray<Scalars['Int']>;
  readonly talentStatus: gqlTalentStatusEnum;
};

/** Project Submission Report */
export type gqlProjectSubmissionReportModel = {
  readonly __typename?: 'ProjectSubmissionReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlProjectSubmissionReportPayloadModel>;
  readonly projectId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly talentStatus: gqlTalentStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Project Overview CD Report Payload */
export type gqlProjectSubmissionReportPayloadModel = {
  readonly __typename?: 'ProjectSubmissionReportPayloadModel';
  readonly divisionLogo?: Maybe<gqlReportMediaModel>;
  readonly divisionName?: Maybe<Scalars['String']>;
  readonly projectInfo: gqlProjectReportInfoModel;
  readonly projectRolesAcceptingSubmissions?: Maybe<ReadonlyArray<gqlProjectSubmissionReportRoleModel>>;
  readonly projectRolesClosedSubmissions?: Maybe<ReadonlyArray<gqlProjectSubmissionReportRoleModel>>;
};

/** Project Submission Report Role */
export type gqlProjectSubmissionReportRoleModel = {
  readonly __typename?: 'ProjectSubmissionReportRoleModel';
  readonly ageRange?: Maybe<Scalars['String']>;
  readonly ethnicAppearance?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly gender?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly roleType: Scalars['String'];
  readonly submissions?: Maybe<ReadonlyArray<Scalars['String']>>;
};

/** Available project types */
export type gqlProjectType = {
  readonly __typename?: 'ProjectType';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlProjectType>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlProjectType>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlProjectTypeEnum {
  AUDIO = 'AUDIO',
  AUDIO_AUDIO_BOOKS = 'AUDIO_AUDIO_BOOKS',
  AUDIO_PODCAST = 'AUDIO_PODCAST',
  AUDIO_RADIO = 'AUDIO_RADIO',
  AUDIO_VOICE_OVER = 'AUDIO_VOICE_OVER',
  COMMERCIAL = 'COMMERCIAL',
  COMMERCIAL_COMMERCIAL = 'COMMERCIAL_COMMERCIAL',
  COMMERCIAL_INDUSTRIAL = 'COMMERCIAL_INDUSTRIAL',
  COMMERCIAL_INFOMERCIAL = 'COMMERCIAL_INFOMERCIAL',
  COMMERCIAL_INTERNET_COMMERCIAL = 'COMMERCIAL_INTERNET_COMMERCIAL',
  COMMERCIAL_PSA = 'COMMERCIAL_PSA',
  EPISODIC = 'EPISODIC',
  EPISODIC_DAYTIME_DRAMA = 'EPISODIC_DAYTIME_DRAMA',
  EPISODIC_GAME_SHOW = 'EPISODIC_GAME_SHOW',
  EPISODIC_MINI_SERIES = 'EPISODIC_MINI_SERIES',
  EPISODIC_PILOT = 'EPISODIC_PILOT',
  EPISODIC_REALITY_TV = 'EPISODIC_REALITY_TV',
  EPISODIC_RE_ENACTMENT = 'EPISODIC_RE_ENACTMENT',
  EPISODIC_STREAMING_SERIES = 'EPISODIC_STREAMING_SERIES',
  EPISODIC_TALK_SHOW = 'EPISODIC_TALK_SHOW',
  EPISODIC_TELEVISION_SERIES = 'EPISODIC_TELEVISION_SERIES',
  EPISODIC_WEB_SERIES = 'EPISODIC_WEB_SERIES',
  FILM = 'FILM',
  FILM_DOCUMENTARY = 'FILM_DOCUMENTARY',
  FILM_FEATURE_FILM = 'FILM_FEATURE_FILM',
  FILM_SHORT_FILM = 'FILM_SHORT_FILM',
  FILM_SHORT_VIDEO = 'FILM_SHORT_VIDEO',
  FILM_TELEVISION_FILM = 'FILM_TELEVISION_FILM',
  GAMING = 'GAMING',
  GAMING_MOBILE_APP = 'GAMING_MOBILE_APP',
  GAMING_VIDEO_GAME = 'GAMING_VIDEO_GAME',
  GAMING_VIRTUAL_REALITY = 'GAMING_VIRTUAL_REALITY',
  LIVE_EVENT = 'LIVE_EVENT',
  LIVE_EVENT_BRAND_AMBASSADOR = 'LIVE_EVENT_BRAND_AMBASSADOR',
  LIVE_EVENT_CABARET = 'LIVE_EVENT_CABARET',
  LIVE_EVENT_COMEDY_SHOW = 'LIVE_EVENT_COMEDY_SHOW',
  LIVE_EVENT_EDUCATIONAL_TRAINING = 'LIVE_EVENT_EDUCATIONAL_TRAINING',
  LIVE_EVENT_LIVE_EVENT = 'LIVE_EVENT_LIVE_EVENT',
  LIVE_EVENT_PROMOTIONAL_EVENT = 'LIVE_EVENT_PROMOTIONAL_EVENT',
  LIVE_EVENT_RUNWAY_SHOW = 'LIVE_EVENT_RUNWAY_SHOW',
  LIVE_EVENT_VARIETY_SHOW = 'LIVE_EVENT_VARIETY_SHOW',
  OTHER = 'OTHER',
  OTHER_DIRECTORS_REEL = 'OTHER_DIRECTORS_REEL',
  OTHER_MUSIC = 'OTHER_MUSIC',
  OTHER_MUSIC_VIDEO = 'OTHER_MUSIC_VIDEO',
  OTHER_OTHER = 'OTHER_OTHER',
  OTHER_SKETCH = 'OTHER_SKETCH',
  PRINT = 'PRINT',
  PRINT_PRINT = 'PRINT_PRINT',
  PRINT_STOCK_PHOTOGRAPHY = 'PRINT_STOCK_PHOTOGRAPHY',
  PROMO = 'PROMO',
  STUDENT = 'STUDENT',
  STUDENT_STUDENT_FILM = 'STUDENT_STUDENT_FILM',
  STUDENT_STUDENT_PROJECT = 'STUDENT_STUDENT_PROJECT',
  THEATRE = 'THEATRE',
  THEATRE_THEATRE = 'THEATRE_THEATRE'
}

/** Project Union Details */
export type gqlProjectUnionDetails = {
  readonly __typename?: 'ProjectUnionDetails';
  /** Union contract id */
  readonly contractId?: Maybe<Scalars['String']>;
  /** Contract Status */
  readonly contractStatus?: Maybe<gqlContractStatus>;
  /** Union contract status id */
  readonly contractStatusId?: Maybe<Scalars['Int']>;
  /** Union details */
  readonly union: gqlTalentUnion;
};

/** Project Viewed Filter */
export enum gqlProjectViewFilterType {
  All = 'All',
  Unviewed = 'Unviewed',
  Viewed = 'Viewed'
}

export type gqlProjectVisibility = {
  readonly __typename?: 'ProjectVisibility';
  /** States if the project has any role with selected talent from user current agency */
  readonly isAnyRoleSelected: Scalars['Boolean'];
  /** States if the project has any role with submitted talent from user current agency */
  readonly isAnyRoleSubmitted: Scalars['Boolean'];
  /** States if the project was viewed by the context user */
  readonly isViewed: Scalars['Boolean'];
  /** Project ID */
  readonly project: gqlProject;
  /** States if the project has any requests for current reps agency */
  readonly requestsInfo?: Maybe<gqlProjectRequestsInfo>;
};

export type gqlProjectVisibilityResult = {
  readonly __typename?: 'ProjectVisibilityResult';
  /** project_id - is_viewed pair */
  readonly projectVisibilities: ReadonlyArray<gqlProjectVisibility>;
  /** Clusterized info about project locations */
  readonly projectsLocations: ReadonlyArray<gqlProjectLocation>;
  /** Total number of projects */
  readonly totalCount?: Maybe<Scalars['Int']>;
  /** Total number of new or updated requests */
  readonly totalCountOfNewOrUpdatedRequests: Scalars['Int'];
  /** Total number of requests */
  readonly totalCountOfRequests: Scalars['Int'];
};

/** Project for Workflow */
export type gqlProjectWorkflow = {
  readonly __typename?: 'ProjectWorkflow';
  readonly castingCompany?: Maybe<Scalars['String']>;
  /** Conflict note */
  readonly conflictNote?: Maybe<Scalars['String']>;
  readonly createdByPC: Scalars['Boolean'];
  /** Displayed project union */
  readonly displayedProjectUnion?: Maybe<Scalars['String']>;
  /** Displayed project union short */
  readonly displayedProjectUnionShort?: Maybe<Scalars['String']>;
  readonly guid?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  /** Project Notes */
  readonly notes?: Maybe<Scalars['String']>;
  /** Project Conflicts */
  readonly projectConflicts?: Maybe<ReadonlyArray<gqlConflict>>;
  /** Project Status */
  readonly projectStatus?: Maybe<gqlProjectStatus>;
  readonly projectType: gqlProjectType;
  readonly projectUnions: ReadonlyArray<gqlProjectUnionDetails>;
  readonly synopsis?: Maybe<Scalars['String']>;
};

/** Prop object */
export type gqlProp = {
  readonly __typename?: 'Prop';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlProp>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlProp>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlPublicCredit = {
  readonly __typename?: 'PublicCredit';
  /** Created at Date */
  readonly created: Scalars['DateTime'];
  readonly creditMedia: ReadonlyArray<gqlMedia>;
  /** Credit Sort ordering */
  readonly creditOrder: Scalars['Int'];
  /** Credit Type */
  readonly creditType?: Maybe<gqlCreditType>;
  /** The director of the Project */
  readonly director?: Maybe<Scalars['String']>;
  readonly guid: Scalars['String'];
  /** Highlighted */
  readonly highlighted: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Profile Credit Type Id */
  readonly profileCreditTypeId: Scalars['Float'];
  /** Profile ID */
  readonly profileId: Scalars['ID'];
  /** Role title */
  readonly role?: Maybe<Scalars['String']>;
  /** Credit Type Sort ordering */
  readonly sortOrder: Scalars['Int'];
  /** Title */
  readonly title?: Maybe<Scalars['String']>;
  /** Updated at Date */
  readonly updated: Scalars['DateTime'];
  /** Year */
  readonly year?: Maybe<Scalars['Int']>;
};

export type gqlPublicProfile = {
  readonly __typename?: 'PublicProfile';
  /** Checks if profile have granted access to roster */
  readonly accessGranted: Scalars['Boolean'];
  readonly accountId?: Maybe<Scalars['Float']>;
  readonly ageInYears?: Maybe<Scalars['Boolean']>;
  /** Profile Artist ID */
  readonly artistId?: Maybe<Scalars['Float']>;
  /** Profile audio assets */
  readonly audios?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  readonly billingFeatures?: Maybe<ReadonlyArray<gqlArtistBillingFeatureModel>>;
  /** @deprecated Use 'billingFeatures' instead. ID will now return 0. */
  readonly billingItems?: Maybe<ReadonlyArray<gqlBillingItemModel>>;
  /** Blurb */
  readonly blurb?: Maybe<Scalars['String']>;
  /** Bust Object */
  readonly bust?: Maybe<gqlBust>;
  /** Chest size Object */
  readonly chestSize?: Maybe<gqlChestSize>;
  /** Coat Object */
  readonly coat?: Maybe<gqlCoat>;
  /** Conflicts for the profile */
  readonly conflicts: ReadonlyArray<gqlProfileConflict>;
  /** Dynamic field. Based on profile status and representative type */
  readonly contactInfo?: Maybe<gqlProfileContactInfo>;
  readonly contactedByRep?: Maybe<ReadonlyArray<gqlProfileContactedByRep>>;
  /** Profile's primary photo */
  readonly cover?: Maybe<gqlMedia>;
  /** Created at Date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Profile Credits */
  readonly credits: ReadonlyArray<gqlPublicCredit>;
  /** Profile Credits Count */
  readonly creditsCount: Scalars['Int'];
  /** Cup size Object */
  readonly cupSize?: Maybe<gqlCupSize>;
  readonly customSlug?: Maybe<gqlCustomSlug>;
  /** Date of birth */
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** stores/holds the id of the person who has deactivated the profile */
  readonly deactivatedBy?: Maybe<Scalars['Float']>;
  /** Default submission note */
  readonly defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Document type(s) */
  readonly documentTypes?: Maybe<ReadonlyArray<gqlDocument>>;
  /** Profile document assets */
  readonly documents?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Dress size Object */
  readonly dressSize?: Maybe<gqlClothesSize>;
  /** Ethnic Appearance(s) */
  readonly ethnicAppearances?: Maybe<ReadonlyArray<gqlProfileEthnicAppearance>>;
  /** Eye Color Object */
  readonly eyeColor?: Maybe<gqlEyeColor>;
  /** Facial Hair Type(s) */
  readonly facialHair?: Maybe<ReadonlyArray<gqlFacialHair>>;
  readonly folderCustomization?: Maybe<gqlPublicProfileFolderCustomization>;
  /** Gender Appearance(s) */
  readonly genderAppearances?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  /** Glove size Object */
  readonly gloveSize?: Maybe<gqlGlove>;
  /** GUID */
  readonly guid?: Maybe<Scalars['String']>;
  /** Hair Color Object */
  readonly hairColor?: Maybe<gqlHairColor>;
  /** Hair Style Object */
  readonly hairStyle?: Maybe<gqlHairStyle>;
  /** Profile has audio reel */
  readonly hasAudioReel?: Maybe<Scalars['Boolean']>;
  readonly hasAuditionHistory: Scalars['Boolean'];
  /** has Representation */
  readonly hasRepresentation?: Maybe<Scalars['Boolean']>;
  /** Profile has resume */
  readonly hasResume?: Maybe<Scalars['Boolean']>;
  /** Profile has video reel */
  readonly hasVideoReel?: Maybe<Scalars['Boolean']>;
  /** Hat size Object */
  readonly hatSize?: Maybe<gqlHatSize>;
  /** Height Object */
  readonly height?: Maybe<gqlHeight>;
  /** Hide credit year */
  readonly hideCreditYear: Scalars['Boolean'];
  /** Hip size Object */
  readonly hipSize?: Maybe<gqlHip>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inseam size Object */
  readonly inseam?: Maybe<gqlInseam>;
  readonly isActive: Scalars['Boolean'];
  /**
   * Returns true if profile was deactivated by a Rep user, returns false if
   * profile was deactivated by a Talent, returns null when there is no information
   * about the deactivating account
   */
  readonly isDeactivatedByReps?: Maybe<Scalars['Boolean']>;
  readonly isDropped: Scalars['Boolean'];
  readonly isInactive: Scalars['Boolean'];
  /** If Profile is overscaled */
  readonly isOverscaled: Scalars['Boolean'];
  /** Identify the personal or represented profile. */
  readonly isPersonal: Scalars['Boolean'];
  readonly isPlayableAgeLockedToTalent: Scalars['Boolean'];
  /** Identify represented profile */
  readonly isRepresented: Scalars['Boolean'];
  /** Is talent seeking representation */
  readonly isSeekingRepresentation?: Maybe<Scalars['Boolean']>;
  /** Last note for the profile */
  readonly lastNote?: Maybe<gqlNote>;
  /** Luminate profile Data */
  readonly luminateProfile?: Maybe<gqlLuminateProfile>;
  /** Manager Email */
  readonly managerEmail?: Maybe<Scalars['String']>;
  /** All profile media */
  readonly mediaAssets?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Profile's primary photo */
  readonly mediaSummary: ReadonlyArray<gqlMediaCount>;
  /** Is minor */
  readonly minor?: Maybe<Scalars['Boolean']>;
  /** Multiple Birth Object */
  readonly multipleBirth?: Maybe<gqlMultipleBirth>;
  /** Neck size Object */
  readonly neck?: Maybe<gqlNeck>;
  /** Note */
  readonly note?: Maybe<Scalars['String']>;
  /** Note to Representative */
  readonly noteToRepresentative?: Maybe<Scalars['String']>;
  /** Notes for the profile */
  readonly notes: ReadonlyArray<gqlNote>;
  readonly organization?: Maybe<gqlOrganization>;
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  /** Other experiences */
  readonly otherExperiences?: Maybe<Scalars['String']>;
  /** Overscale Note */
  readonly overscaleNote?: Maybe<Scalars['String']>;
  /** Parent Name */
  readonly parent?: Maybe<Scalars['String']>;
  /** Profile phone */
  readonly phone?: Maybe<Scalars['String']>;
  /** Profile photo assets */
  readonly photos?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Note about piercing */
  readonly piercingNote?: Maybe<Scalars['String']>;
  /** The age a talent can play */
  readonly playableAge?: Maybe<Scalars['Float']>;
  /** The minimum age a talent can play */
  readonly playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  readonly playableAgeMin?: Maybe<Scalars['Float']>;
  /**
   * Date of pregnancy
   * @deprecated This field is deprecated and will be deleted once the compatible changes at the front end will be added everyewhere for pregnancyDue reference. Use pregnancyDue instead
   */
  readonly pregnancy?: Maybe<Scalars['DateTime']>;
  /** Date of pregnancy */
  readonly pregnancyDue?: Maybe<Scalars['DateTime']>;
  /** Profession */
  readonly profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  readonly profileAccessToken?: Maybe<Scalars['String']>;
  /** ProfileAccessToken created date */
  readonly profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile account country */
  readonly profileAccountCountry?: Maybe<gqlCountry>;
  /** Profile Account System Role */
  readonly profileAccountRole?: Maybe<Scalars['String']>;
  /** Get profile bookouts */
  readonly profileBookOuts: ReadonlyArray<gqlProfileBookOuts>;
  /** Gender Identities */
  readonly profileGenderIdentities?: Maybe<ReadonlyArray<gqlProfileGenderIdentity>>;
  /** Primary ID */
  readonly profileId: Scalars['ID'];
  /** The Profile List ids where current profile participated */
  readonly profileLists: ReadonlyArray<Scalars['Int']>;
  readonly profileMainDivision?: Maybe<gqlOrganization>;
  readonly profileMainOrganization?: Maybe<gqlOrganization>;
  /** media assets */
  readonly profileMedia?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Profile Pants */
  readonly profilePants: ReadonlyArray<gqlPants>;
  /** Profile pets */
  readonly profilePets: ReadonlyArray<gqlProfilePet>;
  /** Profile piercings */
  readonly profilePiercings: ReadonlyArray<gqlPiercing>;
  /** Profile shoe sizes */
  readonly profileShoeSizes: ReadonlyArray<gqlProfileShoeSize>;
  /** Profile general size */
  readonly profileSizes: ReadonlyArray<gqlProfileSize>;
  /** Profile Status Object */
  readonly profileStatus?: Maybe<gqlProfileStatus>;
  readonly profileStatusId?: Maybe<Scalars['Int']>;
  /** Profile TshirtSize */
  readonly profileTshirtSizes: ReadonlyArray<gqlTShirtSize>;
  /** Profile Type */
  readonly profileType?: Maybe<gqlProfileType>;
  /** Profile vehicles */
  readonly profileVehicles: ReadonlyArray<gqlProfileVehicle>;
  /** profileVisibility Object */
  readonly profileVisiblities?: Maybe<ReadonlyArray<gqlProfileVisibility>>;
  /** Wardrobes */
  readonly profileWardrobes?: Maybe<ReadonlyArray<gqlProfileWardrobe>>;
  /** Project Type */
  readonly projectType?: Maybe<Scalars['String']>;
  /** Prop Type(s) */
  readonly props?: Maybe<ReadonlyArray<gqlProfileProp>>;
  /** Job Persuing Type(s) */
  readonly pursuing?: Maybe<ReadonlyArray<gqlPursuing>>;
  /** User's resume document */
  readonly resume?: Maybe<gqlProfileMedia>;
  /** Roster hidden */
  readonly rosterHidden?: Maybe<Scalars['Boolean']>;
  /** Search hidden */
  readonly searchHidden?: Maybe<Scalars['Boolean']>;
  /** SeekingRepresentation Object */
  readonly seekingRepresentations?: Maybe<ReadonlyArray<gqlSeekingRepresentation>>;
  readonly selectionType: gqlProfileSelectionType;
  /** Skills and their Levels */
  readonly skills?: Maybe<ReadonlyArray<gqlProfileSkill>>;
  /** Sleeve Object */
  readonly sleeve?: Maybe<gqlSleeve>;
  /** Sport equipments */
  readonly sportEquipments?: Maybe<ReadonlyArray<gqlProfileSportEquipment>>;
  /** Stage Name */
  readonly stageName: Scalars['String'];
  /** Returns submission */
  readonly submission?: Maybe<gqlPublicSubmission>;
  /** Submission request's media assets */
  readonly submissionRequestMedia: ReadonlyArray<gqlSubmissionMedia>;
  /** talent's contacts regardless representative status */
  readonly talentContactInfo?: Maybe<gqlProfileContactInfo>;
  /** Profile email */
  readonly talentEmail?: Maybe<Scalars['String']>;
  readonly talentFolders?: Maybe<ReadonlyArray<gqlPresentationFolderTalentPresence>>;
  /** Talent unions */
  readonly talentUnions?: Maybe<ReadonlyArray<gqlTalentProfileUnion>>;
  /** Profile tattoo */
  readonly tattoo?: Maybe<gqlTattoo>;
  /** Tattoo Note */
  readonly tattooNote?: Maybe<Scalars['String']>;
  /** Underbust size Object */
  readonly underbust?: Maybe<gqlUnderBust>;
  /** Unique Attribute Type(s) */
  readonly uniqueAttributes?: Maybe<ReadonlyArray<gqlUniqueAttribute>>;
  /** Updated at Date */
  readonly updated?: Maybe<Scalars['DateTime']>;
  /** Profile video assets */
  readonly videos?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  /** Waist Object */
  readonly waist?: Maybe<gqlWaist>;
  /** Weight Object */
  readonly weight?: Maybe<gqlWeight>;
  /** Willingness Objects */
  readonly willingness: ReadonlyArray<gqlWillingness>;
  /** Work permit expiration date */
  readonly workPermitExp?: Maybe<Scalars['DateTime']>;
  /** Working Locations */
  readonly workingLocations?: Maybe<ReadonlyArray<gqlProfileWorkingLocation>>;
};


export type gqlPublicProfileFolderCustomizationArgs = {
  folderId: Scalars['Float'];
};


export type gqlPublicProfileHasAuditionHistoryArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};


export type gqlPublicProfilePhotosArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};


export type gqlPublicProfileSelectionTypeArgs = {
  roleId: Scalars['Float'];
};


export type gqlPublicProfileSubmissionArgs = {
  roleId: Scalars['Float'];
};


export type gqlPublicProfileSubmissionRequestMediaArgs = {
  roleId: Scalars['Float'];
};


export type gqlPublicProfileTalentFoldersArgs = {
  folderId: Scalars['Int'];
};

export type gqlPublicProfileFolderCustomization = {
  readonly __typename?: 'PublicProfileFolderCustomization';
  readonly id: Scalars['Int'];
  readonly isOverscale?: Maybe<Scalars['Boolean']>;
  readonly media: ReadonlyArray<gqlPublicProfileFolderMediaCustomization>;
  readonly note?: Maybe<Scalars['String']>;
  readonly overscaleNote?: Maybe<Scalars['String']>;
};

export type gqlPublicProfileFolderMediaCustomization = {
  readonly __typename?: 'PublicProfileFolderMediaCustomization';
  readonly id: Scalars['Int'];
  readonly isPrimary: Scalars['Boolean'];
  readonly media: gqlMedia;
  readonly mediaId: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly order: Scalars['Int'];
};

/** Public Submission object */
export type gqlPublicSubmission = {
  readonly __typename?: 'PublicSubmission';
  /** Audition Snapshot Media Assets */
  readonly auditionSnapshot?: Maybe<gqlMedia>;
  readonly contactInfo?: Maybe<gqlProfileContactInfo>;
  /** Primary ID */
  readonly id: Scalars['BigInt'];
  /** Media */
  readonly media?: Maybe<ReadonlyArray<gqlSubmissionMedia>>;
  readonly mediaCount?: Maybe<ReadonlyArray<gqlMediaCount>>;
  /** Note */
  readonly note?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<gqlPublicProfile>;
  readonly profileId?: Maybe<Scalars['Int']>;
  /** Profile Working Location ID */
  readonly profileWorkingLocationId?: Maybe<Scalars['Int']>;
  /** Requested Media */
  readonly requestedMedia?: Maybe<ReadonlyArray<gqlSubmissionMedia>>;
  /** Customized Profile Working Location */
  readonly workingLocation?: Maybe<gqlProfileWorkingLocation>;
};

/** Publication Setting object */
export type gqlPublicationSetting = {
  readonly __typename?: 'PublicationSetting';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlPublicationSettingsEnum {
  REPRESENTATIVES = 'REPRESENTATIVES',
  TALENT_ON_THE_CASTING_BILLBOARD = 'TALENT_ON_THE_CASTING_BILLBOARD'
}

export type gqlPurchaseMediaInput = {
  readonly billingInfoId?: InputMaybe<Scalars['String']>;
  readonly couponCode?: InputMaybe<Scalars['String']>;
  readonly mediaIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
};

export type gqlPurchaseSubscriptionInput = {
  readonly billingInfoId?: InputMaybe<Scalars['String']>;
  readonly billingPath?: InputMaybe<Scalars['String']>;
  readonly couponCode?: InputMaybe<Scalars['String']>;
  readonly subscriptionBillingCode: Scalars['String'];
  /** Location in the app where subscription was purchased */
  readonly subscriptionSiteLocation?: InputMaybe<Scalars['String']>;
  readonly threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  readonly trialTermsAccepted?: InputMaybe<Scalars['Boolean']>;
};

/** Pursuing object */
export type gqlPursuing = {
  readonly __typename?: 'Pursuing';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlPursuingEnum {
  ACTING = 'ACTING',
  DANCE = 'DANCE',
  MODELING = 'MODELING',
  STUNT = 'STUNT',
  VOICE_OVER = 'VOICE_OVER'
}

export type gqlQuery = {
  readonly __typename?: 'Query';
  readonly accountStatuses: ReadonlyArray<gqlAccountStatus>;
  readonly accountsIndexable: Scalars['JSON'];
  /** Get all active projects list */
  readonly activeProjectListWithVisibilities: gqlProjectVisibilityResult;
  /** Get all active roles for a Project with optional parameter to filter roles for organization */
  readonly activeProjectRoles: ReadonlyArray<gqlRole>;
  readonly artistByAccount: ReadonlyArray<gqlArtist>;
  readonly artistByAccountWithUnclaimed: ReadonlyArray<gqlArtist>;
  /** Get Artist by BAU Account */
  readonly artistByBauAccount: ReadonlyArray<gqlBauArtist>;
  /** This method is used for getting the amount of total storage and individual media types an artist is using. */
  readonly artistStorageUtilization: gqlArtistStorageUtilization;
  readonly artistSubscriptions: ReadonlyArray<gqlSubscriptionModel>;
  /** Artists by ids */
  readonly artists: ReadonlyArray<gqlArtist>;
  readonly artistsIndexable: Scalars['JSON'];
  readonly askQuestionRequestDetails: gqlAskQuestionRequest;
  readonly askQuestionSubRequestDetails: gqlAskQuestionSubRequest;
  /** Gets single record of Audition Group by provided group id */
  readonly auditionGroup: gqlAuditionGroup;
  /** Gets audition group ids record by profile and session id */
  readonly auditionGroupIdsByProfile?: Maybe<ReadonlyArray<gqlAuditionGroupTalent>>;
  readonly auditionGrouping: gqlAuditionsGroups;
  /** Gets paginated result of Audition Groups by provided session id */
  readonly auditionGroupsBySession: gqlAuditionGroupPaginatedResult;
  /** Gets paginated result of Audition Sessions by provided project id */
  readonly auditionSessionsByProject: gqlAuditionSessionPaginatedResult;
  readonly auditionSources: ReadonlyArray<gqlAuditionSource>;
  readonly auditionTypes: ReadonlyArray<gqlAuditionType>;
  readonly auditions: gqlAuditionPagedListSearch;
  readonly authHealth: Scalars['String'];
  /** Returns true if username and password are matched, otherwise false */
  readonly authenticate: Scalars['Boolean'];
  readonly availableApplications: ReadonlyArray<Scalars['String']>;
  readonly billingPlans: ReadonlyArray<gqlBillingPlan>;
  readonly buildRolesOrganizationMatchNotificationPayloads: gqlRolesPublishedContextNotificationContextAndRecipient;
  readonly busts: ReadonlyArray<gqlBust>;
  /** Projects with Fit For You roles */
  readonly castingBillboardRoles?: Maybe<gqlRoleSearchResult>;
  readonly cdWorksheet: gqlSubmissionPaginatedResult;
  readonly chestSizes: ReadonlyArray<gqlChestSize>;
  readonly coats: ReadonlyArray<gqlCoat>;
  /** Compare a password with hashedPassword */
  readonly comparePassword: Scalars['Boolean'];
  readonly compensation: ReadonlyArray<gqlCompensation>;
  readonly conflicts: ReadonlyArray<gqlConflict>;
  readonly contractStatuses: ReadonlyArray<gqlContractStatus>;
  readonly countSubmissionsPerRole: ReadonlyArray<gqlSubmissionCountPerRole>;
  readonly countries: ReadonlyArray<gqlCountry>;
  readonly countryWithDma: ReadonlyArray<gqlCountry>;
  readonly creditTypes: ReadonlyArray<gqlCreditType>;
  /** Get all the profile's credits in the db */
  readonly credits?: Maybe<ReadonlyArray<gqlCredit>>;
  readonly cupSizes: ReadonlyArray<gqlCupSize>;
  readonly currentUser: gqlBauAccount;
  readonly divisionCastingOrganizations: ReadonlyArray<gqlOrganization>;
  readonly dmas: ReadonlyArray<gqlDma>;
  readonly documentTypes: ReadonlyArray<gqlDocument>;
  readonly dresses: ReadonlyArray<gqlClothesSize>;
  readonly emailHistory: gqlEmailHistoryPaginatedResult;
  readonly ethnicAppearances: ReadonlyArray<gqlEthnicAppearance>;
  readonly eyeColors: ReadonlyArray<gqlEyeColor>;
  readonly facialHairs: ReadonlyArray<gqlFacialHair>;
  readonly featureByType: ReadonlyArray<gqlFeature>;
  readonly features: ReadonlyArray<gqlFeature>;
  readonly genderAppearances: ReadonlyArray<gqlGenderAppearance>;
  readonly genderIdentities: ReadonlyArray<gqlGenderIdentity>;
  readonly generalSizes: ReadonlyArray<gqlGeneralSize>;
  readonly getAccountByIds: ReadonlyArray<gqlAccount>;
  readonly getAccountIds: ReadonlyArray<Scalars['ID']>;
  /** List of sessions */
  readonly getAccountSessions: ReadonlyArray<gqlSession>;
  readonly getAccountSystemRoleCodes: ReadonlyArray<gqlAccountsSystemRoleCodes>;
  readonly getActiveOnboardedPaypalAccounts: ReadonlyArray<gqlAccountActiveOnboardedPaypal>;
  readonly getActiveSubRequestsBySubmissionIds: ReadonlyArray<gqlRequestSubmission>;
  readonly getActivityLogsBySubmissionId: gqlActivityLogPagedList;
  readonly getArtist: gqlArtist;
  readonly getArtistByProfileId: gqlArtist;
  readonly getArtistByProfiles: ReadonlyArray<gqlArtist>;
  /** Audition history of the talent within the company */
  readonly getAuditionHistory: gqlAuditionHistoryPaginatedResult;
  /** Gets single record of Audition Session by provided session id */
  readonly getAuditionSession?: Maybe<gqlAuditionSession>;
  readonly getAuditionTalentPool?: Maybe<gqlAuditionTalentPool>;
  /** This endpoint is specifically designed for FastCapture adapater usage for retrieving the audition talentpool list */
  readonly getAuditionTalentPoolFlat?: Maybe<gqlAuditionTalentPool>;
  /** Returns tuple of min/max available seconds for shifting, e.g. [-3600, 3600] */
  readonly getAvailableScheduleTimeShifting: ReadonlyArray<Scalars['Int']>;
  /** Returns available submission ids for request */
  readonly getAvailableSubmissionIdsForRequest: ReadonlyArray<Scalars['BigInt']>;
  readonly getBauCountries?: Maybe<ReadonlyArray<gqlBauCountry>>;
  readonly getBillingInfo: ReadonlyArray<gqlBillingInfo>;
  /** Gets a paginated list of blocked keywords */
  readonly getBlockedKeywordsPaginatedList: gqlBlockedKeywordPaginatedResult;
  /** Fetch last calendar history change */
  readonly getCalendarLastHistoryChange?: Maybe<gqlScheduleHistory>;
  /** Fetch calendar revision by calendar id and version */
  readonly getCalendarRevision: gqlScheduleDetail;
  readonly getCastingListProfile: gqlGetCastingListProfileResult;
  readonly getCastingListsByProfileAndProject: gqlListGqlModelPagedList;
  readonly getChatMessageByTopic: gqlGetChatMessagesByTopicModel;
  readonly getCollaboratorInviteById: gqlCollaboratorsInvite;
  readonly getCollaboratorsByProjectId: gqlCollaboratorsInvitePaginatedResult;
  readonly getCollaboratorsForOrganization: gqlCollaboratorsInOrganizationPaginatedResult;
  readonly getCommentsForPresentationSubmissionPublic: ReadonlyArray<gqlPresentationSubmissionComment>;
  readonly getCommentsForPresentationSubmissionPublicBySubmissionId: gqlPresentationSubmissionCommentPaginatedResult;
  readonly getCommentsForSubmission: ReadonlyArray<gqlPresentationSubmissionComment>;
  readonly getCommentsForSubmissions: gqlPresentationSubmissionCommentPaginatedResult;
  readonly getCompanyDictionary: gqlOrganizationDictionaryItemPagedList;
  readonly getComplexAccountInfo: gqlAccount;
  readonly getDefaultSavedSearch?: Maybe<gqlSavedSearch>;
  readonly getEnterpriseUserOrganization?: Maybe<gqlUserOrganization>;
  /** Get fit for you values for the specific artist id (Used at Casting Billboard ) */
  readonly getFitForYouValuesForArtist?: Maybe<gqlDefaultFitForYouArtist>;
  readonly getForm: gqlFormGqlModel;
  readonly getFormResponse: gqlFormResponseGqlModel;
  readonly getFormResponsesForMediaRequest: gqlGqlRpcFormBulkResponse;
  readonly getForms: gqlFormGqlModelPagedList;
  readonly getFreeCreditItems: ReadonlyArray<gqlFreeCreditItemModel>;
  readonly getIdByLegacyId: gqlRedirect;
  readonly getInternalNotesByAccountId: gqlNotePaginatedResult;
  readonly getInternalWelcomeContext: gqlInternalWelcomeContextNotificationContextAndRecipient;
  readonly getLanguageByLocale?: Maybe<gqlLanguageInfo>;
  readonly getLanguageLocales: ReadonlyArray<gqlLanguageInfo>;
  readonly getLastActivityLogBySubmissionIds: ReadonlyArray<gqlActivityLog>;
  readonly getLastNote: ReadonlyArray<gqlNote>;
  /** Get account by legacy profileId */
  readonly getLegacyAccountDetails: ReadonlyArray<gqlLegacyAccount>;
  readonly getLists: gqlListGqlModelPagedList;
  /** This method is used for getting the associated media(s) and relevant detail(s) for specific artist and media type. */
  readonly getMediaByArtist: ReadonlyArray<gqlArtistMedia>;
  readonly getMediaMediaTypeById: ReadonlyArray<gqlMediaMediaType>;
  readonly getMessageAfterId: gqlGetChatMessagesByTopicModel;
  /** Get notification settings for specific account */
  readonly getNotificationEntitySubscriptionByAccount?: Maybe<ReadonlyArray<gqlNotificationEntitySubscription>>;
  /** Get notification settings for specific artist */
  readonly getNotificationEntitySubscriptionByArtist?: Maybe<ReadonlyArray<gqlNotificationEntitySubscription>>;
  /** Get notification settings for specific account */
  readonly getNotificationNotificationSubscriptionByAccount?: Maybe<ReadonlyArray<gqlNotificationTypeNotificationSubscription>>;
  /** Get notification settings for specific account */
  readonly getNotificationNotificationSubscriptionByAccounts?: Maybe<ReadonlyArray<gqlNotificationTypeNotificationSubscription>>;
  /** Get notification settings for specific artist */
  readonly getNotificationNotificationSubscriptionByArtist?: Maybe<ReadonlyArray<gqlNotificationTypeNotificationSubscription>>;
  /** Get notification settings for artists */
  readonly getNotificationNotificationSubscriptionByArtists?: Maybe<ReadonlyArray<gqlNotificationTypeNotificationSubscription>>;
  /** Returns paginated result of organization profile lists which logged in user belongs to. */
  readonly getOrganizationProfileLists: gqlProfileListPaginatedResult;
  /** Returns paginated result of organization representative lists which logged in user belongs to. */
  readonly getOrganizationRepresentativeLists: gqlRepresentativeListPaginatedResult;
  /** Paginated query for get organizations by specified representation type and partial/full org name match */
  readonly getOrganizationsByRepTypeAndName: gqlOrganizationsByRepTypeAndNameResultPaginatedResult;
  readonly getPackageFolderTalent: ReadonlyArray<gqlPresentationFolderTalent>;
  readonly getPhone: ReadonlyArray<gqlPhone>;
  readonly getPlanFeatures: ReadonlyArray<gqlPlanFeatureModel>;
  /** Retrieve Presentation by filters */
  readonly getPresentationByFilters: gqlPresentationPublic;
  /** Retrieve Presentation filters Data */
  readonly getPresentationFiltersData: gqlPresentationFilterData;
  /** Query for getting Presentation folder talent(s) by folder ids */
  readonly getPresentationFolderTalent: ReadonlyArray<gqlPresentationFolderTalent>;
  /** Query for getting Presentation folder talent(s) by talent ids */
  readonly getPresentationFolderTalentById: ReadonlyArray<gqlPresentationFolderTalent>;
  /** Gets list of presentation folders for the presentation */
  readonly getPresentationFolders: ReadonlyArray<gqlPresentationFolder>;
  /** Gets presentation folders details */
  readonly getPresentationFoldersDetails: ReadonlyArray<gqlPresentationFolder>;
  /** Retrieve Presentation Group by filters */
  readonly getPresentationGroupByFilters?: Maybe<gqlPresentationGroupPublic>;
  readonly getPresentationGroupPublic?: Maybe<gqlPresentationGroupPublic>;
  readonly getPresentationGroupSubmissions: gqlPresentationGroupSubmissionPaginatedResult;
  /** Query for getting Presentation Groups for the Presentation Folder */
  readonly getPresentationGroupsByFolder: ReadonlyArray<gqlPresentationGroup>;
  readonly getPresentationGroupsSubmissions: gqlPresentationGroupSubmissionPaginatedResult;
  readonly getPresentationGroupsSubmissionsPublic: gqlPresentationTalentPublicPaginatedResult;
  /** Gets info about media which should be archived */
  readonly getPresentationMediaDownloadInfo: ReadonlyArray<gqlMediaToArchive>;
  /** Retrieve Presentation by GUID */
  readonly getPresentationPublic: gqlPresentationPublic;
  /** Retrieve Presentations by IDs */
  readonly getPresentations: ReadonlyArray<gqlPresentation>;
  /** Retrieve Presentations by project */
  readonly getPresentationsByProject: ReadonlyArray<gqlPresentation>;
  /** Get profileAccessToken details */
  readonly getProfileByAccessToken?: Maybe<gqlProfileAccessToken>;
  readonly getProfileByLegacyPersonId?: Maybe<ReadonlyArray<gqlBauProfile>>;
  readonly getProfileDirectMessageContext: gqlProfileDirectMessageContextAndProfile;
  readonly getProfileSearchUnionFilterByRole: ReadonlyArray<gqlProfileUnionFilter>;
  /** Get all the profiles by artist */
  readonly getProfilesByArtist: ReadonlyArray<gqlProfile>;
  /** Get backlot project seach result */
  readonly getProjectBacklot: gqlProjectBacklotSearchResultPaginatedResult;
  /** Get all roles for a Project by role Status */
  readonly getProjectRolesByOrg: ReadonlyArray<gqlRole>;
  /** Project Series */
  readonly getProjectSeriesById: ReadonlyArray<gqlProjectSeries>;
  readonly getPublishedOrganizationIdsForProject: ReadonlyArray<Scalars['Float']>;
  /** Retrieve Presentations for representative organizations */
  readonly getRepPresentations: gqlRepPresentations;
  readonly getReportById?: Maybe<gqlReportUnion>;
  readonly getReportByIdPublic?: Maybe<gqlReportUnion>;
  readonly getReportCsvUrlById?: Maybe<Scalars['String']>;
  readonly getReportCsvUrlByIdPublic?: Maybe<Scalars['String']>;
  readonly getRepresentativeSystemRole: ReadonlyArray<gqlRepresentationSystemRole>;
  /** Get all closed roles for representatives */
  readonly getRepsReadOnlyProjectRoles: ReadonlyArray<gqlRole>;
  /** Get role change log */
  readonly getRoleChangeLogs: ReadonlyArray<gqlRoleChangeLog>;
  readonly getRolesFitForYouNotificationContext: ReadonlyArray<gqlRolesFitForYouContext>;
  readonly getSavedSearchByIds?: Maybe<ReadonlyArray<gqlSavedSearch>>;
  readonly getSavedSearchList?: Maybe<gqlSavedSearchList>;
  readonly getSavedSearchTypes: ReadonlyArray<Scalars['String']>;
  readonly getScheduleReminderContext: gqlScheduleReminderContextNotificationContextAndRecipient;
  /** @deprecated Use getScheduleSlots instead */
  readonly getScheduleSlot?: Maybe<gqlScheduleSlot>;
  readonly getScheduleSlots?: Maybe<ReadonlyArray<gqlScheduleSlot>>;
  /** Fetch Slots summary by scheduleId */
  readonly getScheduleSlotsSummary: ReadonlyArray<gqlScheduleSlotSummary>;
  readonly getScheduleTimeframe: gqlScheduleTimeframe;
  /** Fetch Schedules Details by Ids */
  readonly getSchedules: ReadonlyArray<gqlScheduleDetail>;
  readonly getSlugByProfileId?: Maybe<gqlCustomSlug>;
  readonly getSubRequestsCount: Scalars['Float'];
  /** Get submissions for given role and profile id */
  readonly getSubmissionByRoleProfile: ReadonlyArray<gqlSubmission>;
  readonly getSubmissionFeedback: gqlPresentationSubmissionFeedback;
  readonly getSubmissionIdsByAuditionGroups: ReadonlyArray<Scalars['BigInt']>;
  /** Query for getting Submissions IDs by Presentation Group */
  readonly getSubmissionIdsByPresentationGroupId: ReadonlyArray<Scalars['BigInt']>;
  readonly getSubmissionParticipants: ReadonlyArray<Scalars['String']>;
  /** Get submission profile information for given submission id */
  readonly getSubmissionProfile: gqlSubmissionProfile;
  /** Returns list of submissions for given profileId */
  readonly getSubmissionsByProfileId: ReadonlyArray<gqlSubmission>;
  readonly getSubmissionsByProjectAndProfile: gqlSubmissionPaginatedResult;
  readonly getSubmissionsByRole: gqlSubmissionPaginatedResult;
  /** Returns submissions data for workflow */
  readonly getSubmissionsDataForWorkflow: ReadonlyArray<gqlSubmissionWorkflow>;
  /** Returns submissions data for workflow. (Short) */
  readonly getSubmissionsDataForWorkflowShort: ReadonlyArray<gqlSubmissionWorkflowShort>;
  readonly getTalentAccessProfileContext: gqlTalentAccessProfileContextNotificationContextAndRecipient;
  /** Gets talent pool for the schedule */
  readonly getTalentPoolForSchedule: ReadonlyArray<gqlScheduleTalentPool>;
  readonly getTalentScoutHistory?: Maybe<gqlTalentScoutPageListResult>;
  /** Gets the count for all the profiles for a project. */
  readonly getTotalProfileCount: Scalars['Float'];
  /** Get account id, phone and legacy email for UNCLAIMED profile */
  readonly getUnclaimedProfileRecipientAccount?: Maybe<gqlAccount>;
  /** Get account id, phone and legacy email for UNCLAIMED profile */
  readonly getUnclaimedProfilesRecipientAccount?: Maybe<ReadonlyArray<gqlUnclaimedAccount>>;
  readonly getUnresolvedProhibitedSlugs: gqlPaginatedProhibitedCustomSlug;
  readonly getVerificationEmailContext: gqlBaseEmailContextNotificationContextAndRecipient;
  readonly getWelcomeContext: gqlWelcomeContextNotificationContextAndRecipient;
  readonly gloves: ReadonlyArray<gqlGlove>;
  readonly hairColors: ReadonlyArray<gqlHairColor>;
  readonly hairStyles: ReadonlyArray<gqlHairStyle>;
  readonly hasSavedSearchNotifyOption: Scalars['Boolean'];
  /** Hash a password */
  readonly hashPassword: Scalars['String'];
  readonly hatSizes: ReadonlyArray<gqlHatSize>;
  readonly heights: ReadonlyArray<gqlHeight>;
  readonly hips: ReadonlyArray<gqlHip>;
  readonly inseams: ReadonlyArray<gqlInseam>;
  readonly isArtistEnrolledInBenefit: ReadonlyArray<gqlHasActiveEnrolledBenefit>;
  readonly isPresentationMediaArchiveReady: Scalars['Boolean'];
  readonly lookupBauUsername: Scalars['String'];
  readonly mediaAsset?: Maybe<gqlMedia>;
  readonly mediaAssets: ReadonlyArray<gqlMedia>;
  readonly mediaFileTypes: ReadonlyArray<gqlMediaFileType>;
  readonly mediaRequestDetails: gqlMediaRequest;
  readonly mediaRequestResponseCount: ReadonlyArray<gqlMediaResponseCounts>;
  readonly mediaSubRequestDetails: gqlMediaSubRequest;
  readonly mediaTypes: ReadonlyArray<gqlMediaType>;
  readonly multipleBirths: ReadonlyArray<gqlMultipleBirth>;
  readonly myAccount: gqlAccount;
  readonly necks: ReadonlyArray<gqlNeck>;
  readonly organizationByAccount?: Maybe<ReadonlyArray<gqlOrganizationAccount>>;
  readonly organizationFeature?: Maybe<ReadonlyArray<gqlFeature>>;
  /** Get projects for a organization */
  readonly organizationProjects: gqlProjectBasePaginatedResult;
  /** Get projects groups for a organization */
  readonly organizationProjectsGroups: gqlOrganizationProjectGroupPaginatedResult;
  readonly organizationStatus: ReadonlyArray<gqlOrganizationStatus>;
  /** Return Organization objects from an id array */
  readonly organizations: ReadonlyArray<gqlOrganization>;
  readonly organizationsByRoleSubmissionsAndName: gqlOrganizationsByRoleSubmissionsAndNamePaginatedResult;
  readonly organizationsIndexable: Scalars['JSON'];
  readonly paidTypes: ReadonlyArray<gqlPaidType>;
  readonly pants: ReadonlyArray<gqlPants>;
  /** Returns partial BAU account details by provided legacy login and instance */
  readonly partialBauAccountDetails: gqlPartialBauAccountDetails;
  readonly permissions: ReadonlyArray<gqlPermissionsModel>;
  readonly petSizes: ReadonlyArray<gqlPetSize>;
  readonly pets: ReadonlyArray<gqlPet>;
  readonly piercings: ReadonlyArray<gqlPiercing>;
  readonly preCancel: gqlRetention;
  /** Query for getting Presentation Group details */
  readonly presentationGroupDetails: ReadonlyArray<gqlPresentationGroup>;
  readonly presentationTypes: ReadonlyArray<gqlPresentationType>;
  readonly previewMediaPurchase: gqlMediaInvoice;
  readonly previewSubscriptionPurchase: gqlSubscriptionInvoice;
  readonly previewSubscriptionRenewal: gqlSubscriptionRenewal;
  readonly previousScheduleResponses: ReadonlyArray<gqlScheduleInvitePreviousResponse>;
  /** Get all the profile ids by account */
  readonly profileIdsByAccounts?: Maybe<ReadonlyArray<gqlProfileIdsByAccount>>;
  /** Returns List Details for given list ID */
  readonly profileListDetails: gqlProfileList;
  readonly profileStatuses: ReadonlyArray<gqlProfileStatus>;
  readonly profileTypes: ReadonlyArray<gqlProfileType>;
  /** Get all the profiles in the db */
  readonly profiles?: Maybe<ReadonlyArray<gqlProfile>>;
  readonly profilesIndexable: Scalars['JSON'];
  /** Get project details by id */
  readonly project: gqlProject;
  /** Get Roles for project */
  readonly projectRoles: ReadonlyArray<gqlRole>;
  readonly projectStatus: ReadonlyArray<gqlProjectStatus>;
  readonly projectTypes: ReadonlyArray<gqlProjectType>;
  readonly projectUnions: ReadonlyArray<gqlTalentUnion>;
  /** Get projects by id's */
  readonly projects: ReadonlyArray<gqlProject>;
  /** Get project(s) modification(s) by id's */
  readonly projectsModifications: ReadonlyArray<gqlProject>;
  readonly props: ReadonlyArray<gqlProp>;
  /**
   * Retrieve a public profile. Supports guid argument for backwards compatibility,
   * but prefer pathParam which can be either a guid or slug.
   */
  readonly publicProfile?: Maybe<gqlPublicProfile>;
  readonly publicProjectName?: Maybe<Scalars['String']>;
  readonly publicRoleName?: Maybe<Scalars['String']>;
  readonly publicationHistory: gqlRolePublicationPaginatedResult;
  readonly pursuings: ReadonlyArray<gqlPursuing>;
  readonly repOrganizationList?: Maybe<ReadonlyArray<gqlRepOrganizationList>>;
  readonly representationAgeCategory: ReadonlyArray<gqlRepresentationAgeCategory>;
  readonly representationCategories: ReadonlyArray<gqlRepresentationCategory>;
  readonly representationCategoryByType: ReadonlyArray<gqlRepresentationCategory>;
  readonly representationTypes: ReadonlyArray<gqlRepresentationType>;
  /** Returns Representative List Details for given list ID */
  readonly representativeListDetails: gqlRepresentativeList;
  readonly repsWorksheet: gqlRepsWorksheetItemPagedList;
  readonly repsWorksheetFilters: gqlRepsWorksheetFilters;
  readonly repsWorksheetGroups: gqlRepsWorksheetGroups;
  readonly requests: gqlRequestPagedList;
  /** Get the role from modification by RoleId */
  readonly roleModification?: Maybe<gqlRole>;
  readonly rolePreviousStatus: ReadonlyArray<gqlRolePreviousStatus>;
  readonly roleSidesVisibilities: ReadonlyArray<gqlRoleSidesVisibility>;
  readonly roleTargets: ReadonlyArray<gqlRoleTarget>;
  readonly roleTracker: gqlRoleTrackerPagedList;
  readonly roleTrackerGroups: gqlRoleTrackerGroups;
  readonly roleTrackerStats: gqlRoleTrackerStatsResponse;
  readonly roleTypes: ReadonlyArray<gqlRoleType>;
  readonly roleUnions: ReadonlyArray<gqlRoleUnion>;
  /** Get the roles by id's */
  readonly roles: ReadonlyArray<gqlRole>;
  readonly rolesIndexable: Scalars['JSON'];
  /**
   * Get roster invitation history for provided talent's name and email
   * @deprecated Use rosterTalentInvitationHistory instead
   */
  readonly rosterInvitationTalentHistory: ReadonlyArray<gqlRosterTalentInvitation>;
  readonly rosterInvitations: gqlRosterTalentInvitationPagedList;
  /** Get details for talent invitation by token */
  readonly rosterTalentInvitationByToken: gqlRosterTalentInvitation;
  /** Get roster invitation history for provided talent's name and email */
  readonly rosterTalentInvitationHistory: ReadonlyArray<gqlRosterTalentInvitation>;
  /** Fetch Schedule Details by scheduleId */
  readonly scheduleDetails: gqlScheduleDetail;
  readonly scheduleInviteRequestNotes: gqlScheduleInviteRequestNotes;
  readonly scheduleInviteResponseStatusCounts: ReadonlyArray<gqlScheduleInviteResponseStatusCounts>;
  readonly scheduleInviteSubRequestDetails: gqlScheduleInviteSubRequest;
  readonly scheduleResponses: ReadonlyArray<gqlScheduleInviteResponse>;
  readonly scheduleResponsesCanceled: ReadonlyArray<gqlScheduleInviteResponse>;
  readonly searchAccounts: gqlAccountItemPagedList;
  readonly searchArtistMedia: gqlPaginatedSearchArtistMediaResult;
  readonly searchCompanies: gqlOrganizationItemPagedList;
  readonly searchCompanyDivisions: gqlOrganizationItemPagedList;
  readonly searchMedia: gqlSearchMediaPaginatedResult;
  readonly searchNotifications: gqlNotificationPagedList;
  /** Search in organizations */
  readonly searchOrganizations: gqlOrganizationSearchResult;
  /** Searching Profiles */
  readonly searchProfiles?: Maybe<gqlProfileSearch>;
  readonly searchProfilesByOrgId: gqlProfileSearchResultByOrgIdPaginatedResult;
  /** Project Series */
  readonly searchProjectSeriesByOrg: gqlProjectSeriesPaginatedResult;
  readonly searchProjectsByOrg: gqlProjectPaginatedResult;
  readonly searchRepresentatives: gqlRepresentativeSearchResult;
  /** Searching Schedules */
  readonly searchSchedules?: Maybe<gqlSchedulePaginatedResult>;
  readonly searchSubmissions: gqlSubmissionPaginatedResult;
  readonly searchSubmissionsByAuditionGroupId: gqlSubmissionPaginatedResult;
  readonly seekingRepresentations: ReadonlyArray<gqlSeekingRepresentation>;
  readonly sexualSituations: ReadonlyArray<gqlSexualSituation>;
  readonly shoeModifier: ReadonlyArray<gqlShoeModifier>;
  readonly shoeSizes: ReadonlyArray<gqlShoeSize>;
  readonly sizeModifiers: ReadonlyArray<gqlSizeModifier>;
  readonly skillLevels: ReadonlyArray<gqlSkillLevel>;
  readonly skills: ReadonlyArray<gqlSkill>;
  readonly sleeves: ReadonlyArray<gqlSleeve>;
  /** Fetch Slots with Submissions by scheduleId */
  readonly slotsWithSubmissionsByScheduleId: ReadonlyArray<gqlSlot>;
  readonly sportEquipments: ReadonlyArray<gqlSportEquipment>;
  readonly states: ReadonlyArray<gqlState>;
  readonly submissionStatuses: ReadonlyArray<gqlSubmissionStatus>;
  readonly submissionTrends: gqlSubmissionTrendsResult;
  /** Returns list of submissions for submissionIds */
  readonly submissions: ReadonlyArray<gqlSubmission>;
  readonly submissionsIndexable: Scalars['JSON'];
  readonly systemRoles: ReadonlyArray<gqlSystemRole>;
  /** Returns talent response by rep's request id */
  readonly talentResponse?: Maybe<gqlRepWorksheetResponse>;
  /** Searching Talent Scout Profiles */
  readonly talentScoutSearch?: Maybe<gqlProfileSearch>;
  readonly talentUnions: ReadonlyArray<gqlTalentUnion>;
  readonly tattoos: ReadonlyArray<gqlTattoo>;
  readonly tileSchemaValues: ReadonlyArray<gqlTileSchemaValue>;
  readonly timeZones: ReadonlyArray<gqlTimeZone>;
  readonly tshirtSizes: ReadonlyArray<gqlTShirtSize>;
  readonly underBusts: ReadonlyArray<gqlUnderBust>;
  readonly uniqueAttributes: ReadonlyArray<gqlUniqueAttribute>;
  readonly vehicleMakes: ReadonlyArray<gqlVehicleMake>;
  readonly vehicleTypeMakes: ReadonlyArray<gqlVehicleTypeMake>;
  readonly vehicleTypes: ReadonlyArray<gqlVehicleType>;
  /** Redirect user to Onboarding or B21 application */
  readonly verifyAccount: Scalars['Json'];
  readonly verifyAccountByBauCookie: Scalars['Json'];
  readonly verifyToken: Scalars['Boolean'];
  readonly voiceOverMediaRequests: ReadonlyArray<gqlMediaSubRequest>;
  readonly waists: ReadonlyArray<gqlWaist>;
  readonly wardrobes: ReadonlyArray<gqlWardrobe>;
  readonly weights: ReadonlyArray<gqlWeight>;
  readonly wfApiHealth: Scalars['String'];
  readonly willingnesses: ReadonlyArray<gqlWillingness>;
};


export type gqlQueryAccountStatusesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryAccountsIndexableArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryActiveProjectListWithVisibilitiesArgs = {
  filters?: InputMaybe<gqlProjectFilterInput>;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryActiveProjectRolesArgs = {
  id: Scalars['Float'];
};


export type gqlQueryArtistByAccountArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryArtistByAccountWithUnclaimedArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryArtistStorageUtilizationArgs = {
  artistId?: InputMaybe<Scalars['Int']>;
};


export type gqlQueryArtistSubscriptionsArgs = {
  input: gqlArtistSubscriptionInput;
};


export type gqlQueryArtistsArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryArtistsIndexableArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryAskQuestionRequestDetailsArgs = {
  id: Scalars['ID'];
};


export type gqlQueryAskQuestionSubRequestDetailsArgs = {
  id: Scalars['ID'];
};


export type gqlQueryAuditionGroupArgs = {
  groupId: Scalars['Int'];
};


export type gqlQueryAuditionGroupIdsByProfileArgs = {
  profileIds: ReadonlyArray<Scalars['Int']>;
  sessionId: Scalars['Int'];
};


export type gqlQueryAuditionGroupingArgs = {
  groupBy: gqlAuditionsGroupBy;
  searchPage?: InputMaybe<gqlSearchPageGroupInput>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlAuditionsSearchSortOptionsInput>>;
};


export type gqlQueryAuditionGroupsBySessionArgs = {
  pageInput?: InputMaybe<gqlPageInput>;
  sessionId: Scalars['Int'];
};


export type gqlQueryAuditionSessionsByProjectArgs = {
  filter?: InputMaybe<gqlAuditionSessionFilterInput>;
  pageInput?: InputMaybe<gqlPageInput>;
  projectId: ReadonlyArray<Scalars['Int']>;
  sortInstruction?: InputMaybe<gqlAuditionSessionSortInstructionInput>;
};


export type gqlQueryAuditionSourcesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryAuditionTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryAuditionsArgs = {
  auditionsSearchFilter?: InputMaybe<gqlAuditionsFilterInput>;
  searchPage?: InputMaybe<gqlSearchPageInputWf>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlAuditionsSearchSortOptionsInput>>;
};


export type gqlQueryAuthenticateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type gqlQueryBillingPlansArgs = {
  input?: InputMaybe<gqlBillingPlansInput>;
};


export type gqlQueryBuildRolesOrganizationMatchNotificationPayloadsArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  projectId: Scalars['Int'];
  publicationTimestamp: Scalars['DateTime'];
  roleIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryBustsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryCastingBillboardRolesArgs = {
  castingBillBoardFilters: gqlCastingBillboardSearchInput;
  paginationConfig: gqlPageInput;
  sortInput?: InputMaybe<gqlSortConditionInput>;
};


export type gqlQueryCdWorksheetArgs = {
  filters: gqlCdWorksheetFilterInput;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryChestSizesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryCoatsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryComparePasswordArgs = {
  hashedPassword: Scalars['String'];
  password: Scalars['String'];
};


export type gqlQueryCompensationArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryConflictsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryContractStatusesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryCountSubmissionsPerRoleArgs = {
  input: gqlSubmissionCountPerRoleInput;
};


export type gqlQueryCountriesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryCountryWithDmaArgs = {
  isLimitedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type gqlQueryCreditTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryCreditsArgs = {
  profileId: Scalars['String'];
};


export type gqlQueryCupSizesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryDivisionCastingOrganizationsArgs = {
  organizationId: Scalars['Int'];
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryDmasArgs = {
  input?: InputMaybe<gqlFilterInput>;
  isLimitedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type gqlQueryDocumentTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
  onlySelectable?: InputMaybe<Scalars['Boolean']>;
};


export type gqlQueryDressesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryEmailHistoryArgs = {
  page: gqlPageInput;
  projectId: Scalars['Int'];
};


export type gqlQueryEthnicAppearancesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryEyeColorsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryFacialHairsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryFeatureByTypeArgs = {
  divisionOnly?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<Scalars['Float']>;
};


export type gqlQueryFeaturesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryGenderAppearancesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryGenderIdentitiesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryGeneralSizesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryGetAccountByIdsArgs = {
  accountIds: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryGetAccountIdsArgs = {
  page: gqlPageInput;
};


export type gqlQueryGetAccountSessionsArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryGetAccountSystemRoleCodesArgs = {
  accountIds: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryGetActiveOnboardedPaypalAccountsArgs = {
  input: gqlAccountPaypalLocationInput;
};


export type gqlQueryGetActiveSubRequestsBySubmissionIdsArgs = {
  requestStatus?: InputMaybe<ReadonlyArray<gqlRequestStatusUnion>>;
  requestType?: InputMaybe<gqlRequestType>;
  submissionIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetActivityLogsBySubmissionIdArgs = {
  id: Scalars['Int'];
  page: gqlPageInput;
};


export type gqlQueryGetArtistArgs = {
  artistId: Scalars['Int'];
};


export type gqlQueryGetArtistByProfileIdArgs = {
  profileId: Scalars['Int'];
};


export type gqlQueryGetArtistByProfilesArgs = {
  profileIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetAuditionHistoryArgs = {
  page: gqlPageInput;
  profileId: Scalars['Int'];
};


export type gqlQueryGetAuditionSessionArgs = {
  sessionId: Scalars['Int'];
};


export type gqlQueryGetAuditionTalentPoolArgs = {
  sessionId: Scalars['Int'];
};


export type gqlQueryGetAuditionTalentPoolFlatArgs = {
  sessionId: Scalars['Int'];
};


export type gqlQueryGetAvailableScheduleTimeShiftingArgs = {
  scheduleId: Scalars['Int'];
  timeframeId: Scalars['Int'];
};


export type gqlQueryGetAvailableSubmissionIdsForRequestArgs = {
  roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  searchInput?: InputMaybe<gqlSubmissionSearchInput>;
  tileSchemeValueCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlQueryGetBillingInfoArgs = {
  input: gqlGetBillingInfoInput;
};


export type gqlQueryGetBlockedKeywordsPaginatedListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type gqlQueryGetCalendarLastHistoryChangeArgs = {
  calendarId: Scalars['ID'];
};


export type gqlQueryGetCalendarRevisionArgs = {
  calendarId: Scalars['ID'];
  version: Scalars['Int'];
};


export type gqlQueryGetCastingListProfileArgs = {
  input: gqlGetCastingListProfileArgs;
};


export type gqlQueryGetCastingListsByProfileAndProjectArgs = {
  pageNumber?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  profileId: Scalars['ID'];
  projectId: Scalars['Int'];
};


export type gqlQueryGetChatMessageByTopicArgs = {
  pageSize: Scalars['Int'];
  topicKey: Scalars['String'];
};


export type gqlQueryGetCollaboratorInviteByIdArgs = {
  input: gqlGetCollaboratorInviteInput;
};


export type gqlQueryGetCollaboratorsByProjectIdArgs = {
  page?: InputMaybe<gqlPageInput>;
  projectId?: InputMaybe<Scalars['ID']>;
};


export type gqlQueryGetCollaboratorsForOrganizationArgs = {
  input?: InputMaybe<gqlGetCollaboratorByEmailInput>;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryGetCommentsForPresentationSubmissionPublicArgs = {
  groupId: Scalars['Int'];
  guid: Scalars['String'];
  submissionId: Scalars['BigInt'];
  userName?: InputMaybe<Scalars['String']>;
};


export type gqlQueryGetCommentsForPresentationSubmissionPublicBySubmissionIdArgs = {
  page: gqlPageInput;
  presentationId: Scalars['Int'];
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
  userName?: InputMaybe<Scalars['String']>;
};


export type gqlQueryGetCommentsForSubmissionArgs = {
  submissionId: Scalars['BigInt'];
};


export type gqlQueryGetCommentsForSubmissionsArgs = {
  page?: InputMaybe<gqlPageInput>;
  submissionIds: ReadonlyArray<Scalars['BigInt']>;
  userName?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlQueryGetCompanyDictionaryArgs = {
  filters?: InputMaybe<gqlSearchOrganizationsFiltersArgs>;
  page: gqlPageInput;
};


export type gqlQueryGetComplexAccountInfoArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryGetFitForYouValuesForArtistArgs = {
  artistId?: InputMaybe<Scalars['Float']>;
};


export type gqlQueryGetFormArgs = {
  formId: Scalars['Float'];
};


export type gqlQueryGetFormResponseArgs = {
  formId: Scalars['Float'];
  formResponseId?: InputMaybe<Scalars['Float']>;
};


export type gqlQueryGetFormResponsesForMediaRequestArgs = {
  formId: Scalars['Float'];
  mediaRequestId: Scalars['String'];
};


export type gqlQueryGetFormsArgs = {
  filter?: InputMaybe<gqlFormsFilterArgs>;
  pageInput?: InputMaybe<gqlPageInput>;
  sortInstruction?: InputMaybe<gqlFormSortConditionInput>;
};


export type gqlQueryGetFreeCreditItemsArgs = {
  artistId: Scalars['Float'];
};


export type gqlQueryGetIdByLegacyIdArgs = {
  input: gqlRedirectInput;
};


export type gqlQueryGetInternalNotesByAccountIdArgs = {
  accountId: Scalars['Int'];
  entityTypeCode?: InputMaybe<ReadonlyArray<gqlEntityTypeEnum>>;
  noteTypeCode?: InputMaybe<gqlNoteTypeEnum>;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryGetInternalWelcomeContextArgs = {
  id: Scalars['Float'];
};


export type gqlQueryGetLanguageByLocaleArgs = {
  locale: Scalars['String'];
};


export type gqlQueryGetLastActivityLogBySubmissionIdsArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetLastNoteArgs = {
  entityIds: ReadonlyArray<Scalars['BigInt']>;
  entityTypeCode: gqlEntityTypeEnum;
  noteTypeCode?: InputMaybe<gqlNoteTypeEnum>;
  organizationId?: InputMaybe<Scalars['Int']>;
};


export type gqlQueryGetLegacyAccountDetailsArgs = {
  profileId: Scalars['Int'];
};


export type gqlQueryGetListsArgs = {
  filter?: gqlCastingListFilterInput;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
};


export type gqlQueryGetMediaByArtistArgs = {
  artistId?: InputMaybe<Scalars['Float']>;
  mediaTypeCode: Scalars['String'];
};


export type gqlQueryGetMediaMediaTypeByIdArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetMessageAfterIdArgs = {
  afterMessageId: Scalars['String'];
  direction: gqlSearchDirection;
  pageSize: Scalars['Int'];
  topicKey: Scalars['String'];
};


export type gqlQueryGetNotificationEntitySubscriptionByAccountArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryGetNotificationEntitySubscriptionByArtistArgs = {
  artistId: Scalars['Float'];
};


export type gqlQueryGetNotificationNotificationSubscriptionByAccountArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryGetNotificationNotificationSubscriptionByAccountsArgs = {
  accountIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetNotificationNotificationSubscriptionByArtistArgs = {
  artistId: Scalars['Float'];
};


export type gqlQueryGetNotificationNotificationSubscriptionByArtistsArgs = {
  artistIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetOrganizationProfileListsArgs = {
  order?: InputMaybe<gqlListSortOrderEnum>;
  page: gqlPageInput;
};


export type gqlQueryGetOrganizationRepresentativeListsArgs = {
  input?: InputMaybe<gqlGetOrganizationRepresentativeListsInput>;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryGetOrganizationsByRepTypeAndNameArgs = {
  input: gqlOrganizationsByRepTypeAndNameInput;
};


export type gqlQueryGetPackageFolderTalentArgs = {
  folderId: Scalars['Int'];
  pageInput?: InputMaybe<gqlPageInput>;
  profileIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlQueryGetPhoneArgs = {
  entityType: gqlEntityTypeEnum;
  phoneNumber: Scalars['String'];
};


export type gqlQueryGetPlanFeaturesArgs = {
  planId: Scalars['ID'];
};


export type gqlQueryGetPresentationByFiltersArgs = {
  input: gqlPresentationFilterInput;
};


export type gqlQueryGetPresentationFiltersDataArgs = {
  presentationId: Scalars['Int'];
};


export type gqlQueryGetPresentationFolderTalentArgs = {
  pageInput?: InputMaybe<gqlPageInput>;
  presentationFolderIds: ReadonlyArray<Scalars['Int']>;
  profileIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlQueryGetPresentationFolderTalentByIdArgs = {
  groupTalentIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetPresentationFoldersArgs = {
  presentationId: Scalars['Int'];
};


export type gqlQueryGetPresentationFoldersDetailsArgs = {
  folderIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetPresentationGroupByFiltersArgs = {
  groupId: Scalars['Int'];
  guid: Scalars['String'];
  input: gqlPresentationFilterInput;
};


export type gqlQueryGetPresentationGroupPublicArgs = {
  groupId: Scalars['Int'];
  guid: Scalars['String'];
};


export type gqlQueryGetPresentationGroupSubmissionsArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  page: gqlPageInput;
  presentationGroupId: Scalars['Int'];
};


export type gqlQueryGetPresentationGroupsByFolderArgs = {
  folderId: Scalars['Int'];
  hasPicks?: InputMaybe<Scalars['Boolean']>;
};


export type gqlQueryGetPresentationGroupsSubmissionsArgs = {
  clientNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  page: gqlPageInput;
  presentationGroupIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetPresentationGroupsSubmissionsPublicArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  page: gqlPageInput;
  presentationGroupIds: ReadonlyArray<Scalars['Int']>;
  presentationId: Scalars['Int'];
  userName?: InputMaybe<Scalars['String']>;
};


export type gqlQueryGetPresentationMediaDownloadInfoArgs = {
  folderId: Scalars['Int'];
  groupId?: InputMaybe<Scalars['Int']>;
};


export type gqlQueryGetPresentationPublicArgs = {
  guid: Scalars['String'];
};


export type gqlQueryGetPresentationsArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetPresentationsByProjectArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};


export type gqlQueryGetProfileByAccessTokenArgs = {
  accessToken: Scalars['String'];
};


export type gqlQueryGetProfileDirectMessageContextArgs = {
  input: gqlProfileDirectMessageContextInput;
};


export type gqlQueryGetProfileSearchUnionFilterByRoleArgs = {
  roleIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetProfilesByArtistArgs = {
  artistId: Scalars['Int'];
};


export type gqlQueryGetProjectBacklotArgs = {
  input: gqlProjectBacklotSearchInput;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryGetProjectRolesByOrgArgs = {
  orgId: Scalars['Float'];
  projectId: Scalars['Float'];
  statusFilter: ReadonlyArray<Scalars['String']>;
};


export type gqlQueryGetProjectSeriesByIdArgs = {
  input: gqlGetProjectSeriesByIdInput;
};


export type gqlQueryGetPublishedOrganizationIdsForProjectArgs = {
  page: gqlPageInput;
  projectId: Scalars['Float'];
};


export type gqlQueryGetRepPresentationsArgs = {
  page?: InputMaybe<gqlPageInput>;
  sortOrder?: InputMaybe<gqlSortOrderEnum>;
};


export type gqlQueryGetReportByIdArgs = {
  id: Scalars['ID'];
};


export type gqlQueryGetReportByIdPublicArgs = {
  id: Scalars['ID'];
  publicKey: Scalars['ID'];
};


export type gqlQueryGetReportCsvUrlByIdArgs = {
  id: Scalars['ID'];
};


export type gqlQueryGetReportCsvUrlByIdPublicArgs = {
  id: Scalars['ID'];
  publicKey: Scalars['ID'];
};


export type gqlQueryGetRepsReadOnlyProjectRolesArgs = {
  id: Scalars['Float'];
};


export type gqlQueryGetRoleChangeLogsArgs = {
  projectId: Scalars['Float'];
};


export type gqlQueryGetRolesFitForYouNotificationContextArgs = {
  artistAndRoles: ReadonlyArray<gqlFfyContextInput>;
  messageToTalents?: InputMaybe<Scalars['String']>;
};


export type gqlQueryGetSavedSearchByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryGetSavedSearchListArgs = {
  keyOffset?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type gqlQueryGetScheduleReminderContextArgs = {
  calendarId: Scalars['Int'];
  calendarSlotId: Scalars['Int'];
  calendarTypeId: Scalars['Int'];
  submissionId: Scalars['Int'];
};


export type gqlQueryGetScheduleSlotArgs = {
  id: Scalars['ID'];
};


export type gqlQueryGetScheduleSlotsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryGetScheduleSlotsSummaryArgs = {
  scheduleId: Scalars['ID'];
};


export type gqlQueryGetScheduleTimeframeArgs = {
  timeframeId: Scalars['Int'];
};


export type gqlQueryGetSchedulesArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetSlugByProfileIdArgs = {
  includeExpired?: InputMaybe<Scalars['Boolean']>;
  profileId: Scalars['Int'];
};


export type gqlQueryGetSubRequestsCountArgs = {
  id: Scalars['ID'];
  status?: InputMaybe<ReadonlyArray<gqlRequestStatus>>;
};


export type gqlQueryGetSubmissionByRoleProfileArgs = {
  input: gqlGetSubmissionByRoleProfileInput;
};


export type gqlQueryGetSubmissionFeedbackArgs = {
  submissionId: Scalars['BigInt'];
};


export type gqlQueryGetSubmissionIdsByAuditionGroupsArgs = {
  groupIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetSubmissionIdsByPresentationGroupIdArgs = {
  groupId: Scalars['Int'];
};


export type gqlQueryGetSubmissionParticipantsArgs = {
  roleId: Scalars['Float'];
};


export type gqlQueryGetSubmissionProfileArgs = {
  id: Scalars['BigInt'];
};


export type gqlQueryGetSubmissionsByProfileIdArgs = {
  input?: InputMaybe<gqlSubmissionByProfileInput>;
  profileId: Scalars['Float'];
};


export type gqlQueryGetSubmissionsByProjectAndProfileArgs = {
  pageInput?: InputMaybe<gqlPageInput>;
  profileIds: ReadonlyArray<Scalars['Int']>;
  projectIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetSubmissionsByRoleArgs = {
  pageInput?: InputMaybe<gqlPageInput>;
  roleIds: ReadonlyArray<Scalars['Int']>;
  submissionStatusCodes: ReadonlyArray<Scalars['String']>;
  tileSchemeCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlQueryGetSubmissionsDataForWorkflowArgs = {
  ids: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlQueryGetSubmissionsDataForWorkflowShortArgs = {
  ids: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlQueryGetTalentAccessProfileContextArgs = {
  input: gqlTalentAccessProfileContextInput;
};


export type gqlQueryGetTalentPoolForScheduleArgs = {
  mediaRequestFilter?: InputMaybe<gqlMediaRequestFilterType>;
  pageInput: gqlPageInput;
  roleIds: ReadonlyArray<Scalars['Int']>;
  scheduleId: Scalars['Int'];
  scheduleSelectInput?: InputMaybe<gqlSchedulesSelectInput>;
  tileSchemaValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


export type gqlQueryGetTalentScoutHistoryArgs = {
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryGetTotalProfileCountArgs = {
  id: Scalars['Float'];
};


export type gqlQueryGetUnclaimedProfileRecipientAccountArgs = {
  profileId: Scalars['Float'];
};


export type gqlQueryGetUnclaimedProfilesRecipientAccountArgs = {
  profileIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryGetVerificationEmailContextArgs = {
  audience?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type gqlQueryGetWelcomeContextArgs = {
  id: Scalars['Float'];
};


export type gqlQueryGlovesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryHairColorsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryHairStylesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryHasSavedSearchNotifyOptionArgs = {
  artistId: Scalars['ID'];
};


export type gqlQueryHashPasswordArgs = {
  password: Scalars['String'];
};


export type gqlQueryHatSizesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryHeightsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryHipsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryInseamsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryIsArtistEnrolledInBenefitArgs = {
  input: gqlHasActiveEnrolledBenefitInput;
};


export type gqlQueryIsPresentationMediaArchiveReadyArgs = {
  fileKey: Scalars['String'];
};


export type gqlQueryLookupBauUsernameArgs = {
  legacyEmail: Scalars['String'];
  legacyInstance: Scalars['String'];
  legacyPhone: Scalars['String'];
};


export type gqlQueryMediaAssetArgs = {
  id: Scalars['Int'];
};


export type gqlQueryMediaAssetsArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryMediaFileTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryMediaRequestDetailsArgs = {
  id: Scalars['ID'];
};


export type gqlQueryMediaRequestResponseCountArgs = {
  id: Scalars['ID'];
};


export type gqlQueryMediaSubRequestDetailsArgs = {
  id: Scalars['ID'];
};


export type gqlQueryMediaTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryMultipleBirthsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryNecksArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryOrganizationByAccountArgs = {
  accountId: Scalars['Float'];
};


export type gqlQueryOrganizationFeatureArgs = {
  organizationId: Scalars['Float'];
};


export type gqlQueryOrganizationProjectsArgs = {
  filter?: InputMaybe<gqlOrganizationProjectFilterInput>;
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  page?: InputMaybe<gqlPageInput>;
  sortInstruction?: InputMaybe<gqlSortConditionInput>;
  statusFilter?: InputMaybe<gqlProjectStatusFilterEnum>;
};


export type gqlQueryOrganizationProjectsGroupsArgs = {
  filter?: InputMaybe<gqlOrganizationProjectFilterInput>;
  page?: InputMaybe<gqlPageInput>;
  projectGroupType: gqlProjectGroupType;
};


export type gqlQueryOrganizationStatusArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryOrganizationsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryOrganizationsByRoleSubmissionsAndNameArgs = {
  divisionOrAgencyName?: InputMaybe<Scalars['String']>;
  page: gqlPageInput;
  roleId: Scalars['Int'];
};


export type gqlQueryOrganizationsIndexableArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryPaidTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryPantsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryPartialBauAccountDetailsArgs = {
  input: gqlPartialBauAccountDetailsInput;
};


export type gqlQueryPetSizesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryPetsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryPiercingsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryPreCancelArgs = {
  input: gqlPreCancelInput;
};


export type gqlQueryPresentationGroupDetailsArgs = {
  groupIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryPresentationTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryPreviewMediaPurchaseArgs = {
  input: gqlPurchaseMediaInput;
};


export type gqlQueryPreviewSubscriptionPurchaseArgs = {
  input: gqlPreviewSubscriptionInput;
};


export type gqlQueryPreviewSubscriptionRenewalArgs = {
  input: gqlPreviewSubscriptionRenewalInput;
};


export type gqlQueryPreviousScheduleResponsesArgs = {
  scheduleId: Scalars['Int'];
  submissionIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryProfileIdsByAccountsArgs = {
  accountIds: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryProfileListDetailsArgs = {
  listId: Scalars['Float'];
};


export type gqlQueryProfileStatusesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryProfileTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryProfilesArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryProfilesIndexableArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryProjectArgs = {
  id: Scalars['Float'];
};


export type gqlQueryProjectRolesArgs = {
  id: Scalars['Float'];
  page?: InputMaybe<gqlPageInput>;
  roleStatusCodes?: InputMaybe<ReadonlyArray<gqlRoleStatusCodes>>;
  roleStatusFilter?: InputMaybe<gqlRoleStatusFilter>;
};


export type gqlQueryProjectStatusArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryProjectTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
  onlySelectable?: InputMaybe<Scalars['Boolean']>;
};


export type gqlQueryProjectUnionsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryProjectsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryProjectsModificationsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryPropsArgs = {
  input?: InputMaybe<gqlFilterInput>;
  onlySelectable?: InputMaybe<Scalars['Boolean']>;
};


export type gqlQueryPublicProfileArgs = {
  guid?: InputMaybe<Scalars['String']>;
  pathParam?: InputMaybe<Scalars['String']>;
};


export type gqlQueryPublicProjectNameArgs = {
  guid: Scalars['String'];
};


export type gqlQueryPublicRoleNameArgs = {
  guid: Scalars['String'];
};


export type gqlQueryPublicationHistoryArgs = {
  page: gqlPageInput;
  projectId: Scalars['Int'];
};


export type gqlQueryPursuingsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRepOrganizationListArgs = {
  input: gqlRepOrganizationListInput;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQueryRepresentationAgeCategoryArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRepresentationCategoriesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRepresentationCategoryByTypeArgs = {
  typeId: Scalars['Float'];
};


export type gqlQueryRepresentationTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRepresentativeListDetailsArgs = {
  listId: Scalars['Float'];
};


export type gqlQueryRepsWorksheetArgs = {
  filters?: InputMaybe<gqlRepsWorksheetFiltersArgs>;
  page: gqlPageInput;
  sortBy?: InputMaybe<gqlRepWorksheetFieldOrder>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlRepWorksheetOrderOption>>;
};


export type gqlQueryRepsWorksheetGroupsArgs = {
  filters?: InputMaybe<gqlRepsWorksheetFiltersArgs>;
  groupId?: InputMaybe<Scalars['ID']>;
  groupType: gqlRepsWorksheetGroupType;
  limit: Scalars['Int'];
  sortOrder?: InputMaybe<gqlSortOrder>;
};


export type gqlQueryRequestsArgs = {
  page: gqlPageInput;
  projectId: Scalars['Int'];
};


export type gqlQueryRoleModificationArgs = {
  roleId: Scalars['Float'];
};


export type gqlQueryRolePreviousStatusArgs = {
  roleIds: ReadonlyArray<Scalars['Float']>;
  skipRoleStatuses: ReadonlyArray<Scalars['String']>;
};


export type gqlQueryRoleSidesVisibilitiesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRoleTargetsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRoleTrackerArgs = {
  filters?: InputMaybe<gqlRoleTrackerFiltersArgs>;
  page?: InputMaybe<gqlPageInput>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlRoleTrackerOrderOption>>;
};


export type gqlQueryRoleTrackerGroupsArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
  groupType: gqlRoleTrackerGroupType;
  limit: Scalars['Int'];
  sortOrder?: InputMaybe<gqlSortOrder>;
};


export type gqlQueryRoleTrackerStatsArgs = {
  interval?: InputMaybe<Scalars['Int']>;
  intervalType?: InputMaybe<gqlRoleTrackerStatsIntervalEnum>;
  timeframe?: InputMaybe<Scalars['Int']>;
};


export type gqlQueryRoleTypesArgs = {
  input?: InputMaybe<gqlRoleTypeFilterInput>;
};


export type gqlQueryRoleUnionsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryRolesArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type gqlQueryRolesIndexableArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryRosterInvitationTalentHistoryArgs = {
  filter: gqlRosterInvitationTalentHistoryFilterInput;
};


export type gqlQueryRosterInvitationsArgs = {
  page: gqlPageInput;
};


export type gqlQueryRosterTalentInvitationByTokenArgs = {
  token: Scalars['String'];
};


export type gqlQueryRosterTalentInvitationHistoryArgs = {
  filter: ReadonlyArray<gqlRosterInvitationTalentHistoryFilterInput>;
};


export type gqlQueryScheduleDetailsArgs = {
  id: Scalars['ID'];
};


export type gqlQueryScheduleInviteRequestNotesArgs = {
  scheduleId: Scalars['Int'];
};


export type gqlQueryScheduleInviteResponseStatusCountsArgs = {
  scheduleIds: ReadonlyArray<Scalars['Int']>;
};


export type gqlQueryScheduleInviteSubRequestDetailsArgs = {
  id: Scalars['ID'];
};


export type gqlQueryScheduleResponsesArgs = {
  scheduleId: Scalars['Int'];
};


export type gqlQueryScheduleResponsesCanceledArgs = {
  scheduleId: Scalars['Int'];
};


export type gqlQuerySearchAccountsArgs = {
  filters?: InputMaybe<gqlSearchAccountsFiltersArgs>;
  page: gqlPageInput;
};


export type gqlQuerySearchArtistMediaArgs = {
  input: gqlSearchArtistMediaInput;
};


export type gqlQuerySearchCompaniesArgs = {
  filters?: InputMaybe<gqlSearchOrganizationsFiltersArgs>;
  page: gqlPageInput;
};


export type gqlQuerySearchCompanyDivisionsArgs = {
  filters?: InputMaybe<gqlSearchOrganizationsFiltersArgs>;
  page: gqlPageInput;
};


export type gqlQuerySearchMediaArgs = {
  input: gqlSearchMediaInput;
};


export type gqlQuerySearchNotificationsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<gqlSearchNotificationFiltersArgs>;
  orderBy?: InputMaybe<gqlNotificationOrderByInput>;
  page: gqlPageInput;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type gqlQuerySearchOrganizationsArgs = {
  input?: InputMaybe<gqlOrganizationSearchInput>;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQuerySearchProfilesArgs = {
  input: gqlProfileSearchInput;
  page?: InputMaybe<gqlPageInput>;
  resultType?: InputMaybe<gqlSearchResultType>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlProfileSearchSortOption>>;
};


export type gqlQuerySearchProfilesByOrgIdArgs = {
  page?: InputMaybe<gqlPageInput>;
  partialStageName?: InputMaybe<Scalars['String']>;
};


export type gqlQuerySearchProjectSeriesByOrgArgs = {
  input: gqlGetProjectSeriesByNameInput;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQuerySearchProjectsByOrgArgs = {
  page?: InputMaybe<gqlPageInput>;
  partialProjectName?: InputMaybe<Scalars['String']>;
};


export type gqlQuerySearchRepresentativesArgs = {
  input: gqlSearchRepresentativeInput;
  page: gqlPageInput;
};


export type gqlQuerySearchSchedulesArgs = {
  input: gqlScheduleSearchInput;
  page: gqlPageInput;
};


export type gqlQuerySearchSubmissionsArgs = {
  input: gqlSubmissionSearchInput;
  page?: InputMaybe<gqlPageInput>;
  resultType?: InputMaybe<gqlSearchResultType>;
  searchPage?: InputMaybe<gqlSearchPageInput>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlSubmissionSearchSortOptionsInput>>;
};


export type gqlQuerySearchSubmissionsByAuditionGroupIdArgs = {
  auditionGroupId: Scalars['Int'];
  page: gqlPageInput;
};


export type gqlQuerySeekingRepresentationsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQuerySexualSituationsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryShoeModifierArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryShoeSizesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQuerySizeModifiersArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQuerySkillLevelsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQuerySkillsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQuerySleevesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQuerySlotsWithSubmissionsByScheduleIdArgs = {
  scheduleId: Scalars['ID'];
};


export type gqlQuerySportEquipmentsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryStatesArgs = {
  input?: InputMaybe<gqlStateFilterInput>;
  page?: InputMaybe<gqlPageInput>;
};


export type gqlQuerySubmissionStatusesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQuerySubmissionTrendsArgs = {
  input: gqlSubmissionTrendsInput;
};


export type gqlQuerySubmissionsArgs = {
  ids: ReadonlyArray<Scalars['BigInt']>;
};


export type gqlQuerySubmissionsIndexableArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};


export type gqlQuerySystemRolesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryTalentResponseArgs = {
  id: Scalars['ID'];
};


export type gqlQueryTalentScoutSearchArgs = {
  input: gqlProfileSearchInput;
  page?: InputMaybe<gqlPageInput>;
  resultType?: InputMaybe<gqlSearchResultType>;
  sortOptions?: InputMaybe<ReadonlyArray<gqlProfileSearchSortOption>>;
};


export type gqlQueryTalentUnionsArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryTattoosArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryTileSchemaValuesArgs = {
  input?: InputMaybe<gqlFilterInput>;
  tileSchema?: InputMaybe<gqlTileSchemaEnum>;
};


export type gqlQueryTimeZonesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryTshirtSizesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryUnderBustsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryUniqueAttributesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryVehicleMakesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryVehicleTypesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryVerifyAccountArgs = {
  input: gqlVerifyAccountInput;
};


export type gqlQueryVerifyAccountByBauCookieArgs = {
  input: gqlVerifyAccountByCookieInput;
};


export type gqlQueryVerifyTokenArgs = {
  authToken: Scalars['String'];
};


export type gqlQueryVoiceOverMediaRequestsArgs = {
  roleId: Scalars['Int'];
  submissionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


export type gqlQueryWaistsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryWardrobesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};


export type gqlQueryWeightsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


export type gqlQueryWillingnessesArgs = {
  input?: InputMaybe<gqlFilterInput>;
};

export enum gqlRatePerEnum {
  FLAT_FEE = 'FLAT_FEE',
  PER_2_HOURS = 'PER_2_HOURS',
  PER_3_HOURS = 'PER_3_HOURS',
  PER_4_HOURS = 'PER_4_HOURS',
  PER_5_HOURS = 'PER_5_HOURS',
  PER_6_HOURS = 'PER_6_HOURS',
  PER_7_HOURS = 'PER_7_HOURS',
  PER_8_HOURS = 'PER_8_HOURS',
  PER_9_HOURS = 'PER_9_HOURS',
  PER_10_HOURS = 'PER_10_HOURS',
  PER_DAY = 'PER_DAY',
  PER_HOUR = 'PER_HOUR',
  PER_WEEK = 'PER_WEEK',
  SESSION_FEE = 'SESSION_FEE'
}

/** RatePerType object */
export type gqlRatePerType = {
  readonly __typename?: 'RatePerType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Redirect object type */
export type gqlRedirect = {
  readonly __typename?: 'Redirect';
  /** B21 GUID */
  readonly guid?: Maybe<Scalars['String']>;
  /** B21 ID */
  readonly id?: Maybe<Scalars['ID']>;
  /** B21 Invitation Type Audition/Callback */
  readonly inviationType?: Maybe<Scalars['String']>;
  /** B21 ID */
  readonly presentationFolderId?: Maybe<Scalars['Int']>;
  /** B21 ID */
  readonly presentationGroupId?: Maybe<Scalars['Int']>;
  /** B21 Profile GUID */
  readonly profileGuid?: Maybe<Scalars['String']>;
  /** B21 Project ID */
  readonly projectId?: Maybe<Scalars['ID']>;
};

/** Input type for entity type redirect */
export type gqlRedirectInput = {
  /** Legacy ID from BAU */
  readonly legacyId?: InputMaybe<Scalars['String']>;
  /** BAU Instance */
  readonly legacyInstance: Scalars['String'];
  /** Legacy presentation group id */
  readonly legacyPresentationGroupId?: InputMaybe<Scalars['String']>;
  readonly legacyUserName?: InputMaybe<Scalars['String']>;
  readonly type: Scalars['String'];
};

/** Region */
export type gqlRegion = {
  readonly __typename?: 'Region';
  /** code */
  readonly code: Scalars['String'];
  /** country */
  readonly country?: Maybe<gqlCountry>;
  /** dma objects */
  readonly dmas: ReadonlyArray<gqlDma>;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlRegionItem = {
  readonly __typename?: 'RegionItem';
  /** code */
  readonly code: Scalars['String'];
  readonly country?: Maybe<gqlCountryItem>;
  /** Is this enbaled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlRegisterDeviceInput = {
  readonly deviceType: gqlDeviceTokenType;
  readonly token: Scalars['String'];
};

export type gqlRemoveBillingInfoInput = {
  readonly billingInfoId: Scalars['String'];
};

export type gqlRemoveProfileFromCastingListError = gqlValidationError;

export type gqlRemoveProfileFromCastingListInput = {
  readonly listId: Scalars['ID'];
  readonly listProfileIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlRemoveProfileFromCastingListResult = {
  readonly __typename?: 'RemoveProfileFromCastingListResult';
  readonly error?: Maybe<gqlRemoveProfileFromCastingListError>;
  readonly success: Scalars['Boolean'];
};

export type gqlRenewSubscriptionInput = {
  readonly billingInfoId: Scalars['String'];
  readonly subscriptionId: Scalars['Int'];
};

export type gqlReorderError = gqlMissingDataError | gqlNotFoundError;

export type gqlReorderResult = {
  readonly __typename?: 'ReorderResult';
  readonly error?: Maybe<gqlReorderError>;
  readonly success: Scalars['Boolean'];
};

export type gqlRepOrganizationList = {
  readonly __typename?: 'RepOrganizationList';
  readonly name: Scalars['String'];
  readonly organizationId: Scalars['Int'];
  readonly parentName?: Maybe<Scalars['String']>;
};

export type gqlRepOrganizationListInput = {
  readonly countryCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly divisionsOnly?: InputMaybe<Scalars['Boolean']>;
  readonly organizationParentId?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly organizationStatusCode: ReadonlyArray<Scalars['String']>;
  readonly representationTypeCode: ReadonlyArray<Scalars['String']>;
  readonly sortColumn?: InputMaybe<gqlRepOrganizationListSortEnum>;
};

/** Rep organization sorting enum */
export enum gqlRepOrganizationListSortEnum {
  NAME = 'NAME',
  PARENT_NAME = 'PARENT_NAME'
}

/** Rep Get Presentation by org Response */
export type gqlRepPresentations = {
  readonly __typename?: 'RepPresentations';
  /** Presentation objects */
  readonly records: ReadonlyArray<gqlPresentation>;
  /** Total count of presentation objects in db */
  readonly totalCount: Scalars['Int'];
};

/** Status enum for rep's worksheet */
export enum gqlRepRequestStatus {
  CANCELLED = 'CANCELLED',
  CANCELLED_RESCHEDULED = 'CANCELLED_RESCHEDULED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_REQUEST = 'NEW_REQUEST',
  NOT_SENT = 'NOT_SENT',
  ON_HOLD = 'ON_HOLD',
  PENDING = 'PENDING',
  RESCHEDULED = 'RESCHEDULED',
  RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED',
  REVIEW_RESPONSE = 'REVIEW_RESPONSE',
  UPDATED = 'UPDATED'
}

export enum gqlRepRoleStatusEnum {
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED'
}

export enum gqlRepWorksheetFieldOrder {
  dueDate = 'dueDate',
  projectName = 'projectName',
  receivedAt = 'receivedAt',
  slotDate = 'slotDate',
  status = 'status',
  talentName = 'talentName',
  type = 'type'
}

export type gqlRepWorksheetItem = {
  readonly __typename?: 'RepWorksheetItem';
  /** Date when request was created */
  readonly createdAt: Scalars['DateTime'];
  /** How many times a request has been forwarded to a talent */
  readonly forwardCount?: Maybe<Scalars['Int']>;
  /** Request ID */
  readonly id: Scalars['ID'];
  /** @deprecated Will be removed */
  readonly project?: Maybe<gqlRepWorksheetProject>;
  /** Response to a request */
  readonly response?: Maybe<gqlRepWorksheetResponse>;
  /**
   * Timezone of a Schedule
   * @deprecated Will be removed
   */
  readonly scheduleTimeZone?: Maybe<gqlWfTimeZone>;
  /**
   * Date of Slot
   * @deprecated Will be removed
   */
  readonly slotDate?: Maybe<Scalars['DateTime']>;
  /** Request Status */
  readonly status: gqlRepRequestStatus;
  /** @deprecated Will be removed */
  readonly talent?: Maybe<gqlRepWorksheetTalent>;
  /** Talent's response to a request */
  readonly talentResponse?: Maybe<gqlRepWorksheetResponse>;
  /** Request Type */
  readonly type: Scalars['String'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gqlRepWorksheetOrderOption = {
  readonly field: gqlRepWorksheetFieldOrder;
  readonly sortOrder?: InputMaybe<gqlSortOrder>;
};

export type gqlRepWorksheetOrganization = {
  readonly __typename?: 'RepWorksheetOrganization';
  readonly id?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Talent info */
export type gqlRepWorksheetProfile = {
  readonly __typename?: 'RepWorksheetProfile';
  readonly defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Talent's email */
  readonly email?: Maybe<Scalars['String']>;
  /** Talent's ID */
  readonly id: Scalars['Int'];
  /** Talents phone */
  readonly phone?: Maybe<Scalars['String']>;
  readonly profileMainDivision?: Maybe<gqlRepWorksheetOrganization>;
  /** Talent's stage name */
  readonly stageName: Scalars['String'];
  readonly talentUnions?: Maybe<ReadonlyArray<gqlRepWorksheetTalentUnion>>;
};

/** Project info */
export type gqlRepWorksheetProject = {
  readonly __typename?: 'RepWorksheetProject';
  /** Casting company */
  readonly castingCompany?: Maybe<Scalars['String']>;
  /** Project's ID */
  readonly id: Scalars['Int'];
  /** Project's name */
  readonly name: Scalars['String'];
};

/** Response type for Rep Worksheet */
export type gqlRepWorksheetResponse = {
  readonly __typename?: 'RepWorksheetResponse';
  readonly mediaList?: Maybe<ReadonlyArray<gqlMediaResponseItem>>;
  /**
   * The media files sent in response
   * @deprecated please use mediaList
   */
  readonly medias?: Maybe<ReadonlyArray<gqlWfMedia>>;
  /** Response note */
  readonly note?: Maybe<Scalars['String']>;
  readonly repliedAt?: Maybe<Scalars['DateTime']>;
  /** Response status */
  readonly status: gqlResponseStatus;
};

export type gqlRepWorksheetRole = {
  readonly __typename?: 'RepWorksheetRole';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly project: gqlRepWorksheetProject;
};

export type gqlRepWorksheetScheduleAddress = {
  readonly __typename?: 'RepWorksheetScheduleAddress';
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<gqlRepWorksheetScheduleAddressCountry>;
  readonly id: Scalars['Int'];
  readonly line1?: Maybe<Scalars['String']>;
  readonly line2?: Maybe<Scalars['String']>;
  readonly line3?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  readonly otherDetail?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly state?: Maybe<gqlRepWorksheetScheduleAddressState>;
};

export type gqlRepWorksheetScheduleAddressCountry = {
  readonly __typename?: 'RepWorksheetScheduleAddressCountry';
  readonly code?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type gqlRepWorksheetScheduleAddressState = {
  readonly __typename?: 'RepWorksheetScheduleAddressState';
  readonly code?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type gqlRepWorksheetScheduleDetail = {
  readonly __typename?: 'RepWorksheetScheduleDetail';
  readonly address?: Maybe<gqlRepWorksheetScheduleAddress>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly timeZone?: Maybe<gqlRepWorksheetTimeZone>;
};

/** Submission info */
export type gqlRepWorksheetSubmission = {
  readonly __typename?: 'RepWorksheetSubmission';
  /** Talent's ID */
  readonly id: Scalars['Int'];
  readonly isCreatedByCD?: Maybe<Scalars['Boolean']>;
  /** Submission's note */
  readonly note?: Maybe<Scalars['String']>;
  readonly profile?: Maybe<gqlRepWorksheetProfile>;
  readonly role: gqlRepWorksheetRole;
};

/** Talent info */
export type gqlRepWorksheetTalent = {
  readonly __typename?: 'RepWorksheetTalent';
  /** Talent's cover */
  readonly coverUrl?: Maybe<Scalars['String']>;
  /** Talent's email */
  readonly email?: Maybe<Scalars['String']>;
  /** Talent's ID */
  readonly id: Scalars['Int'];
  /** Talents phone */
  readonly phone?: Maybe<Scalars['String']>;
  /** Talent's stage name */
  readonly stageName: Scalars['String'];
};

export type gqlRepWorksheetTalentUnion = {
  readonly __typename?: 'RepWorksheetTalentUnion';
  readonly code?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Measurement object */
export type gqlRepWorksheetTimeZone = {
  readonly __typename?: 'RepWorksheetTimeZone';
  readonly abbreviation?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly standardName: Scalars['String'];
};

export type gqlReportContactInfoModel = {
  readonly __typename?: 'ReportContactInfoModel';
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

export type gqlReportDma = {
  readonly __typename?: 'ReportDma';
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

/** Report Media */
export type gqlReportMediaModel = {
  readonly __typename?: 'ReportMediaModel';
  readonly fileKey: Scalars['String'];
  readonly guid: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly mediaStorageStatus: gqlMediaStorageStatus;
  readonly mediaType: gqlMediaType;
  readonly thumbnail?: Maybe<gqlReportMediaModel>;
  readonly transformation?: Maybe<gqlMediaTransformation>;
};

/** Report Media Sides */
export type gqlReportMediaObjectModel = {
  readonly __typename?: 'ReportMediaObjectModel';
  readonly mediaObject: gqlReportMediaModel;
  readonly name: Scalars['String'];
};

export type gqlReportPublicationSettings = {
  readonly __typename?: 'ReportPublicationSettings';
  readonly dmas: ReadonlyArray<gqlReportDma>;
  readonly publicationSetting: gqlReportSetting;
};

export type gqlReportRoleDateType = {
  readonly __typename?: 'ReportRoleDateType';
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type gqlReportRoleDateWithLocation = {
  readonly __typename?: 'ReportRoleDateWithLocation';
  readonly cityName?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly roleDateType?: Maybe<gqlReportRoleDateType>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type gqlReportSetting = {
  readonly __typename?: 'ReportSetting';
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export enum gqlReportStatusEnum {
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export enum gqlReportTypeEnum {
  AgencyCallList = 'AgencyCallList',
  AllTalentSubmission = 'AllTalentSubmission',
  BookingCallList = 'BookingCallList',
  FormsReport = 'FormsReport',
  Presentation = 'Presentation',
  PresentationLog = 'PresentationLog',
  PresentationPick = 'PresentationPick',
  PresentationSizeSheets = 'PresentationSizeSheets',
  ProjectOverview = 'ProjectOverview',
  ProjectOverviewCD = 'ProjectOverviewCD',
  ProjectSubmission = 'ProjectSubmission',
  Schedule = 'Schedule',
  ScheduleReport = 'ScheduleReport',
  ScheduleSizeSheets = 'ScheduleSizeSheets',
  TalentSubmission = 'TalentSubmission'
}

export type gqlReportUnion = gqlAgencyCallListReportModel | gqlAllTalentSubmissionModel | gqlBookingCallListsReportModel | gqlFormsReportModel | gqlPresentationLogReportModel | gqlPresentationPickReportModel | gqlPresentationSizeSheetsReportModel | gqlProjectOverviewCdReportModel | gqlProjectOverviewReportModel | gqlProjectSubmissionReportModel | gqlScheduleReportModel | gqlScheduleSizeSheetsReportModel | gqlTalentSubmissionModel;

/** RepresentationAgeCategory object */
export type gqlRepresentationAgeCategory = {
  readonly __typename?: 'RepresentationAgeCategory';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRepresentationAgeCategoryEnum {
  ADULT = 'ADULT',
  ADULT_YOUTH = 'ADULT_YOUTH',
  YOUTH = 'YOUTH'
}

/** RepresentationCategory object */
export type gqlRepresentationCategory = {
  readonly __typename?: 'RepresentationCategory';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRepresentationCategoryEnum {
  BACKGROUND = 'BACKGROUND',
  COMMERCIAL = 'COMMERCIAL',
  DANCE = 'DANCE',
  HOSTING = 'HOSTING',
  INFLUENCERS = 'INFLUENCERS',
  PRINCIPAL = 'PRINCIPAL',
  PRINT = 'PRINT',
  REAL_PEOPLE = 'REAL_PEOPLE',
  SPORTS_STUNTS = 'SPORTS_STUNTS',
  THEATRICAL = 'THEATRICAL',
  VOICE_OVER = 'VOICE_OVER'
}

export type gqlRepresentationSystemRole = {
  readonly __typename?: 'RepresentationSystemRole';
  readonly code?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly internal?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly sortOrder?: Maybe<Scalars['Float']>;
  readonly systemRoleId?: Maybe<Scalars['Float']>;
};

/** RepresentationType object */
export type gqlRepresentationType = {
  readonly __typename?: 'RepresentationType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRepresentationTypeEnum {
  AGENCY = 'AGENCY',
  CASTING_COMPANY = 'CASTING_COMPANY',
  DECISION_MAKER = 'DECISION_MAKER',
  MANAGEMENT = 'MANAGEMENT',
  PROJECT_CREATOR = 'PROJECT_CREATOR',
  SHARED_PROJECT_USER = 'SHARED_PROJECT_USER',
  STUDIO = 'STUDIO'
}

/** Representative List Details */
export type gqlRepresentativeList = {
  readonly __typename?: 'RepresentativeList';
  /** Account of list creator */
  readonly account?: Maybe<gqlAccount>;
  /** List creation date */
  readonly created: Scalars['DateTime'];
  /** List's Creator Account */
  readonly createdBy?: Maybe<gqlAccount>;
  /** List ID */
  readonly id: Scalars['ID'];
  /** Representative List Type Object */
  readonly listType?: Maybe<gqlListType>;
  /** Account of last list editor */
  readonly modifiedBy?: Maybe<gqlAccount>;
  /** List Name */
  readonly name: Scalars['String'];
  /** Organization of represe */
  readonly organization?: Maybe<gqlOrganization>;
  /** Organization ID */
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly representatives: ReadonlyArray<gqlOrganization>;
  /** Total count of representatives */
  readonly totalRepresentatives: Scalars['Float'];
  /** Last list updated date */
  readonly updated?: Maybe<Scalars['DateTime']>;
};

/** Paginated result with [RepresentativeList] objects */
export type gqlRepresentativeListPaginatedResult = {
  readonly __typename?: 'RepresentativeListPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of RepresentativeList objects */
  readonly objects: ReadonlyArray<gqlRepresentativeList>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Representative search result */
export type gqlRepresentativeSearchResult = {
  readonly __typename?: 'RepresentativeSearchResult';
  /** List of representatives */
  readonly organizations: ReadonlyArray<gqlOrganization>;
  /** Total records count */
  readonly totalCount: Scalars['Int'];
};

/** Enum of Represented Talents Submissions Types */
export enum gqlRepsSubmissionTypeEnum {
  SELECTED = 'SELECTED',
  SUBMITTED = 'SUBMITTED'
}

export type gqlRepsWorksheetFilters = {
  readonly __typename?: 'RepsWorksheetFilters';
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganizationFilter>>;
  readonly profiles?: Maybe<ReadonlyArray<gqlProfileFilter>>;
  readonly projects?: Maybe<ReadonlyArray<gqlProjectFilter>>;
};

export type gqlRepsWorksheetFiltersArgs = {
  readonly dueDateFrom?: InputMaybe<Scalars['DateTime']>;
  readonly dueDateTo?: InputMaybe<Scalars['DateTime']>;
  readonly fromOrgIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly projectIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly receivedFrom?: InputMaybe<Scalars['DateTime']>;
  readonly receivedTo?: InputMaybe<Scalars['DateTime']>;
  readonly responseStatus?: InputMaybe<gqlResponseStatus>;
  readonly slotDateFrom?: InputMaybe<Scalars['DateTime']>;
  readonly slotDateTo?: InputMaybe<Scalars['DateTime']>;
  readonly statuses?: InputMaybe<ReadonlyArray<gqlRepRequestStatus>>;
  readonly talentResponseStatus?: InputMaybe<gqlResponseStatus>;
  readonly type?: InputMaybe<gqlRequestType>;
};

export type gqlRepsWorksheetGroup = {
  readonly __typename?: 'RepsWorksheetGroup';
  readonly details?: Maybe<gqlRepsWorksheetGroupDetails>;
  readonly id: Scalars['ID'];
  readonly itemCount: Scalars['Int'];
};

export type gqlRepsWorksheetGroupDetails = gqlRepWorksheetProfile | gqlRepWorksheetProject;

export enum gqlRepsWorksheetGroupType {
  PROFILE = 'PROFILE',
  PROJECT = 'PROJECT'
}

export type gqlRepsWorksheetGroups = {
  readonly __typename?: 'RepsWorksheetGroups';
  readonly groups: ReadonlyArray<gqlRepsWorksheetGroup>;
  readonly nextGroupId?: Maybe<Scalars['ID']>;
};

export type gqlRepsWorksheetItem = {
  readonly __typename?: 'RepsWorksheetItem';
  /** Date when request was created */
  readonly createdAt: Scalars['DateTime'];
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  /** How many times a request has been forwarded to a talent */
  readonly forwardCount?: Maybe<Scalars['Int']>;
  /** Request ID */
  readonly id: Scalars['ID'];
  readonly lastMessageId?: Maybe<Scalars['String']>;
  /** Response to a request */
  readonly response?: Maybe<gqlRepWorksheetResponse>;
  /** Schedule */
  readonly schedule?: Maybe<gqlRepWorksheetScheduleDetail>;
  /** Date of Slot */
  readonly slotDate?: Maybe<Scalars['DateTime']>;
  /** Request Status */
  readonly status: gqlRepRequestStatus;
  readonly submission?: Maybe<gqlRepWorksheetSubmission>;
  /** Talent's response to a request */
  readonly talentResponse?: Maybe<gqlRepWorksheetResponse>;
  /** Request Type */
  readonly type: Scalars['String'];
  readonly unreadMessageCount?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type gqlRepsWorksheetItemPagedList = {
  readonly __typename?: 'RepsWorksheetItemPagedList';
  readonly data: ReadonlyArray<gqlRepsWorksheetItem>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type gqlRequest = {
  readonly __typename?: 'Request';
  /** returns aggregation of all the Reponse Statuses by Talents for Media Requests */
  readonly cdMediaResponseStatusCounts?: Maybe<gqlCdMediaResponseCounts>;
  /** Request ID */
  readonly id: Scalars['ID'];
  /** returns if request is draft or not */
  readonly isDraft?: Maybe<Scalars['Boolean']>;
  readonly lastActionAt?: Maybe<Scalars['DateTime']>;
  readonly name: Scalars['String'];
  readonly sentAt?: Maybe<Scalars['DateTime']>;
  /** Request Type */
  readonly type: gqlRequestType;
};

/** Forms associated with Request */
export type gqlRequestForms = {
  readonly __typename?: 'RequestForms';
  /** List of forms associated with the request */
  readonly forms: ReadonlyArray<gqlFormInfo>;
  /** ID of media request */
  readonly requestId: Scalars['String'];
};

export enum gqlRequestMediaType {
  AUDIO = 'AUDIO',
  PDF = 'PDF',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

export type gqlRequestPagedList = {
  readonly __typename?: 'RequestPagedList';
  readonly data: ReadonlyArray<gqlRequest>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export enum gqlRequestSendTo {
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  NEW_AND_PENDING = 'NEW_AND_PENDING',
  ONLY_NEW = 'ONLY_NEW',
  PENDING = 'PENDING'
}

export enum gqlRequestStatus {
  ADDITIONAL_MEDIA_ADDED = 'ADDITIONAL_MEDIA_ADDED',
  CANCELLED = 'CANCELLED',
  CANCELLED_RESCHEDULED = 'CANCELLED_RESCHEDULED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  MEDIA_ADDED = 'MEDIA_ADDED',
  NEW_REQUEST = 'NEW_REQUEST',
  NOT_SENT = 'NOT_SENT',
  ON_HOLD = 'ON_HOLD',
  PENDING = 'PENDING',
  RESCHEDULED = 'RESCHEDULED',
  REVIEW_RESPONSE = 'REVIEW_RESPONSE'
}

export enum gqlRequestStatusEnum {
  ACTION_REQUIRED_NEW_REQUEST = 'ACTION_REQUIRED_NEW_REQUEST',
  ACTION_REQUIRED_RESCHEDULED = 'ACTION_REQUIRED_RESCHEDULED',
  ACTION_REQUIRED_REVIEW_RESPONSE = 'ACTION_REQUIRED_REVIEW_RESPONSE',
  ACTION_REQUIRED_SEE_UPDATES = 'ACTION_REQUIRED_SEE_UPDATES',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PENDING_CHANGE_REQUESTED = 'PENDING_CHANGE_REQUESTED',
  PENDING_ON_HOLD = 'PENDING_ON_HOLD'
}

/** Request status union */
export enum gqlRequestStatusUnion {
  ADDITIONAL_MEDIA_ADDED = 'ADDITIONAL_MEDIA_ADDED',
  ANSWERED = 'ANSWERED',
  CANCELLED = 'CANCELLED',
  CANCELLED_RESCHEDULED = 'CANCELLED_RESCHEDULED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  MEDIA_ADDED = 'MEDIA_ADDED',
  NOT_SENT = 'NOT_SENT',
  ON_HOLD = 'ON_HOLD',
  PENDING = 'PENDING',
  RESCHEDULED = 'RESCHEDULED',
  RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED',
  UPDATED = 'UPDATED'
}

export type gqlRequestSubmission = {
  readonly __typename?: 'RequestSubmission';
  /** Request ID */
  readonly id: Scalars['ID'];
  /** Submission ID */
  readonly submissionId: Scalars['ID'];
};

export enum gqlRequestType {
  ASK_QUESTION = 'ASK_QUESTION',
  AUDITION = 'AUDITION',
  CALLBACK = 'CALLBACK',
  MEDIA = 'MEDIA',
  ROSTER_INVITATION = 'ROSTER_INVITATION'
}

export enum gqlRequestTypeEnum {
  ASK_A_QUESTION = 'ASK_A_QUESTION',
  AUDITION_INVITE = 'AUDITION_INVITE',
  CALLBACK_INVITE = 'CALLBACK_INVITE',
  QUESTION_TO_REPRESENTATIVE = 'QUESTION_TO_REPRESENTATIVE',
  SUBMISSION_MEDIA_REQUEST = 'SUBMISSION_MEDIA_REQUEST',
  TALENT_ROSTER_INVITE = 'TALENT_ROSTER_INVITE'
}

export type gqlRequestUnion = gqlAskQuestionRequest | gqlMediaRequest;

export type gqlResetSessionObserverUrlInput = {
  readonly scheduleId: Scalars['ID'];
};

export type gqlResetSessionObserverUrlOutput = {
  readonly __typename?: 'ResetSessionObserverUrlOutput';
  readonly virtualAudition?: Maybe<gqlScheduleVirtualAudition>;
};

export enum gqlResponseStatus {
  ADDITIONAL_MEDIA_ADDED = 'ADDITIONAL_MEDIA_ADDED',
  ANSWERED = 'ANSWERED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  MEDIA_ADDED = 'MEDIA_ADDED',
  RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED'
}

export enum gqlResponseStatusEnum {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  SENT = 'SENT'
}

export type gqlRestoreArchivedMediaInput = {
  readonly mediaIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlRestoreArchivedMediaResponse = {
  readonly __typename?: 'RestoreArchivedMediaResponse';
  readonly media: ReadonlyArray<gqlMedia>;
  readonly success: Scalars['Boolean'];
};

export type gqlRestoreDeletedMediaInput = {
  readonly mediaIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlRestoreDeletedMediaResponse = {
  readonly __typename?: 'RestoreDeletedMediaResponse';
  readonly media: ReadonlyArray<gqlMedia>;
  readonly success: Scalars['Boolean'];
};

export type gqlResumeSubscriptionInput = {
  readonly subscriptionId: Scalars['Int'];
};

export type gqlRetention = {
  readonly __typename?: 'Retention';
  /** The URL to redirect the user to start the retention process */
  readonly url: Scalars['String'];
};

/** Role object */
export type gqlRole = {
  readonly __typename?: 'Role';
  /** Ages of roles showing in years */
  readonly ageInYears: Scalars['Boolean'];
  /** Role age max month */
  readonly ageMaxMonth?: Maybe<Scalars['Float']>;
  /** Role age min month */
  readonly ageMinMonth?: Maybe<Scalars['Float']>;
  /** Age range */
  readonly ageRange?: Maybe<Scalars['String']>;
  /** Audition Session ID */
  readonly auditionSessionId?: Maybe<Scalars['ID']>;
  /** Number of intended talents */
  readonly backgroundTalentIntended?: Maybe<Scalars['Int']>;
  /** Is close submissions */
  readonly closeSubmissions?: Maybe<Scalars['Boolean']>;
  /** Role compensations */
  readonly compensations?: Maybe<ReadonlyArray<gqlRoleCompensation>>;
  readonly countOfPairedRepsAndCB: gqlRoleCountOfPairedRepsAndCb;
  /** Created Time */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Currency Object */
  readonly currency?: Maybe<gqlCurrency>;
  /** Role description */
  readonly description: Scalars['String'];
  /** Displayed Role Ethnic Appearance */
  readonly displayedRoleEthnicAppearance: Scalars['String'];
  /** Displayed Role Ethnic Appearance */
  readonly displayedRoleGenderAppearance: Scalars['String'];
  /** Displayed Role Union */
  readonly displayedRoleUnion: Scalars['String'];
  /** Role Ethnic Appearance */
  readonly ethnicAppearance: ReadonlyArray<gqlEthnicAppearance>;
  readonly fieldRestrictions?: Maybe<ReadonlyArray<gqlRoleFieldRestriction>>;
  /** Gender */
  readonly genderAppearance?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  /** Role public GUID */
  readonly guid?: Maybe<Scalars['String']>;
  /** Role Primary ID */
  readonly id: Scalars['ID'];
  /** Role internal name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Is role published after others */
  readonly isNew?: Maybe<Scalars['Boolean']>;
  /** Is open call */
  readonly isOpenCall?: Maybe<Scalars['Boolean']>;
  readonly isReadOnlyAccess: Scalars['Boolean'];
  /** Stores information about the date when the Project Creator user last saw submissions */
  readonly lastViewedSubmissionPage?: Maybe<Scalars['DateTime']>;
  /** DMA */
  readonly location?: Maybe<gqlDma>;
  /** Returns list of role date with location */
  readonly locationDates?: Maybe<ReadonlyArray<gqlRoleDateWithLocation>>;
  /** Location ID */
  readonly locationId?: Maybe<Scalars['ID']>;
  /** Role name */
  readonly name: Scalars['String'];
  /** Order of appearance on which sequence role will be displayed */
  readonly orderOfAppearance?: Maybe<Scalars['Int']>;
  /** Organizationwise submissions for the role */
  readonly organizationSubmissions: ReadonlyArray<gqlSubmission>;
  /** Paid Type */
  readonly paidType?: Maybe<gqlPaidType>;
  /** Role Photos */
  readonly photos?: Maybe<ReadonlyArray<gqlRoleMedia>>;
  /** Project */
  readonly project: gqlProject;
  /** Role project ID */
  readonly projectId: Scalars['ID'];
  /** Project name */
  readonly projectName?: Maybe<Scalars['String']>;
  readonly projectReleaseLocations: ReadonlyArray<gqlDma>;
  readonly projectType?: Maybe<gqlProjectType>;
  /** Project Unions */
  readonly projectUnion?: Maybe<ReadonlyArray<gqlProjectUnionDetails>>;
  /** Publication settings for the role */
  readonly publicationSettings: ReadonlyArray<gqlRolePublicationSetting>;
  /** Role rate */
  readonly rate?: Maybe<Scalars['Float']>;
  /** Role max rate */
  readonly rateMax?: Maybe<Scalars['Float']>;
  /** Rate note */
  readonly rateNote?: Maybe<Scalars['String']>;
  /** Rate Per Object */
  readonly ratePer?: Maybe<gqlRatePerType>;
  /** Rate summary */
  readonly rateSummary?: Maybe<Scalars['String']>;
  /** Is rate type */
  readonly rateType: Scalars['Boolean'];
  readonly repSelectedSubmissionsCount: Scalars['Int'];
  readonly repStatus: gqlRepRoleStatusEnum;
  readonly repSubmittedSubmissionsCount: Scalars['Int'];
  /** The media request instruction */
  readonly requestMediaInstruction: Scalars['String'];
  readonly requestMediaTypes: ReadonlyArray<Scalars['String']>;
  /** Ethnicity / nationality description */
  readonly roleEthnicityNationality: Scalars['String'];
  /** Info about paired reps orgs */
  readonly rolePairedOrganizations: gqlRoleOrganizationPairPaginatedResult;
  /** Role Parent ID */
  readonly roleParentId?: Maybe<Scalars['ID']>;
  /** Role Previous Status */
  readonly rolePreviousStatus?: Maybe<gqlRoleStatus>;
  /** Role published date when role become ACTIVE Or REQUEST_ADDITIONAL_SUBMISSIONS */
  readonly rolePublishedAt?: Maybe<Scalars['DateTime']>;
  /** Role Status */
  readonly roleStatus?: Maybe<gqlRoleStatus>;
  /** Role Type */
  readonly roleType?: Maybe<gqlRoleType>;
  /** Role Union */
  readonly roleUnion?: Maybe<gqlRoleUnion>;
  /** Is rush call */
  readonly rushCall: Scalars['Boolean'];
  /** Is scale */
  readonly scale: Scalars['Boolean'];
  /** Scale modifier ID */
  readonly scaleModifierId?: Maybe<Scalars['ID']>;
  /** Session */
  readonly session?: Maybe<gqlAuditionSession>;
  /** Sexy description */
  readonly sexualSituationDescription: Scalars['String'];
  /** Sexual situations */
  readonly sexualSituations?: Maybe<ReadonlyArray<gqlSexualSituation>>;
  /** Role Sides */
  readonly sides?: Maybe<ReadonlyArray<gqlRoleMedia>>;
  /** Role Sides Visibility */
  readonly sidesVisibility?: Maybe<gqlRoleSidesVisibility>;
  /** Role skills */
  readonly skills: ReadonlyArray<gqlSkill>;
  /** Spot */
  readonly spot?: Maybe<Scalars['String']>;
  readonly submissionCount: ReadonlyArray<gqlRoleSubmissionCount>;
  /** The submission due date */
  readonly submissionDueDate?: Maybe<Scalars['DateTime']>;
  /** The submission instruction */
  readonly submissionInstruction: Scalars['String'];
  /**
   * Submissions for the role. Fetches all the submissions for a role filtered by
   * the scheduled criteria for all users which system role are not equal to
   * Agent(reps), otherwise submissions will be filtered by profile organization
   * participation id.
   */
  readonly submissions: ReadonlyArray<gqlSubmission>;
  /** Project system role id */
  readonly systemRoleId?: Maybe<Scalars['ID']>;
  /** TimeZone */
  readonly timeZone?: Maybe<gqlTimeZone>;
  /** Wardrobe */
  readonly wardrobe?: Maybe<Scalars['String']>;
  /** Work requirement */
  readonly workRequirement: Scalars['String'];
};


/** Role object */
export type gqlRoleOrganizationSubmissionsArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};


/** Role object */
export type gqlRoleRolePairedOrganizationsArgs = {
  pageInput?: InputMaybe<gqlPageInput>;
};


/** Role object */
export type gqlRoleSidesArgs = {
  checkSidesVisibility?: InputMaybe<Scalars['Boolean']>;
};


/** Role object */
export type gqlRoleSubmissionsArgs = {
  auditionScheduled?: InputMaybe<Scalars['Boolean']>;
  callbackScheduled?: InputMaybe<Scalars['Boolean']>;
  profileTypeCode?: InputMaybe<Scalars['String']>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  statusCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** Role Previous Status */
export type gqlRoleChangeLog = {
  readonly __typename?: 'RoleChangeLog';
  /** Role Change Log Primary ID */
  readonly id: Scalars['ID'];
  readonly isOpenCall: Scalars['Boolean'];
  /** Modified Date */
  readonly modifiedAt: Scalars['DateTime'];
  /** Account of role modifier */
  readonly modifiedBy?: Maybe<gqlAccount>;
  /** Modifier Account ID */
  readonly modifiedById: Scalars['Int'];
  /** Role */
  readonly role?: Maybe<gqlRole>;
  readonly roleId: Scalars['Int'];
};

/** Role compensation object */
export type gqlRoleCompensation = {
  readonly __typename?: 'RoleCompensation';
  readonly compensation: gqlCompensation;
  readonly otherCompensation?: Maybe<Scalars['String']>;
  readonly roleCompensationId: Scalars['Float'];
};

/** Input type for role compensation */
export type gqlRoleCompensationInput = {
  /** Role compensation type code */
  readonly compensationCode: Scalars['String'];
  /** Role other compensation description */
  readonly otherCompensation?: InputMaybe<Scalars['String']>;
};

/** Role Paired orgs info */
export type gqlRoleCountOfPairedRepsAndCb = {
  readonly __typename?: 'RoleCountOfPairedRepsAndCB';
  /** Is current role shared with Casting Billboard users(talents) */
  readonly isSharedWithCB: Scalars['Boolean'];
  /**
   * Represents the Count of paired reps organizations for current published role,
   * or count of potentially paired reps organizations for not published role
   */
  readonly repsOrgs: Scalars['Int'];
};

/** Input type for Role creation */
export type gqlRoleCreateInput = {
  readonly ageInYears?: InputMaybe<Scalars['Boolean']>;
  readonly ageMaxMonths?: InputMaybe<Scalars['Int']>;
  readonly ageMinMonths?: InputMaybe<Scalars['Int']>;
  readonly ageMinor?: InputMaybe<Scalars['Int']>;
  readonly backgroundTalentIntended?: InputMaybe<Scalars['Float']>;
  readonly closeSubmissions?: InputMaybe<Scalars['Boolean']>;
  readonly compensations?: InputMaybe<ReadonlyArray<gqlRoleCompensationInput>>;
  /** Should session be created */
  readonly createSubmissionMediaSession?: InputMaybe<Scalars['Boolean']>;
  /** CODE of Currency object */
  readonly currencyCode?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  /** List of Ethnic Appearances associated with role */
  readonly ethnicAppearances?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fieldRestrictionSettings?: InputMaybe<ReadonlyArray<gqlRoleFieldRestrictionInput>>;
  readonly internalName?: InputMaybe<Scalars['String']>;
  readonly locationCode?: InputMaybe<Scalars['String']>;
  readonly locationDateInformations?: InputMaybe<ReadonlyArray<gqlRoleDateWithLocationInput>>;
  readonly name: Scalars['String'];
  /** Paid Type CODE */
  readonly paidTypeCode?: InputMaybe<Scalars['String']>;
  /** RoleMediaInput array of role media input for add/ update the media along with the relevant role */
  readonly photos?: InputMaybe<ReadonlyArray<gqlRoleMediaInput>>;
  readonly projectId: Scalars['ID'];
  /** Rate */
  readonly rate?: InputMaybe<Scalars['Float']>;
  /** rate max */
  readonly rateMax?: InputMaybe<Scalars['Float']>;
  readonly rateNote?: InputMaybe<Scalars['String']>;
  /** CODE of RatePer object */
  readonly ratePerCode?: InputMaybe<Scalars['String']>;
  readonly rateSummary?: InputMaybe<Scalars['String']>;
  readonly rateType?: InputMaybe<Scalars['Boolean']>;
  /** List of Representative List Ids */
  readonly representativesLists?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly requestMediaInstruction?: InputMaybe<Scalars['String']>;
  readonly requestMediaTypeCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly roleEthnicityNationality?: InputMaybe<Scalars['String']>;
  readonly roleGenderCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly roleParentId?: InputMaybe<Scalars['ID']>;
  /** List of Publication Setting Input */
  readonly rolePublicationSettings?: InputMaybe<ReadonlyArray<gqlRolePublicationSettingInput>>;
  /** Roles Side Visibility */
  readonly roleSidesVisibilityCode?: InputMaybe<Scalars['String']>;
  readonly roleSkills?: InputMaybe<ReadonlyArray<gqlRoleSkillInput>>;
  readonly roleStatusCode: Scalars['String'];
  readonly roleType: gqlRoleTypeInput;
  /** Role Union CODE */
  readonly roleUnionCode?: InputMaybe<Scalars['String']>;
  readonly rushCall?: InputMaybe<Scalars['Boolean']>;
  /** Scale */
  readonly scale?: InputMaybe<Scalars['Boolean']>;
  readonly sexualSituationCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sexualSituationDescription?: InputMaybe<Scalars['String']>;
  readonly sides?: InputMaybe<ReadonlyArray<gqlRoleMediaInput>>;
  readonly spot?: InputMaybe<Scalars['String']>;
  readonly submissionDueDate?: InputMaybe<Scalars['DateTime']>;
  readonly submissionInstruction?: InputMaybe<Scalars['String']>;
  /** Time Zone Code */
  readonly timeZoneCode?: InputMaybe<Scalars['String']>;
  readonly wardrobe?: InputMaybe<Scalars['String']>;
  readonly workRequirement?: InputMaybe<Scalars['String']>;
};

/** Role Date type object */
export type gqlRoleDateType = {
  readonly __typename?: 'RoleDateType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRoleDateTypeEnum {
  AUDITION_LOCATION = 'AUDITION_LOCATION',
  CALLBACK_AUDITION_LOCATION = 'CALLBACK_AUDITION_LOCATION',
  FITTING_LOCATION = 'FITTING_LOCATION',
  WORKING_LOCATION = 'WORKING_LOCATION'
}

/** Role Date with Location object */
export type gqlRoleDateWithLocation = {
  readonly __typename?: 'RoleDateWithLocation';
  readonly cityName?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** RoleDateLocation Primary ID */
  readonly id: Scalars['ID'];
  /** Role Date Type */
  readonly roleDateType?: Maybe<gqlRoleDateType>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

/** Role's Date with Location input type */
export type gqlRoleDateWithLocationInput = {
  /** ID of RoleDate object. Used for update existing date. */
  readonly dateId?: InputMaybe<Scalars['ID']>;
  /** CODE of DateType enum */
  readonly dateTypeCode: Scalars['String'];
  /** End date */
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  /** Name of City */
  readonly locationName?: InputMaybe<Scalars['String']>;
  /** Start date */
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
};

export type gqlRoleDefaultDateWithLocation = {
  readonly __typename?: 'RoleDefaultDateWithLocation';
  /** CODE of DateType enum */
  readonly dateTypeCode: Scalars['String'];
  /** End date */
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** Name of City */
  readonly locationName?: Maybe<Scalars['String']>;
  /** Start date */
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type gqlRoleDefaultPublicationSetting = {
  readonly __typename?: 'RoleDefaultPublicationSetting';
  /** List of DMAs CODEs for talents */
  readonly dmas?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Publication Setting CODE */
  readonly publicationSetting: Scalars['String'];
  /** List of Representation Age Categories CODEs */
  readonly repAgeCategories?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representation Categories CODEs */
  readonly repCategories?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representation Type CODEs */
  readonly repTypes?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Representative IDs */
  readonly representativeIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** Representative List ID Selected */
  readonly representativeListId?: Maybe<Scalars['Int']>;
};

/** Role Default Options */
export type gqlRoleDefaults = {
  readonly __typename?: 'RoleDefaults';
  readonly fieldRestrictions?: Maybe<ReadonlyArray<gqlRoleFieldRestriction>>;
  readonly locationDates?: Maybe<ReadonlyArray<gqlRoleDefaultDateWithLocation>>;
  readonly publicationSettings?: Maybe<ReadonlyArray<gqlRoleDefaultPublicationSetting>>;
  readonly wardrobe?: Maybe<Scalars['String']>;
  readonly workRequirements?: Maybe<Scalars['String']>;
};

/** Role Default Options */
export type gqlRoleDefaultsInput = {
  readonly fieldRestrictions?: InputMaybe<ReadonlyArray<gqlRoleFieldRestrictionInput>>;
  readonly locationDates?: InputMaybe<ReadonlyArray<gqlRoleDateWithLocationInput>>;
  /** List of Publication Setting Input */
  readonly publicationSettings?: InputMaybe<ReadonlyArray<gqlRolePublicationSettingInput>>;
  readonly wardrobe?: InputMaybe<Scalars['String']>;
  readonly workRequirements?: InputMaybe<Scalars['String']>;
};

export type gqlRoleFieldRestriction = {
  readonly __typename?: 'RoleFieldRestriction';
  readonly fields: ReadonlyArray<gqlRoleFieldsRestrictionsFieldOption>;
  readonly restrictedFor: gqlProjectFieldRestrictionOption;
};

export type gqlRoleFieldRestrictionInput = {
  readonly fields: ReadonlyArray<gqlRoleFieldsRestrictionsFieldOption>;
  readonly restrictedFor: gqlProjectFieldRestrictionOption;
};

export enum gqlRoleFieldsRestrictionsFieldOption {
  auditionCity = 'auditionCity',
  callbackCity = 'callbackCity',
  fittingCity = 'fittingCity',
  workingCity = 'workingCity'
}

/** Role media object */
export type gqlRoleMedia = {
  readonly __typename?: 'RoleMedia';
  /** ID of role and media objects relation */
  readonly id: Scalars['ID'];
  /** Is this sides media */
  readonly isSides: Scalars['Boolean'];
  /** Media object */
  readonly mediaObject: gqlMedia;
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** ID of role and media objects relation */
  readonly roleMediaId: Scalars['Int'];
  /** Role Side Url */
  readonly url?: Maybe<Scalars['String']>;
};

/** Input type for role media */
export type gqlRoleMediaInput = {
  /** Is this sides media */
  readonly isSides: Scalars['Boolean'];
  /** Media ID */
  readonly mediaId: Scalars['Float'];
  /** Name */
  readonly name?: InputMaybe<Scalars['String']>;
};

/** Info about paired (with role) reps org */
export type gqlRoleOrganizationPair = {
  readonly __typename?: 'RoleOrganizationPair';
  /** Reps Organization */
  readonly organization: gqlOrganization;
  readonly organizationHasSubmissionsToRole: Scalars['Boolean'];
};

/** Paginated result with [RoleOrganizationPair] objects */
export type gqlRoleOrganizationPairPaginatedResult = {
  readonly __typename?: 'RoleOrganizationPairPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of RoleOrganizationPair objects */
  readonly objects: ReadonlyArray<gqlRoleOrganizationPair>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Role Previous Status */
export type gqlRolePreviousStatus = {
  readonly __typename?: 'RolePreviousStatus';
  /** Role Previous Status */
  readonly previousRoleStatus?: Maybe<gqlRoleStatus>;
  readonly roleId: Scalars['Float'];
};

export enum gqlRoleProjectKeywordSearchEnum {
  ALL = 'ALL',
  PROJECT_NAME = 'PROJECT_NAME',
  PROJECT_OR_ROLE_DESCRIPTION = 'PROJECT_OR_ROLE_DESCRIPTION',
  ROLE_NAME = 'ROLE_NAME'
}

export type gqlRolePublication = {
  readonly __typename?: 'RolePublication';
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly messageToReps?: Maybe<Scalars['String']>;
  readonly messageToTalents?: Maybe<Scalars['String']>;
  readonly publicationSettings: ReadonlyArray<gqlPublicationSetting>;
  readonly publicationType: gqlRolePublishTypeEnum;
  readonly roles: ReadonlyArray<gqlRole>;
};

/** Paginated result with [RolePublication] objects */
export type gqlRolePublicationPaginatedResult = {
  readonly __typename?: 'RolePublicationPaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of RolePublication objects */
  readonly objects: ReadonlyArray<gqlRolePublication>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

/** Role Publication setting */
export type gqlRolePublicationSetting = {
  readonly __typename?: 'RolePublicationSetting';
  /** List of Dma objects */
  readonly dmas?: Maybe<ReadonlyArray<gqlDma>>;
  /** Publication Setting object */
  readonly publicationSetting: gqlPublicationSetting;
  /** List of Representation Age Categories objects */
  readonly repAgeCategories?: Maybe<ReadonlyArray<gqlRepresentationAgeCategory>>;
  /** List of Representation Categories objects */
  readonly repCategories?: Maybe<ReadonlyArray<gqlRepresentationCategory>>;
  /** List of Representation Type objects */
  readonly repTypes?: Maybe<ReadonlyArray<gqlRepresentationType>>;
  /** Representative List ID */
  readonly representativeListIds: ReadonlyArray<Scalars['ID']>;
};

/** Publication Role setting */
export type gqlRolePublicationSettingInput = {
  /** List of DMAs CODEs for talents */
  readonly dmas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Publication Setting CODE */
  readonly publicationSetting: Scalars['String'];
  /** List of Representation Age Categories CODEs */
  readonly repAgeCategories?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representation Categories CODEs */
  readonly repCategories?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representation Type CODEs */
  readonly repTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Representative IDs */
  readonly representativeIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Representative List ID Selected */
  readonly representativeListId?: InputMaybe<Scalars['Int']>;
};

/** Role publication type */
export enum gqlRolePublishTypeEnum {
  additionalSubmissions = 'additionalSubmissions',
  approved = 'approved',
  published = 'published',
  widened = 'widened'
}

/** Role search result object */
export type gqlRoleSearchResult = {
  readonly __typename?: 'RoleSearchResult';
  /** Search role list */
  readonly roles: ReadonlyArray<gqlRole>;
  /** Result total count */
  readonly totalCount: Scalars['Float'];
};

/** Role Sides Visibility object */
export type gqlRoleSidesVisibility = {
  readonly __typename?: 'RoleSidesVisibility';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRoleSidesVisibilityEnum {
  DONT_SHOW = 'DONT_SHOW',
  REPRESENTATIVES_AND_CB = 'REPRESENTATIVES_AND_CB',
  REPRESENTATIVES_ONLY = 'REPRESENTATIVES_ONLY'
}

/** Input type for skill */
export type gqlRoleSkillInput = {
  /** Skill CODE */
  readonly skillCode: Scalars['String'];
};

/** Role Status object */
export type gqlRoleStatus = {
  readonly __typename?: 'RoleStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Role status codes options */
export enum gqlRoleStatusCodes {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  ARCHIVE_IN_PROGRESS = 'ARCHIVE_IN_PROGRESS',
  EDIT_TO_ACTIVE_ROLE = 'EDIT_TO_ACTIVE_ROLE',
  INTERNAL = 'INTERNAL',
  MODIFICATIONS_COMPLETE = 'MODIFICATIONS_COMPLETE',
  MODIFICATIONS_REQUESTED = 'MODIFICATIONS_REQUESTED',
  NEW_ROLE_PENDING = 'NEW_ROLE_PENDING',
  NOT_YET_PUBLISHED = 'NOT_YET_PUBLISHED',
  REJECTED = 'REJECTED',
  REQUESTING_ADDITIONAL_SUBMISSIONS = 'REQUESTING_ADDITIONAL_SUBMISSIONS',
  RESTORE_IN_PROGRESS = 'RESTORE_IN_PROGRESS',
  SUBMISSIONS_CLOSED = 'SUBMISSIONS_CLOSED'
}

export enum gqlRoleStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  ARCHIVE_IN_PROGRESS = 'ARCHIVE_IN_PROGRESS',
  EDIT_TO_ACTIVE_ROLE = 'EDIT_TO_ACTIVE_ROLE',
  INTERNAL = 'INTERNAL',
  MODIFICATIONS_COMPLETE = 'MODIFICATIONS_COMPLETE',
  MODIFICATIONS_REQUESTED = 'MODIFICATIONS_REQUESTED',
  NEW_ROLE_PENDING = 'NEW_ROLE_PENDING',
  NOT_YET_PUBLISHED = 'NOT_YET_PUBLISHED',
  REJECTED = 'REJECTED',
  REQUESTING_ADDITIONAL_SUBMISSIONS = 'REQUESTING_ADDITIONAL_SUBMISSIONS',
  RESTORE_IN_PROGRESS = 'RESTORE_IN_PROGRESS',
  SUBMISSIONS_CLOSED = 'SUBMISSIONS_CLOSED'
}

/** Role status filter options */
export enum gqlRoleStatusFilter {
  Active = 'Active',
  All = 'All',
  Archived = 'Archived',
  Published = 'Published',
  UnderReview = 'UnderReview'
}

export type gqlRoleSubmissionCount = {
  readonly __typename?: 'RoleSubmissionCount';
  readonly count: Scalars['Int'];
  readonly roleId: Scalars['Int'];
  readonly submissionStatus: gqlSubmissionStatus;
};

/** Input type for Role Talents */
export type gqlRoleTalent = {
  /** Role Id */
  readonly roleId: Scalars['ID'];
  readonly scheduleSelectInput?: InputMaybe<gqlSchedulesSelectInput>;
  /** List of CODE of Selected Tile Schema */
  readonly selectedTileSchemaCodes: ReadonlyArray<Scalars['String']>;
  /** List of Selected Tile Schemes */
  readonly selectedTileSchemes?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  /** No. of talents in each slot */
  readonly talentsInSlot: Scalars['Float'];
};

/** Role Target object */
export type gqlRoleTarget = {
  readonly __typename?: 'RoleTarget';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRoleTargetEnum {
  BACKGROUND = 'BACKGROUND',
  PRINCIPAL = 'PRINCIPAL'
}

export enum gqlRoleTargetsEnum {
  BACKGROUND = 'BACKGROUND',
  PRINCIPAL = 'PRINCIPAL'
}

/** Role Tracker Model */
export type gqlRoleTracker = {
  readonly __typename?: 'RoleTracker';
  /** Created Date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Primary ID */
  readonly id: Scalars['BigInt'];
  /** Last Status Update */
  readonly lastStatusUpdated?: Maybe<Scalars['DateTime']>;
  /** Role associated with the Role Tracker */
  readonly role?: Maybe<gqlRole>;
  /** Role Tracker Status */
  readonly roleTrackerStatus?: Maybe<gqlRoleTrackerStatusEnum>;
  /** The source of the Role Tracker Submission */
  readonly source?: Maybe<Scalars['String']>;
};

export enum gqlRoleTrackerFieldOrder {
  created = 'created',
  lastStatusUpdated = 'lastStatusUpdated'
}

export type gqlRoleTrackerFiltersArgs = {
  readonly orgIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly submissionIds?: InputMaybe<ReadonlyArray<Scalars['BigInt']>>;
};

export type gqlRoleTrackerGroup = {
  readonly __typename?: 'RoleTrackerGroup';
  readonly id: Scalars['ID'];
  readonly itemCount: Scalars['Int'];
  readonly name: Scalars['String'];
};

export enum gqlRoleTrackerGroupType {
  PROJECT_TYPE = 'PROJECT_TYPE',
  ROLE_TYPE = 'ROLE_TYPE',
  SOURCE = 'SOURCE',
  STATUS = 'STATUS'
}

export type gqlRoleTrackerGroups = {
  readonly __typename?: 'RoleTrackerGroups';
  readonly groups: ReadonlyArray<gqlRoleTrackerGroup>;
  readonly nextGroupId?: Maybe<Scalars['ID']>;
};

export type gqlRoleTrackerOrderOption = {
  readonly field?: InputMaybe<gqlRoleTrackerFieldOrder>;
  readonly sortOrder?: InputMaybe<gqlSortOrder>;
};

export type gqlRoleTrackerPagedList = {
  readonly __typename?: 'RoleTrackerPagedList';
  readonly data: ReadonlyArray<gqlRoleTracker>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Role Tracker Stats Aggregate Model */
export type gqlRoleTrackerStatsAggregate = {
  readonly __typename?: 'RoleTrackerStatsAggregate';
  readonly selected: Scalars['Int'];
  readonly submitted: Scalars['Int'];
  readonly total: Scalars['Int'];
  readonly underConsideration: Scalars['Int'];
};

export enum gqlRoleTrackerStatsIntervalEnum {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  WEEKS = 'WEEKS'
}

/** Role Tracker Stats Period Model */
export type gqlRoleTrackerStatsPeriod = {
  readonly __typename?: 'RoleTrackerStatsPeriod';
  readonly interval?: Maybe<Scalars['Int']>;
  readonly nthInterval?: Maybe<Scalars['Int']>;
  readonly stats: gqlRoleTrackerStatsAggregate;
};

/** Role Tracker Stats Model */
export type gqlRoleTrackerStatsResponse = {
  readonly __typename?: 'RoleTrackerStatsResponse';
  readonly currentPeriod: gqlRoleTrackerStatsPeriod;
  readonly previousPeriod: gqlRoleTrackerStatsPeriod;
};

export enum gqlRoleTrackerStatusEnum {
  SELECTED = 'SELECTED',
  SUBMITTED = 'SUBMITTED',
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION'
}

/** RoleType object */
export type gqlRoleType = {
  readonly __typename?: 'RoleType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  readonly projectType: gqlProjectType;
  readonly roleTarget: gqlRoleTarget;
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRoleTypeEnum {
  BACKGROUND = 'BACKGROUND',
  BRAND_AMBASSADOR = 'BRAND_AMBASSADOR',
  CO_STAR = 'CO_STAR',
  ENSEMBLE = 'ENSEMBLE',
  FEATURED_BACKGROUND = 'FEATURED_BACKGROUND',
  FRACTIONAL_SERIES_REGULAR = 'FRACTIONAL_SERIES_REGULAR',
  GUEST_STAR = 'GUEST_STAR',
  HOST = 'HOST',
  LEAD = 'LEAD',
  MODEL = 'MODEL',
  ONE_DAY_GUEST_STAR = 'ONE_DAY_GUEST_STAR',
  ON_CAMERA = 'ON_CAMERA',
  OTHER = 'OTHER',
  PHOTO_DOUBLE = 'PHOTO_DOUBLE',
  PRECISION_DRIVER = 'PRECISION_DRIVER',
  PRINCIPAL = 'PRINCIPAL',
  RECURRING = 'RECURRING',
  RECURRING_CO_STAR = 'RECURRING_CO_STAR',
  RECURRING_GUEST_STAR = 'RECURRING_GUEST_STAR',
  SERIES_REGULAR = 'SERIES_REGULAR',
  SPECIAL_ABILITY = 'SPECIAL_ABILITY',
  STAND_BY = 'STAND_BY',
  STAND_IN = 'STAND_IN',
  STUDIO_AUDIENCE = 'STUDIO_AUDIENCE',
  STUNT = 'STUNT',
  SUPPORTING = 'SUPPORTING',
  SWING = 'SWING',
  UNDER_5 = 'UNDER_5',
  VOICE_OVER = 'VOICE_OVER'
}

export type gqlRoleTypeFilterInput = {
  readonly keys?: InputMaybe<gqlFilterInput>;
  readonly projectType?: InputMaybe<gqlFilterInput>;
  readonly roleTarget?: InputMaybe<gqlFilterInput>;
};

/** Input type for role type */
export type gqlRoleTypeInput = {
  /** Project Type CODE */
  readonly projectTypeCode: Scalars['String'];
  /** Role target CODE */
  readonly roleTargetCode: Scalars['String'];
  /** Role type CODE */
  readonly roleTypeCode: Scalars['String'];
};

/** RoleUnion object */
export type gqlRoleUnion = {
  readonly __typename?: 'RoleUnion';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlRoleUnionEnum {
  NON_UNION = 'NON_UNION',
  UNION = 'UNION',
  UNION_NON_UNION = 'UNION_NON_UNION'
}

/** Input type for Role update */
export type gqlRoleUpdateInput = {
  readonly ageInYears?: InputMaybe<Scalars['Boolean']>;
  readonly ageMaxMonths?: InputMaybe<Scalars['Int']>;
  readonly ageMinMonths?: InputMaybe<Scalars['Int']>;
  readonly auditionSessionId?: InputMaybe<Scalars['ID']>;
  readonly backgroundTalentIntended?: InputMaybe<Scalars['Float']>;
  readonly closeSubmissions?: InputMaybe<Scalars['Boolean']>;
  readonly compensations?: InputMaybe<ReadonlyArray<gqlRoleCompensationInput>>;
  readonly currencyCode?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  /** List of Ethnic Appearances associated with role */
  readonly ethnicAppearances?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly fieldRestrictionSettings?: InputMaybe<ReadonlyArray<gqlRoleFieldRestrictionInput>>;
  readonly internalName?: InputMaybe<Scalars['String']>;
  readonly locationCode?: InputMaybe<Scalars['String']>;
  readonly locationDateInformations?: InputMaybe<ReadonlyArray<gqlRoleDateWithLocationInput>>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly paidTypeCode?: InputMaybe<Scalars['String']>;
  /** UpdateRoleMediaInput array of role media input for add/ update the media along with the relevant role */
  readonly photos?: InputMaybe<ReadonlyArray<gqlUpdateRoleMediaInput>>;
  readonly projectId?: InputMaybe<Scalars['ID']>;
  readonly rate?: InputMaybe<Scalars['Float']>;
  readonly rateMax?: InputMaybe<Scalars['Float']>;
  readonly rateNote?: InputMaybe<Scalars['String']>;
  readonly ratePerCode?: InputMaybe<Scalars['String']>;
  readonly rateSummary?: InputMaybe<Scalars['String']>;
  readonly rateType?: InputMaybe<Scalars['Boolean']>;
  readonly requestMediaInstruction?: InputMaybe<Scalars['String']>;
  readonly requestMediaTypeCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly roleAccount?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly roleEthnicityNationality?: InputMaybe<Scalars['String']>;
  readonly roleGenderCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly roleId: Scalars['ID'];
  readonly roleOrganization?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly roleParentId?: InputMaybe<Scalars['ID']>;
  readonly rolePublicationSetting?: InputMaybe<gqlRolePublicationSettingInput>;
  /** List of Publication Setting Input */
  readonly rolePublicationSettings?: InputMaybe<ReadonlyArray<gqlRolePublicationSettingInput>>;
  /** Role Sides Visibility */
  readonly roleSidesVisibilityCode?: InputMaybe<Scalars['String']>;
  readonly roleSkills?: InputMaybe<ReadonlyArray<gqlRoleSkillInput>>;
  readonly roleStatusCode?: InputMaybe<Scalars['String']>;
  readonly roleType?: InputMaybe<gqlRoleTypeInput>;
  readonly roleUnionCode?: InputMaybe<Scalars['String']>;
  readonly rushCall?: InputMaybe<Scalars['Boolean']>;
  readonly scale?: InputMaybe<Scalars['Boolean']>;
  readonly sexualSituationCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly sexualSituationDescription?: InputMaybe<Scalars['String']>;
  readonly sides?: InputMaybe<ReadonlyArray<gqlUpdateRoleMediaInput>>;
  readonly spot?: InputMaybe<Scalars['String']>;
  readonly submissionDueDate?: InputMaybe<Scalars['DateTime']>;
  readonly submissionInstruction?: InputMaybe<Scalars['String']>;
  /** Time Zone Code */
  readonly timeZoneCode?: InputMaybe<Scalars['String']>;
  /** updates role date info in all roles within the project */
  readonly updateLocationDateInformationInProject?: InputMaybe<Scalars['Boolean']>;
  readonly wardrobe?: InputMaybe<Scalars['String']>;
  readonly workRequirement?: InputMaybe<Scalars['String']>;
};

export type gqlRoleUpdateWardrobeInput = {
  readonly roleId: Scalars['Int'];
  readonly wardrobe: Scalars['String'];
};

/** Role for Workflow */
export type gqlRoleWorkflow = {
  readonly __typename?: 'RoleWorkflow';
  readonly ageInYears: Scalars['Boolean'];
  readonly ageMax?: Maybe<Scalars['Int']>;
  readonly ageMin?: Maybe<Scalars['Int']>;
  readonly description?: Maybe<Scalars['String']>;
  /** Displayed role age range */
  readonly displayedRoleAgeRange?: Maybe<Scalars['String']>;
  /** Displayed role ethnic appearance */
  readonly displayedRoleEthnicAppearance?: Maybe<Scalars['String']>;
  /** Displayed role genders */
  readonly displayedRoleGenders?: Maybe<Scalars['String']>;
  /** Displayed role union */
  readonly displayedRoleUnion?: Maybe<Scalars['String']>;
  /** Ethnic Appearances */
  readonly ethnicAppearance?: Maybe<ReadonlyArray<gqlEthnicAppearance>>;
  /** Gender Appearances */
  readonly genderAppearance?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  readonly guid?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly locationDates?: Maybe<ReadonlyArray<gqlRoleDateWithLocation>>;
  readonly name: Scalars['String'];
  readonly project?: Maybe<gqlProject>;
  readonly publicationSettingIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly rateMax?: Maybe<Scalars['Float']>;
  readonly ratePerType?: Maybe<gqlRatePerType>;
  /** Rate summary */
  readonly rateSummary?: Maybe<Scalars['String']>;
  readonly roleStatus: gqlRoleStatus;
  readonly roleType: gqlRoleType;
  /** Sexual Situations */
  readonly sexualSituations?: Maybe<ReadonlyArray<gqlSexualSituation>>;
  readonly sides?: Maybe<ReadonlyArray<gqlRoleMedia>>;
  readonly sidesVisibility?: Maybe<gqlRoleSidesVisibility>;
  /** Wardrobe */
  readonly wardrobe?: Maybe<Scalars['String']>;
};

/** Context object for FFY artist object */
export type gqlRolesFitForYouArtistContext = {
  readonly __typename?: 'RolesFitForYouArtistContext';
  readonly id: Scalars['Float'];
  readonly manageEmailSettingsUrl: Scalars['String'];
  readonly name: Scalars['String'];
  readonly notificationSettingsURL: Scalars['String'];
  readonly subjectRolePart: Scalars['String'];
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

/** Casting Company */
export type gqlRolesFitForYouCastingCompanyContext = {
  readonly __typename?: 'RolesFitForYouCastingCompanyContext';
  readonly logo?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** Context object for roles fits for artist */
export type gqlRolesFitForYouContext = {
  readonly __typename?: 'RolesFitForYouContext';
  readonly artists: ReadonlyArray<gqlRolesFitForYouArtistContext>;
  readonly project: gqlRolesFitForYouProjectContext;
  readonly roles: ReadonlyArray<gqlRolesFitForYouRoleContext>;
};

/** Context object for FFY project object */
export type gqlRolesFitForYouProjectContext = {
  readonly __typename?: 'RolesFitForYouProjectContext';
  readonly castingCompany: gqlRolesFitForYouCastingCompanyContext;
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly shootDate: Scalars['String'];
  readonly type: Scalars['String'];
  readonly union: Scalars['String'];
  readonly url: Scalars['String'];
};

/** Context object for FFY role object */
export type gqlRolesFitForYouRoleContext = {
  readonly __typename?: 'RolesFitForYouRoleContext';
  readonly ageRange: Scalars['String'];
  readonly buttonText: Scalars['String'];
  readonly buttonURL: Scalars['String'];
  readonly description: Scalars['String'];
  readonly ethnicAppearance: Scalars['String'];
  readonly genderAppearance: Scalars['String'];
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly rateSummary: Scalars['String'];
  readonly sexualSituations: Scalars['String'];
  readonly type: Scalars['String'];
  readonly union?: Maybe<Scalars['String']>;
};

export type gqlRolesPublishedCastingCompanyContext = {
  readonly __typename?: 'RolesPublishedCastingCompanyContext';
  readonly castingAssistant: Scalars['String'];
  readonly castingAssociate: Scalars['String'];
  readonly castingDirector: Scalars['String'];
  readonly email: Scalars['String'];
  readonly logo: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phone: Scalars['String'];
};

/** Contex object for roles published notification */
export type gqlRolesPublishedContext = {
  readonly __typename?: 'RolesPublishedContext';
  /** Count of additional roles not in newRoles or roles fields */
  readonly additionalRolesCount?: Maybe<Scalars['Float']>;
  readonly buttonURL: Scalars['String'];
  readonly manageEmailSettingsUrl: Scalars['String'];
  readonly messageForReps: Scalars['String'];
  /** New published roles within the project */
  readonly newRoles?: Maybe<ReadonlyArray<gqlRolesPublishedRoleContext>>;
  readonly project: gqlRolesPublishedProjectContext;
  /** Published roles(currently or previously) which match divisions */
  readonly roles: ReadonlyArray<gqlRolesPublishedRoleContext>;
};

export type gqlRolesPublishedContextNotificationContextAndRecipient = {
  readonly __typename?: 'RolesPublishedContextNotificationContextAndRecipient';
  readonly context: gqlRolesPublishedContext;
  readonly to?: Maybe<Scalars['String']>;
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toOrgId?: Maybe<Scalars['Int']>;
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

export type gqlRolesPublishedCreativeTeamContext = {
  readonly __typename?: 'RolesPublishedCreativeTeamContext';
  readonly adAgency: Scalars['String'];
  readonly director: Scalars['String'];
  readonly executiveProducer: Scalars['String'];
  readonly photographer: Scalars['String'];
  readonly producer: Scalars['String'];
  readonly productionCompany: Scalars['String'];
  readonly studio: Scalars['String'];
  readonly writer: Scalars['String'];
};

export type gqlRolesPublishedProjectContext = {
  readonly __typename?: 'RolesPublishedProjectContext';
  readonly additionalProvisionsMessage: Scalars['String'];
  readonly attachmentDetails: ReadonlyArray<gqlAttachmentDetails>;
  readonly attachments: ReadonlyArray<Scalars['String']>;
  readonly auditionType?: Maybe<gqlAuditionType>;
  readonly castingCompany: gqlRolesPublishedCastingCompanyContext;
  readonly conflicts: Scalars['String'];
  readonly creativeTeam: gqlRolesPublishedCreativeTeamContext;
  readonly name: Scalars['String'];
  readonly projectNotesMessage: Scalars['String'];
  readonly projectSynopsisMessage: Scalars['String'];
  readonly projectTypeId: Scalars['Int'];
  readonly projectUnionIds: ReadonlyArray<Scalars['Int']>;
  readonly rate: Scalars['String'];
  readonly type: Scalars['String'];
  readonly unions: Scalars['String'];
  readonly usageMessage: Scalars['String'];
};

export type gqlRolesPublishedRoleContext = {
  readonly __typename?: 'RolesPublishedRoleContext';
  readonly roleDetails: gqlRolesPublishedRoleDetailsContext;
  readonly roleId: Scalars['Int'];
  readonly roleName: Scalars['String'];
};

export type gqlRolesPublishedRoleDetailsContext = {
  readonly __typename?: 'RolesPublishedRoleDetailsContext';
  readonly ageRange: Scalars['String'];
  readonly ethnicAppearance: Scalars['String'];
  readonly genderAppearance: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly roleTargetId: Scalars['Int'];
  readonly type: Scalars['String'];
};

/** 'Roster Invitation Request' type */
export type gqlRosterInvitationRequest = {
  readonly __typename?: 'RosterInvitationRequest';
  /** Request's ID */
  readonly id: Scalars['ID'];
  /** Should be email address of invitor included into the request or not */
  readonly includeOwnEmail: Scalars['Boolean'];
  readonly invitations: ReadonlyArray<gqlRosterTalentInvitation>;
  /** Note for the invited talent */
  readonly note?: Maybe<Scalars['String']>;
};

/** Create Roster Invitation input */
export type gqlRosterInvitationRequestInput = {
  readonly includeOwnEmail: Scalars['Boolean'];
  readonly invitations: ReadonlyArray<gqlRosterInvitationTalentInput>;
  readonly note?: InputMaybe<Scalars['String']>;
};

/** Roster Invitation request status */
export enum gqlRosterInvitationRequestStatus {
  EXPIRED = 'EXPIRED',
  IN_ROSTER = 'IN_ROSTER',
  PENDING = 'PENDING'
}

export type gqlRosterInvitationTalentHistoryFilterInput = {
  /** Filter by email */
  readonly email: Scalars['String'];
  /** Filter by name */
  readonly name: Scalars['String'];
};

export type gqlRosterInvitationTalentInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
};

/** 'Roster Talent Invitation' type */
export type gqlRosterTalentInvitation = {
  readonly __typename?: 'RosterTalentInvitation';
  /** Invitation created date */
  readonly createdAt: Scalars['DateTime'];
  /** Talent's email */
  readonly email: Scalars['String'];
  /** Invitation expiration date */
  readonly expiredAt: Scalars['DateTime'];
  /** Talent's invitation ID */
  readonly id: Scalars['ID'];
  /** Talent's name */
  readonly name: Scalars['String'];
  /** ID of organization which invited talent to the roster */
  readonly organizationId: Scalars['Int'];
  /** Invitation profile Id */
  readonly profileId?: Maybe<Scalars['Int']>;
  /** Current Profile Status */
  readonly profileStatusCode?: Maybe<Scalars['String']>;
  /** Status of talent's invitation */
  readonly status: gqlRosterInvitationRequestStatus;
  /** Generated token for invited talent */
  readonly token: Scalars['String'];
};

export type gqlRosterTalentInvitationPagedList = {
  readonly __typename?: 'RosterTalentInvitationPagedList';
  readonly data: ReadonlyArray<gqlRosterTalentInvitation>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** RPC Form Bulk Response Item */
export type gqlRpcFormBulkResponseItem = {
  readonly __typename?: 'RpcFormBulkResponseItem';
  readonly formResponseDetails: gqlFormBulkResponseFormResponse;
  readonly profileDetails: gqlFormBulkResponseProfileDetails;
  readonly repDetails?: Maybe<gqlFormBulkResponseRepDetails>;
  readonly roleDetails?: Maybe<gqlRpcRoleInfo>;
};

/** RPC Role Info */
export type gqlRpcRoleInfo = {
  readonly __typename?: 'RpcRoleInfo';
  readonly id: Scalars['Int'];
  readonly internalName?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type gqlSaveDraftAskQuestionRequestArgs = {
  /** Updates draft if id is passed else creates a new draft */
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly payload: gqlAskQuestionRequestInput;
};

export type gqlSaveDraftMediaRequestArgs = {
  /** Updates draft if id is passed else creates a new draft */
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly payload: gqlMediaRequestInput;
};

export type gqlSaveFormResponseResult = {
  readonly __typename?: 'SaveFormResponseResult';
  /** The ID of the saved form response */
  readonly formResponseId?: Maybe<Scalars['BigInt']>;
};

export type gqlSavedSearch = {
  readonly __typename?: 'SavedSearch';
  readonly attributes: gqlSavedSearchAttributes;
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly notificationType?: Maybe<Scalars['String']>;
};

export type gqlSavedSearchAttributes = {
  readonly __typename?: 'SavedSearchAttributes';
  readonly ethnicAppearances?: Maybe<ReadonlyArray<gqlEthnicAppearance>>;
  readonly excludeSexualSituations?: Maybe<Scalars['Boolean']>;
  readonly genderAppearances?: Maybe<ReadonlyArray<gqlGenderAppearance>>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly paidTypes?: Maybe<ReadonlyArray<gqlPaidType>>;
  readonly playableAge?: Maybe<gqlAgeRange>;
  readonly projectTypes?: Maybe<ReadonlyArray<gqlProjectType>>;
  readonly roleProjectKeywordSearch?: Maybe<gqlRoleProjectKeywordSearchEnum>;
  readonly roleTargets?: Maybe<ReadonlyArray<gqlRoleTarget>>;
  readonly showRolesNoLongerAcceptingSubmissions?: Maybe<Scalars['Boolean']>;
  readonly talentUnions?: Maybe<ReadonlyArray<gqlTalentUnion>>;
  readonly workingLocations?: Maybe<ReadonlyArray<gqlDma>>;
};

export type gqlSavedSearchList = {
  readonly __typename?: 'SavedSearchList';
  readonly items: ReadonlyArray<gqlSavedSearch>;
  readonly nextKeyOffset?: Maybe<Scalars['String']>;
};

/** Schedule */
export type gqlSchedule = {
  readonly __typename?: 'Schedule';
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** canceled status */
  readonly isCanceled?: Maybe<Scalars['Boolean']>;
  /** Updated Date */
  readonly lastUpdated?: Maybe<Scalars['DateTime']>;
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Schedule Date */
  readonly scheduleDate?: Maybe<Scalars['DateTime']>;
  /** scheduleType */
  readonly scheduleType?: Maybe<Scalars['String']>;
  readonly statusCounts: gqlScheduleInviteResponseStatusCounts;
  /** Submissions Count */
  readonly submissionCount?: Maybe<Scalars['BigInt']>;
  /** Virtual Audition Details */
  readonly virtualAudition?: Maybe<gqlScheduleVirtualAudition>;
};

/** Schedule Details */
export type gqlScheduleDetail = {
  readonly __typename?: 'ScheduleDetail';
  /** Schedule Address */
  readonly address?: Maybe<gqlAddress>;
  /** Calendar Type */
  readonly calendarType?: Maybe<gqlCalendarType>;
  /** Schedule Date */
  readonly date?: Maybe<Scalars['DateTime']>;
  readonly hasScheduledRepsSubmissions: Scalars['Boolean'];
  /** Schedule Id */
  readonly id?: Maybe<Scalars['ID']>;
  readonly isLocationHidden: Scalars['Boolean'];
  /** Schedule Name */
  readonly name: Scalars['String'];
  /** Note */
  readonly note: Scalars['String'];
  /** Project Id */
  readonly projectId?: Maybe<Scalars['Int']>;
  /** Schedule Id */
  readonly scheduleId?: Maybe<Scalars['ID']>;
  readonly scheduleInviteRequestNotes: gqlScheduleInviteRequestNotes;
  /** Time Zone */
  readonly timeZone?: Maybe<gqlTimeZone>;
  readonly timeframes: ReadonlyArray<gqlScheduleTimeframe>;
  readonly timeframesSummary: ReadonlyArray<gqlScheduleTimeframeSummary>;
  readonly version?: Maybe<Scalars['Int']>;
  /** Virtual Audition Details */
  readonly virtualAudition?: Maybe<gqlScheduleVirtualAudition>;
};

/** Schedule History */
export type gqlScheduleHistory = {
  readonly __typename?: 'ScheduleHistory';
  /** Schedule Address */
  readonly address?: Maybe<gqlAddress>;
  /** Calendar Type */
  readonly calendarType?: Maybe<gqlCalendarType>;
  /** Schedule History created date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Schedule Date */
  readonly date?: Maybe<Scalars['DateTime']>;
  readonly hasScheduledRepsSubmissions: Scalars['Boolean'];
  /** Schedule Id */
  readonly id?: Maybe<Scalars['ID']>;
  readonly isLocationHidden: Scalars['Boolean'];
  /** Schedule History modified by */
  readonly modifiedBy?: Maybe<Scalars['Float']>;
  /** Schedule Name */
  readonly name: Scalars['String'];
  /** Note */
  readonly note: Scalars['String'];
  /** Schedule History operation */
  readonly operation?: Maybe<Scalars['String']>;
  /** Schedule History operation time */
  readonly operationTime?: Maybe<Scalars['DateTime']>;
  /** Project Id */
  readonly projectId?: Maybe<Scalars['Int']>;
  /** Schedule Id */
  readonly scheduleId?: Maybe<Scalars['ID']>;
  readonly scheduleInviteRequestNotes: gqlScheduleInviteRequestNotes;
  /** Time Zone */
  readonly timeZone?: Maybe<gqlTimeZone>;
  readonly timeframes: ReadonlyArray<gqlScheduleTimeframe>;
  readonly timeframesSummary: ReadonlyArray<gqlScheduleTimeframeSummary>;
  readonly version?: Maybe<Scalars['Int']>;
  /** Virtual Audition Details */
  readonly virtualAudition?: Maybe<gqlScheduleVirtualAudition>;
};

/** Schedule Information */
export type gqlScheduleInfo = {
  readonly __typename?: 'ScheduleInfo';
  /** Audition Scheduled */
  readonly auditionScheduled?: Maybe<Scalars['Boolean']>;
  /** Callback Scheduled */
  readonly callbackScheduled?: Maybe<Scalars['Boolean']>;
  /** Submission Id */
  readonly submissionId?: Maybe<Scalars['BigInt']>;
};

export type gqlScheduleInvitePreviousResponse = {
  readonly __typename?: 'ScheduleInvitePreviousResponse';
  readonly id: Scalars['ID'];
  readonly responseNote?: Maybe<Scalars['String']>;
  readonly schedule: gqlWfSchedule;
  readonly slotDate?: Maybe<Scalars['DateTime']>;
  readonly status: gqlScheduleInviteResponseStatus;
  readonly submissionId: Scalars['Int'];
};

/**
 * Schedule Invite Request input. The Schedule Invite is always sent from a
 * schedule and some fields are based on the schedule data.
 */
export type gqlScheduleInviteRequestInput = {
  /** A message to Representatives of the talents that are connected to submissions. */
  readonly noteForRepresentative?: InputMaybe<Scalars['String']>;
  /** A message to Talent of the talents that are connected to submissions. */
  readonly noteForTalents?: InputMaybe<Scalars['String']>;
  /** Filter Requests by role */
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Schedule Id */
  readonly scheduleId: Scalars['Int'];
  /** Field for choosing which requests should trigger email notification. */
  readonly scheduleReceiver: gqlScheduleReceiver;
  /** Schedule type: AUDITION / CALLBACK */
  readonly type: gqlScheduleRequestType;
};

export type gqlScheduleInviteRequestNotes = {
  readonly __typename?: 'ScheduleInviteRequestNotes';
  readonly noteForRepresentative?: Maybe<Scalars['String']>;
  readonly noteForTalents?: Maybe<Scalars['String']>;
};

/** Schedule request status */
export enum gqlScheduleInviteRequestStatus {
  CANCELLED = 'CANCELLED',
  CANCELLED_RESCHEDULED = 'CANCELLED_RESCHEDULED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  ON_HOLD = 'ON_HOLD',
  PENDING = 'PENDING',
  RESCHEDULED = 'RESCHEDULED',
  RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED',
  UPDATED = 'UPDATED'
}

export type gqlScheduleInviteResponse = {
  readonly __typename?: 'ScheduleInviteResponse';
  readonly chatTopic?: Maybe<gqlWfChatTopic>;
  readonly chatTopicKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly note?: Maybe<Scalars['String']>;
  readonly prevRevisionResponseStatus?: Maybe<gqlScheduleInviteRequestStatus>;
  readonly repliedAt?: Maybe<Scalars['DateTime']>;
  readonly sentAt?: Maybe<Scalars['DateTime']>;
  readonly slotId: Scalars['Int'];
  readonly slotStartDate?: Maybe<Scalars['DateTime']>;
  readonly status: gqlScheduleInviteRequestStatus;
  readonly submissionId: Scalars['Int'];
};

/** Schedule Invite Response input that can be sent by a Representative or Talent user. */
export type gqlScheduleInviteResponseInput = {
  /** Schedule Invite Response id. */
  readonly id: Scalars['ID'];
  /** A response: a user can confirm, decline or request rescheduling the Schedule. */
  readonly response: gqlScheduleInviteResponseStatus;
  /** A note. Should be filled if declining or rescheduling the invite. */
  readonly responseNote?: InputMaybe<Scalars['String']>;
};

export enum gqlScheduleInviteResponseStatus {
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED'
}

export type gqlScheduleInviteResponseStatusCounts = {
  readonly __typename?: 'ScheduleInviteResponseStatusCounts';
  readonly confirmed: Scalars['Int'];
  readonly declined: Scalars['Int'];
  readonly notSent: Scalars['Int'];
  readonly pending: Scalars['Int'];
  readonly rescheduleRequested: Scalars['Int'];
  readonly scheduleId: Scalars['Int'];
};

/** Schedule Invite Response input that can be sent by a Casting Director */
export type gqlScheduleInviteSetStatusInput = {
  /** Schedule Invite Response id. */
  readonly id: Scalars['ID'];
  /** A response: a user can confirm, decline or request rescheduling the Schedule. */
  readonly response: gqlScheduleInviteResponseStatus;
};

/**
 * Schedule Invite Request type. The Schedule Invite is always sent from a schedule
 * and some fields are based on the schedule data.
 */
export type gqlScheduleInviteSubRequest = {
  readonly __typename?: 'ScheduleInviteSubRequest';
  /** Cancellation note */
  readonly cancellationNote?: Maybe<Scalars['String']>;
  readonly chatTopicKey: Scalars['String'];
  /** Forwarded Note */
  readonly forwardNote?: Maybe<Scalars['String']>;
  /** Schedule Invite Request id. */
  readonly id: Scalars['ID'];
  /** A message to Representatives of the talents that are connected to submissions. */
  readonly noteForRepresentative?: Maybe<Scalars['String']>;
  /** An instruction for Talents connected to submissions. */
  readonly noteForTalents?: Maybe<Scalars['String']>;
  /** Previous Response Note */
  readonly prevResponseNote?: Maybe<Scalars['String']>;
  /** Previous Response Status */
  readonly prevResponseStatus?: Maybe<Scalars['String']>;
  /** Previous Slot Date */
  readonly prevSlotDate?: Maybe<Scalars['DateTime']>;
  readonly profile?: Maybe<gqlWfProfile>;
  readonly project: gqlWfProject;
  /** Reps Worksheet Status */
  readonly repsWorksheetStatus: Scalars['String'];
  readonly responseNote?: Maybe<Scalars['String']>;
  /** Response Status */
  readonly responseStatus?: Maybe<Scalars['String']>;
  readonly role: gqlWfRole;
  readonly schedule?: Maybe<gqlWfSchedule>;
  /** Date of Slot */
  readonly slotDate?: Maybe<Scalars['DateTime']>;
  readonly status: gqlScheduleInviteRequestStatus;
  readonly submissionId: Scalars['Int'];
};

/** Paginated result with [Schedule] objects */
export type gqlSchedulePaginatedResult = {
  readonly __typename?: 'SchedulePaginatedResult';
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of Schedule objects */
  readonly objects: ReadonlyArray<gqlSchedule>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export enum gqlScheduleReceiver {
  ALL = 'ALL',
  UPDATED = 'UPDATED',
  UPDATED_AND_UNCONFIRMED = 'UPDATED_AND_UNCONFIRMED'
}

export type gqlScheduleReminderCastingCompanyContext = {
  readonly __typename?: 'ScheduleReminderCastingCompanyContext';
  readonly logo: Scalars['String'];
  readonly name: Scalars['String'];
};

/** Contex object for audition/callback notification */
export type gqlScheduleReminderContext = {
  readonly __typename?: 'ScheduleReminderContext';
  readonly buttonText: Scalars['String'];
  readonly buttonURL?: Maybe<Scalars['String']>;
  readonly dividerText: Scalars['String'];
  readonly heading: Scalars['String'];
  readonly instructionForTalent?: Maybe<Scalars['String']>;
  readonly locationNote?: Maybe<Scalars['String']>;
  readonly manageEmailSettingsUrl: Scalars['String'];
  readonly profile: gqlScheduleReminderProfileContext;
  readonly project: gqlScheduleReminderProjectContext;
  readonly receiverEmail?: Maybe<Scalars['String']>;
  readonly role: gqlScheduleReminderRoleContext;
  readonly scheduleDate: Scalars['String'];
  readonly scheduleLocation: Scalars['String'];
  readonly scheduleType: Scalars['String'];
  readonly subHeading: Scalars['String'];
};

export type gqlScheduleReminderContextNotificationContextAndRecipient = {
  readonly __typename?: 'ScheduleReminderContextNotificationContextAndRecipient';
  readonly context: gqlScheduleReminderContext;
  readonly to?: Maybe<Scalars['String']>;
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toOrgId?: Maybe<Scalars['Int']>;
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

export type gqlScheduleReminderProfileContext = {
  readonly __typename?: 'ScheduleReminderProfileContext';
  readonly id: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly primaryPhotoUrl: Scalars['String'];
};

export type gqlScheduleReminderProjectContext = {
  readonly __typename?: 'ScheduleReminderProjectContext';
  readonly castingCompany: gqlScheduleReminderCastingCompanyContext;
  readonly name: Scalars['String'];
};

export type gqlScheduleReminderRoleContext = {
  readonly __typename?: 'ScheduleReminderRoleContext';
  readonly name: Scalars['String'];
};

/** Schedule Report Input Arguments */
export type gqlScheduleReportCreateInput = {
  readonly castingNotes?: InputMaybe<Scalars['Boolean']>;
  readonly confirmationStatuses?: InputMaybe<Scalars['Boolean']>;
  readonly displayTalentInfoOptions?: InputMaybe<ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>>;
  readonly displayTalentPhotoOptions?: InputMaybe<ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>>;
  readonly includeTimeframes?: InputMaybe<Scalars['Boolean']>;
  readonly repContactInfo?: InputMaybe<Scalars['Boolean']>;
  readonly scheduleId: Scalars['Int'];
  readonly submissionNotes?: InputMaybe<Scalars['Boolean']>;
};

/** Schedule Report */
export type gqlScheduleReportModel = {
  readonly __typename?: 'ScheduleReportModel';
  readonly castingNotes?: Maybe<Scalars['Boolean']>;
  readonly confirmationStatuses?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly displayTalentInfoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>>;
  readonly displayTalentPhotoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>>;
  readonly id: Scalars['ID'];
  readonly includeTimeframes?: Maybe<Scalars['Boolean']>;
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlScheduleReportPayloadModel>;
  readonly repContactInfo?: Maybe<Scalars['Boolean']>;
  readonly reportType: gqlReportTypeEnum;
  readonly scheduleId: Scalars['Int'];
  readonly status: gqlReportStatusEnum;
  readonly submissionNotes?: Maybe<Scalars['Boolean']>;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Schedule Report Payload */
export type gqlScheduleReportPayloadModel = {
  readonly __typename?: 'ScheduleReportPayloadModel';
  readonly castingCompanyLogo?: Maybe<gqlReportMediaModel>;
  readonly projectName: Scalars['String'];
  readonly scheduleDate: Scalars['DateTime'];
  readonly scheduleType: gqlCalendarTypeEnum;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly timeframes: ReadonlyArray<gqlScheduleReportTimeframeModel>;
};

/** Schedule Report Submission */
export type gqlScheduleReportSubmissionModel = {
  readonly __typename?: 'ScheduleReportSubmissionModel';
  /** Agency contacts for repped talent */
  readonly agencyContacts?: Maybe<gqlReportContactInfoModel>;
  /** @deprecated use talentPhone */
  readonly agencyPhone?: Maybe<Scalars['String']>;
  readonly castingNote?: Maybe<Scalars['String']>;
  readonly cover?: Maybe<gqlReportMediaModel>;
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  readonly genderAppearances?: Maybe<ReadonlyArray<gqlSubFieldsGql>>;
  readonly headshotMedia?: Maybe<gqlReportMediaModel>;
  readonly id: Scalars['Int'];
  readonly isPersonal?: Maybe<Scalars['Boolean']>;
  readonly isRepresented?: Maybe<Scalars['Boolean']>;
  readonly minor?: Maybe<Scalars['Boolean']>;
  readonly notes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly orgAbbreviation?: Maybe<Scalars['String']>;
  readonly orgName?: Maybe<Scalars['String']>;
  readonly orgParentName?: Maybe<Scalars['String']>;
  readonly orgPhone?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly profileId?: Maybe<Scalars['Float']>;
  readonly roleName: Scalars['String'];
  readonly scheduleResponseStatus?: Maybe<Scalars['String']>;
  readonly sizeSheets?: Maybe<gqlPresentationSizeSheetsTalentModel>;
  readonly snapshotMedia?: Maybe<gqlReportMediaModel>;
  readonly stageName?: Maybe<Scalars['String']>;
  readonly submissionNote?: Maybe<Scalars['String']>;
  readonly talentEmail?: Maybe<Scalars['String']>;
  readonly talentPhone?: Maybe<Scalars['String']>;
  readonly talentUnions?: Maybe<ReadonlyArray<Scalars['String']>>;
};

/** Schedule Report Timeframe */
export type gqlScheduleReportTimeframeModel = {
  readonly __typename?: 'ScheduleReportTimeframeModel';
  readonly endDate: Scalars['DateTime'];
  readonly id: Scalars['Int'];
  readonly isBreak: Scalars['Boolean'];
  readonly startDate: Scalars['DateTime'];
  readonly timeslots: ReadonlyArray<gqlScheduleReportTimeslotModel>;
};

/** Schedule Report Timeslot */
export type gqlScheduleReportTimeslotModel = {
  readonly __typename?: 'ScheduleReportTimeslotModel';
  readonly endDate: Scalars['DateTime'];
  readonly id: Scalars['Int'];
  readonly startDate: Scalars['DateTime'];
  readonly submissions: ReadonlyArray<gqlScheduleReportSubmissionModel>;
};

export enum gqlScheduleRequestType {
  AUDITION = 'AUDITION',
  CALLBACK = 'CALLBACK'
}

/** Search input */
export type gqlScheduleSearchInput = {
  /** List of Project Id to search in */
  readonly projectId?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly scheduleType?: InputMaybe<gqlCalendarTypeEnum>;
};

/** Schedule Size Sheeets Report Input Arguments */
export type gqlScheduleSizeSheetsReportCreateInput = {
  readonly displayTalentInfoOptions: ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>;
  readonly displayTalentPerPageOptions: gqlDisplayTalentPerPageOptionsEnum;
  readonly displayTalentPhotoOptions: ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>;
  readonly scheduleId: Scalars['Int'];
};

/** Schedule Size Sheets Report */
export type gqlScheduleSizeSheetsReportModel = {
  readonly __typename?: 'ScheduleSizeSheetsReportModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly displayTalentInfoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentInfoOptionsEnum>>;
  readonly displayTalentPerPageOptions?: Maybe<gqlDisplayTalentPerPageOptionsEnum>;
  readonly displayTalentPhotoOptions?: Maybe<ReadonlyArray<gqlDisplayTalentPhotoOptionsEnum>>;
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlScheduleReportPayloadModel>;
  readonly reportType: gqlReportTypeEnum;
  readonly scheduleId: Scalars['Int'];
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Object for representing a Schedule Slot. */
export type gqlScheduleSlot = {
  readonly __typename?: 'ScheduleSlot';
  /** Slot Id */
  readonly id?: Maybe<Scalars['ID']>;
  /** Slot End Time */
  readonly slotEndTime?: Maybe<Scalars['DateTime']>;
  /** Slot Id */
  readonly slotId?: Maybe<Scalars['Float']>;
  /** Slot Start Time */
  readonly slotStartTime?: Maybe<Scalars['DateTime']>;
  readonly submissions: ReadonlyArray<gqlSubmission>;
};

export type gqlScheduleSlotSummary = {
  readonly __typename?: 'ScheduleSlotSummary';
  readonly endTime: Scalars['DateTime'];
  readonly id: Scalars['Int'];
  readonly roleCount: Scalars['Int'];
  readonly startTime: Scalars['DateTime'];
  readonly submissionCount: Scalars['Int'];
  readonly timeframeId: Scalars['Int'];
};

export type gqlScheduleTalentPool = {
  readonly __typename?: 'ScheduleTalentPool';
  readonly roleId: Scalars['Int'];
  readonly submissionCount: Scalars['Int'];
  readonly submissions?: Maybe<ReadonlyArray<gqlSubmission>>;
};

/** Schedule Timeframe Request Summary */
export type gqlScheduleTimeframRequestSummary = {
  readonly __typename?: 'ScheduleTimeframRequestSummary';
  readonly count: Scalars['Int'];
  readonly status: Scalars['String'];
};

/** Schedule Timeframe Details */
export type gqlScheduleTimeframe = {
  readonly __typename?: 'ScheduleTimeframe';
  /** Timeframe Duration */
  readonly durations?: Maybe<ReadonlyArray<Scalars['Float']>>;
  /** Timeframe End Time */
  readonly endTime?: Maybe<Scalars['DateTime']>;
  /** Timeframe Id */
  readonly id?: Maybe<Scalars['ID']>;
  /** Is this timeframe represents a break */
  readonly isBreak: Scalars['Boolean'];
  /** Timeframe Name */
  readonly name: Scalars['String'];
  /** List of Slots in this Timeframe */
  readonly slots?: Maybe<ReadonlyArray<gqlScheduleSlot>>;
  /** Timeframe Start Time */
  readonly startTime?: Maybe<Scalars['DateTime']>;
};

/** Schedule Timeframe Role Summary */
export type gqlScheduleTimeframeRoleSummary = {
  readonly __typename?: 'ScheduleTimeframeRoleSummary';
  readonly id: Scalars['ID'];
  readonly internalName?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly submissionCount: Scalars['Int'];
  readonly timeframeId: Scalars['ID'];
};

export type gqlScheduleTimeframeShiftedResult = {
  readonly __typename?: 'ScheduleTimeframeShiftedResult';
  readonly submissionIds: ReadonlyArray<Scalars['BigInt']>;
  readonly timeframeIds: ReadonlyArray<Scalars['Int']>;
};

/** Schedule Timeframe Summary */
export type gqlScheduleTimeframeSummary = {
  readonly __typename?: 'ScheduleTimeframeSummary';
  /** Timeframe Duration */
  readonly durations?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly emptySlotsCount: Scalars['Int'];
  readonly endTime: Scalars['DateTime'];
  /** Timeframe Id */
  readonly id: Scalars['ID'];
  readonly isBreak: Scalars['Boolean'];
  readonly requestSummary: ReadonlyArray<gqlScheduleTimeframRequestSummary>;
  readonly rolesSummary: ReadonlyArray<gqlScheduleTimeframeRoleSummary>;
  readonly slotsCount: Scalars['Int'];
  readonly startTime: Scalars['DateTime'];
};

/** Virtual Audition Details */
export type gqlScheduleVirtualAudition = {
  readonly __typename?: 'ScheduleVirtualAudition';
  readonly attendees: ReadonlyArray<gqlVirtualAuditionAttendee>;
  readonly id: Scalars['ID'];
  readonly joinUrl?: Maybe<Scalars['String']>;
  readonly observerUrl?: Maybe<Scalars['String']>;
};

/** Search Schedules input */
export type gqlSchedulesSelectInput = {
  /** Schedule ids to search in */
  readonly scheduleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** List of Unscheduled submissions */
  readonly unscheduled?: InputMaybe<Scalars['Boolean']>;
};

export type gqlSearchAccountsFiltersArgs = {
  readonly accountId?: InputMaybe<Scalars['Int']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly firstName?: InputMaybe<Scalars['String']>;
  readonly former?: InputMaybe<Scalars['Boolean']>;
  readonly lastName?: InputMaybe<Scalars['String']>;
  readonly legacyEmail?: InputMaybe<Scalars['String']>;
  readonly legacyInstance?: InputMaybe<Scalars['String']>;
  readonly legacyLogin?: InputMaybe<Scalars['String']>;
  readonly organizationIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly systemRoleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export enum gqlSearchArtistMediaIncludeActive {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  INACTIVE = 'INACTIVE'
}

export enum gqlSearchArtistMediaIncludeStatus {
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
  LOCKED = 'LOCKED',
  READY = 'READY',
  UNLOCKED = 'UNLOCKED'
}

export enum gqlSearchArtistMediaIncludeType {
  ALL = 'ALL',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

export type gqlSearchArtistMediaInput = {
  readonly artistId?: InputMaybe<Scalars['Int']>;
  readonly includeStatus?: InputMaybe<ReadonlyArray<gqlSearchArtistMediaIncludeStatus>>;
  readonly includeType?: InputMaybe<ReadonlyArray<gqlSearchArtistMediaIncludeType>>;
  readonly mediaIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly page?: InputMaybe<gqlPageInput>;
  readonly query?: InputMaybe<gqlSearchArtistMediaQueryInput>;
};

export type gqlSearchArtistMediaQueryInput = {
  readonly artistId: Scalars['Int'];
  readonly includeActive?: InputMaybe<ReadonlyArray<gqlSearchArtistMediaIncludeActive>>;
  readonly includeType?: InputMaybe<ReadonlyArray<gqlSearchArtistMediaIncludeType>>;
  readonly mediaIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export enum gqlSearchDirection {
  Asc = 'Asc',
  Dsc = 'Dsc'
}

export type gqlSearchMediaFilter = {
  /** A filter for search results by status. Defaults to null meaning no filtering by status. */
  readonly status?: InputMaybe<ReadonlyArray<gqlSearchMediaStatus>>;
  /** A filter for search results by type. Defaults to null meaning no filtering by type. */
  readonly type?: InputMaybe<ReadonlyArray<gqlSearchMediaType>>;
};

/** Provides filtering, sorting, and pagination for Media searches. */
export type gqlSearchMediaInput = {
  /** Sorts by primary media then non-archivable and finally archivable media before applying the sort */
  readonly applyPrimarySort?: InputMaybe<Scalars['Boolean']>;
  /** Filters results to media that belongs to the specified artist. Defaults to null meaning no filtering by artist. */
  readonly artistId?: InputMaybe<Scalars['ID']>;
  /** Filters for search results. Defaults to null meaning no filtering. */
  readonly filter?: InputMaybe<gqlSearchMediaFilter>;
  /** Pagination for search results. Defaults to first page with 10 results. */
  readonly page: gqlPageInput;
  /** Sorts search results. Defaults to Updated. */
  readonly sortBy?: InputMaybe<gqlSearchMediaSortBy>;
  /** Direction used to sort search results. Defaults to Desc. */
  readonly sortOrder?: InputMaybe<gqlSearchMediaSortOrder>;
};

export type gqlSearchMediaPaginatedResult = {
  readonly __typename?: 'SearchMediaPaginatedResult';
  readonly lastKey?: Maybe<Scalars['String']>;
  readonly objects: ReadonlyArray<gqlMedia>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

/** Value used to sort Media search results. */
export enum gqlSearchMediaSortBy {
  Created = 'Created',
  Status = 'Status',
  Updated = 'Updated'
}

/** Direction used to sort Media search results. */
export enum gqlSearchMediaSortOrder {
  Asc = 'Asc',
  Desc = 'Desc'
}

/** Value used to filter Media search results by status. */
export enum gqlSearchMediaStatus {
  Archived = 'Archived',
  CanArchive = 'CanArchive',
  Ready = 'Ready'
}

/** Value used to filter Media search results by type. */
export enum gqlSearchMediaType {
  Audio = 'Audio',
  Photo = 'Photo',
  Video = 'Video'
}

export type gqlSearchNotificationFiltersArgs = {
  readonly archived?: InputMaybe<Scalars['Boolean']>;
};

export type gqlSearchOrganizationsFiltersArgs = {
  /** Organization name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  readonly organizationId?: InputMaybe<Scalars['Int']>;
  /** Organization Parent Id */
  readonly organizationParentId?: InputMaybe<Scalars['Int']>;
  /** Organization status code */
  readonly organizationStatusCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Organization Type Id */
  readonly representationTypeId?: InputMaybe<Scalars['Int']>;
  /** System role code */
  readonly systemRoles?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type gqlSearchPageGroupInput = {
  /** Returns dataset after provided key */
  readonly after?: InputMaybe<ReadonlyArray<gqlGroupLastKeyInput>>;
  /** Page size */
  readonly pageSize?: InputMaybe<Scalars['Int']>;
};

export type gqlSearchPageInput = {
  /** Returns dataset after provided key */
  readonly after?: InputMaybe<Scalars['String']>;
  /** Page number */
  readonly pageNumber?: InputMaybe<Scalars['Int']>;
  /** Page size */
  readonly pageSize: Scalars['Int'];
};

export type gqlSearchPageInputWf = {
  /** Returns dataset after provided key */
  readonly after?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Page size */
  readonly pageSize: Scalars['Int'];
};

/** Input type for search representatives */
export type gqlSearchRepresentativeInput = {
  /** Company name */
  readonly companyName?: InputMaybe<Scalars['String']>;
  /** List of Dma CODEs */
  readonly dmas?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representative Age Category CODEs */
  readonly representativeAgeCategoryCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representative Category CODEs */
  readonly representativeCategoryCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Representative Type CODEs */
  readonly representativeTypeCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum gqlSearchResultType {
  ALL = 'ALL',
  DOCUMENTS = 'DOCUMENTS',
  FACETS = 'FACETS',
  GROUPS = 'GROUPS'
}

/** SeekingRepresentation object */
export type gqlSeekingRepresentation = {
  readonly __typename?: 'SeekingRepresentation';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlSendDirectMessageToTalentsInput = {
  readonly message: Scalars['String'];
  readonly profileIds: ReadonlyArray<Scalars['Int']>;
  readonly roleId?: InputMaybe<Scalars['Int']>;
  readonly subject?: InputMaybe<Scalars['String']>;
  readonly talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};

export type gqlSendVoiceOverMediaRequestInput = {
  readonly roleId: Scalars['Int'];
  readonly submissionIds: ReadonlyArray<Scalars['Int']>;
  readonly talentInstructions: Scalars['String'];
};

export type gqlSeriesDetails = {
  readonly __typename?: 'SeriesDetails';
  readonly projectSeriesEpisode?: Maybe<Scalars['Int']>;
  readonly projectSeriesId?: Maybe<Scalars['ID']>;
  readonly projectSeriesName?: Maybe<Scalars['String']>;
  readonly projectSeriesSeason?: Maybe<Scalars['Int']>;
};

export type gqlSession = {
  readonly __typename?: 'Session';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly context?: Maybe<gqlAccountContext>;
  readonly deviceId?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
};

export type gqlSetSubscriptionPaymentMethodInput = {
  readonly billingInfoId: Scalars['String'];
  readonly subscriptionId: Scalars['Int'];
};

/** SexualSituation object */
export type gqlSexualSituation = {
  readonly __typename?: 'SexualSituation';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlSexualSituationEnum {
  NUDITY = 'NUDITY',
  SEXUAL_SITUATIONS = 'SEXUAL_SITUATIONS'
}

/** Audition Presentation input type */
export type gqlShareSelectPresentationInput = {
  /** Date of link expiration */
  readonly linkExpiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Project ID */
  readonly projectId: Scalars['Int'];
  /** Role IDs */
  readonly roleIds: ReadonlyArray<Scalars['Int']>;
  /** Tile Scheme Codes */
  readonly tileSchemeCodes: ReadonlyArray<Scalars['String']>;
};

/** Parameters used to create a Share Talent Presentation */
export type gqlShareTalentPresentationInput = {
  /** Date of link expiration */
  readonly linkExpiresAt?: InputMaybe<Scalars['DateTime']>;
  /** The name of the presentation */
  readonly name: Scalars['String'];
  /** Project ID */
  readonly projectId: Scalars['Int'];
  /** Talent from these roles that meet the request criteria will be added to the presentation. */
  readonly roleIds: ReadonlyArray<Scalars['Int']>;
  /** The list of statuses of submissions to add to the presentation. */
  readonly submissionStatuses: ReadonlyArray<gqlSubmissionStatusEnum>;
  /** Determines which title schemes should be added to the presentation */
  readonly tileSchemeCodes: ReadonlyArray<Scalars['String']>;
};

export type gqlShiftTimeframeInput = {
  readonly newStartTime: Scalars['DateTime'];
  readonly scheduleId: Scalars['Int'];
  readonly timeframeId: Scalars['Int'];
};

/** Shoe modifier object */
export type gqlShoeModifier = {
  readonly __typename?: 'ShoeModifier';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Measurement object */
export type gqlShoeSize = {
  readonly __typename?: 'ShoeSize';
  /** Gender */
  readonly category: gqlBasicSetting;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** ShoeSize for AU */
  readonly shoeSizeAU: Scalars['String'];
  /** ShoeSize for EU */
  readonly shoeSizeEU: Scalars['String'];
  /** ShoeSize for UK */
  readonly shoeSizeUK: Scalars['String'];
  /** ShoeSize for US */
  readonly shoeSizeUS: Scalars['String'];
  /** short order */
  readonly sortOrder: Scalars['Float'];
};

export enum gqlSizeGenderEnum {
  BOY = 'BOY',
  FEMALE = 'FEMALE',
  GIRL = 'GIRL',
  INFANT = 'INFANT',
  KID = 'KID',
  MALE = 'MALE',
  TODDLER = 'TODDLER'
}

/** Size Modifier object */
export type gqlSizeModifier = {
  readonly __typename?: 'SizeModifier';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Skill object */
export type gqlSkill = {
  readonly __typename?: 'Skill';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlSkill>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlSkill>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlSkillEnum {
  ACTING_STYLES = 'ACTING_STYLES',
  ACTING_STYLES_ARTAUD = 'ACTING_STYLES_ARTAUD',
  ACTING_STYLES_BRECHT = 'ACTING_STYLES_BRECHT',
  ACTING_STYLES_GROTOWSKY = 'ACTING_STYLES_GROTOWSKY',
  ACTING_STYLES_IMPROVISATION_GENERAL = 'ACTING_STYLES_IMPROVISATION_GENERAL',
  ACTING_STYLES_IMPROVISATION_LONG_FORM = 'ACTING_STYLES_IMPROVISATION_LONG_FORM',
  ACTING_STYLES_IMPROVISATION_MUSICAL = 'ACTING_STYLES_IMPROVISATION_MUSICAL',
  ACTING_STYLES_IMPROVISATION_SHORT_FORM = 'ACTING_STYLES_IMPROVISATION_SHORT_FORM',
  ACTING_STYLES_KABUKI = 'ACTING_STYLES_KABUKI',
  ACTING_STYLES_MEISNER = 'ACTING_STYLES_MEISNER',
  ACTING_STYLES_METHOD = 'ACTING_STYLES_METHOD',
  ACTING_STYLES_SHAKESPEAREAN = 'ACTING_STYLES_SHAKESPEAREAN',
  ACTING_STYLES_SKETCH = 'ACTING_STYLES_SKETCH',
  ACTING_STYLES_STAND_UP = 'ACTING_STYLES_STAND_UP',
  ACTING_STYLES_STANISLAVSKI = 'ACTING_STYLES_STANISLAVSKI',
  ACTING_STYLES_VIOLA_SPOLIN = 'ACTING_STYLES_VIOLA_SPOLIN',
  BAND_STYLES = 'BAND_STYLES',
  BAND_STYLES_ALTERNATIVE = 'BAND_STYLES_ALTERNATIVE',
  BAND_STYLES_ART_ROCK = 'BAND_STYLES_ART_ROCK',
  BAND_STYLES_A_CAPPELLA = 'BAND_STYLES_A_CAPPELLA',
  BAND_STYLES_BACHATA = 'BAND_STYLES_BACHATA',
  BAND_STYLES_BIG_BAND = 'BAND_STYLES_BIG_BAND',
  BAND_STYLES_BLUEGRASS = 'BAND_STYLES_BLUEGRASS',
  BAND_STYLES_BLUES = 'BAND_STYLES_BLUES',
  BAND_STYLES_BOLERO = 'BAND_STYLES_BOLERO',
  BAND_STYLES_BOY_GROUP = 'BAND_STYLES_BOY_GROUP',
  BAND_STYLES_CELTIC = 'BAND_STYLES_CELTIC',
  BAND_STYLES_CLASSICAL_MUSIC = 'BAND_STYLES_CLASSICAL_MUSIC',
  BAND_STYLES_CORRIDO = 'BAND_STYLES_CORRIDO',
  BAND_STYLES_COUNTRY = 'BAND_STYLES_COUNTRY',
  BAND_STYLES_CUMBIA = 'BAND_STYLES_CUMBIA',
  BAND_STYLES_ELECTRONIC = 'BAND_STYLES_ELECTRONIC',
  BAND_STYLES_FOLK = 'BAND_STYLES_FOLK',
  BAND_STYLES_FUNK = 'BAND_STYLES_FUNK',
  BAND_STYLES_GIRL_GROUP = 'BAND_STYLES_GIRL_GROUP',
  BAND_STYLES_GOSPEL = 'BAND_STYLES_GOSPEL',
  BAND_STYLES_HARDCORE = 'BAND_STYLES_HARDCORE',
  BAND_STYLES_HIP_HOP = 'BAND_STYLES_HIP_HOP',
  BAND_STYLES_INDIE = 'BAND_STYLES_INDIE',
  BAND_STYLES_INDUSTRIAL = 'BAND_STYLES_INDUSTRIAL',
  BAND_STYLES_INSTRUMENTAL = 'BAND_STYLES_INSTRUMENTAL',
  BAND_STYLES_JAZZ = 'BAND_STYLES_JAZZ',
  BAND_STYLES_LATIN = 'BAND_STYLES_LATIN',
  BAND_STYLES_MARIACHI = 'BAND_STYLES_MARIACHI',
  BAND_STYLES_METAL = 'BAND_STYLES_METAL',
  BAND_STYLES_NEW_WAVE = 'BAND_STYLES_NEW_WAVE',
  BAND_STYLES_NORTENO = 'BAND_STYLES_NORTENO',
  BAND_STYLES_ONE_MAN = 'BAND_STYLES_ONE_MAN',
  BAND_STYLES_OTHER = 'BAND_STYLES_OTHER',
  BAND_STYLES_POP = 'BAND_STYLES_POP',
  BAND_STYLES_POP_ROCK = 'BAND_STYLES_POP_ROCK',
  BAND_STYLES_PUNK = 'BAND_STYLES_PUNK',
  BAND_STYLES_RANCHERO = 'BAND_STYLES_RANCHERO',
  BAND_STYLES_RAP = 'BAND_STYLES_RAP',
  BAND_STYLES_REGGAE = 'BAND_STYLES_REGGAE',
  BAND_STYLES_REGGAETON = 'BAND_STYLES_REGGAETON',
  BAND_STYLES_RHYTHM_AND_BLUES = 'BAND_STYLES_RHYTHM_AND_BLUES',
  BAND_STYLES_ROCK = 'BAND_STYLES_ROCK',
  BAND_STYLES_ROCKABILLY = 'BAND_STYLES_ROCKABILLY',
  BAND_STYLES_ROCK_AND_ROLL = 'BAND_STYLES_ROCK_AND_ROLL',
  BAND_STYLES_SALSA = 'BAND_STYLES_SALSA',
  BAND_STYLES_SWING = 'BAND_STYLES_SWING',
  BAND_STYLES_TRIBAL = 'BAND_STYLES_TRIBAL',
  BAND_STYLES_WEDDING = 'BAND_STYLES_WEDDING',
  CIRCUS_SKILLS = 'CIRCUS_SKILLS',
  CIRCUS_SKILLS_AERIAL_HOOP_LYRA = 'CIRCUS_SKILLS_AERIAL_HOOP_LYRA',
  CIRCUS_SKILLS_AERIAL_ROPE = 'CIRCUS_SKILLS_AERIAL_ROPE',
  CIRCUS_SKILLS_AERIAL_SILKS = 'CIRCUS_SKILLS_AERIAL_SILKS',
  CIRCUS_SKILLS_AERIAL_WORK_GENERAL = 'CIRCUS_SKILLS_AERIAL_WORK_GENERAL',
  CIRCUS_SKILLS_CLOWNING = 'CIRCUS_SKILLS_CLOWNING',
  CIRCUS_SKILLS_CONTORTION = 'CIRCUS_SKILLS_CONTORTION',
  CIRCUS_SKILLS_CYR_WHEEL = 'CIRCUS_SKILLS_CYR_WHEEL',
  CIRCUS_SKILLS_FIRE_EATING = 'CIRCUS_SKILLS_FIRE_EATING',
  CIRCUS_SKILLS_FIRE_JUGGLING = 'CIRCUS_SKILLS_FIRE_JUGGLING',
  CIRCUS_SKILLS_HIGH_WIRE = 'CIRCUS_SKILLS_HIGH_WIRE',
  CIRCUS_SKILLS_JUGGLING = 'CIRCUS_SKILLS_JUGGLING',
  CIRCUS_SKILLS_RHONRAD_GERMAN_WHEEL = 'CIRCUS_SKILLS_RHONRAD_GERMAN_WHEEL',
  CIRCUS_SKILLS_STILT_WALKING = 'CIRCUS_SKILLS_STILT_WALKING',
  CIRCUS_SKILLS_TIGHT_ROPE = 'CIRCUS_SKILLS_TIGHT_ROPE',
  CIRCUS_SKILLS_TRAPEZE = 'CIRCUS_SKILLS_TRAPEZE',
  CIRCUS_SKILLS_UNICYCLE = 'CIRCUS_SKILLS_UNICYCLE',
  DANCE = 'DANCE',
  DANCE_AFRICAN = 'DANCE_AFRICAN',
  DANCE_BALLET = 'DANCE_BALLET',
  DANCE_BALLROOM = 'DANCE_BALLROOM',
  DANCE_BELLY = 'DANCE_BELLY',
  DANCE_BOLLYWOOD_INDIAN = 'DANCE_BOLLYWOOD_INDIAN',
  DANCE_BREAK = 'DANCE_BREAK',
  DANCE_CHA_CHA = 'DANCE_CHA_CHA',
  DANCE_CLOG = 'DANCE_CLOG',
  DANCE_CLUB_FREESTYLE = 'DANCE_CLUB_FREESTYLE',
  DANCE_CONTEMPORARY = 'DANCE_CONTEMPORARY',
  DANCE_CONTRA_DANCE = 'DANCE_CONTRA_DANCE',
  DANCE_DISCO = 'DANCE_DISCO',
  DANCE_EXOTIC_DANCING = 'DANCE_EXOTIC_DANCING',
  DANCE_FLAMENCO = 'DANCE_FLAMENCO',
  DANCE_FLEXING_BONE_BREAKING = 'DANCE_FLEXING_BONE_BREAKING',
  DANCE_FOLK = 'DANCE_FOLK',
  DANCE_HIP_HOP = 'DANCE_HIP_HOP',
  DANCE_HOUSE = 'DANCE_HOUSE',
  DANCE_HULA = 'DANCE_HULA',
  DANCE_IRISH = 'DANCE_IRISH',
  DANCE_JAZZ = 'DANCE_JAZZ',
  DANCE_JIVE = 'DANCE_JIVE',
  DANCE_KRUMP = 'DANCE_KRUMP',
  DANCE_LINE = 'DANCE_LINE',
  DANCE_MODERN = 'DANCE_MODERN',
  DANCE_MODERN_JAZZ = 'DANCE_MODERN_JAZZ',
  DANCE_POINTE = 'DANCE_POINTE',
  DANCE_POLE = 'DANCE_POLE',
  DANCE_POLKA = 'DANCE_POLKA',
  DANCE_POP_AND_LOCK = 'DANCE_POP_AND_LOCK',
  DANCE_REGGAE = 'DANCE_REGGAE',
  DANCE_ROBOT = 'DANCE_ROBOT',
  DANCE_ROCK_AND_ROLL = 'DANCE_ROCK_AND_ROLL',
  DANCE_RUMBA = 'DANCE_RUMBA',
  DANCE_SALSA = 'DANCE_SALSA',
  DANCE_SAMBA = 'DANCE_SAMBA',
  DANCE_SQUARE = 'DANCE_SQUARE',
  DANCE_SWING = 'DANCE_SWING',
  DANCE_TANGO = 'DANCE_TANGO',
  DANCE_TAP = 'DANCE_TAP',
  DANCE_TUTTING = 'DANCE_TUTTING',
  DANCE_VOGUE = 'DANCE_VOGUE',
  DANCE_WAACKING = 'DANCE_WAACKING',
  DANCE_WALTZ = 'DANCE_WALTZ',
  DIALECTS = 'DIALECTS',
  DIALECTS_AMERICAN = 'DIALECTS_AMERICAN',
  DIALECTS_AMERICAN_MIDWESTERN_MINNESOTA_WISCONSIN = 'DIALECTS_AMERICAN_MIDWESTERN_MINNESOTA_WISCONSIN',
  DIALECTS_AMERICAN_MID_ATLANTIC = 'DIALECTS_AMERICAN_MID_ATLANTIC',
  DIALECTS_AMERICAN_NEW_ENGLAND_BOSTON = 'DIALECTS_AMERICAN_NEW_ENGLAND_BOSTON',
  DIALECTS_AMERICAN_NEW_JERSEY = 'DIALECTS_AMERICAN_NEW_JERSEY',
  DIALECTS_AMERICAN_NEW_YORK_BRONX_BROOKLYN_QUEENS = 'DIALECTS_AMERICAN_NEW_YORK_BRONX_BROOKLYN_QUEENS',
  DIALECTS_AMERICAN_NEW_YORK_LONG_ISLAND = 'DIALECTS_AMERICAN_NEW_YORK_LONG_ISLAND',
  DIALECTS_AMERICAN_SOUTHERN = 'DIALECTS_AMERICAN_SOUTHERN',
  DIALECTS_ARMENIAN = 'DIALECTS_ARMENIAN',
  DIALECTS_AUSTRALIAN = 'DIALECTS_AUSTRALIAN',
  DIALECTS_AUSTRALIAN_ABORIGINAL = 'DIALECTS_AUSTRALIAN_ABORIGINAL',
  DIALECTS_BELGIAN = 'DIALECTS_BELGIAN',
  DIALECTS_BRITISH = 'DIALECTS_BRITISH',
  DIALECTS_CAJUN = 'DIALECTS_CAJUN',
  DIALECTS_CANADIAN = 'DIALECTS_CANADIAN',
  DIALECTS_CHINESE = 'DIALECTS_CHINESE',
  DIALECTS_COCKNEY = 'DIALECTS_COCKNEY',
  DIALECTS_EAST_INDIAN = 'DIALECTS_EAST_INDIAN',
  DIALECTS_FRENCH = 'DIALECTS_FRENCH',
  DIALECTS_FRENCH_CANADIAN = 'DIALECTS_FRENCH_CANADIAN',
  DIALECTS_GERMAN = 'DIALECTS_GERMAN',
  DIALECTS_IRISH = 'DIALECTS_IRISH',
  DIALECTS_ITALIAN = 'DIALECTS_ITALIAN',
  DIALECTS_JAMAICAN = 'DIALECTS_JAMAICAN',
  DIALECTS_JAPANESE = 'DIALECTS_JAPANESE',
  DIALECTS_LATIN_AMERICA_ARGENTINE = 'DIALECTS_LATIN_AMERICA_ARGENTINE',
  DIALECTS_LATIN_AMERICA_BRAZILIAN = 'DIALECTS_LATIN_AMERICA_BRAZILIAN',
  DIALECTS_LATIN_AMERICA_CHILEAN = 'DIALECTS_LATIN_AMERICA_CHILEAN',
  DIALECTS_LATIN_AMERICA_COASTAL = 'DIALECTS_LATIN_AMERICA_COASTAL',
  DIALECTS_LATIN_AMERICA_COLOMBIAN = 'DIALECTS_LATIN_AMERICA_COLOMBIAN',
  DIALECTS_LATIN_AMERICA_CUBAN = 'DIALECTS_LATIN_AMERICA_CUBAN',
  DIALECTS_LATIN_AMERICA_NORTHERN = 'DIALECTS_LATIN_AMERICA_NORTHERN',
  DIALECTS_LIVERPOOL = 'DIALECTS_LIVERPOOL',
  DIALECTS_MEXICAN = 'DIALECTS_MEXICAN',
  DIALECTS_MIDDLE_EASTERN_ARABIC_PERSIAN = 'DIALECTS_MIDDLE_EASTERN_ARABIC_PERSIAN',
  DIALECTS_NEW_ZEALAND = 'DIALECTS_NEW_ZEALAND',
  DIALECTS_PUERTO_RICAN = 'DIALECTS_PUERTO_RICAN',
  DIALECTS_RECEIVED_PRONUNCIATION = 'DIALECTS_RECEIVED_PRONUNCIATION',
  DIALECTS_RUSSIAN = 'DIALECTS_RUSSIAN',
  DIALECTS_SCANDINAVIAN = 'DIALECTS_SCANDINAVIAN',
  DIALECTS_SCOTTISH = 'DIALECTS_SCOTTISH',
  DIALECTS_SOUTH_AFRICAN = 'DIALECTS_SOUTH_AFRICAN',
  DIALECTS_SPANISH_AMERICAS = 'DIALECTS_SPANISH_AMERICAS',
  DIALECTS_SPANISH_CASTILIAN = 'DIALECTS_SPANISH_CASTILIAN',
  DIALECTS_SPANISH_SPAIN = 'DIALECTS_SPANISH_SPAIN',
  DIALECTS_SWISS_GERMAN = 'DIALECTS_SWISS_GERMAN',
  DIALECTS_TORRES_STRAIT_ISLANDER = 'DIALECTS_TORRES_STRAIT_ISLANDER',
  DIALECTS_WELSH = 'DIALECTS_WELSH',
  DRIVING_BOATING_PILOTING = 'DRIVING_BOATING_PILOTING',
  DRIVING_BOATING_PILOTING_4WD = 'DRIVING_BOATING_PILOTING_4WD',
  DRIVING_BOATING_PILOTING_AIRPLANE = 'DRIVING_BOATING_PILOTING_AIRPLANE',
  DRIVING_BOATING_PILOTING_ATV = 'DRIVING_BOATING_PILOTING_ATV',
  DRIVING_BOATING_PILOTING_BOAT_GENERAL = 'DRIVING_BOATING_PILOTING_BOAT_GENERAL',
  DRIVING_BOATING_PILOTING_BOAT_JUMPING = 'DRIVING_BOATING_PILOTING_BOAT_JUMPING',
  DRIVING_BOATING_PILOTING_CANOEING = 'DRIVING_BOATING_PILOTING_CANOEING',
  DRIVING_BOATING_PILOTING_DUNE_BUGGY = 'DRIVING_BOATING_PILOTING_DUNE_BUGGY',
  DRIVING_BOATING_PILOTING_HELICOPTER = 'DRIVING_BOATING_PILOTING_HELICOPTER',
  DRIVING_BOATING_PILOTING_HOT_AIR_BALLOON = 'DRIVING_BOATING_PILOTING_HOT_AIR_BALLOON',
  DRIVING_BOATING_PILOTING_JET_SKIING = 'DRIVING_BOATING_PILOTING_JET_SKIING',
  DRIVING_BOATING_PILOTING_KAYAKING = 'DRIVING_BOATING_PILOTING_KAYAKING',
  DRIVING_BOATING_PILOTING_KITE_SURFING = 'DRIVING_BOATING_PILOTING_KITE_SURFING',
  DRIVING_BOATING_PILOTING_MOTORBOAT = 'DRIVING_BOATING_PILOTING_MOTORBOAT',
  DRIVING_BOATING_PILOTING_MOTORCYCLE_DIRT_BIKE = 'DRIVING_BOATING_PILOTING_MOTORCYCLE_DIRT_BIKE',
  DRIVING_BOATING_PILOTING_MOTORCYCLE_ROAD = 'DRIVING_BOATING_PILOTING_MOTORCYCLE_ROAD',
  DRIVING_BOATING_PILOTING_PADDLE_BOARDING = 'DRIVING_BOATING_PILOTING_PADDLE_BOARDING',
  DRIVING_BOATING_PILOTING_RAFTING = 'DRIVING_BOATING_PILOTING_RAFTING',
  DRIVING_BOATING_PILOTING_ROAD_DRIVING = 'DRIVING_BOATING_PILOTING_ROAD_DRIVING',
  DRIVING_BOATING_PILOTING_ROWING = 'DRIVING_BOATING_PILOTING_ROWING',
  DRIVING_BOATING_PILOTING_SAILING = 'DRIVING_BOATING_PILOTING_SAILING',
  DRIVING_BOATING_PILOTING_SCULLING = 'DRIVING_BOATING_PILOTING_SCULLING',
  DRIVING_BOATING_PILOTING_SEMI_TRUCK = 'DRIVING_BOATING_PILOTING_SEMI_TRUCK',
  DRIVING_BOATING_PILOTING_SNOWMOBILE = 'DRIVING_BOATING_PILOTING_SNOWMOBILE',
  DRIVING_BOATING_PILOTING_STICK_SHIFT = 'DRIVING_BOATING_PILOTING_STICK_SHIFT',
  DRIVING_BOATING_PILOTING_TRACK_DRIVING = 'DRIVING_BOATING_PILOTING_TRACK_DRIVING',
  HOBBIES = 'HOBBIES',
  HOBBIES_ASTROLOGY = 'HOBBIES_ASTROLOGY',
  HOBBIES_CARPENTRY = 'HOBBIES_CARPENTRY',
  HOBBIES_COOKING = 'HOBBIES_COOKING',
  HOBBIES_CROCHET = 'HOBBIES_CROCHET',
  HOBBIES_GAMING_VIDEO_COMPUTER = 'HOBBIES_GAMING_VIDEO_COMPUTER',
  HOBBIES_KNITTING = 'HOBBIES_KNITTING',
  HOBBIES_POTTERY = 'HOBBIES_POTTERY',
  INSTRUMENTS = 'INSTRUMENTS',
  INSTRUMENTS_ACCORDION = 'INSTRUMENTS_ACCORDION',
  INSTRUMENTS_AUTOHARP = 'INSTRUMENTS_AUTOHARP',
  INSTRUMENTS_BAGPIPES = 'INSTRUMENTS_BAGPIPES',
  INSTRUMENTS_BANJO = 'INSTRUMENTS_BANJO',
  INSTRUMENTS_BANJO_UKE = 'INSTRUMENTS_BANJO_UKE',
  INSTRUMENTS_BASSOON = 'INSTRUMENTS_BASSOON',
  INSTRUMENTS_BASS_DOUBLE_UPRIGHT = 'INSTRUMENTS_BASS_DOUBLE_UPRIGHT',
  INSTRUMENTS_BASS_GUITAR_ACOUSTIC = 'INSTRUMENTS_BASS_GUITAR_ACOUSTIC',
  INSTRUMENTS_BASS_GUITAR_ELECTRIC = 'INSTRUMENTS_BASS_GUITAR_ELECTRIC',
  INSTRUMENTS_BONES = 'INSTRUMENTS_BONES',
  INSTRUMENTS_BONGOS = 'INSTRUMENTS_BONGOS',
  INSTRUMENTS_CAJON_DE_TAPEO = 'INSTRUMENTS_CAJON_DE_TAPEO',
  INSTRUMENTS_CELLO = 'INSTRUMENTS_CELLO',
  INSTRUMENTS_CLARINET = 'INSTRUMENTS_CLARINET',
  INSTRUMENTS_CONGA = 'INSTRUMENTS_CONGA',
  INSTRUMENTS_DRUMS = 'INSTRUMENTS_DRUMS',
  INSTRUMENTS_DULCIMER = 'INSTRUMENTS_DULCIMER',
  INSTRUMENTS_FIDDLE = 'INSTRUMENTS_FIDDLE',
  INSTRUMENTS_FINGER_CYMBALS = 'INSTRUMENTS_FINGER_CYMBALS',
  INSTRUMENTS_FLUTE = 'INSTRUMENTS_FLUTE',
  INSTRUMENTS_FRENCH_HORN = 'INSTRUMENTS_FRENCH_HORN',
  INSTRUMENTS_GUIRO = 'INSTRUMENTS_GUIRO',
  INSTRUMENTS_GUITARRON = 'INSTRUMENTS_GUITARRON',
  INSTRUMENTS_GUITAR_ACOUSTIC = 'INSTRUMENTS_GUITAR_ACOUSTIC',
  INSTRUMENTS_GUITAR_ELECTRIC = 'INSTRUMENTS_GUITAR_ELECTRIC',
  INSTRUMENTS_GUITAR_GENERAL = 'INSTRUMENTS_GUITAR_GENERAL',
  INSTRUMENTS_HARMONICA = 'INSTRUMENTS_HARMONICA',
  INSTRUMENTS_HARP = 'INSTRUMENTS_HARP',
  INSTRUMENTS_JARANA = 'INSTRUMENTS_JARANA',
  INSTRUMENTS_KAZOO = 'INSTRUMENTS_KAZOO',
  INSTRUMENTS_MANDOLIN = 'INSTRUMENTS_MANDOLIN',
  INSTRUMENTS_MARACAS = 'INSTRUMENTS_MARACAS',
  INSTRUMENTS_MARIMBA = 'INSTRUMENTS_MARIMBA',
  INSTRUMENTS_MELODICA = 'INSTRUMENTS_MELODICA',
  INSTRUMENTS_OBOE = 'INSTRUMENTS_OBOE',
  INSTRUMENTS_ORGAN = 'INSTRUMENTS_ORGAN',
  INSTRUMENTS_PERCUSSSION = 'INSTRUMENTS_PERCUSSSION',
  INSTRUMENTS_PIANO_GENERAL = 'INSTRUMENTS_PIANO_GENERAL',
  INSTRUMENTS_PIANO_JAZZ = 'INSTRUMENTS_PIANO_JAZZ',
  INSTRUMENTS_PIANO_KEYBOARD = 'INSTRUMENTS_PIANO_KEYBOARD',
  INSTRUMENTS_PICCOLO = 'INSTRUMENTS_PICCOLO',
  INSTRUMENTS_RECORDER = 'INSTRUMENTS_RECORDER',
  INSTRUMENTS_SAXOPHONE = 'INSTRUMENTS_SAXOPHONE',
  INSTRUMENTS_SYNTHESIZER = 'INSTRUMENTS_SYNTHESIZER',
  INSTRUMENTS_TAMBOURINE = 'INSTRUMENTS_TAMBOURINE',
  INSTRUMENTS_TOLOLOCHE = 'INSTRUMENTS_TOLOLOCHE',
  INSTRUMENTS_TROMBONE = 'INSTRUMENTS_TROMBONE',
  INSTRUMENTS_TRUMPET = 'INSTRUMENTS_TRUMPET',
  INSTRUMENTS_TUBA = 'INSTRUMENTS_TUBA',
  INSTRUMENTS_TUBA_SOUSAPHONE = 'INSTRUMENTS_TUBA_SOUSAPHONE',
  INSTRUMENTS_UKULELE = 'INSTRUMENTS_UKULELE',
  INSTRUMENTS_VIOLA = 'INSTRUMENTS_VIOLA',
  INSTRUMENTS_VIOLIN = 'INSTRUMENTS_VIOLIN',
  JOBS = 'JOBS',
  JOBS_BARTENDER = 'JOBS_BARTENDER',
  JOBS_BULLFIGHTER = 'JOBS_BULLFIGHTER',
  JOBS_CARD_DEALER = 'JOBS_CARD_DEALER',
  JOBS_CHEF = 'JOBS_CHEF',
  JOBS_COMEDIAN = 'JOBS_COMEDIAN',
  JOBS_DISC_JOCKEY = 'JOBS_DISC_JOCKEY',
  JOBS_MASSAGE_THERAPIST = 'JOBS_MASSAGE_THERAPIST',
  JOBS_MEDICAL = 'JOBS_MEDICAL',
  JOBS_STENOGRAPHER = 'JOBS_STENOGRAPHER',
  JOBS_STRIPPER = 'JOBS_STRIPPER',
  LANGUAGES = 'LANGUAGES',
  LANGUAGES_ABKHAZIAN = 'LANGUAGES_ABKHAZIAN',
  LANGUAGES_AFRIKAANS = 'LANGUAGES_AFRIKAANS',
  LANGUAGES_ALBANIAN = 'LANGUAGES_ALBANIAN',
  LANGUAGES_AMERICAN_SIGN_LANGUAGE = 'LANGUAGES_AMERICAN_SIGN_LANGUAGE',
  LANGUAGES_AMHARIC = 'LANGUAGES_AMHARIC',
  LANGUAGES_ARABIC = 'LANGUAGES_ARABIC',
  LANGUAGES_ARMENIAN = 'LANGUAGES_ARMENIAN',
  LANGUAGES_AUSTRALIAN_ABORIGINAL = 'LANGUAGES_AUSTRALIAN_ABORIGINAL',
  LANGUAGES_AUSTRALIAN_SIGN_LANGUAGE = 'LANGUAGES_AUSTRALIAN_SIGN_LANGUAGE',
  LANGUAGES_AYMARA = 'LANGUAGES_AYMARA',
  LANGUAGES_AZERBAIJANI = 'LANGUAGES_AZERBAIJANI',
  LANGUAGES_BELARUSIAN = 'LANGUAGES_BELARUSIAN',
  LANGUAGES_BENGALI = 'LANGUAGES_BENGALI',
  LANGUAGES_BERBER = 'LANGUAGES_BERBER',
  LANGUAGES_BISAYA = 'LANGUAGES_BISAYA',
  LANGUAGES_BISLAMA = 'LANGUAGES_BISLAMA',
  LANGUAGES_BOSNIAN = 'LANGUAGES_BOSNIAN',
  LANGUAGES_BRITISH_SIGN_LANGUAGE = 'LANGUAGES_BRITISH_SIGN_LANGUAGE',
  LANGUAGES_BULGARIAN = 'LANGUAGES_BULGARIAN',
  LANGUAGES_BURMESE = 'LANGUAGES_BURMESE',
  LANGUAGES_CATALAN = 'LANGUAGES_CATALAN',
  LANGUAGES_CELTIC_GAELIC = 'LANGUAGES_CELTIC_GAELIC',
  LANGUAGES_CHICHEWA_NYANJA = 'LANGUAGES_CHICHEWA_NYANJA',
  LANGUAGES_CHINESE_CANTONESE = 'LANGUAGES_CHINESE_CANTONESE',
  LANGUAGES_CHINESE_MANDARIN = 'LANGUAGES_CHINESE_MANDARIN',
  LANGUAGES_CHINESE_OTHER_VARIETY = 'LANGUAGES_CHINESE_OTHER_VARIETY',
  LANGUAGES_CROATIAN = 'LANGUAGES_CROATIAN',
  LANGUAGES_CZECH = 'LANGUAGES_CZECH',
  LANGUAGES_DANISH = 'LANGUAGES_DANISH',
  LANGUAGES_DARI = 'LANGUAGES_DARI',
  LANGUAGES_DHIVEHI = 'LANGUAGES_DHIVEHI',
  LANGUAGES_DUTCH = 'LANGUAGES_DUTCH',
  LANGUAGES_DZONGKHA = 'LANGUAGES_DZONGKHA',
  LANGUAGES_ENGLISH = 'LANGUAGES_ENGLISH',
  LANGUAGES_ESTONIAN = 'LANGUAGES_ESTONIAN',
  LANGUAGES_FIJIAN = 'LANGUAGES_FIJIAN',
  LANGUAGES_FILIPINO = 'LANGUAGES_FILIPINO',
  LANGUAGES_FINNISH = 'LANGUAGES_FINNISH',
  LANGUAGES_FLEMISH = 'LANGUAGES_FLEMISH',
  LANGUAGES_FRENCH = 'LANGUAGES_FRENCH',
  LANGUAGES_FRENCH_CANADA = 'LANGUAGES_FRENCH_CANADA',
  LANGUAGES_GEORGIAN = 'LANGUAGES_GEORGIAN',
  LANGUAGES_GERMAN = 'LANGUAGES_GERMAN',
  LANGUAGES_GREEK = 'LANGUAGES_GREEK',
  LANGUAGES_GUARANI = 'LANGUAGES_GUARANI',
  LANGUAGES_GUJARATI = 'LANGUAGES_GUJARATI',
  LANGUAGES_HAITIAN_CREOLE = 'LANGUAGES_HAITIAN_CREOLE',
  LANGUAGES_HEBREW = 'LANGUAGES_HEBREW',
  LANGUAGES_HINDI = 'LANGUAGES_HINDI',
  LANGUAGES_HIRI_MOTU = 'LANGUAGES_HIRI_MOTU',
  LANGUAGES_HUNGARIAN = 'LANGUAGES_HUNGARIAN',
  LANGUAGES_ICELANDIC = 'LANGUAGES_ICELANDIC',
  LANGUAGES_ILOCANO = 'LANGUAGES_ILOCANO',
  LANGUAGES_INDONESIAN = 'LANGUAGES_INDONESIAN',
  LANGUAGES_ITALIAN = 'LANGUAGES_ITALIAN',
  LANGUAGES_JAPANESE = 'LANGUAGES_JAPANESE',
  LANGUAGES_KAZAKH = 'LANGUAGES_KAZAKH',
  LANGUAGES_KHMER = 'LANGUAGES_KHMER',
  LANGUAGES_KINYARWANDA = 'LANGUAGES_KINYARWANDA',
  LANGUAGES_KIRUNDI = 'LANGUAGES_KIRUNDI',
  LANGUAGES_KOREAN = 'LANGUAGES_KOREAN',
  LANGUAGES_KURDISH = 'LANGUAGES_KURDISH',
  LANGUAGES_KYRGYZ = 'LANGUAGES_KYRGYZ',
  LANGUAGES_LAO = 'LANGUAGES_LAO',
  LANGUAGES_LATIN = 'LANGUAGES_LATIN',
  LANGUAGES_LATVIAN = 'LANGUAGES_LATVIAN',
  LANGUAGES_LITHUANIAN = 'LANGUAGES_LITHUANIAN',
  LANGUAGES_LUXEMBOURGISH = 'LANGUAGES_LUXEMBOURGISH',
  LANGUAGES_MACEDONIAN = 'LANGUAGES_MACEDONIAN',
  LANGUAGES_MALAGASY = 'LANGUAGES_MALAGASY',
  LANGUAGES_MALAY = 'LANGUAGES_MALAY',
  LANGUAGES_MALTESE = 'LANGUAGES_MALTESE',
  LANGUAGES_MAORI = 'LANGUAGES_MAORI',
  LANGUAGES_MOLDOVAN = 'LANGUAGES_MOLDOVAN',
  LANGUAGES_MONGOLIAN = 'LANGUAGES_MONGOLIAN',
  LANGUAGES_MONTENEGRIN = 'LANGUAGES_MONTENEGRIN',
  LANGUAGES_NDEBELE = 'LANGUAGES_NDEBELE',
  LANGUAGES_NEPALI = 'LANGUAGES_NEPALI',
  LANGUAGES_NORTHERN_SOTHO = 'LANGUAGES_NORTHERN_SOTHO',
  LANGUAGES_NORWEGIAN = 'LANGUAGES_NORWEGIAN',
  LANGUAGES_OSSETIAN = 'LANGUAGES_OSSETIAN',
  LANGUAGES_PAPIAMENTO = 'LANGUAGES_PAPIAMENTO',
  LANGUAGES_PASHTO = 'LANGUAGES_PASHTO',
  LANGUAGES_PERSIAN_FARSI = 'LANGUAGES_PERSIAN_FARSI',
  LANGUAGES_POLISH = 'LANGUAGES_POLISH',
  LANGUAGES_PORTUGUESE = 'LANGUAGES_PORTUGUESE',
  LANGUAGES_QUECHUA = 'LANGUAGES_QUECHUA',
  LANGUAGES_ROMANIAN = 'LANGUAGES_ROMANIAN',
  LANGUAGES_ROMANSH = 'LANGUAGES_ROMANSH',
  LANGUAGES_RUSSIAN = 'LANGUAGES_RUSSIAN',
  LANGUAGES_SAMOAN = 'LANGUAGES_SAMOAN',
  LANGUAGES_SANGO = 'LANGUAGES_SANGO',
  LANGUAGES_SERBIAN = 'LANGUAGES_SERBIAN',
  LANGUAGES_SEYCHELLOIS_CREOLE = 'LANGUAGES_SEYCHELLOIS_CREOLE',
  LANGUAGES_SHONA = 'LANGUAGES_SHONA',
  LANGUAGES_SINHALA = 'LANGUAGES_SINHALA',
  LANGUAGES_SLOVAK = 'LANGUAGES_SLOVAK',
  LANGUAGES_SLOVENE = 'LANGUAGES_SLOVENE',
  LANGUAGES_SOMALI = 'LANGUAGES_SOMALI',
  LANGUAGES_SOTHO = 'LANGUAGES_SOTHO',
  LANGUAGES_SPANISH = 'LANGUAGES_SPANISH',
  LANGUAGES_SRANAN_TONGO = 'LANGUAGES_SRANAN_TONGO',
  LANGUAGES_SWAHILI = 'LANGUAGES_SWAHILI',
  LANGUAGES_SWATI = 'LANGUAGES_SWATI',
  LANGUAGES_SWEDISH = 'LANGUAGES_SWEDISH',
  LANGUAGES_TAGALOG = 'LANGUAGES_TAGALOG',
  LANGUAGES_TAIWANESE = 'LANGUAGES_TAIWANESE',
  LANGUAGES_TAJIK = 'LANGUAGES_TAJIK',
  LANGUAGES_TAMIL = 'LANGUAGES_TAMIL',
  LANGUAGES_TETUM = 'LANGUAGES_TETUM',
  LANGUAGES_THAI = 'LANGUAGES_THAI',
  LANGUAGES_TIGRE = 'LANGUAGES_TIGRE',
  LANGUAGES_TIGRINYA = 'LANGUAGES_TIGRINYA',
  LANGUAGES_TOISAN = 'LANGUAGES_TOISAN',
  LANGUAGES_TOK_PISIN = 'LANGUAGES_TOK_PISIN',
  LANGUAGES_TONGAN = 'LANGUAGES_TONGAN',
  LANGUAGES_TORRES_STRAIT_ISLANDER = 'LANGUAGES_TORRES_STRAIT_ISLANDER',
  LANGUAGES_TSONGA = 'LANGUAGES_TSONGA',
  LANGUAGES_TSWANA = 'LANGUAGES_TSWANA',
  LANGUAGES_TURKISH = 'LANGUAGES_TURKISH',
  LANGUAGES_TURKMEN = 'LANGUAGES_TURKMEN',
  LANGUAGES_UKRAINIAN = 'LANGUAGES_UKRAINIAN',
  LANGUAGES_URDU = 'LANGUAGES_URDU',
  LANGUAGES_UZBEK = 'LANGUAGES_UZBEK',
  LANGUAGES_VENDA = 'LANGUAGES_VENDA',
  LANGUAGES_VIETNAMESE = 'LANGUAGES_VIETNAMESE',
  LANGUAGES_WELSH = 'LANGUAGES_WELSH',
  LANGUAGES_XHOSA = 'LANGUAGES_XHOSA',
  LANGUAGES_YIDDISH = 'LANGUAGES_YIDDISH',
  LANGUAGES_YORUBA = 'LANGUAGES_YORUBA',
  LANGUAGES_ZULU = 'LANGUAGES_ZULU',
  MILITARY = 'MILITARY',
  MILITARY_AIR_FORCE = 'MILITARY_AIR_FORCE',
  MILITARY_ARMY = 'MILITARY_ARMY',
  MILITARY_COAST_GUARD = 'MILITARY_COAST_GUARD',
  MILITARY_MARINES = 'MILITARY_MARINES',
  MILITARY_NATIONAL_GUARD = 'MILITARY_NATIONAL_GUARD',
  MILITARY_NAVY = 'MILITARY_NAVY',
  MILITARY_NAVY_SEALS = 'MILITARY_NAVY_SEALS',
  MILITARY_OTHER = 'MILITARY_OTHER',
  MILITARY_ROYAL_AIR_FORCE = 'MILITARY_ROYAL_AIR_FORCE',
  MILITARY_R_O_T_C_ = 'MILITARY_R_O_T_C_',
  MILITARY_SPECIAL_FORCES = 'MILITARY_SPECIAL_FORCES',
  PERFORMANCE_SKILLS = 'PERFORMANCE_SKILLS',
  PERFORMANCE_SKILLS_BURLESQUE = 'PERFORMANCE_SKILLS_BURLESQUE',
  PERFORMANCE_SKILLS_CARD_TRICKS = 'PERFORMANCE_SKILLS_CARD_TRICKS',
  PERFORMANCE_SKILLS_DRAG = 'PERFORMANCE_SKILLS_DRAG',
  PERFORMANCE_SKILLS_EAR_PROMPTING = 'PERFORMANCE_SKILLS_EAR_PROMPTING',
  PERFORMANCE_SKILLS_HOSTING = 'PERFORMANCE_SKILLS_HOSTING',
  PERFORMANCE_SKILLS_IMPERSONATIONS_IMPRESSIONS = 'PERFORMANCE_SKILLS_IMPERSONATIONS_IMPRESSIONS',
  PERFORMANCE_SKILLS_MAGIC = 'PERFORMANCE_SKILLS_MAGIC',
  PERFORMANCE_SKILLS_MASCOT = 'PERFORMANCE_SKILLS_MASCOT',
  PERFORMANCE_SKILLS_MIMING = 'PERFORMANCE_SKILLS_MIMING',
  PERFORMANCE_SKILLS_PUPPETRY = 'PERFORMANCE_SKILLS_PUPPETRY',
  PERFORMANCE_SKILLS_STAND_IN = 'PERFORMANCE_SKILLS_STAND_IN',
  PERFORMANCE_SKILLS_TELEPROMPTER = 'PERFORMANCE_SKILLS_TELEPROMPTER',
  PERFORMANCE_SKILLS_VENTRILOQUISM = 'PERFORMANCE_SKILLS_VENTRILOQUISM',
  PERFORMANCE_SKILLS_VOICE_OVER = 'PERFORMANCE_SKILLS_VOICE_OVER',
  PERFORMANCE_SKILLS_WHISTLING = 'PERFORMANCE_SKILLS_WHISTLING',
  PERFORMANCE_SKILLS_YO_YO = 'PERFORMANCE_SKILLS_YO_YO',
  RODEO = 'RODEO',
  RODEO_BARREL_RACING = 'RODEO_BARREL_RACING',
  RODEO_BULL_RIDING = 'RODEO_BULL_RIDING',
  RODEO_CALA = 'RODEO_CALA',
  RODEO_CHARRO_COMPLETO = 'RODEO_CHARRO_COMPLETO',
  RODEO_COLAS = 'RODEO_COLAS',
  RODEO_ESCARAMUZA_CHARRA = 'RODEO_ESCARAMUZA_CHARRA',
  RODEO_JINETEO_DE_YEGUA = 'RODEO_JINETEO_DE_YEGUA',
  RODEO_JINETEO_TORO = 'RODEO_JINETEO_TORO',
  RODEO_MANGANAS_A_PIE_Y_A_CABALLO = 'RODEO_MANGANAS_A_PIE_Y_A_CABALLO',
  RODEO_MUTTON_BUSTING = 'RODEO_MUTTON_BUSTING',
  RODEO_PASO_DE_LA_MUERTE = 'RODEO_PASO_DE_LA_MUERTE',
  RODEO_PIALES = 'RODEO_PIALES',
  RODEO_ROPING = 'RODEO_ROPING',
  RODEO_TERNA_EN_EL_RUEDO = 'RODEO_TERNA_EN_EL_RUEDO',
  SINGING_STYLES = 'SINGING_STYLES',
  SINGING_STYLES_A_CAPPELLA = 'SINGING_STYLES_A_CAPPELLA',
  SINGING_STYLES_BEATBOXING = 'SINGING_STYLES_BEATBOXING',
  SINGING_STYLES_BLUES = 'SINGING_STYLES_BLUES',
  SINGING_STYLES_CELTIC = 'SINGING_STYLES_CELTIC',
  SINGING_STYLES_COUNTRY = 'SINGING_STYLES_COUNTRY',
  SINGING_STYLES_FLAMENCO = 'SINGING_STYLES_FLAMENCO',
  SINGING_STYLES_FOLK = 'SINGING_STYLES_FOLK',
  SINGING_STYLES_GOSPEL = 'SINGING_STYLES_GOSPEL',
  SINGING_STYLES_GREGORIAN_CHANT = 'SINGING_STYLES_GREGORIAN_CHANT',
  SINGING_STYLES_GUTTURAL_DEATH_GROWL = 'SINGING_STYLES_GUTTURAL_DEATH_GROWL',
  SINGING_STYLES_HEAVY_METAL_HARDCORE_PUNK = 'SINGING_STYLES_HEAVY_METAL_HARDCORE_PUNK',
  SINGING_STYLES_JAZZ = 'SINGING_STYLES_JAZZ',
  SINGING_STYLES_LOUNGE = 'SINGING_STYLES_LOUNGE',
  SINGING_STYLES_MOTOWN = 'SINGING_STYLES_MOTOWN',
  SINGING_STYLES_MUSICAL_THEATRE = 'SINGING_STYLES_MUSICAL_THEATRE',
  SINGING_STYLES_OPERA = 'SINGING_STYLES_OPERA',
  SINGING_STYLES_POP = 'SINGING_STYLES_POP',
  SINGING_STYLES_RANCHERO = 'SINGING_STYLES_RANCHERO',
  SINGING_STYLES_RAP = 'SINGING_STYLES_RAP',
  SINGING_STYLES_REGGAE = 'SINGING_STYLES_REGGAE',
  SINGING_STYLES_ROCK = 'SINGING_STYLES_ROCK',
  SINGING_STYLES_R_B = 'SINGING_STYLES_R_B',
  SINGING_STYLES_SINGING_GENERAL = 'SINGING_STYLES_SINGING_GENERAL',
  SINGING_STYLES_VIBRATO = 'SINGING_STYLES_VIBRATO',
  SINGING_STYLES_YODELING = 'SINGING_STYLES_YODELING',
  SPORTS = 'SPORTS',
  SPORTS_COMBAT_SPORTS = 'SPORTS_COMBAT_SPORTS',
  SPORTS_COMBAT_SPORTS_BOXING = 'SPORTS_COMBAT_SPORTS_BOXING',
  SPORTS_COMBAT_SPORTS_FENCING = 'SPORTS_COMBAT_SPORTS_FENCING',
  SPORTS_COMBAT_SPORTS_KICKBOXING = 'SPORTS_COMBAT_SPORTS_KICKBOXING',
  SPORTS_COMBAT_SPORTS_MMA = 'SPORTS_COMBAT_SPORTS_MMA',
  SPORTS_COMBAT_SPORTS_SUMO = 'SPORTS_COMBAT_SPORTS_SUMO',
  SPORTS_COMBAT_SPORTS_WRESTLING_GENERAL = 'SPORTS_COMBAT_SPORTS_WRESTLING_GENERAL',
  SPORTS_COMBAT_SPORTS_WRESTLING_GRECO_ROMAN = 'SPORTS_COMBAT_SPORTS_WRESTLING_GRECO_ROMAN',
  SPORTS_COMBAT_SPORTS_WRESTLING_LUCHA_LIBRE = 'SPORTS_COMBAT_SPORTS_WRESTLING_LUCHA_LIBRE',
  SPORTS_CYCLING = 'SPORTS_CYCLING',
  SPORTS_CYCLING_BMX = 'SPORTS_CYCLING_BMX',
  SPORTS_CYCLING_CYCLING_GENERAL = 'SPORTS_CYCLING_CYCLING_GENERAL',
  SPORTS_CYCLING_DOWNHILL_BIKING = 'SPORTS_CYCLING_DOWNHILL_BIKING',
  SPORTS_CYCLING_FLATLAND_FREESTYLE = 'SPORTS_CYCLING_FLATLAND_FREESTYLE',
  SPORTS_CYCLING_HALF_PIPE_VERT_WALL = 'SPORTS_CYCLING_HALF_PIPE_VERT_WALL',
  SPORTS_CYCLING_JUMPING = 'SPORTS_CYCLING_JUMPING',
  SPORTS_CYCLING_MOUNTAIN_BIKING = 'SPORTS_CYCLING_MOUNTAIN_BIKING',
  SPORTS_CYCLING_RECUMBENT = 'SPORTS_CYCLING_RECUMBENT',
  SPORTS_CYCLING_ROAD_BIKING = 'SPORTS_CYCLING_ROAD_BIKING',
  SPORTS_CYCLING_TRIALS_RIDING = 'SPORTS_CYCLING_TRIALS_RIDING',
  SPORTS_EQUESTRIAN_SPORTS = 'SPORTS_EQUESTRIAN_SPORTS',
  SPORTS_EQUESTRIAN_SPORTS_BARE_BACK = 'SPORTS_EQUESTRIAN_SPORTS_BARE_BACK',
  SPORTS_EQUESTRIAN_SPORTS_DRESSAGE = 'SPORTS_EQUESTRIAN_SPORTS_DRESSAGE',
  SPORTS_EQUESTRIAN_SPORTS_ENDURANCE_RIDING = 'SPORTS_EQUESTRIAN_SPORTS_ENDURANCE_RIDING',
  SPORTS_EQUESTRIAN_SPORTS_ENGLISH_RIDING = 'SPORTS_EQUESTRIAN_SPORTS_ENGLISH_RIDING',
  SPORTS_EQUESTRIAN_SPORTS_HARNESS_RACING = 'SPORTS_EQUESTRIAN_SPORTS_HARNESS_RACING',
  SPORTS_EQUESTRIAN_SPORTS_HORSEBACK_RIDING_GENERAL = 'SPORTS_EQUESTRIAN_SPORTS_HORSEBACK_RIDING_GENERAL',
  SPORTS_EQUESTRIAN_SPORTS_HORSE_JUMPING = 'SPORTS_EQUESTRIAN_SPORTS_HORSE_JUMPING',
  SPORTS_EQUESTRIAN_SPORTS_POLO = 'SPORTS_EQUESTRIAN_SPORTS_POLO',
  SPORTS_EQUESTRIAN_SPORTS_RODEO = 'SPORTS_EQUESTRIAN_SPORTS_RODEO',
  SPORTS_EQUESTRIAN_SPORTS_ROPE = 'SPORTS_EQUESTRIAN_SPORTS_ROPE',
  SPORTS_EQUESTRIAN_SPORTS_SIDE_SADDLE = 'SPORTS_EQUESTRIAN_SPORTS_SIDE_SADDLE',
  SPORTS_EQUESTRIAN_SPORTS_WESTERN = 'SPORTS_EQUESTRIAN_SPORTS_WESTERN',
  SPORTS_EXTREME_SPORTS = 'SPORTS_EXTREME_SPORTS',
  SPORTS_EXTREME_SPORTS_BASE_JUMPING = 'SPORTS_EXTREME_SPORTS_BASE_JUMPING',
  SPORTS_EXTREME_SPORTS_BUNGEE_JUMPING = 'SPORTS_EXTREME_SPORTS_BUNGEE_JUMPING',
  SPORTS_EXTREME_SPORTS_CANOPYING = 'SPORTS_EXTREME_SPORTS_CANOPYING',
  SPORTS_EXTREME_SPORTS_FREEDIVING = 'SPORTS_EXTREME_SPORTS_FREEDIVING',
  SPORTS_EXTREME_SPORTS_GLIDING = 'SPORTS_EXTREME_SPORTS_GLIDING',
  SPORTS_EXTREME_SPORTS_HANG_GLIDING = 'SPORTS_EXTREME_SPORTS_HANG_GLIDING',
  SPORTS_EXTREME_SPORTS_INLINE_SKATING_GENERAL = 'SPORTS_EXTREME_SPORTS_INLINE_SKATING_GENERAL',
  SPORTS_EXTREME_SPORTS_INLINE_SKATING_STUNTS_COMPETITIVE = 'SPORTS_EXTREME_SPORTS_INLINE_SKATING_STUNTS_COMPETITIVE',
  SPORTS_EXTREME_SPORTS_MOUNTAIN_BOARDING = 'SPORTS_EXTREME_SPORTS_MOUNTAIN_BOARDING',
  SPORTS_EXTREME_SPORTS_PARKOUR = 'SPORTS_EXTREME_SPORTS_PARKOUR',
  SPORTS_EXTREME_SPORTS_SKATEBOARDING_GENERAL = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_GENERAL',
  SPORTS_EXTREME_SPORTS_SKATEBOARDING_HALF_PIPE_VERT = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_HALF_PIPE_VERT',
  SPORTS_EXTREME_SPORTS_SKATEBOARDING_SLALOM = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_SLALOM',
  SPORTS_EXTREME_SPORTS_SKATEBOARDING_STREET = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_STREET',
  SPORTS_EXTREME_SPORTS_SKATEBOARDING_STUNTS_COMPETITIVE = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_STUNTS_COMPETITIVE',
  SPORTS_EXTREME_SPORTS_SKY_DIVING = 'SPORTS_EXTREME_SPORTS_SKY_DIVING',
  SPORTS_EXTREME_SPORTS_SKY_SURFING = 'SPORTS_EXTREME_SPORTS_SKY_SURFING',
  SPORTS_EXTREME_SPORTS_SUPERCROSS = 'SPORTS_EXTREME_SPORTS_SUPERCROSS',
  SPORTS_EXTREME_SPORTS_WING_SUIT = 'SPORTS_EXTREME_SPORTS_WING_SUIT',
  SPORTS_GYMNASTIC_SPORTS = 'SPORTS_GYMNASTIC_SPORTS',
  SPORTS_GYMNASTIC_SPORTS_ACROBATIC_GYMNASTICS = 'SPORTS_GYMNASTIC_SPORTS_ACROBATIC_GYMNASTICS',
  SPORTS_GYMNASTIC_SPORTS_BALANCE_BEAM = 'SPORTS_GYMNASTIC_SPORTS_BALANCE_BEAM',
  SPORTS_GYMNASTIC_SPORTS_BATON_TWIRLING = 'SPORTS_GYMNASTIC_SPORTS_BATON_TWIRLING',
  SPORTS_GYMNASTIC_SPORTS_CHEERLEADING = 'SPORTS_GYMNASTIC_SPORTS_CHEERLEADING',
  SPORTS_GYMNASTIC_SPORTS_HIGH_BAR = 'SPORTS_GYMNASTIC_SPORTS_HIGH_BAR',
  SPORTS_GYMNASTIC_SPORTS_PARALLEL_BARS = 'SPORTS_GYMNASTIC_SPORTS_PARALLEL_BARS',
  SPORTS_GYMNASTIC_SPORTS_POMMEL_HORSE = 'SPORTS_GYMNASTIC_SPORTS_POMMEL_HORSE',
  SPORTS_GYMNASTIC_SPORTS_RHYTHMIC_GYMNASTICS = 'SPORTS_GYMNASTIC_SPORTS_RHYTHMIC_GYMNASTICS',
  SPORTS_GYMNASTIC_SPORTS_STILL_RINGS = 'SPORTS_GYMNASTIC_SPORTS_STILL_RINGS',
  SPORTS_GYMNASTIC_SPORTS_TRAMPOLINE = 'SPORTS_GYMNASTIC_SPORTS_TRAMPOLINE',
  SPORTS_GYMNASTIC_SPORTS_TUMBLING = 'SPORTS_GYMNASTIC_SPORTS_TUMBLING',
  SPORTS_GYMNASTIC_SPORTS_UNEVEN_BARS = 'SPORTS_GYMNASTIC_SPORTS_UNEVEN_BARS',
  SPORTS_GYMNASTIC_SPORTS_VAULT = 'SPORTS_GYMNASTIC_SPORTS_VAULT',
  SPORTS_GYM_SPORTS = 'SPORTS_GYM_SPORTS',
  SPORTS_GYM_SPORTS_AEROBICS = 'SPORTS_GYM_SPORTS_AEROBICS',
  SPORTS_GYM_SPORTS_BODY_BUILDING = 'SPORTS_GYM_SPORTS_BODY_BUILDING',
  SPORTS_GYM_SPORTS_CROSSFIT_TRAINING = 'SPORTS_GYM_SPORTS_CROSSFIT_TRAINING',
  SPORTS_GYM_SPORTS_JOGGING = 'SPORTS_GYM_SPORTS_JOGGING',
  SPORTS_GYM_SPORTS_PILATES = 'SPORTS_GYM_SPORTS_PILATES',
  SPORTS_GYM_SPORTS_ROPE_CLIMBING = 'SPORTS_GYM_SPORTS_ROPE_CLIMBING',
  SPORTS_GYM_SPORTS_SPINNING = 'SPORTS_GYM_SPORTS_SPINNING',
  SPORTS_GYM_SPORTS_TAE_BO = 'SPORTS_GYM_SPORTS_TAE_BO',
  SPORTS_GYM_SPORTS_WEIGHT_LIFTING = 'SPORTS_GYM_SPORTS_WEIGHT_LIFTING',
  SPORTS_GYM_SPORTS_YOGA = 'SPORTS_GYM_SPORTS_YOGA',
  SPORTS_GYM_SPORTS_ZUMBA = 'SPORTS_GYM_SPORTS_ZUMBA',
  SPORTS_MARTIAL_ARTS = 'SPORTS_MARTIAL_ARTS',
  SPORTS_MARTIAL_ARTS_AIKIDO = 'SPORTS_MARTIAL_ARTS_AIKIDO',
  SPORTS_MARTIAL_ARTS_BRAZILIAN_JIU_JITSU = 'SPORTS_MARTIAL_ARTS_BRAZILIAN_JIU_JITSU',
  SPORTS_MARTIAL_ARTS_CAPOEIRA = 'SPORTS_MARTIAL_ARTS_CAPOEIRA',
  SPORTS_MARTIAL_ARTS_ESCRIMA = 'SPORTS_MARTIAL_ARTS_ESCRIMA',
  SPORTS_MARTIAL_ARTS_GRAPPLING = 'SPORTS_MARTIAL_ARTS_GRAPPLING',
  SPORTS_MARTIAL_ARTS_HAND_TO_HAND = 'SPORTS_MARTIAL_ARTS_HAND_TO_HAND',
  SPORTS_MARTIAL_ARTS_HAPKIDO = 'SPORTS_MARTIAL_ARTS_HAPKIDO',
  SPORTS_MARTIAL_ARTS_JEET_KUNE_DO = 'SPORTS_MARTIAL_ARTS_JEET_KUNE_DO',
  SPORTS_MARTIAL_ARTS_JIU_JITSU = 'SPORTS_MARTIAL_ARTS_JIU_JITSU',
  SPORTS_MARTIAL_ARTS_JUDO = 'SPORTS_MARTIAL_ARTS_JUDO',
  SPORTS_MARTIAL_ARTS_KARATE = 'SPORTS_MARTIAL_ARTS_KARATE',
  SPORTS_MARTIAL_ARTS_KENDO = 'SPORTS_MARTIAL_ARTS_KENDO',
  SPORTS_MARTIAL_ARTS_KRAV_MAGA = 'SPORTS_MARTIAL_ARTS_KRAV_MAGA',
  SPORTS_MARTIAL_ARTS_KUNG_FU = 'SPORTS_MARTIAL_ARTS_KUNG_FU',
  SPORTS_MARTIAL_ARTS_MUAY_LAO = 'SPORTS_MARTIAL_ARTS_MUAY_LAO',
  SPORTS_MARTIAL_ARTS_MUAY_THAI = 'SPORTS_MARTIAL_ARTS_MUAY_THAI',
  SPORTS_MARTIAL_ARTS_SAMBO = 'SPORTS_MARTIAL_ARTS_SAMBO',
  SPORTS_MARTIAL_ARTS_SANSHOU = 'SPORTS_MARTIAL_ARTS_SANSHOU',
  SPORTS_MARTIAL_ARTS_TAE_KWON_DO = 'SPORTS_MARTIAL_ARTS_TAE_KWON_DO',
  SPORTS_MARTIAL_ARTS_TAI_CHI = 'SPORTS_MARTIAL_ARTS_TAI_CHI',
  SPORTS_MARTIAL_ARTS_VALE_TUDO = 'SPORTS_MARTIAL_ARTS_VALE_TUDO',
  SPORTS_OUTDOOR_SPORTS = 'SPORTS_OUTDOOR_SPORTS',
  SPORTS_OUTDOOR_SPORTS_ARCHERY = 'SPORTS_OUTDOOR_SPORTS_ARCHERY',
  SPORTS_OUTDOOR_SPORTS_CLAY_SHOOTING = 'SPORTS_OUTDOOR_SPORTS_CLAY_SHOOTING',
  SPORTS_OUTDOOR_SPORTS_FISHING = 'SPORTS_OUTDOOR_SPORTS_FISHING',
  SPORTS_OUTDOOR_SPORTS_FLY_FISHING = 'SPORTS_OUTDOOR_SPORTS_FLY_FISHING',
  SPORTS_OUTDOOR_SPORTS_GOLFING = 'SPORTS_OUTDOOR_SPORTS_GOLFING',
  SPORTS_OUTDOOR_SPORTS_HIKING = 'SPORTS_OUTDOOR_SPORTS_HIKING',
  SPORTS_OUTDOOR_SPORTS_HUNTING = 'SPORTS_OUTDOOR_SPORTS_HUNTING',
  SPORTS_OUTDOOR_SPORTS_MOUNTAIN_CLIMBING = 'SPORTS_OUTDOOR_SPORTS_MOUNTAIN_CLIMBING',
  SPORTS_OUTDOOR_SPORTS_RAPPELLING = 'SPORTS_OUTDOOR_SPORTS_RAPPELLING',
  SPORTS_OUTDOOR_SPORTS_ROCK_CLIMBING = 'SPORTS_OUTDOOR_SPORTS_ROCK_CLIMBING',
  SPORTS_OUTDOOR_SPORTS_SKEET_SHOOTING = 'SPORTS_OUTDOOR_SPORTS_SKEET_SHOOTING',
  SPORTS_OUTDOOR_SPORTS_TRAP_SHOOTING = 'SPORTS_OUTDOOR_SPORTS_TRAP_SHOOTING',
  SPORTS_RACQUET_SPORTS = 'SPORTS_RACQUET_SPORTS',
  SPORTS_RACQUET_SPORTS_BADMINTON = 'SPORTS_RACQUET_SPORTS_BADMINTON',
  SPORTS_RACQUET_SPORTS_FRONTENIS = 'SPORTS_RACQUET_SPORTS_FRONTENIS',
  SPORTS_RACQUET_SPORTS_PICKLEBALL = 'SPORTS_RACQUET_SPORTS_PICKLEBALL',
  SPORTS_RACQUET_SPORTS_RACQUETBALL = 'SPORTS_RACQUET_SPORTS_RACQUETBALL',
  SPORTS_RACQUET_SPORTS_SQUASH = 'SPORTS_RACQUET_SPORTS_SQUASH',
  SPORTS_RACQUET_SPORTS_TENNIS = 'SPORTS_RACQUET_SPORTS_TENNIS',
  SPORTS_RECREATIONAL_SPORTS = 'SPORTS_RECREATIONAL_SPORTS',
  SPORTS_RECREATIONAL_SPORTS_BOWLING = 'SPORTS_RECREATIONAL_SPORTS_BOWLING',
  SPORTS_RECREATIONAL_SPORTS_CROQUET = 'SPORTS_RECREATIONAL_SPORTS_CROQUET',
  SPORTS_RECREATIONAL_SPORTS_DARTS = 'SPORTS_RECREATIONAL_SPORTS_DARTS',
  SPORTS_RECREATIONAL_SPORTS_FRISBEE = 'SPORTS_RECREATIONAL_SPORTS_FRISBEE',
  SPORTS_RECREATIONAL_SPORTS_FRISBEE_GOLF = 'SPORTS_RECREATIONAL_SPORTS_FRISBEE_GOLF',
  SPORTS_RECREATIONAL_SPORTS_HACKY_SACK = 'SPORTS_RECREATIONAL_SPORTS_HACKY_SACK',
  SPORTS_RECREATIONAL_SPORTS_HULA_HOOP = 'SPORTS_RECREATIONAL_SPORTS_HULA_HOOP',
  SPORTS_RECREATIONAL_SPORTS_JUMP_ROPE_DOUBLE_DUTCH = 'SPORTS_RECREATIONAL_SPORTS_JUMP_ROPE_DOUBLE_DUTCH',
  SPORTS_RECREATIONAL_SPORTS_JUMP_ROPE_GENERAL = 'SPORTS_RECREATIONAL_SPORTS_JUMP_ROPE_GENERAL',
  SPORTS_RECREATIONAL_SPORTS_LASER_TAG = 'SPORTS_RECREATIONAL_SPORTS_LASER_TAG',
  SPORTS_RECREATIONAL_SPORTS_PAINTBALL = 'SPORTS_RECREATIONAL_SPORTS_PAINTBALL',
  SPORTS_RECREATIONAL_SPORTS_POGO_STICK = 'SPORTS_RECREATIONAL_SPORTS_POGO_STICK',
  SPORTS_RECREATIONAL_SPORTS_RAZOR_SCOOTER = 'SPORTS_RECREATIONAL_SPORTS_RAZOR_SCOOTER',
  SPORTS_RECREATIONAL_SPORTS_ROLLER_SKATING = 'SPORTS_RECREATIONAL_SPORTS_ROLLER_SKATING',
  SPORTS_RECREATIONAL_SPORTS_SEGWAY_RIDING = 'SPORTS_RECREATIONAL_SPORTS_SEGWAY_RIDING',
  SPORTS_RECREATIONAL_SPORTS_SHUFFLE_BOARD = 'SPORTS_RECREATIONAL_SPORTS_SHUFFLE_BOARD',
  SPORTS_RECREATIONAL_SPORTS_ZORBING = 'SPORTS_RECREATIONAL_SPORTS_ZORBING',
  SPORTS_TABLE_SPORTS = 'SPORTS_TABLE_SPORTS',
  SPORTS_TABLE_SPORTS_AIR_HOCKEY = 'SPORTS_TABLE_SPORTS_AIR_HOCKEY',
  SPORTS_TABLE_SPORTS_BILLIARDS = 'SPORTS_TABLE_SPORTS_BILLIARDS',
  SPORTS_TABLE_SPORTS_FOOSBALL = 'SPORTS_TABLE_SPORTS_FOOSBALL',
  SPORTS_TABLE_SPORTS_POOL = 'SPORTS_TABLE_SPORTS_POOL',
  SPORTS_TABLE_SPORTS_SNOOKER = 'SPORTS_TABLE_SPORTS_SNOOKER',
  SPORTS_TABLE_SPORTS_TABLE_TENNIS_PING_PONG = 'SPORTS_TABLE_SPORTS_TABLE_TENNIS_PING_PONG',
  SPORTS_TEAM_SPORTS = 'SPORTS_TEAM_SPORTS',
  SPORTS_TEAM_SPORTS_AMERICAN_FOOTBALL = 'SPORTS_TEAM_SPORTS_AMERICAN_FOOTBALL',
  SPORTS_TEAM_SPORTS_AUSTRALIAN_FOOTBALL = 'SPORTS_TEAM_SPORTS_AUSTRALIAN_FOOTBALL',
  SPORTS_TEAM_SPORTS_BASEBALL = 'SPORTS_TEAM_SPORTS_BASEBALL',
  SPORTS_TEAM_SPORTS_BASKETBALL = 'SPORTS_TEAM_SPORTS_BASKETBALL',
  SPORTS_TEAM_SPORTS_BEACH_VOLLEYBALL = 'SPORTS_TEAM_SPORTS_BEACH_VOLLEYBALL',
  SPORTS_TEAM_SPORTS_CRICKET = 'SPORTS_TEAM_SPORTS_CRICKET',
  SPORTS_TEAM_SPORTS_DODGE_BALL = 'SPORTS_TEAM_SPORTS_DODGE_BALL',
  SPORTS_TEAM_SPORTS_FIELD_HOCKEY = 'SPORTS_TEAM_SPORTS_FIELD_HOCKEY',
  SPORTS_TEAM_SPORTS_HANDBALL = 'SPORTS_TEAM_SPORTS_HANDBALL',
  SPORTS_TEAM_SPORTS_HURLING = 'SPORTS_TEAM_SPORTS_HURLING',
  SPORTS_TEAM_SPORTS_ICE_HOCKEY = 'SPORTS_TEAM_SPORTS_ICE_HOCKEY',
  SPORTS_TEAM_SPORTS_INDOOR_SOCCER = 'SPORTS_TEAM_SPORTS_INDOOR_SOCCER',
  SPORTS_TEAM_SPORTS_LACROSSE = 'SPORTS_TEAM_SPORTS_LACROSSE',
  SPORTS_TEAM_SPORTS_ROLLER_HOCKEY = 'SPORTS_TEAM_SPORTS_ROLLER_HOCKEY',
  SPORTS_TEAM_SPORTS_RUGBY_LEAGUE = 'SPORTS_TEAM_SPORTS_RUGBY_LEAGUE',
  SPORTS_TEAM_SPORTS_RUGBY_UNION = 'SPORTS_TEAM_SPORTS_RUGBY_UNION',
  SPORTS_TEAM_SPORTS_SOCCER = 'SPORTS_TEAM_SPORTS_SOCCER',
  SPORTS_TEAM_SPORTS_SOFTBALL = 'SPORTS_TEAM_SPORTS_SOFTBALL',
  SPORTS_TEAM_SPORTS_VOLLEYBALL = 'SPORTS_TEAM_SPORTS_VOLLEYBALL',
  SPORTS_TRACK_AND_FIELD = 'SPORTS_TRACK_AND_FIELD',
  SPORTS_TRACK_AND_FIELD_BROAD_JUMP = 'SPORTS_TRACK_AND_FIELD_BROAD_JUMP',
  SPORTS_TRACK_AND_FIELD_DECATHLON = 'SPORTS_TRACK_AND_FIELD_DECATHLON',
  SPORTS_TRACK_AND_FIELD_DISCUS = 'SPORTS_TRACK_AND_FIELD_DISCUS',
  SPORTS_TRACK_AND_FIELD_DISTANCE_RUNNING = 'SPORTS_TRACK_AND_FIELD_DISTANCE_RUNNING',
  SPORTS_TRACK_AND_FIELD_HAMMER = 'SPORTS_TRACK_AND_FIELD_HAMMER',
  SPORTS_TRACK_AND_FIELD_HEPTATHLON = 'SPORTS_TRACK_AND_FIELD_HEPTATHLON',
  SPORTS_TRACK_AND_FIELD_HIGH_JUMP = 'SPORTS_TRACK_AND_FIELD_HIGH_JUMP',
  SPORTS_TRACK_AND_FIELD_HURDLE = 'SPORTS_TRACK_AND_FIELD_HURDLE',
  SPORTS_TRACK_AND_FIELD_JAVELIN = 'SPORTS_TRACK_AND_FIELD_JAVELIN',
  SPORTS_TRACK_AND_FIELD_LONG_JUMP = 'SPORTS_TRACK_AND_FIELD_LONG_JUMP',
  SPORTS_TRACK_AND_FIELD_MARATHON = 'SPORTS_TRACK_AND_FIELD_MARATHON',
  SPORTS_TRACK_AND_FIELD_POLE_VAULT = 'SPORTS_TRACK_AND_FIELD_POLE_VAULT',
  SPORTS_TRACK_AND_FIELD_RELAY = 'SPORTS_TRACK_AND_FIELD_RELAY',
  SPORTS_TRACK_AND_FIELD_RUNNING = 'SPORTS_TRACK_AND_FIELD_RUNNING',
  SPORTS_TRACK_AND_FIELD_SHOT_PUT = 'SPORTS_TRACK_AND_FIELD_SHOT_PUT',
  SPORTS_TRACK_AND_FIELD_SPRINTING = 'SPORTS_TRACK_AND_FIELD_SPRINTING',
  SPORTS_TRACK_AND_FIELD_TRACK_GENERAL = 'SPORTS_TRACK_AND_FIELD_TRACK_GENERAL',
  SPORTS_TRACK_AND_FIELD_TRIATHLON = 'SPORTS_TRACK_AND_FIELD_TRIATHLON',
  SPORTS_TRACK_AND_FIELD_TRIPLE_JUMP = 'SPORTS_TRACK_AND_FIELD_TRIPLE_JUMP',
  SPORTS_WATER_SPORTS = 'SPORTS_WATER_SPORTS',
  SPORTS_WATER_SPORTS_BODYBOARDING = 'SPORTS_WATER_SPORTS_BODYBOARDING',
  SPORTS_WATER_SPORTS_BODY_SURFING = 'SPORTS_WATER_SPORTS_BODY_SURFING',
  SPORTS_WATER_SPORTS_BOOGIE_BOARDING = 'SPORTS_WATER_SPORTS_BOOGIE_BOARDING',
  SPORTS_WATER_SPORTS_DIVING = 'SPORTS_WATER_SPORTS_DIVING',
  SPORTS_WATER_SPORTS_SCUBA_DIVING = 'SPORTS_WATER_SPORTS_SCUBA_DIVING',
  SPORTS_WATER_SPORTS_SKIM_BOARDING = 'SPORTS_WATER_SPORTS_SKIM_BOARDING',
  SPORTS_WATER_SPORTS_SNORKELING = 'SPORTS_WATER_SPORTS_SNORKELING',
  SPORTS_WATER_SPORTS_SURFING = 'SPORTS_WATER_SPORTS_SURFING',
  SPORTS_WATER_SPORTS_SWIMMING_BACK_STROKE = 'SPORTS_WATER_SPORTS_SWIMMING_BACK_STROKE',
  SPORTS_WATER_SPORTS_SWIMMING_BREAST_STROKE = 'SPORTS_WATER_SPORTS_SWIMMING_BREAST_STROKE',
  SPORTS_WATER_SPORTS_SWIMMING_BUTTERFLY = 'SPORTS_WATER_SPORTS_SWIMMING_BUTTERFLY',
  SPORTS_WATER_SPORTS_SWIMMING_FREESTYLE = 'SPORTS_WATER_SPORTS_SWIMMING_FREESTYLE',
  SPORTS_WATER_SPORTS_SWIMMING_GENERAL = 'SPORTS_WATER_SPORTS_SWIMMING_GENERAL',
  SPORTS_WATER_SPORTS_SWIMMING_SYNCHRONIZED = 'SPORTS_WATER_SPORTS_SWIMMING_SYNCHRONIZED',
  SPORTS_WATER_SPORTS_WAKEBOARDING = 'SPORTS_WATER_SPORTS_WAKEBOARDING',
  SPORTS_WATER_SPORTS_WATER_POLO = 'SPORTS_WATER_SPORTS_WATER_POLO',
  SPORTS_WATER_SPORTS_WATER_SKIING = 'SPORTS_WATER_SPORTS_WATER_SKIING',
  SPORTS_WATER_SPORTS_WINDSURFING = 'SPORTS_WATER_SPORTS_WINDSURFING',
  SPORTS_WINTER_SPORTS = 'SPORTS_WINTER_SPORTS',
  SPORTS_WINTER_SPORTS_BIATHLON = 'SPORTS_WINTER_SPORTS_BIATHLON',
  SPORTS_WINTER_SPORTS_CROSS_COUNTRY_SKIING = 'SPORTS_WINTER_SPORTS_CROSS_COUNTRY_SKIING',
  SPORTS_WINTER_SPORTS_FIGURE_SKATING = 'SPORTS_WINTER_SPORTS_FIGURE_SKATING',
  SPORTS_WINTER_SPORTS_ICE_SKATING = 'SPORTS_WINTER_SPORTS_ICE_SKATING',
  SPORTS_WINTER_SPORTS_LUGE = 'SPORTS_WINTER_SPORTS_LUGE',
  SPORTS_WINTER_SPORTS_SKI_JUMPING = 'SPORTS_WINTER_SPORTS_SKI_JUMPING',
  SPORTS_WINTER_SPORTS_SNOWBOARDING = 'SPORTS_WINTER_SPORTS_SNOWBOARDING',
  SPORTS_WINTER_SPORTS_SNOW_SKIING = 'SPORTS_WINTER_SPORTS_SNOW_SKIING',
  SPORTS_WINTER_SPORTS_SPEED_SKATING = 'SPORTS_WINTER_SPORTS_SPEED_SKATING',
  STUNTS = 'STUNTS',
  STUNTS_AIR_RAMS = 'STUNTS_AIR_RAMS',
  STUNTS_BREAKAWAYS = 'STUNTS_BREAKAWAYS',
  STUNTS_BREAK_FALLS = 'STUNTS_BREAK_FALLS',
  STUNTS_BUILDING_FALLS = 'STUNTS_BUILDING_FALLS',
  STUNTS_CAR_HITS = 'STUNTS_CAR_HITS',
  STUNTS_CREATURE_SUIT_WORK = 'STUNTS_CREATURE_SUIT_WORK',
  STUNTS_FULL_FIRE_BURNS = 'STUNTS_FULL_FIRE_BURNS',
  STUNTS_HIGH_FALLS_ABOVE_50 = 'STUNTS_HIGH_FALLS_ABOVE_50',
  STUNTS_HIGH_FALLS_BELOW_50 = 'STUNTS_HIGH_FALLS_BELOW_50',
  STUNTS_HORSE_FALLS = 'STUNTS_HORSE_FALLS',
  STUNTS_PARTIAL_FIRE_BURNS = 'STUNTS_PARTIAL_FIRE_BURNS',
  STUNTS_PRATFALLS = 'STUNTS_PRATFALLS',
  STUNTS_PRECISION_DRIVING = 'STUNTS_PRECISION_DRIVING',
  STUNTS_RATCHETS = 'STUNTS_RATCHETS',
  STUNTS_SADDLE_FALLS = 'STUNTS_SADDLE_FALLS',
  STUNTS_SQUIB_HITS = 'STUNTS_SQUIB_HITS',
  STUNTS_STAGE_COMBAT = 'STUNTS_STAGE_COMBAT',
  STUNTS_STAIR_FALLS = 'STUNTS_STAIR_FALLS',
  STUNTS_WIRE_WORK = 'STUNTS_WIRE_WORK',
  VOCAL_RANGE = 'VOCAL_RANGE',
  VOCAL_RANGE_ALTO = 'VOCAL_RANGE_ALTO',
  VOCAL_RANGE_BARITONE = 'VOCAL_RANGE_BARITONE',
  VOCAL_RANGE_BASS = 'VOCAL_RANGE_BASS',
  VOCAL_RANGE_MEZZO_SOPRANO = 'VOCAL_RANGE_MEZZO_SOPRANO',
  VOCAL_RANGE_SOPRANO = 'VOCAL_RANGE_SOPRANO',
  VOCAL_RANGE_TENOR = 'VOCAL_RANGE_TENOR',
  WEAPONS_FIREARMS = 'WEAPONS_FIREARMS',
  WEAPONS_FIREARMS_AUTOMATIC_WEAPON = 'WEAPONS_FIREARMS_AUTOMATIC_WEAPON',
  WEAPONS_FIREARMS_BO_STAFF = 'WEAPONS_FIREARMS_BO_STAFF',
  WEAPONS_FIREARMS_BROADSWORD = 'WEAPONS_FIREARMS_BROADSWORD',
  WEAPONS_FIREARMS_CROSS_BOW = 'WEAPONS_FIREARMS_CROSS_BOW',
  WEAPONS_FIREARMS_DAGGERS = 'WEAPONS_FIREARMS_DAGGERS',
  WEAPONS_FIREARMS_HANDGUN = 'WEAPONS_FIREARMS_HANDGUN',
  WEAPONS_FIREARMS_KAMA = 'WEAPONS_FIREARMS_KAMA',
  WEAPONS_FIREARMS_LONG_BOW = 'WEAPONS_FIREARMS_LONG_BOW',
  WEAPONS_FIREARMS_NUNCHUCKS = 'WEAPONS_FIREARMS_NUNCHUCKS',
  WEAPONS_FIREARMS_RAPIER = 'WEAPONS_FIREARMS_RAPIER',
  WEAPONS_FIREARMS_RIFLE = 'WEAPONS_FIREARMS_RIFLE',
  WEAPONS_FIREARMS_SAI = 'WEAPONS_FIREARMS_SAI',
  WEAPONS_FIREARMS_SHOTGUN = 'WEAPONS_FIREARMS_SHOTGUN',
  WEAPONS_FIREARMS_STAFF = 'WEAPONS_FIREARMS_STAFF',
  WEAPONS_FIREARMS_SWORDS = 'WEAPONS_FIREARMS_SWORDS',
  WEAPONS_FIREARMS_THROWING_KNIVES = 'WEAPONS_FIREARMS_THROWING_KNIVES',
  WEAPONS_FIREARMS_THROWING_STARS = 'WEAPONS_FIREARMS_THROWING_STARS',
  WEAPONS_FIREARMS_TONFA = 'WEAPONS_FIREARMS_TONFA',
  WEAPONS_FIREARMS_WHIP = 'WEAPONS_FIREARMS_WHIP'
}

/** Input type for skill and skill level */
export type gqlSkillInput = {
  readonly id: Scalars['ID'];
  readonly levelId?: InputMaybe<Scalars['Float']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly profileMediaId?: InputMaybe<Scalars['Float']>;
};

/** SkillLevel object */
export type gqlSkillLevel = {
  readonly __typename?: 'SkillLevel';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlSkillLevelEnum {
  BEGINNER = 'BEGINNER',
  EXPERT = 'EXPERT',
  INTERMEDIATE = 'INTERMEDIATE'
}

/** Input type for skill and skill level */
export type gqlSkillSearchInput = {
  readonly id: Scalars['ID'];
  readonly levelId?: InputMaybe<Scalars['ID']>;
};

export type gqlSleeve = {
  readonly __typename?: 'Sleeve';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

/** Holds the input data for Slot. */
export type gqlSlot = {
  readonly __typename?: 'Slot';
  /** Slot argument ID */
  readonly slotArguments: ReadonlyArray<gqlSlotArguments>;
  /** Slot ID */
  readonly slotId: Scalars['Int'];
  /** Submission ID */
  readonly submissionIds: ReadonlyArray<Scalars['Int']>;
};

/** Holds the link between slot and entity. */
export type gqlSlotArguments = {
  readonly __typename?: 'SlotArguments';
  /** entity ID */
  readonly entityId: Scalars['BigInt'];
  /** Slot argument entity ID */
  readonly entityType: Scalars['Int'];
  /** Slot argument ID */
  readonly slotArgId: Scalars['Int'];
};

export type gqlSlotSubmissionsInput = {
  /** Slot Id */
  readonly slotId: Scalars['Float'];
  /** List of SubmissionIds. */
  readonly submissionIds: ReadonlyArray<Scalars['BigInt']>;
};

/** Result Sort Conditions */
export type gqlSortConditionInput = {
  /** Column name by which result should be sorted */
  readonly orderColumn: Scalars['String'];
  /** Either ASC or DESC */
  readonly orderDirection: Scalars['String'];
};

export enum gqlSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum gqlSortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

/** Sport Equipment object */
export type gqlSportEquipment = {
  readonly __typename?: 'SportEquipment';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlSportEquipment>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlSportEquipment>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** State */
export type gqlState = {
  readonly __typename?: 'State';
  /** code */
  readonly code: Scalars['String'];
  /** Country Id */
  readonly country: gqlCountry;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlStateFilterInput = {
  /** Filter states by country ID or CODE values */
  readonly country?: InputMaybe<gqlFilterInput>;
  /** Filter states by ID or CODE values */
  readonly state?: InputMaybe<gqlFilterInput>;
};

export type gqlSubFieldsGql = {
  readonly __typename?: 'SubFieldsGql';
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
};

/** Submission object */
export type gqlSubmission = {
  readonly __typename?: 'Submission';
  /** Comments count left in presentations */
  readonly commentsCount?: Maybe<Scalars['Int']>;
  /** Submission creator company name */
  readonly companyName: Scalars['String'];
  readonly contactInfo?: Maybe<gqlProfileContactInfo>;
  /** Submission's cover */
  readonly cover?: Maybe<gqlMedia>;
  /** Created Date */
  readonly created: Scalars['DateTime'];
  /** Submission creator account */
  readonly creatorAccount: gqlAccount;
  /** Notes for the Submission object */
  readonly fastcastNotes: ReadonlyArray<gqlNote>;
  /** Determines has submission audio reel or not */
  readonly hasAudioReel: Scalars['Boolean'];
  /** Determines has submission video reel or not */
  readonly hasVideoReel: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['BigInt'];
  /** @deprecated DEPRECATED. Use submission.source instead. */
  readonly isCreatedByCD?: Maybe<Scalars['Boolean']>;
  /** Identify the submission is cutomized or not. */
  readonly isCustomized: Scalars['Boolean'];
  /** Represents that profile is overscale or not */
  readonly isOverscale?: Maybe<Scalars['Boolean']>;
  /** Last note for the submission */
  readonly lastNote?: Maybe<gqlNote>;
  /** Submission status last changed date */
  readonly lastStatusUpdated?: Maybe<Scalars['DateTime']>;
  /** Submission's media assets */
  readonly media: ReadonlyArray<gqlSubmissionMedia>;
  readonly mediaCount: ReadonlyArray<gqlMediaCount>;
  /** Note */
  readonly note: Scalars['String'];
  /** Notes for the Submission object */
  readonly notes: ReadonlyArray<gqlNote>;
  readonly otherSubmissionSelectionType: gqlProfileSelectionType;
  /** Over scale note */
  readonly overscaleNote?: Maybe<Scalars['String']>;
  readonly pickedBy: ReadonlyArray<gqlSubmissionPick>;
  /** Picks count left in presentations */
  readonly picksCount?: Maybe<Scalars['Int']>;
  /** Profile associated with the subscription */
  readonly profile?: Maybe<gqlProfile>;
  /** Submission Profile Id */
  readonly profileId: Scalars['Int'];
  /** Profile Working Location ID */
  readonly profileWorkingLocationId?: Maybe<Scalars['Int']>;
  /** Submission request's media assets */
  readonly requestMedia: ReadonlyArray<gqlSubmissionMedia>;
  /** Role associated with the submission */
  readonly role: gqlRole;
  /** Submission Role Id */
  readonly roleId: Scalars['Int'];
  /** Submission Role Name */
  readonly roleName?: Maybe<Scalars['String']>;
  /** Schedule Info */
  readonly scheduleInfo?: Maybe<gqlScheduleInfo>;
  /** Schedule slot detail associated with submission */
  readonly scheduleSlotDetail?: Maybe<gqlSubmissionScheduleSlot>;
  /** Is Scheduled */
  readonly scheduled?: Maybe<Scalars['Boolean']>;
  /** Snapshot media assets */
  readonly snapshotMedia?: Maybe<gqlMedia>;
  /** Source of the Submission */
  readonly source?: Maybe<gqlSubmissionSourceType>;
  /** Submission Previous Status */
  readonly submissionPreviousStatus?: Maybe<gqlSubmissionStatus>;
  /** SSubmissions with responses details */
  readonly submissionResponses?: Maybe<gqlSubmissionResponses>;
  /** Submission Status */
  readonly submissionStatus?: Maybe<gqlSubmissionStatus>;
  /** Tile schema value object */
  readonly tileSchemaValue?: Maybe<gqlTileSchemaValue>;
  /** Tile Scheme Value ID */
  readonly tileSchemeValueId?: Maybe<Scalars['Int']>;
  /** Customized Profile Working Location */
  readonly workingLocation?: Maybe<gqlProfileWorkingLocation>;
};


/** Submission object */
export type gqlSubmissionMediaCountArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  requested?: InputMaybe<Scalars['Boolean']>;
};


/** Submission object */
export type gqlSubmissionPickedByArgs = {
  clientNames?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


/** Submission object */
export type gqlSubmissionProfileArgs = {
  returnDeleted?: InputMaybe<Scalars['Boolean']>;
};

/** Submissions By Profile */
export type gqlSubmissionByProfileInput = {
  /** Start Date */
  readonly dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** End Date */
  readonly dateTo?: InputMaybe<Scalars['DateTime']>;
  /** Offset */
  readonly pageNumber?: InputMaybe<Scalars['Float']>;
  /** Page Size */
  readonly pageSize?: InputMaybe<Scalars['Float']>;
};

/** Submission Count By Role object */
export type gqlSubmissionCountPerRole = {
  readonly __typename?: 'SubmissionCountPerRole';
  /** Submission Role Id */
  readonly roleId?: Maybe<Scalars['Int']>;
  /** Total count of rows */
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Submission Search input */
export type gqlSubmissionCountPerRoleInput = {
  readonly excludeRequestMasterId?: InputMaybe<Scalars['String']>;
  /** Profile Type Id */
  readonly profileTypeId?: InputMaybe<Scalars['Int']>;
  /** List of Role IDs */
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly stageName?: InputMaybe<Scalars['String']>;
  /** List of Submission Status Codes */
  readonly submissionStatusCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Submission Tile Schema Codes */
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type gqlSubmissionCreated = {
  readonly __typename?: 'SubmissionCreated';
  readonly created: Scalars['Boolean'];
  readonly submissions: ReadonlyArray<gqlSubmission>;
};

/** Determine discrete boundaries for project filtering */
export enum gqlSubmissionDueDateSearchEnum {
  FUTURE = 'FUTURE',
  TODAY = 'TODAY'
}

/** Submission Media object */
export type gqlSubmissionMedia = {
  readonly __typename?: 'SubmissionMedia';
  readonly accountId: Scalars['Float'];
  readonly appearOnSubmissions: Scalars['Boolean'];
  readonly appearancesOnProfiles: Scalars['Int'];
  readonly archivableOn?: Maybe<Scalars['DateTime']>;
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Returns the Organization of the media create, if one exists */
  readonly createdByOrg?: Maybe<gqlOrganization>;
  /** Formatted size with units */
  readonly displaySize?: Maybe<gqlMediaDisplaySize>;
  /** Enabled */
  readonly enabled: Scalars['Boolean'];
  readonly fileKey?: Maybe<Scalars['String']>;
  readonly fileType: gqlMediaFileType;
  readonly filename?: Maybe<Scalars['String']>;
  readonly guid: Scalars['String'];
  readonly height?: Maybe<Scalars['Int']>;
  /** Media ID */
  readonly id: Scalars['ID'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  /** Is Primary */
  readonly isPrimary: Scalars['Boolean'];
  readonly length?: Maybe<Scalars['Float']>;
  /** Media ID */
  readonly mediaId: Scalars['Int'];
  readonly mediaParentId?: Maybe<Scalars['Float']>;
  readonly mediaStatus: gqlMediaStatus;
  readonly mediaStorageStatus: gqlMediaStorageStatus;
  readonly mediaType?: Maybe<gqlMediaType>;
  readonly moderationStatus: gqlMediaModerationStatus;
  /** Customized media name */
  readonly name?: Maybe<Scalars['String']>;
  /** Order */
  readonly orderOfAppearance: Scalars['Int'];
  readonly profiles?: Maybe<ReadonlyArray<gqlProfileMedia>>;
  readonly purpose: gqlMediaPurpose;
  /** @deprecated use url field instead */
  readonly resizedPaths: ReadonlyArray<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly thumbnail?: Maybe<gqlMedia>;
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly transformation?: Maybe<gqlMediaTransformation>;
  readonly transformations: ReadonlyArray<gqlMedia>;
  readonly updated?: Maybe<Scalars['DateTime']>;
  /** @deprecated use url field instead */
  readonly uploadPath?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly width?: Maybe<Scalars['Int']>;
};

/** Submission Media */
export type gqlSubmissionMediaInput = {
  /** Is media hidden on profile? */
  readonly isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Is media primary? (Primary photo, video reel or audio reel) */
  readonly isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media Id */
  readonly mediaId: Scalars['ID'];
  /** Customize media file name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Media order of appearance */
  readonly order?: InputMaybe<Scalars['Int']>;
};

/** Paginated result with [Submission] objects */
export type gqlSubmissionPaginatedResult = {
  readonly __typename?: 'SubmissionPaginatedResult';
  readonly facets?: Maybe<ReadonlyArray<gqlFacet>>;
  /** Last key */
  readonly lastKey?: Maybe<Scalars['String']>;
  /** List of Submission objects */
  readonly objects: ReadonlyArray<gqlSubmission>;
  /** Current page */
  readonly page: Scalars['Int'];
  /** Total count of rows */
  readonly totalCount: Scalars['Int'];
  /** Total pages */
  readonly totalPages: Scalars['Int'];
};

export type gqlSubmissionPick = {
  readonly __typename?: 'SubmissionPick';
  /** ID of Presentation Group Talent Pick */
  readonly id: Scalars['Int'];
  readonly userName: Scalars['String'];
};

/** Submission Profile Output */
export type gqlSubmissionProfile = {
  readonly __typename?: 'SubmissionProfile';
  /** Profile Information */
  readonly profile?: Maybe<gqlProfile>;
  /** Role Name */
  readonly roleName: Scalars['String'];
};

/** Submission Quick Creation Input type */
export type gqlSubmissionQuickAddInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly profileTypeCode: Scalars['String'];
  readonly roleId: Scalars['Int'];
};

export type gqlSubmissionRequestSearchInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly notInRequest?: InputMaybe<ReadonlyArray<Scalars['Boolean']>>;
  readonly responseStatuses?: InputMaybe<ReadonlyArray<gqlRequestStatusUnion>>;
};

/** Submission Response */
export type gqlSubmissionResponse = {
  readonly __typename?: 'SubmissionResponse';
  readonly chatTopic?: Maybe<gqlChatTopic>;
  /** Chat Topic Key */
  readonly chatTopicKey: Scalars['String'];
  readonly masterId: Scalars['String'];
  /** Request Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Request Type */
  readonly requestType: Scalars['String'];
  /** Response Id */
  readonly responseId: Scalars['String'];
  /** Schedule Id */
  readonly scheduleId?: Maybe<Scalars['Int']>;
  /** Status */
  readonly status: Scalars['String'];
  /** Submission Id */
  readonly submissionId?: Maybe<Scalars['Int']>;
};

/** Submissions Responses */
export type gqlSubmissionResponses = {
  readonly __typename?: 'SubmissionResponses';
  /** Submission Responses */
  readonly responses?: Maybe<ReadonlyArray<gqlSubmissionResponse>>;
  /** Submission Id */
  readonly submissionId: Scalars['Int'];
};

export type gqlSubmissionScheduleRequestSearchInput = {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly notInRequest?: InputMaybe<ReadonlyArray<Scalars['Boolean']>>;
  readonly responseStatuses?: InputMaybe<ReadonlyArray<gqlScheduleInviteRequestStatus>>;
};

/** Submission Schedule Slot Details */
export type gqlSubmissionScheduleSlot = {
  readonly __typename?: 'SubmissionScheduleSlot';
  /** Schedule EndTime */
  readonly endTime?: Maybe<Scalars['DateTime']>;
  /** Schedule Date */
  readonly scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Schedule Id */
  readonly scheduleId: Scalars['ID'];
  /** Calendar Slot EndTime */
  readonly slotEndTime?: Maybe<Scalars['DateTime']>;
  /** Calendar Slot StartTime */
  readonly slotStartTime?: Maybe<Scalars['DateTime']>;
  /** Schedule StartTime */
  readonly startTime?: Maybe<Scalars['DateTime']>;
  /** Submission Id */
  readonly submissionId: Scalars['ID'];
  /** Time Zone */
  readonly timeZone?: Maybe<gqlTimeZone>;
  /** Timezone Id */
  readonly timeZoneId: Scalars['ID'];
};

/** Submission Search input */
export type gqlSubmissionSearchInput = {
  /** Is submission scheduled for audition */
  readonly auditionScheduled?: InputMaybe<Scalars['Boolean']>;
  /** Is submission scheduled for callback */
  readonly callbackScheduled?: InputMaybe<Scalars['Boolean']>;
  /** List of Submission IDs to be excluded from the search results */
  readonly excludeSubmissionsIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly hasComments?: InputMaybe<Scalars['Boolean']>;
  readonly hasPicks?: InputMaybe<Scalars['Boolean']>;
  /** Profile Search Input */
  readonly profileSearchInput?: InputMaybe<gqlProfileSearchInput>;
  /** List of Role IDs */
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Filter by Schedule */
  readonly scheduleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** Is submission scheduled */
  readonly scheduled?: InputMaybe<Scalars['Boolean']>;
  /** Filter by Audition Session */
  readonly sessionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly stageName?: InputMaybe<Scalars['String']>;
  /** List of Submission IDs */
  readonly submissionIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** List of Submission Status Codes */
  readonly submissionStatusCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Submission Status IDs */
  readonly submissionStatusIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  /** List of Submission Tile Schema Codes */
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** List of Submission Tile Schema IDs */
  readonly tileSchemeValueIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

/** Enum of an available columns for sorting */
export enum gqlSubmissionSearchSortColumn {
  LAST_NAME = 'LAST_NAME',
  RANDOM = 'RANDOM',
  RANDOMIZE = 'RANDOMIZE',
  ROLE = 'ROLE',
  SELECTION_TAG = 'SELECTION_TAG',
  STAGE_NAME = 'STAGE_NAME',
  SUBMITTED_DATE = 'SUBMITTED_DATE'
}

export type gqlSubmissionSearchSortOptionsInput = {
  /** Field name by which should be sorted */
  readonly fieldName: gqlSubmissionSearchSortColumn;
  /** Sort order value(asc or desc). Default value: asc */
  readonly sortOrder?: InputMaybe<gqlSortOrderEnum>;
};

/** Submission Selection Result object */
export type gqlSubmissionSelectionResult = {
  readonly __typename?: 'SubmissionSelectionResult';
  /** Other Submissions of artist in the same project */
  readonly otherSubmissions?: Maybe<ReadonlyArray<gqlSubmission>>;
  /** Current Submission updated */
  readonly updatedSubmission: gqlSubmission;
};

/** Submission Source Type */
export type gqlSubmissionSourceType = {
  readonly __typename?: 'SubmissionSourceType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** SubmissionStatus object */
export type gqlSubmissionStatus = {
  readonly __typename?: 'SubmissionStatus';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlSubmissionStatusCodeEnum {
  BOOKED = 'BOOKED',
  DRAFT = 'DRAFT',
  ON_AVAIL = 'ON_AVAIL',
  REVIEWED = 'REVIEWED',
  SELECTED = 'SELECTED',
  TO_REVIEW = 'TO_REVIEW',
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
  UNSUBMITTED = 'UNSUBMITTED'
}

export enum gqlSubmissionStatusEnum {
  BOOKED = 'BOOKED',
  DRAFT = 'DRAFT',
  ON_AVAIL = 'ON_AVAIL',
  REVIEWED = 'REVIEWED',
  SELECTED = 'SELECTED',
  TO_REVIEW = 'TO_REVIEW',
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
  UNSUBMITTED = 'UNSUBMITTED'
}

export type gqlSubmissionTrend = {
  readonly __typename?: 'SubmissionTrend';
  readonly date?: Maybe<Scalars['DateTime']>;
  readonly selectionCount: Scalars['Int'];
  readonly submissionCount: Scalars['Int'];
};

export type gqlSubmissionTrendsInput = {
  readonly interval?: InputMaybe<gqlIntervalEnum>;
  readonly timeFrame?: InputMaybe<gqlTimeFrameEnum>;
};

export type gqlSubmissionTrendsNoAccessError = gqlIError & {
  readonly __typename?: 'SubmissionTrendsNoAccessError';
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

export type gqlSubmissionTrendsResult = gqlSubmissionTrendsNoAccessError | gqlSubmissionTrendsSuccess;

export type gqlSubmissionTrendsSuccess = {
  readonly __typename?: 'SubmissionTrendsSuccess';
  readonly data: ReadonlyArray<gqlSubmissionTrend>;
};

/** Input type for updating submission */
export type gqlSubmissionUpdateInput = {
  /** Profile working location id */
  readonly dmaId?: InputMaybe<Scalars['Int']>;
  /** Represents that profile is overscale or not */
  readonly isOverscale?: InputMaybe<Scalars['Boolean']>;
  /** Note for submission */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Over scale note */
  readonly overscaleNote?: InputMaybe<Scalars['String']>;
  /** Profile ID */
  readonly profileId?: InputMaybe<Scalars['Int']>;
  /** Request media(s) */
  readonly requestMedia?: InputMaybe<ReadonlyArray<gqlSubmissionMediaInput>>;
  /** Submission status CODE */
  readonly statusCode?: InputMaybe<Scalars['String']>;
  /** Submission ID */
  readonly submissionId: Scalars['BigInt'];
  /** Submission profile media(s) */
  readonly submissionProfileMedia?: InputMaybe<ReadonlyArray<gqlSubmissionMediaInput>>;
  /** Tile Schema Value CODE */
  readonly tileSchemeValueCode?: InputMaybe<Scalars['String']>;
};

/** Input type for updating submission */
export type gqlSubmissionUpdateSelectionInput = {
  /** Submission status CODE */
  readonly statusCode: Scalars['String'];
  /** Submission ID */
  readonly submissionId: Scalars['BigInt'];
  /** Tile Schema Value Code */
  readonly tileSchemeValueCode?: InputMaybe<Scalars['String']>;
};

/** Submission data for Workflow */
export type gqlSubmissionWorkflow = {
  readonly __typename?: 'SubmissionWorkflow';
  /** Talent's custom cover */
  readonly cover?: Maybe<gqlMedia>;
  readonly creatorAccountId: Scalars['Int'];
  readonly id: Scalars['BigInt'];
  readonly isCreatedByCD?: Maybe<Scalars['Boolean']>;
  readonly isCustomized: Scalars['Boolean'];
  readonly profile?: Maybe<gqlProfile>;
  readonly project: gqlProjectWorkflow;
  readonly role: gqlRoleWorkflow;
};

/** Submission data for Workflow. Short version */
export type gqlSubmissionWorkflowShort = {
  readonly __typename?: 'SubmissionWorkflowShort';
  readonly agencyId?: Maybe<Scalars['Int']>;
  readonly creatorAccountId: Scalars['Int'];
  readonly id: Scalars['BigInt'];
  readonly profileAccountId?: Maybe<Scalars['Int']>;
  readonly profileArtistId?: Maybe<Scalars['Int']>;
  readonly profileId: Scalars['Int'];
  readonly projectId: Scalars['Int'];
  readonly roleId: Scalars['Int'];
};

export type gqlSubscription = {
  readonly __typename?: 'Subscription';
  readonly billingInfoId?: Maybe<Scalars['String']>;
  readonly canRenew: Scalars['Boolean'];
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly nextBillingDate?: Maybe<Scalars['DateTime']>;
  /** Null unless subscription is paused or will pause at the end of the current billing period. */
  readonly pausedAt?: Maybe<Scalars['DateTime']>;
  readonly plan: gqlBillingPlan;
  /** Null unless subscription is paused and will be resumed at the end of the current billing period. */
  readonly resumeAt?: Maybe<Scalars['DateTime']>;
  readonly status: Scalars['String'];
  readonly transaction?: Maybe<Scalars['String']>;
  readonly trialEndsAt?: Maybe<Scalars['DateTime']>;
  /**
   * The number of trial days offered to the user when they signed up for this
   * subscription. This can be different from the plan trialLengthDays.
   */
  readonly trialLengthDays?: Maybe<Scalars['Int']>;
};

/** Subscription Invoice Model */
export type gqlSubscriptionInvoice = {
  readonly __typename?: 'SubscriptionInvoice';
  readonly coupon?: Maybe<gqlCoupon>;
  readonly creditsApplied: Scalars['Float'];
  readonly currency: gqlCurrency;
  readonly discount: Scalars['Float'];
  readonly email: Scalars['String'];
  readonly invoiceId?: Maybe<Scalars['String']>;
  readonly paymentDetails?: Maybe<gqlPaymentDetails>;
  readonly subscription: gqlSubscription;
  readonly total: Scalars['Float'];
};

export type gqlSubscriptionMediaEntitlement = {
  readonly __typename?: 'SubscriptionMediaEntitlement';
  readonly count: Scalars['Int'];
  readonly isUnlimited: Scalars['Boolean'];
  readonly mediaType: Scalars['String'];
};

/** Subscription */
export type gqlSubscriptionModel = {
  readonly __typename?: 'SubscriptionModel';
  readonly billingInfoId?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly nextBillingDate?: Maybe<Scalars['DateTime']>;
  readonly plan?: Maybe<gqlPlanModel>;
  readonly status: Scalars['String'];
  readonly transaction?: Maybe<Scalars['String']>;
  readonly trialEndsAt?: Maybe<Scalars['DateTime']>;
  readonly trialLengthDays?: Maybe<Scalars['Int']>;
};

/** Subscription Renewal Model */
export type gqlSubscriptionRenewal = {
  readonly __typename?: 'SubscriptionRenewal';
  readonly cardType: Scalars['String'];
  readonly creditsApplied: Scalars['Float'];
  readonly currency: gqlCurrency;
  readonly discount: Scalars['Float'];
  readonly email: Scalars['String'];
  readonly invoiceId?: Maybe<Scalars['String']>;
  readonly lastFour: Scalars['String'];
  readonly paymentType?: Maybe<Scalars['String']>;
  readonly subscription: gqlBillingItemModel;
  readonly total: Scalars['Float'];
};

export enum gqlSubscriptionStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FUTURE = 'FUTURE',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING'
}

/** Success Response */
export type gqlSuccessResponse = {
  readonly __typename?: 'SuccessResponse';
  readonly success: Scalars['Boolean'];
};

/** System role */
export type gqlSystemRole = {
  readonly __typename?: 'SystemRole';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Specifies, is it internal role */
  readonly internal: Scalars['Boolean'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlSystemRoleCode {
  AGENT = 'AGENT',
  API = 'API',
  CASTING_DIRECTOR = 'CASTING_DIRECTOR',
  CREATIVE = 'CREATIVE',
  LEGACY_USER = 'LEGACY_USER',
  MANAGER = 'MANAGER',
  PROJECT_CREATOR = 'PROJECT_CREATOR',
  ROOT_USER = 'ROOT_USER',
  SHARED_PROJECT_USER = 'SHARED_PROJECT_USER',
  STUDIO = 'STUDIO',
  TALENT = 'TALENT'
}

export enum gqlSystemRoleEnum {
  Agent = 'Agent',
  Api = 'Api',
  CastingDirector = 'CastingDirector',
  Creative = 'Creative',
  LegacyUser = 'LegacyUser',
  Manager = 'Manager',
  ProjectCreator = 'ProjectCreator',
  RootUser = 'RootUser',
  SharedProjectUser = 'SharedProjectUser',
  Studio = 'Studio',
  Talent = 'Talent'
}

/** Tshirt Measurement object */
export type gqlTShirtSize = {
  readonly __typename?: 'TShirtSize';
  /** Gender */
  readonly category: gqlBasicSetting;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Tag object */
export type gqlTag = {
  readonly __typename?: 'Tag';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlTag>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlTag>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Contex object for talent access profile */
export type gqlTalentAccessProfileContext = {
  readonly __typename?: 'TalentAccessProfileContext';
  readonly buttonText: Scalars['String'];
  readonly buttonURL: Scalars['String'];
  readonly expiresDays: Scalars['Float'];
  readonly manageEmailSettingsUrl: Scalars['String'];
  readonly messageFromRepresentative: Scalars['String'];
  readonly organizationDivision: Scalars['String'];
  readonly organizationLogo: Scalars['String'];
  readonly profile: gqlTalentAccessProfileDetailsContext;
};

/** Talent Access Profile Context input */
export type gqlTalentAccessProfileContextInput = {
  readonly accessToken: Scalars['String'];
  readonly email: Scalars['String'];
  readonly note?: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['Int'];
  readonly stageName: Scalars['String'];
};

export type gqlTalentAccessProfileContextNotificationContextAndRecipient = {
  readonly __typename?: 'TalentAccessProfileContextNotificationContextAndRecipient';
  readonly context: gqlTalentAccessProfileContext;
  readonly to?: Maybe<Scalars['String']>;
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toOrgId?: Maybe<Scalars['Int']>;
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

/** Context object for talent access profile object */
export type gqlTalentAccessProfileDetailsContext = {
  readonly __typename?: 'TalentAccessProfileDetailsContext';
  readonly name: Scalars['String'];
};

export type gqlTalentPickPublic = {
  readonly __typename?: 'TalentPickPublic';
  readonly id: Scalars['String'];
  readonly userName: Scalars['String'];
};

/** Talent Profile Union */
export type gqlTalentProfileUnion = {
  readonly __typename?: 'TalentProfileUnion';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlTalentUnion>;
  /** code */
  readonly code: Scalars['String'];
  /** country */
  readonly country?: Maybe<gqlCountry>;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlTalentUnion>;
  /** Represents project union for all true values */
  readonly projectEnabled: Scalars['Boolean'];
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
  /** Represents talent union for all true values */
  readonly talentEnabled: Scalars['Boolean'];
  /** Talent Union membership Id (usually number) */
  readonly talentMembershipId?: Maybe<Scalars['String']>;
};

export type gqlTalentScoutModel = {
  readonly __typename?: 'TalentScoutModel';
  readonly createdAt: Scalars['DateTime'];
  /** Account of creator */
  readonly createdBy?: Maybe<gqlAccount>;
  readonly id: Scalars['String'];
  readonly message: Scalars['String'];
  readonly profile?: Maybe<gqlProfile>;
};

/** Talent scout history list */
export type gqlTalentScoutPageListResult = {
  readonly __typename?: 'TalentScoutPageListResult';
  /** List of talent scout */
  readonly data: ReadonlyArray<gqlTalentScoutModel>;
  /** Current Page */
  readonly page: Scalars['Int'];
  /** Total records count */
  readonly totalCount: Scalars['Int'];
  /** Total Pages */
  readonly totalPages: Scalars['Int'];
};

export enum gqlTalentStatusEnum {
  SelectedTalent = 'SelectedTalent',
  SubmittedTalent = 'SubmittedTalent'
}

/** Talent Submission Item Payload */
export type gqlTalentSubmissionItemModel = {
  readonly __typename?: 'TalentSubmissionItemModel';
  readonly ageRange: Scalars['String'];
  readonly castingDirector?: Maybe<Scalars['String']>;
  readonly id: Scalars['Float'];
  readonly mediaRequest: Scalars['Boolean'];
  readonly projectName: Scalars['String'];
  readonly roleName: Scalars['String'];
  readonly status: Scalars['String'];
  readonly submissionDate: Scalars['DateTime'];
};

/** Talent Submission Report */
export type gqlTalentSubmissionModel = {
  readonly __typename?: 'TalentSubmissionModel';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<Scalars['Int']>;
  readonly csvFileName?: Maybe<Scalars['String']>;
  readonly dateFrom: Scalars['DateTime'];
  readonly dateTo: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly organizationId?: Maybe<Scalars['Int']>;
  readonly payload?: Maybe<gqlTalentSubmissionPayloadModel>;
  readonly profileId: Scalars['Int'];
  readonly reportType: gqlReportTypeEnum;
  readonly status: gqlReportStatusEnum;
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Talent Submission Report Payload */
export type gqlTalentSubmissionPayloadModel = {
  readonly __typename?: 'TalentSubmissionPayloadModel';
  readonly castingCompanyInfo?: Maybe<gqlCompanyInfoModel>;
  readonly profile?: Maybe<gqlTalentSubmissionProfileModel>;
  readonly submissions?: Maybe<ReadonlyArray<gqlTalentSubmissionItemModel>>;
};

/** Talent Submission Profile Model */
export type gqlTalentSubmissionProfileModel = {
  readonly __typename?: 'TalentSubmissionProfileModel';
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly stageName?: Maybe<Scalars['String']>;
};

/** Talent Submission Report Input Arguments */
export type gqlTalentSubmissionReportCreateInput = {
  readonly dateFrom: Scalars['DateTime'];
  readonly dateTo: Scalars['DateTime'];
  readonly profileId: Scalars['Int'];
};

/** Talent Union */
export type gqlTalentUnion = {
  readonly __typename?: 'TalentUnion';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlTalentUnion>;
  /** code */
  readonly code: Scalars['String'];
  /** country */
  readonly country?: Maybe<gqlCountry>;
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlTalentUnion>;
  /** Represents project union for all true values */
  readonly projectEnabled: Scalars['Boolean'];
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
  /** Represents talent union for all true values */
  readonly talentEnabled: Scalars['Boolean'];
};

export enum gqlTalentUnionEnum {
  ACTRA = 'ACTRA',
  AEA = 'AEA',
  ANTC = 'ANTC',
  BAY_AREA_THEATRE = 'BAY_AREA_THEATRE',
  BUSINESS_THEATRE_EVENTS = 'BUSINESS_THEATRE_EVENTS',
  CABARET = 'CABARET',
  CAEA = 'CAEA',
  CASINO = 'CASINO',
  CAT = 'CAT',
  COMMERCIAL = 'COMMERCIAL',
  CORPORATE_EDUCATIONAL_NON_BROADCAST = 'CORPORATE_EDUCATIONAL_NON_BROADCAST',
  CORST = 'CORST',
  COST = 'COST',
  DINNER_THEATRE = 'DINNER_THEATRE',
  DINNER_THEATRE_ARTIST = 'DINNER_THEATRE_ARTIST',
  DISNEYWORLD = 'DISNEYWORLD',
  ELIGIBLE = 'ELIGIBLE',
  EQUITY = 'EQUITY',
  FICORE = 'FICORE',
  GUEST_ARTIST = 'GUEST_ARTIST',
  HAT = 'HAT',
  LOA = 'LOA',
  LOA_NYC = 'LOA_NYC',
  LORT_NON_REP = 'LORT_NON_REP',
  LORT_REP = 'LORT_REP',
  LOS_ANGELES_TRANSITIONAL_99_SEAT_THEATRE_CODE = 'LOS_ANGELES_TRANSITIONAL_99_SEAT_THEATRE_CODE',
  LOW_BUDGET_FILM = 'LOW_BUDGET_FILM',
  MEAA = 'MEAA',
  MEMBER = 'MEMBER',
  MIDSIZE_THEATRES = 'MIDSIZE_THEATRES',
  MINI = 'MINI',
  MODIFIED_BAT = 'MODIFIED_BAT',
  MODIFIED_LOW_BUDGET_FILM = 'MODIFIED_LOW_BUDGET_FILM',
  MSUA = 'MSUA',
  MUSIC_VIDEO = 'MUSIC_VIDEO',
  NEAT = 'NEAT',
  NEW_MEDIA = 'NEW_MEDIA',
  NOLA = 'NOLA',
  NON_UNION = 'NON_UNION',
  NO_UNION_AFFILIATION = 'NO_UNION_AFFILIATION',
  OAT = 'OAT',
  OFF_BROADWAY = 'OFF_BROADWAY',
  OTHER = 'OTHER',
  OUTDOOR_DRAMA = 'OUTDOOR_DRAMA',
  PRODUCTION_DISNEY = 'PRODUCTION_DISNEY',
  PRODUCTION_LEAGUE = 'PRODUCTION_LEAGUE',
  RMTA = 'RMTA',
  SAG_AFTRA = 'SAG_AFTRA',
  SHORT_ENGAGEMENT_TOURING_AGREEMENT = 'SHORT_ENGAGEMENT_TOURING_AGREEMENT',
  SHORT_FILM = 'SHORT_FILM',
  SHOWCASE_NY = 'SHOWCASE_NY',
  SOUND_RECORDINGS = 'SOUND_RECORDINGS',
  SPECIAL_AGREEMENT = 'SPECIAL_AGREEMENT',
  SPECIAL_APPEARANCE = 'SPECIAL_APPEARANCE',
  SPECIAL_PRODUCTION_CENTRAL = 'SPECIAL_PRODUCTION_CENTRAL',
  SPECIAL_PRODUCTION_EASTERN = 'SPECIAL_PRODUCTION_EASTERN',
  SPECIAL_PRODUCTION_WESTERN = 'SPECIAL_PRODUCTION_WESTERN',
  SPT = 'SPT',
  STUDENT_FILM = 'STUDENT_FILM',
  TELEVISION = 'TELEVISION',
  THEATRICAL_FILM = 'THEATRICAL_FILM',
  TRANSITION = 'TRANSITION',
  TYA = 'TYA',
  UBCP_ACTRA = 'UBCP_ACTRA',
  UDA = 'UDA',
  ULTRA_LOW_BUDGET_FILM = 'ULTRA_LOW_BUDGET_FILM',
  URTA = 'URTA',
  WCLO = 'WCLO',
  WORKSHOP = 'WORKSHOP'
}

/** Input type for talent union */
export type gqlTalentUnionInput = {
  readonly unionId: Scalars['ID'];
  readonly unionMemberId?: InputMaybe<Scalars['String']>;
};

export type gqlTalentUnionModel = {
  readonly __typename?: 'TalentUnionModel';
  readonly code?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name?: Maybe<Scalars['String']>;
  readonly talentMembershipId?: Maybe<Scalars['String']>;
};

/** Tattoo object */
export type gqlTattoo = {
  readonly __typename?: 'Tattoo';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for search by tattoo attributes */
export type gqlTattooInput = {
  /** tattoo id list */
  readonly tattooId?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** tattoo description */
  readonly tattooNote?: InputMaybe<Scalars['String']>;
};

export enum gqlThumbnailType {
  headshot = 'headshot',
  snapshot = 'snapshot'
}

export enum gqlTileSchemaEnum {
  Default = 'Default',
  PresentationPick = 'PresentationPick'
}

/** Publication Setting object */
export type gqlTileSchemaValue = {
  readonly __typename?: 'TileSchemaValue';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlTileSchemaValueEnum {
  CB = 'CB',
  PACKAGE_PICK_1 = 'PACKAGE_PICK_1',
  PRESENTATION_PICK_1 = 'PRESENTATION_PICK_1',
  _1 = '_1',
  _2 = '_2',
  _3 = '_3',
  _4 = '_4',
  _5 = '_5',
  _6 = '_6'
}

export enum gqlTileSchemeValueEnum {
  CB = 'CB',
  PACKAGE_PICK_1 = 'PACKAGE_PICK_1',
  PRESENTATION_PICK_1 = 'PRESENTATION_PICK_1',
  _1 = '_1',
  _2 = '_2',
  _3 = '_3',
  _4 = '_4',
  _5 = '_5',
  _6 = '_6'
}

/** Time frame */
export enum gqlTimeFrameEnum {
  All = 'All',
  NinetyDays = 'NinetyDays',
  SixMonths = 'SixMonths',
  ThirtyDays = 'ThirtyDays',
  Year = 'Year'
}

/** Time Zone object */
export type gqlTimeZone = {
  readonly __typename?: 'TimeZone';
  readonly abbreviation?: Maybe<Scalars['String']>;
  /** code */
  readonly code: Scalars['String'];
  readonly codeBrowser: Scalars['String'];
  readonly daylightSavingsCode?: Maybe<Scalars['String']>;
  readonly dstOffset: Scalars['Float'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  readonly gmtOffset: Scalars['Float'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name?: Maybe<Scalars['String']>;
  readonly rawOffset: Scalars['Float'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
  readonly standardName: Scalars['String'];
};

/** Account info */
export type gqlUnclaimedAccount = {
  readonly __typename?: 'UnclaimedAccount';
  readonly accountOrganization?: Maybe<ReadonlyArray<gqlOrganizationInfo>>;
  readonly accountOrganizationDivisionsAccess: ReadonlyArray<gqlOrganization>;
  readonly accountOrganizationDivisionsAccessCount?: Maybe<Scalars['Float']>;
  /** Account status object */
  readonly accountStatus?: Maybe<gqlAccountStatus>;
  readonly accountStatusId: Scalars['Int'];
  readonly accountSystemRoles: ReadonlyArray<gqlSystemRole>;
  readonly accountUrlTypes?: Maybe<ReadonlyArray<gqlAccountUrlType>>;
  readonly artists: ReadonlyArray<gqlArtist>;
  /** Account billing info */
  readonly billingInfo?: Maybe<gqlAccountBillingInfo>;
  /** Country object */
  readonly country?: Maybe<gqlCountry>;
  readonly countryId: Scalars['Int'];
  readonly created: Scalars['DateTime'];
  /** Divisions that user belongs to */
  readonly divisions?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly email: Scalars['String'];
  /** Holds the generated token value, till it gets expired or the email update verification done successfully. */
  readonly emailResetToken?: Maybe<Scalars['String']>;
  /** Holds the value to reset the original account email till it gets verified */
  readonly emailToReset?: Maybe<Scalars['String']>;
  /** Returns organization features */
  readonly features?: Maybe<ReadonlyArray<gqlFeature>>;
  readonly firstName: Scalars['String'];
  readonly former?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  readonly ipcInfo: gqlIpcInfo;
  /** Checks if user is in role Independent Project Creator */
  readonly isAccountIPC: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  /** Checks if user is in role Casting Director */
  readonly isCCD: Scalars['Boolean'];
  /** Checks if user is in role Independent Project Creator */
  readonly isIPC: Scalars['Boolean'];
  /** Checks if the current/switched user is PC or not */
  readonly isInPCContext: Scalars['Boolean'];
  /** Language Info object */
  readonly language?: Maybe<gqlLanguageInfo>;
  readonly languageLocaleId?: Maybe<Scalars['Int']>;
  readonly lastArtistId?: Maybe<Scalars['Int']>;
  readonly lastLogin?: Maybe<Scalars['DateTime']>;
  readonly lastName: Scalars['String'];
  /** Legacy email */
  readonly legacyEmail?: Maybe<Scalars['String']>;
  /** Legacy instance */
  readonly legacyInstance?: Maybe<Scalars['String']>;
  /** Legacy login */
  readonly legacyLogin?: Maybe<Scalars['String']>;
  readonly legacyProfileStatus?: Maybe<gqlProfileStatus>;
  readonly linkedAccountDivisionCount?: Maybe<Scalars['Float']>;
  readonly linkedAccountOrganization?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly linkedOrganization?: Maybe<Scalars['Float']>;
  readonly linkedToAccountId?: Maybe<Scalars['Int']>;
  readonly loginAttemptCount?: Maybe<Scalars['Int']>;
  readonly loginAttemptFailedAt?: Maybe<Scalars['DateTime']>;
  readonly notificationSubscriptions: ReadonlyArray<gqlNotificationTypeNotificationSubscription>;
  readonly organizationDivisionsCount?: Maybe<Scalars['Float']>;
  /** Organizations that user belongs to */
  readonly organizations?: Maybe<ReadonlyArray<gqlOrganization>>;
  readonly passwordHash?: Maybe<Scalars['String']>;
  readonly phone: Scalars['String'];
  readonly preferences: gqlAccountPreferences;
  readonly profileId?: Maybe<Scalars['Float']>;
  /** Roster invitation token to hold the invitation token till logged in user will not create the profile using it */
  readonly rosterInvitationToken?: Maybe<Scalars['String']>;
  readonly subscription?: Maybe<gqlActiveSubscriptionModel>;
  /** system roles */
  readonly systemRoles?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly systemRolesIds?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly termsOfUse: Scalars['Boolean'];
  /** Time zone object */
  readonly timeZone?: Maybe<gqlTimeZone>;
  /** Updated at Date */
  readonly updated?: Maybe<Scalars['DateTime']>;
};


/** Account info */
export type gqlUnclaimedAccountAccountOrganizationArgs = {
  systemRoleCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


/** Account info */
export type gqlUnclaimedAccountOrganizationsArgs = {
  systemRoleCode?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type gqlUnderBust = {
  readonly __typename?: 'UnderBust';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

/** Unique Attributes */
export type gqlUniqueAttribute = {
  readonly __typename?: 'UniqueAttribute';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Unit System object */
export type gqlUnitSystem = {
  readonly __typename?: 'UnitSystem';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlUnitSystemEnum {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC'
}

export type gqlUnlockMediaInput = {
  readonly mediaIds: ReadonlyArray<Scalars['ID']>;
};

export type gqlUnlockMediaResponse = {
  readonly __typename?: 'UnlockMediaResponse';
  readonly media: ReadonlyArray<gqlMedia>;
  readonly mediaExternalStatusIds: ReadonlyArray<Scalars['Int']>;
  readonly success: Scalars['Boolean'];
};

/** Updates a user account based on given input values */
export type gqlUpdateAccountInput = {
  readonly accountId?: InputMaybe<Scalars['Int']>;
  readonly countryId?: InputMaybe<Scalars['Int']>;
  readonly email?: InputMaybe<Scalars['String']>;
  /** Holds the generated token value, till it gets expired or the email update verification done successfully. */
  readonly emailResetToken?: InputMaybe<Scalars['String']>;
  /** Holds email reset token creation date and time till token gets expired or the email update verification done successfully. */
  readonly emailResetTokenCreated?: InputMaybe<Scalars['DateTime']>;
  /** Used for holding the new email value going to be updated to the actual account email */
  readonly emailToReset?: InputMaybe<Scalars['String']>;
  readonly firstName?: InputMaybe<Scalars['String']>;
  readonly languageLocaleId?: InputMaybe<Scalars['Int']>;
  readonly lastName?: InputMaybe<Scalars['String']>;
  readonly loginAttemptCount?: InputMaybe<Scalars['Int']>;
  readonly loginAttemptFailedAt?: InputMaybe<Scalars['DateTime']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly rosterInvitationToken?: InputMaybe<Scalars['String']>;
  readonly timezoneId?: InputMaybe<Scalars['Int']>;
};

export type gqlUpdateBillingInfoInput = {
  readonly backupPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  readonly billingInfoId: Scalars['String'];
  readonly primaryPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  readonly threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  readonly tokenId?: InputMaybe<Scalars['String']>;
};

export type gqlUpdateDueDateInput = {
  readonly dueDate?: InputMaybe<Scalars['DateTime']>;
  readonly dueDateTimeZoneId?: InputMaybe<Scalars['Int']>;
};

/** Update Legacy Profile Result Object */
export type gqlUpdateLegacyResult = {
  readonly __typename?: 'UpdateLegacyResult';
  /** Update Legacy Profile Status */
  readonly status?: Maybe<Scalars['Int']>;
  /** New JWT Token For Redirect */
  readonly token?: Maybe<gqlAuthTokenItem>;
};

/** Update List Name Input */
export type gqlUpdateListNameInput = {
  /** List Id */
  readonly listId: Scalars['Float'];
  /** List Name */
  readonly listName: Scalars['String'];
};

export type gqlUpdateMediaInput = {
  readonly length?: InputMaybe<Scalars['Int']>;
  readonly mediaId: Scalars['Int'];
  readonly mediaModerationStatusCode?: InputMaybe<Scalars['String']>;
  readonly mediaStatusId?: InputMaybe<Scalars['Int']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly paid?: InputMaybe<Scalars['Boolean']>;
  readonly size?: InputMaybe<Scalars['Int']>;
  readonly tag?: InputMaybe<Scalars['String']>;
};

/** Update Note Input Type */
export type gqlUpdateNoteInput = {
  /** Text of the note */
  readonly comment?: InputMaybe<Scalars['String']>;
  /** Entity ID that note will be assigned to */
  readonly entityId?: InputMaybe<Scalars['Int']>;
  /** Entity Type CODE */
  readonly entityTypeCode?: InputMaybe<Scalars['String']>;
  /** Note ID which should be updated */
  readonly noteId: Scalars['Int'];
  /** Note Modifier CODE */
  readonly noteModifierCode?: InputMaybe<Scalars['String']>;
  /** Note Type CODE */
  readonly noteTypeCode?: InputMaybe<Scalars['String']>;
};

/** Input type for Organization update */
export type gqlUpdateOrganizationInput = {
  readonly abbreviation?: InputMaybe<Scalars['String']>;
  readonly address?: InputMaybe<ReadonlyArray<gqlAddressInput>>;
  readonly contactList?: InputMaybe<ReadonlyArray<gqlContactInput>>;
  readonly countryId?: InputMaybe<Scalars['Int']>;
  readonly creatorId?: InputMaybe<Scalars['Int']>;
  readonly deletedContactIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly diversityDisclaimer?: InputMaybe<Scalars['String']>;
  readonly divisionFeaturesList?: InputMaybe<ReadonlyArray<gqlFeatureInput>>;
  /** Devisions could have multiple locations/dma's */
  readonly dmaIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly featureIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly logoMediaId?: InputMaybe<Scalars['Int']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['Int'];
  readonly organizationParentId?: InputMaybe<Scalars['Int']>;
  readonly organizationStatusCode?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly profileListId?: InputMaybe<Scalars['Int']>;
  readonly projectCastingAssistant?: InputMaybe<Scalars['String']>;
  readonly projectCastingAssociate?: InputMaybe<Scalars['String']>;
  readonly projectCastingDirector?: InputMaybe<Scalars['String']>;
  readonly representationAgeCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly representationCategoryIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly representationTypeId?: InputMaybe<Scalars['Int']>;
  readonly scheduleAddressNote?: InputMaybe<Scalars['String']>;
  readonly systemRoleId?: InputMaybe<Scalars['Int']>;
  readonly tileSchemeId?: InputMaybe<Scalars['Int']>;
  readonly websiteUrl?: InputMaybe<Scalars['String']>;
};

/** Update Organization Result Object */
export type gqlUpdateOrganizationResult = {
  readonly __typename?: 'UpdateOrganizationResult';
  /** Update Organization Status */
  readonly status?: Maybe<Scalars['Int']>;
  /** New JWT Token For Redirect */
  readonly token?: Maybe<gqlAuthTokenItem>;
};

/** Determined location Address Input for creating IPC project */
export type gqlUpdatePcProjectDeterminedAddressInput = {
  /** City */
  readonly city?: InputMaybe<Scalars['ID']>;
  /** Country CODE */
  readonly countryCode?: InputMaybe<Scalars['ID']>;
  /** Location notes */
  readonly locationNotes?: InputMaybe<Scalars['ID']>;
};

/** Input type for PC Project update */
export type gqlUpdatePcProjectInput = {
  readonly adAgency?: InputMaybe<Scalars['String']>;
  readonly additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  readonly attachmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Project sides visibility CODE */
  readonly attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  readonly auditionTypeId?: InputMaybe<Scalars['ID']>;
  readonly canTalentSubmitSelfTape?: InputMaybe<Scalars['Boolean']>;
  readonly castingAssistant?: InputMaybe<Scalars['String']>;
  readonly castingAssociate?: InputMaybe<Scalars['String']>;
  readonly castingDirector?: InputMaybe<Scalars['String']>;
  readonly conflictIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly conflictNote?: InputMaybe<Scalars['String']>;
  readonly contactEmail?: InputMaybe<Scalars['String']>;
  readonly contactInfoPermissions?: InputMaybe<Scalars['Boolean']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  readonly contractStatusCode?: InputMaybe<Scalars['String']>;
  readonly determinedLocationInput?: InputMaybe<gqlUpdatePcProjectDeterminedAddressInput>;
  readonly director?: InputMaybe<Scalars['String']>;
  readonly executiveProducer?: InputMaybe<Scalars['String']>;
  readonly fieldRestrictionSettings?: InputMaybe<ReadonlyArray<gqlProjectFieldRestrictionInput>>;
  readonly hidden?: InputMaybe<Scalars['Boolean']>;
  readonly hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  readonly internalName?: InputMaybe<Scalars['String']>;
  readonly isAuditionInPerson?: InputMaybe<Scalars['Boolean']>;
  readonly isSpecificLocation?: InputMaybe<Scalars['Boolean']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly network?: InputMaybe<Scalars['String']>;
  readonly newSeriesName?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly photographer?: InputMaybe<Scalars['String']>;
  readonly producer?: InputMaybe<Scalars['String']>;
  readonly productionCompany?: InputMaybe<Scalars['String']>;
  readonly projectId: Scalars['ID'];
  readonly projectOrganization?: InputMaybe<ReadonlyArray<gqlProjectOrganizationInput>>;
  readonly projectParentId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  readonly projectSeriesId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  readonly projectStatusCode?: InputMaybe<Scalars['String']>;
  readonly projectStatusId?: InputMaybe<Scalars['ID']>;
  readonly projectTypeId?: InputMaybe<Scalars['ID']>;
  readonly projectUnionId?: InputMaybe<Scalars['ID']>;
  readonly rate?: InputMaybe<Scalars['String']>;
  /** IPC project release location codes */
  readonly releaseLocations?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requestBrief?: InputMaybe<Scalars['Boolean']>;
  readonly requestBriefInstruction?: InputMaybe<Scalars['String']>;
  readonly roleDefaults?: InputMaybe<gqlRoleDefaultsInput>;
  readonly specificLocationInput?: InputMaybe<gqlUpdatePcProjectSpecificAddressInput>;
  readonly studio?: InputMaybe<Scalars['String']>;
  readonly submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  readonly synopsis?: InputMaybe<Scalars['String']>;
  readonly tileSchemeId?: InputMaybe<Scalars['ID']>;
  readonly unionContractId?: InputMaybe<Scalars['String']>;
  readonly usage?: InputMaybe<Scalars['String']>;
  readonly writer?: InputMaybe<Scalars['String']>;
};

/** Specific location Address Input for creating IPC project */
export type gqlUpdatePcProjectSpecificAddressInput = {
  /** Address Line 1 */
  readonly addressLine1?: InputMaybe<Scalars['ID']>;
  /** Address Line 2 */
  readonly addressLine2?: InputMaybe<Scalars['String']>;
  /** City */
  readonly city?: InputMaybe<Scalars['ID']>;
  /** Country CODE */
  readonly countryCode?: InputMaybe<Scalars['ID']>;
  /** Location notes */
  readonly locationNotes?: InputMaybe<Scalars['String']>;
  /** ZIP/Postal Code */
  readonly postalCode?: InputMaybe<Scalars['ID']>;
  /** State / Province Id */
  readonly stateId?: InputMaybe<Scalars['Int']>;
};

/** Update profile conflict input */
export type gqlUpdateProfileConflictInput = {
  readonly conflictId?: InputMaybe<Scalars['Int']>;
  readonly expiration?: InputMaybe<Scalars['DateTime']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly profileConflictId: Scalars['Int'];
};

/** Input type for move profile to artist */
export type gqlUpdateProfileMoveToArtistInput = {
  /** artist id */
  readonly artistId: Scalars['ID'];
  /** Profile id */
  readonly profileId: Scalars['ID'];
};

/** Input type for Update Profile Vehicle */
export type gqlUpdateProfileVehicleInput = {
  /** Vehicle name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Vehicle type make id */
  readonly vehicleTypeMakeId: Scalars['Float'];
  /** Vehicle year */
  readonly year?: InputMaybe<Scalars['Float']>;
};

/** Input type for Project update */
export type gqlUpdateProjectInput = {
  readonly adAgency?: InputMaybe<Scalars['String']>;
  readonly additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  readonly attachmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** Project sides visibility CODE */
  readonly attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  readonly auditionTypeId?: InputMaybe<Scalars['ID']>;
  readonly castingAssistant?: InputMaybe<Scalars['String']>;
  readonly castingAssociate?: InputMaybe<Scalars['String']>;
  readonly castingDirector?: InputMaybe<Scalars['String']>;
  readonly conflictIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly conflictNote?: InputMaybe<Scalars['String']>;
  readonly contactEmail?: InputMaybe<Scalars['String']>;
  readonly contactInfoPermissions?: InputMaybe<Scalars['Boolean']>;
  readonly contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  readonly contractStatusCode?: InputMaybe<Scalars['String']>;
  readonly director?: InputMaybe<Scalars['String']>;
  readonly executiveProducer?: InputMaybe<Scalars['String']>;
  readonly fieldRestrictionSettings?: InputMaybe<ReadonlyArray<gqlProjectFieldRestrictionInput>>;
  readonly hidden?: InputMaybe<Scalars['Boolean']>;
  readonly hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  readonly internalName?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly network?: InputMaybe<Scalars['String']>;
  readonly newSeriesName?: InputMaybe<Scalars['String']>;
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly photographer?: InputMaybe<Scalars['String']>;
  readonly producer?: InputMaybe<Scalars['String']>;
  readonly productionCompany?: InputMaybe<Scalars['String']>;
  readonly projectId: Scalars['ID'];
  readonly projectOrganization?: InputMaybe<ReadonlyArray<gqlProjectOrganizationInput>>;
  readonly projectParentId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  readonly projectSeriesId?: InputMaybe<Scalars['ID']>;
  readonly projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  readonly projectStatusCode?: InputMaybe<Scalars['String']>;
  readonly projectStatusId?: InputMaybe<Scalars['ID']>;
  readonly projectTypeId?: InputMaybe<Scalars['ID']>;
  readonly projectUnionId?: InputMaybe<Scalars['ID']>;
  readonly rate?: InputMaybe<Scalars['String']>;
  /** IPC project release location codes */
  readonly releaseLocations?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly requestBrief?: InputMaybe<Scalars['Boolean']>;
  readonly requestBriefInstruction?: InputMaybe<Scalars['String']>;
  readonly roleDefaults?: InputMaybe<gqlRoleDefaultsInput>;
  readonly studio?: InputMaybe<Scalars['String']>;
  readonly submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  readonly synopsis?: InputMaybe<Scalars['String']>;
  readonly tileSchemeId?: InputMaybe<Scalars['ID']>;
  readonly unionContractId?: InputMaybe<Scalars['String']>;
  readonly usage?: InputMaybe<Scalars['String']>;
  readonly writer?: InputMaybe<Scalars['String']>;
};

/** Update Representative List Input */
export type gqlUpdateRepresentativeListInput = {
  /** List Id */
  readonly listId: Scalars['Float'];
  /** List of Representative List Ids */
  readonly representativeListIds: ReadonlyArray<Scalars['BigInt']>;
};

/** Input type for role media */
export type gqlUpdateRoleMediaInput = {
  /** Is this media marked for deletion */
  readonly isDeleted: Scalars['Boolean'];
  /** Is this sides media */
  readonly isSides: Scalars['Boolean'];
  /** Media id for creating new media record. */
  readonly mediaId: Scalars['Float'];
  /** Name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Role-Media relation ID */
  readonly roleMediaId?: InputMaybe<Scalars['Float']>;
};

export type gqlUpdateSavedSearchInput = {
  readonly id: Scalars['ID'];
  readonly isDefault?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly notificationType?: InputMaybe<Scalars['String']>;
};

/** Update Schedule Input */
export type gqlUpdateScheduleInput = {
  /** Address Id */
  readonly addressId?: InputMaybe<Scalars['Int']>;
  /** Is location hidden */
  readonly isLocationHidden?: InputMaybe<Scalars['Boolean']>;
  /** Schedule Name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Note */
  readonly note?: InputMaybe<Scalars['String']>;
  /** Project Id */
  readonly projectId?: InputMaybe<Scalars['Int']>;
  /** Schedule Date */
  readonly scheduleDate?: InputMaybe<Scalars['String']>;
  /** Schedule Id */
  readonly scheduleId: Scalars['Int'];
  /** Schedule Type Code */
  readonly scheduleTypeCode?: InputMaybe<Scalars['String']>;
  /** Time Zone CODE */
  readonly timeZoneCode?: InputMaybe<Scalars['String']>;
};

/** Input type for updating submission status */
export type gqlUpdateSubmissionStatusInput = {
  /** ID of the creator */
  readonly createdBy?: InputMaybe<Scalars['Int']>;
  /** New submissionstatus to set */
  readonly newSubmissionStatusCode: Scalars['String'];
  /** Submission ID */
  readonly submissionId: Scalars['BigInt'];
};

/** Url Type object */
export type gqlUrlType = {
  readonly __typename?: 'UrlType';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlUrlTypeEnum {
  FACEBOOK = 'FACEBOOK',
  IMDB = 'IMDB',
  INSTAGRAM = 'INSTAGRAM',
  PAST_WORK = 'PAST_WORK',
  PORTFOLIO = 'PORTFOLIO',
  TWITTER = 'TWITTER',
  VIMEO = 'VIMEO',
  WEBSITE = 'WEBSITE',
  YOUTUBE = 'YOUTUBE'
}

/** Organization Object */
export type gqlUserOrganization = {
  readonly __typename?: 'UserOrganization';
  /** Address ID */
  readonly addressId?: Maybe<Scalars['Int']>;
  /** Representation age categories */
  readonly ageCategories?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** City */
  readonly city?: Maybe<Scalars['String']>;
  /** Company Address Line 1 */
  readonly companyAddressLine1?: Maybe<Scalars['String']>;
  /** Company Address Line 2 */
  readonly companyAddressLine2?: Maybe<Scalars['String']>;
  /** Company Name */
  readonly companyName?: Maybe<Scalars['String']>;
  /** Company Phone */
  readonly companyPhone?: Maybe<Scalars['String']>;
  /** Contact ID */
  readonly contactId?: Maybe<Scalars['Int']>;
  /** Country */
  readonly countryId?: Maybe<Scalars['Int']>;
  /** DMA id */
  readonly dmaId?: Maybe<Scalars['Int']>;
  /** Organization ID */
  readonly id?: Maybe<Scalars['Int']>;
  /** Organization Status */
  readonly organizationStatus?: Maybe<Scalars['String']>;
  /** Parent Organization ID */
  readonly parentOrganizationId?: Maybe<Scalars['Int']>;
  /** Primary Contacts Email */
  readonly primaryContactEmail?: Maybe<Scalars['String']>;
  /** Primary Contacts Name */
  readonly primaryContactName?: Maybe<Scalars['String']>;
  /** Primary Contacts Phone Number */
  readonly primaryContactPhoneNumber?: Maybe<Scalars['String']>;
  /** Primary working location */
  readonly primaryWorkingLocation?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** Publishing categories */
  readonly publishingCategories?: Maybe<ReadonlyArray<Scalars['Int']>>;
  /** State id */
  readonly stateId?: Maybe<Scalars['Int']>;
  /** Zip */
  readonly zip?: Maybe<Scalars['String']>;
};

export type gqlValidationError = gqlIError & {
  readonly __typename?: 'ValidationError';
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

export type gqlValidationErrorForm = gqlIError & {
  readonly __typename?: 'ValidationErrorForm';
  readonly code: Scalars['Int'];
  readonly message: Scalars['String'];
};

/** VehicleMake object */
export type gqlVehicleMake = {
  readonly __typename?: 'VehicleMake';
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for vehicle search */
export type gqlVehicleSearchInput = {
  /** Vehicle Make ID */
  readonly makeId?: InputMaybe<Scalars['Int']>;
  /** Vehicle name */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Vehicle type ID */
  readonly typeId?: InputMaybe<Scalars['Int']>;
  /** Year range of vehicle */
  readonly yearRange?: InputMaybe<gqlIdRangeInput>;
};

/** Vehicle Type object */
export type gqlVehicleType = {
  readonly __typename?: 'VehicleType';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlVehicleType>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlVehicleType>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Vehicle Type Make */
export type gqlVehicleTypeMake = {
  readonly __typename?: 'VehicleTypeMake';
  /** Vehicle type make id */
  readonly id: Scalars['Float'];
  /** Vehicle make */
  readonly make: gqlVehicleMake;
  /** Vehicle type */
  readonly type: gqlVehicleType;
};

export type gqlVerifyAccountByCookieInput = {
  /** legacy instance for onboarding */
  readonly instance: Scalars['String'];
  readonly secureCookieToken: Scalars['String'];
};

/** login with B21 or BAU credentials */
export type gqlVerifyAccountInput = {
  /** legacy instance for onboarding */
  readonly instance?: InputMaybe<Scalars['String']>;
  /** accitional account claiming for onboarding */
  readonly migratedAccountID?: InputMaybe<Scalars['Float']>;
  /** B21 email or legacy username */
  readonly name: Scalars['String'];
  /** B21 password or legacy password */
  readonly password: Scalars['String'];
};

/** Virtual Audition Attendee Details */
export type gqlVirtualAuditionAttendee = {
  readonly __typename?: 'VirtualAuditionAttendee';
  readonly accountId?: Maybe<Scalars['ID']>;
  readonly artistId?: Maybe<Scalars['ID']>;
  readonly joinUrl: Scalars['String'];
  readonly submissionId?: Maybe<Scalars['BigInt']>;
  readonly type: gqlVirtualAuditionAttendeeType;
};

export enum gqlVirtualAuditionAttendeeType {
  host = 'host',
  observer = 'observer',
  participant = 'participant'
}

export type gqlWaist = {
  readonly __typename?: 'Waist';
  /** Imperial Feet Inches */
  readonly IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  readonly IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  readonly METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
  /** Inches rounded */
  readonly inchRounded: Scalars['String'];
  /** Show in imperial UI */
  readonly showInImperialUI: Scalars['Boolean'];
};

/** Wardrobe object */
export type gqlWardrobe = {
  readonly __typename?: 'Wardrobe';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlWardrobe>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlWardrobe>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** General weight object */
export type gqlWeight = {
  readonly __typename?: 'Weight';
  /** Imperial pounds */
  readonly IMPERIAL_LBS: Scalars['Int'];
  /** Metric kg */
  readonly METRIC_KG: Scalars['Float'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly kg: Scalars['Float'];
  readonly kgRounded: Scalars['Float'];
  /** pounds */
  readonly lbs: Scalars['Int'];
  /** Show in imperial UI */
  readonly showInMetricUI: Scalars['Boolean'];
};

/** Context object for welcome email. */
export type gqlWelcomeContext = {
  readonly __typename?: 'WelcomeContext';
  readonly receiverEmail: Scalars['String'];
  readonly receiverName: Scalars['String'];
};

export type gqlWelcomeContextNotificationContextAndRecipient = {
  readonly __typename?: 'WelcomeContextNotificationContextAndRecipient';
  readonly context: gqlWelcomeContext;
  readonly to?: Maybe<Scalars['String']>;
  readonly toAccountId?: Maybe<Scalars['Int']>;
  readonly toOrgId?: Maybe<Scalars['Int']>;
  readonly toProfileId?: Maybe<Scalars['Int']>;
};

export type gqlWfAddress = {
  readonly __typename?: 'WfAddress';
  readonly city?: Maybe<Scalars['String']>;
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly countryName?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly line1?: Maybe<Scalars['String']>;
  readonly line2?: Maybe<Scalars['String']>;
  readonly line3?: Maybe<Scalars['String']>;
  readonly note?: Maybe<Scalars['String']>;
  /** Postal code */
  readonly postalCode: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
};

/** Base object */
export type gqlWfBasicSettingModel = {
  readonly __typename?: 'WfBasicSettingModel';
  /** code */
  readonly code: Scalars['String'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
};

export type gqlWfChatMessage = {
  readonly __typename?: 'WfChatMessage';
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly messageBody: Scalars['String'];
  readonly messageType: Scalars['String'];
  readonly ownMessage: Scalars['Boolean'];
  readonly senderAccount: gqlChatSenderAccount;
  readonly senderAccountId?: Maybe<Scalars['Int']>;
  readonly senderOrgId?: Maybe<Scalars['Int']>;
  readonly topicKey: Scalars['String'];
};

export type gqlWfChatTopic = {
  readonly __typename?: 'WfChatTopic';
  readonly lastUserMessage?: Maybe<gqlWfChatMessage>;
  readonly topicKey: Scalars['String'];
  readonly unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type gqlWfComplexSetting = {
  readonly __typename?: 'WfComplexSetting';
  readonly childObjects: ReadonlyArray<gqlWfComplexSetting>;
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly parentObject?: Maybe<Scalars['Int']>;
  readonly selectable: Scalars['Boolean'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfContact = {
  readonly __typename?: 'WfContact';
  readonly email?: Maybe<Scalars['String']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['Int'];
  readonly mainContact?: Maybe<Scalars['Boolean']>;
  readonly phone?: Maybe<Scalars['String']>;
};

export type gqlWfContinent = {
  readonly __typename?: 'WfContinent';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfContractStatus = {
  readonly __typename?: 'WfContractStatus';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfCountry = {
  readonly __typename?: 'WfCountry';
  readonly code: Scalars['String'];
  readonly continent: gqlWfContinent;
  readonly currency?: Maybe<gqlWfCurrency>;
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly regions?: Maybe<ReadonlyArray<gqlWfRegion>>;
  readonly sortOrder?: Maybe<Scalars['Int']>;
  readonly unitSystem: gqlWfUnitSystem;
};

/** Credit Attribute */
export type gqlWfCredit = {
  readonly __typename?: 'WfCredit';
  /** Created at Date */
  readonly created?: Maybe<Scalars['DateTime']>;
  /** Credit Sort ordering */
  readonly creditOrder: Scalars['Int'];
  /** The director of the Project */
  readonly director?: Maybe<Scalars['String']>;
  readonly guid: Scalars['String'];
  /** Highlighted */
  readonly highlighted: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Profile Credit Type Id */
  readonly profileCreditTypeId: Scalars['Int'];
  /** Profile ID */
  readonly profileId: Scalars['Int'];
  /** Role title */
  readonly role?: Maybe<Scalars['String']>;
  /** Credit Type Sort ordering */
  readonly sortOrder: Scalars['Int'];
  /** Title */
  readonly title?: Maybe<Scalars['String']>;
  /** Updated at Date */
  readonly updated: Scalars['DateTime'];
  /** Year */
  readonly year?: Maybe<Scalars['Int']>;
};

export type gqlWfCurrency = {
  readonly __typename?: 'WfCurrency';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
  readonly symbol: Scalars['String'];
};

export type gqlWfDma = {
  readonly __typename?: 'WfDma';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly region?: Maybe<gqlWfRegion>;
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfEntityType = {
  readonly __typename?: 'WfEntityType';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Measurement object */
export type gqlWfMeasurementModel = {
  readonly __typename?: 'WfMeasurementModel';
  /** Centimeters */
  readonly cm: Scalars['Int'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** Inches */
  readonly inch: Scalars['Float'];
};

export type gqlWfMedia = {
  readonly __typename?: 'WfMedia';
  readonly fileKey?: Maybe<Scalars['String']>;
  readonly fileType?: Maybe<gqlWfMediaFileType>;
  readonly filename: Scalars['String'];
  readonly guid: Scalars['String'];
  readonly isLocked?: Maybe<Scalars['Boolean']>;
  readonly mediaId: Scalars['Int'];
  readonly mediaStatus?: Maybe<gqlWfMediaStatus>;
  readonly mediaStorageStatus?: Maybe<gqlWfMediaStatus>;
  readonly mediaType?: Maybe<gqlWfMediaType>;
  readonly name?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly thumbnail?: Maybe<gqlWfMedia>;
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly transformation?: Maybe<gqlWfMediaTransformation>;
  readonly url?: Maybe<Scalars['String']>;
};

/** Media Count */
export type gqlWfMediaCount = {
  readonly __typename?: 'WfMediaCount';
  /** Count of the media */
  readonly count?: Maybe<Scalars['Int']>;
  /** Media Type */
  readonly type?: Maybe<gqlWfMediaType>;
};

export type gqlWfMediaFileType = {
  readonly __typename?: 'WfMediaFileType';
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly mediaType: gqlWfMediaType;
  readonly name: Scalars['String'];
};

export type gqlWfMediaStatus = {
  readonly __typename?: 'WfMediaStatus';
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type gqlWfMediaTransformation = {
  readonly __typename?: 'WfMediaTransformation';
  readonly height?: Maybe<Scalars['Float']>;
  readonly rotate?: Maybe<Scalars['Float']>;
  readonly width?: Maybe<Scalars['Float']>;
  readonly xAxis?: Maybe<Scalars['Float']>;
  readonly yAxis?: Maybe<Scalars['Float']>;
};

export type gqlWfMediaType = {
  readonly __typename?: 'WfMediaType';
  readonly code: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

/** Note object type */
export type gqlWfNote = {
  readonly __typename?: 'WfNote';
  /** Account id who created the note */
  readonly accountId: Scalars['Int'];
  /** Comment */
  readonly comment: Scalars['String'];
  /** Created Date */
  readonly created: Scalars['String'];
  /** Note Id */
  readonly id: Scalars['Int'];
  readonly noteModifier?: Maybe<gqlWfNoteModifier>;
};

export type gqlWfNoteModifier = {
  readonly __typename?: 'WfNoteModifier';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly entityType: gqlWfEntityType;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** Organization object */
export type gqlWfOrganization = {
  readonly __typename?: 'WfOrganization';
  /** Abbreviation */
  readonly abbreviation?: Maybe<Scalars['String']>;
  readonly contacts?: Maybe<ReadonlyArray<gqlWfContact>>;
  /** Primary ID */
  readonly id: Scalars['Int'];
  /** If false, it is a division. */
  readonly isOrganization?: Maybe<Scalars['Boolean']>;
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Parent Name (if division) */
  readonly parentName?: Maybe<Scalars['String']>;
  readonly parentOrganization?: Maybe<gqlWfOrganization>;
};

export type gqlWfProfile = {
  readonly __typename?: 'WfProfile';
  /** Identify the access granted by agency or not. */
  readonly accessGranted: Scalars['Boolean'];
  readonly artistId?: Maybe<Scalars['Float']>;
  readonly contactInfo?: Maybe<gqlWfProfileContactInfo>;
  /** Talent's cover */
  readonly cover?: Maybe<gqlWfMedia>;
  /**
   * Talent's cover
   * @deprecated Replaced with cover field
   */
  readonly coverUrl?: Maybe<Scalars['String']>;
  readonly credits?: Maybe<ReadonlyArray<gqlWfCredit>>;
  readonly creditsCount: Scalars['Int'];
  /** Date of birth */
  readonly dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** Talent's email */
  readonly email?: Maybe<Scalars['String']>;
  readonly guid?: Maybe<Scalars['String']>;
  /** HairColor object */
  readonly hairColor?: Maybe<gqlWfBasicSettingModel>;
  /** HairStyle object */
  readonly hairStyle?: Maybe<gqlWfBasicSettingModel>;
  readonly hasAuditionHistory?: Maybe<Scalars['Boolean']>;
  readonly hasResume?: Maybe<Scalars['Boolean']>;
  /** Height object */
  readonly height?: Maybe<gqlWfMeasurementModel>;
  readonly id: Scalars['Int'];
  /** If Profile is overscaled */
  readonly isOverscaled?: Maybe<Scalars['Boolean']>;
  /** Identify the personal or represented profile. */
  readonly isPersonal: Scalars['Boolean'];
  readonly isRepresented?: Maybe<Scalars['Boolean']>;
  readonly lastNote?: Maybe<gqlWfNote>;
  /** All profile media */
  readonly mediaAssets: ReadonlyArray<gqlWfProfileMedia>;
  readonly mediaSummary?: Maybe<ReadonlyArray<gqlWfMediaCount>>;
  /** Is minor */
  readonly minor?: Maybe<Scalars['Boolean']>;
  /**
   * Talent's name
   * @deprecated Deprecated. replaced with stageName field
   */
  readonly name?: Maybe<Scalars['String']>;
  /** profile note */
  readonly note?: Maybe<Scalars['String']>;
  /** all notes */
  readonly notes?: Maybe<ReadonlyArray<gqlWfNote>>;
  readonly organization?: Maybe<gqlWfOrganization>;
  readonly organizations: ReadonlyArray<gqlWfOrganization>;
  /** Overscale Note */
  readonly overscaleNote?: Maybe<Scalars['String']>;
  /** Talents phone */
  readonly phone?: Maybe<Scalars['String']>;
  /** The minimum age a talent can play */
  readonly playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  readonly playableAgeMin?: Maybe<Scalars['Float']>;
  /** Profession */
  readonly profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  readonly profileAccessToken?: Maybe<Scalars['String']>;
  readonly profileMainDivision?: Maybe<gqlWfOrganization>;
  readonly profileMainOrganization?: Maybe<gqlWfOrganization>;
  /** Profile Status Object */
  readonly profileStatus?: Maybe<gqlWfBasicSettingModel>;
  readonly resume?: Maybe<gqlWfProfileMedia>;
  /** Talent's stage name */
  readonly stageName: Scalars['String'];
  /** Talent unions */
  readonly talentUnions: ReadonlyArray<gqlWfTalentProfileUnion>;
  /** Weight object */
  readonly weight?: Maybe<gqlWfWeight>;
  /** Working Locations */
  readonly workingLocations: ReadonlyArray<gqlWfDma>;
};

/** Profile Media */
export type gqlWfProfileContactInfo = {
  readonly __typename?: 'WfProfileContactInfo';
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

/** Profile Media */
export type gqlWfProfileMedia = {
  readonly __typename?: 'WfProfileMedia';
  readonly media: gqlWfMedia;
  readonly profileMediaId: Scalars['Float'];
};

export type gqlWfProject = {
  readonly __typename?: 'WfProject';
  readonly castingCompany?: Maybe<Scalars['String']>;
  readonly createdByPC: Scalars['Boolean'];
  readonly displayedProjectUnion?: Maybe<Scalars['String']>;
  readonly displayedProjectUnionShort?: Maybe<Scalars['String']>;
  readonly guid?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly projectStatus: gqlWfBasicSettingModel;
  readonly projectType: gqlWfProjectType;
  readonly projectUnions?: Maybe<ReadonlyArray<gqlWfProjectUnionDetails>>;
  readonly synopsis?: Maybe<Scalars['String']>;
};

export type gqlWfProjectType = {
  readonly __typename?: 'WfProjectType';
  readonly childObjects: ReadonlyArray<gqlWfComplexSetting>;
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly parentObject?: Maybe<Scalars['Int']>;
  readonly selectable: Scalars['Boolean'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfProjectUnionDetails = {
  readonly __typename?: 'WfProjectUnionDetails';
  readonly contractId?: Maybe<Scalars['String']>;
  readonly contractStatus?: Maybe<gqlWfContractStatus>;
  readonly contractStatusId?: Maybe<Scalars['Int']>;
  readonly union: gqlWfTalentUnion;
};

export type gqlWfRatePerType = {
  readonly __typename?: 'WfRatePerType';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfRegion = {
  readonly __typename?: 'WfRegion';
  readonly code: Scalars['String'];
  readonly country?: Maybe<gqlWfCountry>;
  readonly dmas?: Maybe<ReadonlyArray<gqlWfDma>>;
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfRole = {
  readonly __typename?: 'WfRole';
  /** Ages of roles showing in years */
  readonly ageInYears: Scalars['Boolean'];
  readonly ageMax?: Maybe<Scalars['Int']>;
  readonly ageMin?: Maybe<Scalars['Int']>;
  /** Role description */
  readonly description?: Maybe<Scalars['String']>;
  readonly guid?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  /** Role internal name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Role description */
  readonly locationDates?: Maybe<ReadonlyArray<gqlWfRoleLocationDate>>;
  readonly name: Scalars['String'];
  readonly project?: Maybe<gqlWfProject>;
  readonly publicationSettingIds?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly rate?: Maybe<Scalars['Float']>;
  readonly rateMax?: Maybe<Scalars['Float']>;
  readonly ratePerType?: Maybe<gqlWfRatePerType>;
  /** Rate summary */
  readonly rateSummary?: Maybe<Scalars['String']>;
  readonly roleStatus: gqlWfBasicSettingModel;
  readonly roleType: gqlWfRoleType;
  readonly sides?: Maybe<ReadonlyArray<gqlWfRoleMedia>>;
  readonly sidesVisibility?: Maybe<gqlWfRoleSidesVisibility>;
  readonly synopsis?: Maybe<Scalars['String']>;
  readonly wardrobe?: Maybe<Scalars['String']>;
};

export type gqlWfRoleDateType = {
  readonly __typename?: 'WfRoleDateType';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfRoleLocationDate = {
  readonly __typename?: 'WfRoleLocationDate';
  readonly cityName?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['DateTime']>;
  /** RoleDateLocation Primary ID */
  readonly id: Scalars['ID'];
  readonly roleDateType?: Maybe<gqlWfRoleDateType>;
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

export type gqlWfRoleMedia = {
  readonly __typename?: 'WfRoleMedia';
  readonly isSides: Scalars['Boolean'];
  readonly mediaObject: gqlWfMedia;
  readonly name?: Maybe<Scalars['String']>;
  readonly roleMediaId: Scalars['Int'];
};

export type gqlWfRoleShort = {
  readonly __typename?: 'WfRoleShort';
  readonly id: Scalars['Int'];
  readonly internalName?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly parentId?: Maybe<Scalars['Int']>;
  readonly projectId: Scalars['Int'];
  readonly statusId: Scalars['Int'];
};

export type gqlWfRoleSidesVisibility = {
  readonly __typename?: 'WfRoleSidesVisibility';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfRoleTarget = {
  readonly __typename?: 'WfRoleTarget';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfRoleType = {
  readonly __typename?: 'WfRoleType';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly projectType: gqlWfProjectType;
  readonly roleTarget: gqlWfRoleTarget;
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export type gqlWfSchedule = {
  readonly __typename?: 'WfSchedule';
  readonly address?: Maybe<gqlWfAddress>;
  readonly date?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['Int'];
  readonly isLocationHidden: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly note?: Maybe<Scalars['String']>;
  /** Time Zone */
  readonly timeZone?: Maybe<gqlWfTimeZone>;
  readonly type: gqlScheduleRequestType;
  readonly virtualAudition?: Maybe<gqlWfScheduleVirtualAudition>;
};

export type gqlWfScheduleVirtualAudition = {
  readonly __typename?: 'WfScheduleVirtualAudition';
  readonly joinUrl?: Maybe<Scalars['String']>;
};

export type gqlWfSubmission = {
  readonly __typename?: 'WfSubmission';
  readonly contactInfo?: Maybe<gqlWfProfileContactInfo>;
  /** Talent's customized cover */
  readonly cover?: Maybe<gqlWfMedia>;
  readonly hasAudioReel?: Maybe<Scalars['Boolean']>;
  readonly hasVideoReel?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['Int'];
  readonly isCreatedByCD?: Maybe<Scalars['Boolean']>;
  readonly isCustomized: Scalars['Boolean'];
  readonly isOverscale?: Maybe<Scalars['Boolean']>;
  readonly lastNote?: Maybe<gqlWfNote>;
  readonly mediaCount?: Maybe<ReadonlyArray<gqlWfMediaCount>>;
  readonly note?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<ReadonlyArray<gqlWfNote>>;
  readonly overscaleNote?: Maybe<Scalars['String']>;
  /** Profile */
  readonly profile?: Maybe<gqlWfProfile>;
  /** Role */
  readonly role?: Maybe<gqlWfRole>;
  readonly tileSchemaValue?: Maybe<gqlWfBasicSettingModel>;
};


export type gqlWfSubmissionMediaCountArgs = {
  requested?: InputMaybe<Scalars['Boolean']>;
};

export type gqlWfSubmissionPagedList = {
  readonly __typename?: 'WfSubmissionPagedList';
  readonly data: ReadonlyArray<gqlWfSubmission>;
  readonly page: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type gqlWfSubmissionSearchInput = {
  readonly roleIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly stageName?: InputMaybe<Scalars['String']>;
  readonly tileSchemeValueCodes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** General weight object */
export type gqlWfTalentProfileUnion = {
  readonly __typename?: 'WfTalentProfileUnion';
  /** Code */
  readonly code: Scalars['String'];
  /** Primary ID */
  readonly id: Scalars['String'];
  /** Name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlWfTalentProfileUnion>;
};

export type gqlWfTalentUnion = {
  readonly __typename?: 'WfTalentUnion';
  readonly childObjects: ReadonlyArray<gqlWfTalentUnion>;
  readonly code: Scalars['String'];
  readonly country?: Maybe<gqlWfCountry>;
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly parentObject?: Maybe<gqlWfTalentUnion>;
  readonly projectEnabled: Scalars['Boolean'];
  readonly selectable: Scalars['Boolean'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
  readonly talentEnabled: Scalars['Boolean'];
};

/** Measurement object */
export type gqlWfTimeZone = {
  readonly __typename?: 'WfTimeZone';
  readonly abbreviation?: Maybe<Scalars['String']>;
  /** code */
  readonly code: Scalars['String'];
  readonly codeBrowser: Scalars['String'];
  readonly daylightSavingsCode?: Maybe<Scalars['String']>;
  readonly dstOffset: Scalars['Float'];
  readonly gmtOffset: Scalars['Float'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  readonly rawOffset: Scalars['Float'];
  readonly standardName: Scalars['String'];
};

export type gqlWfUnitSystem = {
  readonly __typename?: 'WfUnitSystem';
  readonly code: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

/** General weight object */
export type gqlWfWeight = {
  readonly __typename?: 'WfWeight';
  /** Primary ID */
  readonly id: Scalars['ID'];
  readonly kg: Scalars['Float'];
  /** pounds */
  readonly lbs: Scalars['Int'];
};

/** Willingness object */
export type gqlWillingness = {
  readonly __typename?: 'Willingness';
  /** child objects */
  readonly childObjects: ReadonlyArray<gqlWillingness>;
  /** code */
  readonly code: Scalars['String'];
  /** Is this enabled? */
  readonly enabled: Scalars['Boolean'];
  /** Primary ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** parent object */
  readonly parentObject?: Maybe<gqlWillingness>;
  /** Selectable Flag */
  readonly selectable: Scalars['Boolean'];
  /** Sort order */
  readonly sortOrder?: Maybe<Scalars['Int']>;
};

export enum gqlWillingnessEnum {
  ANIMALS = 'ANIMALS',
  ANIMALS_WORK_WITH_CATS = 'ANIMALS_WORK_WITH_CATS',
  ANIMALS_WORK_WITH_DOGS = 'ANIMALS_WORK_WITH_DOGS',
  EXTRAS_WORK = 'EXTRAS_WORK',
  OTHER = 'OTHER',
  OTHER_COLOR_HAIR = 'OTHER_COLOR_HAIR',
  OTHER_CUT_HAIR = 'OTHER_CUT_HAIR',
  OTHER_EAT_MEAT = 'OTHER_EAT_MEAT',
  OTHER_GROW_OR_SHAVE_FACIAL_HAIR = 'OTHER_GROW_OR_SHAVE_FACIAL_HAIR',
  OTHER_KISSING_SCENE = 'OTHER_KISSING_SCENE',
  OTHER_SAME_SEX_KISSING_SCENE = 'OTHER_SAME_SEX_KISSING_SCENE',
  OTHER_SEXUAL_SITUATIONS = 'OTHER_SEXUAL_SITUATIONS',
  OTHER_SMOKE = 'OTHER_SMOKE',
  REVEAL = 'REVEAL',
  REVEAL_APPEAR_IN_LINGERIE_OR_SWIMSUIT = 'REVEAL_APPEAR_IN_LINGERIE_OR_SWIMSUIT',
  REVEAL_CROSS_DRESS = 'REVEAL_CROSS_DRESS',
  REVEAL_NUDE = 'REVEAL_NUDE',
  REVEAL_SEMI_NUDE = 'REVEAL_SEMI_NUDE'
}

export type gqlAuditionGroupDetails = gqlAuditionsOrganization | gqlWfProfile | gqlWfProject;

export enum gqlProjectSidesVisibilityEnum {
  REPRESENTATIVES_AND_CB = 'REPRESENTATIVES_AND_CB',
  REPRESENTATIVES_ONLY = 'REPRESENTATIVES_ONLY'
}

export type SchemaEntities = {
  Account: gqlAccount;
  AccountActiveOnboardedPaypal: gqlAccountActiveOnboardedPaypal;
  AccountBillingInfo: gqlAccountBillingInfo;
  AccountContext: gqlAccountContext;
  AccountCreateInput: gqlAccountCreateInput;
  AccountItem: gqlAccountItem;
  AccountItemPagedList: gqlAccountItemPagedList;
  AccountOnboardingStatusEnum: gqlAccountOnboardingStatusEnum;
  AccountOrganizationItem: gqlAccountOrganizationItem;
  AccountPaypalLocationInput: gqlAccountPaypalLocationInput;
  AccountPreferences: gqlAccountPreferences;
  AccountProjectFiltersInput: gqlAccountProjectFiltersInput;
  AccountProjectFiltersPreferences: gqlAccountProjectFiltersPreferences;
  AccountRecoveryType: gqlAccountRecoveryType;
  AccountSchedulePreferences: gqlAccountSchedulePreferences;
  AccountSchedulePreferencesInput: gqlAccountSchedulePreferencesInput;
  AccountStatus: gqlAccountStatus;
  AccountStatusEnum: gqlAccountStatusEnum;
  AccountUrlType: gqlAccountUrlType;
  AccountsSystemRoleCodes: gqlAccountsSystemRoleCodes;
  ActiveSubscriptionModel: gqlActiveSubscriptionModel;
  ActivityLog: gqlActivityLog;
  ActivityLogAccount: gqlActivityLogAccount;
  ActivityLogAuditionGroup: gqlActivityLogAuditionGroup;
  ActivityLogAuditionSession: gqlActivityLogAuditionSession;
  ActivityLogCalendarSlot: gqlActivityLogCalendarSlot;
  ActivityLogNote: gqlActivityLogNote;
  ActivityLogOperation: gqlActivityLogOperation;
  ActivityLogOrganization: gqlActivityLogOrganization;
  ActivityLogPagedList: gqlActivityLogPagedList;
  ActivityLogPresentation: gqlActivityLogPresentation;
  ActivityLogPresentationFolder: gqlActivityLogPresentationFolder;
  ActivityLogPresentationGroup: gqlActivityLogPresentationGroup;
  ActivityLogProject: gqlActivityLogProject;
  ActivityLogRequest: gqlActivityLogRequest;
  ActivityLogRole: gqlActivityLogRole;
  ActivityLogSchedule: gqlActivityLogSchedule;
  ActivityLogSubmission: gqlActivityLogSubmission;
  ActivityLogType: gqlActivityLogType;
  AddArtistFreeCreditsInput: gqlAddArtistFreeCreditsInput;
  AddBillingInfoInput: gqlAddBillingInfoInput;
  AddCastingListProfileToRoleInput: gqlAddCastingListProfileToRoleInput;
  AddNoteInput: gqlAddNoteInput;
  AddNoteToCastingListProfileResult: gqlAddNoteToCastingListProfileResult;
  AddNoteToProfileError: gqlAddNoteToProfileError;
  AddOrDeleteListEntitiesInput: gqlAddOrDeleteListEntitiesInput;
  AddProfileError: gqlAddProfileError;
  AddProfileListInput: gqlAddProfileListInput;
  AddProfileNoteInput: gqlAddProfileNoteInput;
  AddProfileSubmissionsToCastingListInput: gqlAddProfileSubmissionsToCastingListInput;
  AddProfileToAuditionGroupAddInInput: gqlAddProfileToAuditionGroupAddInInput;
  AddProfileToAuditionGroupInput: gqlAddProfileToAuditionGroupInput;
  AddProfileToCastingListInput: gqlAddProfileToCastingListInput;
  AddProfileToCastingListResult: gqlAddProfileToCastingListResult;
  AddProfileToProjectInput: gqlAddProfileToProjectInput;
  AddProfileToRoleError: gqlAddProfileToRoleError;
  AddProfileToRoleResult: gqlAddProfileToRoleResult;
  AddProfileToTimeSlotAddInInput: gqlAddProfileToTimeSlotAddInInput;
  AddProfileToTimeSlotInput: gqlAddProfileToTimeSlotInput;
  AddRepresentativeListInput: gqlAddRepresentativeListInput;
  AddSubmissionsToTimeframeInput: gqlAddSubmissionsToTimeframeInput;
  AddTimeframeInput: gqlAddTimeframeInput;
  Address: gqlAddress;
  AddressInput: gqlAddressInput;
  AddressTypeEnum: gqlAddressTypeEnum;
  AgeRange: gqlAgeRange;
  AgeRangeInput: gqlAgeRangeInput;
  AgencyCallListDivisionGroup: gqlAgencyCallListDivisionGroup;
  AgencyCallListProjectInfo: gqlAgencyCallListProjectInfo;
  AgencyCallListReportCreateInput: gqlAgencyCallListReportCreateInput;
  AgencyCallListReportModel: gqlAgencyCallListReportModel;
  AgencyCallListReportPayloadModel: gqlAgencyCallListReportPayloadModel;
  AgencyCallListScheduleInfo: gqlAgencyCallListScheduleInfo;
  AgencyCallListSubmission: gqlAgencyCallListSubmission;
  AgentOrganizationInput: gqlAgentOrganizationInput;
  AllTalentSubmissionCountModel: gqlAllTalentSubmissionCountModel;
  AllTalentSubmissionModel: gqlAllTalentSubmissionModel;
  AllTalentSubmissionPayloadModel: gqlAllTalentSubmissionPayloadModel;
  AllTalentSubmissionProfileModel: gqlAllTalentSubmissionProfileModel;
  AllTalentSubmissionReportCreateInput: gqlAllTalentSubmissionReportCreateInput;
  ApprovePCProjectInput: gqlApprovePcProjectInput;
  ArchiveMediaInput: gqlArchiveMediaInput;
  ArchiveMediaResponse: gqlArchiveMediaResponse;
  Artist: gqlArtist;
  ArtistBillingFeatureModel: gqlArtistBillingFeatureModel;
  ArtistCreditsInput: gqlArtistCreditsInput;
  ArtistMedia: gqlArtistMedia;
  ArtistMediaEntitlement: gqlArtistMediaEntitlement;
  ArtistMediaProfile: gqlArtistMediaProfile;
  ArtistMediaUtilization: gqlArtistMediaUtilization;
  ArtistMediaUtilizationMediaType: gqlArtistMediaUtilizationMediaType;
  ArtistMediaUtilizationMediaTypeCount: gqlArtistMediaUtilizationMediaTypeCount;
  ArtistMediaUtilizationPurchaseStatusCount: gqlArtistMediaUtilizationPurchaseStatusCount;
  ArtistMediaUtilizationPurchaseType: gqlArtistMediaUtilizationPurchaseType;
  ArtistStorageUtilization: gqlArtistStorageUtilization;
  ArtistSubscriptionInput: gqlArtistSubscriptionInput;
  ArtistSubscriptionMediaEntitlement: gqlArtistSubscriptionMediaEntitlement;
  ArtistUpdateInput: gqlArtistUpdateInput;
  AskQuestionRequest: gqlAskQuestionRequest;
  AskQuestionRequestInput: gqlAskQuestionRequestInput;
  AskQuestionRequestStatus: gqlAskQuestionRequestStatus;
  AskQuestionResponse: gqlAskQuestionResponse;
  AskQuestionResponseInput: gqlAskQuestionResponseInput;
  AskQuestionResponsePagedList: gqlAskQuestionResponsePagedList;
  AskQuestionSubRequest: gqlAskQuestionSubRequest;
  AttachMediaToProfileInput: gqlAttachMediaToProfileInput;
  AttachMultipleMediaToProfileInput: gqlAttachMultipleMediaToProfileInput;
  AttachmentDetails: gqlAttachmentDetails;
  AuditionAddress: gqlAuditionAddress;
  AuditionGroup: gqlAuditionGroup;
  AuditionGroupBulkUpdateInput: gqlAuditionGroupBulkUpdateInput;
  AuditionGroupInput: gqlAuditionGroupInput;
  AuditionGroupMedia: gqlAuditionGroupMedia;
  AuditionGroupMediaInput: gqlAuditionGroupMediaInput;
  AuditionGroupMediaItemInput: gqlAuditionGroupMediaItemInput;
  AuditionGroupMediaOrderUpdateInput: gqlAuditionGroupMediaOrderUpdateInput;
  AuditionGroupPaginatedResult: gqlAuditionGroupPaginatedResult;
  AuditionGroupTalent: gqlAuditionGroupTalent;
  AuditionGrouping: gqlAuditionGrouping;
  AuditionHistory: gqlAuditionHistory;
  AuditionHistoryPaginatedResult: gqlAuditionHistoryPaginatedResult;
  AuditionHistorySessionGroupItem: gqlAuditionHistorySessionGroupItem;
  AuditionHistorySessionItem: gqlAuditionHistorySessionItem;
  AuditionPagedListSearch: gqlAuditionPagedListSearch;
  AuditionPresentationInput: gqlAuditionPresentationInput;
  AuditionSession: gqlAuditionSession;
  AuditionSessionCreateInput: gqlAuditionSessionCreateInput;
  AuditionSessionFilterInput: gqlAuditionSessionFilterInput;
  AuditionSessionPaginatedResult: gqlAuditionSessionPaginatedResult;
  AuditionSessionSortInstructionInput: gqlAuditionSessionSortInstructionInput;
  AuditionSessionUpdateInput: gqlAuditionSessionUpdateInput;
  AuditionSource: gqlAuditionSource;
  AuditionSubmissionSnapshotInput: gqlAuditionSubmissionSnapshotInput;
  AuditionTalentPool: gqlAuditionTalentPool;
  AuditionType: gqlAuditionType;
  AuditionsFilterInput: gqlAuditionsFilterInput;
  AuditionsGroupBy: gqlAuditionsGroupBy;
  AuditionsGroups: gqlAuditionsGroups;
  AuditionsOrganization: gqlAuditionsOrganization;
  AuditionsSearchSortOptionsInput: gqlAuditionsSearchSortOptionsInput;
  AuditionsSortColumn: gqlAuditionsSortColumn;
  AuthToken: gqlAuthToken;
  AuthTokenItem: gqlAuthTokenItem;
  BaseEmailContext: gqlBaseEmailContext;
  BaseEmailContextNotificationContextAndRecipient: gqlBaseEmailContextNotificationContextAndRecipient;
  BasicSetting: gqlBasicSetting;
  BauAccount: gqlBauAccount;
  BauAccountInput: gqlBauAccountInput;
  BauArtist: gqlBauArtist;
  BauArtistInput: gqlBauArtistInput;
  BauCountry: gqlBauCountry;
  BauHeight: gqlBauHeight;
  BauLoginByLegacyDataInput: gqlBauLoginByLegacyDataInput;
  BauLoginInput: gqlBauLoginInput;
  BauMedia: gqlBauMedia;
  BauPCAccountUpdateInput: gqlBauPcAccountUpdateInput;
  BauPersonalProfileInput: gqlBauPersonalProfileInput;
  BauProfile: gqlBauProfile;
  BauProfileInput: gqlBauProfileInput;
  BauProfileUpdateInput: gqlBauProfileUpdateInput;
  BauTalentUnion: gqlBauTalentUnion;
  BauWeight: gqlBauWeight;
  BillingCode: gqlBillingCode;
  BillingInfo: gqlBillingInfo;
  BillingItemCode: gqlBillingItemCode;
  BillingItemModel: gqlBillingItemModel;
  BillingPlan: gqlBillingPlan;
  BillingPlanTerm: gqlBillingPlanTerm;
  BillingPlansInput: gqlBillingPlansInput;
  BlockedKeyword: gqlBlockedKeyword;
  BlockedKeywordPaginatedResult: gqlBlockedKeywordPaginatedResult;
  BookingCallListDivisionSubmissionsModel: gqlBookingCallListDivisionSubmissionsModel;
  BookingCallListPersonalProfilesModel: gqlBookingCallListPersonalProfilesModel;
  BookingCallListReportPayloadModel: gqlBookingCallListReportPayloadModel;
  BookingCallListsReportModel: gqlBookingCallListsReportModel;
  Bust: gqlBust;
  CDWorksheetFilterInput: gqlCdWorksheetFilterInput;
  CalendarType: gqlCalendarType;
  CalendarTypeEnum: gqlCalendarTypeEnum;
  CancelBenefits: gqlCancelBenefits;
  CancelBenefitsInput: gqlCancelBenefitsInput;
  CancelRequestInput: gqlCancelRequestInput;
  CancelScheduleInput: gqlCancelScheduleInput;
  CastingBillboardSearchInput: gqlCastingBillboardSearchInput;
  CastingListFilterInput: gqlCastingListFilterInput;
  CastingListProfile: gqlCastingListProfile;
  CastingListProfileMedia: gqlCastingListProfileMedia;
  CastingListProfilePagedList: gqlCastingListProfilePagedList;
  CdMediaResponseCounts: gqlCdMediaResponseCounts;
  ChangeAuditionGroupTalentOrderInput: gqlChangeAuditionGroupTalentOrderInput;
  ChangeAuditionSessionTalentRoleNameInput: gqlChangeAuditionSessionTalentRoleNameInput;
  ChatMessageCreateInput: gqlChatMessageCreateInput;
  ChatMessageModel: gqlChatMessageModel;
  ChatMessageType: gqlChatMessageType;
  ChatSenderAccount: gqlChatSenderAccount;
  ChatTopic: gqlChatTopic;
  ChestSize: gqlChestSize;
  ClothesSize: gqlClothesSize;
  Coat: gqlCoat;
  CollaboratorAccountCreateInput: gqlCollaboratorAccountCreateInput;
  CollaboratorDetails: gqlCollaboratorDetails;
  CollaboratorInviteCreateInput: gqlCollaboratorInviteCreateInput;
  CollaboratorInviteFromOrgDetails: gqlCollaboratorInviteFromOrgDetails;
  CollaboratorInviteProjectDetails: gqlCollaboratorInviteProjectDetails;
  CollaboratorInviteStatusCode: gqlCollaboratorInviteStatusCode;
  CollaboratorRevokeAccessFromOrgInput: gqlCollaboratorRevokeAccessFromOrgInput;
  CollaboratorRevokeAccessFromProjectInput: gqlCollaboratorRevokeAccessFromProjectInput;
  CollaboratorsInOrganization: gqlCollaboratorsInOrganization;
  CollaboratorsInOrganizationPaginatedResult: gqlCollaboratorsInOrganizationPaginatedResult;
  CollaboratorsInvite: gqlCollaboratorsInvite;
  CollaboratorsInvitePaginatedResult: gqlCollaboratorsInvitePaginatedResult;
  CompanyInfoModel: gqlCompanyInfoModel;
  Compensation: gqlCompensation;
  CompensationEnum: gqlCompensationEnum;
  CompleteArtistMediaCreationInput: gqlCompleteArtistMediaCreationInput;
  CompleteMediaCreationInputItem: gqlCompleteMediaCreationInputItem;
  Conflict: gqlConflict;
  ConflictEnum: gqlConflictEnum;
  Contact: gqlContact;
  ContactInput: gqlContactInput;
  ContactSubTalentInput: gqlContactSubTalentInput;
  ContextRole: gqlContextRole;
  Continent: gqlContinent;
  ContractStatus: gqlContractStatus;
  ContractStatusEnum: gqlContractStatusEnum;
  CopyAllProfileToCastingListInput: gqlCopyAllProfileToCastingListInput;
  CopyPresentationInput: gqlCopyPresentationInput;
  CopyProfileToCastingListError: gqlCopyProfileToCastingListError;
  CopyProfileToCastingListInput: gqlCopyProfileToCastingListInput;
  CopyProfileToCastingListResult: gqlCopyProfileToCastingListResult;
  Country: gqlCountry;
  CountryEnum: gqlCountryEnum;
  CountryItem: gqlCountryItem;
  Coupon: gqlCoupon;
  CreateAccountWithGoogleInput: gqlCreateAccountWithGoogleInput;
  CreateArtistMediaInput: gqlCreateArtistMediaInput;
  CreateArtistMediaResponse: gqlCreateArtistMediaResponse;
  CreateCastingListResult: gqlCreateCastingListResult;
  CreateCompedSubscriptionInput: gqlCreateCompedSubscriptionInput;
  CreateCouponRedemptionInput: gqlCreateCouponRedemptionInput;
  CreateFormResult: gqlCreateFormResult;
  CreateMediaInputItem: gqlCreateMediaInputItem;
  CreateOrganizationInput: gqlCreateOrganizationInput;
  CreatePCProjectInput: gqlCreatePcProjectInput;
  CreateProfileConflictInput: gqlCreateProfileConflictInput;
  CreateProfileSubmissionInput: gqlCreateProfileSubmissionInput;
  CreateProfileSubmissionOrGetInput: gqlCreateProfileSubmissionOrGetInput;
  CreateProjectInput: gqlCreateProjectInput;
  CreateProjectSeriesInput: gqlCreateProjectSeriesInput;
  CreateSavedSearchInput: gqlCreateSavedSearchInput;
  CreateScheduleInput: gqlCreateScheduleInput;
  CreateSubscriptionInput: gqlCreateSubscriptionInput;
  CreateTalentScoutHistoryInput: gqlCreateTalentScoutHistoryInput;
  Credit: gqlCredit;
  CreditCardTypeEnum: gqlCreditCardTypeEnum;
  CreditInput: gqlCreditInput;
  CreditOrderInput: gqlCreditOrderInput;
  CreditType: gqlCreditType;
  CreditUpdateInput: gqlCreditUpdateInput;
  CupSize: gqlCupSize;
  Currency: gqlCurrency;
  CurrencyEnum: gqlCurrencyEnum;
  CustomMediaInput: gqlCustomMediaInput;
  CustomProfileInput: gqlCustomProfileInput;
  CustomSlug: gqlCustomSlug;
  CustomSlugBlockedReasonEnum: gqlCustomSlugBlockedReasonEnum;
  CustomSlugExpiredReasonEnum: gqlCustomSlugExpiredReasonEnum;
  CustomUrlSlugExpiredReason: gqlCustomUrlSlugExpiredReason;
  CustomizeTalentInFolderInput: gqlCustomizeTalentInFolderInput;
  DateRangeInput: gqlDateRangeInput;
  DefaultFitForYouArtist: gqlDefaultFitForYouArtist;
  DeleteCastingListResult: gqlDeleteCastingListResult;
  DeleteMediaInput: gqlDeleteMediaInput;
  DeleteMediaResponse: gqlDeleteMediaResponse;
  DeleteMediaV2Input: gqlDeleteMediaV2Input;
  DeleteMediaV2Response: gqlDeleteMediaV2Response;
  DeletePresentationProfilePickPublickInput: gqlDeletePresentationProfilePickPublickInput;
  DeleteProjectSeriesInput: gqlDeleteProjectSeriesInput;
  DeleteSubmissionFromSlotInput: gqlDeleteSubmissionFromSlotInput;
  DeleteSubmissionsFromSlotInput: gqlDeleteSubmissionsFromSlotInput;
  DeleteTimeframeInput: gqlDeleteTimeframeInput;
  DeviceTokenType: gqlDeviceTokenType;
  DisplayOnlyTalentWithPicksEnum: gqlDisplayOnlyTalentWithPicksEnum;
  DisplayRoleOptionsEnum: gqlDisplayRoleOptionsEnum;
  DisplayTalentInfoOptionsEnum: gqlDisplayTalentInfoOptionsEnum;
  DisplayTalentPerPageOptionsEnum: gqlDisplayTalentPerPageOptionsEnum;
  DisplayTalentPhotoOptionsEnum: gqlDisplayTalentPhotoOptionsEnum;
  Dma: gqlDma;
  DmaEnum: gqlDmaEnum;
  DmaItem: gqlDmaItem;
  Document: gqlDocument;
  EditCastingListResult: gqlEditCastingListResult;
  EditListError: gqlEditListError;
  EditProfileCastingListResult: gqlEditProfileCastingListResult;
  EditProfileError: gqlEditProfileError;
  EditProfileInput: gqlEditProfileInput;
  EditProjectSeriesInput: gqlEditProjectSeriesInput;
  EmailHistory: gqlEmailHistory;
  EmailHistoryPaginatedResult: gqlEmailHistoryPaginatedResult;
  EmailHistoryTypeEnum: gqlEmailHistoryTypeEnum;
  EndUserAccountInput: gqlEndUserAccountInput;
  EnrollBenefits: gqlEnrollBenefits;
  EnrollBenefitsInput: gqlEnrollBenefitsInput;
  EntitySubscriptionInput: gqlEntitySubscriptionInput;
  EntityType: gqlEntityType;
  EntityTypeEnum: gqlEntityTypeEnum;
  EthnicAppearance: gqlEthnicAppearance;
  EthnicAppearanceEnum: gqlEthnicAppearanceEnum;
  ExpireCompedSubscriptionInput: gqlExpireCompedSubscriptionInput;
  ExternalAuthenticationProvider: gqlExternalAuthenticationProvider;
  EyeColor: gqlEyeColor;
  FFYContextInput: gqlFfyContextInput;
  Facet: gqlFacet;
  FacetBucket: gqlFacetBucket;
  FacialHair: gqlFacialHair;
  Feature: gqlFeature;
  FeatureEnum: gqlFeatureEnum;
  FeatureInput: gqlFeatureInput;
  FilterInput: gqlFilterInput;
  FilterOperator: gqlFilterOperator;
  FinalizeMediaUploadInput: gqlFinalizeMediaUploadInput;
  FinalizeMediaUploadResponse: gqlFinalizeMediaUploadResponse;
  FinalizeUploadMediaInput: gqlFinalizeUploadMediaInput;
  FormAssociatedProject: gqlFormAssociatedProject;
  FormBulkResponseFormResponse: gqlFormBulkResponseFormResponse;
  FormBulkResponseProfileDetails: gqlFormBulkResponseProfileDetails;
  FormBulkResponseRepDetails: gqlFormBulkResponseRepDetails;
  FormDetails: gqlFormDetails;
  FormGqlModel: gqlFormGqlModel;
  FormGqlModelPagedList: gqlFormGqlModelPagedList;
  FormInfo: gqlFormInfo;
  FormResponseGqlModel: gqlFormResponseGqlModel;
  FormSortBy: gqlFormSortBy;
  FormSortConditionInput: gqlFormSortConditionInput;
  FormSortOrder: gqlFormSortOrder;
  FormsFilterArgs: gqlFormsFilterArgs;
  FormsReportModel: gqlFormsReportModel;
  FormsReportPayload: gqlFormsReportPayload;
  FreeCreditItemModel: gqlFreeCreditItemModel;
  GQLFormBulkResponseFormResponse: gqlGqlFormBulkResponseFormResponse;
  GQLFormBulkResponseProfileDetails: gqlGqlFormBulkResponseProfileDetails;
  GQLFormBulkResponseRepDetails: gqlGqlFormBulkResponseRepDetails;
  GQLFormBulkResponseRoleDetails: gqlGqlFormBulkResponseRoleDetails;
  GQLRpcFormBulkResponse: gqlGqlRpcFormBulkResponse;
  GQLRpcFormBulkResponseItem: gqlGqlRpcFormBulkResponseItem;
  GenderAppearance: gqlGenderAppearance;
  GenderAppearanceEnum: gqlGenderAppearanceEnum;
  GenderIdentity: gqlGenderIdentity;
  GenderIdentityEnum: gqlGenderIdentityEnum;
  GenderInput: gqlGenderInput;
  GeneralSize: gqlGeneralSize;
  GetBillingInfoInput: gqlGetBillingInfoInput;
  GetCastingListProfileArgs: gqlGetCastingListProfileArgs;
  GetCastingListProfileResult: gqlGetCastingListProfileResult;
  GetCastingListProfileResultError: gqlGetCastingListProfileResultError;
  GetChatMessagesByTopicModel: gqlGetChatMessagesByTopicModel;
  GetCollaboratorByEmailInput: gqlGetCollaboratorByEmailInput;
  GetCollaboratorInviteInput: gqlGetCollaboratorInviteInput;
  GetOrCreateVirtualAuditionAttendeeInput: gqlGetOrCreateVirtualAuditionAttendeeInput;
  GetOrganizationRepresentativeListsInput: gqlGetOrganizationRepresentativeListsInput;
  GetProjectSeriesByIdInput: gqlGetProjectSeriesByIdInput;
  GetProjectSeriesByNameInput: gqlGetProjectSeriesByNameInput;
  GetSubmissionByRoleProfileInput: gqlGetSubmissionByRoleProfileInput;
  Glove: gqlGlove;
  GroupLastKey: gqlGroupLastKey;
  GroupLastKeyInput: gqlGroupLastKeyInput;
  HairColor: gqlHairColor;
  HairStyle: gqlHairStyle;
  HasActiveEnrolledBenefit: gqlHasActiveEnrolledBenefit;
  HasActiveEnrolledBenefitInput: gqlHasActiveEnrolledBenefitInput;
  HatSize: gqlHatSize;
  Height: gqlHeight;
  Hip: gqlHip;
  ICreateMediaResponse: gqlICreateMediaResponse;
  IError: gqlIError;
  IPCInfo: gqlIpcInfo;
  IdRangeInput: gqlIdRangeInput;
  InitiateMediaBulkDownloadInput: gqlInitiateMediaBulkDownloadInput;
  InitiateMediaBulkDownloadResponse: gqlInitiateMediaBulkDownloadResponse;
  InitiateMediaUploadInput: gqlInitiateMediaUploadInput;
  InitiateMediaUploadResponse: gqlInitiateMediaUploadResponse;
  InitiateUploadMediaInput: gqlInitiateUploadMediaInput;
  Inseam: gqlInseam;
  InternalAccountCreateInput: gqlInternalAccountCreateInput;
  InternalWelcomeContext: gqlInternalWelcomeContext;
  InternalWelcomeContextNotificationContextAndRecipient: gqlInternalWelcomeContextNotificationContextAndRecipient;
  IntervalEnum: gqlIntervalEnum;
  ItemEnum: gqlItemEnum;
  KeyType: gqlKeyType;
  LanguageEnum: gqlLanguageEnum;
  LanguageInfo: gqlLanguageInfo;
  LegacyAccount: gqlLegacyAccount;
  ListGqlModel: gqlListGqlModel;
  ListGqlModelPagedList: gqlListGqlModelPagedList;
  ListSortOrderEnum: gqlListSortOrderEnum;
  ListType: gqlListType;
  LuminateCompanyModel: gqlLuminateCompanyModel;
  LuminateContactModel: gqlLuminateContactModel;
  LuminateProfile: gqlLuminateProfile;
  Media: gqlMedia;
  MediaByType: gqlMediaByType;
  MediaChangeInput: gqlMediaChangeInput;
  MediaCount: gqlMediaCount;
  MediaDisplaySize: gqlMediaDisplaySize;
  MediaFileType: gqlMediaFileType;
  MediaFileTypeExtension: gqlMediaFileTypeExtension;
  MediaInitUploadInput: gqlMediaInitUploadInput;
  MediaInput: gqlMediaInput;
  MediaInvoice: gqlMediaInvoice;
  MediaItem: gqlMediaItem;
  MediaMediaType: gqlMediaMediaType;
  MediaMetadataInput: gqlMediaMetadataInput;
  MediaModerationStatus: gqlMediaModerationStatus;
  MediaModerationStatusEnum: gqlMediaModerationStatusEnum;
  MediaPurchase: gqlMediaPurchase;
  MediaPurchaseStatus: gqlMediaPurchaseStatus;
  MediaPurpose: gqlMediaPurpose;
  MediaRequest: gqlMediaRequest;
  MediaRequestFilterType: gqlMediaRequestFilterType;
  MediaRequestInput: gqlMediaRequestInput;
  MediaRequestStatus: gqlMediaRequestStatus;
  MediaResponse: gqlMediaResponse;
  MediaResponseCounts: gqlMediaResponseCounts;
  MediaResponseFormDetails: gqlMediaResponseFormDetails;
  MediaResponseInput: gqlMediaResponseInput;
  MediaResponseItem: gqlMediaResponseItem;
  MediaResponsePagedList: gqlMediaResponsePagedList;
  MediaResponseStatus: gqlMediaResponseStatus;
  MediaStatus: gqlMediaStatus;
  MediaStorage: gqlMediaStorage;
  MediaStorageEnum: gqlMediaStorageEnum;
  MediaStorageStatus: gqlMediaStorageStatus;
  MediaStorageStatusEnum: gqlMediaStorageStatusEnum;
  MediaSubRequest: gqlMediaSubRequest;
  MediaToArchive: gqlMediaToArchive;
  MediaTransformation: gqlMediaTransformation;
  MediaTransformationInput: gqlMediaTransformationInput;
  MediaType: gqlMediaType;
  MediaTypeCodeEnum: gqlMediaTypeCodeEnum;
  MediaTypeEnum: gqlMediaTypeEnum;
  MediaVersionType: gqlMediaVersionType;
  MicroServices: gqlMicroServices;
  MissingDataError: gqlMissingDataError;
  MoveAllProfileToCastingListInput: gqlMoveAllProfileToCastingListInput;
  MoveProfileToCastingListError: gqlMoveProfileToCastingListError;
  MoveProfileToCastingListInput: gqlMoveProfileToCastingListInput;
  MoveProfileToCastingListResult: gqlMoveProfileToCastingListResult;
  MoveSubmissionsFromSlotsInput: gqlMoveSubmissionsFromSlotsInput;
  MultipleBirth: gqlMultipleBirth;
  MutateFormError: gqlMutateFormError;
  MutateListError: gqlMutateListError;
  Neck: gqlNeck;
  NotFoundError: gqlNotFoundError;
  Note: gqlNote;
  NoteModifier: gqlNoteModifier;
  NoteModifierEnum: gqlNoteModifierEnum;
  NotePaginatedResult: gqlNotePaginatedResult;
  NoteType: gqlNoteType;
  NoteTypeEnum: gqlNoteTypeEnum;
  Notification: gqlNotification;
  NotificationContext: gqlNotificationContext;
  NotificationEntityDetails: gqlNotificationEntityDetails;
  NotificationEntitySubscription: gqlNotificationEntitySubscription;
  NotificationNature: gqlNotificationNature;
  NotificationNatureEnum: gqlNotificationNatureEnum;
  NotificationOrderByInput: gqlNotificationOrderByInput;
  NotificationPagedList: gqlNotificationPagedList;
  NotificationSubscriptionInput: gqlNotificationSubscriptionInput;
  NotificationType: gqlNotificationType;
  NotificationTypeEnum: gqlNotificationTypeEnum;
  NotificationTypeNotificationSubscription: gqlNotificationTypeNotificationSubscription;
  NotificationTypeSubscriptionInput: gqlNotificationTypeSubscriptionInput;
  OnboardingStatus: gqlOnboardingStatus;
  OrderedItemInput: gqlOrderedItemInput;
  Organization: gqlOrganization;
  OrganizationAccount: gqlOrganizationAccount;
  OrganizationAccountInput: gqlOrganizationAccountInput;
  OrganizationDictionaryItem: gqlOrganizationDictionaryItem;
  OrganizationDictionaryItemPagedList: gqlOrganizationDictionaryItemPagedList;
  OrganizationFeatureInput: gqlOrganizationFeatureInput;
  OrganizationFilter: gqlOrganizationFilter;
  OrganizationInfo: gqlOrganizationInfo;
  OrganizationInput: gqlOrganizationInput;
  OrganizationItem: gqlOrganizationItem;
  OrganizationItemPagedList: gqlOrganizationItemPagedList;
  OrganizationLogo: gqlOrganizationLogo;
  OrganizationProjectFilterInput: gqlOrganizationProjectFilterInput;
  OrganizationProjectGroup: gqlOrganizationProjectGroup;
  OrganizationProjectGroupDetails: gqlOrganizationProjectGroupDetails;
  OrganizationProjectGroupPaginatedResult: gqlOrganizationProjectGroupPaginatedResult;
  OrganizationSearchInput: gqlOrganizationSearchInput;
  OrganizationSearchResult: gqlOrganizationSearchResult;
  OrganizationStatus: gqlOrganizationStatus;
  OrganizationStatusEnum: gqlOrganizationStatusEnum;
  OrganizationsByRepTypeAndNameInput: gqlOrganizationsByRepTypeAndNameInput;
  OrganizationsByRepTypeAndNameResult: gqlOrganizationsByRepTypeAndNameResult;
  OrganizationsByRepTypeAndNameResultPaginatedResult: gqlOrganizationsByRepTypeAndNameResultPaginatedResult;
  OrganizationsByRoleSubmissionsAndName: gqlOrganizationsByRoleSubmissionsAndName;
  OrganizationsByRoleSubmissionsAndNamePaginatedResult: gqlOrganizationsByRoleSubmissionsAndNamePaginatedResult;
  PCAccountUpdateInput: gqlPcAccountUpdateInput;
  PCProjectDeterminedAddressInput: gqlPcProjectDeterminedAddressInput;
  PCProjectSpecificAddressInput: gqlPcProjectSpecificAddressInput;
  PageInput: gqlPageInput;
  PaginatedProhibitedCustomSlug: gqlPaginatedProhibitedCustomSlug;
  PaginatedSearchArtistMediaResult: gqlPaginatedSearchArtistMediaResult;
  PaidType: gqlPaidType;
  PaidTypeEnum: gqlPaidTypeEnum;
  Pants: gqlPants;
  PartialBauAccountDetails: gqlPartialBauAccountDetails;
  PartialBauAccountDetailsInput: gqlPartialBauAccountDetailsInput;
  PayPreferencesEnum: gqlPayPreferencesEnum;
  PaymentDetails: gqlPaymentDetails;
  PaypalLocation: gqlPaypalLocation;
  PermissionsModel: gqlPermissionsModel;
  Pet: gqlPet;
  PetEnum: gqlPetEnum;
  PetSize: gqlPetSize;
  Phone: gqlPhone;
  Piercing: gqlPiercing;
  PiercingInput: gqlPiercingInput;
  PlanFeatureModel: gqlPlanFeatureModel;
  PlanModel: gqlPlanModel;
  PlanTermEnum: gqlPlanTermEnum;
  PlanTermModel: gqlPlanTermModel;
  PreCancelInput: gqlPreCancelInput;
  Presentation: gqlPresentation;
  PresentationFilterData: gqlPresentationFilterData;
  PresentationFilterFolder: gqlPresentationFilterFolder;
  PresentationFilterInput: gqlPresentationFilterInput;
  PresentationFilterRole: gqlPresentationFilterRole;
  PresentationFolder: gqlPresentationFolder;
  PresentationFolderInput: gqlPresentationFolderInput;
  PresentationFolderPublic: gqlPresentationFolderPublic;
  PresentationFolderTalent: gqlPresentationFolderTalent;
  PresentationFolderTalentCustomization: gqlPresentationFolderTalentCustomization;
  PresentationFolderTalentCustomizedMediaInput: gqlPresentationFolderTalentCustomizedMediaInput;
  PresentationFolderTalentMediaCustomization: gqlPresentationFolderTalentMediaCustomization;
  PresentationFolderTalentPresence: gqlPresentationFolderTalentPresence;
  PresentationFolderUpdateInput: gqlPresentationFolderUpdateInput;
  PresentationGroup: gqlPresentationGroup;
  PresentationGroupMedia: gqlPresentationGroupMedia;
  PresentationGroupPublic: gqlPresentationGroupPublic;
  PresentationGroupSubmission: gqlPresentationGroupSubmission;
  PresentationGroupSubmissionPaginatedResult: gqlPresentationGroupSubmissionPaginatedResult;
  PresentationGroupUpdateInput: gqlPresentationGroupUpdateInput;
  PresentationLinkEmailInput: gqlPresentationLinkEmailInput;
  PresentationLogFolderModel: gqlPresentationLogFolderModel;
  PresentationLogGroupModel: gqlPresentationLogGroupModel;
  PresentationLogPresentationModel: gqlPresentationLogPresentationModel;
  PresentationLogReportModel: gqlPresentationLogReportModel;
  PresentationLogReportPayloadModel: gqlPresentationLogReportPayloadModel;
  PresentationMediaDownloadInput: gqlPresentationMediaDownloadInput;
  PresentationOrganizationPublic: gqlPresentationOrganizationPublic;
  PresentationPickPresentationModel: gqlPresentationPickPresentationModel;
  PresentationPickReportModel: gqlPresentationPickReportModel;
  PresentationPickReportPayloadModel: gqlPresentationPickReportPayloadModel;
  PresentationPickRoleModel: gqlPresentationPickRoleModel;
  PresentationProjectPublic: gqlPresentationProjectPublic;
  PresentationPublic: gqlPresentationPublic;
  PresentationReportCreateInput: gqlPresentationReportCreateInput;
  PresentationReportCreatePublicInput: gqlPresentationReportCreatePublicInput;
  PresentationReportDisplayOptionsModel: gqlPresentationReportDisplayOptionsModel;
  PresentationReportTalentModel: gqlPresentationReportTalentModel;
  PresentationReportTalentNoteCommentModel: gqlPresentationReportTalentNoteCommentModel;
  PresentationReportTalentNoteModel: gqlPresentationReportTalentNoteModel;
  PresentationSettings: gqlPresentationSettings;
  PresentationSettingsInput: gqlPresentationSettingsInput;
  PresentationSizeSheetsMetricsModel: gqlPresentationSizeSheetsMetricsModel;
  PresentationSizeSheetsProfileSizeModel: gqlPresentationSizeSheetsProfileSizeModel;
  PresentationSizeSheetsReportModel: gqlPresentationSizeSheetsReportModel;
  PresentationSizeSheetsSizeMetricsModel: gqlPresentationSizeSheetsSizeMetricsModel;
  PresentationSizeSheetsTalentModel: gqlPresentationSizeSheetsTalentModel;
  PresentationSizeSheetsWeightMetricsModel: gqlPresentationSizeSheetsWeightMetricsModel;
  PresentationSubmissionComment: gqlPresentationSubmissionComment;
  PresentationSubmissionCommentPaginatedResult: gqlPresentationSubmissionCommentPaginatedResult;
  PresentationSubmissionFeedback: gqlPresentationSubmissionFeedback;
  PresentationSubmissionPublic: gqlPresentationSubmissionPublic;
  PresentationTalentPublic: gqlPresentationTalentPublic;
  PresentationTalentPublicPaginatedResult: gqlPresentationTalentPublicPaginatedResult;
  PresentationType: gqlPresentationType;
  PresentationTypeEnum: gqlPresentationTypeEnum;
  PresentationUpdateInput: gqlPresentationUpdateInput;
  PreviewSubscriptionInput: gqlPreviewSubscriptionInput;
  PreviewSubscriptionRenewalInput: gqlPreviewSubscriptionRenewalInput;
  Profile: gqlProfile;
  ProfileAccessToken: gqlProfileAccessToken;
  ProfileBookOutInput: gqlProfileBookOutInput;
  ProfileBookOuts: gqlProfileBookOuts;
  ProfileConflict: gqlProfileConflict;
  ProfileContactInfo: gqlProfileContactInfo;
  ProfileContactedByRep: gqlProfileContactedByRep;
  ProfileCreateInput: gqlProfileCreateInput;
  ProfileDirectMessageContextAndProfile: gqlProfileDirectMessageContextAndProfile;
  ProfileDirectMessageContextInput: gqlProfileDirectMessageContextInput;
  ProfileDirectMessageProjectContext: gqlProfileDirectMessageProjectContext;
  ProfileDirectMessageRoleContext: gqlProfileDirectMessageRoleContext;
  ProfileEthnicAppearance: gqlProfileEthnicAppearance;
  ProfileFilter: gqlProfileFilter;
  ProfileFromEnum: gqlProfileFromEnum;
  ProfileGenderIdentity: gqlProfileGenderIdentity;
  ProfileGqlModel: gqlProfileGqlModel;
  ProfileIdsByAccount: gqlProfileIdsByAccount;
  ProfileInput: gqlProfileInput;
  ProfileList: gqlProfileList;
  ProfileListPaginatedResult: gqlProfileListPaginatedResult;
  ProfileMedia: gqlProfileMedia;
  ProfileMediaInput: gqlProfileMediaInput;
  ProfilePet: gqlProfilePet;
  ProfilePetInput: gqlProfilePetInput;
  ProfilePick: gqlProfilePick;
  ProfileProp: gqlProfileProp;
  ProfileSearch: gqlProfileSearch;
  ProfileSearchInput: gqlProfileSearchInput;
  ProfileSearchRepresentationType: gqlProfileSearchRepresentationType;
  ProfileSearchResultByOrgId: gqlProfileSearchResultByOrgId;
  ProfileSearchResultByOrgIdPaginatedResult: gqlProfileSearchResultByOrgIdPaginatedResult;
  ProfileSearchSortField: gqlProfileSearchSortField;
  ProfileSearchSortOption: gqlProfileSearchSortOption;
  ProfileSelectionType: gqlProfileSelectionType;
  ProfileShoeSize: gqlProfileShoeSize;
  ProfileShoeSizeInput: gqlProfileShoeSizeInput;
  ProfileSize: gqlProfileSize;
  ProfileSizeInput: gqlProfileSizeInput;
  ProfileSkill: gqlProfileSkill;
  ProfileSkillInput: gqlProfileSkillInput;
  ProfileSportEquipment: gqlProfileSportEquipment;
  ProfileStatus: gqlProfileStatus;
  ProfileStatusEnum: gqlProfileStatusEnum;
  ProfileStatusSearchEnum: gqlProfileStatusSearchEnum;
  ProfileType: gqlProfileType;
  ProfileTypeEnum: gqlProfileTypeEnum;
  ProfileUnionFilter: gqlProfileUnionFilter;
  ProfileUpdateInput: gqlProfileUpdateInput;
  ProfileVehicle: gqlProfileVehicle;
  ProfileVehicleInput: gqlProfileVehicleInput;
  ProfileVisibility: gqlProfileVisibility;
  ProfileWardrobe: gqlProfileWardrobe;
  ProfileWorkingLocation: gqlProfileWorkingLocation;
  ProhibitedCustomSlug: gqlProhibitedCustomSlug;
  Project: gqlProject;
  ProjectAttachSupportTicketNumber: gqlProjectAttachSupportTicketNumber;
  ProjectBacklotSearchInput: gqlProjectBacklotSearchInput;
  ProjectBacklotSearchResult: gqlProjectBacklotSearchResult;
  ProjectBacklotSearchResultPaginatedResult: gqlProjectBacklotSearchResultPaginatedResult;
  ProjectBase: gqlProjectBase;
  ProjectBasePaginatedResult: gqlProjectBasePaginatedResult;
  ProjectFieldRestriction: gqlProjectFieldRestriction;
  ProjectFieldRestrictionFieldOption: gqlProjectFieldRestrictionFieldOption;
  ProjectFieldRestrictionInput: gqlProjectFieldRestrictionInput;
  ProjectFieldRestrictionOption: gqlProjectFieldRestrictionOption;
  ProjectFilter: gqlProjectFilter;
  ProjectFilterInput: gqlProjectFilterInput;
  ProjectGroupType: gqlProjectGroupType;
  ProjectLocation: gqlProjectLocation;
  ProjectNoteInput: gqlProjectNoteInput;
  ProjectNotificationToRepsInput: gqlProjectNotificationToRepsInput;
  ProjectOrganizationInput: gqlProjectOrganizationInput;
  ProjectOverviewCDReportModel: gqlProjectOverviewCdReportModel;
  ProjectOverviewCDReportPayloadModel: gqlProjectOverviewCdReportPayloadModel;
  ProjectOverviewReportModel: gqlProjectOverviewReportModel;
  ProjectOverviewReportPayloadModel: gqlProjectOverviewReportPayloadModel;
  ProjectPaginatedResult: gqlProjectPaginatedResult;
  ProjectReportAttachmentModel: gqlProjectReportAttachmentModel;
  ProjectReportCDCreateInput: gqlProjectReportCdCreateInput;
  ProjectReportCDRoleModel: gqlProjectReportCdRoleModel;
  ProjectReportCreateInput: gqlProjectReportCreateInput;
  ProjectReportInfoModel: gqlProjectReportInfoModel;
  ProjectReportRoleModel: gqlProjectReportRoleModel;
  ProjectRequestsInfo: gqlProjectRequestsInfo;
  ProjectSeries: gqlProjectSeries;
  ProjectSeriesPaginatedResult: gqlProjectSeriesPaginatedResult;
  ProjectSidesVisibility: gqlProjectSidesVisibility;
  ProjectStatus: gqlProjectStatus;
  ProjectStatusEnum: gqlProjectStatusEnum;
  ProjectStatusFilterEnum: gqlProjectStatusFilterEnum;
  ProjectSubmissionCreateInput: gqlProjectSubmissionCreateInput;
  ProjectSubmissionReportModel: gqlProjectSubmissionReportModel;
  ProjectSubmissionReportPayloadModel: gqlProjectSubmissionReportPayloadModel;
  ProjectSubmissionReportRoleModel: gqlProjectSubmissionReportRoleModel;
  ProjectType: gqlProjectType;
  ProjectTypeEnum: gqlProjectTypeEnum;
  ProjectUnionDetails: gqlProjectUnionDetails;
  ProjectViewFilterType: gqlProjectViewFilterType;
  ProjectVisibility: gqlProjectVisibility;
  ProjectVisibilityResult: gqlProjectVisibilityResult;
  ProjectWorkflow: gqlProjectWorkflow;
  Prop: gqlProp;
  PublicCredit: gqlPublicCredit;
  PublicProfile: gqlPublicProfile;
  PublicProfileFolderCustomization: gqlPublicProfileFolderCustomization;
  PublicProfileFolderMediaCustomization: gqlPublicProfileFolderMediaCustomization;
  PublicSubmission: gqlPublicSubmission;
  PublicationSetting: gqlPublicationSetting;
  PublicationSettingsEnum: gqlPublicationSettingsEnum;
  PurchaseMediaInput: gqlPurchaseMediaInput;
  PurchaseSubscriptionInput: gqlPurchaseSubscriptionInput;
  Pursuing: gqlPursuing;
  PursuingEnum: gqlPursuingEnum;
  RatePerEnum: gqlRatePerEnum;
  RatePerType: gqlRatePerType;
  Redirect: gqlRedirect;
  RedirectInput: gqlRedirectInput;
  Region: gqlRegion;
  RegionItem: gqlRegionItem;
  RegisterDeviceInput: gqlRegisterDeviceInput;
  RemoveBillingInfoInput: gqlRemoveBillingInfoInput;
  RemoveProfileFromCastingListError: gqlRemoveProfileFromCastingListError;
  RemoveProfileFromCastingListInput: gqlRemoveProfileFromCastingListInput;
  RemoveProfileFromCastingListResult: gqlRemoveProfileFromCastingListResult;
  RenewSubscriptionInput: gqlRenewSubscriptionInput;
  ReorderError: gqlReorderError;
  ReorderResult: gqlReorderResult;
  RepOrganizationList: gqlRepOrganizationList;
  RepOrganizationListInput: gqlRepOrganizationListInput;
  RepOrganizationListSortEnum: gqlRepOrganizationListSortEnum;
  RepPresentations: gqlRepPresentations;
  RepRequestStatus: gqlRepRequestStatus;
  RepRoleStatusEnum: gqlRepRoleStatusEnum;
  RepWorksheetFieldOrder: gqlRepWorksheetFieldOrder;
  RepWorksheetItem: gqlRepWorksheetItem;
  RepWorksheetOrderOption: gqlRepWorksheetOrderOption;
  RepWorksheetOrganization: gqlRepWorksheetOrganization;
  RepWorksheetProfile: gqlRepWorksheetProfile;
  RepWorksheetProject: gqlRepWorksheetProject;
  RepWorksheetResponse: gqlRepWorksheetResponse;
  RepWorksheetRole: gqlRepWorksheetRole;
  RepWorksheetScheduleAddress: gqlRepWorksheetScheduleAddress;
  RepWorksheetScheduleAddressCountry: gqlRepWorksheetScheduleAddressCountry;
  RepWorksheetScheduleAddressState: gqlRepWorksheetScheduleAddressState;
  RepWorksheetScheduleDetail: gqlRepWorksheetScheduleDetail;
  RepWorksheetSubmission: gqlRepWorksheetSubmission;
  RepWorksheetTalent: gqlRepWorksheetTalent;
  RepWorksheetTalentUnion: gqlRepWorksheetTalentUnion;
  RepWorksheetTimeZone: gqlRepWorksheetTimeZone;
  ReportContactInfoModel: gqlReportContactInfoModel;
  ReportDma: gqlReportDma;
  ReportMediaModel: gqlReportMediaModel;
  ReportMediaObjectModel: gqlReportMediaObjectModel;
  ReportPublicationSettings: gqlReportPublicationSettings;
  ReportRoleDateType: gqlReportRoleDateType;
  ReportRoleDateWithLocation: gqlReportRoleDateWithLocation;
  ReportSetting: gqlReportSetting;
  ReportStatusEnum: gqlReportStatusEnum;
  ReportTypeEnum: gqlReportTypeEnum;
  ReportUnion: gqlReportUnion;
  RepresentationAgeCategory: gqlRepresentationAgeCategory;
  RepresentationAgeCategoryEnum: gqlRepresentationAgeCategoryEnum;
  RepresentationCategory: gqlRepresentationCategory;
  RepresentationCategoryEnum: gqlRepresentationCategoryEnum;
  RepresentationSystemRole: gqlRepresentationSystemRole;
  RepresentationType: gqlRepresentationType;
  RepresentationTypeEnum: gqlRepresentationTypeEnum;
  RepresentativeList: gqlRepresentativeList;
  RepresentativeListPaginatedResult: gqlRepresentativeListPaginatedResult;
  RepresentativeSearchResult: gqlRepresentativeSearchResult;
  RepsSubmissionTypeEnum: gqlRepsSubmissionTypeEnum;
  RepsWorksheetFilters: gqlRepsWorksheetFilters;
  RepsWorksheetFiltersArgs: gqlRepsWorksheetFiltersArgs;
  RepsWorksheetGroup: gqlRepsWorksheetGroup;
  RepsWorksheetGroupDetails: gqlRepsWorksheetGroupDetails;
  RepsWorksheetGroupType: gqlRepsWorksheetGroupType;
  RepsWorksheetGroups: gqlRepsWorksheetGroups;
  RepsWorksheetItem: gqlRepsWorksheetItem;
  RepsWorksheetItemPagedList: gqlRepsWorksheetItemPagedList;
  Request: gqlRequest;
  RequestForms: gqlRequestForms;
  RequestMediaType: gqlRequestMediaType;
  RequestPagedList: gqlRequestPagedList;
  RequestSendTo: gqlRequestSendTo;
  RequestStatus: gqlRequestStatus;
  RequestStatusEnum: gqlRequestStatusEnum;
  RequestStatusUnion: gqlRequestStatusUnion;
  RequestSubmission: gqlRequestSubmission;
  RequestType: gqlRequestType;
  RequestTypeEnum: gqlRequestTypeEnum;
  RequestUnion: gqlRequestUnion;
  ResetSessionObserverUrlInput: gqlResetSessionObserverUrlInput;
  ResetSessionObserverUrlOutput: gqlResetSessionObserverUrlOutput;
  ResponseStatus: gqlResponseStatus;
  ResponseStatusEnum: gqlResponseStatusEnum;
  RestoreArchivedMediaInput: gqlRestoreArchivedMediaInput;
  RestoreArchivedMediaResponse: gqlRestoreArchivedMediaResponse;
  RestoreDeletedMediaInput: gqlRestoreDeletedMediaInput;
  RestoreDeletedMediaResponse: gqlRestoreDeletedMediaResponse;
  ResumeSubscriptionInput: gqlResumeSubscriptionInput;
  Retention: gqlRetention;
  Role: gqlRole;
  RoleChangeLog: gqlRoleChangeLog;
  RoleCompensation: gqlRoleCompensation;
  RoleCompensationInput: gqlRoleCompensationInput;
  RoleCountOfPairedRepsAndCB: gqlRoleCountOfPairedRepsAndCb;
  RoleCreateInput: gqlRoleCreateInput;
  RoleDateType: gqlRoleDateType;
  RoleDateTypeEnum: gqlRoleDateTypeEnum;
  RoleDateWithLocation: gqlRoleDateWithLocation;
  RoleDateWithLocationInput: gqlRoleDateWithLocationInput;
  RoleDefaultDateWithLocation: gqlRoleDefaultDateWithLocation;
  RoleDefaultPublicationSetting: gqlRoleDefaultPublicationSetting;
  RoleDefaults: gqlRoleDefaults;
  RoleDefaultsInput: gqlRoleDefaultsInput;
  RoleFieldRestriction: gqlRoleFieldRestriction;
  RoleFieldRestrictionInput: gqlRoleFieldRestrictionInput;
  RoleFieldsRestrictionsFieldOption: gqlRoleFieldsRestrictionsFieldOption;
  RoleMedia: gqlRoleMedia;
  RoleMediaInput: gqlRoleMediaInput;
  RoleOrganizationPair: gqlRoleOrganizationPair;
  RoleOrganizationPairPaginatedResult: gqlRoleOrganizationPairPaginatedResult;
  RolePreviousStatus: gqlRolePreviousStatus;
  RoleProjectKeywordSearchEnum: gqlRoleProjectKeywordSearchEnum;
  RolePublication: gqlRolePublication;
  RolePublicationPaginatedResult: gqlRolePublicationPaginatedResult;
  RolePublicationSetting: gqlRolePublicationSetting;
  RolePublicationSettingInput: gqlRolePublicationSettingInput;
  RolePublishTypeEnum: gqlRolePublishTypeEnum;
  RoleSearchResult: gqlRoleSearchResult;
  RoleSidesVisibility: gqlRoleSidesVisibility;
  RoleSidesVisibilityEnum: gqlRoleSidesVisibilityEnum;
  RoleSkillInput: gqlRoleSkillInput;
  RoleStatus: gqlRoleStatus;
  RoleStatusCodes: gqlRoleStatusCodes;
  RoleStatusEnum: gqlRoleStatusEnum;
  RoleStatusFilter: gqlRoleStatusFilter;
  RoleSubmissionCount: gqlRoleSubmissionCount;
  RoleTalent: gqlRoleTalent;
  RoleTarget: gqlRoleTarget;
  RoleTargetEnum: gqlRoleTargetEnum;
  RoleTargetsEnum: gqlRoleTargetsEnum;
  RoleTracker: gqlRoleTracker;
  RoleTrackerFieldOrder: gqlRoleTrackerFieldOrder;
  RoleTrackerFiltersArgs: gqlRoleTrackerFiltersArgs;
  RoleTrackerGroup: gqlRoleTrackerGroup;
  RoleTrackerGroupType: gqlRoleTrackerGroupType;
  RoleTrackerGroups: gqlRoleTrackerGroups;
  RoleTrackerOrderOption: gqlRoleTrackerOrderOption;
  RoleTrackerPagedList: gqlRoleTrackerPagedList;
  RoleTrackerStatsAggregate: gqlRoleTrackerStatsAggregate;
  RoleTrackerStatsIntervalEnum: gqlRoleTrackerStatsIntervalEnum;
  RoleTrackerStatsPeriod: gqlRoleTrackerStatsPeriod;
  RoleTrackerStatsResponse: gqlRoleTrackerStatsResponse;
  RoleTrackerStatusEnum: gqlRoleTrackerStatusEnum;
  RoleType: gqlRoleType;
  RoleTypeEnum: gqlRoleTypeEnum;
  RoleTypeFilterInput: gqlRoleTypeFilterInput;
  RoleTypeInput: gqlRoleTypeInput;
  RoleUnion: gqlRoleUnion;
  RoleUnionEnum: gqlRoleUnionEnum;
  RoleUpdateInput: gqlRoleUpdateInput;
  RoleUpdateWardrobeInput: gqlRoleUpdateWardrobeInput;
  RoleWorkflow: gqlRoleWorkflow;
  RolesFitForYouArtistContext: gqlRolesFitForYouArtistContext;
  RolesFitForYouCastingCompanyContext: gqlRolesFitForYouCastingCompanyContext;
  RolesFitForYouContext: gqlRolesFitForYouContext;
  RolesFitForYouProjectContext: gqlRolesFitForYouProjectContext;
  RolesFitForYouRoleContext: gqlRolesFitForYouRoleContext;
  RolesPublishedCastingCompanyContext: gqlRolesPublishedCastingCompanyContext;
  RolesPublishedContext: gqlRolesPublishedContext;
  RolesPublishedContextNotificationContextAndRecipient: gqlRolesPublishedContextNotificationContextAndRecipient;
  RolesPublishedCreativeTeamContext: gqlRolesPublishedCreativeTeamContext;
  RolesPublishedProjectContext: gqlRolesPublishedProjectContext;
  RolesPublishedRoleContext: gqlRolesPublishedRoleContext;
  RolesPublishedRoleDetailsContext: gqlRolesPublishedRoleDetailsContext;
  RosterInvitationRequest: gqlRosterInvitationRequest;
  RosterInvitationRequestInput: gqlRosterInvitationRequestInput;
  RosterInvitationRequestStatus: gqlRosterInvitationRequestStatus;
  RosterInvitationTalentHistoryFilterInput: gqlRosterInvitationTalentHistoryFilterInput;
  RosterInvitationTalentInput: gqlRosterInvitationTalentInput;
  RosterTalentInvitation: gqlRosterTalentInvitation;
  RosterTalentInvitationPagedList: gqlRosterTalentInvitationPagedList;
  RpcFormBulkResponseItem: gqlRpcFormBulkResponseItem;
  RpcRoleInfo: gqlRpcRoleInfo;
  SaveDraftAskQuestionRequestArgs: gqlSaveDraftAskQuestionRequestArgs;
  SaveDraftMediaRequestArgs: gqlSaveDraftMediaRequestArgs;
  SaveFormResponseResult: gqlSaveFormResponseResult;
  SavedSearch: gqlSavedSearch;
  SavedSearchAttributes: gqlSavedSearchAttributes;
  SavedSearchList: gqlSavedSearchList;
  Schedule: gqlSchedule;
  ScheduleDetail: gqlScheduleDetail;
  ScheduleHistory: gqlScheduleHistory;
  ScheduleInfo: gqlScheduleInfo;
  ScheduleInvitePreviousResponse: gqlScheduleInvitePreviousResponse;
  ScheduleInviteRequestInput: gqlScheduleInviteRequestInput;
  ScheduleInviteRequestNotes: gqlScheduleInviteRequestNotes;
  ScheduleInviteRequestStatus: gqlScheduleInviteRequestStatus;
  ScheduleInviteResponse: gqlScheduleInviteResponse;
  ScheduleInviteResponseInput: gqlScheduleInviteResponseInput;
  ScheduleInviteResponseStatus: gqlScheduleInviteResponseStatus;
  ScheduleInviteResponseStatusCounts: gqlScheduleInviteResponseStatusCounts;
  ScheduleInviteSetStatusInput: gqlScheduleInviteSetStatusInput;
  ScheduleInviteSubRequest: gqlScheduleInviteSubRequest;
  SchedulePaginatedResult: gqlSchedulePaginatedResult;
  ScheduleReceiver: gqlScheduleReceiver;
  ScheduleReminderCastingCompanyContext: gqlScheduleReminderCastingCompanyContext;
  ScheduleReminderContext: gqlScheduleReminderContext;
  ScheduleReminderContextNotificationContextAndRecipient: gqlScheduleReminderContextNotificationContextAndRecipient;
  ScheduleReminderProfileContext: gqlScheduleReminderProfileContext;
  ScheduleReminderProjectContext: gqlScheduleReminderProjectContext;
  ScheduleReminderRoleContext: gqlScheduleReminderRoleContext;
  ScheduleReportCreateInput: gqlScheduleReportCreateInput;
  ScheduleReportModel: gqlScheduleReportModel;
  ScheduleReportPayloadModel: gqlScheduleReportPayloadModel;
  ScheduleReportSubmissionModel: gqlScheduleReportSubmissionModel;
  ScheduleReportTimeframeModel: gqlScheduleReportTimeframeModel;
  ScheduleReportTimeslotModel: gqlScheduleReportTimeslotModel;
  ScheduleRequestType: gqlScheduleRequestType;
  ScheduleSearchInput: gqlScheduleSearchInput;
  ScheduleSizeSheetsReportCreateInput: gqlScheduleSizeSheetsReportCreateInput;
  ScheduleSizeSheetsReportModel: gqlScheduleSizeSheetsReportModel;
  ScheduleSlot: gqlScheduleSlot;
  ScheduleSlotSummary: gqlScheduleSlotSummary;
  ScheduleTalentPool: gqlScheduleTalentPool;
  ScheduleTimeframRequestSummary: gqlScheduleTimeframRequestSummary;
  ScheduleTimeframe: gqlScheduleTimeframe;
  ScheduleTimeframeRoleSummary: gqlScheduleTimeframeRoleSummary;
  ScheduleTimeframeShiftedResult: gqlScheduleTimeframeShiftedResult;
  ScheduleTimeframeSummary: gqlScheduleTimeframeSummary;
  ScheduleVirtualAudition: gqlScheduleVirtualAudition;
  SchedulesSelectInput: gqlSchedulesSelectInput;
  SearchAccountsFiltersArgs: gqlSearchAccountsFiltersArgs;
  SearchArtistMediaIncludeActive: gqlSearchArtistMediaIncludeActive;
  SearchArtistMediaIncludeStatus: gqlSearchArtistMediaIncludeStatus;
  SearchArtistMediaIncludeType: gqlSearchArtistMediaIncludeType;
  SearchArtistMediaInput: gqlSearchArtistMediaInput;
  SearchArtistMediaQueryInput: gqlSearchArtistMediaQueryInput;
  SearchDirection: gqlSearchDirection;
  SearchMediaFilter: gqlSearchMediaFilter;
  SearchMediaInput: gqlSearchMediaInput;
  SearchMediaPaginatedResult: gqlSearchMediaPaginatedResult;
  SearchMediaSortBy: gqlSearchMediaSortBy;
  SearchMediaSortOrder: gqlSearchMediaSortOrder;
  SearchMediaStatus: gqlSearchMediaStatus;
  SearchMediaType: gqlSearchMediaType;
  SearchNotificationFiltersArgs: gqlSearchNotificationFiltersArgs;
  SearchOrganizationsFiltersArgs: gqlSearchOrganizationsFiltersArgs;
  SearchPageGroupInput: gqlSearchPageGroupInput;
  SearchPageInput: gqlSearchPageInput;
  SearchPageInputWf: gqlSearchPageInputWf;
  SearchRepresentativeInput: gqlSearchRepresentativeInput;
  SearchResultType: gqlSearchResultType;
  SeekingRepresentation: gqlSeekingRepresentation;
  SendDirectMessageToTalentsInput: gqlSendDirectMessageToTalentsInput;
  SendVoiceOverMediaRequestInput: gqlSendVoiceOverMediaRequestInput;
  SeriesDetails: gqlSeriesDetails;
  Session: gqlSession;
  SetSubscriptionPaymentMethodInput: gqlSetSubscriptionPaymentMethodInput;
  SexualSituation: gqlSexualSituation;
  SexualSituationEnum: gqlSexualSituationEnum;
  ShareSelectPresentationInput: gqlShareSelectPresentationInput;
  ShareTalentPresentationInput: gqlShareTalentPresentationInput;
  ShiftTimeframeInput: gqlShiftTimeframeInput;
  ShoeModifier: gqlShoeModifier;
  ShoeSize: gqlShoeSize;
  SizeGenderEnum: gqlSizeGenderEnum;
  SizeModifier: gqlSizeModifier;
  Skill: gqlSkill;
  SkillEnum: gqlSkillEnum;
  SkillInput: gqlSkillInput;
  SkillLevel: gqlSkillLevel;
  SkillLevelEnum: gqlSkillLevelEnum;
  SkillSearchInput: gqlSkillSearchInput;
  Sleeve: gqlSleeve;
  Slot: gqlSlot;
  SlotArguments: gqlSlotArguments;
  SlotSubmissionsInput: gqlSlotSubmissionsInput;
  SortConditionInput: gqlSortConditionInput;
  SortOrder: gqlSortOrder;
  SortOrderEnum: gqlSortOrderEnum;
  SportEquipment: gqlSportEquipment;
  State: gqlState;
  StateFilterInput: gqlStateFilterInput;
  SubFieldsGql: gqlSubFieldsGql;
  Submission: gqlSubmission;
  SubmissionByProfileInput: gqlSubmissionByProfileInput;
  SubmissionCountPerRole: gqlSubmissionCountPerRole;
  SubmissionCountPerRoleInput: gqlSubmissionCountPerRoleInput;
  SubmissionCreated: gqlSubmissionCreated;
  SubmissionDueDateSearchEnum: gqlSubmissionDueDateSearchEnum;
  SubmissionMedia: gqlSubmissionMedia;
  SubmissionMediaInput: gqlSubmissionMediaInput;
  SubmissionPaginatedResult: gqlSubmissionPaginatedResult;
  SubmissionPick: gqlSubmissionPick;
  SubmissionProfile: gqlSubmissionProfile;
  SubmissionQuickAddInput: gqlSubmissionQuickAddInput;
  SubmissionRequestSearchInput: gqlSubmissionRequestSearchInput;
  SubmissionResponse: gqlSubmissionResponse;
  SubmissionResponses: gqlSubmissionResponses;
  SubmissionScheduleRequestSearchInput: gqlSubmissionScheduleRequestSearchInput;
  SubmissionScheduleSlot: gqlSubmissionScheduleSlot;
  SubmissionSearchInput: gqlSubmissionSearchInput;
  SubmissionSearchSortColumn: gqlSubmissionSearchSortColumn;
  SubmissionSearchSortOptionsInput: gqlSubmissionSearchSortOptionsInput;
  SubmissionSelectionResult: gqlSubmissionSelectionResult;
  SubmissionSourceType: gqlSubmissionSourceType;
  SubmissionStatus: gqlSubmissionStatus;
  SubmissionStatusCodeEnum: gqlSubmissionStatusCodeEnum;
  SubmissionStatusEnum: gqlSubmissionStatusEnum;
  SubmissionTrend: gqlSubmissionTrend;
  SubmissionTrendsInput: gqlSubmissionTrendsInput;
  SubmissionTrendsNoAccessError: gqlSubmissionTrendsNoAccessError;
  SubmissionTrendsResult: gqlSubmissionTrendsResult;
  SubmissionTrendsSuccess: gqlSubmissionTrendsSuccess;
  SubmissionUpdateInput: gqlSubmissionUpdateInput;
  SubmissionUpdateSelectionInput: gqlSubmissionUpdateSelectionInput;
  SubmissionWorkflow: gqlSubmissionWorkflow;
  SubmissionWorkflowShort: gqlSubmissionWorkflowShort;
  Subscription: gqlSubscription;
  SubscriptionInvoice: gqlSubscriptionInvoice;
  SubscriptionMediaEntitlement: gqlSubscriptionMediaEntitlement;
  SubscriptionModel: gqlSubscriptionModel;
  SubscriptionRenewal: gqlSubscriptionRenewal;
  SubscriptionStatusEnum: gqlSubscriptionStatusEnum;
  SuccessResponse: gqlSuccessResponse;
  SystemRole: gqlSystemRole;
  SystemRoleCode: gqlSystemRoleCode;
  SystemRoleEnum: gqlSystemRoleEnum;
  TShirtSize: gqlTShirtSize;
  Tag: gqlTag;
  TalentAccessProfileContext: gqlTalentAccessProfileContext;
  TalentAccessProfileContextInput: gqlTalentAccessProfileContextInput;
  TalentAccessProfileContextNotificationContextAndRecipient: gqlTalentAccessProfileContextNotificationContextAndRecipient;
  TalentAccessProfileDetailsContext: gqlTalentAccessProfileDetailsContext;
  TalentPickPublic: gqlTalentPickPublic;
  TalentProfileUnion: gqlTalentProfileUnion;
  TalentScoutModel: gqlTalentScoutModel;
  TalentScoutPageListResult: gqlTalentScoutPageListResult;
  TalentStatusEnum: gqlTalentStatusEnum;
  TalentSubmissionItemModel: gqlTalentSubmissionItemModel;
  TalentSubmissionModel: gqlTalentSubmissionModel;
  TalentSubmissionPayloadModel: gqlTalentSubmissionPayloadModel;
  TalentSubmissionProfileModel: gqlTalentSubmissionProfileModel;
  TalentSubmissionReportCreateInput: gqlTalentSubmissionReportCreateInput;
  TalentUnion: gqlTalentUnion;
  TalentUnionEnum: gqlTalentUnionEnum;
  TalentUnionInput: gqlTalentUnionInput;
  TalentUnionModel: gqlTalentUnionModel;
  Tattoo: gqlTattoo;
  TattooInput: gqlTattooInput;
  ThumbnailType: gqlThumbnailType;
  TileSchemaEnum: gqlTileSchemaEnum;
  TileSchemaValue: gqlTileSchemaValue;
  TileSchemaValueEnum: gqlTileSchemaValueEnum;
  TileSchemeValueEnum: gqlTileSchemeValueEnum;
  TimeFrameEnum: gqlTimeFrameEnum;
  TimeZone: gqlTimeZone;
  UnclaimedAccount: gqlUnclaimedAccount;
  UnderBust: gqlUnderBust;
  UniqueAttribute: gqlUniqueAttribute;
  UnitSystem: gqlUnitSystem;
  UnitSystemEnum: gqlUnitSystemEnum;
  UnlockMediaInput: gqlUnlockMediaInput;
  UnlockMediaResponse: gqlUnlockMediaResponse;
  UpdateAccountInput: gqlUpdateAccountInput;
  UpdateBillingInfoInput: gqlUpdateBillingInfoInput;
  UpdateDueDateInput: gqlUpdateDueDateInput;
  UpdateLegacyResult: gqlUpdateLegacyResult;
  UpdateListNameInput: gqlUpdateListNameInput;
  UpdateMediaInput: gqlUpdateMediaInput;
  UpdateNoteInput: gqlUpdateNoteInput;
  UpdateOrganizationInput: gqlUpdateOrganizationInput;
  UpdateOrganizationResult: gqlUpdateOrganizationResult;
  UpdatePCProjectDeterminedAddressInput: gqlUpdatePcProjectDeterminedAddressInput;
  UpdatePCProjectInput: gqlUpdatePcProjectInput;
  UpdatePCProjectSpecificAddressInput: gqlUpdatePcProjectSpecificAddressInput;
  UpdateProfileConflictInput: gqlUpdateProfileConflictInput;
  UpdateProfileMoveToArtistInput: gqlUpdateProfileMoveToArtistInput;
  UpdateProfileVehicleInput: gqlUpdateProfileVehicleInput;
  UpdateProjectInput: gqlUpdateProjectInput;
  UpdateRepresentativeListInput: gqlUpdateRepresentativeListInput;
  UpdateRoleMediaInput: gqlUpdateRoleMediaInput;
  UpdateSavedSearchInput: gqlUpdateSavedSearchInput;
  UpdateScheduleInput: gqlUpdateScheduleInput;
  UpdateSubmissionStatusInput: gqlUpdateSubmissionStatusInput;
  UrlType: gqlUrlType;
  UrlTypeEnum: gqlUrlTypeEnum;
  UserOrganization: gqlUserOrganization;
  ValidationError: gqlValidationError;
  ValidationErrorForm: gqlValidationErrorForm;
  VehicleMake: gqlVehicleMake;
  VehicleSearchInput: gqlVehicleSearchInput;
  VehicleType: gqlVehicleType;
  VehicleTypeMake: gqlVehicleTypeMake;
  VerifyAccountByCookieInput: gqlVerifyAccountByCookieInput;
  VerifyAccountInput: gqlVerifyAccountInput;
  VirtualAuditionAttendee: gqlVirtualAuditionAttendee;
  VirtualAuditionAttendeeType: gqlVirtualAuditionAttendeeType;
  Waist: gqlWaist;
  Wardrobe: gqlWardrobe;
  Weight: gqlWeight;
  WelcomeContext: gqlWelcomeContext;
  WelcomeContextNotificationContextAndRecipient: gqlWelcomeContextNotificationContextAndRecipient;
  WfAddress: gqlWfAddress;
  WfBasicSettingModel: gqlWfBasicSettingModel;
  WfChatMessage: gqlWfChatMessage;
  WfChatTopic: gqlWfChatTopic;
  WfComplexSetting: gqlWfComplexSetting;
  WfContact: gqlWfContact;
  WfContinent: gqlWfContinent;
  WfContractStatus: gqlWfContractStatus;
  WfCountry: gqlWfCountry;
  WfCredit: gqlWfCredit;
  WfCurrency: gqlWfCurrency;
  WfDma: gqlWfDma;
  WfEntityType: gqlWfEntityType;
  WfMeasurementModel: gqlWfMeasurementModel;
  WfMedia: gqlWfMedia;
  WfMediaCount: gqlWfMediaCount;
  WfMediaFileType: gqlWfMediaFileType;
  WfMediaStatus: gqlWfMediaStatus;
  WfMediaTransformation: gqlWfMediaTransformation;
  WfMediaType: gqlWfMediaType;
  WfNote: gqlWfNote;
  WfNoteModifier: gqlWfNoteModifier;
  WfOrganization: gqlWfOrganization;
  WfProfile: gqlWfProfile;
  WfProfileContactInfo: gqlWfProfileContactInfo;
  WfProfileMedia: gqlWfProfileMedia;
  WfProject: gqlWfProject;
  WfProjectType: gqlWfProjectType;
  WfProjectUnionDetails: gqlWfProjectUnionDetails;
  WfRatePerType: gqlWfRatePerType;
  WfRegion: gqlWfRegion;
  WfRole: gqlWfRole;
  WfRoleDateType: gqlWfRoleDateType;
  WfRoleLocationDate: gqlWfRoleLocationDate;
  WfRoleMedia: gqlWfRoleMedia;
  WfRoleShort: gqlWfRoleShort;
  WfRoleSidesVisibility: gqlWfRoleSidesVisibility;
  WfRoleTarget: gqlWfRoleTarget;
  WfRoleType: gqlWfRoleType;
  WfSchedule: gqlWfSchedule;
  WfScheduleVirtualAudition: gqlWfScheduleVirtualAudition;
  WfSubmission: gqlWfSubmission;
  WfSubmissionPagedList: gqlWfSubmissionPagedList;
  WfSubmissionSearchInput: gqlWfSubmissionSearchInput;
  WfTalentProfileUnion: gqlWfTalentProfileUnion;
  WfTalentUnion: gqlWfTalentUnion;
  WfTimeZone: gqlWfTimeZone;
  WfUnitSystem: gqlWfUnitSystem;
  WfWeight: gqlWfWeight;
  Willingness: gqlWillingness;
  WillingnessEnum: gqlWillingnessEnum;
  auditionGroupDetails: gqlAuditionGroupDetails;
  projectSidesVisibilityEnum: gqlProjectSidesVisibilityEnum;
}