import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@minecraft.icon';
import { ButtonProps } from './types';

const StyledGoogleButton = styled.button<Pick<ButtonProps, 'disabled'>>`
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: WHITE;
  border: 1px solid #747775;
  border-radius: 4px;
  color: #1f1f1f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 14px 'Roboto', arial, sans-serif;
  height: 40px;
  letter-spacing: 0.25px;
  max-width: 400px;
  min-width: min-content;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  transition: 0.218s;
  white-space: nowrap;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
    svg, span { opacity: 38%; }
  `}

  &:not(:disabled) {
    &:hover {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      &::before {
        opacity: 8%;
      }
    }

    &:hover::before,
    &:focus::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: #303030;
    }

    &:focus::before {
      opacity: 12%;
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 12px;
    display: block;
  }
`;

/**
 * A styled button component that implements Google's sign-in button design guidelines.
 * Renders a button with the Google logo and "Continue with Google" text as default.
 * Supports disabled state and hover effects.
 * https://developers.google.com/identity/branding-guidelines
 * @component
 * @param {ButtonProps} props - The component props
 * @param {Function} props.onClick - Click handler function
 * @param {boolean} props.disabled - Whether the button is disabled
 */
export const GoogleButton: FC<ButtonProps> = ({ onClick, disabled, label = 'Continue with Google', ...props }) => {
  return (
    <StyledGoogleButton onClick={onClick} disabled={disabled} {...props}>
      <Icon name="google" />
      <span>{label}</span>
    </StyledGoogleButton>
  );
};
